import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import store from '@/store/index'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: routes,
})

router.beforeEach((to, from, next) => {
  if (store.getters['auth/getLoggedIn'] !== null || to.path === '/') {
    const localizationName = store.getters['auth/getLocalizationName'] // FIXME: not working
    if (localizationName) {
      document.title = localizationName + ' | Papu.io'
    } else {
      document.title = 'Papu.io'
    }
    next()
  } else {
    if (['demo', 'password-reset', 'password-reset-confirm'].includes(to.name || '')) {
      next()
    } else {
      next('/')
    }
  }
})

// $router.push() overwrite to catch all errors when navigation is aborted
// (due to eg. NavigationDuplicated error when app navigates to actual view)
const originalPush = Router.prototype.push
Router.prototype.push = function (location: any) {
  // @ts-expect-error: Property 'catch' does not exist on type 'void'.
  return originalPush.call(this, location).catch((err: any) => err)
}

export default router

<template>
  <div class="nav-item-info">
    <span class="nav-item-info__title">{{ $t('message.orderDetails.orderSource') }}:</span>
    <b-spinner v-if="isLoading" small class="nav-item-info__spinner" />
    <div
      v-else
      class="menu-source-info-block"
      :style="{
        'background-color': getSourceColor(menuSource),
        color: getButtonTextColor(menuSource),
      }"
    >
      <span>{{ menuTranslated }}</span>
      <span v-if="externalDisplayId">({{ externalDisplayId }})</span>
    </div>
  </div>
</template>


<script>
import { menuSourcesMixins } from '@/mixins'
export default {
  name: 'NavItemOrderSource',
  mixins: [menuSourcesMixins],
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    menuSource: {
      type: [String, undefined],
      default: undefined,
    },
    menuTranslated: {
      type: [String, undefined],
      default: undefined,
    },
    externalDisplayId: {
      type: [String, Number, undefined],
      default: undefined,
    },
  },
}
</script>

<style scoped lang="scss">
.menu-source-info-block {
  padding: 0.3rem 1rem;
  border-radius: 2px;
  display: flex;
  align-items: center;
}
</style>

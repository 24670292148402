import { RegionsState } from './index'
import { Point, Region } from '@/types/interface'

const setRegions = (state: RegionsState, regions: Array<Region>) => {
  state.regions = regions
}
const setRegionsRequest = (state: RegionsState, status: string) => {
  state.regionsRequest = status
}
const saveRegion = (state: RegionsState, region: Region) => {
  const _regions = [...state.regions]
  const index = _regions.findIndex((reg) => reg.id === region.id)
  _regions[index] = region
  state.regions = [..._regions]
}

const setIsEditing = (state: RegionsState, editting: boolean) => {
  state.isEditing = editting
}

const setAddingNewRegion = (state: RegionsState, adding: boolean) => {
  state.addingNewRegion = adding
}

const updateEditedRegionPoints = (state: RegionsState, points: Array<Point>) => {
  state.editedRegionPoints = points
}

const setEditedRegionId = (state: RegionsState, id: number) => {
  state.editedRegionId = id
}

export default {
  setRegionsRequest,
  setRegions,
  saveRegion,
  setAddingNewRegion,
  setIsEditing,
  updateEditedRegionPoints,
  setEditedRegionId,
}

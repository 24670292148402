import { IOrdersPapu3State } from '.'

const orders = (state: IOrdersPapu3State) => state.orders
const ordersRequest = (state: IOrdersPapu3State) => state.ordersRequest
const primaryFilters = (state: IOrdersPapu3State) => state.primaryFilters

export default {
  orders,
  ordersRequest,
  primaryFilters,
}

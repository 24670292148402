<template>
  <b-modal
    id="moveItemModal"
    ref="moveItemModal"
    class="modal-bills"
    :title="$t('message.splitBill.moveOrderItemsHeading')"
    centered
    @hidden="resetModal"
    @ok="handleOk"
  >
    <template v-if="billPosition">
      <div class="my-3 d-flex justify-content-around">
        <template v-for="(quantity, quantityIndex) in quantities">
          <b-button
            v-if="quantity <= billPosition.quantity"
            :key="`quantity_${quantityIndex}`"
            variant="outline-primary"
            size="lg"
            class="mr-3"
            :disabled="processing"
            @click="selectQuantity(quantity)"
          >
            {{ quantity }}
          </b-button>
        </template>
        <b-button
          variant="outline-primary"
          size="lg"
          :disabled="processing"
          @click="selectQuantity(billPosition.quantity)"
        >
          {{ $t('message.common.all') }}
          <template v-if="billPosition"> ({{ billPosition.quantity }}) </template>
        </b-button>
      </div>
      <p class="modal-bills__separating-text">
        {{ $t('message.common.or') }}
      </p>
      <ValidationObserver ref="observer">
        <b-form ref="form" slot-scope="{ validate }" @submit.prevent="validate().then(handleSubmit)">
          <ValidationProvider
            :rules="`required|min_value:0.01|max_value:${parseFloat(billPosition.quantity)}`"
            :name="' '"
            tag="div"
          >
            <b-form-group
              slot-scope="{ valid, errors }"
              :label="$t('message.splitBill.numberOfItems')"
              label-for="numberOfItems"
            >
              <b-form-input
                id="numberOfItems"
                v-model="numberOfItems"
                :state="errors[0] ? false : valid ? true : null"
                :step="0.01"
                :placeholder="`${$t('message.splitBill.enterQuantity')}...`"
                type="number"
                class="modal-bills__input"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-form>
      </ValidationObserver>
    </template>
    <template #modal-footer="{ ok, cancel }">
      <b-button size="lg" variant="outline-dark" class="mr-3" @click="cancel()">
        {{ $t('message.common.cancel') }}
      </b-button>
      <b-overlay :show="processing" rounded="sm" variant="success" spinner-variant="light" opacity="1">
        <b-button size="lg" variant="success" @click="ok()">
          <i class="fas fa-check mr-2" aria-hidden="true" />
          {{ $t('message.common.confirm') }}
        </b-button>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'

const STORE = '_orderSplitBill'

export default {
  name: 'MoveItemModal',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      numberOfItems: '',
      state: null,
      quantities: [0.25, 0.5, 1],
      processing: false,
    }
  },
  computed: {
    billPosition() {
      return this.$store.getters[`${STORE}/selectedBillPosition`]
    },
    sourceBillId() {
      return this.$store.getters[`${STORE}/selectedSourceBillId`]
    },
    toBillId() {
      return this.$store.getters[`${STORE}/selectedToBillId`]
    },
  },
  methods: {
    resetModal() {
      this.numberOfItems = ''
      this.state = null
      this.$store.commit(`${STORE}/setSelectedBillPosition`, null)
    },
    async handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.handleSubmit()
      }
    },
    handleSubmit() {
      this.selectQuantity(this.numberOfItems)
    },
    async selectQuantity(value) {
      const quantity = typeof value === 'string' ? parseFloat(value) : value
      this.processing = true

      try {
        await this.$store.dispatch(`${STORE}/movePosition`, {
          quantity,
          positionId: this.billPosition.id,
          sourceBillId: this.sourceBillId,
          toBillId: this.toBillId,
        })
        this.$bvModal.hide('moveItemModal')
      } catch ({ errorStatus, errorValue }) {
        const msg = errorStatus !== 500 && errorValue ? errorValue : this.$t('message.common.anErrorOccured')
        this.$toasted.error(msg, {
          duration: 5000,
        })
      }

      this.processing = false
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/scss/components/split-bill';
</style>

<template>
  <b-modal
    ref="modal"
    :title="$t('message.navMain.chooseRestaurant')"
    :visible="visible"
    :no-close-on-backdrop="!closable"
    :no-close-on-esc="!closable"
    :hide-header-close="isErestoBrands || setBrand"
    hide-footer
    @hidden="hide"
  >
    <b-button
      v-for="(brand, brandId, index) in getBrands()"
      :key="brandId"
      variant="light"
      block
      size="lg"
      class="brand-button"
      :class="[getBorder(index)]"
      @click="chooseBrand(brandId, brand.name)"
    >
      {{ brand.name }} <small v-if="brand.only_online" class="brand-button__online">ONLINE</small>
    </b-button>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import store from '@/views/order-edit/_store'
import _ from 'lodash'

export default {
  name: 'BrandsModal',
  props: {
    closable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      orderOnSite: false,
      brandBorders: [
        'border-primary',
        'border-success',
        'border-danger',
        'border-warning',
        'border-info',
        'border-dark',
      ],
      isErestoBrands: false,
      setBrand: false,
      orderPapu3: false,
      showOnline: true,
    }
  },
  computed: {
    ...mapGetters({
      brands: 'auth/brands',
      erestoBrands: 'auth/erestoBrands',
    }),
  },
  mounted() {
    this.$eventBus.$on('onShowBrandsModal', (payload = {}) => {
      const { orderOnSite, erestoBrands, setBrand, orderPapu3, showOnline = true } = payload
      this.isErestoBrands = !!erestoBrands
      this.setBrand = !!setBrand
      this.orderPapu3 = !!orderPapu3
      this.showOnline = showOnline

      if (this.orderPapu3) {
        this.show()
        return
      }

      if (!this.$store.state['_orderEdit']) this.$store.registerModule('_orderEdit', store)
      if (this.$store.getters['_orderEdit/mealSaved']) {
        this.show()
        this.orderOnSite = orderOnSite
      } else this.$eventBus.$emit('onShowMealDiscardChangesModal')
    })
  },
  destroyed() {
    this.$eventBus.$off('onShowBrandsModal')
  },
  methods: {
    ...mapActions({
      chooseMenu: 'chooseMenu',
    }),
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
      this.orderOnSite = false
    },
    getBorder(index) {
      return this.brandBorders[index]
    },
    chooseBrand(brandId, brandName) {
      if (this.$listeners?.onChoose) {
        this.$emit('onChoose', brandId)
        this.hide()
        return true
      }
      this.chooseMenu({ brandId: parseInt(brandId), brandName })
      if (this.isErestoBrands || this.setBrand) {
        this.$eventBus.$emit('brandChosen')
        if (this.orderPapu3) this.showNewOrderModal()
      } else {
        this.$router.push({ name: 'new-order', params: { edit: false }, query: { orderOnSite: this.orderOnSite } })
        this.$eventBus.$emit('refreshNewOrder')
      }
      this.hide()
    },
    getBrands() {
      let brands = { ...this.brands }
      if (this.isErestoBrands) brands = this.erestoBrands
      if (!this.showOnline) {
        _.forEach(brands, (brand, key) => {
          if (brand.only_online) delete brands[key]
        })
      }
      return brands
    },
    showNewOrderModal() {
      this.$eventBus.$emit('showOrderPapu3Modal')
    },
  },
}
</script>
<style scoped lang="scss">
.brand-button {
  border-width: 0 0 3px;

  &__online {
    font-size: 10px;
    opacity: 0.5;
  }
}
</style>

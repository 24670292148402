<template>
  <div class="integration-section">
    <h1 class="h3 mb-4 pb-3"><span class="logo__integration logo__integration--wolt" aria-hidden="true" />Wolt</h1>
    <template v-if="hasIntegration('wolt')">
      <b-row class="integration-option-row">
        <settings-button
          class="position-relative"
          :text="hasWoltLocalizationOnline ? $t('message.integrations.wolt.off') : $t('message.integrations.wolt.on')"
          :processing="processingWoltStatus"
          variant="primary"
          @click="switchWoltStatus"
        >
          <template #icon>
            <i class="fas fa-power-off fa-fw badge--active icon--power-off" aria-hidden="true" />
          </template>
        </settings-button>
        <div class="d-flex align-items-center ml-2 settings-section__subtitle mb-0">
          <small class="text">
            (
            {{ hasWoltLocalizationOnline ? $t('message.integrations.online') : $t('message.integrations.offline') }}
            )
          </small>
        </div>
      </b-row>
    </template>
    <template v-else>
      <div class="integration-info">
        <span>{{ $t('message.integrations.noIntegrationInfo') }} Wolt.</span>
        <a href="https://papu.io/pl/integracje/" target="_blank">{{ $t('message.common.knowMore') }}</a>
      </div>
    </template>
  </div>
</template>

<script>
import SettingsButton from '@/components/SettingsButton'
import { API } from '@/services/api/api'
import { mapGetters, mapActions } from 'vuex'
import { MENU_SOURCE_CHOICES } from '@/common/constants'
import { integrationsMixins } from '@/mixins/integrationsMixins'

export default {
  name: 'IntegrationWolt',
  components: {
    SettingsButton,
  },
  mixins: [integrationsMixins],
  data() {
    return {
      processingWoltStatus: false,
      hasWoltLocalizationOnline: false,
      MENU_SOURCE_CHOICES,
    }
  },
  computed: {
    ...mapGetters('integrations', ['hasIntegration', 'getIntegration', 'integrations']),
    hasWoltActive() {
      return this.hasIntegration('wolt') && this.getIntegration('wolt').is_active
    },
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
    ...mapActions('integrations', ['saveIntegrations']),
    async switchWoltStatus() {
      this.processingWoltStatus = true
      const status = this.hasWoltLocalizationOnline ? 'offline' : 'online'
      const { error } = await API.integrateWolt(status)
      if (!error) {
        this.showConfirmation(this.$t('message.integrations.woltConfirmation'))
        this.hasWoltLocalizationOnline = !this.hasWoltLocalizationOnline
      } else {
        this.showFailure(this.$t('message.integrations.woltFailure'))
      }
      this.processingWoltStatus = false
    },
  },
}
</script>
<style lang="scss" scoped>

</style>

import { API } from '@/services/api/api'
import Vue from 'vue'
import { ActionContext } from 'vuex'
import { WorkstationsState } from './index'
import { RootState } from '@/store'
import { showErrorToast, showSuccessToast } from '@/utils/common'
import { IMenuSource, IWorkStation } from '@/types/interface'
import { i18n } from '@/utils/i18n'
import { MENU_SOURCE_CHOICES } from '@/common/constants'

const getWorkstations = async ({ commit }: ActionContext<WorkstationsState, RootState>) => {
  const { error, data } = await API.getWorkstations()
  if (!error && data) {
    commit('setWorkstations', data)
  } else {
    showErrorToast(Vue.toasted, error)
  }
}

const getWorkstation = async ({ commit }: ActionContext<WorkstationsState, RootState>, id: number) => {
  const { error, data } = await API.getWorkstation(id)
  if (!error && data) {
    commit('setWorkstation', data)
  }
}
const updateWorkstation = async ({}, { id, data }: { id: number; data: Partial<IWorkStation> }) => {
  const { error, data: responseData } = await API.updateWorkstation(id, data)
  if (!error) {
    showSuccessToast(Vue.toasted, i18n.t('message.workstationEdit.workstationUpdated'))
  } else {
    showErrorToast(Vue.toasted, error)
  }
  return { error, responseData }
}

const createWorkstation = async ({}, formData: Partial<IWorkStation>) => {
  const { error, data } = await API.createWorkstation(formData)
  return { error, data }
}

const removeWorkstation = async ({}, id: number) => {
  const { error, data } = await API.removeWorkstation(id)

  return { error, data }
}

const updateWorkstationActiveStatus = async (
  { dispatch, commit }: ActionContext<WorkstationsState, RootState>,
  { id, is_active }: { id: number; is_active: boolean }
) => {
  commit('setWorkstationActiveStatus', { id, is_active })
  const { error, data } = await dispatch('updateWorkstation', { id, data: { is_active } })
  if (error) {
    commit('setWorkstationActiveStatus', { id, is_active: !is_active })
  }
  return { error, data }
}

const getMealCategories = async ({ commit, state, rootGetters }: ActionContext<WorkstationsState, RootState>) => {
  const menuIds = Object.keys(rootGetters['auth/getMenuSources']).map((brandId) => {
    const brandSources = rootGetters['auth/getMenuSources'][brandId]
    const menuId = brandSources.find(
      (source: IMenuSource) => source.source === parseInt(MENU_SOURCE_CHOICES.DEFAULT)
    )?.menu_id
    return {
      brandName: rootGetters['auth/brands'][brandId]?.name,
      menuId,
    }
  })

  menuIds.forEach(({ brandName, menuId }) => {
    API.getCategories(menuId).then(({ error, data }) => {
      if (!error && data) {
        commit('setMealCategories', [
          ...state.mealCategories,
          {
            menuId,
            brandName,
            categories: data,
          },
        ])
      }
    })
  })
}

export default {
  getWorkstations,
  removeWorkstation,
  getMealCategories,
  getWorkstation,
  updateWorkstation,
  createWorkstation,
  updateWorkstationActiveStatus,
}

<template>
  <div class="table-responsive">
    <table class="table table-bordered table-sm">
      <thead class="thead-dark">
        <tr>
          <th scope="col" rowspan="2">{{ $t('message.billing.start') }}</th>
          <th scope="col" rowspan="2">{{ $t('message.billing.end') }}</th>
          <th scope="col" rowspan="2">{{ $t('message.billing.operationalCash') }}</th>
          <th scope="col" rowspan="2">{{ $t('message.settings.orders') }}</th>
          <th scope="col" colspan="3" class="border-left border-right text-center">
            {{ $t('message.orderFormFull.paymentTypes.cash') }}
          </th>
          <th scope="col" rowspan="2">{{ $t('message.billing.balance') }}</th>
          <th rowspan="2"></th>
        </tr>
        <tr>
          <th class="text-center">{{ $t('message.billing.acceptedCash') }}</th>
          <th class="text-center">{{ $t('message.billing.givenBack') }}</th>
          <th class="text-center">{{ $t('message.billing.balance') }}</th>
        </tr>
      </thead>
      <template v-if="billingPeriodRequest === 'loading'">
        <TablePlaceholder :row="4" :col="9" :loading="true" />
      </template>
      <tbody v-else>
        <shift-row
          v-for="(shift, index) in shifts"
          :key="'shift_' + index"
          :shift="shift"
          :closing-shift-btn="!!billingPeriod.finished_at"
          :employee="billingPeriod.employee"
        ></shift-row>
      </tbody>
    </table>
    <shift-control-modal @shiftEdited="refreshBillingPeriod"></shift-control-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TablePlaceholder from '@/components/TablePlaceholder'
import ShiftRow from '@/views/shifts/list/_components/ShiftRow'
import ShiftControlModal from '@/views/shifts/current/_components/ShiftControlModal'

export default {
  name: 'ShiftsTable',
  components: {
    TablePlaceholder,
    ShiftRow,
    ShiftControlModal,
  },
  computed: {
    ...mapGetters('_billingPeriodDetails', {
      billingPeriod: 'billingPeriod',
      billingPeriodRequest: 'billingPeriodRequest',
    }),
    shifts() {
      return this.billingPeriod.shifts || []
    },
  },
  methods: {
    ...mapActions('_billingPeriodDetails', ['getBillingPeriod']),
    refreshBillingPeriod() {
      this.getBillingPeriod({ loading: true, billingPeriodId: this.billingPeriod.id })
    },
  },
}
</script>

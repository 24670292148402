import { API } from '@/services/api/api'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import { ShiftsState } from './index'
import Types from '@/types/interface'

const getShifts = async (
  { commit }: ActionContext<ShiftsState, RootState>,
  payload: { loading?: boolean; filters?: Partial<Types.GetShiftsFilters> }
) => {
  const { loading, filters = {} } = payload
  if (loading) commit('setShiftsRequest', 'loading')
  const { error, data }: { data: any; error: any } = await API.getShifts(filters)
  if (!error && data) {
    commit('setShifts', data.results)
    commit('setShiftsRequest', 'success')
  } else {
    commit('setShiftsRequest', 'error')
  }
  return { error, data }
}

const finishShift = async (
  { dispatch }: ActionContext<ShiftsState, RootState>,
  payload: {
    formData: Types.FinishShiftData
    onSuccess: ({}) => void
    onError: ({}) => void
  }
) => {
  const { formData, onSuccess, onError } = payload
  const { error, data }: { data: any; error: any } = await API.finishShift(formData)
  if (!error && data) {
    if (onSuccess) onSuccess(data)
    dispatch('getShifts', {})
  } else {
    if (onError && error?.response?.data) onError(error?.response?.data)
  }
}

export default {
  getShifts,
  finishShift,
}

<template>
  <b-card :class="['card--bill', { 'card--overlay': !editableBill || closing }]" no-body>
    <template v-if="bill">
      <b-card-header class="card__header">
        <div class="d-flex">
          <h2 class="card__title mt-0">
            {{ $t('message.mealDetails.billNo') }}
            <span class="card__badge ml-2">{{ billPosition }}</span>
          </h2>
          <div>
            <b-badge v-if="hasDoneBadgeShown" class="ml-3" variant="success">
              {{ $t('message.splitBill.status.ended') }}
            </b-badge>
            <b-badge v-if="isDoneAndPrinted" class="ml-3" variant="info">
              {{ $t('message.splitBill.status.printed') }}
            </b-badge>
            <b-badge v-if="isPrintError" class="ml-3" variant="danger">
              {{ $t('message.splitBill.status.printError') }}
            </b-badge>
          </div>
        </div>
        <p class="card__total">{{ priceDecimal(bill.total_price) }} {{ currency }}</p>
      </b-card-header>
      <b-card-body no-body class="card__body card__body--bill">
        <ul v-if="areBillPositions" class="list">
          <li
            v-for="(billPosition, billPositionIndex) in bill.positions"
            :key="`position_${billPositionIndex}`"
            class="list__item"
          >
            <BillPosition move-action="onLeft" :item="billPosition" />
          </li>
        </ul>
        <div v-else class="list__placeholder">
          <p class="list__placeholder-text">
            {{ $t('message.splitBill.emptyBillDescription') }}
          </p>
        </div>
      </b-card-body>
      <b-card-footer class="card__footer py-3 px-0">
        <template v-if="areBillPositions">
          <b-form-group
            v-slot="{ ariaDescribedby }"
            :label="$t('message.common.paymentMethod')"
            label-sr-only
            class="px-3"
          >
            <b-form-radio-group
              id="btn-radios-2"
              v-model="selectedPaymentMethod"
              :options="paymentMethods"
              :aria-describedby="ariaDescribedby"
              button-variant="outline-secondary"
              buttons
            />
          </b-form-group>
          <hr />
        </template>
        <div class="actions px-3">
          <b-button v-b-modal.removeBillModal :disabled="!editableBill" variant="danger">
            <i class="far fa-trash-alt mr-2" aria-hidden="true" />
            {{ $t('message.common.delete') }}
          </b-button>
          <div v-if="areBillPositions">
            <b-overlay
              class="d-inline-block"
              :show="closing"
              rounded="sm"
              variant="success"
              spinner-small
              spinner-variant="light"
              opacity="1"
            >
              <b-button :disabled="!editableBill" variant="success" @click="closeBill(bill.id)">
                <i class="fas fa-check mr-2" aria-hidden="true" />
                {{ $t('message.common.end') }}
              </b-button>
            </b-overlay>
            <b-overlay
              :class="['ml-4 d-inline-block', { 'overlay--print': canPrintedBill }]"
              :show="printing"
              rounded="sm"
              variant="info"
              spinner-small
              spinner-variant="light"
              opacity="1"
            >
              <b-button :disabled="!canPrintedBill" variant="info" @click="printBill(bill.id)">
                <i class="fas fa-receipt mr-2" aria-hidden="true" />
                <template v-if="isPrintError">
                  {{ $t('message.common.printAgain') }}
                </template>
                <template v-else>
                  {{ $t('message.common.bill') }}
                </template>
              </b-button>
            </b-overlay>
          </div>
        </div>
        <RemoveBillModal />
        <CashCalculatorModal v-if="areBillPositions" />
      </b-card-footer>
    </template>
    <Loader v-else />
  </b-card>
</template>

<script>
import BillPosition from './_components/BillPosition.vue'
import RemoveBillModal from './_components/modals/RemoveBillModal.vue'
import CashCalculatorModal from './_components/modals/CashCalculatorModal.vue'
import { billStatusMixins, printBillMixins } from '@/mixins'
import { mapGetters } from 'vuex'
import Loader from '@/components/Loader'

const STORE = '_orderSplitBill'

export default {
  name: 'OrdersSplitBillEdit',
  components: {
    BillPosition,
    RemoveBillModal,
    CashCalculatorModal,
    Loader,
  },
  mixins: [billStatusMixins, printBillMixins],
  data() {
    return {
      paymentMethods: [
        { text: this.$t('message.common.card'), value: 'card' },
        { text: this.$t('message.common.cash'), value: 'cash' },
        { text: this.$t('message.common.paid'), value: 'paid' },
      ],
      printing: false,
      closing: false,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    ...mapGetters({
      currency: 'currency',
      orderId: `${STORE}/orderId`,
    }),
    bill() {
      return this.$store.getters[`${STORE}/bill`](this.queryBillId) || null
    },
    billPosition() {
      return this.$store.getters[`${STORE}/billPosition`](this.bill) || 1
    },
    selectedPaymentMethod: {
      get() {
        return this.bill.payment
      },
      set(value) {
        this.$store.commit(`${STORE}/setBillPayment`, { value, bill: this.bill })
      },
    },
    areBillPositions() {
      return this.bill.positions && !!this.bill.positions.length
    },
  },
  watch: {
    queryBillId(id) {
      this.setSelectedToBillId(id)
    },
  },
  mounted() {
    this.setSelectedToBillId(this.queryBillId)
  },
  methods: {
    setSelectedToBillId(id) {
      this.$store.commit(`${STORE}/setSelectedToBillId`, parseInt(id))
    },
    async printBill(id) {
      this.printing = true
      try {
        this.printBillRequest(this.orderId, id)
      } catch ({ errorStatus, errorValue }) {
        this.showErrorToast(errorStatus, errorValue, this.$t('message.common.anErrorOccured'))
      }
      this.printing = false
    },
    async closeBill(id) {
      const isValid = !!this.selectedPaymentMethod

      if (isValid) {
        if (this.selectedPaymentMethod === 'cash') {
          this.$bvModal.show('cashCalculatorModal')
        } else {
          this.closing = true
          try {
            await this.$store.dispatch(`${STORE}/closeBill`, id)
          } catch ({ errorStatus, errorValue }) {
            this.showErrorToast(errorStatus, errorValue, this.$t('message.splitBill.error.closeBill'))
          }
          this.closing = false
        }
      } else {
        this.$toasted.info(this.$t('message.splitBill.selectPayment'), {
          duration: 5000,
        })
      }
    },
    showErrorToast(errorStatus, errorValue, alternativeMsg) {
      const msg = errorStatus !== 500 && errorValue ? errorValue : alternativeMsg
      this.$toasted.error(msg, {
        duration: 5000,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/scss/components/split-bill';

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>

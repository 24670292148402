<template>
  <b-modal id="sourceChangeModal" hide-footer :title="$t('message.orderFormFull.changeOrderSource')">
    <b-button
      v-for="(source, index) in sources"
      :key="index"
      :class="['source-change-btn', isSelected(source.source) && 'source-change-btn--selected']"
      block
      :style="{
        'background-color': getSourceColor(source),
        color: getButtonTextColor(source),
      }"
      size="lg"
      @click="changeSource(source)"
    >
      <span v-if="isSelected(source.source)" class="fa fa-check-square" />
      <span>{{ source.name }}</span>
    </b-button>
    <b-button
      v-if="showAllBtn"
      block
      size="lg"
      :class="['source-change-btn', !chosenSourceId && 'source-change-btn--selected']"
      @click="changeSource(null)"
    >
      <span v-if="!chosenSourceId" class="fa fa-check-square" />
      <span>{{ $t('message.mapping.sourceButtonAllLabel') }}</span>
    </b-button>
  </b-modal>
</template>

<script>
import { menuSourcesMixins } from '@/mixins/menuSourcesMixins'

export default {
  name: 'SourceChangeModal',
  mixins: [menuSourcesMixins],
  props: ['sources', 'chosenSourceId', 'showAllBtn'],
  data() {
    return {}
  },
  computed: {
    isSelected() {
      return (id) => id === parseInt(this.chosenSourceId)
    },
  },
  methods: {
    changeSource(source) {
      this.$emit('changeSource', source.source, source.menu_id)
      this.$bvModal.hide('sourceChangeModal')
    },
  },
}
</script>

<style scoped lang="scss">
.source-change-btn {
  .fa-check-square {
    position: absolute;
    left: 1rem;
  }
}
</style>

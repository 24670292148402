import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { getField, updateField } from 'vuex-map-fields'
import { IMenuState } from '@/types/interface'

const getInitialState = (): IMenuState => {
  return {
    mealsTrees: [],
    mealSets: [],
  }
}

const resetState = (state: IMenuState) => {
  Object.assign(state, getInitialState())
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    ...getters,
    getField,
  },
  mutations: {
    ...mutations,
    updateField,
    resetState,
  },
  actions,
}

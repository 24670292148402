<template>
  <span>
    <b-button :id="id" :size="size" :pill="pill" :variant="variant" :class="buttonClass" @click.stop="clicked">
      <slot></slot>
    </b-button>
    <b-tooltip :show="show || showTooltip" :target="id" :placement="placement" :variant="tooltipVariant" @hide="hide()">
      <slot name="tooltipText"></slot>
    </b-tooltip>
  </span>
</template>

<script>
export default {
  name: 'TooltipButton',
  props: {
    id: String,
    variant: {
      type: String,
      default: 'info',
    },
    placement: {
      type: String,
      default: 'top',
    },
    size: {
      type: String,
      default: 'sm',
    },
    buttonClass: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    tooltipVariant: {
      type: String,
      default: '',
    },
    pill: {
      type: Boolean,
      default: true,
    },
    onButtonClick: {
      type: Function,
    },
  },
  data: () => {
    return {
      showTooltip: false,
    }
  },
  beforeMount() {
    this.showTooltip = this.show
  },
  mounted() {
    if (!this.id) console.error('Missing TooltipButton id')
  },
  methods: {
    hide() {
      this.showTooltip = false
    },
    clicked(){
      if (this.onButtonClick) this.onButtonClick()
      else this.tooltipButtonClicked()
    },
    tooltipButtonClicked() {
      if (document.activeElement.id === this.id && this.showTooltip) {
        document.activeElement.blur()
      }
      this.showTooltip = !this.showTooltip
    },
  },
}
</script>

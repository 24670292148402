import { API } from '@/services/api/api'
import Vue from 'vue'
import { ActionContext } from 'vuex'
import { MappingState } from './index'
import { RootState } from '@/store'
import { IGetMappingListParams, IMappedItem, IMappingItem, IMenuSource, MappingEntryType } from '@/types/interface'
import { showErrorToast } from '@/utils/common'
import { i18n } from '@/utils/i18n'
import { MENU_SOURCE_CHOICES } from '@/common/constants'

const getMappedList = async (
  { state, commit, getters }: ActionContext<MappingState, RootState>,
  { page, name }: { page?: number; name?: string } = {}
) => {
  commit('setLoaders', { mappedList: true })

  let params: IGetMappingListParams
  if (getters.showDefaultMenuPositions) {
    params = {
      brand: state.selectedBrandId,
      menu: getters.getDefaultMenuId,
      menu_source: MENU_SOURCE_CHOICES.DEFAULT,
      integration_menu: getters.getSelectedSourceMenuID,
      is_mapped: state.showIgnoredOnly ? false : state.showMappedOnly,
      page: page || state.mappedListPagination.page,
      name: name || '',
      ignore_mapping: state.showIgnoredOnly,
    }
  } else {
    params = {
      brand: state.selectedBrandId,
      menu: getters.getSelectedSourceMenuID,
      menu_source: getters.selectedSourceId,
      is_mapped: state.showIgnoredOnly ? false : state.showMappedOnly,
      is_external_id: true,
      page: page || state.mappedListPagination.page,
      name: name || '',
      ignore_mapping: state.showIgnoredOnly,
    }
  }
  let res
  switch (state.selectedMappedTypeList) {
    case 'meal': {
      res = await API.getMappingMeals({ params })
      break
    }
    case 'single_choice_entry': {
      res = await API.getMappingSingleChoiceEntries({ params })
      break
    }
    case 'multi_choice_entry': {
      res = await API.getMappingMultiChoiceEntries({ params })
      break
    }
  }
  const { error, data } = res
  if (!error && data) {
    const { count, previous, next, results } = data
    commit('setMappedListPagination', { page: page || state.mappedListPagination.page, count, previous, next })
    commit('setMappedList', results)
  } else {
    showErrorToast(Vue.toasted, error)
  }
  commit('setLoaders', { mappedList: false })
}

const getMappedListForOrder = async ({ state, commit }: ActionContext<MappingState, RootState>) => {
  if (!state.order) return
  commit('setLoaders', { mappedList: true })
  const { error: errorOrderMeals, data: orderMealsData } = await API.getMappingOrderMeals(state.order?.id)
  const { error: errorOrderMealSCE, data: orderMealSingleChoiceEntriesData } =
    await API.getMappingOrderMealSingleChoiceEntries(state.order?.id)
  const { error: errorOrderMealMCE, data: orderMealMultiChoiceEntriesData } =
    await API.getMappingOrderMealMultiChoiceEntries(state.order?.id)
  if (
    !errorOrderMeals &&
    !errorOrderMealSCE &&
    !errorOrderMealMCE &&
    orderMealsData &&
    orderMealSingleChoiceEntriesData &&
    orderMealMultiChoiceEntriesData
  ) {
    const orderMeals = orderMealsData.map((orderMeal: any) => {
      return {
        ...orderMeal,
        single_choice_entries: [],
        multi_choice_entries: [],
      }
    })

    orderMealSingleChoiceEntriesData.forEach((orderMealSCE: any) => {
      const orderMeal = orderMeals.find((orderMeal: any) => orderMeal.id === orderMealSCE.order_meal)
      if (orderMeal) orderMeal.single_choice_entries.push(orderMealSCE)
    })

    orderMealMultiChoiceEntriesData.forEach((orderMealMCE: any) => {
      const orderMeal = orderMeals.find((orderMeal: any) => orderMeal.id === orderMealMCE.order_meal)
      if (orderMeal) orderMeal.multi_choice_entries.push(orderMealMCE)
    })

    commit('setMappedList', orderMeals)
  } else {
    if (errorOrderMeals) showErrorToast(Vue.toasted, errorOrderMeals)
    if (errorOrderMealSCE) showErrorToast(Vue.toasted, errorOrderMealSCE)
    if (errorOrderMealMCE) showErrorToast(Vue.toasted, errorOrderMealMCE)
  }
  commit('setLoaders', { mappedList: false })
}

const getMappingMealsList = async (
  { state, commit, getters }: ActionContext<MappingState, RootState>,
  {
    page,
    sourceMenuId,
    name,
    showMappedPositions,
  }: { page: number; sourceMenuId?: number | null; name?: string; showMappedPositions?: boolean } = { page: 1 }
) => {
  const params: IGetMappingListParams = {
    brand: state.selectedBrandId,
    menu: getters.getDefaultMenuId,
    menu_source: MENU_SOURCE_CHOICES.DEFAULT,
    integration_menu: sourceMenuId || getters.getSelectedSourceMenuID || getters.getOrderSourceMenuID,
    is_mapped: showMappedPositions ? '' : false,
    page,
    name,
    fields: 'name,price,size_name,category_name,vat,id',
  }
  const { error, data } = await API.getMappingMeals({ params })
  if (!error && data) {
    commit('setMappingMealsList', data.results)
  } else {
    showErrorToast(Vue.toasted, error)
  }
  return { error, data }
}

const getMappingEntriesList = async (
  { state, commit, getters }: ActionContext<MappingState, RootState>,
  {
    page,
    entryType,
    sourceMenuId,
    name,
    showMappedPositions,
  }: {
    page: number
    entryType: MappingEntryType
    sourceMenuId?: number | null
    name?: string
    showMappedPositions?: boolean
  }
) => {
  const params = {
    brand: state.selectedBrandId,
    menu: getters.getDefaultMenuId,
    menu_source: MENU_SOURCE_CHOICES.DEFAULT,
    integration_menu: sourceMenuId || getters.getSelectedSourceMenuID || getters.getOrderSourceMenuID,
    is_mapped: showMappedPositions ? '' : false,
    page,
    name,
    fields: 'name,price,group_name,entry_type,id',
  }
  const { error, data } =
    entryType === 'single_choice_entry'
      ? await API.getMappingSingleChoiceEntries({ params })
      : await API.getMappingMultiChoiceEntries({ params })
  if (!error && data) {
    entryType === 'single_choice_entry'
      ? commit('setMappingSingleChoiceEntriesList', data.results)
      : commit('setMappingMultiChoiceEntriesList', data.results)
  } else {
    showErrorToast(Vue.toasted, error)
  }
  return { error, data }
}
const getMapApiCallMethodName = (isOrder: boolean, isEntry: string): string => {
  let apiMethodName = ''
  if (isOrder) {
    if (isEntry) {
      apiMethodName = isEntry === 'single_choice_entry' ? 'mapOrderSingleChoiceEntry' : 'mapOrderMultiChoiceEntry'
    } else apiMethodName = 'mapOrderMeal'
  } else {
    if (isEntry) {
      apiMethodName = isEntry === 'single_choice_entry' ? 'mapSingleChoiceEntry' : 'mapMultiChoiceEntry'
    } else apiMethodName = 'mapMeal'
  }
  return apiMethodName
}
const saveMappedItem = async (
  { commit, state }: ActionContext<MappingState, RootState>,
  { mappedItem }: { mappedItem: IMappedItem }
) => {
  const id = mappedItem.id
  const isEntry: MappingEntryType = mappedItem.entry_type
  // @ts-ignore
  const target: IMappingItem = mappedItem[isEntry ? 'mapped_entry' : 'mapped_meal']
  if (!target) return
  commit('setMappedItemLoading', { mappedItem, value: true })
  const requestData = isEntry
    ? { target_entry_id: target.id, target_entry_type: target.entry_type }
    : { target_meal_id: target.id }
  try {
    const { error, data } = await API[getMapApiCallMethodName(!!state.order, isEntry)](id, requestData)
    commit('setMappedItemLoading', { mappedItem, value: false })
    if (!error) {
      mappedItem = { ...mappedItem, ...data }
      commit('updateMappedList', { mappedItem })
      Vue.toasted.show(i18n.t('message.mapping.mappingSuccess') as string, {
        type: 'success',
        duration: 3000,
      })
    } else {
      showErrorToast(Vue.toasted, error)
    }
  } catch (e) {
    Vue.toasted.error(i18n.t('message.common.errorOccured') as string)
    commit('setMappedItemLoading', { mappedItem, value: false })
  }
}

const getRemoveApiCall = async (entryType: string | null, mappedItemId: number) => {
  switch (entryType) {
    case 'single_choice_entry':
      return await API.removeMappingFromSingleChoice(mappedItemId)
    case 'multi_choice_entry':
      return await API.removeMappingFromMultiChoice(mappedItemId)
    default:
      return await API.removeMappingFromMeal(mappedItemId)
  }
}

const getMenuEntryType = (mappedItem: IMappedItem, isOrder: boolean) => {
  if (isOrder) {
    return mappedItem.single_choice_entry ? 'single_choice_entry' : 'multi_choice_entry'
  } else {
    return mappedItem.entry_type
  }
}

const removeMappingFromItem = async (
  { commit, state }: ActionContext<MappingState, RootState>,
  mappedItem: IMappedItem
) => {
  const isOrder = !!state.order

  let idToRemove = mappedItem.id
  if (mappedItem.meal) {
    idToRemove = mappedItem.meal
  } else if (mappedItem.single_choice_entry) {
    idToRemove = mappedItem.single_choice_entry
  } else if (mappedItem.multi_choice_entry) {
    idToRemove = mappedItem.multi_choice_entry
  }
  commit('setMappedItemLoading', { mappedItem, value: true })
  const menuEntryType = mappedItem.entry_type ? getMenuEntryType(mappedItem, isOrder) : null
  const { error } = await getRemoveApiCall(menuEntryType, idToRemove)
  if (!error) {
    if (mappedItem.mapped_meal) mappedItem.mapped_meal = null
    else if (mappedItem.mapped_entry) mappedItem.mapped_entry = null
    commit('updateMappedList', { mappedItem })
    Vue.toasted.show(i18n.t('message.mapping.remove.success') + mappedItem.name, {
      type: 'success',
      duration: 3000,
    })
  } else {
    showErrorToast(Vue.toasted, error)
  }
  commit('setMappedItemLoading', { mappedItem, value: false })
}

const getCreateElementForOrderMealElementApiMethodName = (entryType: string | null): string => {
  let apiMethodName = ''
  if (entryType) {
    apiMethodName =
      entryType === 'single_choice_entry'
        ? 'createEntryForOrderMealSingleChoiceEntry'
        : 'createEntryForOrderMealMultiChoiceEntry'
  } else apiMethodName = 'createMealForOrderMeal'
  return apiMethodName
}

const getUpdateMenuElementApiMethodName = (menuEntryType: string | null): string => {
  let apiMethodName = ''
  if (menuEntryType) {
    apiMethodName = menuEntryType == 'single_choice_entry' ? 'updateSingleChoiceEntry' : 'updateMultiChoiceEntry'
  } else apiMethodName = 'updateMeal'
  return apiMethodName
}

const setMappedItemElementId = (mappedItem: IMappedItem, data: any): IMappedItem => {
  if (data.entry_type) {
    if (data.entry_type === 'single_choice_entry') {
      mappedItem.single_choice_entry = data.id
    } else {
      mappedItem.multi_choice_entry = data.id
    }
  } else mappedItem.meal = data.id
  return mappedItem
}

const ignoreMenuElement = async (
  { commit, state }: ActionContext<MappingState, RootState>,
  { mappedItem, ignoreMapping }: { mappedItem: IMappedItem; ignoreMapping: boolean }
) => {
  // Ignoring is actually available only for Entries and turned off for Meals

  const orderMealElementId = mappedItem.id
  const entryType: MappingEntryType = mappedItem.entry_type
  const isOrder = !!state.order
  commit('setMappedItemLoading', { mappedItem, value: true })
  try {
    let menuElementId = null
    let error = null
    if (isOrder) {
      if (entryType) menuElementId = mappedItem.single_choice_entry || mappedItem.multi_choice_entry
      else menuElementId = mappedItem.meal
      if (!menuElementId) {
        const { e, data } = await API[getCreateElementForOrderMealElementApiMethodName(entryType)](orderMealElementId)
        if (!e) {
          mappedItem = setMappedItemElementId(mappedItem, data)
          menuElementId = data.id
        } else error = e
      }
    } else {
      menuElementId = mappedItem.id
    }
    if (!error) {
      const menuEntryType = mappedItem.entry_type ? getMenuEntryType(mappedItem, isOrder) : null
      const { e } = await API[getUpdateMenuElementApiMethodName(menuEntryType)](menuElementId, {
        ignore_mapping: ignoreMapping,
      })
      if (!e) {
        mappedItem.ignore_mapping = ignoreMapping
        if (ignoreMapping) {
          if (mappedItem.mapped_meal) mappedItem.mapped_meal = null
          else if (mappedItem.mapped_entry) mappedItem.mapped_entry = null
        }
      } else error = e
    }
    commit('setMappedItemLoading', { mappedItem, value: false })
    if (!error) {
      commit('updateMappedList', { mappedItem })
      Vue.toasted.show(i18n.t('message.mapping.ignoreMappingSuccess') as string, {
        type: 'success',
        duration: 3000,
      })
    } else {
      showErrorToast(Vue.toasted, error)
    }
  } catch (e) {
    Vue.toasted.error(i18n.t('message.common.errorOccured') as string)
    commit('setMappedItemLoading', { mappedItem, value: false })
  }
}

const getMappingBrandMenuIds = (
  menuSources: Array<IMenuSource>,
  brandId: number,
  limitedMenuSources: Array<number>
) => {
  // @ts-ignore
  let brandMenuSources = menuSources[brandId].filter(
    (menuSource: IMenuSource) =>
      // @ts-ignore
      MENU_SOURCE_CHOICES.ERESTAURANT != menuSource.source && MENU_SOURCE_CHOICES.NEW_ERESTAURANT != menuSource.source
  )

  if (limitedMenuSources.length > 0) {
    brandMenuSources = brandMenuSources.filter((menuSource: IMenuSource) =>
      limitedMenuSources.includes(menuSource.source)
    )
  }

  return brandMenuSources.map((menuSource: IMenuSource) => menuSource.menu_id)
}

const getBrandMenuElements = async (
  { dispatch, getters, rootGetters }: ActionContext<MappingState, RootState>,
  { brandId, limitedMenuSources }: { brandId: number; limitedMenuSources: Array<number> }
) => {
  const menuIds = getMappingBrandMenuIds(rootGetters['auth/getMenuSources'], brandId, limitedMenuSources)

  menuIds.forEach((menuId: number) => {
    if (!rootGetters['_menu/mealsTree'](menuId)) dispatch('_menu/getMealsTree', menuId, { root: true })
    if (!getters['singleChoiceGroups'][menuId]) dispatch('getSingleChoiceGroups', menuId)
    if (!getters['multiChoiceGroups'][menuId]) dispatch('getMultiChoiceGroups', menuId)
  })
}

const getSingleChoiceGroups = async ({ commit }: ActionContext<MappingState, RootState>, menuId: number) => {
  const { error, data } = await API.getSingleChoiceGroups(menuId)
  if (!error && data) {
    commit('setSingleChoiceGroups', {
      menuId: menuId,
      singleChoiceGroups: data,
    })
  }
}

const getMultiChoiceGroups = async ({ commit }: ActionContext<MappingState, RootState>, menuId: number) => {
  const { error, data } = await API.getMultiChoiceGroups(menuId)
  if (!error && data) {
    commit('setMultiChoiceGroups', {
      menuId: menuId,
      multiChoiceGroups: data,
    })
  }
}

export default {
  getMappedList,
  getMappedListForOrder,
  getMappingMealsList,
  saveMappedItem,
  getMappingEntriesList,
  removeMappingFromItem,
  ignoreMenuElement,
  getBrandMenuElements,
  getSingleChoiceGroups,
  getMultiChoiceGroups,
}

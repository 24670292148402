import { API } from '@/services/api/api'
import { ILocalization, IAuthState, IAuthCompany, IBrand, IIntegration } from '@/types/interface'

const setAuthToken = (state: IAuthState, authToken: string | null) => {
  state.authToken = authToken
  if (authToken) {
    API.setAuthToken(authToken)
  }
}
const setName = (state: IAuthState, name: string) => {
  state.name = name
}
const setLocalization = (state: IAuthState, localization: ILocalization) => {
  state.localization = localization
}

const setLocalizations = (state: IAuthState, localizations: Array<ILocalization>) => {
  state.localizations = localizations
}

const setLocalizationName = (state: { localization: { name: any } }, localizationName: any) => {
  state.localization.name = localizationName
}

const setLoggedIn = (state: IAuthState, loggedIn: boolean) => {
  state.loggedIn = loggedIn
}

const setUserRole = (state: IAuthState, userRole: string) => {
  state.userRole = userRole
}

const setBrands = (state: IAuthState, brands: Array<IBrand>) => {
  state.brands = brands
}

const setCompanyLocalizations = (state: IAuthState, companyLocalizations: Array<string>) => {
  state.companyLocalizations = companyLocalizations
}

const setMenuSources = (state: IAuthState, menuSources: any) => {
  state.menuSources = menuSources
}

const setCompany = (state: IAuthState, company: IAuthCompany) => {
  state.company = company
}

const setPaymentBackend = (state: IAuthState, paymentBackend: string) => {
  state.paymentBackend = paymentBackend
}

const setSignInRequestStatus = (state: IAuthState, status: string) => {
  state.signInRequestStatus = status
}

export default {
  setAuthToken,
  setName,
  setLocalizations,
  setLocalization,
  setLocalizationName,
  setLoggedIn,
  setUserRole,
  setBrands,
  setMenuSources,
  setCompany,
  setCompanyLocalizations,
  setPaymentBackend,
  setSignInRequestStatus,
}

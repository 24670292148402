<template>
  <div class="edit-section">
    <label for="categoryName"> {{ $t('message.menu.categories.name') }} <span class="text-danger ml-1">*</span> </label>
    <b-form-input
      id="categoryName"
      v-validate="validateName"
      :value="value"
      class="editName"
      name="categoryName"
      :disabled="saving"
      @input="$emit('input', $event)"
      @blur="veeFields.categoryName.changed ? $emit('blur') : null"
    />
    <input-error-msg field="categoryName" :_errors="errors" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const STORE = '_menuEdit'

export default {
  name: 'NameCategory',
  inject: ['$validator'],
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(STORE, ['saving']),
    validateName() {
      return {
        required: true,
        min: 1,
      }
    },
  },
}
</script>

import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { User } from '@/types/interface'

export interface OrdersSummaryState {
  employees: Array<User>
  employeesRequest: string
}

const state: OrdersSummaryState = {
  employees: [],
  employeesRequest: '',
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

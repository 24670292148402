import { mapActions } from 'vuex'
export const settingsMixins = {
  data() {
    return {
      validationErrors: {},
    }
  },
  methods: {
    ...mapActions('settings', ['sendPreferences']),
    setTimeFilters() {
      return {
        1: {
          startTime: '',
          endTime: '',
        },
        2: {
          startTime: '',
          endTime: '',
        },
        3: {
          startTime: '',
          endTime: '',
        },
        4: {
          startTime: '',
          endTime: '',
        },
        5: {
          startTime: '',
          endTime: '',
        },
        6: {
          startTime: '',
          endTime: '',
        },
        7: {
          startTime: '',
          endTime: '',
        },
      }
    },
    setValidationErrors(value) {
      this.validationErrors[value.tableName] = value.errors
    },
    anyValidationErrors() {
      return Object.values(this.validationErrors).some((error) => {
        return error === true
      })
    },
    validate() {
      // If not implemented, then no validation is set
    },
    save() {
      console.error('save not implemented')
    },
    validateAndSave() {
      this.validate()
      if (!this.anyValidationErrors()) {
        this.save()
      } else {
        this.$toasted.show('Błąd danych. Sprawdź brakujące pozycje.', {
          type: 'error',
          icon: {
            name: 'times',
          },
          duration: 3000,
        })
      }
    },
    async savePreferences(settingsType, payload) {
      const { error } = await this.sendPreferences({
        settingsType,
        formData: payload.formData,
        onSuccess: (responseData) => {
          this.$toasted.show(this.$t('message.administration.settingsSaved'), {
            type: 'success',
            icon: {
              name: 'check',
            },
            duration: 3000,
          })
          payload.onSuccess?.(responseData)
        },
        onError: (error) => {
          const msg = error?.response?.data?.non_field_errors
          this.$toasted.show(msg || 'Błąd przesyłania danych. Sprawdź wszystkie pola.', {
            type: 'error',
            icon: {
              name: 'data',
            },
            duration: 3000,
          })
          payload.onError?.(error)
        },
      })
      if (error) {
        payload.onError?.(error)
      }
    },
  },
}

import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { IRecommendation } from '@/types/interface'

export interface IRecommendationsState {
  recommendation: IRecommendation | null
}

const getInitialState = (): IRecommendationsState => {
  return {
    recommendation: null,
  }
}

const resetState = (state: IRecommendationsState) => {
  Object.assign(state, getInitialState())
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations: {
    ...mutations,
    resetState,
  },
  actions,
}

<template>
  <transition :name="noTransition ? '' : 'fade'">
    <div :class="['loader', { 'loader--transparent': transparent }]">
      <b-spinner label="Loading..." />
      <p v-if="text" class="loader__text">
        {{ text }}
      </p>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    transparent: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    noTransition: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss" scoped>
.loader {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: absolute;
  z-index: 100;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.65);

  &--transparent {
    background-color: transparent;
  }

  &__text {
    display: block;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }
}
</style>

<template>
  <div class="h-100">
    <b-row class="h-100">
      <template v-if="!pickTime">
        <b-col cols="12" :lg="modal ? '' : 8" class="mb-2">
          <b-button-group class="btn-group-justified btn-group-toggle w-100 h-100 text-center">
            <div
              v-for="promisedTimeValue in promisedTimes"
              :id="'promised-time-' + promisedTimeValue.value + '-btn'"
              :key="'promisedTime' + promisedTimeValue.value"
              class="timeTile"
            >
              <span v-if="promisedTimeValue.predicted" class="recommendedTimeTooltipButtonContainer">
                <tooltip-button id="recommendedTimeTooltip" size="lg" button-class="aiPredictionTooltipButton">
                  <i class="far fa-lightbulb"></i>
                  <template #tooltipText>{{ $t('message.promisedTime.recommendedByAi') }}</template>
                </tooltip-button>
              </span>
              <b-button
                id="change-promised-time-value-btn"
                variant="light"
                size="lg"
                class="h-100 w-100"
                :class="{ active: promisedTimeValue.value == value, recommended: promisedTimeValue.predicted }"
                :value="promisedTimeValue.value"
                :disabled="isEdit"
                @click="changePromisedTimeValue(promisedTimeValue.value)"
              >
                {{ promisedTimeValue.name }}
              </b-button>
            </div>
          </b-button-group>
        </b-col>
        <b-col cols="6" :lg="modal ? '' : 2" class="mb-2">
          <b-input-group append="min" class="h-100">
            <b-form-input
              id="change-promised-time-value"
              class="form-control h-100"
              number
              type="number"
              :value="value"
              :placeholder="$t('message.promisedTime.ownPromisedTime')"
              @change="changePromisedTimeValue"
            />
          </b-input-group>
        </b-col>
        <b-col v-if="customPromisedTime" cols="6" :lg="modal ? '' : 2" class="mb-2">
          <div class="promised-time__value">
            {{ customPromisedTime }}
          </div>
        </b-col>
      </template>
      <template v-else>
        <b-col :cols="orderDetails ? 6 : ''" style="align-items: center">
          <vue-ctk-date-time-picker
            v-model="promisedTime"
            time-format="YYYY-MM-DD HH:mm"
            :format="POS_API_DATETIME_INPUT_FORMAT"
            :formatted="POS_API_DATETIME_INPUT_FORMAT"
            :minute-interval="pickerInterval"
            :label="$t('message.orderFormFull.promisedTime')"
            name="customerPromisedTime"
            no-button-now
            no-clear-button
            @input="changePromisedTimeDate()"
            @is-hidden="checkPromisedTime()"
            @is-shown="pickerShown()"
          />
        </b-col>
      </template>
      <b-col v-if="isFuturePromisedTime" cols="1" class="pl-1 pr-0" style="align-self: center">
        <tooltip-button id="recommendedTimeTooltip" button-class="defaultTooltipButton" class="ml-3"
          >?
          <template #tooltipText>{{ $t('message.promisedTime.futurePromisedTimeInfo') }}</template>
        </tooltip-button>
      </b-col>
    </b-row>
    <future-date-confirm-modal @cancel="sendMessageToParent" @ok="futureDateConfirmed()"> </future-date-confirm-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DELIVERY_TYPES, POS_API_DATETIME_INPUT_FORMAT, COMMON_DATETIME_INPUT_FORMAT } from '../common/constants'
import TooltipButton from './TooltipButton'
import FutureDateConfirmModal from '@/components/FutureDateConfirmModal'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

export default {
  name: 'PromisedTime',
  components: { TooltipButton, VueCtkDateTimePicker, FutureDateConfirmModal },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    defaultTime: {
      type: String,
      default: '',
    },
    pickTime: {
      type: Boolean,
      default: false,
    },
    customPromisedTime: {
      type: String,
      default: '',
    },
    orderDetails: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    estimatedDeliveryTime: {
      type: Number,
      default: NaN,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      COMMON_DATETIME_INPUT_FORMAT: COMMON_DATETIME_INPUT_FORMAT,
      POS_API_DATETIME_INPUT_FORMAT: POS_API_DATETIME_INPUT_FORMAT,
      DELIVERY_TYPES: DELIVERY_TYPES,
      value: this.defaultTime ? this.defaultTime : '',
      initialValue: null,
      promisedTime: '',
      initialPromisedTime: null,
    }
  },
  computed: {
    promisedTimes() {
      let promisedTimes = [
        { name: 15, value: 15 },
        { name: 30, value: 30 },
        { name: 45, value: 45 },
        { name: 60, value: 60 },
        { name: 75, value: 75 },
        { name: 90, value: 90 },
        { name: 120, value: 120 },
      ]
      if (this.small) {
        promisedTimes.pop()
      }

      if (this.$hasModuleAccess('show-promised-time-prediction') && this.estimatedDeliveryTime) {
        // find existing promisedTime value
        const existingPromisedTimeValue = promisedTimes.find((pt) => pt.value === this.estimatedDeliveryTime)
        // or add new promisedTime value
        if (!existingPromisedTimeValue) {
          promisedTimes.push({
            name: this.estimatedDeliveryTime,
            value: this.estimatedDeliveryTime,
            predicted: true,
          })
          promisedTimes = _.sortBy(promisedTimes, 'value')
        }
      }
      return promisedTimes
    },
    pickerInterval() {
      return this.orderDetails ? 15 : this.isEdit ? 1 : 5
    },
    isFuturePromisedTime() {
      return this.$moment(this.promisedTime, POS_API_DATETIME_INPUT_FORMAT).isAfter(this.$moment().add(24, 'hours'))
    },
  },
  watch: {
    customPromisedTime: function (newValue) {
      this.promisedTime = newValue
    },
    estimatedDeliveryTime: function (newValue) {
      if (this.$hasModuleAccess('show-promised-time-prediction') && newValue) this.changePromisedTimeValue(newValue)
    },
  },
  mounted() {
    this.promisedTime = this.customPromisedTime
    // to make default value work
    this.sendMessageToParent()
  },
  methods: {
    changePromisedTimeValue(promisedTimeValue = null) {
      // set promisedTimeValue in parent and popup modal if more than 12h between dt and now
      this.initialValue = this.value
      this.value = parseInt(promisedTimeValue)
      this.sendMessageToParent()
      if (this.value > 720 && this.initialValue !== this.value) {
        this.$eventBus.$emit('showFutureDateConfirmModal', {
          value: this.$moment().add(this.value, 'minutes').format(POS_API_DATETIME_INPUT_FORMAT),
          initialValue: this.initialValue,
          dateType: this.$t('message.futureDateConfirmModal.promisedTime'),
        })
      }
    },
    changePromisedTimeDate() {
      // set promisedTime dt in parent and popup modal
      this.sendMessageToParent()
      this.initialPromisedTime = this.customPromisedTime
    },
    pickerShown() {
      this.$emit('pickerShown', true)
    },
    checkPromisedTime() {
      // if more than 12h difference between dt and now - popup message
      let initial = this.initialPromisedTime || this.promisedTime
      if (
        this.$moment(this.promisedTime, POS_API_DATETIME_INPUT_FORMAT).isAfter(this.$moment().add(12, 'hours')) &&
        initial !== this.promisedTime
      ) {
        this.$eventBus.$emit('showFutureDateConfirmModal', {
          value: this.promisedTime,
          initialValue: initial,
          dateType: this.$t('message.futureDateConfirmModal.promisedTime'),
        })
      } else this.initialPromisedTime = this.promisedTime
      this.$emit('pickerShown', false)
    },
    futureDateConfirmed() {
      this.initialPromisedTime = null
    },
    sendMessageToParent(value = null) {
      // if user canceled on futureDateConfirmModal, then value is filled and will set old value in parent
      if (value) {
        if (this.pickTime) this.promisedTime = value
        else {
          this.value = value
          this.initialValue = null
        }
        this.$emit('input', value)
        // send new value of minutes/dt to parent
      } else this.$emit('input', this.pickTime ? this.promisedTime : this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.recommended {
  background-color: $cyan;
  border: 2px dashed $cyan-dark !important;
  color: white;
}
.timeTile {
  position: relative;
  width: 100%;
  height: 100%;

  .btn:focus {
    position: relative;
  }

  &:first-child {
    > .btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.timeTile + .timeTile {
  > .btn {
    border-left: none;
  }

  &:not(:last-child) {
    > .btn {
      border-radius: 0;
    }
  }

  &:last-child {
    > .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.recommendedTimeTooltipButtonContainer {
  position: absolute;
  bottom: 101%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  z-index: 100;
}
.promised-time__value {
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 18px;
  height: 100%;
  border: 1px dashed #e0e0e0;
  border-radius: 3px;
  background: #fdfdfd;
  @include media-breakpoint-up(md) {
    font-size: 14px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 18px;
  }
}
@include media-breakpoint-down(sm) {
  .timeTile {
    .btn {
      padding: 1rem 0.8rem;
    }
  }
} ;
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card orders-filters-container",attrs:{"id":"orders-filters"}},[(_vm.createdByPosAppsmart)?_c('div',{staticClass:"orders-filters orders-filters-whitelabel"},[_c('SearchOrders',{attrs:{"orders":_vm.orders},on:{"searching":_vm.searchOrder}}),_c('div',{staticClass:"d-flex filters-buttons"},[_c('b-button-group',[_vm._l((_vm.getOrderSmartStatusFilters()),function(button){return _c('FilterButton',{key:button.value,attrs:{"is-active":_vm.ordersListFilters[button.filterType] === button.value,"text":button.text,"count":_vm.countOrdersByStatus(button.value),"on-click":() => {
              _vm.$emit('setFilter', { filter: button.filterType, value: button.value })
            }}})}),_c('FilterButton',{attrs:{"is-active":_vm.isFilterFuture,"on-click":() => {
              _vm.$emit('setFilter', { filter: 'future', value: true })
            },"text":`(>24h)`,"count":_vm.countFutureOrders()}}),_c('FilterButton',{attrs:{"is-active":_vm.noFilters,"on-click":_vm._clearFilters,"text":_vm.$t('message.ordersList.all'),"count":_vm.orders.length}})],2),_c('order-list-filters-toggle-button',{attrs:{"primary-filters-count":_vm.primaryFiltersCount,"extra-filters":_vm.extraFilters},on:{"toggleExtraFilters":function($event){_vm.extraFilters = !_vm.extraFilters}}})],1)],1):_c('div',{class:['orders-filters', { 'orders-filters-papu3': _vm.isPapu3 }]},[_c('SearchOrders',{attrs:{"orders":_vm.orders},on:{"searching":_vm.searchOrder}}),(!_vm.isPapu3)?_c('div',{staticClass:"d-flex filters-buttons filters-by-type"},[_c('b-button-group',_vm._l((_vm.filterByTypeButtons),function(button){return _c('FilterButton',{key:button.value,attrs:{"is-active":_vm.ordersListFilters[button.filterType] === button.value,"is-unaccepted":_vm.anyUnacceptedOrders(button.value),"text":button.text,"icon":button.icon,"count":_vm.countOrdersByPickupType(button.value),"on-click":() => {
              _vm.$emit('setFilter', { filter: button.filterType, value: button.value })
            }}})}),1),_c('order-list-filters-toggle-button',{staticClass:"toggle-filters-button-mobile",attrs:{"primary-filters-count":_vm.primaryFiltersCount,"extra-filters":_vm.extraFilters},on:{"toggleExtraFilters":function($event){_vm.extraFilters = !_vm.extraFilters}}})],1):_vm._e(),_c('div',{staticClass:"d-flex filters-buttons filters-by-state"},[_c('b-button-group',[_vm._l((_vm.filterByStatusButtons),function(button){return _c('FilterButton',{key:button.value,attrs:{"is-active":_vm.ordersListFilters[button.filterType] === button.value,"is-unaccepted":_vm.anyUnacceptedOrders(button.value),"text":button.text,"count":_vm.countOrdersByStatus(button.value),"on-click":() => {
              _vm.$emit('setFilter', { filter: button.filterType, value: button.value })
            }}})}),(!_vm.isPapu3)?_c('FilterButton',{attrs:{"is-active":_vm.isFilterFuture,"on-click":() => {
              _vm.$emit('setFilter', { filter: 'future', value: true })
            },"text":`(>24h)`,"count":_vm.countFutureOrders()}}):_vm._e(),(!_vm.isPapu3)?_c('FilterButton',{attrs:{"is-active":_vm.noFilters,"on-click":_vm._clearFilters,"text":_vm.$t('message.ordersList.all'),"count":_vm.orders.length}}):_vm._e()],2),_c('order-list-filters-toggle-button',{staticClass:"toggle-filters-button",attrs:{"primary-filters-count":_vm.primaryFiltersCount,"extra-filters":_vm.extraFilters},on:{"toggleExtraFilters":function($event){_vm.extraFilters = !_vm.extraFilters}}})],1)],1),(_vm.extraFilters)?_c('div',{attrs:{"id":"orders-extra-filters"}},[_c('div',{staticClass:"d-flex extra-filters"},[_c('b-dropdown',{attrs:{"text":`${_vm.$t('message.ordersList.restaurant')}: ${_vm.getFilterBrandName}`,"variant":"outline-primary"}},[_c('b-dropdown-item-btn',{attrs:{"active":!_vm.primaryFilters.brand},on:{"click":function($event){return _vm.$emit('setPrimaryFilter', { filter: 'brand', value: null })}}},[_vm._v(" "+_vm._s(_vm.$t('message.ordersList.restaurant'))+": "+_vm._s(_vm.$t('message.ordersList.all'))+" ")]),_vm._l((_vm.brands),function(brand,brandId,index){return _c('b-dropdown-item-btn',{key:brandId,attrs:{"value":brandId,"active":_vm.primaryFilters.brand == brandId},on:{"click":function($event){return _vm.$emit('setPrimaryFilter', { filter: 'brand', value: brandId })}}},[_vm._v(" "+_vm._s(_vm.$t('message.ordersList.restaurant'))+": "+_vm._s(brand.name)+" "),(brand.only_online)?_c('small',[_vm._v("(online)")]):_vm._e()])})],2)],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <h3 class="h3 mb-3">
      {{ $t('message.menu.sizes.title') }}
      <b-badge v-if="!loadingList" variant="primary" pill class="ml-2" style="vertical-align: middle">
        {{ sizes.length }}
      </b-badge>
    </h3>
    <template v-if="categories.length === 0">
      {{ $t('message.menu.noCategories') }}
    </template>
    <b-overlay v-else :show="loadingList" spinner-variant="primary" spinner-small>
      <b-card
        v-for="(category, categoryIndex) in groupedCategories"
        :key="category.id"
        title-tag="h6"
        body-class="p-1"
        class="categorySizeGroup"
      >
        <b-card-body class="p-2">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <span class="font-weight-bold">{{ category.name }}</span>
            <b-button variant="primary" size="sm" :disabled="saving" @click="addNewSize(category, categoryIndex)">
              <i class="fas fa-plus-square mr-2" aria-hidden="true" />
              {{ $t('message.menu.sizes.add') }}
            </b-button>
          </div>

          <draggable
            v-if="category.sizes"
            v-model="category.sizes"
            v-bind="dragOptions"
            :group="`sizes_${category.id}`"
            class="list-group custom-list-group"
            @change="updateSizesPositions($event, categoryIndex)"
          >
            <b-list-group-item
              v-for="(size, index) in category.sizes"
              :key="index"
              :active="selectedSizeId === index && chosenCategory === categoryIndex"
              class="custom-list-group-item custom-list-group-item-slim list-group-item--draggable"
              @click="editSize(size, category, categoryIndex, index)"
            >
              <div class="d-flex align-items-center">
                <i
                  class="fas fa-align-justify fa-fw mr-1"
                  :class="selectedSizeId === index && chosenCategory === categoryIndex ? 'text-white' : 'drag-icon'"
                ></i>
                <span>{{ size.name === 'default' ? $t('message.menu.defaultSizeName') : size.name }}</span>
              </div>
              <div class="d-flex align-items-center">
                <i
                  v-if="size.is_default"
                  class="fas fa-check-circle fa-fw"
                  :class="selectedSizeId === index && chosenCategory === categoryIndex ? 'text-white' : 'text-success'"
                ></i>
                <TooltipButton :id="'category_' + categoryIndex + '_size_' + index" variant="link">
                  <b-badge
                    :variant="selectedSizeId === index && chosenCategory === categoryIndex ? 'light' : 'secondary'"
                    pill
                    >{{ size.meals && size.meals.length }}</b-badge
                  >
                  <template #tooltipText>{{ $t('message.menu.sizeMealsCount') }}</template>
                </TooltipButton>
              </div>
            </b-list-group-item>
          </draggable>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import TooltipButton from '@/components/TooltipButton'
import draggable from 'vuedraggable'
import { mapFields } from 'vuex-map-fields'
const STORE = '_menuEdit'

export default {
  name: 'SizesList',
  components: { TooltipButton, draggable },
  data() {
    return {
      loadingList: false,
      selectedSizeId: null,
      categoryIndex: null,
    }
  },
  computed: {
    ...mapGetters(STORE, ['categories', 'form', 'saving', 'getActiveMenuId', 'menuElement']),
    ...mapFields(STORE, ['sizes']),
    ...mapGetters(['isMobile']),
    groupedCategories() {
      this.categories.forEach((cat) => (cat.sizes = []))
      return this.sizes.reduce((categories, size) => {
        categories.find((cat) => {
          if (size.category === cat.id) {
            cat.sizes.push(size)
          }
        })
        return categories
      }, this.categories)
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
        handle: this.isMobile ? '.drag-icon' : '.custom-list-group-item',
      }
    },
    chosenCategory() {
      const formSize = this.getFormSize
      return formSize?.categoryIndex > -1 ? formSize.categoryIndex : null
    },
  },
  mounted() {
    this.refreshList()
  },
  methods: {
    ...mapMutations(STORE, ['setForm', 'setIsEdited']),
    ...mapActions(STORE, ['getCategories', 'getSizes', 'getSize', 'addSize', 'updateElementsPositions']),
    async refreshList() {
      this.loadingList = true
      if (!this.categories) {
        await this.getCategories()
      }
      await this.getSizes()
      this.loadingList = false
    },
    async editSize(size) {
      this.selectedSizeId = size.id
      await this.getSize(size.id)
    },
    addNewSize(category, categoryIndex) {
      this.selectedSizeId = null
      this.addSize({ category, categoryIndex })
    },
    async updateSizesPositions($event, categoryIndex) {
      let requestData = this.groupedCategories[categoryIndex].sizes.map((size, index) => ({
        id: size.id,
        position: index + 1,
      }))
      requestData = { menu_id: this.getActiveMenuId, positions: requestData }
      const { error } = await this.updateElementsPositions({ requestData, apiCall: 'updateSizesPositions' })
      if (error) {
        await this.getSizes()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.categorySizeGroup {
  margin-bottom: 0.5em;
}
</style>

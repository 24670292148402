<template>
  <div>
    <input-error-msg
      v-for="(fieldName, index) in fields"
      :key="`error_message_${index}`"
      :field="fieldName"
      :_errors="currentErrors"
    />
  </div>
</template>

<script>
export default {
  name: 'MultiErrorMessage',
  props: {
    fields: {
      type: Array,
      required: true,
    },
    currentErrors: {
      type: Object,
      default: () => ({
        items: [],
      }),
    },
  },
}
</script>

<template>
  <b-modal
    ref="modal"
    :title="title"
    :visible="visible"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    @keyup.enter.native="save"
  >
    <b-container>
      <div class="form-group">
        <label for="amount">{{ $t('message.billing.amount') }}</label>
        <input id="amount" v-model.number="form.amount" type="number" class="form-control" @keyup.enter.stop="save" />
      </div>
      <input-error-msg field="amount" :_errors="errors" :response-errors="responseErrors" />
      <div class="form-group">
        <label for="comment">{{ $t('message.billing.comment') }}<span class="text-danger ml-1">*</span></label>
        <textarea
          id="comment"
          v-model="form.comment"
          v-validate="'required'"
          class="form-control"
          name="comment"
          rows="3"
          @keyup.enter.stop
        ></textarea>
        <input-error-msg field="comment" :_errors="errors" :response-errors="responseErrors" />
      </div>
      <response-error-msg field="non_field_errors" :response-errors="responseErrors" />
      <response-error-msg field="billing_period_id" :response-errors="responseErrors" />
    </b-container>
    <div slot="modal-footer">
      <button type="button" class="btn btn-secondary" @click="hide">{{ $t('message.billing.cancel') }}</button>
      <button type="button" class="btn btn-success" @click="save">
        <kbd class="pb-0 pt-1 mr-2 bg-white text-dark">&crarr;</kbd> {{ $t('message.billing.save') }}
      </button>
    </div>
    <!--<h5 class="modal-title" v-if="formData.type == 1">{{ $t('message.billing.addAdvance') }}</h5>-->
    <!--<h5 class="modal-title" v-if="formData.type == 2">{{ $t('message.billing.addPenalty') }}</h5>-->
    <!--<h5 class="modal-title" v-if="formData.type == 3">{{ $t('message.billing.addBonus') }}</h5>-->
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import { responseErrorsHandler } from '@/mixins'

export default {
  name: 'BillingPeriodEntryModal',
  mixins: [responseErrorsHandler],
  data() {
    return {
      visible: false,
      user: {},
      entryType: '',
      form: {},
    }
  },
  computed: {
    title() {
      switch (this.entryType) {
        case 1: {
          return this.$t('message.billing.addAdvance')
        }
        case 2: {
          return this.$t('message.billing.addPenalty')
        }
        case 3: {
          return this.$t('message.billing.addBonus')
        }
      }
    },
  },
  methods: {
    ...mapActions({
      createBillingPeriodEntry: '_currentBillingPeriods/createBillingPeriodEntry',
    }),
    resetForm() {
      this.form = {
        billing_period_id: '',
        amount: '',
        comment: '',
        type: '',
      }
    },
    show() {
      this.visible = true
    },
    hide() {
      this.resetForm()
      this.visible = false
    },
    save() {
      this.$validator.validate().then((result) => {
        if (result) {
          const request = {
            form: this.form,
            onSuccess: () => {
              this.hide()
              this.clearResponseErrors({})
            },
            onError: (errors) => {
              this.setResponseErrors(errors)
            },
          }
          this.createBillingPeriodEntry(request)
        }
      })
    },
    assignFormData() {
      this.resetForm()
      this.form = {
        ...this.form,
        billing_period_id: this.user.last_billing_period.id,
        type: this.entryType,
      }
    },
  },
  mounted() {
    this.$eventBus.$on('onShowBillingPeriodEntryModal', (payload) => {
      const { user, entryType } = payload
      this.user = user
      this.entryType = entryType
      this.assignFormData()
      this.show()
    })
  },
  destroyed() {
    this.$eventBus.$off('onShowBillingPeriodEntryModal')
  },
}
</script>

<template>
  <b-modal
    ref="modal"
    modal-class="onsite-modal"
    dialog-class="onsite-dialog"
    content-class="onsite-content"
    body-class="onsite-body"
    :title="$t('message.cart.title')"
    header-class="py-1"
    :visible="visible"
    no-close-on-backdrop
    @hidden="hide"
  >
    <b-container fluid>
      <b-row>
        <b-col class="mb-3">
          <b-form-group :label="$t('message.cart.releaseType')" class="mb-0">
            <b-form-radio-group
              id="btnradios1"
              v-model="form.pickup_type"
              buttons
              button-variant="outline-primary"
              size="lg"
              :options="pickupTypes"
              name="radiosBtnDefault"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="form.pickup_type === PICKUP_TYPE_ON_SITE" cols="12" :md="5" class="mb-3">
          <b-row>
            <b-col>
              <b-form-group :label="$t('message.cart.tableNumber')" class="mb-0">
                <b-form-radio-group
                  id="btnradios1"
                  v-model="form.table"
                  buttons
                  button-variant="outline-primary"
                  size="lg"
                  :options="tables"
                  name="radiosBtnDefault"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group :label="$t('message.cart.differentTableNumber')" class="mb-0">
                <b-form-input id="table-number-input" v-model="form.table" type="number" size="lg" @keyup.enter.stop />
              </b-form-group>
              <response-error-msg field="table" :response-errors="responseErrors" />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" :md="3" class="mb-3">
          <b-form-group :label="$t('message.orderFormFull.orderSource')" class="mb-0">
            <source-change
              v-model="form.menu_source"
              :change-menu-source="changeMenuSource"
              :response-errors="responseErrors"
              :no-label="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="8">
          <b-form-group :label="$t('message.orderFormFull.promisedTime')">
            <PromisedTime
              v-model="promisedTimeValue"
              small
              default-time="15"
              :estimated-delivery-time="estimatedDeliveryTime"
              :modal="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="hasFiscal" cols="12" :md="4">
          <b-form-group :label="$t('message.orderFormFull.customerTaxID')" class="mr-3 mb-0">
            <b-form-input
              id="company-tax-id-input"
              v-model="form.company_tax_id"
              type="text"
              class="mb-3"
              @input="customerTaxIDformatter('form.company_tax_id')"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-row class="h-100">
            <b-col cols="12" class="m-auto d-flex justify-content-around">
              <b-form-checkbox
                v-if="hasFiscal"
                v-model="printBill"
                button
                name="printBill"
                button-variant="outline-primary"
                size="lg"
              >
                <i v-if="!printBill" class="far fa-square"></i>
                <i v-else class="fas fa-check-square"></i>
                <span>{{ $t('message.cart.printBill') }}</span>
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.close_order"
                button
                name="closeOrder"
                button-variant="outline-success"
                size="lg"
              >
                <i v-if="!form.close_order" class="far fa-square"></i>
                <i v-else class="fas fa-check-square"></i>
                <span>{{ $t('message.cart.closeOrder') }}</span>
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <AdditionalFeesForm />
      <template v-if="form.close_order || printBill">
        <hr class="mt-1 mb-1" />
        <b-row class="my-3">
          <b-col cols="12" :md="form.payment === 'cash' ? 4 : 12">
            <b-form-group>
              <template #label
                >{{ $t('message.cart.paymentMethod') }}
                <span class="text-danger ml-1">*</span>
              </template>
              <b-form-radio-group
                id="btnradios1"
                v-model="form.payment"
                buttons
                button-variant="outline-primary"
                size="lg"
                :options="paymentTypes"
                name="radiosBtnDefault"
              />
            </b-form-group>
            <div>
              <response-error-msg field="payment" :response-errors="responseErrors" />
            </div>
          </b-col>
          <b-col v-if="form.payment === 'cash'" class="mb-2">
            <CashCalculator v-model="form.cash" :total-price="orderTotalPrice" />
          </b-col>
        </b-row>
      </template>
      <response-error-msg field="non_field_errors" :response-errors="responseErrors" />
      <response-error-msg field="promised_time" :response-errors="responseErrors" />
      <response-error-msg field="price" :response-errors="responseErrors" />
    </b-container>
    <div slot="modal-footer" class="d-flex w-100">
      <div class="full-price">
        <span class="full-price__title">{{ $t('message.mealDetails.totalPrice') }}:</span>
        <span class="full-price__value">{{ orderTotalPrice }} {{ currency }}</span>
      </div>
      <b-button
        v-shortkey="!submittedButtonDisabled ? ['enter'] : ['null']"
        variant="success"
        size="lg"
        block
        :disabled="submittedButtonDisabled"
        @click="placeOrder"
        @shortkey="placeOrder"
      >
        <kbd class="pb-0 pt-1 mr-2 bg-white text-dark">&crarr;</kbd>
        {{ $t('message.orderFormFull.placeOrder') }}
        <b-spinner v-if="editOrderRequestStatus === 'loading'" class="ml-4" small />
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { MENU_SOURCE_CHOICES, PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT, DELIVERY_TYPES } from '@/common/constants'
import { mapGetters, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { printBillMixins, responseErrorsHandler, taxIdMixins } from '@/mixins'
import store from '@/store'
import PromisedTime from '@/components/PromisedTime'
import SourceChange from '@/components/source-change/SourceChange'
import CashCalculator from '@/components/CashCalculator'
import AdditionalFeesForm from '@/components/order-form/AdditionalFeesForm'

function initData() {
  return {
    visible: false,
    form: {
      pickup_type: PICKUP_TYPE_ON_SITE,
      table: 1,
      payment: [],
      close_order: store.getters['settings/localizationPreferences']
        ? store.getters['settings/localizationPreferences'].order_onsite_finish_order
        : true,
      cash: '',
      company_tax_id: '',
      menu_source:
        store.getters['settings/localizationPreferences'] &&
        store.getters['settings/localizationPreferences'].menu_source_manual_choice_required
          ? null
          : MENU_SOURCE_CHOICES.DEFAULT,
    },
  }
}

export default {
  name: 'OrderOnSiteModal',
  components: {
    SourceChange,
    PromisedTime,
    CashCalculator,
    AdditionalFeesForm,
  },
  mixins: [responseErrorsHandler, printBillMixins, taxIdMixins],
  data() {
    return Object.assign(initData(), {
      submittedButtonDisabled: false,
      PICKUP_TYPE_ON_SITE: PICKUP_TYPE_ON_SITE,
      pickupTypes: [
        { text: this.$t('message.orderFormFull.pickupTypes.onSite'), value: PICKUP_TYPE_ON_SITE },
        { text: this.$t('message.orderFormFull.pickupTypes.takeaway'), value: PICKUP_TYPE_TAKOUT },
      ],
      paymentTypes: [
        { text: this.$t('message.orderFormFull.paymentTypes.cash'), value: 'cash' },
        { text: this.$t('message.orderFormFull.paymentTypes.card'), value: 'card' },
      ],
      tables: [
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
      ],
      promisedTimeValue: '',
    })
  },
  computed: {
    ...mapGetters('_orderEdit', [
      'cart',
      'orderTotalPrice',
      'orderTotalPrice',
      'editOrderRequestStatus',
      'getCartDiscounts',
      'getServiceChargePercent',
    ]),
    ...mapGetters(['currency', 'menu']),
    ...mapFields('_orderEdit', {
      estimatedDeliveryTime: 'estimatedDeliveryTime',
    }),
    calcRest() {
      return (this.form.cash - parseFloat(this.orderTotalPrice)).toFixed(2)
    },
  },
  watch: {
    promisedTimeValue: function () {
      this.setPromisedTime(this.promisedTimeValue)
    },
  },
  mounted() {
    this.$eventBus.$on('onShowOrderOnSiteModal', (payload = {}) => {
      this.show()
    })
  },
  destroyed() {
    this.$eventBus.$off('onShowOrderOnSiteModal')
  },
  methods: {
    ...mapMutations({
      setPromisedTime: '_orderEdit/setPromisedTime',
      setDelayOrdersListRefresh: 'setDelayOrdersListRefresh',
    }),
    show() {
      this.visible = true
    },
    hide() {
      let initdata = initData()
      this.visible = initdata.visible
      this.form.pickup_type = initdata.form.pickup_type
      this.form.table = initdata.form.table
      this.form.payment = initdata.form.payment
      this.printBill = this.defaultOnsitePrintBill
      this.form.close_order = initdata.form.close_order
      this.form.cash = initdata.form.cash
      this.form.menu_source = initdata.menu_source
    },
    changeMenuSource(source) {
      this.form.menu_source = source
    },
    getOrderData() {
      const cart = this.$store.getters['_orderEdit/cart']
      if (!this.form.close_order && !this.printBill) {
        this.form.payment = []
      }
      const { discountChoice, discountValue } = this.getCartDiscounts
      let data = {
        ...this.form,
        menu: this.menu.id,
        price: this.orderTotalPrice,
        meals_json: cart.meals,
        comment: cart.comment,
        promised_time: cart.customer.promisedTime,
        print_bill: this.printBill,
        discount_choice: discountChoice,
        discount_value: discountValue,
        service_charge_percent: this.getServiceChargePercent,
        service_charge_value: cart.serviceChargeValue,
        package_price: cart.packagePrice ? this.priceDecimal(cart.packagePrice) : '0.00',
        discount_code: cart.discountCodeApplied && cart.discountCode ? cart.discountCode : null,
        delivery_type: DELIVERY_TYPES.ASAP,
      }
      // Close order only if print_bill not checked or fiscal type K2 or PAPU
      if (data['close_order'] && (!this.printBill || this.isBackendPrinting())) {
        data['status'] = 'finished'
        data['finished_type'] = 'finished'
      } else {
        data['status'] = 'accepted'
      }
      return data
    },
    placeOrder() {
      this.submittedButtonDisabled = true
      let order = this.getOrderData()
      const closeOrder = order['close_order']
      delete order['close_order']
      if (closeOrder) this.setDelayOrdersListRefresh(3)
      this.$store.dispatch('_orderEdit/placeOrder', {
        orderOnSite: true,
        order: order,
        onSuccess: (responseData) => {
          const bill_id = responseData?.bill_id || null
          if (responseData.hasOwnProperty('reason')) {
            console.log('problem printing bill: ' + responseData.reason)
          }
          if (responseData.hasOwnProperty('xml')) {
            this.printBillCommand(responseData.id, responseData.xml, bill_id, true, closeOrder)
          }
          this.clearResponseErrors({})
        },
        onError: (error) => {
          this.submittedButtonDisabled = false
          this.validationErrors = error.response.data
          this.setResponseErrors(error.response.data)
        },
      })
    },
  },
}
</script>

<style lang="scss">
.onsite {
  &-modal {
    padding: 0 !important;
  }
  &-dialog {
    max-width: 1000px !important;
  }
  &-content {
  }
  &-body {
    padding-bottom: 0;
  }
}
</style>
<style scoped lang="scss">
.form-control-block-info {
  background: #ebeff3;
  font-size: 18px;
  text-align: center;
}
</style>

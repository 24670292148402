<template>
  <div class="card map-container">
    <div class="map-wrapper">
      <l-map
        v-if="showMap"
        ref="map"
        :zoom="zoom"
        :center="center"
        :options="mapOptions"
        style="z-index: 1"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
      >
        <l-tile-layer :url="url" />
        <!--        <l-control position="topleft">-->
        <!--          <div class="leaflet-control-zoom leaflet-bar leaflet-control m-0 enlarge-button">-->
        <!--            <a class="" @click="setMapIsEnlarged(!mapIsEnlarged)">-->
        <!--              <i v-if="mapIsEnlarged" class="fas fa-compress-alt" />-->
        <!--              <i v-else class="fas fa-expand-alt" />-->
        <!--            </a>-->
        <!--          </div>-->
        <!--          <div class="leaflet-control-zoom leaflet-bar leaflet-control m-0 mt-2 enlarge-button">-->
        <!--            <a class="" @click="fitMapBoundsToAllMarkers()">-->
        <!--              <i class="fas fa-expand" />-->
        <!--            </a>-->
        <!--          </div>-->
        <!--        </l-control>-->
        <CourierMapMarker
          v-if="courierMarker"
          :coords="courierMarker.coords"
          :orders-count="courierMarker.countCurrentOrders"
          :label="courierMarker.label"
          :on-click="() => {}"
        />
        <LocalizationMapMarker />
        <OrderMapMarker
          v-if="orderMarker"
          :id="orderMarker.id"
          :key="'order_marker_' + orderMarker.id"
          :coords="orderMarker.coords"
          :label="orderMarker.label"
          :promised-time="orderMarker.promised_time"
          :courier="orderMarker.courier"
          :number="orderMarker.number"
        />
      </l-map>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import L from 'leaflet'
import { LControl, LMap, LTileLayer } from 'vue2-leaflet'
import restaurantIcon from '@/../public/static/images/restaurant.svg'
import orderIcon from '@/../public/static/images/order.svg'
import courierIcon from '@/../public/static/images/courier.svg'
import OrderMapMarker from '@/components/OrderMapMarker'
import LocalizationMapMarker from '@/components/LocalizationMapMarker'
import CourierMapMarker from '@/components/CourierMapMarker'
import { orderMixins } from '@/mixins'

const STORE_NAME = 'newOrder'

export default {
  name: 'OrderDetailsMap',
  components: {
    LMap,
    LTileLayer,
    OrderMapMarker,
    LocalizationMapMarker,
    CourierMapMarker,
    LControl,
  },
  mixins: [orderMixins],
  data: function () {
    return {
      url: 'https://tiles.papu.io/{z}/{x}/{y}.png',
      center: '',
      zoom: 14,
      mapOptions: {
        zoomSnap: 0.5,
      },
      restaurantIcon: restaurantIcon,
      orderIcon: orderIcon,
      courierIcon: courierIcon,
      showMap: false,
    }
  },
  computed: {
    ...mapGetters(STORE_NAME, {
      order: 'orderData',
    }),
    ...mapGetters('_orders', ['couriers']),
    ...mapGetters('auth', ['getLocalizationLat', 'getLocalizationLon']),
    orderMarker() {
      if (!this.order?.uuid || !this.order?.lat || !this.order.lon) return false
      const { uuid, lat, lon, promised_time, courier, order_number } = this.order
      return {
        id: uuid,
        coords: L.latLng(lat, lon),
        label: this.getOrderFullAddress(this.order),
        promised_time: promised_time,
        courier: courier,
        number: order_number,
      }
    },
    courierMarker() {
      if (!this.order || this.couriers.length === 0) return null

      const courier = this.couriers.find((courier) => {
        return courier.name === this.order?.courier?.name
      })

      if (!courier || !courier.coords || !courier.coords.lat || !courier.coords.lon || !courier.coords.timestamp)
        return null

      return {
        id: courier.id,
        coords: L.latLng(courier.coords.lat, courier.coords.lon),
        label: `${courier.name} (${courier.coords.timestamp})`,
        countCurrentOrders: courier.count_current_orders,
        user_id: courier.user_id,
      }
    },
  },
  beforeMount() {
    this.center = L.latLng(
      this.$store.getters['auth/getLocalizationLat'],
      this.$store.getters['auth/getLocalizationLon']
    )
  },
  mounted() {
    setTimeout(() => {
      this.showMap = true
      setTimeout(() => {
        this.fitMapBoundsToAllMarkers()
      }, 300)
    }, 500)
  },
  methods: {
    zoomUpdate(zoom) {
      this.zoom = zoom
    },
    centerUpdate(center) {
      this.center = center
    },
    fitMapBoundsToAllMarkers() {
      let coords = []
      if (this.orderMarker.coords) coords.push(this.orderMarker.coords)
      if (this.getLocalizationLat && this.getLocalizationLon)
        coords.push(L.latLng(this.getLocalizationLat, this.getLocalizationLon))
      const latLngBounds = new L.latLngBounds(coords)
      if (this.$refs.map && latLngBounds.isValid()) {
        this.$refs.map.fitBounds(latLngBounds)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.map-container {
  padding: 1px;
  display: flex;
  overflow-y: auto;
  height: 100%;
}
.map-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}
.map-buttons-container {
  position: absolute;
  z-index: 10;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.map-button {
  padding: 5px 10px;
  margin-bottom: 15px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
}
@media (max-width: 576px) {
  .map-buttons-container {
    position: absolute;
    z-index: 10;
    top: 5px;
    right: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .map-button {
    margin-bottom: 10px;
  }
}
</style>

<template>
  <b-col class="categorySizes">
    <div class="d-flex align-items-center w-100">
      <i :class="['fas fa-bars fa-fw text-secondary mr-2 movable-meal', { invisible: disabledDraggable }]" />
      <b-form-input
        id="ingredient-name-input"
        v-validate="validator"
        :value="value"
        class="categorySize"
        name="ingredientName"
        :disabled="saving"
        @input="$emit('input', $event)"
        @blur="veeFields.ingredientName.changed ? $emit('blur') : null"
      />
    </div>

    <b-button v-if="id" variant="danger" class="btn-input" :disabled="saving" @click="$emit('remove-ingredient')">
      <i class="fas fa-trash-alt" :aria-label="$t('message.common.delete')" />
    </b-button>
    <template v-else>
      <b-button
        :disabled="saving"
        class="btn-input mr-2"
        variant="outline-secondary"
        @click="$emit('cancel-add-ingredient')"
      >
        <i class="fas fa-times" :aria-label="$t('message.common.cancel')" />
      </b-button>
      <b-button variant="success" :disabled="saving" class="btn-input" @click="$emit('create-ingredient')">
        <i class="fas fa-save" :aria-label="$t('message.common.save')" />
      </b-button>
    </template>
  </b-col>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { showErrorToast } from '@/utils/common'
import { API } from '@/services/api/api'

const STORE = '_menuEdit'

export default {
  name: 'IngredientMeal',
  props: {
    value: {
      type: String,
      default: '',
    },
    id: {
      type: Number,
      required: true,
    },
    disabledDraggable: {
      type: Boolean,
      default: false,
    },
    validator: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapFields(STORE, ['form.ingredients']),
    ...mapGetters(STORE, ['saving', 'isEditMode', 'menuElement']),
  },
}
</script>

<template>
  <b-modal
    ref="modal"
    scrollable
    :title="$t('order_changes_history')"
    :visible="visible"
    hide-footer
    size="lg"
    @hidden="hide"
  >
    <table class="table">
      <thead>
        <th>{{ $t('change_date') }}</th>
        <th>{{ $t('field') }}</th>
        <th>{{ $t('old_value') }}</th>
        <th></th>
        <th>{{ $t('new_value') }}</th>
      </thead>
      <tbody>
        <template v-for="(history, time) in parseHistory">
          <tr style="background: #f4f4f4">
            <td>{{ time }}</td>
            <td colspan="4"></td>
          </tr>
          <tr v-for="item in history" :key="item.key + time">
            <td />
            <td>{{ $t(item.key) }}</td>
            <td>{{ item.oldValue }}</td>
            <td><i class="fa fa-arrow-right"></i></td>
            <td>{{ item.newValue }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </b-modal>
</template>

<script>
import { isEqual } from 'lodash'
export default {
  name: 'OrderEditHistoryModal',
  props: {
    history: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    // Group history by time
    parseHistory() {
      const history = {}

      if (!this.history?.length) return history

      this.history.forEach((item, index) => {
        if (index === this.history.length - 1) return

        Object.keys(item).forEach((key) => {
          let oldValue = this.history[index + 1][key]
          let newValue = item[key]

          // Avoid comparing id and time values and compare only changed values
          if (key !== 'id' && key !== 'time' && !isEqual(newValue, oldValue)) {
            if (!history[item.time]) history[item.time] = []

            if (key === 'status') {
              oldValue = this.$t(oldValue)
              newValue = this.$t(newValue)
            } else if (key === 'user') {
              oldValue = oldValue?.name
              newValue = newValue?.name
            }

            history[item.time].push({
              key,
              oldValue,
              newValue,
            })
          }
        })
      })

      return history
    },
  },
  mounted() {
    this.$eventBus.$on('showOrderEditHistoryModal', () => {
      this.show()
    })
  },
  destroyed() {
    this.$eventBus.$off('showOrderEditHistoryModal')
  },
  methods: {
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    },
  },
}
</script>
<style scoped lang="scss">

</style>

<template>
  <div :class="['card order-preview', { 'd-flex': visible }]">
    <div class="widget-header">
      <div class="widget-header__title">
        <small>{{ $t('message.orderFormShort.order') }}:</small>
        <span class="ml-2">{{ cart.number ? cart.number : $t('message.orderFormShort.new') }}</span>
      </div>
    </div>
    <div class="widget-body-wrapper d-flex flex-column pt-0 pb-0">
      <div v-if="!orderOnSite" class="customer-info">
        <b-row>
          <b-col cols="12">
            <div class="mb-2">
              <strong>{{ $t('message.orderFormShort.customer') }}: </strong>
              <span v-if="cart.customer.name">{{ cart.customer.name }}</span>
              <span v-else class="text-secondary">{{ $t('message.common.noData') }}</span>
            </div>
            <div class="mb-2">
              <strong>{{ $t('message.orderFormShort.address') }}: </strong>
              <span v-if="cart.customer.address">{{ cart.customer.address }}</span>
              <span v-else class="text-secondary">{{ $t('message.common.noData') }}</span>
            </div>
          </b-col>
          <b-col cols="12">
            <b-button
              v-shortkey="['enter']"
              block
              size="lg"
              variant="warning"
              :disabled="!cart.id && isEdit"
              @click="checkIfMealIsSaved()"
              @shortkey="checkIfMealIsSaved()"
            >
              <b-spinner v-if="!cart.id && isEdit"></b-spinner>
              <span v-else>
                <kbd class="pb-0 pt-1 mr-3 bg-white text-dark">&crarr;</kbd><i class="fas fa-user-edit"></i>
                {{ $t('message.orderFormShort.changeDetails') }}
              </span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <Cart :full="false" :cart="cart" />
      <template v-if="orderOnSite">
        <b-button
          id="openorderOnSiteModalButton"
          v-shortkey="cart.meals.length > 0 ? ['enter'] : ['null']"
          class="submit mb-3"
          :disabled="cart.meals.length <= 0"
          variant="success"
          size="lg"
          style="margin-top: auto"
          @shortkey="openorderOnSiteModal"
          @click="openorderOnSiteModal"
        >
          <kbd class="pb-0 mr-3 bg-white text-dark submit__shortcut">&crarr;</kbd>
          <i class="fas fa-plus-square"></i>
          <span class="submit__text">{{ $t('message.orderFormShort.addOrderOnSite') }}</span>
        </b-button>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Cart from '@/components/cart/Cart'

const STORE = '_orderEdit'

export default {
  name: 'OrderPreview',
  components: { Cart },
  computed: {
    ...mapGetters(STORE, ['cart', 'orderOnSite', 'showOrderForm', 'isEdit']),
    visible() {
      return this.orderOnSite && this.showOrderForm
    },
  },
  methods: {
    openorderOnSiteModal() {
      this.$eventBus.$emit('onShowOrderOnSiteModal', {})
    },
    checkIfMealIsSaved() {
      // If meal was changed, it pops up confirmation modal, otherwise goes to OrderFormLong
      if (this.$store.getters['_orderEdit/mealSaved']) this.$store.commit('_orderEdit/toggleOrderForm', true)
      else this.$eventBus.$emit('onShowMealDiscardChangesModal')
    },
  },
}
</script>

<style lang="scss" scoped>
.order-preview {
  height: 100%;
  width: 250px;

  @include media-breakpoint-down(sm) {
    display: none;
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    width: 300px;
  }

  @include media-breakpoint-up(xl) {
    width: 350px;
  }
}

.customer-info {
  margin-bottom: 20px;
  padding-top: 0.5rem;
}
.submit {
  position: relative;
  min-height: 81px;

  &__text {
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    display: block;
    margin-top: 11px;
  }

  &__shortcut {
    position: absolute;
    left: 6px;
    top: 6px;
    height: 25px;
    width: 27px;
    padding-top: 0;
  }
}
</style>

<template>
  <b-overlay :show="loaders.categorySizes || loaders.mealGroupMeals">
    <div class="edit-section">
      <b-table-simple small>
        <b-thead>
          <b-tr class="text-center">
            <b-th>
              {{ $t('message.menu.meal.size') }}
            </b-th>
            <b-th>
              {{ $t('message.menu.vatRate') }}
            </b-th>
            <b-th> {{ $t('message.menu.meal.price') }}<span class="text-danger ml-1">*</span> </b-th>
            <b-th v-if="isErestoBrand && differentPrices">
              {{ $t('message.menu.meal.erestoPrice') }}<span class="text-danger ml-1">*</span>
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <size-meal
            v-for="(size, index) in sizes"
            :key="index"
            :size="size"
            :validator="validatePrice"
            @blur="updateMeal(size)"
            @createMeal="createMeal(size)"
            @removeMeal="removeMeal(size)"
          />
        </b-tbody>
      </b-table-simple>
      <input-error-msg field="sizePrice" :_errors="errors"></input-error-msg>
      <input-error-msg field="noMealSizes" :_errors="errors"></input-error-msg>
    </div>
  </b-overlay>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions, mapGetters } from 'vuex'
import SizeMeal from '@/views/menu/_components/edit/meal/SizeMeal'

const STORE = '_menuEdit'

export default {
  name: 'SizeListMeal',
  components: {
    SizeMeal,
  },
  props: {
    mealGroupId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapFields(STORE, ['form.sizes', 'form.meals']),
    ...mapGetters({
      differentPrices: `${STORE}/differentPrices`,
      isEditMode: `${STORE}/isEditMode`,
      isErestoBrand: 'auth/isErestoBrand',
      loaders: `${STORE}/loaders`,
      selectedCategoryId: `${STORE}/selectedCategoryId`,
    }),
    validatePrice() {
      return {
        required: true,
        min: 1,
        decimal: true,
      }
    },
  },
  async mounted() {
    await this.getCategorySizes(this.selectedCategoryId)
    if (this.isEditMode) {
      await this.getMealGroupMeals(this.mealGroupId)
    }
  },
  methods: {
    ...mapActions(STORE, ['getCategorySizes', 'getMealGroupMeals', 'updateElement', 'deleteElement', 'createElement']),
    async updateMeal(size) {
      const meal = this.meals.find((meal) => meal.size == size.id)

      if (this.isEditMode) {
        const { error, data } = await this.updateElement({ id: meal.id, requestData: meal, apiCall: 'updateMeal' })
        if (!error) {
          const index = this.meals.findIndex((m) => m.size == size.id)
          this.meals.splice(index, 1, data)
        }
      } else {
        const index = this.meals.findIndex((m) => m.size == meal.size)
        this.meals.splice(index, 1, meal)
      }
    },
    async createMeal(size) {
      const meal = {
        price: 0,
        erestaurant_price: 0,
        size: size.id,
      }
      if (this.mealGroupId) meal.meal_group = this.mealGroupId

      if (this.isEditMode) {
        const { error, data } = await this.createElement({ requestData: meal, apiCall: 'createMeal' })
        if (!error) {
          this.meals.push({
            ...data,
          })
        }
      } else {
        // meal_group is new, so we're adding meal to meals without sending them
        this.meals.push({
          ...meal,
          id: 0,
        })
      }
    },
    async removeMeal(size) {
      const meal = this.meals.find((m) => m.size == size.id)

      if (this.isEditMode) {
        const { error, data } = await this.deleteElement({ id: meal.id, apiCall: 'deleteMeal' })
        if (!error) {
          const index = this.meals.findIndex((m) => m.size == size.id)
          this.meals.splice(index, 1)
        }
      } else {
        const index = this.meals.findIndex((m) => m.size == size.id)
        this.meals.splice(index, 1)
      }
    },
  },
}
</script>

<style scoped>

</style>

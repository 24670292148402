import { IntegrationsState } from './index'
import { MENU_SOURCE_CHOICES } from '@/common/constants'
import { RootState } from '@/store'

const integrations = (state: IntegrationsState) => {
  return state.integrations
}
const getIntegration = (state: IntegrationsState) => (name: string) => {
  return Array.from(state.integrations).find((integration) => integration.name === name) || null
}
const hasIntegration = (state: IntegrationsState, getters: any) => (name: string) => {
  return !!getters.getIntegration(name)
}
const getActiveIntegrations = (state: IntegrationsState) => {
  return state.integrations.filter((integration) => integration.is_active)
}

/** Uber eats */

const hasUberEatsIntegration = (state: IntegrationsState, getters: any, rootState: RootState) => {
  if (!rootState.auth?.menuSources) return false
  return Object.values(rootState.auth?.menuSources)?.some((brand) =>
    brand.some((source) => source.source === parseInt(MENU_SOURCE_CHOICES.UBEREATS))
  )
}

export default {
  integrations,
  getIntegration,
  hasIntegration,
  getActiveIntegrations,
  hasUberEatsIntegration
}

<template>
  <div class="d-flex flex-column h-100">
    <b-row class="mb-3">
      <b-col>
        <div class="search-meal">
          <span v-if="searchKeyword" class="search-meal__remove" @click="clearSearch()">
            <i class="far fa-times-circle" />
          </span>
          <span v-else class="search-meal__remove">
            <i class="fas fa-search" />
          </span>
          <b-form-input
            id="search-mapping-item-input"
            v-model="searchKeyword"
            class="search-meal__input"
            :class="{ 'search-meal__input--filled': searchKeyword }"
            :placeholder="$t('message.ordersList.search') + '...'"
          />
        </div>
      </b-col>
    </b-row>

    <div class="table-container h-100">
      <b-overlay :show="loading" spinner-variant="primary" spinner-small>
        <b-table-simple class="table mapping-items-table" outlined striped>
          <b-thead head-variant="light">
            <tr>
              <th scope="col">{{ $t('message.common.name') }}</th>
              <th v-if="!isEntry" scope="col">{{ $t('message.common.category') }}</th>
              <th v-if="!isEntry" scope="col">{{ $t('message.common.size') }}</th>
              <th v-if="isEntry" scope="col">{{ $t('message.common.group') }}</th>
              <th scope="col">{{ $t('message.common.price') }}</th>
              <th v-if="!isEntry" scope="col">VAT</th>
            </tr>
          </b-thead>
          <tbody>
            <tr
              v-for="item in dataTable"
              :key="item.id"
              :class="['mapping-items-table__row', { 'mapping-items-table__row--active': item.id === selectedItemId }]"
              @click="selectItem(item.id)"
            >
              <td class="font-weight-bold text-wrap">{{ item.name }}</td>
              <td v-if="!isEntry" class="text-wrap">{{ item.category_name }}</td>
              <td v-if="!isEntry" class="text-wrap">
                {{ item.size_name === 'default' ? $t('message.menu.defaultSizeName') : item.size_name || '' }}
              </td>
              <td v-if="isEntry" class="text-wrap">{{ item.group_name }}</td>
              <td>{{ priceDecimal(item.price) }} {{ currency }}</td>
              <td v-if="!isEntry">{{ getVatRateText(item.vat) }}</td>
            </tr>
            <tr v-if="dataTable.length === 0">
              <th colspan="100%" class="text-center">{{ $t('message.mapping.table.noPositionInMenu') }}</th>
            </tr>
          </tbody>
        </b-table-simple>
      </b-overlay>
    </div>
    <div class="mt-3">
      <b-pagination
        v-model="pagination.page"
        :total-rows="pagination.count"
        :per-page="DEFAULT_PAGE_SIZE"
        aria-controls="meals-table"
        align="right"
        @input="getList()"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { vatMixins } from '@/mixins'
import { DEFAULT_PAGE_SIZE } from '@/common/constants'
import _ from 'lodash'

const STORE = '_mapping'

const defaultPagination = {
  page: 1,
  count: 0,
  next: null,
  previous: null,
}

export default {
  name: 'MappingItemsTable',
  mixins: [vatMixins],
  props: {
    type: { type: String, required: true },
    showMappedPositions: { type: Boolean, default: false },
  },
  data() {
    return {
      isMounted: false,
      loading: false,
      selectedItemId: null,
      pagination: { ...defaultPagination },
      sourceMenuId: null,
      searchKeyword: '',
      DEFAULT_PAGE_SIZE,
    }
  },
  computed: {
    ...mapGetters(['currency']),
    ...mapGetters('auth', ['getSourceById']),
    ...mapGetters(STORE, ['mappedItem', 'selectedBrandId']),
    dataTable() {
      switch (this.type) {
        case 'meals':
          return this.$store.getters['_mapping/mappingMealsList']
        case 'singleChoice':
          return this.$store.getters['_mapping/mappingSingleChoiceEntriesList']
        case 'multiChoice':
          return this.$store.getters['_mapping/mappingMultiChoiceEntriesList']
      }
    },
    isEntry() {
      return this.type !== 'meals'
    },
  },
  watch: {
    searchKeyword() {
      if (this.isMounted) this.debounceGetList()
    },
    showMappedPositions() {
      this.getList()
    },
  },
  created() {
    this.searchKeyword = this.mappedItem?.name || ''
    this.getList()
  },
  mounted() {
    setTimeout(() => {
      this.isMounted = true
    }, 100)
  },
  methods: {
    ...mapMutations(STORE, ['mapSelectedItem']),
    ...mapActions(STORE, ['getMappingMealsList', 'getMappingEntriesList']),
    async getList(resetPage = false) {
      if (!this.mappedItem) return false
      if (resetPage) {
        this.resetPagination()
      }
      this.loading = true
      let response
      const requestData = {
        page: this.pagination.page,
        sourceMenuId: this.getSourceById(this.selectedBrandId, this.mappedItem.source)?.menu_id,
        name: this.searchKeyword,
        showMappedPositions: this.showMappedPositions,
      }
      switch (this.type) {
        case 'meals': {
          response = await this.getMappingMealsList(requestData)
          break
        }
        case 'singleChoice': {
          response = await this.getMappingEntriesList({ ...requestData, entryType: 'single_choice_entry' })
          break
        }
        case 'multiChoice': {
          response = await this.getMappingEntriesList({ ...requestData, entryType: 'multi_choice_entry' })
          break
        }
      }
      const { error, data } = response
      if (!error && data) {
        const { count, previous, next } = data
        this.pagination = { ...this.pagination, count, previous, next }
      }
      this.loading = false
    },
    debounceGetList: _.debounce(function () {
      this.getList(true)
    }, 500),
    selectItem(id) {
      const selectedItem = this.dataTable.find((item) => item.id === id)
      this.mapSelectedItem({ mappingItem: selectedItem })
      this.selectedItemId = id
    },
    clearSearch() {
      this.searchKeyword = ''
    },
    resetPagination() {
      this.pagination = { ...defaultPagination }
    },
  },
}
</script>

<style scoped lang="scss">
.mapping-items-table {
  border-collapse: separate;
  border-spacing: 0;
  margin: 0;
  thead {
    > tr {
      > th {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 2;
      }
      &:nth-child(2) {
        > th {
          top: 40px;
        }
      }
    }
  }
}
.mapping-items-table__row {
  cursor: pointer;
  &:not(&--active):not(&:active):hover {
    background-color: $gray-400;
  }
  &--active,
  &:active {
    background-color: $primary !important;
    color: #fff;
  }
}
</style>

import { render, staticRenderFns } from "./LocalizationMapMarker.vue?vue&type=template&id=038ffd05&scoped=true&"
import script from "./LocalizationMapMarker.vue?vue&type=script&lang=js&"
export * from "./LocalizationMapMarker.vue?vue&type=script&lang=js&"
import style0 from "./LocalizationMapMarker.vue?vue&type=style&index=0&id=038ffd05&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "038ffd05",
  null
  
)

export default component.exports
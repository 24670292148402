<template>
  <div class="card customer-details">
    <div class="widget-header">
      <span class="widget-header__title">{{ $t('message.orderDetails.customerDetails') }}</span>
    </div>
    <div class="widget-details widget-info customer-details">
      <b-list-group flush>
        <b-list-group-item> <i class="fas fa-phone fa-fw mr-3" /> {{ order.order_customer.phone }} </b-list-group-item>
        <b-list-group-item>
          <div class="d-flex flex-direction-row align-items-center">
            <i class="fas fa-map-marker-alt fa-fw mr-3" />
            <span class="mr-3">{{ getOrderFullAddress(order) }}</span>
            <i
              v-if="order.order_customer.address_comment"
              v-b-tooltip.hover
              class="fas fa-comment fa-fw mr-3 text-info"
              :title="order.order_customer.address_comment"
            />
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <i class="fas fa-user-circle fa-fw mr-3" />{{ order.order_customer.name || '–' }}
        </b-list-group-item>
        <b-list-group-item>
          <i class="fas fa-envelope fa-fw mr-3" />{{ order.order_customer.email || '–' }}
        </b-list-group-item>
        <b-list-group-item>
          <i class="fas fa-briefcase fa-fw mr-3" />{{ order.company_name || '–' }}
        </b-list-group-item>
        <b-list-group-item>
          <i class="fas fa-id-badge fa-fw mr-3" />{{ order.company_tax_id || '–' }}
        </b-list-group-item>
        <b-list-group-item>
          <div class="d-flex align-items-center">
            <i class="fas fa-wallet fa-fw mr-3" />
            <span>{{ order.payment_translated }}</span>
            <b-dropdown
              v-if="order.status !== 'new' && !hasSplitBills && $hasModuleAccess('payment-method-edit')"
              variant="warning"
              size="sm"
              class="ml-auto"
            >
              <template #button-content><i class="fas fa-edit" /></template>
              <b-dropdown-item-button disabled>
                {{ $t('message.orderDetails.changePaymentMethod') }}
              </b-dropdown-item-button>
              <b-dropdown-item-button
                v-for="paymentMethod in paymentMethods"
                :key="paymentMethod.value"
                :active="paymentMethod.value === order.payment"
                @click="changePaymentMethod(paymentMethod.value)"
              >
                <i class="fa-fw" :class="`fas ${paymentMethod.icon}`" style="margin-right: 10px" />
                {{ paymentMethod.text }}
              </b-dropdown-item-button>
            </b-dropdown>
          </div>
          <Loader v-if="paymentLoading" />
        </b-list-group-item>
        <b-list-group-item v-if="order.pickup_type === PICKUP_TYPE_ON_SITE">
          <div class="d-flex align-items-center">
            <i class="fas fa-square fa-fw mr-3" />
            <b-form-input
              v-if="$hasModuleAccess('table-number-edit') && tableNumberEdit"
              id="order-table-input"
              v-model="order.table"
              :formatter="tableNumberFormatter"
              pattern="\d*"
              min="-32767"
              max="32768"
              step="1"
              class="form-control col-sm-4"
            >
            </b-form-input>
            <span v-else-if="order.table === null">{{ $t('message.orderDetails.table.missingTable') }}</span>
            <span v-else-if="!tableNumberEdit">
              {{ $t('message.orderDetails.table.nameTable') }} {{ order.table }}
            </span>
            <b-btn class="btn-group ml-auto" variant="warning" @click="manageTableNumberEdit()">
              <i :class="`fas ` + (tableNumberEdit ? `fa-check` : `fa-edit`)" />
            </b-btn>
          </div>
          <Loader v-if="tableLoading" />
        </b-list-group-item>
        <b-list-group-item> <i class="fas fa-taxi fa-fw mr-3" /> {{ order.pickup_type_translated }} </b-list-group-item>
        <b-list-group-item>
          <i class="fas fa-calendar-check fa-fw mr-3" /> {{ order.finished_at || '–' }}
        </b-list-group-item>
        <b-list-group-item> <i class="fas fa-clock fa-fw mr-3" /> {{ order.promised_time }} </b-list-group-item>
        <b-list-group-item> <i class="fas fa-download fa-fw mr-3" /> {{ order.created }} </b-list-group-item>
        <b-list-group-item>
          <i class="fas fa-user fa-fw mr-3" />
          <span v-if="order.courier">{{ order.courier.name }}</span>
          <span v-else>–</span>
        </b-list-group-item>
        <b-list-group-item v-if="transferButtonVisible">
          <b-button
            v-b-tooltip.hover.bottom
            class="send-order-button"
            variant="warning"
            :title="$t('message.orderDetails.changeOrderLocalizationInfo')"
            @click="showChangeOrderLocalizationModal()"
          >
            <span>
              <i class="fas fa-home" />
              <i class="fas fa-long-arrow-alt-right ml-1 mr-1" />
              <i class="fas fa-home" />
            </span>
          </b-button>
        </b-list-group-item>        
      </b-list-group>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { PICKUP_TYPE_ON_SITE, PICKUP_TYPE_DELIVERY, ORDER_STATUSES } from '@/common/constants'
import { orderMixins } from '@/mixins'
import Loader from '@/components/Loader'

export default {
  name: 'CustomerDetails',
  components: { Loader },
  mixins: [orderMixins],
  props: {
    order: {
      type: Object,
      required: true,
    },
    paymentMethods: {
      type: Array,
      required: true,
    },
    isOrderLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      PICKUP_TYPE_ON_SITE,
      tableNumberEdit: false,
    }
  },
  computed: {
    ...mapGetters('_orderDetails', ['hasSplitBills', 'paymentLoading', 'tableLoading', 'updateOrder']),
    ...mapGetters({
      companyLocalizations: 'auth/companyLocalizations',
      hasIntegration: 'integrations/hasIntegration',
    }),
    transferButtonVisible() {
      // only if more than 1 company localizations, delivery and not finished order
      return (
        this.order?.status !== ORDER_STATUSES.ORDER_STATUS_FINISHED &&
        Object.keys(this.companyLocalizations).length > 1 &&
        this.order?.pickup_type === PICKUP_TYPE_DELIVERY
      )
    },
    
  },
  methods: {
    changePaymentMethod(paymentMethod) {
      this.$store.commit(`_orderDetails/setPaymentLoading`, true)
      this.$store.dispatch('_orderDetails/updateOrder', {
        order: {
          payment: paymentMethod,
        },
        refresh: true,
        onError: (error) => {
          let reason = this.getErrorValue(error)
          this.$toasted.show(reason, {
            type: 'error',
            icon: {
              name: 'times',
            },
            duration: 3000,
          })
        },
        onSuccess: () => {
          this.$toasted.show(this.$t('message.orderDetails.paymentTypeChanged'), {
            type: 'success',
            icon: {
              name: 'check',
            },
            duration: 3000,
          })
        },
      })
    },
    tableNumberFormatter(value, event) {
      return value.replace(/[^\d]/, '')
    },
    manageTableNumberEdit() {
      if (this.tableNumberEdit) {
        this.changeTableNumber()
      }
      this.tableNumberEdit = !this.tableNumberEdit
    },
    changeTableNumber() {
      this.$store.commit(`_orderDetails/setTableLoading`, true)
      const orderData = {
        table: this.order.table === '' ? null : this.order.table,
      }
      this.$store.dispatch('_orderDetails/updateOrder', {
        order: orderData,
        refresh: true,
        onError: (error) => {
          // show any reason of error that came from backend (validation error)
          let reason = ''
          if (error.response && error.response.data) {
            reason = error.response.data.non_field_errors || error.response.data['table']
          }
          this.$toasted.show(reason || this.$t('message.orderDetails.tableNumberUpdateError'), {
            type: 'error',
            icon: {
              name: 'times',
            },
            duration: 3000,
          })
        },
        onSuccess: () => {
          this.$toasted.show(this.$t('message.orderDetails.tableNumberChanged'), {
            type: 'success',
            icon: {
              name: 'check',
            },
            duration: 3000,
          })
        },
      })
    },
    showChangeOrderLocalizationModal() {
      this.$eventBus.$emit('onShowChangeOrderLocalizationModal')
    },
    
    getErrorValue(error) {
      const response = error?.response || null
      const data = response?.data || null
      const errorKey = data ? Object.keys(data) : null
      const errorValue = errorKey?.length ? data[errorKey[0]] : null
      return errorValue
    },
  },
}
</script>

<style scoped lang="scss">
.customer-details {
  width: 300px;
  overflow-y: auto;
  overflow-x: hidden;

  @include media-breakpoint-down(md) {
    width: 230px;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.send-order-button {
  height: 50px;
  width: 100px;
  text-align: left;

  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>

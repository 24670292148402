import {
  FINISHED_TYPES,
  MEAL_STATUSES,
  MENU_SOURCE_CHOICES,
  ORDER_STATUSES,
  PICKUP_TYPE_DELIVERY,
  PICKUP_TYPE_ON_SITE,
  PICKUP_TYPE_TAKOUT,
} from '@/common/constants'
import { isWordInString } from '@/utils/common'
export const orderMixins = {
  computed: {
    mealsStatus() {
      if (this.order.meals.length === 1) return this.order.meals[0].status
      else if (this.order.meals.every((meal) => meal.status === MEAL_STATUSES.DONE)) return MEAL_STATUSES.DONE
      else if (
        this.order.meals.some(
          (meal) =>
            meal.status === MEAL_STATUSES.DOING ||
            meal.status === MEAL_STATUSES.IN_OVEN ||
            meal.status === MEAL_STATUSES.DONE
        )
      )
        return MEAL_STATUSES.DOING
      else return MEAL_STATUSES.TODO
    },
  },
  methods: {
    getOrderFullAddress(order) {
      if (!order.order_customer) return ''

      let address = order.order_customer.address || ''
      const city = order.order_customer.city

      if (address && city && !isWordInString(address, city)) {
        address += `, ${city}`
      }
      return address || ''
    },
    getPickupTypeTranslate(pickupType) {
      switch (pickupType) {
        case PICKUP_TYPE_DELIVERY:
          return this.$i18n.t('message.ordersList.delivery')
        case PICKUP_TYPE_TAKOUT:
          return this.$i18n.t('message.ordersList.takeaway')
        case PICKUP_TYPE_ON_SITE:
          return this.$i18n.t('message.ordersList.onSite')
      }
    },
    getFinishTypeTranslate(finishType) {
      switch (finishType) {
        case FINISHED_TYPES.FINISHED_ORDER_CANCELED:
          return this.$i18n.t('message.orderDetails.finishOrderChoices.canceled')
        case FINISHED_TYPES.FINISHED_ORDER_DELIVERED:
          return this.$i18n.t('message.orderDetails.finishOrderChoices.delivered')
        case FINISHED_TYPES.FINISHED_ORDER_NOT_DELIVERED:
          return this.$i18n.t('message.orderDetails.finishOrderChoices.notDelivered')
        case FINISHED_TYPES.FINISHED_ORDER_PICKED_UP:
          return this.$i18n.t('message.orderDetails.finishOrderChoices.pickedUp')
      }
    },
    getStatusTranslated(statusType) {
      switch (statusType) {
        case ORDER_STATUSES.ORDER_STATUS_ACCEPTED:
          return this.$i18n.t('message.ordersList.accepted')
        case ORDER_STATUSES.ORDER_STATUS_DELIVERING_IN:
          return this.$i18n.t('message.ordersList.deliveringIn')
        case ORDER_STATUSES.ORDER_STATUS_FINISHED:
          return this.$i18n.t('message.ordersList.statusFinished')
        case ORDER_STATUSES.ORDER_STATUS_NEW:
          return this.$i18n.t('message.orderFormShort.new')
        case ORDER_STATUSES.ORDER_STATUS_PICKED_UP:
          return this.$i18n.t('message.ordersList.deliveringIn')
      }
    },
  },
}

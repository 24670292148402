<template>
  <pp-widget>
    <b-row>
      <b-col>
        <b-breadcrumb class="bg-white pl-4">
          <b-breadcrumb-item :to="{ name: 'settings' }">
            {{ $t('message.settings.settings') }}
          </b-breadcrumb-item>
          <b-breadcrumb-item active>
            {{ $t('message.settings.shiftsList') }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3">
        <button
          id="get-shifts-btn"
          class="btn btn-primary btn-block"
          @click="
            showHidden = !showHidden
            _getShifts(true)
          "
        >
          {{ showHidden ? $t('message.billing.hideRemovedEmployees') : $t('message.billing.showRemovedEmployees') }}
        </button>
      </b-col>
      <b-col cols="3">
        <select v-model="selectedEmployeeID" class="form-control" @change="_getShifts(true)">
          <option value="">{{ $t('message.billing.filterByEmployee') }}</option>
          <option v-for="(employee, index) in employees" :key="'employee_' + index" :value="employee.id">
            {{ employee.name }}
          </option>
        </select>
      </b-col>
      <b-col>
        <b-pagination-nav
          v-model="page"
          :number-of-pages="numberOfPages"
          :link-gen="shiftsListLinkGen"
          limit="5"
          use-router
          align="right"
          @input="pageChanged"
        >
        </b-pagination-nav>
      </b-col>
    </b-row>
    <div class="table-container">
      <b-table-simple responsive>
        <thead>
          <tr>
            <th scope="col" rowspan="2">{{ $t('message.summary.name') }}</th>
            <th scope="col" rowspan="2">{{ $t('message.summary.role') }}</th>
            <th scope="col" rowspan="2">{{ $t('message.billing.start') }}</th>
            <th scope="col" rowspan="2">{{ $t('message.billing.end') }}</th>
            <th scope="col" rowspan="2">{{ $t('message.billing.operationalCash') }}</th>
            <th scope="col" rowspan="2">{{ $t('message.settings.orders') }}</th>
            <th scope="col" colspan="3" class="border-left border-right text-center">
              {{ $t('message.orderFormFull.paymentTypes.cash') }}
            </th>
            <th scope="col" rowspan="2">{{ $t('message.billing.balance') }}</th>
            <th rowspan="2"></th>
          </tr>
          <tr>
            <th class="text-center">{{ $t('message.billing.acceptedCash') }}</th>
            <th class="text-center">{{ $t('message.billing.givenBack') }}</th>
            <th class="text-center">{{ $t('message.billing.balance') }}</th>
          </tr>
        </thead>
        <template v-if="shiftsRequest === 'loading'">
          <TablePlaceholder :row="10" :col="9" :loading="true" />
        </template>
        <tbody v-else>
          <shift-row v-for="(shift, index) in shifts" :key="'shift_' + index" :shift="shift"></shift-row>
        </tbody>
      </b-table-simple>
    </div>
    <shift-control-modal />
  </pp-widget>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import store from './_store'
import Widget from '@/components/Widget'
import TablePlaceholder from '@/components/TablePlaceholder'
import ShiftControlModal from '@/views/shifts/current/_components/ShiftControlModal'
import ShiftRow from './_components/ShiftRow'
import { Decimal } from 'decimal.js'

const STORE_NAME = '_shifts'

export default {
  name: 'Shifts',
  components: {
    'pp-widget': Widget,
    TablePlaceholder,
    ShiftControlModal,
    ShiftRow,
  },
  data() {
    return {
      selectedEmployeeID: '',
      selectedEmployee: '',
      showHidden: false,
      page: 1,
      numberOfPages: 1,
    }
  },
  computed: {
    ...mapGetters(STORE_NAME, {
      shifts: 'shifts',
      shiftsRequest: 'shiftsRequest',
    }),
    ...mapGetters({
      employees: 'employees/employees',
    }),
  },
  methods: {
    ...mapActions(STORE_NAME, ['getShifts']),
    pageChanged() {
      this._getShifts(false, false)
    },
    shiftsListLinkGen(pageNum) {
      // generating link for every page-button of pagination
      return this.$route.fullPath.replace(/page=\d/, `page=${pageNum}`)
    },
    async _getShifts(filterRequest = false, countNumberOfPages = true) {
      // fetches shiftsList
      // if this method fires from filter, then filterRequest should be true
      let filters = { page: this.page }
      if (filterRequest) {
        this.page = 1 // resets the page of paginator
        filters = {
          employee_id: this.selectedEmployeeID,
          show_hidden: this.showHidden,
          page: this.page,
        }
      }
      let onSuccess = null
      if (countNumberOfPages) {
        onSuccess = (shiftsCount) => {
          // calculating number of pages of shifts
          let numberOfPages = new Decimal(shiftsCount)
            .dividedBy(this.shifts?.length || 1)
            .ceil()
            .toNumber()
          if (!numberOfPages) numberOfPages = 1
          this.numberOfPages = numberOfPages
        }
      }
      const { error, data } = await this.getShifts({ loading: true, filters: filters })
      if (!error && data && onSuccess) {
        onSuccess(data.count)
      }
    },
    getFiltersFromQuery() {
      const query = this.$route.query
      this.selectedEmployeeID = query.employeeID || ''
    },
    showShiftControlModal(payload) {
      this.$eventBus.$emit('onShowShiftEntryModal', payload)
    },
  },
  beforeCreate() {
    if (!this.$store.state[STORE_NAME]) this.$store.registerModule(STORE_NAME, store)
  },
  created() {
    this.getFiltersFromQuery()
    this._getShifts()
    this.$store.dispatch('employees/getEmployees')
  },
  beforeDestroy() {
    this.$store.unregisterModule(STORE_NAME)
  },
}
</script>

<style scoped></style>

<template>
  <b-modal
    ref="modal"
    :title="title"
    :visible="visible"
    no-close-on-backdrop
    no-close-on-esc
    @close="$emit('close')"
    @hidden="hide"
    @keyup.enter.native="accept"
  >
    <b-container class="bv-example-row">
      <b-row v-for="(choice, index) in finishOrderChoices" :key="choice.value" :value="choice.value" class="mb-3">
        <b-col>
          <b-form-checkbox
            v-model="form.finishOrderChoice"
            button
            name="printReceipt"
            :button-variant="getFinishOrderChoiceBtnVariant(choice.value)"
            :value="choice.value"
            unchecked-value=""
            size="lg"
          >
            <i v-if="form.finishOrderChoice === choice.value" class="fas fa-check-square"></i>
            <i v-else class="far fa-square"></i>
            <span>{{ choice.name }}</span>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <response-error-msg field="finished_type" :response-errors="responseErrors" />

      <response-error-msg field="non_field_errors" :response-errors="responseErrors" />
    </b-container>
    <b-button
      slot="modal-footer"
      ref="finishOkButton"
      :disabled="disabled"
      variant="success"
      size="lg"
      block
      @click="accept"
      ><kbd class="pb-0 pt-1 mr-3 bg-white text-dark">&crarr;</kbd>OK</b-button
    >
  </b-modal>
</template>

<script>
import { PICKUP_TYPE_DELIVERY, PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT } from '@/common/constants'
import { mapActions, mapGetters } from 'vuex'
import { responseErrorsHandler } from '@/mixins'
import { API } from '@/services/api/api'

function initData() {
  return {
    visible: false,
    disabled: false,
    form: {
      finishOrderChoice: '',
    },
    PICKUP_TYPE_ON_SITE: PICKUP_TYPE_ON_SITE,
  }
}

export default {
  name: 'FinishOrderModal',
  mixins: [responseErrorsHandler],
  data() {
    return initData()
  },
  computed: {
    ...mapGetters('newOrder', ['orderData']),
    title() {
      return `${this.$t('message.orderDetails.finishOrder')} # ${this.order.order_number}`
    },
    order() {
      return this.orderData
    },
    finishOrderChoices() {
      const choices = [
        {
          name: this.$t('message.orderDetails.finishOrderChoices.canceled'),
          value: 'canceled',
          pickupTypes: [PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT, PICKUP_TYPE_DELIVERY],
        },
        {
          name: this.$t('message.orderDetails.finishOrderChoices.delivered'),
          value: 'delivered',
          pickupTypes: [PICKUP_TYPE_DELIVERY],
        },
        {
          name: this.$t('message.orderDetails.finishOrderChoices.notDelivered'),
          value: 'not delivered',
          pickupTypes: [PICKUP_TYPE_DELIVERY],
        },
        {
          name: this.$t('message.orderDetails.finishOrderChoices.pickedUp'),
          value: 'finished',
          pickupTypes: [PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT],
        },
      ]

      return choices.filter((choice) => choice.pickupTypes.includes(this.order.pickup_type))
    },
    calcRest() {
      return this.priceDecimal(this.form.cash - this.calcPrice)
    },
    calcPrice() {
      // return (parseFloat(this.cart.totalPrice) + parseFloat(this.cart.totalPrice) * (this.form.serviceCharge) / 100).toFixed(2)
      return this.priceDecimal(this.cart.totalPrice)
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler(val) {
        if (val) {
          setTimeout(() => {
            if (this.$refs['finishOkButton']) this.$refs['finishOkButton'].focus()
          }, 100)
          window.addEventListener('keydown', this.keyListener)
        } else {
          window.removeEventListener('keydown', this.keyListener)
        }
      },
    },
  },
  mounted() {
    this.$eventBus.$on('onShowFinishOrderModal', (payload = {}) => {
      this.show()
    })
  },
  destroyed() {
    this.$eventBus.$off('onShowFinishOrderModal')
  },
  methods: {
    ...mapActions('newOrder', ['updateOrder']),
    ...mapActions('ordersPapu3', ['getOrders']),
    show() {
      this.visible = true
      this.form.finishOrderChoice = this.order.finished_type || ''
    },
    hide() {
      let initdata = initData()
      this.visible = initdata.visible
      this.form.finishOrderChoice = initdata.form.finishOrderChoice
    },
    async accept() {
      this.disabled = true
      let orderData = {
        finished_type: this.form.finishOrderChoice,
      }
      const uuid = this.order.uuid
      const { error, data } = await API[
        this.form.finishOrderChoice === 'canceled' ? 'p3_cancelOrder' : 'p3_finishOrder'
      ](uuid, orderData)

      if (!error) {
        this.clearResponseErrors({})
        this.disabled = false
        this.hide()
        this.getOrders({ filters: { archive: false } })
      } else {
        this.disabled = false
        if (data) this.setResponseErrors(data)
      }

      this.$emit('changeStatus')
    },
    getFinishOrderChoiceBtnVariant(choice) {
      if (choice === 'canceled') return 'outline-danger'
      else return 'outline-primary'
    },
    keyListener(e) {
      if (e.key === 'Enter' && this.disabled === false) {
        this.accept()
      }
    },
  },
}
</script>

export const posErestoMealSetMixins = {
  methods: {
    generateMealSetItemMenuCategoryJson(mealSetItem, menuOperational = true) {
      // creates menuJson (category => sizes => meals etc) for given mealSetItem
      // menuOperational - if menu is used later to generate any other object (will be using objects of this menu)
      // otherwise (e.g. for calculations) can be non-operational and will not copy category object by JSON (which is slow)
      const menu = this.$store.getters.menu
      const categoryId = mealSetItem.category.id || mealSetItem.category_id
      let category = menu.categories.find((category) => category.id === categoryId)
      if (menuOperational) category = JSON.parse(JSON.stringify(category))
      if (mealSetItem.sizes && mealSetItem.sizes.length > 0) {
        category.sizes = this.intersectionObjectListsBy(category.sizes, mealSetItem.sizes, 'id')
      }
      if (mealSetItem.meals && mealSetItem.meals.length > 0) {
        category.sizes.forEach((size) => {
          size.meals = _.intersectionBy(size.meals, mealSetItem.meals, 'id')
        })
      }
      return category
    },
  },
}

<template>
  <b-modal
    ref="modal"
    :title="$t('message.assignCourier.assignCourierToOrder')"
    :visible="order && Object.values(order).length > 0"
    :hide-footer="true"
    @hidden="hidden()"
  >
    <b-overlay :show="loading">
      <template v-if="order && order.courier">
        <button id="assign-courier-btn" class="btn btn-block btn-danger" @click="assignCourier()">
          {{ $t('message.assignCourier.none') }}
        </button>
        <div class="divider divider--dashed"></div>
      </template>
      <b-button
        v-for="(courier, index) in couriers"
        :key="courier.id"
        block
        size="lg"
        :variant="getBtnClass(courier.name)"
        :class="[{ 'mt-4': index >= 1 }]"
        :value="courier.id"
        @click="assignCourier(courier.id)"
      >
        {{ courier.name }}
      </b-button>
      <template v-if="couriers.length <= 0">
        <p class="text-center my-5" style="opacity: 0.5">{{ $t('message.assignCourier.emptyCouriersList') }}</p>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
/* global $ */
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { showErrorToast } from '@/utils/common'

export default {
  name: 'AssignCourierPapu3',
  props: {
    order: {
      type: Object,
      default: () => {
        return {}
      },
    },
    orders: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data: function () {
    return {
      selectedCourierID: '',
      courierAssigned: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      couriers: '_orders/couriers',
    }),
    // ordersList() {
    //   return this.orders && this.orders.length > 0
    // },
  },
  methods: {
    ...mapMutations({
      updateOrderList: '_orders/updateOrderList',
    }),
    ...mapActions('newOrder', ['updateOrder']),
    hidden() {
      // Clear passed data from OrdersMap component
      this.$emit('onAssignCourierModalHidden')
    },
    getBtnClass(courierName) {
      return this.order?.courier?.name === courierName ? 'primary' : 'outline-primary'
    },
    async assignCourier(courierID = null) {
      this.loading = true
      this.courierAssigned = false
      // let data = {
      //   courier_id: courierID,
      // }
      // if (this.ordersList) {
      //   // If assigning courier to more than 1 order
      //   data = {
      //     ...data,
      //     orders: this.orders,
      //   }
      // }
      // let response = null
      // if (this.ordersList) {
      //   response = await API.assignCourierToOrders(data)
      // } else {
      //   response = await API.assignCourierToOrder(this.order.id, data)
      // }
      this.updateOrder({
        uuid: this.order.uuid,
        order: { courier_id: courierID },
        onSuccess: () => {
          this.courierAssigned = true
          this.selectedCourierID = courierID
          if (this.$refs['modal']?.hide) this.$refs['modal'].hide()
          this.loading = false
        },
        onError: (error) => {
          showErrorToast(this.$toasted, error)
          this.loading = false
        },
      })
      // const { error, data: responseData } = response
      // if (!error) {
      //   this.courierAssigned = true
      //   this.selectedCourierID = courierID
      //   if (this.$refs['modal']?.hide) this.$refs['modal'].hide()
      //   // get single or many orders
      //   if (this.ordersList) {
      //     this.$emit('couriersAssigned')
      //   } else {
      //     this.updateOrderList(responseData)
      //   }
      //
      // } else {
      //   showErrorToast(this.$toasted, error)
      // }
      this.loading = false
    },
  },
  mounted() {
    if (this.order) {
      const { courier } = this.order
      if (courier) {
        this.selectedCourierID = courier.id
      }
    }
  },
}
</script>

<template>
  <b-tr :class="['mealsetitem', { 'mealsetitem-disabled': disabled }]">
    <b-td><i v-if="!isHalfPizza" class="fas fa-align-justify fa-fw mr-3 drag-icon" /></b-td>
    <b-td>{{ getParsedDiscount(mealSetItem) }}</b-td>
    <b-td>{{ mealSetItem.count }}</b-td>
    <b-td>{{ getMealSetItemCategoryInfo() }}</b-td>
    <b-td>{{ getMealSetItemSizesInfo() }}</b-td>
    <b-td>{{ getMealSetItemMealsInfo() }}</b-td>
    <b-td class="td-fit-content">
      <b-button v-if="!disabled" v-b-modal.mealSetItemModal size="sm" variant="warning" @click="editItem()">
        <i class="fas fa-edit mr-2" />{{ $t('message.common.edit') }}
      </b-button>
      <b-button v-if="!disabled" variant="danger" size="sm" class="ml-2" @click="removeItem()">
        <i class="fas fa-trash mr-2" />{{ $t('message.common.delete') }}</b-button
      >
    </b-td>
  </b-tr>
</template>

<script>
import { MEALSET_ITEM_CONTEXT_CHOICES } from '@/common/constants'
import { mapGetters } from 'vuex'
import { mealSetsMixins } from '@/mixins/mealSetsMixins'

export default {
  name: 'MealSetItemRow',
  mixins: [mealSetsMixins],
  props: {
    mealSetItem: {
      type: Object,
      default: () => {},
    },
    isHalfPizza: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['currency']),
  },
  methods: {
    getMealSetItemCategoryInfo() {
      return this.mealSetItem.category ? this.mealSetItem.category.name : '-'
    },
    getMealSetItemSizesInfo() {
      if (this.mealSetItem.context === MEALSET_ITEM_CONTEXT_CHOICES.CONTEXT_CATEGORY) {
        return this.$t('message.mealSets.allSizesOfCategory')
      } else {
        let sizeNames = this.getMealSetItemSizeNames(this.mealSetItem)
        if (sizeNames) return sizeNames
        else return this.$t('message.mealSets.allSizesOfCategory')
      }
    },
    getMealSetItemMealsInfo() {
      switch (this.mealSetItem.context) {
        case MEALSET_ITEM_CONTEXT_CHOICES.CONTEXT_CATEGORY:
          return this.$t('message.mealSets.allMealsOfCategory')
        case MEALSET_ITEM_CONTEXT_CHOICES.CONTEXT_SIZE:
          return this.$t('message.mealSets.allMealsOfSizes')
        default:
          return this.getMealSetItemMealsNames(this.mealSetItem)
      }
    },
    editItem() {
      this.$emit('editMealSetItem')
    },
    removeItem() {
      this.$emit('removeMealSetItem')
    },
  },
}
</script>

<style scoped lang="scss">
.mealsetitem {
  height: 65px;
  &-disabled {
    opacity: 0.5;
    background: $gray-400;
  }
}
</style>

import Vue from 'vue'
import { API } from '@/services/api/api'
import { IEditMenuState, ICategory, ISize, IEditMenuForm, IEntry } from '@/types/interface'
import { RootState } from '@/store'
import { ActionContext } from 'vuex'

import { showErrorToast } from '@/utils/common'
/** Element actions */

const createElement = async (
  { commit }: ActionContext<IEditMenuState, RootState>,
  { requestData, apiCall }: { requestData: any; apiCall: string }
) => {
  commit('setSavingStatus', true)
  const { error, data } = await API[apiCall](requestData)
  Vue.toasted.clear()
  if (!error) {
    commit('setIsEdited', true)
  } else {
    showErrorToast(Vue.toasted, error)
  }
  commit('setSavingStatus', false)
  return { error, data }
}

const updateElement = async (
  { commit }: ActionContext<IEditMenuState, RootState>,
  { id, requestData, apiCall }: { id: number; requestData: any; apiCall: string }
) => {
  commit('setSavingStatus', true)
  const { error, data } = await API[apiCall](id, requestData)
  Vue.toasted.clear()
  if (!error) {
    commit('setIsEdited', true)
  } else {
    showErrorToast(Vue.toasted, error)
  }
  commit('setSavingStatus', false)
  return { error, data }
}

const deleteElement = async (
  { commit }: ActionContext<IEditMenuState, RootState>,
  { id, apiCall }: { id: number; apiCall: string }
) => {
  commit('setSavingStatus', true)
  const { error, data } = await API[apiCall](id)
  Vue.toasted.clear()
  if (!error) {
    commit('setIsEdited', true)
  } else {
    showErrorToast(Vue.toasted, error)
  }
  commit('setSavingStatus', false)
  return { error, data }
}

const updateElementsPositions = async (
  { commit }: ActionContext<IEditMenuState, RootState>,
  { requestData, apiCall }: { requestData: any; apiCall: string }
) => {
  commit('setSavingStatus', true)
  const { error, data } = await API[apiCall](requestData)
  Vue.toasted.clear()
  if (!error) {
    commit('setIsEdited', true)
  } else {
    showErrorToast(Vue.toasted, error)
  }
  commit('setSavingStatus', false)
  return { error, data }
}

/** Categories */

const getCategories = async ({ commit, state }: ActionContext<IEditMenuState, RootState>) => {
  if (!state.activeMenuId) return false
  const { error, data } = await API.getCategories(state.activeMenuId)
  if (!error && data) {
    commit('setCategories', data)
  }
}

const getCategory = async ({ commit }: ActionContext<IEditMenuState, RootState>, id: number) => {
  commit('setLoaders', { form: true })
  const { error, data } = await API.getCategory(id)
  if (!error && data) {
    commit('setForm', data)
  }
  commit('setLoaders', { form: false })
  return { error, data }
}

const getCategorySizes = async ({ state, commit }: ActionContext<IEditMenuState, RootState>, id: number) => {
  commit('setLoaders', { categorySizes: true })
  const { error, data } = await API.getCategorySizes(id)
  if (!error && data) {
    commit('setForm', { ...state.form, sizes: data })
  }
  commit('setLoaders', { categorySizes: false })
}

const addCategory = ({ commit }: ActionContext<IEditMenuState, RootState>) => {
  commit('setForm', {
    icon: null,
    id: 0,
    in_erestaurant: false,
    is_default: false,
    name: '',
    position: 0,
    sizes: [],
    vat: '',
  })
}

/** Sizes */

const getSizes = async ({ commit, state }: ActionContext<IEditMenuState, RootState>) => {
  const { error, data } = await API.getSizes(state.activeMenuId)
  if (!error && data) {
    commit('setSizes', data)
  }
}

const getSize = async ({ commit }: ActionContext<IEditMenuState, RootState>, id: number) => {
  commit('setLoaders', { form: true })
  const { error, data } = await API.getSize(id)
  if (!error && data) {
    commit('setForm', data)
  }
  commit('setLoaders', { form: false })
  return { error, data }
}

const addSize = (
  { commit }: ActionContext<IEditMenuState, RootState>,
  { category, categoryIndex }: { category: ICategory; categoryIndex: number }
) => {
  commit('setForm', {
    id: 0,
    name: '',
    is_default: false,
    meals: [],
    in_erestaurant: false,
    categoryIndex,
    category,
  })
}

/** Multi Choice Groups */

const getMultiChoiceGroups = async ({ commit, state }: ActionContext<IEditMenuState, RootState>) => {
  const { error, data } = await API.getMultiChoiceGroups(state.activeMenuId)
  if (!error && data) {
    commit('setMultiChoiceGroups', data)
  }
}

const getMultiChoiceGroup = async ({ commit }: ActionContext<IEditMenuState, RootState>, id: number) => {
  commit('setLoaders', { form: true })
  const { error, data } = await API.getMultiChoiceGroup(id)
  if (!error && data) {
    commit('setForm', { ...data, mealsTree: [], entries: [] })
  }
  commit('setLoaders', { form: false })
  return { error, data }
}

/** Single Choice Groups */

const getSingleChoiceGroups = async ({ commit, state }: ActionContext<IEditMenuState, RootState>) => {
  const { error, data } = await API.getSingleChoiceGroups(state.activeMenuId)
  if (!error && data) {
    commit('setSingleChoiceGroups', data)
  }
}

const getSingleChoiceGroup = async ({ commit }: ActionContext<IEditMenuState, RootState>, id: number) => {
  commit('setLoaders', { form: true })
  const { error, data } = await API.getSingleChoiceGroup(id)
  if (!error && data) {
    commit('setForm', { ...data, entries: [] })
  }
  commit('setLoaders', { form: false })
  return { error, data }
}

const addChoiceGroup = (
  { commit }: ActionContext<IEditMenuState, RootState>,
  { category, size }: { category: ICategory; size: ISize }
) => {
  commit('setForm', {
    id: null,
    entries: [],
    name: '',
    in_erestaurant: false,
    is_required: false,
    category,
    size,
    defaultEntry: null,
    meals: [],
  })
}

const getChoiceGroupEntries = async (
  { state, commit }: ActionContext<IEditMenuState, RootState>,
  { id, apiCall }: { id: number; apiCall: string }
) => {
  commit('setLoaders', { entries: true })
  const { error, data } = await API[apiCall](id)
  if (!error && data) {
    commit('setForm', {
      ...state.form,
      entries: data,
      defaultEntry: data.find((entry: IEntry) => entry.is_default)?.id,
    })
  }
  commit('setLoaders', { entries: false })
  return { error, data }
}

const getMealsTree = async ({ dispatch, commit, state }: ActionContext<IEditMenuState, RootState>) => {
  commit('setLoaders', { mealsTree: true })
  await dispatch('_menu/getMealsTree', state.activeMenuId, { root: true })
  commit('setLoaders', { mealsTree: false })
}

const cloneChoiceGroup = async ({ state, commit, dispatch }: ActionContext<IEditMenuState, RootState>, id: number) => {
  commit('setLoaders', { form: true })
  const { error, data } = await API[
    state.menuElement === 'single_choice_groups' ? 'cloneSingleChoiceGroup' : 'cloneMultiChoiceGroup'
  ](id)
  if (!error && data) {
    commit('setForm', { ...data, entries: [] })
    dispatch(state.menuElement === 'single_choice_groups' ? 'getSingleChoiceGroups' : 'getMultiChoiceGroups')
  }
  commit('setLoaders', { form: false })
  return { error, data }
}

/** Meal Groups */

const getMealGroups = async ({ getters, commit }: ActionContext<IEditMenuState, RootState>) => {
  const { error, data } = await API.getMealGroups(getters.getActiveMenuId)
  if (!error && data) {
    commit('setMealGroups', data)
  }
}

const getMealGroup = async ({ commit }: ActionContext<IEditMenuState, RootState>, id: number) => {
  commit('setLoaders', { form: true })
  const { error, data } = await API.getMealGroup(id)
  if (!error && data) {
    commit('setForm', data)
  }
  commit('setLoaders', { form: false })
  return { error, data }
}

const getMealGroupMeals = async ({ state, commit }: ActionContext<IEditMenuState, RootState>, mealGroupId: number) => {
  commit('setLoaders', { mealGroupMeals: true })
  const { error, data } = await API.getMealGroupMeals(mealGroupId)
  if (!error && data) {
    commit('setForm', { ...state.form, meals: data })
  }
  commit('setLoaders', { mealGroupMeals: false })
}

const getMealGroupIngredients = async (
  { state, commit }: ActionContext<IEditMenuState, RootState>,
  mealGroupId: number
) => {
  commit('setLoaders', { mealGroupIngredients: true })
  const { error, data } = await API.getMealGroupIngredients(mealGroupId)
  if (!error && data) {
    commit('setForm', { ...state.form, ingredients: data })
  }
  commit('setLoaders', { mealGroupIngredients: false })
}

const addMealGroup = ({ getters, commit }: ActionContext<IEditMenuState, RootState>) => {
  commit('setForm', {
    id: 0,
    name: '',
    description: '',
    image: null,
    imageBase64: '',
    in_erestaurant: false,
    category: getters.selectedCategoryId,
    sizes: [],
    ingredients: [],
    meals: [],
  })
}

const refreshElementsList = async ({ dispatch }: ActionContext<IEditMenuState, RootState>, elementType: string) => {
  switch (elementType) {
    case 'categories':
      await dispatch('getCategories')
      break
    case 'sizes':
      await dispatch('getSizes')
      break
    case 'single_choice_groups':
      await dispatch('getSingleChoiceGroups')
      break
    case 'multi_choice_groups':
      await dispatch('getMultiChoiceGroups')
      break
    case 'meals':
      await dispatch('getMealGroups')
      break
  }
}

const updateListByElement = (
  { state, commit }: ActionContext<IEditMenuState, RootState>,
  { list, element }: { list: string; element: IEditMenuForm }
) => {
  switch (list) {
    case 'categories': {
      commit(
        'setCategories',
        state.categories.map((el) => (el.id === element?.id ? element : el))
      )
      break
    }
  }
}

const resetLists = ({ commit }: ActionContext<IEditMenuState, RootState>) => {
  commit('setCategories', [])
  commit('setSizes', [])
  commit('setMultiChoiceGroups', [])
  commit('setSingleChoiceGroups', [])
  commit('setMealGroups', [])
}

export default {
  updateElement,
  createElement,
  deleteElement,
  updateElementsPositions,
  getCategories,
  getCategory,
  getCategorySizes,
  addCategory,
  getSizes,
  getSize,
  addSize,
  getMultiChoiceGroups,
  getMultiChoiceGroup,
  getSingleChoiceGroups,
  getSingleChoiceGroup,
  addChoiceGroup,
  getChoiceGroupEntries,
  cloneChoiceGroup,
  getMealGroups,
  getMealGroupMeals,
  getMealGroupIngredients,
  getMealGroup,
  addMealGroup,
  refreshElementsList,
  resetLists,
  updateListByElement,
  getMealsTree,
}

import { ShiftState } from './index'
import { Shift } from '@/types/interface'

const setShift = (state: ShiftState, shift: Shift) => {
  state.shift = shift
}
const setShiftDetailsRequest = (state: ShiftState, status: string) => {
  state.shiftDetailsRequest = status
}

export default {
  setShift,
  setShiftDetailsRequest,
}

<template>
  <div :id="id" class="justify-content-center">
    <div class="administration-section"><i :class="`fa fa-${icon} pr-3 pt-1`"></i>{{ title }}</div>
    <b-row class="administration-section-content mt-4">
      <b-col cols="12" md="9">
        <slot />
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: 'SettingsSection',
  props: {
    id: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
<style lang="scss">

</style>

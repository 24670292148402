import { render, staticRenderFns } from "./PickupType.vue?vue&type=template&id=ae0563e4&scoped=true&"
import script from "./PickupType.vue?vue&type=script&lang=js&"
export * from "./PickupType.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae0563e4",
  null
  
)

export default component.exports
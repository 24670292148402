<template>
  <b-tooltip :target="target" variant="light" custom-class="mapped-table-group-name-tooltip">
    <div class="text-left font-weight-medium">{{ $t('message.mapping.table.mealsInGroup') }}</div>
    <b-table-simple table-variant="light">
      <b-thead head-variant="light">
        <b-tr class="header-cells">
          <b-th>{{ $t('message.common.name') }}</b-th>
          <b-th>{{ $t('message.common.size') }}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="entryGroupMeal in entryGroupMeals" :key="entryGroupMeal.id">
          <b-td>{{ entryGroupMeal.name }}</b-td>
          <b-td>{{ entryGroupMeal.size_names.join(', ') }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-tooltip>
</template>

<script>
export default {
  name: 'MappedTableGroupNameTooltip',
  props: {
    target: {
      type: String,
      default: '',
    },
    entryGroupMeals: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss">
.mapped-table-group-name-tooltip {
  .tooltip-inner {
    background-color: white !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
}
.header-cells {
  th {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
}
</style>

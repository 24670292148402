<template>
  <b-modal id="mergeSizesModal" :title="$t('message.menu.sizes.selectMergeSizes')" hide-footer>
    <div class="editSection">
      <div v-for="size in sizesForCategory" :key="size.id" class="meals mergingSizes">
        <b-overlay :show="saving" rounded="sm" spinner-small>
          <b-button
            :disabled="size.id === id"
            class="meal meal--menu position-relative"
            @click="mergeChosenSize(size.id)"
          >
            {{ size.name }}
          </b-button>
        </b-overlay>
      </div>
    </div>
    <div>
      <b-alert show variant="warning">
        {{ $t('message.menu.sizes.deleteWarning') }}
      </b-alert>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { API } from '@/services/api/api'
import { showErrorToast } from '@/utils/common'

const STORE = '_menuEdit'

export default {
  name: 'EditMergeSizesModal',
  computed: {
    ...mapGetters(STORE, ['saving', 'menuElement', 'sizes']),
    ...mapFields(STORE, ['form.category', 'form.meals', 'form.id']),
    sizesForCategory() {
      return this.sizes.filter((size) => size.category === this.category)
    },
  },
  methods: {
    ...mapMutations(STORE, ['setSavingStatus', 'setIsEdited', 'clearForm']),
    ...mapActions(STORE, ['getSizes']),
    async mergeChosenSize(mergedSizeId) {
      this.setSavingStatus(true)
      const { error } = await API.mergeSize(this.id, { merged_size_id: mergedSizeId })
      this.$toasted.clear()
      if (!error) {
        this.setIsEdited(true)
        this.clearForm()
        await this.getSizes()
      } else {
        showErrorToast(this.$toasted, error)
      }
      this.setSavingStatus(false)
      this.$bvModal.hide('mergeSizesModal')
    },
  },
}
</script>

<style scoped>
.mergingSizes {
  display: inline-grid;
}
</style>

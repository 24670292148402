import { API } from '@/services/api/api'

const getBillingPeriod = async ({ commit }, payload = {}) => {
  const { loading, billingPeriodId, shiftEndDate } = payload
  if (loading) commit('setBillingPeriodRequest', 'loading')
  let url = `salaries/billing-period/${billingPeriodId}/`
  if (shiftEndDate) url += `?shift_end_date=${shiftEndDate}`
  const { error, data } = await API.getBillingPeriod(url)
  if (!error && data) {
    commit('setBillingPeriod', data)
    commit('setBillingPeriodRequest', 'success')
  } else {
    commit('setBillingPeriodRequest', 'error')
  }
}

const closeBillingPeriod = async ({ commit, dispatch }, payload) => {
  const { billingPeriodId, request, onSuccess, onError } = payload
  commit('setCloseBillingPeriodRequest', 'loading')
  const { error } = await API.closeBillingPeriodById(billingPeriodId, request)
  if (!error) {
    commit('setCloseBillingPeriodRequest', 'success')
    if (onSuccess) onSuccess()
    dispatch('getBillingPeriod', { billingPeriodId })
  } else {
    commit('setCloseBillingPeriodRequest', 'error')
    if (onError && error?.response?.data) onError(error?.response?.data)
  }
}

const reopenBillingPeriod = async ({ commit, dispatch }, payload) => {
  const { billingPeriodId, request, onSuccess, onError } = payload
  commit('setCloseBillingPeriodRequest', 'loading')
  const { error } = await API.openBillingPeriod(billingPeriodId, request)
  if (!error) {
    commit('setCloseBillingPeriodRequest', 'success')
    if (onSuccess) onSuccess()
    dispatch('getBillingPeriod', { billingPeriodId })
  } else {
    commit('setCloseBillingPeriodRequest', 'error')
    if (onError && error?.response?.data) onError(error?.response?.data)
  }
}

export default {
  getBillingPeriod,
  closeBillingPeriod,
  reopenBillingPeriod,
}

<template>
  <div class="widget">
    <div class="widget-body-wrapper">
      <b-row>
        <b-col>
          <b-breadcrumb class="bg-white pl-0">
            <b-breadcrumb-item :to="{ name: 'settings' }">
              {{ $t('message.settings.settings') }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              {{ $t('message.settings.employeesList') }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="btns-group">
          <router-link :to="{ name: 'employee' }" tag="button" class="btn btn-primary btn--add-employee">
            <i class="fas fa-user-plus fa-fw" aria-hidden="true" />
            <span> {{ $t('message.settings.addEmployee') }}</span>
          </router-link>
          <div>
            <toggle-button v-model="filters.byActive" aria-describedby="employeesFilter" class="mb-0" />
            <span id="employeesFilter" class="label ml-2">{{ $t('message.employeeList.showOnlyActive') }}</span>
          </div>
        </b-col>
      </b-row>
      <div class="row mt-3">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-striped table-align-v--mid">
              <thead>
                <tr>
                  <th scope="col">{{ $t('message.employeeList.name') }}</th>
                  <th scope="col">{{ $t('message.employeeList.username') }}</th>
                  <th scope="col">{{ $t('message.employeeList.phone') }}</th>
                  <th scope="col">{{ $t('message.employeeList.active') }}</th>
                  <th scope="col">{{ $t('message.employeeList.loggedIn') }}</th>
                  <th scope="col">{{ $t('message.employeeList.type') }}</th>
                  <th scope="col">{{ $t('message.employeeList.edit') }}</th>
                  <th scope="col">{{ $t('message.employeeList.remove') }}</th>
                </tr>
              </thead>
              <template v-if="getEmployeesLoading">
                <TablePlaceholder :col="7" :loading="true" />
              </template>
              <template v-else>
                <tbody>
                  <tr v-for="employee in filterEmployees" :key="employee.id">
                    <td>{{ employee.name }}</td>
                    <td>{{ employee.username }}</td>
                    <td>{{ employee.phone }}</td>
                    <td>
                      <toggle-button
                        :disabled="activeToggleDisabled(employee)"
                        :value="employee.is_active"
                        sync
                        @change="setActiveStatus(employee.id, !employee.is_active, null)"
                      />
                    </td>
                    <td>
                      <toggle-button
                        v-if="employee.role === 'courier'"
                        :value="isCourierOnline(employee.courier.status)"
                        sync
                        @change="setActiveStatus(employee.id, null, !isCourierOnline(employee.courier.status))"
                      />
                    </td>
                    <td>
                      <span>{{ getUserRoleName(employee.role) }}</span>
                    </td>
                    <td>
                      <router-link
                        :to="{ name: 'employee', params: { id: employee.id } }"
                        tag="button"
                        :disabled="editUserButtonDisabled(employee)"
                        class="btn btn-block btn-warning"
                        ><i class="fas fa-edit" />
                      </router-link>
                    </td>
                    <td>
                      <b-button
                        variant="danger"
                        :disabled="removeUserButtonDisabled(employee)"
                        @click="_removeUser(employee)"
                      >
                        <i class="far fa-trash-alt" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </template>
            </table>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="remove-employee-modal"
      :title="$t('message.employeeList.removeEmployee')"
      :cancel-title="$t('message.employeeList.no')"
      ok-variant="danger"
      :ok-title="$t('message.employeeList.yes')"
      @ok="removeUser(selectedEmployee.id)"
    >
      {{ $t('message.employeeList.areYouSure') }} {{ selectedEmployee.name }}?
    </b-modal>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import ToggleButton from 'vue-js-toggle-button/src/Button'
import store from './_store'
import TablePlaceholder from '@/components/TablePlaceholder'
import { ROLE } from '@/common/constants'
import { UsersMixins } from '@/mixins'
const STORE_NAME = '_employeesList'

export default {
  name: 'EmployeesList',
  components: { TablePlaceholder, ToggleButton },
  mixins: [UsersMixins],
  data: function () {
    return {
      userName: this.$store.getters['auth/getUserName'],
      filters: {
        byActive: false,
      },
      selectedEmployee: {},
      ROLE,
      getEmployeesLoading: false,
    }
  },
  computed: {
    ...mapGetters(STORE_NAME, {
      employees: 'employees',
      filteredByActive: 'filteredByActive',
      employeesRequestStatus: 'employeesRequestStatus',
    }),
    ...mapGetters('auth', ['hasUserRole']),
    filterEmployees() {
      let employees = []
      if (this.filters.byActive) employees = this.filteredByActive
      else employees = this.employees

      employees = employees.filter((employee) => employee.role !== ROLE.OWNER)
      return employees
    },
  },
  beforeCreate() {
    if (!this.$store.state[STORE_NAME]) this.$store.registerModule(STORE_NAME, store)
  },
  async mounted() {
    this.getEmployeesLoading = true
    await this.getEmployees()
    this.getEmployeesLoading = false
  },
  beforeDestroy() {
    this.$store.commit(`${STORE_NAME}/resetState`)
    this.$store.unregisterModule(STORE_NAME)
  },
  methods: {
    ...mapActions({
      getEmployees: '_employeesList/getEmployees',
      setUserIsActive: '_employeesList/setUserIsActive',
      updateUser: '_employeeEdit/updateUser',
      removeUser: '_employeesList/removeUser',
    }),
    ...mapMutations(STORE_NAME, {
      setEmployeeStatus: 'setEmployeeStatus',
    }),
    setActiveStatus(employeeId, isActive, loggedIn) {
      const index = this.employees.findIndex((emp) => emp.id === employeeId)
      const employee = this.employees[index]
      if (isActive !== null && !isActive) loggedIn = false
      if (loggedIn !== null && loggedIn && !employee.is_active) isActive = true
      this.setEmployeeStatus({ employeeId, isActive, loggedIn })
      this.setUserIsActive({ user_id: employeeId, is_active: isActive, logged_in: loggedIn })
    },
    isCourierOnline(status) {
      return status === '1'
    },
    _removeUser(employee) {
      this.selectedEmployee = employee
      this.$bvModal.show('remove-employee-modal')
    },
    removeUserButtonDisabled(employee) {
      return (
        (employee.role === 'courier' && this.isCourierOnline(employee.courier.status)) ||
        employee.name === this.userName ||
        (!this.hasUserRole([ROLE.OWNER, ROLE.MANAGER]) && employee.role === ROLE.MANAGER)
      )
    },
    editUserButtonDisabled(employee) {
      return !this.hasUserRole([ROLE.OWNER, ROLE.MANAGER]) && employee.role === ROLE.MANAGER
    },
    activeToggleDisabled(employee) {
      return (
        this.userName === employee.name ||
        (!this.hasUserRole([ROLE.OWNER, ROLE.MANAGER]) && employee.role === ROLE.MANAGER)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.btns-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.btn--add-employee {
  width: 100%;
  margin-bottom: 15px;

  @include media-breakpoint-up(md) {
    flex-grow: 0;
    order: 2;
    width: auto;
    margin-bottom: 0;
  }
}
</style>

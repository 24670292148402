export const validatorDictionary = {
  en: {
    attributes: {
      phone: 'phone number',
      phoneNumber: 'phone number',
      customerAddress: 'address',
      employeeType: 'employee type',
      name: 'name',
      email: 'email',
      password: 'password',
      confirmPassword: 'confirm password',
      courier_type: 'courier type',
      mean_of_transport: 'courier transport method',
      pickupType: 'pickup type',
      paymentType: 'payment type',
      customerPromisedTime: 'promised time',
      customerPromisedTimeValue: 'promised time',
      regionName: 'region name',
      startDateTime: 'start date',
      finishDateTime: 'finish date',
      started_at: 'date and time of shift start',
      finished_at: 'date and time of shift finish',
      comment: 'comment',
      role: 'employee role',
      category: 'category',
      size: 'size',
      meal: 'meal',
      categoryName: 'category name',
      categorySizeName: 'category size',
      mealName: 'meal name',
      sizePrice: 'meal price in size',
      ingredientName: 'ingredient name',
      choiceGroupName: 'group name',
      entryName: 'name',
      entryPrice: 'price',
      discountValue: 'value',
      receiptPrinterName: 'receipt printer name',
      receiptPrinterInternalId: 'receipt printer internal windows name',
      receiptTemplateName: 'receipt template name',
      receiptTemplateNoOfCopies: 'number of copies',
      mealSetName: 'meal set name',
    },
  },
  pl: {
    attributes: {
      phone: 'numer telefonu',
      phoneNumber: 'numer telefonu',
      customerAddress: 'adres',
      employeeType: 'typ pracownika',
      name: 'imię i nazwisko',
      email: 'adres email',
      password: 'hasło',
      confirmPassword: 'potwierdź hasło',
      courier_type: 'typ kuriera',
      mean_of_transport: 'środek transportu',
      pickupType: 'typ odbioru',
      paymentType: 'metoda płatności',
      customerPromisedTime: 'obietnica czasu',
      customerPromisedTimeValue: 'obietnica czasu',
      regionName: 'nazwa regionu',
      startDateTime: 'data startu',
      finishDateTime: 'data końca',
      started_at: 'data i godzina rozpoczęcia zmiany',
      finished_at: 'data i godzina zakończenia zmiany',
      comment: 'komentarz',
      role: 'typ pracownika',
      category: 'kategoria',
      size: 'rozmiar',
      meal: 'danie',
      categoryName: 'nazwy kategorii',
      categorySizeName: 'nazwy rozmiaru',
      mealName: 'nazwy dania',
      sizePrice: 'ceny dania w rozmiarze',
      ingredientName: 'nazwy składnika',
      choiceGroupName: 'nazwy grupy',
      entryName: 'nazwy',
      entryPrice: 'ceny',
      discountValue: 'wartości',
      receiptPrinterName: 'nazwa boniarki',
      receiptPrinterInternalId: 'nazwa boniarki w systemie Windows',
      receiptTemplateName: 'nazwa szablonu',
      receiptTemplateNoOfCopies: 'ilość kopii',
      mealSetName: 'nazwa zestawu',
    },
  },
  de: {
    attributes: {
      phone: 'Die Telefonnummer',
      phoneNumber: 'Die Telefonnummer',
      customerAddress: 'Die Adresse',
      employeeType: 'Der Mitarbeitertyp',
      name: 'Der Name',
      email: 'Die Email',
      password: 'Das Passwort',
      confirmPassword: 'Das Passwort bestätigen',
      courier_type: 'Der Kuriertyp',
      mean_of_transport: 'Die Kuriertransportmethode',
      pickupType: 'Der Abholtyp',
      paymentType: 'Die Zahlungsart',
      customerPromisedTime: 'Die versprochene Zeit',
      customerPromisedTimeValue: 'Die versprochene Zeit',
      regionName: 'Der Name der Region',
      startDateTime: 'Das Startdatum',
      finishDateTime: 'Das Enddatum',
      started_at: 'Das Datum und die Uhrzeit des Schichtbeginns',
      finished_at: 'Das Datum und die Uhrzeit des Schichtendes',
      comment: 'Der Kommentar',
      role: 'Die Mitarbeiterrolle',
      category: 'Kategorie',
      size: 'Größe',
      meal: 'Mahlzeit',
      categoryName: 'Der Kategoriename',
      categorySizeName: 'Die Kategorie Größe',
      mealName: 'Der Name der Mahlzeit',
      sizePrice: 'Der Essenspreis in der Größe',
      ingredientName: 'Der Essenspreis in der Größe',
      choiceGroupName: 'Der Gruppenname',
      entryName: 'Der Name',
      entryPrice: 'Der Preis',
      discountValue: 'Der Wert',
      receiptPrinterName: 'Den Namen des Belegdruckers',
      receiptPrinterInternalId: 'Der interne Windows-Name des Belegdruckers',
      receiptTemplateName: 'Den Namen der Quittungsvorlage',
      receiptTemplateNoOfCopies: 'Die Anzahl der Kopien',
      mealSetName: 'Name des Essenssets',
    },
  },
}

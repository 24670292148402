<template>
  <div class="table-responsive">
    <table class="table table-bordered table-sm">
      <thead class="thead-dark">
        <tr>
          <th scope="col">{{ $t('message.shifts.restaurantCash') }}</th>
          <th scope="col">{{ $t('message.shifts.value') }}</th>
        </tr>
      </thead>
      <template v-if="shiftDetailsRequest === 'loading'">
        <TablePlaceholder :row="4" :col="4" :loading="true" />
      </template>
      <tbody v-else>
        <tr>
          <td>{{ $t('message.shifts.cashForOrders') }}</td>
          <td>{{ priceDecimal(shift.orders_cash_balance) }}</td>
        </tr>
        <tr>
          <td>{{ $t('message.shifts.operationalCashBalance') }}</td>
          <td>{{ priceDecimal(shift.operational_cash) }}</td>
        </tr>
        <tr>
          <td>{{ $t('message.shifts.sum') }}</td>
          <td>{{ priceDecimal(shift.balance) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TablePlaceholder from '@/components/TablePlaceholder'

export default {
  name: 'CashTable',
  components: {
    TablePlaceholder,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('_shiftDetails', {
      shift: 'shift',
      shiftDetailsRequest: 'shiftDetailsRequest',
    }),
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.summary-row {
  background-color: #f1f1f1;
  font-weight: bold;
  td {
    text-align: right;
  }
}
</style>

import { API } from '@/services/api/api'
import router from '@/router'
import { User } from '@/types/interface'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import { EmployeeEditState } from './index'

const getUser = async ({ commit, state }: ActionContext<EmployeeEditState, RootState>) => {
  const { error, data } = await API.getUser(state.userId)
  if (!error && data) {
    commit('setUser', data)
  }
}
const updateUser = async ({ state }: ActionContext<EmployeeEditState, RootState>, formData: Partial<User>) => {
  const { error, data } = await API.updateUser(state.userId, formData)

  return { error, data }
}

const createUser = async ({}, formData: Partial<User>) => {
  const { error, data } = await API.createUser(formData)
  if (!error) {
    router.push({ name: 'employees-list' })
  }
  return { error, data }
}

export default {
  getUser,
  updateUser,
  createUser,
}

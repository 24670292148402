<template>
  <b-tr class="mealset-row">
    <b-td class="text-center"><i class="fas fa-align-justify fa-fw mr-3 drag-icon" /></b-td>
    <b-td>{{ getMealSetTypeName(mealSet) }}</b-td>
    <b-td>{{ mealSet.brand_name }}</b-td>
    <b-td>{{ mealSet.name }}</b-td>
    <b-td>{{ getParsedDiscount(mealSet) }}</b-td>
    <b-td>
      <div v-for="(parsedItem, index) in parseMealSetItems()" :key="index" class="mealset-row__mealset-item">
        {{ parsedItem }}
      </div>
    </b-td>
    <b-td class="text-center">
      <b-badge variant="success" v-if="mealSet.in_erestaurant">
        <i class="fas fa-check" />
      </b-badge>
    </b-td>
    <b-td class="td-fit-content">
      <b-button variant="warning" size="sm" :to="{ name: 'mealset-edit', params: { mealSet, type: mealSet.type } }">
        <i class="fas fa-edit" />
        <span class="d-none d-md-inline ml-1">{{ $t('message.common.edit') }}</span>
      </b-button>
      <b-button variant="danger" size="sm" class="ml-2" @click="removeMealSet()">
        <i class="fas fa-trash " />
        <span class="d-none d-md-inline ml-1">{{ $t('message.common.delete') }}</span>
      </b-button>
    </b-td>
  </b-tr>
</template>

<script>
import { MEALSET_ITEM_CONTEXT_CHOICES } from '@/common/constants'
import { mapGetters } from 'vuex'
import { mealSetsMixins } from '@/mixins/mealSetsMixins'

export default {
  name: 'MealSetRow',
  mixins: [mealSetsMixins],
  props: {
    mealSet: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(['menu', 'currency']),
  },
  methods: {
    async removeMealSet() {
      this.$emit('removeMealSet', this.mealSet.id)
    },
    parseMealSetItems() {
      let parsedItems = []
      this.mealSet.items.forEach((item) => {
        let count = item.count
        let text = ''
        if (count > 1) text += `${count}x `
        text += `${item.category.name}`
        if (item.sizes.length > 0 && item.context !== MEALSET_ITEM_CONTEXT_CHOICES.CONTEXT_CATEGORY) {
          let sizeNames = this.getMealSetItemSizeNames(item)
          if (sizeNames) text = `${text} - ${sizeNames}`
        }
        if (item.meals.length > 0) {
          text = `${text} - ${this.getMealSetItemMealsNames(item)}`
        }
        parsedItems.push(text)
      })
      return parsedItems
    },
  },
}
</script>

<style scoped lang="scss">
.mealset-row{

  td{
    vertical-align: baseline;
  }

  &__mealset-item{
    background-color: #f0f0f0;
    padding: 4px;
    border-radius: 2px;
    font-size: 12px;
    margin-bottom: 2px;
    border: 1px solid #0000000f;
  }
}
</style>

<template>
  <div class="region" :class="regionClass" @click="selectRegion">
    <div class="region-header">
      <div v-if="!isEdit" class="region-name">
        <span class="region-active-badge" :class="activeBadgeClass"></span>
        <span>{{ region.name }}</span>
      </div>
      <div v-else class="region-name-edit">
        <input
          v-model="region.name"
          v-validate="validateName"
          type="text"
          :placeholder="$t('message.regions.regionName')"
          class="form-control form-control-sm form-control--name"
          name="regionName"
        />
        <p v-if="errors.first('regionName')" class="text-danger">{{ errors.first('regionName') }}</p>
        <b-checkbox id="inlineCheckbox1" v-model="region.active" class="region-active-checkbox">
          {{ $t('message.regions.active') }}
        </b-checkbox>
      </div>
      <div v-if="!isOtherRegionEdited" class="region-header-buttons">
        <b-button v-if="!isEdit" size="sm" variant="warning" @click.stop="enableEdit">
          <i class="fas fa-edit" />
        </b-button>
        <b-button v-if="!isEdit" size="sm" variant="danger" @click.stop="remove"><i class="fas fa-trash" /></b-button>
        <b-button v-if="isEdit" size="sm" variant="success" @click.stop="save"
          ><i class="fas fa-check" /><span>{{ $t('message.regions.save') }}</span></b-button
        >
        <b-button v-if="isEdit" size="sm" @click.stop="cancelEdit"
          ><i class="fas fa-times" /><span>{{ $t('message.regions.cancel') }}</span></b-button
        >
      </div>
    </div>
    <div class="fields">
      <div class="field">
        <span class="field__name">{{ $t('message.regions.deliveryPrice') }}</span>
        <price-input v-if="isEdit" v-model="region.delivery_price" placeholder="0.00" size="sm" class="mt-auto mb-0" />
        <span v-else-if="region.delivery_price" class="field__value"
          >{{ priceDecimal(region.delivery_price) }} {{ currency }}</span
        >
        <span v-else class="field__value">0.00</span>
      </div>
      <div class="field">
        <span class="field__name">{{ $t('message.regions.minOrderValue') }}</span>
        <price-input v-if="isEdit" v-model="region.min_order_value" size="sm" class="mt-auto mb-0" />
        <span v-else-if="region.min_order_value" class="field__value"
          >{{ priceDecimal(region.min_order_value) }} {{ currency }}</span
        >
        <span v-else class="field__value">-</span>
      </div>
      <div class="field">
        <span class="field__name">{{ $t('message.regions.freeDeliveryFromValue') }}</span>
        <price-input v-if="isEdit" v-model="region.free_delivery_from_value" size="sm" class="mt-auto mb-0" />
        <span v-else-if="region.free_delivery_from_value" class="field__value"
          >{{ priceDecimal(region.free_delivery_from_value) }} {{ currency }}</span
        >
        <span v-else class="field__value">-</span>
      </div>
      <div class="field">
        <span class="field__name">{{ $t('message.regions.courierPrice') }}</span>
        <price-input v-if="isEdit" v-model="region.courier_price" size="sm" class="mt-auto mb-0"> </price-input>
        <span v-else-if="region.courier_price" class="field__value"
          >{{ priceDecimal(region.courier_price) }} {{ currency }}</span
        >
        <span v-else class="field__value">-</span>
      </div>
    </div>
    <div
      v-if="isNew && !validEditedRegionPoints.valid && !circleAreaRadius"
      class="polygon-info"
      :class="[getPolygonValidClass]"
    >
      <i class="fas fa-exclamation-triangle" style="margin: 0 10px" />
      <span v-if="getPolygonValidClass.includes('error')">{{ $t('message.regions.errors.noPolygon') }}</span>
      <span v-else-if="getPolygonValidClass.includes('warning')">
        {{ $t('message.regions.errors.polygonMinVertexes') }}</span
      >
    </div>
    <div v-if="isNew" class="polygon-info polygon-info--info circleArea">
      <span class="circleArea-info">
        <i class="far fa-dot-circle circleArea-icon" />
        {{ $t('message.regions.errors.areaRadiusInput') }}
      </span>

      <b-input-group append="km" class="circleArea-Input">
        <b-form-input
          id="circle-area-radius-input"
          v-model="circleAreaRadius"
          size="lg"
          type="text"
          @input="drawNewCircleArea"
        />
      </b-input-group>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PriceInput from '@/components/PriceInput'
import { isMobile, showSuccessToast } from '@/utils/common'
import Vue from 'vue'
import { i18n } from '@/utils/i18n'

export default {
  name: 'RegionsListItem',
  components: { PriceInput },
  props: {
    data: {
      type: Object,
      default: () => ({
        active: true,
        courier_price: null,
        delivery_price: null,
        free_delivery_from_value: null,
        id: 0,
        min_order_value: null,
        name: '',
        points: [],
      }),
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEdit: this.isNew || false,
      region: { ...this.data },
      circleAreaRadius: '',
      isMobile,
    }
  },
  computed: {
    ...mapGetters('_regions', {
      isEditing: 'isEditing',
      isAddingNewRegion: 'isAddingNewRegion',
      validEditedRegionPoints: 'validEditedRegionPoints',
    }),
    ...mapGetters(['currency']),
    regionClass() {
      return {
        'region--inactive': !this.isEdit && !this.region.active,
        'region--is-edit': this.isEdit,
        'region--is-new': this.isNew,
      }
    },
    activeBadgeClass() {
      return this.region.active ? 'region-active-badge--active' : 'region-active-badge--inactive'
    },
    isOtherRegionEdited() {
      return (this.isEditing || this.isAddingNewRegion) && !this.isEdit
    },
    getPolygonValidClass() {
      const n = this.validEditedRegionPoints.pointsCount
      console.log(n)
      if (n === 0) {
        return 'polygon-info--error'
      } else if (n > 0 && n < 3) {
        return 'polygon-info--warning'
      }
      return ''
    },
    validateName() {
      return this.region.name.length > 3
    },
  },
  mounted() {
    if (this.isMobile) {
      if (this.editable) this.isEdit = true
    } else {
      this.$store.commit('_regions/setIsEditing', false)
    }
  },
  methods: {
    drawNewCircleArea() {
      this.circleAreaRadius = this.circleAreaRadius.replace(/[^\d.,]/, '').replace(',', '.')
      let patt = /^\d*(\.\d+)?$/
      if (patt.test(this.circleAreaRadius)) {
        this.$eventBus.$emit('onDrawNewCircleArea', this.circleAreaRadius)
      }
    },
    enableEdit() {
      this.isEdit = true
      this.$store.commit('_regions/setIsEditing', true)
      this.$store.commit('_regions/setEditedRegionId', this.region.id)
      this.$eventBus.$emit('onStartEditRegion', this.region.id)
    },
    cancelEdit() {
      this.isEdit = false
      this.region = { ...this.data }
      if (this.isNew) this.$store.commit('_regions/setAddingNewRegion', false)
      this.$store.commit('_regions/setIsEditing', false)
      this.$store.commit('_regions/setEditedRegionId', null)
      this.$eventBus.$emit('onCancelEditRegion')
      this.$emit('onCancel')
    },
    save() {
      if (!this.validate()) {
        return false
      }
      this.adjustValues()
      const onSuccess = () => {
        this.$eventBus.$emit('onSaveRegion')
        this.$emit('onCancel')
        this.isEdit = false
        this.circleAreaRadius = null
        showSuccessToast(Vue.toasted, i18n.t('message.regions.regionUpdateSuccess'))
      }
      if (this.isNew) {
        this.$store.commit('_regions/setAddingNewRegion', false)
        this.$store.dispatch('_regions/addRegion', { region: this.region, onSuccess })
      } else {
        this.$store.commit('_regions/setIsEditing', false)
        this.$store.commit('_regions/setEditedRegionId', null)
        this.$store.dispatch('_regions/updateRegion', { region: this.region, onSuccess })
      }
    },
    remove() {
      this.$bvModal
        .msgBoxConfirm(this.$t('message.menu.deleteMsgBox.confirm'), {
          centered: true,
          size: 'sm',
          okTitle: this.$t('message.common.delete'),
          cancelTitle: this.$t('message.common.cancel'),
          okVariant: 'danger',
          cancelVariant: 'outline-secondary',
          footerClass: 'modal-footer--menu-management',
        })
        .then(async (confirm) => {
          if (!confirm) return false

          const onSuccess = () => {
            this.$eventBus.$emit('onRegionDeleted')
          }

          this.$store.dispatch('_regions/deleteRegion', { regionId: this.region.id, onSuccess })
        })
    },
    validate() {
      this.$validator.validate().then(() => {})
      let valid = true
      if (!this.isMobile() && !this.validEditedRegionPoints.valid && !this.circleAreaRadius) {
        valid = false
      }
      if (this.circleAreaRadius) {
        let patt = /^\d*(\.\d+)?$/
        if (!patt.test(this.circleAreaRadius)) {
          valid = false
        }
      }
      if (!this.region.name) {
        valid = false
      }
      return valid
    },
    selectRegion() {
      if (this.isNew) return false
      this.$eventBus.$emit('fitMapToRegion', this.region.id)
    },
    adjustValues() {
      // Django's DecimalField accepts None's(null), but doesn't accept empty strings
      if (this.region.delivery_price === '') this.region.delivery_price = null
      if (this.region.min_order_value === '') this.region.min_order_value = null
      if (this.region.free_delivery_from_value === '') this.region.free_delivery_from_value = null
      if (this.region.courier_price === '') this.region.courier_price = null
      this.region.circleAreaRadius = this.circleAreaRadius
    },
  },
}
</script>

<style scoped lang="scss">
.region {
  position: relative;
  padding: 10px;
  border-bottom: 1px solid #cecece;

  &:hover {
    background-color: #f9f9f9;
    .region-header-buttons {
      visibility: visible;
      transform: translateX(0px);
    }
  }

  &--inactive {
    background: #f3f3f3;
    .fields {
      opacity: 0.35;
    }
  }
  &--is-edit {
    border: 1px dashed #2796f9 !important;
    .region-header-buttons {
      visibility: visible;
      transform: translateX(0px);
    }
  }
  &--is-new {
    margin-bottom: 20px;
    margin-top: 20px;
    border: 1px dashed #2796f9 !important;
  }
  &-active-checkbox {
    display: flex;
    padding-left: 15px;
    margin-top: 10px;
  }
}
.region-header {
  display: flex;
  margin-bottom: 5px;
  justify-content: space-between;
}
.region-header-buttons {
  visibility: hidden;
  transform: translateX(200px);
  transition: all 0.15s ease;
  display: flex;
  align-items: flex-start;

  @include media-breakpoint-up(md) {
    position: absolute;
    top: 10px;
    right: 5px;
  }

  button {
    margin: 0 2px;
    @include media-breakpoint-down(xs) {
      padding: 8px 5px;
    }

    i + span {
      @include media-breakpoint-down(xs) {
        margin-left: 5px;
      }
    }
  }
}
.region-name {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  .badge {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400;
  }
}
.region-active-badge {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #00000017;
  margin-right: 7px;
  &--active {
    background-color: #83ce11;
    box-shadow: 0 1px 13px #83ce11ab;
  }
  &--inactive {
    background-color: red;
    box-shadow: 0 1px 13px #fd33118f;
  }
}
.fields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5px;

  &:not(:last-child) {
    border-right: 1px dashed #e4e4e4;
  }

  &__name {
    color: #50565a;
    text-align: center;
    margin-bottom: 2px;
    font-size: 11px;
    line-height: 13px;
  }
  &__value {
    font-size: 12px;
    font-weight: 700;
    @include media-breakpoint-up(md) {
      font-size: 16px;
      font-weight: 400;
    }
  }

  input {
    margin-top: auto;
  }
}
.polygon-info {
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 14px;
  margin-top: 10px;
  &--error {
    background-color: #f1332f;
  }
  &--warning {
    background-color: orange;
  }
  &--info {
    color: black;
    background-color: #77e0af;
  }
}
.circleArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-icon {
    margin: 0 15px;
    font-size: 30px;
  }
  &-info {
    display: flex;
    align-items: center;
  }
  &-Input {
    display: inline-flex;
    width: 120px;
  }
}

.region-name-edit {
  @include media-breakpoint-down(sm) {
    margin-right: 3px;
    flex-grow: 1;
  }
}
.form-control {
  &--name {
    margin-bottom: 5px;
    min-height: 32px;
  }
}
</style>
<style>
.region-active-checkbox label {
  display: flex !important;
  align-items: center !important;
}
</style>

import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { getField, updateField } from 'vuex-map-fields'

const initialState = () => {
  return {
    order: {
      order_customer: {},
      printing_error: '',
      invoice_id: null,
    },
    orderId: null,
    cart: {
      totalPrice: 0,
      discount_choice: null,
      discount_value: null,
      serviceChargeValue: null,
      serviceChargePercent: null,
      glovoServiceCharge: null,
      predictedPromisedTime: '',
    },
    paymentLoading: false,
    tableLoading: false,
    orderRequestStatus: '',
    estimatedDeliveryTime: '',
    invoice: {
      city: '',
      name: '',
      postalCode: '',
      address: '',
      taxId: '',
      hideDetails: false,
      hideDetailsVat: '',
      hideNameChosen: '',
      hideNameTypeChosen: 'default',
      paymentTypeChosen: 'order',
      paymentTypeName: '',
      customPaymentType: '',
    },
    allBills: [],
    baseBill: null,
    bills: [],
  }
}

export default {
  namespaced: true,
  state: () => initialState(),
  getters: {
    getField,
    ...getters,
  },
  mutations: {
    updateField,
    resetState: (state) => {
      Object.keys(initialState()).forEach((key) => {
        state[key] = initialState()[key]
      })
    },
    ...mutations,
  },
  actions,
}

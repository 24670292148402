<template>
  <b-modal
    ref="modal"
    :title="$t('message.mapping.selectMealToMap')"
    :visible="visible"
    no-close-on-backdrop
    no-close-on-esc
    size="xl"
    scrollable
    @hidden="close"
  >
    <b-overlay :show="loading" spinner-variant="primary" spinner-small class="h-100">
      <div class="d-flex flex-column h-100">
        <b-row v-if="mappedItem && !MENU_POS_LIST.includes(mappedItem.source)">
          <b-col>
            <b-checkbox v-model="showMappedPositions">{{ $t('message.mapping.showMappedPositions') }}</b-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <CompareMeals :mapped-item-type="getMapedItemType" />
        </b-row>
        <b-row class="mt-3 h-100 overflow-y-auto">
          <b-col cols="12 h-100 overflow-y-auto">
            <template v-if="getMapedItemType === 'meal'">
              <MappingItemsTable type="meals" :show-mapped-positions="showMappedPositions" />
            </template>
            <b-tabs v-else content-class="mt-3" justified pills>
              <b-tab :title="$t('message.mapping.singleChoice')" :active="getMapedItemType === 'singe_choice_entry'">
                <MappingItemsTable
                  key="singleChoice"
                  type="singleChoice"
                  :show-mapped-positions="showMappedPositions"
                />
              </b-tab>
              <b-tab :title="$t('message.mapping.multiChoice')" :active="getMapedItemType === 'multi_choice_entry'">
                <MappingItemsTable key="multiChoice" type="multiChoice" :show-mapped-positions="showMappedPositions" />
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
    <template #modal-footer>
      <b-container fluid>
        <b-row align-h="end">
          <b-button variant="outline-secondary" @click="cancel()">{{ $t('message.common.cancel') }}</b-button>
          <b-button variant="success" style="margin-left: 12px" :disabled="!hasMapped" @click="confirm()">{{
            $t('message.common.confirm')
          }}</b-button>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CompareMeals from '@/components/mapping-select-meal-modal/CompareMeals'
import MappingItemsTable from './MappingItemsTable'
import { MENU_POS_LIST } from '@/common/constants'

const STORE = '_mapping'

export default {
  name: 'MappingSelectMealModal',
  components: { CompareMeals, MappingItemsTable },
  data() {
    return {
      MENU_POS_LIST: MENU_POS_LIST,
      loading: false,
      visible: false,
      searchKeyword: '',
      showMappedPositions: false,
    }
  },
  computed: {
    ...mapGetters(STORE, ['mappedItem']),
    hasMapped() {
      return this.mappedItem?.mapped_entry || this.mappedItem?.mapped_meal
    },
    getMapedItemType() {
      if (!this.mappedItem) return false
      if (this.mappedItem?.entry_type) {
        return this.mappedItem.entry_type === 'single_choice_entry' ? 'singe_choice_entry' : 'multi_choice_entry'
      } else return 'meal'
    },
  },
  mounted() {
    this.$eventBus.$on('onShowMappingSelectMealModal', () => {
      this.show()
    })
  },
  destroyed() {
    this.$eventBus.$off('onShowMappingSelectMealModal')
  },
  methods: {
    ...mapActions(STORE, ['saveMappedItem']),
    ...mapMutations(STORE, ['resetMappedItem']),
    show() {
      this.visible = true
    },
    close() {
      this.showMappedPositions = false
      this.visible = false
    },
    cancel() {
      this.resetMappedItem()
      this.close()
    },
    confirm() {
      this.saveMappedItem({ mappedItem: this.mappedItem })
      this.close()
    },
    clearSearch() {
      this.searchKeyword = ''
    },
  },
}
</script>
<style lang="scss">
.search-meal {
  width: 100%;
  display: flex;
  align-items: center;

  &__input {
    width: 100%;
  }

  &__remove {
    width: 36px;
    height: 36px;
    margin-right: 10px;
    cursor: pointer;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      opacity: 1;
    }
    .fa-times-circle {
      font-size: 22px;
    }
  }
}
</style>

<template>
  <div :class="['view-container', { 'is-archive': isArchive }]">
    <b-row v-if="isArchive" align-v="center" align-h="between">
      <b-col>
        <b-breadcrumb class="pl-4 mb-0">
          <b-breadcrumb-item :to="{ name: 'settings' }">
            {{ $t('message.settings.settings') }}
          </b-breadcrumb-item>
          <b-breadcrumb-item active>
            {{ $t('message.settings.orderBase') }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </b-col>
    </b-row>
    <OrdersListFilters
      v-if="!isArchive"
      :orders="orders"
      :orders-list-filters="ordersListFilters"
      :primary-filters="primaryFilters"
      :is-archive="isArchive"
      @setFilter="setFilter($event.filter, $event.value)"
      @setPrimaryFilter="setPrimaryFilter($event)"
      @clearFilters="clearFilters()"
      @searchKeyword="searchByKeyword($event)"
    />
    <div class="card orders-table-wrapper">
      <div v-if="isArchive" class="orders-table-filters mb-2">
        <b-row class="mb-3" align-v="center" align-h="between">
          <b-col cols="9" md="9">
            <SearchOrders
              :orders="orders"
              :disabled="isLoading"
              :is-archive="isArchive"
              @searching="searchByField($event)"
            />
          </b-col>
          <b-col>
            <div class="text-right">
              <b-button
                :variant="showArchiveFilters ? 'primary' : 'outline-primary'"
                @click="toggleArchiveFilters(!showArchiveFilters)"
                ><i class="fas fa-filter" /><span class="d-none d-md-inline-block">{{
                  showArchiveFilters ? $t('message.ordersList.hideFilters') : $t('message.ordersList.showFilters')
                }}</span></b-button
              >
            </div>
          </b-col>
        </b-row>
        <div v-show="showArchiveFilters" class="orders-table-filters-container pt-2">
          <Loader v-if="isLoading" :no-transition="true" />
          <b-row class="mb-2">
            <b-col
              ><h4>{{ $t('message.ordersList.filters') }}</h4>
            </b-col>
            <b-col class="text-right">
              <b-button size="sm" class="mr-2" variant="outline-primary" @click="resetFilters"
                ><i class="fas fa-times" /><span>{{ $t('message.common.reset') }}</span></b-button
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mb-3" cols="12" md="2"> {{ $t('message.ordersList.orderStatus') }}: </b-col>
            <b-col class="mb-3">
              <b-radio-group
                v-model="finished"
                :options="statusTypeChoices"
                @input="getArchiveOrders(true)"
              ></b-radio-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mb-3" cols="12" md="2">
              <span>{{ $t('message.ordersList.filterThroughDateType') }}:</span>
            </b-col>
            <b-col class="mb-3">
              <b-radio-group
                v-model="dateType"
                :options="dateTypeChoices"
                @input="getArchiveOrders(true)"
              ></b-radio-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mb-3" cols="12" md="2"> <span>Typ zakończenia: </span></b-col>
            <b-col class="mb-3">
              <div
                v-for="finishedOrderChoice in finishedOrderChoices"
                :key="finishedOrderChoice.value"
                class="form-check form-check-inline"
              >
                <input
                  :id="'finishOrder' + finishedOrderChoice.value"
                  v-model="selectedFinishedOrderChoices"
                  class="form-check-input"
                  type="checkbox"
                  :value="finishedOrderChoice.value"
                  @change="getArchiveOrders(true)"
                />
                <label class="form-check-label" :for="'finishOrder' + finishedOrderChoice.value">{{
                  finishedOrderChoice.name
                }}</label>
              </div>
            </b-col>
          </b-row>
          <b-row align-v="end">
            <b-col cols="12" md="auto" class="mb-2">
              <b-row>
                <b-col cols="6" md="auto">
                  <label>{{ $t('message.summary.filterFromDate') }}</label>
                  <vue-ctk-date-time-picker
                    v-model="dateFilters.startDate"
                    format="YYYY-MM-DD HH:mm"
                    formatted="YYYY-MM-DD HH:mm"
                    time-format="HH:mm"
                    :minute-interval="15"
                    :label="$t('message.summary.filterFromDate')"
                    no-button-now
                    no-clear-button
                    @is-hidden="getArchiveOrders(true)"
                  />
                </b-col>
                <b-col cols="6" md="auto">
                  <label>{{ $t('message.summary.filterToDate') }}</label>
                  <vue-ctk-date-time-picker
                    v-model="dateFilters.endDate"
                    format="YYYY-MM-DD HH:mm"
                    formatted="YYYY-MM-DD HH:mm"
                    time-format="HH:mm"
                    :minute-interval="15"
                    :label="$t('message.summary.filterToDate')"
                    no-button-now
                    no-clear-button
                    @is-hidden="getArchiveOrders(true)"
                  />
                </b-col>
              </b-row>
              <b-row v-if="Object.keys(responseErrors).length">
                <b-col>
                  <response-error-msg field="finished_at" :response-errors="responseErrors" />
                  <response-error-msg field="created" :response-errors="responseErrors" />
                  <response-error-msg field="promised_time" :response-errors="responseErrors" />
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="auto" class="mb-2">
              <label for="input-live">{{ $t('message.ordersList.filterByEmployee') }}</label>
              <b-select v-model="selectedEmployeeID" class="form-control" @change="getArchiveOrders(true)">
                <b-select-option value="">-</b-select-option>
                <b-select-option v-for="employee in employees" :key="employee.id" :value="employee.id">{{
                  employee.name
                }}</b-select-option>
              </b-select>
            </b-col>
            <b-col cols="12" md="auto" class="mb-2">
              <label for="input-live">{{ $t('message.ordersList.filterByPaymentMethod') }}</label>
              <b-select v-model="selectedPaymentMethod" class="form-control" @change="getArchiveOrders(true)">
                <b-select-option value="">-</b-select-option>
                <b-select-option
                  v-for="paymentMethod in paymentMethods"
                  :key="paymentMethod.value"
                  :value="paymentMethod.value"
                  >{{ paymentMethod.name }}</b-select-option
                >
              </b-select>
            </b-col>
            <b-col cols="12" md="auto" class="mb-2">
              <label for="input-live">{{ $t('message.ordersList.source') }}</label>
              <b-select v-model="selectedMenuSource" class="form-control" @change="getArchiveOrders(true)">
                <b-select-option value="">-</b-select-option>
                <b-select-option v-for="(source, index) in getExistingMenuSources" :key="source" :value="index">{{
                  source
                }}</b-select-option>
              </b-select>
            </b-col>
            <b-col v-if="multipleRestaurants()" cols="12" md="auto" class="mb-2">
              <label for="input-live">{{ $t('message.ordersList.restaurants') }}</label>
              <b-select v-model="selectedBrandId" class="form-control" @change="getArchiveOrders(true)">
                <b-select-option value="" @click="selectedBrandName = ''"> - </b-select-option>
                <b-select-option
                  v-for="(restaurant, index) in multipleRestaurants()"
                  :key="'brand_' + index"
                  :value="index"
                  @click="selectedBrandName = restaurant.name"
                  >{{ restaurant.name }} <small v-if="restaurant.only_online">(online)</small>
                </b-select-option>
              </b-select>
            </b-col>

            <b-col cols="12" md="auto" class="mb-2">
              <label for="input-live">{{ $t('message.ordersList.filterByIsPrinted') }}</label>
              <b-select v-model="isPrinted" class="form-control" @change="getArchiveOrders(true)">
                <b-select-option value="">-</b-select-option>
                <b-select-option :value="true">{{ $t('message.ordersList.isPrinted') }}</b-select-option>
                <b-select-option :value="false">{{ $t('message.ordersList.isNotPrinted') }}</b-select-option>
              </b-select>
            </b-col>

            <b-col cols="12" md="auto" class="mb-2">
              <label for="input-live">{{ $t('message.ordersList.filterByHasInvoice') }}</label>
              <b-select v-model="hasInvoice" class="form-control" @change="getArchiveOrders(true)">
                <b-select-option value="">-</b-select-option>
                <b-select-option :value="true">{{ $t('message.ordersList.isPrinted') }}</b-select-option>
                <b-select-option :value="false">{{ $t('message.ordersList.isNotPrinted') }}</b-select-option>
              </b-select>
            </b-col>

            <b-col cols="12" md="auto" class="mb-2">
              <label for="input-live">{{ $t('message.ordersList.isTransferred') }}</label>
              <b-select v-model="isTransferred" class="form-control" @change="getArchiveOrders(true)">
                <b-select-option value="">-</b-select-option>
                <b-select-option :value="true">{{ $t('message.ordersList.yes') }}</b-select-option>
                <b-select-option :value="false">{{ $t('message.ordersList.no') }}</b-select-option>
              </b-select>
            </b-col>
          </b-row>
          <b-row v-if="!hasUserRole([ROLE.OWNER]) && showPastDaysOrdersAlert()" class="mt-1">
            <b-col>
              <b-alert show variant="warning">
                <span
                  >{{
                    $tc('message.ordersList.pastDaysOrders', pastDaysOrders, {
                      past_days_orders: pastDaysOrders,
                    })
                  }}
                  <TooltipButton
                    id="past_days_info_button"
                    button-class="defaultTooltipButton"
                    placement="bottom"
                    class="tooltip__button"
                  >
                    ?
                    <template #tooltipText>{{ $t('message.ordersList.pastDaysOrdersInfo') }}</template>
                  </TooltipButton>
                </span>
              </b-alert>
            </b-col>
          </b-row>

          <div class="row mt-2 mb-3">
            <b-col cols="4" md="3">
              <div class="form-check form-check-inline">
                <input
                  id="with_discount"
                  v-model="discount"
                  class="form-check-input"
                  type="checkbox"
                  @change="getArchiveOrders(true)"
                />
                <label class="form-check-label" for="with_discount">
                  {{ $t('message.ordersList.discountFilter') }}
                </label>
              </div>
            </b-col>
            <b-col v-if="$hasModuleAccess('payments-tips')" cols="4" md="3">
              <div class="form-check form-check-inline">
                <input
                  id="with_tips"
                  v-model="tips"
                  class="form-check-input"
                  type="checkbox"
                  @change="getArchiveOrders(true)"
                />
                <label class="form-check-label" for="with_tips">
                  {{ $t('message.ordersList.tipsFilter') }}
                </label>
              </div>
            </b-col>
            <b-col cols="4" md="3">
              <div class="form-check form-check-inline">
                <input
                  id="splittedBills"
                  v-model="isSplitted"
                  class="form-check-input"
                  type="checkbox"
                  @change="getArchiveOrders(true)"
                />
                <label class="form-check-label" for="splittedBills">
                  {{ $t('message.ordersList.splittedBills') }}
                </label>
              </div>
            </b-col>

            <div v-if="hasUberEatsIntegration" class="col-12">
              <div class="form-check form-check-inline">
                <input
                  id="with_uber_discount"
                  v-model="uberDiscount"
                  class="form-check-input"
                  type="checkbox"
                  @change="getArchiveOrders(true)"
                />
                <label class="form-check-label" for="with_uber_discount">
                  {{ $t('message.ordersList.uberDiscountFilter') }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="orders-table-container"
        :class="{ 'd-flex': isArchive }"
        :style="[showArchiveFilters ? { 'min-height': '600px' } : {}]"
      >
        <b-row v-if="isArchive" class="pt-2 m-0" align-v="center">
          <b-col cols="12" sm="8">
            <h6 v-if="numberOfOrders > 0" class="orders-summary-info">
              {{ $t('message.ordersList.total') }} <span>{{ numberOfOrders }}</span> {{ $t('message.ordersList.for') }}
              <span>{{ priceDecimal(sumOfOrdersPrice) }} {{ currency }}</span
              >. {{ $t('message.ordersList.discountsFor') }}
              <span>{{ priceDecimal(sumOfOrdersDiscount) }} {{ currency }}</span
              >.
              <template v-if="$hasModuleAccess('payments-tips')">
                {{ $t('message.ordersList.tipsFor') }}
                <span> {{ priceDecimal(sumOfOrdersTips) }} {{ currency }} </span>.
              </template>
            </h6>
            <h6 v-if="numberOfOrders === 0">
              {{ $t('message.ordersList.noOrders') }}
            </h6>
          </b-col>
          <b-col>
            <b-pagination-nav
              v-model="page"
              :number-of-pages="numberOfPages"
              :link-gen="archiveOrdersLinkGen"
              limit="5"
              use-router
              align="right"
              @input="pageChanged"
            />
          </b-col>
        </b-row>

        <div ref="ordersTable" class="table-container">
          <b-table-simple class="table orders-table">
            <thead>
              <tr>
                <th scope="col">Nr</th>
                <th v-if="multipleRestaurants()" scope="col">
                  {{ selectedBrandName || $t('message.ordersList.restaurants') }}
                </th>
                <th v-if="!isArchive" scope="col">{{ $t('message.ordersList.remainingPromisedTime') }}</th>
                <th scope="col">{{ $t('message.ordersList.createdAt') }}</th>
                <th v-if="isArchive" scope="col">{{ $t('message.ordersList.finishedAt') }}</th>
                <th v-if="isArchive" scope="col">{{ $t('message.ordersList.promisedTime') }}</th>
                <th scope="col">{{ $t('message.ordersList.customerPhone') }}</th>
                <th scope="col">{{ $t('message.ordersList.address') }}</th>
                <th scope="col">{{ $t('message.ordersList.price') }}</th>
                <th v-if="isArchive" scope="col">{{ $t('message.ordersList.discount') }}</th>
                <th v-if="isArchive && $hasModuleAccess('payments-tips')" scope="col">
                  {{ $t('message.ordersList.tips') }}
                </th>
                <th scope="col">{{ $t('message.ordersList.payment') }}</th>
                <th scope="col">{{ $t('message.ordersList.meals') }}</th>
                <th scope="col">{{ $t('message.ordersList.courier') }}</th>
                <th scope="col">{{ $t('message.ordersList.status') }}</th>
                <th v-if="isArchive" scope="col">{{ $t('message.ordersList.finishedStatus') }}</th>
                <th scope="col">{{ $t('message.ordersList.source') }}</th>
              </tr>
            </thead>
            <TablePlaceholder v-if="ordersRequest === 'loading'" :row="10" :col="isArchive ? 15 : 11" :loading="true" />
            <TablePlaceholder
              v-else-if="ordersRequest !== 'loading' && allOrders.length === 0"
              :no-data="true"
              :text="$t('message.ordersList.noOrders')"
            />
            <template v-else>
              <tbody>
                <OrderTableRow
                  v-for="order in allOrders"
                  :ref="`order_${order.id}`"
                  :key="order.id"
                  :order="order"
                  :is-archive="isArchive"
                  :open-assign-courier="openAssignCourier"
                />
              </tbody>
            </template>
          </b-table-simple>
        </div>
        <AssignCourier :order="assignCourierData" @onAssignCourierModalHidden="assignCourierData = null" />
      </div>
    </div>
    <div v-if="!isArchive" class="card orders-list">
      <div class="orders-list-items">
        <OrderListItem
          v-for="order in allOrders"
          :key="'order_list_' + order.id"
          :ref="`order_${order.id}`"
          :order="order"
          :actionColVisible="actionColVisible"
          @onOpenAssignCourier="openAssignCourier"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { DELIVERY_TYPES, PICKUP_TYPE_DELIVERY, PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT, ROLE } from '@/common/constants'
import { mapActions, mapGetters, mapState } from 'vuex'
import AssignCourier from '@/components/orders/AssignCourier'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import TablePlaceholder from '@/components/TablePlaceholder'
import decimal from 'decimal.js'
import { dateFiltersFromTimeFilters, orderMixins, ordersListMixins, responseErrorsHandler } from '@/mixins'
import OrdersListFilters from '@/components/orders/OrdersListFilters'
import TooltipButton from '@/components/TooltipButton'
import SearchOrders from '@/components/orders/SearchOrders'
import OrderTableRow from '@/components/orders/OrderTableRow'
import OrderListItem from '@/components/orders/OrderListItem'
import Loader from '@/components/Loader'

export default {
  name: 'OrdersList',
  components: {
    SearchOrders,
    TooltipButton,
    OrdersListFilters,
    AssignCourier,
    VueCtkDateTimePicker,
    TablePlaceholder,
    OrderTableRow,
    OrderListItem,
    Loader,
  },
  mixins: [dateFiltersFromTimeFilters, orderMixins, ordersListMixins, responseErrorsHandler],
  data: function () {
    const query = this.$route.query
    return {
      PICKUP_TYPE_ON_SITE,
      PICKUP_TYPE_TAKOUT,
      PICKUP_TYPE_DELIVERY,
      DELIVERY_TYPES,
      ROLE,
      dateFilters: {
        startDate: query.hasOwnProperty('startDate') ? query.startDate : this.getTodayTimeFilter('startTime'),
        endDate: query.hasOwnProperty('endDate') ? query.endDate : this.getTodayTimeFilter('endTime'),
      },
      selectedEmployeeID: query.employeeID || '',
      selectedPaymentMethod: query.paymentMethod || '',
      // Make sure initial selectedFinishedOrderChoices is array even if no value or only 1 value set in query.finishedOrderChoices
      selectedFinishedOrderChoices: query.finishedOrderChoices
        ? Array.isArray(query.finishedOrderChoices)
          ? [...query.finishedOrderChoices]
          : [query.finishedOrderChoices]
        : [],
      selectedMenuSource: query.menuSource || '',
      discount: query.discount === 'true',
      tips: query.tips === 'true',
      uberDiscount: query.uberDiscount === 'true',
      isTransferred: query.isTransferred === 'true',
      selectedBrandName: '',
      selectedBrandId: query.brand || '',
      hasInvoice: query.hasInvoice || '',
      isPrinted: query.isPrinted || '',
      paymentMethods: [
        { name: this.$t('message.orderFormFull.paymentTypes.cash'), value: 'cash' },
        { name: this.$t('message.orderFormFull.paymentTypes.paid'), value: 'paid' },
        { name: this.$t('message.orderFormFull.paymentTypes.card'), value: 'card' },
      ],
      finishedOrderChoices: [
        { name: this.$t('message.orderDetails.finishOrderChoices.canceled'), value: 'canceled' },
        { name: this.$t('message.orderDetails.finishOrderChoices.delivered'), value: 'delivered' },
        { name: this.$t('message.orderDetails.finishOrderChoices.notDelivered'), value: 'not delivered' },
        { name: this.$t('message.orderDetails.finishOrderChoices.pickedUp'), value: 'finished' },
      ],
      dateTypeChoices: [
        { text: this.$t('message.ordersList.createdAt'), value: 'created' },
        { text: this.$t('message.ordersList.finishedAt'), value: 'finished_at' },
        { text: this.$t('message.ordersList.promisedTime'), value: 'promised_time' },
      ],
      statusTypeChoices: [
        { text: this.$t('message.ordersList.statusFinished'), value: true },
        { text: this.$t('message.ordersList.statusCurrent'), value: false },
        { text: this.$t('message.ordersList.all'), value: null },
      ],
      dateType: query.hasOwnProperty('dateType') ? query.dateType : 'finished_at',
      finished: query.hasOwnProperty('finished') ? query.finished : true,
      searchKeyword: query.searchKeyword || '',
      searchBy: query.searchBy || '',
      page: query.page || 1,
      numberOfPages: 1,
      numberOfOrders: 0,
      sumOfOrdersPrice: '0.00',
      sumOfOrdersDiscount: '0.00',
      sumOfOrdersTips: '0.00',
      sumOfOrderBillValuesMismatch: '0.00',
      showArchiveFilters: this.isArchive,
      isSplitted: query.isSplitted === 'true',
    }
  },
  computed: {
    ...mapState({
      couriers: (state) => state._orders.couriers,
      employees: (state) => state._orders.employees,
    }),
    ...mapGetters('_orders', {
      orders: 'orders',
      ordersRequest: 'ordersRequest',
      couriers: 'couriers',
    }),
    ...mapGetters({
      brands: 'auth/brands',
      hasUserRole: 'auth/hasUserRole',
      getExistingMenuSources: 'auth/getExistingMenuSources',
      hasUberEatsIntegration: 'integrations/hasUberEatsIntegration',
      pastDaysOrders: 'settings/permissionsPastDaysOrders',
    }),
    ...mapGetters(['currency', 'delayOrdersListRefresh']),
    filters() {
      const filters = {
        archive: this.isArchive,
        brand: this.selectedBrandId,
        dateType: this.dateType,
        discount: this.discount,
        employeeID: this.selectedEmployeeID,
        endDate: this.dateFilters.endDate,
        finished: this.finished,
        finishedOrderChoices: JSON.stringify(this.selectedFinishedOrderChoices),
        hasInvoice: this.hasInvoice,
        isPrinted: this.isPrinted,
        isSplitted: this.isSplitted,
        isTransferred: this.isTransferred,
        menuSource: this.selectedMenuSource,
        page: this.page,
        paymentMethod: JSON.stringify(this.formattedPaymentMethod),
        searchBy: this.searchBy,
        searchKeyword: this.searchKeyword,
        startDate: this.dateFilters.startDate,
        tips: this.tips,
        uberDiscount: this.uberDiscount,
      }
      if (this.isArchive) {
        this.$router.push({
          query: {
            brand: this.selectedBrandId,
            dateType: this.dateType,
            discount: this.discount,
            employeeID: this.selectedEmployeeID,
            endDate: this.dateFilters.endDate,
            finished: this.finished,
            finishedOrderChoices: this.selectedFinishedOrderChoices,
            hasInvoice: this.hasInvoice,
            isPrinted: this.isPrinted,
            isSplitted: this.isSplitted,
            isTransferred: this.isTransferred,
            menuSource: this.selectedMenuSource,
            page: this.page,
            paymentMethod: this.selectedPaymentMethod,
            searchBy: this.searchBy,
            searchKeyword: this.searchKeyword,
            startDate: this.dateFilters.startDate,
            tips: this.tips,
            uberDiscount: this.uberDiscount,
          },
        })
      }
      return filters
    },
    queryPaymentMethods() {
      return this.$route.query.paymentMethod
    },
    formattedPaymentMethod() {
      let paymentMethod = this.selectedPaymentMethod
      paymentMethod = paymentMethod !== '' ? paymentMethod : []
      return typeof paymentMethod === 'string' ? [paymentMethod] : paymentMethod
    },
    isLoading() {
      return this.ordersRequest === 'loading'
    },
    actionColVisible(){
      return this.allOrders.some(order => order.pickup_type === PICKUP_TYPE_DELIVERY) 
    }
  },
  watch: {
    finished(newValue) {
      if (newValue === null) {
        this.dateType = 'created'
      }
    },
  },
  mounted() {
    this.$eventBus.$on('onCloseDetailsModal', (id) => {
      let orderItem = this.$refs[`order_${id}`]
      if (orderItem !== undefined && orderItem[0]) {
        this.$refs['ordersTable'].scrollTo(0, orderItem[0].offsetTop)
      }
    })
    this.selectedPaymentMethod = this.queryPaymentMethods
  },
  created() {
    this.getFiltersFromLocalStorage()
    const getOrdersMethod = this.isArchive ? this.getArchiveOrders : this.getCurrentOrders
    if (this.delayOrdersListRefresh) {
      setTimeout(() => {
        getOrdersMethod()
      }, this.delayOrdersListRefresh * 1000)
    } else {
      getOrdersMethod()
    }
    this.getOnlineCouriersList()
    if (!this.isArchive) {
      // Play notification every 10 seconds when new order is available
      this.refreshInterval = setInterval(() => {
        this.getCurrentOrders()
        this.getOnlineCouriersList()
      }, 30000)
    } else {
      this.getEmployees()
    }
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval)
    this.$eventBus.$off('onCloseDetailsModal')
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name !== 'orders-archive' &&
      to.name !== 'orders-summary' &&
      to.name !== 'settings' &&
      localStorage.getItem('dateFilters')
    )
      localStorage.removeItem('dateFilters')
    next()
  },
  methods: {
    ...mapActions({
      getOrders: '_orders/getOrders',
      getOnlineCouriersList: '_orders/getOnlineCouriersList',
      getEmployees: '_orders/getEmployees',
    }),
    pageChanged() {
      this.getArchiveOrders()
    },
    archiveOrdersLinkGen(pageNum) {
      // generating link for every page-button of pagination
      return this.$route.fullPath.replace(/page=\d/, `page=${pageNum}`)
    },
    getCurrentOrders() {
      this.getOrders({
        loading: this.orders.length <= 0,
        filters: {
          archive: false,
        },
        onSuccess: () => {
          // Play notification immediately
          this.playNotification()
        },
      })
    },
    getArchiveOrders(filterChanged = false) {
      // fetches orders from backend
      // filterChanged should be true if this method runs because of any filter change, then page should reset to 1
      if (filterChanged) this.page = 1
      this.getOrders({
        filters: this.filters,
        loading: true,
        onSuccess: ({ count, price_sum, discount_sum, tip_sum }) => {
          this.numberOfOrders = count
          this.sumOfOrdersPrice = price_sum
          this.sumOfOrdersDiscount = discount_sum
          this.sumOfOrdersTips = tip_sum
          if (count) {
            // calculating number of pages of orders
            let numberOfPages = new decimal.Decimal(count).dividedBy(this.orders.length).ceil().toNumber()
            if (!numberOfPages) numberOfPages = 1
            this.numberOfPages = numberOfPages
          }
        },
      })
    },
    openAssignCourier(order) {
      this.assignCourierData = order
      this.assignCourierIsOpen = true
    },
    openOrderDetails(orderId) {
      this.$eventBus.$emit('onShowDetailsModal', { id: orderId })
    },
    multipleRestaurants() {
      return this.brands && Object.values(this.brands).length > 1 ? this.brands : false
    },
    resetFilters() {
      this.dateFilters.startDate = ''
      this.dateFilters.endDate = ''
      this.selectedEmployeeID = ''
      this.selectedPaymentMethod = ''
      this.selectedMenuSource = ''
      this.selectedFinishedOrderChoices = []
      this.selectedBrandName = ''
      this.selectedBrandId = ''
      this.isPrinted = ''
      this.hasInvoice = ''
      this.isTransferred = ''
      this.dateType = 'finished_at'
      this.finished = true
      this.searchKeyword = null
      this.searchBy = null
      this.getFiltersFromLocalStorage()
      this.getArchiveOrders()
    },
    getOrderPrice(order) {
      let deliveryPrice = order.delivery_price
      if (deliveryPrice === null) {
        deliveryPrice = '0'
      }
      return this.priceDecimal(this.priceDecimal(order.price, false).minus(deliveryPrice))
    },
    searchByKeyword(value) {
      // If Archive, get orders from backend filtered by searchKeyword (on backend)
      // otherwise value is a list of orders that match searchKeyword (filtered by front)
      if (this.isArchive) {
        this.searchKeyword = value
        this.getArchiveOrders(true)
      } else this.searchResults = value
    },
    searchByField({ searchBy, searchKeyword } = { searchBy: null, searchKeyword: null }) {
      this.searchKeyword = searchKeyword
      this.searchBy = searchBy
      this.getArchiveOrders(true)
    },
    toggleArchiveFilters(value) {
      this.showArchiveFilters = value
    },
    showPastDaysOrdersAlert() {
      return this.pastDaysOrders > 0 && moment().diff(moment(this.dateFilters.startDate), 'days') > this.pastDaysOrders
    },
  },
}
</script>

<style lang="scss" scoped>
.view-container.is-archive {
  overflow-y: auto;
  overflow-x: hidden;
  .orders-table-container {
  }
}
.orders-table-wrapper {
  height: 100%;
  overflow-y: auto;
  display: none;
}
.orders-table-container {
  height: 100%;
  overflow: auto;
  display: none;
  thead > tr > th {
    background-color: white;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 2;
    box-shadow: 5px 0 6px rgba(21, 21, 21, 0.2);
  }
  .pagination {
    margin: 0;
  }
}
.orders-table-filters {
  position: relative;
  padding: 1rem 1rem 0 1rem;
  border-bottom: 1px solid $gray-300;
  &-container {
    position: relative;
    border-top: 1px solid $gray-300;
  }
}
.orders-table {
  border-collapse: separate;
  border-spacing: 0;
  margin: 0;
}
.orders-list {
  display: none;
}
@media (max-width: 576px) {
  .orders-list {
    display: block;
    position: relative;
    height: 100%;
    overflow-x: hidden;
    &__empty {
      height: 100%;
      width: 100%;
      font-size: 16px;
      opacity: 0.5;
    }
  }
  .is-archive {
    .orders-table-wrapper {
      display: flex;
    }
    .orders-table-container {
      flex-wrap: wrap;
    }
  }
}
@media (min-width: 577px) {
  .orders-table-container {
    display: flex;
    flex-direction: column;
  }
  .orders-table-wrapper {
    display: flex;
  }
}
.orders-summary-info {
  font-weight: 300;
  > span {
    font-weight: 500;
  }
}
</style>

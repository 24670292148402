import { mapGetters, mapActions } from 'vuex'
import { FINISHED_TYPES, ORDER_STATUSES, FISCAL_TYPES, BILL_STATUSES } from '@/common/constants'
import { mapFields } from 'vuex-map-fields'
import { API } from '@/services/api/api'
import { APILocalPrinter } from '@/services/api/apiLocalPrinter'

export const printBillMixins = {
  computed: {
    ...mapGetters(['currency']),
    ...mapGetters({
      hasFiscal: 'auth/hasFiscal',
      defaultOnsitePrintBill: 'auth/defaultOnsitePrintBill',
      defaultDeliveryPrintBill: 'auth/defaultDeliveryPrintBill',
    }),
    printBill: {
      get() {
        const currentStore = this.$store.hasModule('_orderSplitBill') ? '_orderSplitBill' : '_orderEdit'
        return this.$store.getters[`${currentStore}/getField`]('printBill')
      },
      set(value) {
        const currentStore = this.$store.hasModule('_orderSplitBill') ? '_orderSplitBill' : '_orderEdit'
        this.$store.commit(`${currentStore}/updateField`, { path: 'printBill', value })
      },
    },
  },
  methods: {
    ...mapActions(['goToDefaultOrdersList']),
    async printBillRequest(order_id, bill_id, openDrawer = true) {
      if (this.isBackendPrinting()) {
        if (this.order) {
          // order is available only in orderDetails view (printing from new order will already route to orders list)
          this.order.is_printed = true
        }
      }
      const { error, data, response } = await API.printBill(bill_id)
      if (!error) {
        if (response.status !== 200) {
          return
        }
        if (!this.isBackendPrinting()) {
          let bill = data.xml
          await this.printBillCommand(order_id, bill, bill_id, openDrawer)
        }
        if (this.$store.hasModule('_orders')) {
          this.$store.commit('_orders/updateOrderList', { id: order_id, is_printed: true })
        }
      } else {
        let response = error?.request?.response
        if (response) {
          let reason = JSON.parse(response).reason
          this.$toasted.show(reason, {
            type: 'error',
            duration: 3000,
          })
        }
      }
    },
    isBackendPrinting() {
      return JSON.parse(sessionStorage.getItem('localization')).fiscal_type !== FISCAL_TYPES.BSX
    },
    async printBillCommand(orderId, bill, bill_id, openDrawer = true, closeOrder = false) {
      if (this.order) {
        this.order.bsx_printing = true
      }
      const { error, data } = await APILocalPrinter.printBill({ cmd: 'parsexml', data: bill, password: '' })
      if (!error && data) {
        this.changeBillStatus(bill_id, BILL_STATUSES.CREATED_AND_PRINTED)
        if (this.$route.name === 'order' || this.fromModal) {
          this.order.is_printed = true
        }
        if (closeOrder) {
          await this.markOrderPrinted(orderId)
        }
        let id = data.id
        await this.getBillError(id)
        if (JSON.parse(sessionStorage.getItem('localization')).open_fiscal_drawer && openDrawer) {
          await this.openDrawer()
        }
      } else {
        this.changeBillStatus(bill_id, BILL_STATUSES.UNKNOWN_ERROR)
      }
    },
    async getBillError(id) {
      const { error, data } = await APILocalPrinter.getBillError({
        cmd: 'gethistory',
        type: 'documents',
        iddoc: id,
        password: '',
      })
      if (!error && data) {
        if (data.length === 0) {
          this.$toasted.error(this.$t('message.orderDetails.billAlreadyPrinted'), {
            type: 'error',
            duration: 3000,
          })
        } else {
          let error = data[0]['errorstr']
          if (error !== '') {
            this.$toasted.error(error)
          }
        }
      }
    },
    async openDrawer() {
      await APILocalPrinter.openDrawer({ cmd: '#DRAWER', password: '' })
    },
    async markOrderPrinted(orderId) {
      let data = {
        status: ORDER_STATUSES.ORDER_STATUS_FINISHED,
        finished_type: FINISHED_TYPES.FINISHED_ORDER_PICKED_UP,
      }
      const { error } = await API.updateOrder(orderId, data)
      if (error) {
        const msg = this.getErrorValue(error)
        this.$toasted.error(msg, {
          duration: 5000,
        })
      }
    },
    setPrintBill(type) {
      this.printBill = type === 'delivery' ? this.defaultDeliveryPrintBill : this.defaultOnsitePrintBill
    },
    async changeBillStatus(bill_id, status) {
      if (bill_id) {
        const { error } = await API.updateBill(bill_id, { status })
        if (error) {
          const msg = this.getErrorValue(error)
          this.$toasted.error(msg, {
            duration: 5000,
          })
        }
      }
    },
    getErrorValue(error) {
      const response = error?.response || null
      const data = response?.data || null
      const errorKey = data ? Object.keys(data) : null
      const errorValue = errorKey?.length ? data[errorKey[0]] : null
      return errorValue
    },
  },
}

import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { Point, Region } from '@/types/interface'

export interface RegionsState {
  regions: Array<Region>
  regionsRequest: string
  addingNewRegion: boolean
  isEditing: boolean
  editedRegionPoints: Array<Point>
  editedRegionId: number
}

const getDefaultState = () => {
  return {
    regions: <Region[]>[],
    regionsRequest: 'success',
    addingNewRegion: false,
    isEditing: false,
    editedRegionPoints: <Point[]>[],
    editedRegionId: null,
  }
}

const state = getDefaultState()

export default {
  namespaced: true,
  state,
  getters,
  mutations: {
    ...mutations,
    resetState: (state: RegionsState) => {
      Object.assign(state, getDefaultState())
    },
  },
  actions,
}

import { EmployeesListState } from './index'
import { User } from '@/types/interface'

const setEmployees = (state: EmployeesListState, employees: Array<User>) => {
  state.employees = [...employees]
}
const setEmployeeStatus = (state: EmployeesListState, payload: any) => {
  const { employeeId, isActive, loggedIn } = payload
  const index = state.employees.findIndex((emp) => emp.id === employeeId)
  const employee = state.employees[index]
  if (isActive !== null) employee.is_active = isActive
  if (employee.courier) {
    if (loggedIn !== null) employee.courier.status = loggedIn ? '1' : '0'
  }
  state.employees[index] = employee
  state.employees = [...state.employees]
}

export default {
  setEmployees,
  setEmployeeStatus,
}

import { INewOrderState } from '@/store/modules/new-order/index'
import { IOrderFormEditMode, IOrderPapu3 } from '@/types/interface'

const setMenu = (state: INewOrderState, { menu_source, menu_id }: { menu_source: number; menu_id: number }) => {
  state.menu_source = menu_source
  state.order.menu = menu_id
}

const setOrderData = (state: INewOrderState, data: IOrderPapu3) => {
  state.orderData = data
}

const fillOrderForm = (state: INewOrderState, data: any) => {
  state.order = {
    customer: {
      phone: data.order_customer?.phone,
      address: {
        address: data.address.address,
        city: data.address.city,
      },
    },
    payment: data.payment,
    delivery_type: data.delivery_type,
    pickup_type: data.pickup_type,
    menu: data.source,
    promised_time: data.promised_time,
    comment: data.comment,
    price: data.price,
  }
  state.menu_source = data.source
}

const setLoading = (state: INewOrderState, value: boolean) => {
  state.loading = value
}

const setEditMode = (state: INewOrderState, mode: IOrderFormEditMode) => {
  state.editMode = mode
}

export default {
  setOrderData,
  setMenu,
  setLoading,
  setEditMode,
  fillOrderForm,
}

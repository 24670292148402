var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isButton)?[_c('b-button',{staticClass:"bill-shortcut__btn bill-shortcut__btn--transparent m-0",on:{"click":function($event){return _vm.$emit('clicked')}}},[_c('i',{staticClass:"fas fa-plus-circle bill-shortcut__btn-icon",attrs:{"aria-label":_vm.$t('message.splitBill.addBill')}})])]:[_c('router-link',{class:[
        'bill-shortcut__link',
        {
          'bill-shortcut__link--red-full': _vm.checkIsPrintError(_vm.shortcutStatus) && !_vm.active,
          'bill-shortcut__link--red': _vm.checkIsPrintError(_vm.shortcutStatus),
          'bill-shortcut__link--green-full': _vm.checkHasDoneBadgeShown(_vm.shortcutStatus) && !_vm.active,
          'bill-shortcut__link--green': _vm.checkHasDoneBadgeShown(_vm.shortcutStatus) && _vm.active,
          'bill-shortcut__link--blue': _vm.checkIsEditable(_vm.shortcutStatus) && _vm.active,
        },
      ],attrs:{"id":`billShortcut${_vm.id}`,"to":{ name: 'orders-split-bill-edit', params: { billId: _vm.id, orderStatus: _vm.queryOrderStatus } }}},[_c('span',{staticClass:"bill-shortcut__mark"},[_vm._v(" "+_vm._s(_vm.$t('message.splitBill.mark'))+_vm._s(_vm.number)+" ")]),_c('span',{staticClass:"bill-shortcut__price"},[_vm._v(" "+_vm._s(_vm.priceDecimal(_vm.total))+" "+_vm._s(_vm.currency)+" ")]),(_vm.checkIsPrintError(_vm.shortcutStatus) || _vm.checkIsDoneAndPrinted(_vm.shortcutStatus))?_c('span',{class:[
          'bill-flag',
          {
            'bill-flag--red': _vm.checkIsPrintError(_vm.shortcutStatus),
            'bill-flag--green': _vm.checkIsDoneAndPrinted(_vm.shortcutStatus),
          },
        ]},[(_vm.checkIsPrintError(_vm.shortcutStatus))?_c('i',{staticClass:"fas fa-print",attrs:{"aria-label":_vm.$t('message.splitBill.status.printError')}}):(_vm.checkIsDoneAndPrinted(_vm.shortcutStatus))?_c('i',{staticClass:"fas fa-print",attrs:{"aria-label":_vm.$t('message.splitBill.status.printed')}}):_vm._e()]):_vm._e()]),(_vm.checkIsPrintError(_vm.shortcutStatus))?_c('b-tooltip',{attrs:{"target":`billShortcut${_vm.id}`,"variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('message.splitBill.status.printError'))+" ")]):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
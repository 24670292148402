import { IMenuState } from '@/types/interface'

const mealsTree = (state: IMenuState) => (menuId: number) => {
  return state.mealsTrees ? state.mealsTrees[menuId] : []
}
const mealSets = (state: IMenuState) => state.mealSets

export default {
  mealsTree,
  mealSets,
}

import {
  PICKUP_TYPE_DELIVERY,
  MENU_SOURCE_CHOICES,
  DATETIME_TIMEZONE,
  POS_API_DATETIME_INPUT_FORMAT,
  DISCOUNT_CHOICES,
} from '@/common/constants'
import moment from 'moment-timezone'
import store from '@/store'
import decimal from 'decimal.js'

const setOrderId = (state, orderId) => {
  state.orderId = orderId
}
const setOrder = (state, order) => {
  state.order = order
  state.externalOrder = order.external
}
const showMenu = (state, payload) => {
  state.show.menu = {
    full: payload.full,
    categories: payload.categories,
    sizes: payload.sizes,
    meals: payload.meals,
    mealSearchResults: payload.mealSearchResults,
    mealDetails: payload.mealDetails,
  }
  if (payload.mealDetails) {
    state.show.mealsMenu = true
    state.show.orderForm = false
  }
}
//showNewOrderFormLong
const toggleOrderForm = (state, value) => {
  state.show.mealsMenu = !value
  state.show.orderForm = value
}

const setEmptyCart = (state) => {
  state.cart = {
    customer: {
      address: '',
      addressComment: '',
      city: '',
      pickupType: PICKUP_TYPE_DELIVERY,
      name: '',
      paymentType: '',
      phone: sessionStorage.getItem('callerID') || store.getters['auth/getCompany']?.phone_area_code || '',
      promisedTime: moment().tz(DATETIME_TIMEZONE).format(POS_API_DATETIME_INPUT_FORMAT),
      promisedTimeValue: '',
      deliveryType: 'asap',
      tax_id: '',
      company_name: '',
      email: '',
    },
    deliveryPrice: null,
    manualDeliveryPrice: null,
    discountChoice: null,
    discountValue: '',
    discountCode: null,
    discountCodeApplied: false,
    id: null,
    meals: [],
    totalPrice: 0,
    serviceChargeValue: 0,
    serviceChargePercent: '',
    packagePrice: '',
    comment: '',
    menuSource:
      store.getters['settings/localizationPreferences'] &&
      store.getters['settings/localizationPreferences'].menu_source_manual_choice_required
        ? null
        : MENU_SOURCE_CHOICES.DEFAULT,
    lat: null,
    lon: null,
    bills: state.cart.bills,
  }
  sessionStorage.setItem('cart', JSON.stringify(state.cart))
}

const setCart = (state, data) => {
  // if order_customer city and localization city are the same => don't fill customer city
  let city =
    data.order_customer.city && data.order_customer.city !== store.getters['auth/getLocalizationCity']
      ? data.order_customer.city
      : ''
  let address = data.order_customer.address
  // adding city to NEW_ERESTAURANT order's customer address
  if (
    address &&
    data.order_customer.city &&
    data.external &&
    data.menu_source === MENU_SOURCE_CHOICES.NEW_ERESTAURANT
  ) {
    address += `, ${data.order_customer.city}`
  }
  state.cart = {
    customer: {
      name: data.order_customer.name || '',
      city: city || '',
      phone: data.order_customer.phone || '',
      address: address || '',
      addressComment: data.order_customer.address_comment || '',
      pickupType: data.pickup_type,
      promisedTime: data.promised_time || '',
      paymentType: data.payment || '',
      deliveryType: data.delivery_type,
      tax_id: data.company_tax_id || '',
      company_name: data.company_name || '',
      email: data.order_customer.email || '',
    },
    meals: data.meals,
    totalPrice: data.price,
    fullPrice: data.full_price,
    comment: data.comment,
    deliveryPrice: data.delivery_price,
    manualDeliveryPrice: data.delivery_price, // on edit - deliveryPrice is constant until changed
    discountChoice: data.discount_choice,
    discountValue: data.discount_choice ? data.discount_value : '',
    discountCode: data.discount_code,
    discountCodeApplied: !!data.discount_code,
    serviceChargeValue: data.service_charge_value,
    serviceChargePercent: data.service_charge_percent !== '0.00' ? data.service_charge_percent : '',
    packagePrice: data.package_price !== '0.00' ? data.package_price : null,
    edit: true,
    id: data.id,
    number: data.number,
    menu: data.menu_source, // FIXME: Can be deleted and instead used menuSource
    menuSource: data.menu_source,
    lat: data.lat,
    lon: data.lon,
    bills: data.bills || state.cart.bills,
  }
  sessionStorage.setItem('cart', JSON.stringify(state.cart))
}

const setIsEdit = (state, isEdit) => {
  state.isEdit = isEdit
}

const setMealSaved = (state, mealSaved) => {
  state.mealSaved = mealSaved
  state.cartTotalPriceChanged = true
}

const setDeliveryAreaData = (state, data) => {
  state.deliveryArea = {
    id: data.id,
    freeDeliveryFromValue: data.free_delivery_from_value,
    minOrderValue: data.min_order_value,
    deliveryPrice: data.delivery_price,
    name: data.name,
  }
}

const addMeal = (state, meal) => {
  state.cart.meals = [...state.cart.meals, meal]
  state.mealSaved = true
  sessionStorage.setItem('cart', JSON.stringify(state.cart)) // Zamiast tego można dać watch na cały cart (deep: true) w componencie lub nawet w store i wywoływać zapisane do sesji
}

const updateMeal = (state, { meal, cartPosition }) => {
  state.cart.meals.splice(cartPosition, 1, meal)
  state.mealSaved = true
  sessionStorage.setItem('cart', JSON.stringify(state.cart))
}

const setPromisedTime = (state, value) => {
  state.cart.customer.promisedTime = moment()
    .tz(DATETIME_TIMEZONE)
    .add(value, 'minutes')
    .format(POS_API_DATETIME_INPUT_FORMAT) // assigning full date and time
  state.cart.customer.promisedTimeValue = value
  sessionStorage.setItem('cart', JSON.stringify(state.cart))
}

const setMenuSource = (state, value) => {
  state.cart.menuSource = value
  sessionStorage.setItem('cart', JSON.stringify(state.cart))
}

const removePositionFromCart = (state, cartPosition) => {
  state.cart.meals.splice(cartPosition, 1)
  sessionStorage.setItem('cart', JSON.stringify(state.cart))
  state.cartTotalPriceChanged = true
}

const setOrderOnSite = (state, orderOnSite) => {
  state.orderOnSite = orderOnSite
}

const setPaymentType = (state, value) => {
  state.cart.customer.paymentType = value
  sessionStorage.setItem('cart', JSON.stringify(state.cart))
}

const setServiceChargeValue = (state, value) => {
  state.cart.serviceChargeValue = value
}
const setDiscount = (state, discountData) => {
  /* eslint-disable */
  const { discount_type, discount_value } = discountData
  const { deliveryPrice } = state.cart
  state.cart.discountChoice = discount_type
  if (discount_type === DISCOUNT_CHOICES.DISCOUNT_CHOICE_FREE_DELIVERY) {
    state.cart.discountValue = deliveryPrice
  } else {
    state.cart.discountValue = new decimal.Decimal(discount_value).toFixed(2)
  }
  state.cart.discountCodeApplied = true
}

const clearDiscount = (state) => {
  state.cart.discountValue = null
  state.cart.discountPercent = null
  state.cart.promotionalPrice = null
  state.cart.discountCode = null
  state.cart.discountChoice = null
  state.cart.discountCodeApplied = false
}

const setEstimatedDeliveryTime = (state, data) => {
  if (data?.predicted_promised_time) {
    const predictedTime = new decimal.Decimal(data.predicted_promised_time).toFixed(0)
    state.estimatedDeliveryTime = predictedTime
    state.cart.predictedPromisedTime = moment().add(predictedTime, 'minutes').format(POS_API_DATETIME_INPUT_FORMAT)
  }
}

const savePositionInOrder = (state, deliveryAreaResponse) => {
  state.cart.lat = deliveryAreaResponse.lat
  state.cart.lon = deliveryAreaResponse.lon
  state.cart.customer.geocoded_by = deliveryAreaResponse.geocoded_by
  state.cart.customer.address_processed_through_heuristics = deliveryAreaResponse.address_processed_through_heuristics
  state.cart.customer.real_address_geolocalized = deliveryAreaResponse.real_address_geolocalized
  state.cart.customer.distance_from_localization = deliveryAreaResponse.distance_from_localization
}

const clearDeliveryPrice = (state) => {
  state.cart.manualDeliveryPrice = null
  state.cart.deliveryPrice = null // null resets cartdeliveryPrice and let it be computed
}

const setBillsInCart = (state, value) => {
  state.cart.bills = value
}

const setBills = (state, items) => {
  state.allBills = items || []
  state.bills = items && items.filter(item => !item.main_bill) || []
  state.baseBill =  items && items.find(item => item.main_bill) || null
}

const setEditOrderRequestStatus = (state, status) => {
  state.editOrderRequestStatus = status
}

const setAddressHintRequests = (state, requests) => {
  state.addressHintRequests = requests
}

const setAddressHintClicks = (state, clicks) => {
  state.addressHintClicks = clicks
}


export default {
  setOrderId,
  setOrder,
  showMenu,
  setEmptyCart,
  setIsEdit,
  setCart,
  toggleOrderForm,
  addMeal,
  updateMeal,
  setPromisedTime,
  removePositionFromCart,
  setOrderOnSite,
  setMenuSource,
  setPaymentType,
  setMealSaved,
  setDeliveryAreaData,
  setServiceChargeValue,
  setEstimatedDeliveryTime,
  setDiscount,
  clearDiscount,
  savePositionInOrder,
  clearDeliveryPrice,
  setBillsInCart,
  setBills,
  setEditOrderRequestStatus,
  setAddressHintRequests,
  setAddressHintClicks,
}

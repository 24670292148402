<template>
  <div class="mb-4">
    <b-form-group :label="$t('message.cart.discountLabel')">
      <b-form-radio-group
        id="discountsRadioBtns"
        v-model="selectedType"
        name="discountTypes"
        buttons
        size="sm"
        class="discountTypes"
        stacked
        @input="$emit('discountTypeChange', $event)"
      >
        <b-form-radio
          v-for="(item, index) in items"
          :key="index"
          :value="item.type"
          :disabled="disabled"
          :button-variant="
            disabled ? (selectedType === item.type ? 'secondary' : 'outline-secondary') : 'outline-primary'
          "
        >
          {{ item.name }}
        </b-form-radio>
      </b-form-radio-group>
    </b-form-group>
    <transition name="fade-fast">
      <price-input
        v-if="selectedItem && selectedItem.type && selectedItem.type !== DISCOUNT_CHOICES.DISCOUNT_CHOICE_FREE_DELIVERY"
        id="discountValue"
        :disabled="disabled"
        class="mt-3"
        :value="value"
        :label="selectedItem.name"
        :append-text="selectedItem.suffix"
        @input="$emit('input', $event)"
      />
    </transition>
  </div>
</template>

<script>
import PriceInput from '@/components/PriceInput'
import { mapGetters } from 'vuex'
import { DISCOUNT_CHOICES } from '@/common/constants'

const STORE = '_orderEdit'

export default {
  name: 'DiscountsGroup',
  components: {
    PriceInput,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    discountType: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      DISCOUNT_CHOICES,
      selectedType: null,
    }
  },
  computed: {
    ...mapGetters(['currency']),
    ...mapGetters(STORE, ['isCartDiscountCode']),
    items() {
      let items = [
        {
          name: this.$t('message.common.none'),
          type: null,
        },
        {
          name: this.$t('message.cart.percent'),
          type: DISCOUNT_CHOICES.DISCOUNT_CHOICE_PERCENT,
          suffix: '%',
        },
        {
          name: this.$t('message.cart.value'),
          type: DISCOUNT_CHOICES.DISCOUNT_CHOICE_VALUE,
          suffix: this.currency,
        },
        {
          name: this.$t('message.cart.promotionalPrice'),
          type: DISCOUNT_CHOICES.DISCOUNT_CHOICE_PROMO_PRICE,
          suffix: this.currency,
        },
      ]

      // show FREE_DELIVERY discount option only when cart has discount code for free delivery applied
      if (this.isCartDiscountCode && this.discountType === DISCOUNT_CHOICES.DISCOUNT_CHOICE_FREE_DELIVERY) {
        items = [
          ...items,
          {
            name: this.$t('message.cart.freeDelivery'),
            type: DISCOUNT_CHOICES.DISCOUNT_CHOICE_FREE_DELIVERY,
          },
        ]
      }
      return items
    },
    selectedItem() {
      return this.items.find((item) => item.type === this.selectedType) || null
    },
  },
  watch: {
    discountType: {
      handler: function () {
        this.selectedType = this.discountType
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
.discountTypes {
  width: 100%;
  label {
    &.disabled,
    &:disabled {
      opacity: 1;
    }
  }
}
</style>

<template>
  <b-modal
    ref="modal"
    :title="
      !ordersList ? $t('message.assignCourier.assignCourierToOrder') : $t('message.assignCourier.assignCourierToOrders')
    "
    :visible="(order && Object.values(order).length > 0) || ordersList"
    :hide-footer="true"
    @hidden="hidden()"
  >
    <b-overlay :show="loading">
      <template v-if="ordersList || (order && order.courier)">
        <button id="assign-courier-btn" class="btn btn-block btn-danger" @click="assignCourier()">
          <i class="fas fa-user-slash" />
          <span>{{ $t('message.assignCourier.none') }}</span>
        </button>
        <div class="divider divider--dashed"></div>
      </template>
      <button
        v-for="(courier, index) in couriers"
        :key="courier.id"       
        :class="['assign-courier-btn', {'assign-courier-btn--active': isActive(courier.name), 'btn-recommendation-outline': hasRecommendation(courier.id)}]"
        :value="courier.id"
        @click="assignCourier(courier.id)"
      >
        <div class="d-flex align-items-center">
          <div class="assign-courier-btn__transport">
            <i :class="`fas fa-${transportIcon(courier.mean_of_transport)}`" />
          </div>
          
          <span class="mr-auto">{{ courier.name }}</span>

          <b-badge v-if="hasRecommendation(courier.user_id)" class="btn-recommendation-badge mr-1 ">REKOMENDOWANY</b-badge>

          <template v-if="courier.condition">
            <b-badge v-if="courier.condition === COURIER_CONDITIONS.IN_RESTAURANT" variant="success" class="ml-1">{{$t('courier_in_base')}}</b-badge>
            <b-badge v-if="courier.condition === COURIER_CONDITIONS.COMING_BACK" variant="secondary" class="ml-1">{{$t('courier_coming_back')}}</b-badge>
            <b-badge v-if="courier.condition === COURIER_CONDITIONS.IN_DELIVERY" variant="danger" class="ml-1">{{$t('courier_in_delivery')}}</b-badge>
          </template>

          <template v-else>
            <b-badge v-if="courier.count_current_orders > 0" variant="danger">{{$t('courier_busy')}}</b-badge>
            <b-badge v-else variant="success">{{$t('courier_free')}}</b-badge>
          </template>
          
          <b-badge variant="light" class="ml-1">{{ courier.count_current_orders }}</b-badge>
        </div>
      </button>

      <template v-if="couriers.length <= 0">
        <p class="text-center my-5" style="opacity: 0.5">{{ $t('message.assignCourier.emptyCouriersList') }}</p>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
/* global $ */
import { mapGetters, mapMutations } from 'vuex'
import { API } from '@/services/api/api'
import { showErrorToast } from '@/utils/common'
import { COURIER_CONDITIONS } from '@/common/constants'

export default {
  name: 'AssignCourier',
  props: {
    order: {
      type: Object,
      default: () => {
        return {}
      },
    },
    orders: {
      type: Array,
      default: () => {
        return []
      },
    },    
  },
  data: function () {
    return {
      selectedCourierID: '',
      courierAssigned: false,
      loading: false,
      COURIER_CONDITIONS
    }
  },
  computed: {
    ...mapGetters({
      couriers: '_orders/couriers',
      recommendation: 'recommendations/recommendation',
    }),
    ordersList() {
      return this.orders && this.orders.length > 0
    },
    
  },
  methods: {
    ...mapMutations({
      updateOrderList: '_orders/updateOrderList',
    }),
    hidden() {
      // Clear passed data from OrdersMap component
      this.$emit('onAssignCourierModalHidden')
    },
    isActive(courierName) {
      return this.order?.courier?.name === courierName
    },
    hasRecommendation(couriedId){
      return couriedId === this.recommendation?.courier_id
    },
    async assignCourier(courierID = null) {
      this.loading = true
      this.courierAssigned = false
      let data = {
        courier_id: courierID,
      }
      if (this.ordersList) {
        // If assigning courier to more than 1 order
        data = {
          ...data,
          orders: this.orders,
        }
      }
      let response = null
      if (this.ordersList) {
        response = await API.assignCourierToOrders(data)
      } else {
        response = await API.assignCourierToOrder(this.order.id, data)
      }
      const { error, data: responseData } = response
      if (!error) {
        this.courierAssigned = true
        this.selectedCourierID = courierID
        if (this.$refs['modal']?.hide) this.$refs['modal'].hide()
        // get single or many orders
        if (this.ordersList) {
          this.$emit('couriersAssigned')
        } else {
          this.updateOrderList(responseData)
        }

        if (this.hasRecommendation(courierID)) {
          this.$store.commit('recommendations/remove')
        }
      } else {
        showErrorToast(this.$toasted, error)
      }
      this.loading = false
    },
    transportIcon (transport) {
      if (transport === 'A') return 'car'
      else if (transport === 'R') return 'bicycle'
      else if (transport === 'M') return 'motorcycle'
    }
  },
  mounted() {
    if (this.order) {
      const { courier } = this.order
      if (courier) {
        this.selectedCourierID = courier.id
      }
    }
  },
}
</script>
<style lang="scss">

.assign-courier-btn{
  
  display: block;
  width:100%;
  border: 1px solid $gray-400;
  border-radius:4px;
  padding:12px;
  font-size:16px;
  background-color: #fff;

  &:not(.assign-courier-btn--active):hover{
    border-color: $gray-500;
    background-color: $gray-400;
  }

  &:not(:last-child){
    margin-bottom: 24px;
  }

  &--active{
    color:#fff;
    background-color: $blue;
  }

  &__transport{
    width:28px;
    height:28px;
    border-radius:50%;
    background-color:#fff;
    display:inline-flex;
    align-items: center;
    justify-content: center;
    margin-right:12px;
    color: $gray-700;
  }
}
</style>

<template>
  <div>
    <div ref="_cartMealsRef" class="table-container">
      <table class="table table-sm cart-table table-striped">
        <thead>
          <tr>
            <th scope="col">{{ $t('message.cart.name') }}</th>
            <th scope="col">{{ $t('message.cart.quantity') }}</th>
            <th scope="col">{{ $t('message.cart.unitPrice') }}</th>
            <th scope="col">{{ $t('message.cart.sum') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(meal, index) in meals" :key="'meal' + index">
            <td>
              <div class="d-flex">
                <div class="meal-cell">
                  <span v-for="(textItem, index) in parseMeal(meal)" :key="index" v-html="textItem"></span>
                </div>
              </div>
            </td>

            <td>
              <span>{{ meal.quantity }}</span>
            </td>

            <td>
              <span v-if="meal.size && meal.size.price">{{ priceDecimal(meal.size.price) }} {{ currency }}</span>
              <span v-else>-</span>
            </td>

            <td>
              <span>{{ priceDecimal(meal.price) }} {{ currency }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="!meals || (meals && meals.length <= 0)" class="empty-cart">
        <span>
          {{ $t('message.cart.noMeals') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { COMPLEX_MEAL_TYPES } from '@/common/constants'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'MealsListPapu3',
  props: {
    meals: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['currency']),
  },
  methods: {
    ...mapMutations(['setBrand']),
    parseMeal(meal) {
      // returns list of texts to be shown
      if (!meal) return []
      if (meal.complexMeal && meal.complexMealType === COMPLEX_MEAL_TYPES.MEAL_SET) {
        // returns list of mealSet name + names of every submeal
        return [`${meal.name}: `, ...meal.submeals.map((submeal) => this.getParsedMeal(submeal, true, true))]
      } else return [this.getParsedMeal(meal, true)]
    },
    getParsedMeal(meal, size = true, isMealSetItem = false) {
      if (!meal.name && !isMealSetItem) return `<span class="meal-empty">BRAK</span>` // TODO: translate
      const { singleChoiceGroupEntries, multiChoiceGroupEntries, ingredients } = meal
      let mealName = meal.name
      if (meal.complexMeal && meal.complexMealType !== COMPLEX_MEAL_TYPES.MEAL_SET) {
        // HALF_PIZZA - 1/2 submeal1 / 1/2 submeal2
        mealName = `[${this.getParsedMeal(meal.submeals[0], false)} / ${this.getParsedMeal(meal.submeals[1], false)}]`
      }
      let mealSizeName = null
      if (meal.size && size) {
        mealSizeName = meal.size.name
        if (meal.size.name === 'default') {
          mealSizeName = ''
        }
      }
      let singleChoiceGroupEntriesText = this.getSingleChoiceGroupEntriesText(singleChoiceGroupEntries)

      let multiChoiceGroupEntriesText = this.getMultiChoiceGroupEntriesText(multiChoiceGroupEntries)

      let ingredientsText = this.getIngredientText(ingredients)

      let parsedMealText = ''

      if (isMealSetItem && !meal?.name) return `<strong>[${this.$t('message.common.choose')}]</strong>, `

      if (isMealSetItem && meal.quantity) parsedMealText += ` • ${parseInt(meal.quantity)}x `

      parsedMealText += `${mealName}`

      if (mealSizeName) parsedMealText = parsedMealText + ` ${mealSizeName}`

      if (singleChoiceGroupEntriesText) {
        parsedMealText += ` (${singleChoiceGroupEntriesText})`
      }

      if (multiChoiceGroupEntriesText) {
        parsedMealText += ` ${multiChoiceGroupEntriesText}`
      }

      if (ingredientsText) {
        parsedMealText += ` ${ingredientsText}`
      }

      if (isMealSetItem) {
        parsedMealText += ', '
      }

      return parsedMealText
    },
    getSingleChoiceGroupEntriesText(singleChoiceGroupEntries) {
      if (!singleChoiceGroupEntries) return false
      return singleChoiceGroupEntries
        .map(({ name }) => `<span class="meal-ingredient--single-choice">${name}</span>`)
        .join(', ')
    },
    getMultiChoiceGroupEntriesText(multiChoiceGroupEntries) {
      if (!multiChoiceGroupEntries) return false
      return multiChoiceGroupEntries.map(({ name }) => `<span class="meal-ingredient--add">+ ${name}</span>`).join(' ')
    },
    getIngredientText(ingredients) {
      if (!ingredients) return false
      return ingredients.map(({ name }) => `<span class="meal-ingredient--remove">- ${name}</span>`).join(' ')
    },
  },
}
</script>
<style lang="scss">
.meal-ingredient--single-choice {
  font-weight: bold;
  white-space: nowrap;
}
.meal-ingredient--add {
  color: green;
  white-space: nowrap;
}
.meal-ingredient--remove {
  color: red;
  white-space: nowrap;
}
.meal-empty {
  font-weight: bold;
  color: red;
}
</style>
<style lang="scss" scoped>
.cart-meals {
  height: 100%;
  border-bottom: 1px solid #ebecef;
  overflow: auto;
  display: flex;
  flex-direction: column;

  &--order-details {
    .cart-table {
      td,
      .meal-cell {
        font-size: 13px;
      }
    }
  }
}
.meal-cell {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  white-space: normal;
}

.empty-cart {
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: center;
  height: calc(100% - 35px);
  align-items: center;
  background-color: $gray-200;
  color: $gray-500;
  min-height: 60px;
}
.table-container {
  overflow: auto;
}
.cart-table {
  margin-bottom: 0;
  border-collapse: separate;
  border-spacing: 0;
  overflow: auto;
  td {
    white-space: nowrap;
    font-size: 12px;
  }
  thead > tr > th {
    background-color: white;
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    z-index: 2;
    box-shadow: 5px 0 6px rgba(21, 21, 21, 0.2);
    font-size: 12px;
  }
  th {
    @include media-breakpoint-down(md) {
      vertical-align: middle;
      font-size: 10px;
      text-align: center;
      border-right: 1px dashed $gray-500;

      &:last-of-type {
        border-right: 0;
      }
    }
  }
  &-subheader {
    th {
      background-color: #e6e6e6;
      font-size: 12px;
      border-top: 1px solid #d9dee2;
    }
  }
}
.active-meal {
  border: 2px solid $blue;
  border-radius: 0;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"toggle-filters"},[_c('b-button',{staticClass:"btn-toggle-filters",attrs:{"variant":_vm.extraFilters
        ? _vm.primaryFiltersCount
          ? 'outline-primary'
          : 'primary'
        : _vm.primaryFiltersCount
        ? 'outline-primary'
        : 'light'},on:{"click":function($event){return _vm.$emit('toggleExtraFilters')}}},[_c('i',{staticClass:"fa fa-filter"})]),(_vm.primaryFiltersCount)?_c('b-badge',{staticClass:"btn-toggle-filters-badge",attrs:{"variant":"primary","pill":""}},[_vm._v(" "+_vm._s(_vm.primaryFiltersCount)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
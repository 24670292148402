<template>
  <ul class="list list-style-none">
    <li class="list__item">
      <div class="font-weight-bold">{{ $t('message.navMain.papuHelp') }}</div>
      <div>Tel: +48 616 006 825</div>
      <div>E-mail: <a href="mailto:help@papu.io">help@papu.io</a></div>
    </li>

    <li v-if="!onlyCs" class="list__item">
      <div class="font-weight-bold mt-3">Michał Strecker</div>
      <div>CTO</div>
      <div>
        <div>Tel: +48 881 401 106</div>
        <div>E-mail: <a href="mailto:m.strecker@papu.io">m.strecker@papu.io</a></div>
      </div>
    </li>

    <li v-if="!onlyCs" class="list__item">
      <div class="font-weight-bold mt-3">Emil Konrad</div>
      <div>CEO</div>
      <div>
        <div>Tel: +48 506 199 047</div>
        <div>E-mail: <a href="mailto:e.konrad@papu.io">e.konrad@papu.io</a></div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'HelpData',
  props: ['onlyCs'],
}
</script>

<template>
  <div class="order-list-item-placeholder">
    <div class="row">
      <div class="col-2 pr-0 bg"></div>
      <div class="col-6">
        <p class="bg"></p>
        <p class="bg mt-1"></p>
        <p class="bg mt-1"></p>
      </div>
      <div class="col-4 pl-0 bg"></div>
    </div>
    <div class="row">
      <div class="col-8 pl-0 mt-2">
        <p class="bg"></p>
        <p class="bg mt-1"></p>
      </div>
      <div class="col-4 pr-0 mt-2">
        <p class="bg"></p>
        <p class="bg mt-1"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderListItemPlaceholder',
}
</script>

<style lang="scss" scoped>
.order-list-item-placeholder {
  padding: 10px 25px;
  border-bottom: 1px solid #f0f2f3;
  .bg {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShine;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    position: relative;
    border-radius: 3px;
  }
  p {
    display: block;
    margin: 0;
    height: 14px;
  }
}
</style>

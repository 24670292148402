<template>
  <div class="table-responsive">
    <table class="table table-bordered table-sm">
      <thead class="thead-dark">
        <tr>
          <th scope="col">{{ $t('message.billing.period.component') }}</th>
          <th scope="col">{{ $t('message.billing.period.amount') }}</th>
          <th scope="col">{{ $t('message.billing.period.value') }}</th>
        </tr>
      </thead>
      <template v-if="billingPeriodRequest === 'loading'">
        <TablePlaceholder :row="9" :col="3" :loading="true" />
      </template>
      <tbody v-else>
        <tr v-for="(row, index) in summaryTableData" :key="'balance_table_row_' + index">
          <td class="table-cell">
            {{ row.title }}
            <TooltipButton
              v-if="row.title === $t('message.billing.ordersToDA') && billingPeriod.not_all_orders_in_zones"
              :id="'noZoneTooltipButton_' + index"
              placement="bottom"
              variant="light"
              class="no-zone-alert"
              button-class="smallTooltipButton-exclamation"
            >
              <i class="fas fa-exclamation-circle"></i>
              <template #tooltipText>{{ $t('message.billing.notAllOrdersWithDA') }}</template>
            </TooltipButton>
          </td>
          <td class="table-cell">{{ row.amount }}</td>
          <td class="table-cell">{{ row.val }}</td>
        </tr>
        <tr class="summary-row">
          <td colspan="2">{{ $t('message.billing.balance') }}</td>
          <td>{{ billingPeriod.total_salary }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TablePlaceholder from '@/components/TablePlaceholder'
import TooltipButton from '@/components/TooltipButton'

export default {
  name: 'SummaryTable',
  components: {
    TooltipButton,
    TablePlaceholder,
  },
  computed: {
    ...mapGetters('_billingPeriodDetails', {
      billingPeriod: 'billingPeriod',
      billingPeriodRequest: 'billingPeriodRequest',
    }),
    summaryTableData() {
      const {
        man_hours,
        man_hours_salary,
        mileage,
        mileage_salary,
        orders,
        orders_salary,
        zones_salary,
        operational_balance,
        orders_cash_balance,
        advances,
        penalties,
        bonuses,
      } = this.billingPeriod
      const rows = [
        { title: this.$t('message.billing.manHours'), amount: man_hours, val: man_hours_salary },
        { title: this.$t('message.billing.kilometrage'), amount: mileage, val: mileage_salary },
        { title: this.$t('message.billing.orders'), amount: orders, val: orders_salary },
        { title: this.$t('message.billing.ordersToDA'), amount: orders, val: zones_salary },
        { title: this.$t('message.billing.operationalCashBalance'), amount: '', val: operational_balance },
        { title: this.$t('message.billing.cashFromOrders'), amount: '', val: orders_cash_balance },
        { title: this.$t('message.billing.advances'), amount: '', val: advances },
        { title: this.$t('message.billing.penalties'), amount: '', val: penalties },
        { title: this.$t('message.billing.bonuses'), amount: '', val: bonuses },
      ]
      return rows
    },
  },
}
</script>

<style scoped lang="scss">
.summary-row {
  background-color: #f1f1f1;
  font-weight: bold;
  td {
    text-align: right;
  }
}
</style>

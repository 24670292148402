<template>
  <b-modal
    id="mealSetsModal"
    ref="mealSetsModal"
    title="Wybierz zestaw pół na pół"
    :visible="visible"
    hide-header-close
    hide-footer
    @hidden="hide"
  >
    <Meals :meals="mealSets" :is-meal-sets="true" @input="mealSetChosen" />
  </b-modal>
</template>

<script>
import Meals from '@/components/meals-menu/Meals'

export default {
  name: 'MealsetsModal',
  components: { Meals },
  props: ['mealSets'],
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    },
    mealSetChosen(meal) {
      this.$emit('onMealSetChosen', meal)
      this.hide()
      this.$refs.mealSetsModal.hide()
    },
  },
}
</script>

import { MappingState } from './index'
import { IMenuSource } from '@/types/interface'
import { MENU_SOURCE_CHOICES } from '@/common/constants'

const selectedSourceId = (state: MappingState) => state.selectedSourceId
const getSelectedSourceMenuID = (state: MappingState, getters: any, rootState: any, rootGetters: any) => {
  if (state.selectedBrandId) {
    return rootGetters['auth/getSourceById'](state.selectedBrandId, state.selectedSourceId)?.menu_id
  } else return null
}
const getOrderSourceMenuID = (state: MappingState, getters: any, rootState: any, rootGetters: any) => {
  if (state.order?.menu_source) {
    return rootGetters['auth/getSourceById'](state.selectedBrandId, parseInt(state.order?.menu_source))?.menu_id
  } else return null
}

const selectedBrandId = (state: MappingState) => state.selectedBrandId
const selectedBrandName = (state: MappingState, getters: any, rootState: any) =>
  state.selectedBrandId ? rootState?.auth.brands?.[state.selectedBrandId]?.name : ''
const getDefaultMenuId = (state: MappingState, getters: any, rootState: any, rootGetters: any): number | null => {
  if (state.selectedBrandId) {
    return rootGetters['auth/getSourceById'](state.selectedBrandId, parseInt(MENU_SOURCE_CHOICES.DEFAULT))?.menu_id
  } else return null
}

const selectedMappedTypeList = (state: MappingState) => state.selectedMappedTypeList
const mappedList = (state: MappingState) => state.mappedList
const mappedListPagination = (state: MappingState) => state.mappedListPagination
const mappedItem = (state: MappingState) => state.mappedItem
const mappingMealsList = (state: MappingState) => state.mappingMealsList
const mappingSingleChoiceEntriesList = (state: MappingState) => state.mappingSingleChoiceEntriesList
const mappingMultiChoiceEntriesList = (state: MappingState) => state.mappingMultiChoiceEntriesList
const mappingMode = (state: MappingState) => state.mappingMode
const isStaticMode = (state: MappingState) => state.mappingMode === 'static'
const isDynamicMode = (state: MappingState) => state.mappingMode === 'dynamic'
const loaders = (state: MappingState) => state.loaders
const showMappedOnly = (state: MappingState) => state.showMappedOnly
const showDefaultMenuPositions = (state: MappingState) => state.showDefaultMenuPositions

const singleChoiceGroups = (state: MappingState) => state.singleChoiceGroups
const multiChoiceGroups = (state: MappingState) => state.multiChoiceGroups

export default {
  selectedSourceId,
  getSelectedSourceMenuID,
  getOrderSourceMenuID,
  selectedBrandId,
  selectedBrandName,
  getDefaultMenuId,
  selectedMappedTypeList,
  mappedList,
  mappedListPagination,
  mappedItem,
  mappingMealsList,
  mappingSingleChoiceEntriesList,
  mappingMultiChoiceEntriesList,
  mappingMode,
  isStaticMode,
  isDynamicMode,
  loaders,
  showMappedOnly,
  showDefaultMenuPositions,
  singleChoiceGroups,
  multiChoiceGroups,
}

<template>
  <div class="widget">
    <div class="widget-body-wrapper">
      <div class="container-fluid mt-3">
        <div class="row">
          <div class="col-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb bg-white pl-0">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'settings' }" tag="a">{{ $t('message.settings.settings') }}</router-link>
                </li>
                <li class="breadcrumb-item active">
                  {{ $t('message.settings.currentBillingPeriods') }}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <button
              class="btn btn-primary btn-block"
              @click="
                showHidden = !showHidden
                getCurrentBillingPeriods({ loading: true })
              "
            >
              {{ showHidden ? $t('message.billing.hideRemovedEmployees') : $t('message.billing.showRemovedEmployees') }}
            </button>
          </div>
          <div class="col-3">
            <select
              v-model="selectedEmployeeID"
              class="form-control"
              @change="getCurrentBillingPeriods({ loading: true })"
            >
              <option value="">{{ $t('message.billing.filterByEmployee') }}</option>
              <option v-for="(employee, index) in employees" :key="'employee_' + index" :value="employee.id">
                {{ employee.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">{{ $t('message.billing.name') }}</th>
                    <th scope="col">{{ $t('message.billing.startedAt') }}</th>
                    <th scope="col">{{ $t('message.billing.manHours') }}</th>
                    <th scope="col">{{ $t('message.billing.advances') }}</th>
                    <th scope="col">{{ $t('message.billing.penalties') }}</th>
                    <th scope="col">{{ $t('message.billing.bonuses') }}</th>
                    <th scope="col">{{ $t('message.billing.kilometrage') }}</th>
                    <th scope="col">{{ $t('message.billing.numberOfOrders') }}</th>
                    <th scope="col">{{ $t('message.billing.toBePaidOut') }}</th>
                    <th scope="col">{{ $t('message.billing.finishedAt') }}</th>
                  </tr>
                </thead>
                <template v-if="billingPeriodsRequest === 'loading'">
                  <TablePlaceholder :row="10" :col="10" :loading="true" />
                </template>
                <tbody v-else>
                  <BillingPeriodRow v-for="(user, id) in billingPeriods" :key="`billing_period_${id}`" :user="user" />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <billing-period-entry-modal />
  </div>
</template>

<script>
import store from './_store'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import TablePlaceholder from '@/components/TablePlaceholder'
import BillingPeriodRow from './_components/BillingPeriodRow'
import BillingPeriodEntryModal from './_components/BillingPeriodEntryModal'

const STORE_NAME = '_currentBillingPeriods'

export default {
  name: 'CurrentBillingPeriods',
  components: {
    BillingPeriodEntryModal,
    TablePlaceholder,
    BillingPeriodRow,
  },
  data: function () {
    return {
      selectedEmployeeID: '',
      selectedBillingPeriodID: '',
      showHidden: false,
      formData: {
        billingPeriodEntryID: '',
        amount: 0.0,
        comment: '',
        type: '',
      },
      billingPeriodEntryTypes: [
        { name: this.$t('message.billing.advances'), value: 1 },
        { name: this.$t('message.billing.penalties'), value: 2 },
        { name: this.$t('message.billing.bonuses'), value: 3 },
      ],
    }
  },
  computed: {
    ...mapGetters(STORE_NAME, {
      billingPeriods: 'billingPeriods',
      billingPeriodsRequest: 'billingPeriodsRequest',
    }),
    ...mapGetters({
      employees: 'employees/employees',
    }),
  },
  methods: {
    ...mapActions(STORE_NAME, {
      getBillingPeriods: 'getBillingPeriods',
    }),
    getCurrentBillingPeriods(payload = {}) {
      this.getBillingPeriods({
        ...payload,
        filters: {
          employee_id: this.selectedEmployeeID,
          show_hidden: this.showHidden,
        },
      })
    },
    resetFormData() {
      this.formData = {
        billingPeriodEntryID: '',
        amount: 0.0,
        comment: '',
        type: '',
      }
    },
  },
  beforeCreate() {
    if (!this.$store.state[STORE_NAME]) this.$store.registerModule(STORE_NAME, store)
  },
  created() {
    this.getBillingPeriods({ loading: true })
    this.$store.dispatch('employees/getEmployees')
  },
}
</script>

<template>
  <div class="bill-position">
    <transition name="slide-left">
      <div v-if="editableBill">
        <b-overlay
          :show="processing"
          rounded="sm"
          class="mr-3"
          variant="primary"
          spinner-small
          spinner-variant="light"
          opacity="1"
        >
          <b-button v-if="moveAction === 'onLeft'" variant="primary" class="bill-position__btn" @click="returnPosition">
            <i class="fas fa-arrow-left" :aria-label="$t('message.common.move')" />
          </b-button>
        </b-overlay>
      </div>
    </transition>
    <h3 :class="['bill-position__title', { 'pl-0': moveAction === 'onRight' }]">
      {{ item.name }}
    </h3>
    <p class="bill-position__price-group">
      <span class="bill-position__quantity">
        {{ item.quantity }}
      </span>
      x
      <span class="bill-position__price"> {{ priceDecimal(item.price) }} {{ currency }} </span>
    </p>
    <p class="bill-position__total">{{ priceDecimal(item.value) }} {{ currency }}</p>
    <b-button
      v-if="moveAction === 'onRight'"
      variant="primary"
      class="bill-position__btn ml-3"
      @click="selectBillPosition(item)"
    >
      <i class="fas fa-arrow-right" :aria-label="$t('message.common.move')" />
    </b-button>
  </div>
</template>

<script>
import { billStatusMixins } from '@/mixins'
import { mapGetters } from 'vuex'

const STORE = '_orderSplitBill'

export default {
  name: 'BillPosition',
  mixins: [billStatusMixins],
  props: {
    moveAction: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      processing: false,
    }
  },
  computed: {
    ...mapGetters({
      currency: 'currency',
      sourceBillId: `${STORE}/selectedSourceBillId`,
      toBillId: `${STORE}/selectedToBillId`,
    }),
  },
  methods: {
    selectBillPosition(item) {
      if (this.editableBill) {
        this.$store.commit(`${STORE}/setSelectedBillPosition`, item)
        this.$bvModal.show('moveItemModal')
      } else {
        this.$toasted.error(this.$t('message.splitBill.error.movePosition'), {
          duration: 5000,
        })
      }
    },
    async returnPosition() {
      const returnSourceBillId = this.toBillId
      const returnToBillId = this.sourceBillId

      this.processing = true
      try {
        await this.$store.dispatch(`${STORE}/movePosition`, {
          quantity: this.item.quantity,
          positionId: this.item.id,
          sourceBillId: returnSourceBillId,
          toBillId: returnToBillId,
        })
      } catch ({ errorStatus, errorValue }) {
        const msg = errorStatus !== 500 && errorValue ? errorValue : this.$t('message.common.anErrorOccured')
        this.$toasted.error(msg, {
          duration: 5000,
        })
      }
      this.processing = false
    },
  },
}
</script>

<style lang="scss" scoped>
.bill-position {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: 0px 1px 0px rgba(165, 165, 165, 0.25);
  padding: 9px 16px;
  min-height: 58px;

  &__title,
  &__quantity,
  &__price-group,
  &__price,
  &__total {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0;
  }

  &__title,
  &__price-group,
  &__total {
    padding: 0 8px;
  }

  &__title {
    flex-grow: 1;
  }

  &__btn {
    width: 56px;
    height: 40px;
  }

  &__price-group,
  &__total {
    flex-shrink: 0;
  }
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.7s;
}
.slide-left-enter,
.slide-left-leave-to {
  width: 0;
  opacity: 0;
  overflow: hidden;
}
</style>

import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { getField, updateField } from 'vuex-map-fields'

const initialState = {
  orderId: null,
  selectedBillPosition: null,
  selectedSourceBillId: null,
  selectedToBillId: null,
  allBills: [],
  baseBill: null,
  bills: [],
  billsRequestStatus: '',
  billsLimit: 20,
  //printBillMixin uses printBill
  printBill: false,
}

export default {
  namespaced: true,
  state: () => {
    return { ...initialState }
  },
  getters: {
    getField,
    ...getters,
  },
  mutations: {
    updateField,
    resetState: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    ...mutations,
  },
  actions,
}

<template>
  <div class="map-container">
    <div class="map-wrapper">
      <div id="map" class="map" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import 'leaflet-draw'
export default {
  name: 'Map',
  data() {
    const { lat, lon } = JSON.parse(sessionStorage.getItem('localization'))
    return {
      map: null,
      polygons: {},
      editFeatures: null,
      drawControl: null,
      circleArea: null,
      mapCenter: {
        lat,
        lon,
      },
      colors: ['#4ed60b', '#2aebec', '#2a7fec', '#efea21', '#ec8517', '#ef0808', '#444444'],
    }
  },
  computed: {
    ...mapGetters('_regions', {
      regions: 'regions',
      isAddingNewRegion: 'isAddingNewRegion',
    }),
  },
  watch: {
    regions: {
      handler() {
        this.initAllPolygons()
      },
      deep: true,
    },
  },
  mounted() {
    this.$eventBus.$on('fitMapToRegion', this.fitMapToRegion)
    this.$eventBus.$on(['onStartEditRegion'], this.initEdit)
    this.$eventBus.$on(['onAddNewRegion'], this.initAddNew)
    this.$eventBus.$on('onCancelEditRegion', this.cancelEdit)
    this.$eventBus.$on('onSaveRegion', this.cancelEdit)
    this.$eventBus.$on('onDrawNewCircleArea', this.drawCircleArea)
    this.$eventBus.$on('onRegionDeleted', this.initAllPolygons)
    this.$nextTick(() => {
      this.initMap()
      this.$emit('map-loaded')
    })
  },
  destroyed() {
    this.$eventBus.$off('fitMapToRegion')
    this.$eventBus.$off('onStartEditRegion')
    this.$eventBus.$off('onAddNewRegion')
    this.$eventBus.$off('onCancelEditRegion')
    this.$eventBus.$off('onSaveRegion')
    this.$eventBus.$off('onDrawNewCircleArea')
    this.$eventBus.$off('onRegionDeleted')
  },
  methods: {
    ...mapMutations('_regions', {
      updateEditedRegionPoints: 'updateEditedRegionPoints',
    }),
    onMapReady() {
      this.initLocalization()
      this.initMapEvents()
      this.initAllPolygons()
    },
    createPolygon({ region, index }) {
      const { points, color, area } = region
      const polygon = L.polygon(
        points.map((p) => [p.lat, p.lon]),
        { color: color || this.colors[index] || 'rgba(255,0,0,0.5)', area: area }
      )
      if (region.name) polygon.bindPopup(region.name)
      if (!region.active) {
        // hides polygon on map, but actually draws it (helps editing inactive area)
        polygon.options.stroke = false
        polygon.options.fill = false
      }
      return polygon
    },
    addPolygon(poly) {
      poly.addTo(this.map)
    },
    removePolygon(poly) {
      poly.remove()
    },
    createAllPolygons() {
      _.forEach(this.regions, (region, index) => {
        this.polygons[region.id] = this.createPolygon({ region, index })
      })
    },
    addAllPolygons() {
      // Sorting delivery areas by area descending
      // biggest area will be rendered first => bottom layer - and smallest last => top layer
      let polygonsCopy = Object.values(this.polygons)
      polygonsCopy.sort((polygon1, polygon2) => polygon1.options.area - polygon2.options.area).reverse()
      _.forEach(polygonsCopy, (poly) => this.addPolygon(poly))
    },
    removeAllPolygons() {
      _.forEach(this.polygons, (poly) => this.removePolygon(poly))
      this.polygons = {}
    },
    initAllPolygons() {
      this.removeAllPolygons()
      if (this.circleArea) this.map.removeLayer(this.circleArea)
      if (this.regions.length <= 0) return false
      this.createAllPolygons()
      this.addAllPolygons()
      this.limitDrawPolygonsOnTouch()
    },
    fitMapToRegion(regionId) {
      const bounds = this.polygons[regionId].getBounds()
      this.map.fitBounds([
        [bounds._northEast.lat, bounds._northEast.lng],
        [bounds._southWest.lat, bounds._southWest.lng],
      ])
    },
    zoomUpdate(zoom) {
      this.zoom = zoom
    },
    centerUpdate(center) {
      this.center = center
    },
    initMap() {
      this.map = L.map('map', {}).setView([this.mapCenter.lat, this.mapCenter.lon], 10)
      L.tileLayer('https://tiles.papu.io/{z}/{x}/{y}.png').addTo(this.map)
      this.initDrawControls()
      this.onMapReady()
    },
    initMapEvents() {
      const self = this
      this.map.on(L.Draw.Event.DRAWSTART, (event) => {
        self.editFeatures.clearLayers()
      })
      this.map.on(L.Draw.Event.EDITVERTEX, (event) => {
        const poly = event.poly
        if (poly) self.updateEditedRegionPoints(self.getPointsFromLatLng(poly._latlngs[0]))
      })
      this.map.on(L.Draw.Event.DRAWVERTEX, (event) => {
        const layers = event.layers
        if (layers) self.updateEditedRegionPoints(self.getPointsFromLatLng(_.map(layers._layers, (l) => l._latlng)))
      })
      this.map.on(L.Draw.Event.CREATED, (event) => {
        const layer = event.layer
        self.editFeatures.addLayer(layer)
        self.updateEditedRegionPoints(self.getPointsFromLatLng(layer._latlngs[0]))
      })
    },
    initDrawControls() {
      this.editFeatures = new L.FeatureGroup().addTo(this.map)
      this.drawControl = new L.Control.Draw({
        draw: {
          marker: false,
          polyline: false,
          circle: false,
          rectangle: false,
          circlemarker: false,
          poly: {
            allowIntersection: false,
          },
        },
        edit: {
          featureGroup: this.editFeatures,
          poly: {
            allowIntersection: false,
          },
        },
      })
    },
    initLocalization() {
      let icon = L.icon({
        iconUrl: '/static/images/localization-map-marker.svg',
        iconSize: [25, 25],
      })
      L.marker(this.mapCenter, { icon: icon }).addTo(this.map)
    },
    addDrawControl() {
      this.editFeatures.addTo(this.map)
      this.map.addControl(this.drawControl)
      if (this.isAddingNewRegion) {
        this.newDrawPolygon = new L.Draw.Polygon(this.map, this.drawControl.options.polygon)
        this.newDrawPolygon.enable()
      }
    },
    removeDrawControl() {
      if (this.newDrawPolygon) {
        this.newDrawPolygon.disable()
        this.newDrawPolygon = null
      }
      this.map.removeControl(this.drawControl)
      this.editFeatures.clearLayers()
    },
    initEdit(regionId) {
      if (!regionId) return false
      this.cancelEdit()
      this.addDrawControl()
      const polygon = this.polygons[regionId]
      // show edited polygon even if not active
      polygon.options.stroke = true
      polygon.options.fill = true

      polygon.editing.enable()
      this.updateEditedRegionPoints(this.getPointsFromLatLng(polygon._latlngs[0]))
      polygon.on('edit', () => {
        this.updateEditedRegionPoints(this.getPointsFromLatLng(polygon._latlngs[0]))
      })
      this.fitMapToRegion(regionId)
    },
    initAddNew() {
      this.cancelEdit()
      this.addDrawControl()
    },
    cancelEdit() {
      this.updateEditedRegionPoints([])
      if (this.circleArea) this.map.removeLayer(this.circleArea)
      this.removeDrawControl()
      this.initAllPolygons()
    },
    getPointsFromLatLng(latlngs) {
      let points = []
      _.forEach(latlngs, (latlng) => {
        points.push({ lat: latlng.lat, lon: latlng.lng })
      })
      return points
    },
    drawCircleArea(radius) {
      if (this.circleArea) this.map.removeLayer(this.circleArea)
      if (radius) {
        this.cancelEdit()
        this.circleArea = L.circle([this.mapCenter.lat, this.mapCenter.lon], { radius: radius * 1000 }).addTo(this.map)
      } else {
        this.initAddNew()
      }
    },
    limitDrawPolygonsOnTouch() {
      L.Draw.Polygon.prototype._onTouch = L.Util.falseFn
    },
  },
}
</script>

<style lang="scss" scoped>
.map-container {
  display: flex;
  height: 100%;
  width: 100%;
}
.map-wrapper {
  height: 100%;
  width: 100%;
  border: 1px solid #dde0e1;
}

.map {
  height: 100%;
}
</style>

<template>
  <div class="search-meal" :class="{ 'is-searching-active': isSearching }">
    <i class="far fa-times-circle search-meal__clear" @click="clear" />
    <i class="fas fa-search" />
    <div class="input-group search-meal__input">
      <input
        id="searchMealInput"
        ref="searchMealInput"
        v-model="_searchKeyword"
        type="text"
        class="form-control"
        :placeholder="$t('message.mealsMenu.search')"
        @input="onInput"
        @click="onInputClick()"
        @keydown.tab="changeFocusToMeals"
        @keydown.esc="clear"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SearchMeal',
  props: {
    onInput: {
      type: Function,
      required: true,
    },
    searchKeyword: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    _searchKeyword: {
      get() {
        return this.searchKeyword
      },
      set(value) {
        this.$emit('update:searchKeyword', value)
      },
    },
    isSearching() {
      return this._searchKeyword.length > 0
    },
  },
  mounted() {
    this.$eventBus.$on('focusSearchMealInput', this.focusInput)
  },
  beforeDestroy() {
    this.$eventBus.$off('focusSearchMealInput', this.focusInput)
  },
  methods: {
    onInputClick() {
      this.$store.commit('_orderEdit/toggleOrderForm', false)
    },
    clear() {
      this._searchKeyword = ''
    },
    focusInput() {
      if (this.isMobile) return false
      if (this.$refs.searchMealInput) {
        setTimeout(() => {
          if (this.$refs.searchMealInput) this.$refs.searchMealInput.focus()
        }, 200)
      }
    },
    changeFocusToMeals(event) {
      if (document.getElementById('meals').children.length > 0) {
        const btn = document.getElementById('meals').firstElementChild
        if (btn) btn.focus()
      }
      event.preventDefault()
    },
  },
}
</script>

<style scoped lang="scss">
.search-meal {
  position: relative;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  height: 50px;
  background-color: #fff;
  border-top-left-radius: 6px;

  &.is-searching-active {
    .fa-search {
      color: $primary;
      opacity: 0;
      transform: scale(0);
      pointer-events: none;
    }
    .search-meal__clear {
      transform: scale(1.2);
      opacity: 0.7;
    }
  }
  &__clear,
  .fa-search {
    position: absolute;
    left: 10px;
    z-index: 10;
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.25s ease;
  }
  &__clear {
    transform: scale(0);
    opacity: 0;
  }

  .form-control {
    border: none;
    height: 50px;
    padding-left: 34px;
    &:focus {
      box-shadow: inset 0 0 0 0.2rem rgba(59, 133, 255, 0.25);
    }
  }
}
</style>

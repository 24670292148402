import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { IOrderPapu3 } from '@/types/interface'

export interface IOrdersPapu3State {
  orders: IOrderPapu3[]
  ordersRequest: string
  primaryFilters: any
}

const getDefaultState = (): IOrdersPapu3State => {
  return {
    orders: [],
    ordersRequest: '',
    primaryFilters: {
      // Filters which are constant filters, not cleared when changing status/pickup type
      brand: null,
    },
  }
}

const resetState = (state: IOrdersPapu3State) => {
  Object.assign(state, getDefaultState())
}

export default {
  name: 'orders-papu-3',
  namespaced: true,
  state: getDefaultState(),
  getters,
  mutations: {
    ...mutations,
    resetState,
  },
  actions,
}

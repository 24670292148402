<template>
  <div class="edit-section d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <label class="switch mb-0 mr-2" aria-labelledby="isDefault">
        <input :checked="value" :disabled="disabled || saving" type="checkbox" @input="onClick" />
        <span class="slider round"></span>
      </label>
      <h4 id="isDefault" class="label mb-0">
        <slot name="label">
          {{ $t('message.menu.categories.defaultCategory') }}
        </slot>
      </h4>
    </div>
    <TooltipButton
      v-if="tooltipText"
      id="sizeEditTooltipButton"
      size="md"
      placement="topleft"
      button-class="defaultTooltipButton"
    >
      ?
      <template #tooltipText>
        {{ tooltipText }}
      </template>
    </TooltipButton>
  </div>
</template>

<script>
import TooltipButton from '@/components/TooltipButton'
import { mapGetters } from 'vuex'

const STORE = '_menuEdit'

export default {
  name: 'IsDefaultCategory',
  components: {
    TooltipButton,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(STORE, ['saving']),
  },
  methods: {
    onClick($event) {
      this.$emit('input', $event.target.checked)
      this.$emit('click')
    },
  },
}
</script>

<template>
  <b-modal
    id="cashCalculatorModal"
    ref="cashCalculatorModal"
    class="modal-bills"
    :title="$t('message.common.cashCalculator')"
    size="lg"
    centered
    @hidden="resetModal"
    @ok="handleOk"
  >
    <CashCalculator v-model="cash" :total-price="price" class="m-lg-4" />
    <template #modal-footer="{ ok, cancel }">
      <b-button size="lg" variant="outline-dark" class="mr-3" @click="cancel()">
        {{ $t('message.common.cancel') }}
      </b-button>
      <b-overlay :show="processing" rounded="sm" variant="success" spinner-variant="light" opacity="1">
        <b-button size="lg" variant="success" @click="ok()">
          <i class="fas fa-check mr-2" aria-hidden="true" />
          {{ $t('message.common.end') }}
        </b-button>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import CashCalculator from '@/components/CashCalculator'

const STORE = '_orderSplitBill'

export default {
  name: 'CashCalculatorModal',
  components: {
    CashCalculator,
  },
  data() {
    return {
      cash: '',
      processing: false,
    }
  },
  computed: {
    billId() {
      return parseInt(this.$route.params.billId)
    },
    bill() {
      return this.$store.getters[`${STORE}/bill`](this.billId) || null
    },
    price() {
      const price = this.bill?.total_price || 0
      return parseFloat(price).toFixed(2)
    },
    rest() {
      const cash = this.cash ? this.priceDecimal(this.cash) : 0
      return this.priceDecimal(cash - this.price)
    },
  },
  methods: {
    resetModal() {
      this.cash = ''
      this.$store.commit(`${STORE}/setSelectedBillPosition`, null)
    },
    async handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      await this.closeBill(this.billId)
      this.$nextTick(() => {
        this.$bvModal.hide('cashCalculatorModal')
      })
    },
    async closeBill(id) {
      this.processing = true
      try {
        await this.$store.dispatch(`${STORE}/closeBill`, id)
      } catch ({ errorStatus, errorValue }) {
        const msg = errorStatus !== 500 && errorValue ? errorValue : this.$t('message.splitBill.error.closeBill')
        this.$toasted.error(msg, {
          duration: 5000,
        })
      }
      this.processing = false
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/scss/components/split-bill';
</style>

<template>
  <b-row no-gutters>
    <b-col>
      <div>
        <b-form-input
          id="customer-address"
          v-validate="validateAddress"
          :value="address"
          type="text"
          name="address"
          class="required-input"
          :placeholder="$t('message.orderFormFull.address')"
          :disabled="disabled"
          @input="updateAddress"
        >
        </b-form-input>
      </div>
      <input-error-message :_errors="errors" field="address" :small="false" />
    </b-col>
    <b-col cols="4">
      <b-form-input
        id="customer-city"
        :value="city"
        type="text"
        class="mb-3"
        :placeholder="getLocalizationCity"
        :disabled="disabled"
        @input="updateCity"
      >
      </b-form-input>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import InputErrorMessage from '@/components/InputErrorMessage'
import { PICKUP_TYPE_DELIVERY } from '@/common/constants'

export default {
  name: 'OrderCustomerAddress',
  components: {
    InputErrorMessage,
  },
  inject: ['parentValidator'],
  props: {
    address: {
      type: String,
      default: '',
    },
    city: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pickupType: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters('auth', ['getLocalizationCity']),
    validateAddress() {
      return this.pickupType === PICKUP_TYPE_DELIVERY ? 'required' : ''
    },
  },
  created() {
    this.$validator = this.parentValidator
  },
  methods: {
    updateAddress(value) {
      this.$emit('setAddress', value)
    },
    updateCity(value) {
      this.$emit('setCity', value)
    },
  },
}
</script>

<style scoped>
.required-input {
  display: inline-flex;
  width: calc(100% - 15px);
}
</style>

<template>
  <!--  in case long loading @ children `OrderDetailsContent` component (and despite `no-close-on-esc` attribute),-->
  <!--    user might leave that modal with `ESC` in `OrderDetailsContent` (`ESC` triggers there method `acceptOrDecline`-->
  <!--    which emits `closeModal` in case `this.orderRequestStatus !== "loading"'; `closeModal` calls here `hide` method)-->
  <b-modal
    id="detailsModal"
    ref="modal"
    :visible="visible"
    size="xl"
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    modal-class="modal-fullscreen"
    body-class="p-0 m-0 h-100"
    @hidden="hide"
  >
    <OrderDetailsContent
      :id="id"
      :key="id"
      :from-modal="true"
      @editOrder="
        hide()
        $router.push({ name: 'edit-order', params: $event })
      "
      @closeModal="hide()"
      @goToDefaultOrdersList="hide()"
    />
  </b-modal>
</template>

<script>
import OrderDetailsContent from './OrderDetailsContent.vue'

function initData() {
  return {
    visible: false,
    id: null,
  }
}

export default {
  name: 'DetailsModal',
  components: {
    OrderDetailsContent,
  },
  data() {
    return initData()
  },
  watch: {
    $route(route) {
      if (route.name === 'new-order') {
        this.hide()
      }
    },
  },
  mounted() {
    this.$eventBus.$on('onShowDetailsModal', (payload = {}) => {
      this.id = payload.id
      this.show()
    })
  },
  destroyed() {
    this.id = null
    this.$eventBus.$off('onShowDetailsModal')
  },
  methods: {
    show() {
      this.visible = true
    },
    hide() {
      let initdata = initData()
      this.visible = initdata.visible
      this.$eventBus.$emit('onCloseDetailsModal', this.id)
    },
  },
}
</script>

<style lang="scss">
.modal-fullscreen .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  position: fixed;
  z-index: 100000;
}
.modal-fullscreen > .modal-dialog > .modal-content {
  border: none;
  border-radius: 0;
}
</style>

<template>
  <div id="app" class="app">
    <component :is="$route.meta.layout || 'DefaultLayout'" />
  </div>
</template>

<script>
import DefaultLayout from '@/layouts/Default'
import { mapGetters } from 'vuex'
import { ROLE } from '@/common/constants'

export default {
  name: 'App',
  components: {
    DefaultLayout,
  },
  computed: {
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
      getUserName: 'auth/getUserName',
    }),
    hasAccessToCurrentRoute() {
      return this.$hasModuleAccess(this.$route.name)
    },
  },
  watch: {
    $route: {
      handler() {
        this.redirectForbiddenAccess()
        if (window.Tawk_API === undefined) {
          this.initializeTawk()
        } else {
          window.Tawk_API.hideWidget()
        }
      },
    },
  },
  created() {
    this.addGoogleAnalitycs()
    window.addEventListener('resize', this.setWindowWidth)
  },
  mounted() {
    this.redirectForbiddenAccess()
    this.initializeTawk()
    setInterval(() => {
      if (window.Tawk_API && window.Tawk_API.onLoaded) {
        window.Tawk_API.hideWidget()
      }
    }, 5000)
    this.$store.commit('setWindowWidth', window.innerWidth)
  },
  destroyed() {
    window.removeEventListener('resize', this.setWindowWidth)
  },
  errorCaptured(err) {
    this.$rollbar.error(err)
  },
  methods: {
    initializeTawk() {
      if (this.hasUserRole([ROLE.OWNER, ROLE.MANAGER, ROLE.WAITER])) {
        window.Tawk_API = window.Tawk_API || {}
        window.Tawk_API.visitor = {
          name: this.getUserName,
          email: this.getUserName,
        }

        window.Tawk_API.onLoad = function () {
          window.Tawk_API.hideWidget()
        }

        window.Tawk_API.onChatEnded = function () {
          window.Tawk_API.hideWidget()
        }

        window.Tawk_API.onChatStarted = function () {
          window.Tawk_API.hideWidget()
        }
        ;(function () {
          // eslint-disable-next-line one-var
          let s1 = document.createElement('script'),
            s0 = document.getElementsByTagName('script')[0]
          s1.async = true
          s1.src = 'https://embed.tawk.to/60241ce59c4f165d47c201a4/1eu6h231i'
          s1.charset = 'UTF-8'
          s1.setAttribute('crossorigin', '*')
          s0.parentNode.insertBefore(s1, s0)
        })()
      }
    },
    setWindowWidth(e) {
      this.$store.commit('setWindowWidth', e?.target?.innerWidth)
    },
    redirectForbiddenAccess() {
      if (!this.hasAccessToCurrentRoute) {
        console.error('You have no access to this route')
        this.$router.replace('/orders')
      }
    },
    addGoogleAnalitycs() {
      if (!process.env.VUE_APP_GOOGLE_ANALITYCS_ID) return

      const gtagScript = document.createElement('script')
      gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.VUE_APP_GOOGLE_ANALITYCS_ID}`
      gtagScript.async = true
      document.head.appendChild(gtagScript)

      const script = document.createElement('script')
      script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${process.env.VUE_APP_GOOGLE_ANALITYCS_ID}');`
      document.head.appendChild(script)
    },
  },
}
</script>

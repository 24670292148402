<template>
  <div class="view-container">
    <b-container>
      <b-row align-v="center" align-h="between">
        <b-col>
          <b-breadcrumb class="pl-4 mb-1">
            <b-breadcrumb-item :to="{ name: 'settings' }">
              {{ $t('message.settings.settings') }}
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'workstations' }" active>
              KDS - {{ $t('message.workstations.title') }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
      </b-row>
      <section class="widget">
        <div class="widget-body-wrapper p-4">
          <router-link :to="{ name: 'workstation-edit' }" tag="button" class="btn btn-primary mb-3">
            <i class="fas fa-plus-square fa-fw" aria-hidden="true" />
            <span>{{ $t('message.workstations.addNewWorkstation') }}</span>
          </router-link>
          <div class="row">
            <div class="col-12">
              <WorkstationsTable />
            </div>
          </div>
        </div>
      </section>
    </b-container>
  </div>
</template>

<script>
import WorkstationsTable from './_components/WorkstationsTable'
import store from '@/views/workstations/_store'
import { mapActions } from 'vuex'

export default {
  name: 'WorkstationsList',
  components: { WorkstationsTable },
  data() {
    return {}
  },
  beforeMount() {
    if (!this.$store.state[store.name]) this.$store.registerModule(store.name, store)
  },
  destroyed() {
    //this.$store.unregisterModule(store.name)
  },
  methods: {
    ...mapActions('_workstations', ['getMealCategories']),
  },
}
</script>
<style lang="scss">

</style>

<template>
  <div class="widget">
    <div class="widget-body-wrapper pl-0">
      <b-row>
        <b-col>
          <b-breadcrumb class="bg-white">
            <b-breadcrumb-item :to="{ name: 'settings' }">
              {{ $t('message.settings.settings') }}
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'receipt-printers-list' }">
              {{ $t('message.receiptPrintersSettings.name') }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active :to="{ name: 'discount' }">
              {{ $t('message.receiptPrintersSettings.singlePrinterView') }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
      </b-row>
      <b-container>
        <b-row class="justify-content-center">
          <b-col cols="12" lg="8" xl="6">
            <div>
              <b-button v-b-toggle.collapse-1 variant="outline-primary">{{
                $t('message.receiptPrintersSettings.howItWorksCollapsible')
              }}</b-button>
              <b-collapse id="collapse-1" class="mt-2">
                <b-card>
                  <p class="card-text">{{ $t('message.receiptPrintersSettings.howItWorksCollapsibleDescription') }}</p>
                  <b-button
                    size="sm"
                    variant="outline-primary"
                    @click="
                      updateInstructionInnerCollapsible(
                        $t('message.receiptPrintersSettings.exampleCollapsibleDescription')
                      )
                    "
                  >
                    {{ $t('message.receiptPrintersSettings.exampleCollapsible') }}
                  </b-button>
                  <b-button
                    size="sm"
                    variant="outline-primary"
                    @click="
                      updateInstructionInnerCollapsible(
                        $t('message.receiptPrintersSettings.allMealsRemainingMealsCollapsibleDescription')
                      )
                    "
                  >
                    {{ $t('message.receiptPrintersSettings.allMealsRemainingMealsCollapsible') }}
                  </b-button>
                  <b-collapse id="collapse-1-inner" v-model="instruction.showInnerCollapsible" class="mt-2">
                    <b-card
                      ><p>{{ instruction.innerCollapsibleText }}</p></b-card
                    >
                  </b-collapse>
                </b-card>
              </b-collapse>
            </div>
            <div class="mt-3">
              {{ $t('message.receiptPrintersSettings.printerName') }}<span class="text-danger mx-1">*</span>:
              <b-input-group>
                <b-form-input
                  id="receiptPrinterName"
                  v-model="printerForm.name"
                  v-validate="validateReceiptPrinterName"
                  name="receiptPrinterName"
                >
                </b-form-input>
                <template #append>
                  <b-button
                    :variant="printerName.changed ? 'success' : 'outline-success'"
                    :disabled="!printerName.changed"
                    class="saveButton savePrinterNameButton"
                    @click="saveReceiptPrinterName()"
                  >
                    {{ $t('message.receiptPrintersSettings.save') }}
                  </b-button>
                </template>
              </b-input-group>
            </div>
            <input-error-message :_errors="errors" field="receiptPrinterName" :small="false"></input-error-message>

            <div class="mt-3">
              {{ $t('message.receiptPrintersSettings.printerInternalId') }}<span class="text-danger mx-1">*</span>:
              <b-input-group>
                <b-form-input
                  id="receiptPrinterInternalId"
                  v-model="printerForm.internal_id"
                  v-validate="validateReceiptPrinterInternalId"
                  name="receiptPrinterInternalId"
                >
                </b-form-input>
                <template #append>
                  <b-button
                    :variant="printerInternalId.changed ? 'success' : 'outline-success'"
                    :disabled="!printerInternalId.changed"
                    class="saveButton savePrinterInternalIdButton"
                    @click="saveReceiptPrinterInternalId()"
                  >
                    {{ $t('message.receiptPrintersSettings.save') }}
                  </b-button>
                </template>
              </b-input-group>
            </div>
            <input-error-message
              :_errors="errors"
              field="receiptPrinterInternalId"
              :small="false"
            ></input-error-message>

            <div class="mt-3">
              <span>{{ $t('message.receiptPrintersSettings.assignedTemplates') }}:</span>
              <div>
                <b-button
                  v-for="receipt_template in printerForm.receipt_templates"
                  :key="receipt_template.id"
                  class="mr-2 mt-2"
                  variant="primary"
                  @click="showModal(receipt_template)"
                >
                  {{ receipt_template.name_display }}
                </b-button>
              </div>
            </div>
            <div class="mt-3">
              {{ $t('message.receiptPrintersSettings.freeTemplates') }}:
              <div>
                <b-button
                  v-for="receipt_template in printerForm.free_receipt_templates"
                  :key="receipt_template.id"
                  class="success mr-2 mt-2"
                  @click="showModal(receipt_template)"
                >
                  {{ receipt_template.name_display }}
                </b-button>
              </div>
            </div>
            <b-button variant="success" class="mt-3" @click="templateTypeModalShow = true"
              ><i class="fas fa-plus mr-2"></i>
              {{ $t('message.receiptPrintersSettings.newTemplate') }}
            </b-button>

            <b-modal
              v-model="templateTypeModalShow"
              hide-footer
              centered
              :title="$t('message.receiptPrintersSettings.addNewTemplateTemplateType')"
            >
              <b-button
                class="mt-3"
                variant="warning"
                @click="hideTypeModalShowReceiptTemplateFormModal(RECEIPT_TEMPLATE_CHOICES.WHITELISTED_MEALS)"
              >
                {{ $t('message.receiptPrintersSettings.whitelistTemplateType') }}
              </b-button>
              <b-button
                class="mt-3"
                variant="warning"
                @click="hideTypeModalShowReceiptTemplateFormModal(RECEIPT_TEMPLATE_CHOICES.ALL_MEALS)"
              >
                {{ $t('message.receiptPrintersSettings.allMealsTemplateType') }}
              </b-button>
              <b-button
                class="mt-3"
                variant="warning"
                @click="hideTypeModalShowReceiptTemplateFormModal(RECEIPT_TEMPLATE_CHOICES.REMAINING_MEALS)"
              >
                {{ $t('message.receiptPrintersSettings.remainingMealsTemplateType') }}
              </b-button>
            </b-modal>

            <b-modal
              v-model="modal.show"
              :title="`${$t('message.receiptPrintersSettings.template')} ${modal.receipt_template.name_display}`"
              hide-footer
            >
              <b-form-group>
                <template #label
                  >{{ $t('message.receiptPrintersSettings.no_of_copies') }}
                  <span class="text-danger mx-1">*</span>
                </template>
                <b-form-input
                  v-if="modal.receipt_template.type === RECEIPT_TEMPLATE_CHOICES.WHITELISTED_MEALS"
                  id="receiptTemplateName"
                  v-model="modal.receipt_template.name"
                  v-validate="validateReceiptTemplateName"
                  name="receiptTemplateName"
                  :disabled="!modal.isEdit"
                >
                </b-form-input>
                <b-form-input
                  v-else
                  id="receiptTemplateName"
                  name="receiptTemplateName"
                  :value="modal.receipt_template.name_display"
                  disabled
                >
                </b-form-input>
              </b-form-group>
              <input-error-message :_errors="errors" field="receiptTemplateName" :small="false"></input-error-message>
              <b-form-group>
                <template #label
                  >{{ $t('message.receiptPrintersSettings.no_of_copies') }}
                  <span class="text-danger mx-1">*</span>
                </template>
                <b-form-input
                  id="receiptTemplateNoOfCopies"
                  v-model="modal.receipt_template.no_of_copies"
                  v-validate="validateReceiptTemplateNumberOfCopies"
                  name="receiptTemplateNoOfCopies"
                  :disabled="!modal.isEdit"
                  :formatter="quantityInputFormatter"
                >
                </b-form-input>
              </b-form-group>
              <input-error-message
                :_errors="errors"
                field="receiptTemplateNoOfCopies"
                :small="false"
              ></input-error-message>
              <b-form-group
                v-if="modal.receipt_template.type === RECEIPT_TEMPLATE_CHOICES.WHITELISTED_MEALS"
                :label="$t('message.receiptPrintersSettings.whitelist')"
              >
                <b-form-textarea
                  v-model="modal.receipt_template.whitelist"
                  :disabled="!modal.isEdit"
                  size="lg"
                  rows="7"
                  no-auto-shrink
                >
                </b-form-textarea>
              </b-form-group>
              <b-form-group :label="$t('message.receiptPrintersSettings.receiptDesign')">
                <b-button class="mb-2" @click="modal.show_source_code_options = !modal.show_source_code_options">
                  {{
                    modal.show_source_code_options
                      ? $t('message.receiptPrintersSettings.shrink')
                      : $t('message.receiptPrintersSettings.expand')
                  }}
                </b-button>
                <b-collapse v-model="modal.show_source_code_options">
                  <b-card v-for="source_code_option in modal.source_code_options" :key="source_code_option.value">
                    <b-form-radio
                      v-model="modal.receipt_template.chosen_template_design"
                      :value="source_code_option.value"
                      :disabled="!modal.isEdit"
                    >
                      <img :src="source_code_option.source" />
                    </b-form-radio>
                  </b-card>
                  <b-card>
                    <b-form-radio
                      v-model="modal.receipt_template.chosen_template_design"
                      :value="1"
                      :disabled="!modal.isEdit"
                    >
                      {{ $t('message.receiptPrintersSettings.ownReceiptDesign') }}
                    </b-form-radio>
                  </b-card>
                </b-collapse>
              </b-form-group>

              <div class="mt-3">
                <!--                <b-button v-if="!modal.isEdit" @click="modal.show = false" class="mr-5">-->
                <!--                  OK-->
                <!--                </b-button>-->
                <b-button
                  v-if="!modal.isEdit"
                  variant="primary"
                  @click="modal.receipt_template.printer ? unassignReceiptTemplate() : assignReceiptTemplate()"
                >
                  {{
                    modal.receipt_template.printer
                      ? $t('message.receiptPrintersSettings.free')
                      : $t('message.receiptPrintersSettings.assign')
                  }}
                </b-button>
                <b-button v-if="!modal.isEdit" variant="warning" @click="editReceiptTemplate()">
                  {{ $t('message.receiptPrintersSettings.edit') }}
                </b-button>
                <b-button
                  v-if="isDeletingTemplatePossible"
                  variant="danger"
                  @click="modal.deleteTemplateModal.show = true"
                >
                  {{ $t('message.receiptPrintersSettings.delete') }}
                </b-button>
                <b-button v-if="modal.isEdit" variant="success" @click="createOrSaveReceiptTemplate()">
                  {{
                    modal.isNewTemplate
                      ? $t('message.receiptPrintersSettings.create')
                      : $t('message.receiptPrintersSettings.save')
                  }}
                </b-button>
                <b-modal
                  v-model="modal.deleteTemplateModal.show"
                  hide-footer
                  centered
                  :title="$t('message.receiptPrintersSettings.deleteReceiptTemplate')"
                >
                  <b-button class="mt-3" variant="danger" @click="deleteReceiptTemplate()">{{
                    $t('message.receiptPrintersSettings.delete')
                  }}</b-button>
                  <b-button class="mt-3" @click="modal.deleteTemplateModal.show = false">{{
                    $t('message.receiptPrintersSettings.cancel')
                  }}</b-button>
                </b-modal>
              </div>
            </b-modal>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { API } from '@/services/api/api'
import { RECEIPT_TEMPLATE_CHOICES, DEFAULT_RECEIPT_TEMPLATE_DESIGN } from '@/common/constants'
import { responseErrorsHandler } from '@/mixins'
import InputErrorMessage from '@/components/InputErrorMessage'
import receipt_2_amigos from '../../../../public/static/images/receipt_templates/receipt_2_amigos.jpg'
import receipt_3_kuchnia_italia from '../../../../public/static/images/receipt_templates/receipt_3_kuchnia_italia.jpg'
import receipt_4_vietlong from '../../../../public/static/images/receipt_templates/receipt_4_vietlong.jpg'
import receipt_5_daves from '../../../../public/static/images/receipt_templates/receipt_5_daves.jpg'

export default {
  name: 'ReceiptPrinterEdit',
  components: { InputErrorMessage },
  mixins: [responseErrorsHandler],
  props: ['printer'],
  data() {
    return {
      RECEIPT_TEMPLATE_CHOICES: RECEIPT_TEMPLATE_CHOICES,
      DEFAULT_RECEIPT_TEMPLATE_DESIGN: DEFAULT_RECEIPT_TEMPLATE_DESIGN,
      instruction: {
        showInnerCollapsible: false,
        innerCollapsibleText: '',
      },
      printerName: {
        changed: false,
        startingValue: '',
      },
      printerInternalId: {
        changed: false,
        startingValue: '',
      },
      printerForm: {
        id: null,
        name: '',
        internal_id: '',
        receipt_templates: [],
        free_receipt_templates: [],
      },
      templateTypeModalShow: false,
      modal: {
        show: false, // is template modal visible
        isEdit: false, // is user able to edit form in modal
        isNewTemplate: false, // is user creating a new template (different buttons for saving and creating)
        deleteTemplateModal: {
          show: false,
        },
        receipt_template: {
          printer: null,
          name: '',
          name_display: '',
          no_of_copies: null,
          id: null,
          type: null,
          whitelist: '',
          chosen_template_design: DEFAULT_RECEIPT_TEMPLATE_DESIGN,
        },
        show_source_code_options: false,
        source_code_options: [
          { value: 2, source: receipt_2_amigos },
          { value: 3, source: receipt_3_kuchnia_italia },
          { value: 4, source: receipt_4_vietlong },
          { value: 5, source: receipt_5_daves },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      getCompany: 'auth/getCompany',
    }),
    validateReceiptTemplateName() {
      return this.modal.receipt_template.name ? '' : 'required'
    },
    validateReceiptTemplateNumberOfCopies() {
      return this.modal.receipt_template.no_of_copies ? '' : 'required'
    },
    validateReceiptPrinterName() {
      return this.printerForm.name ? '' : 'required'
    },
    validateReceiptPrinterInternalId() {
      return this.printerForm.internal_id ? '' : 'required'
    },
    computedPrinterName() {
      // you can't watch nested data, but you can watch computed properties
      return this.printerForm.name
    },
    computedPrinterInternalId() {
      return this.printerForm.internal_id
    },
    computedWhitelist() {
      // you can't watch nested data, but you can watch computed properties
      return this.modal.receipt_template.whitelist
    },
    isDeletingTemplatePossible() {
      if (this.modal.isEdit) {
        // you can't delete any template if you're in edit mode
        return false
      } else {
        if (this.modal.receipt_template.type === RECEIPT_TEMPLATE_CHOICES.WHITELISTED_MEALS) {
          // you can delete every WHITELISTED_MEALS template there is as long as you're not in edit mode
          return true
        } else {
          // You can delete ALL MEALS/REMAINING_MEALS template if  there's more than one
          // ALL MEALS/REMAINING_MEALS template available for you.
          // To check you need to look for ALL MEALS/REMAINING_MEALS template
          // in printerForm.receipt_templates and printerForm.free_receipt_templates.
          let allTemplates = this.printerForm.receipt_templates.concat(this.printerForm.free_receipt_templates)
          if (this.modal.receipt_template.type === RECEIPT_TEMPLATE_CHOICES.ALL_MEALS) {
            return allTemplates.filter((rt) => rt.type === RECEIPT_TEMPLATE_CHOICES.ALL_MEALS).length > 1
          } else {
            return allTemplates.filter((rt) => rt.type === RECEIPT_TEMPLATE_CHOICES.REMAINING_MEALS).length > 1
          }
        }
      }
    },
  },
  watch: {
    computedPrinterName(newValue) {
      document.querySelector('.savePrinterNameButton').innerHTML = this.$t('message.receiptPrintersSettings.save')
      this.printerName.startingValue !== newValue
        ? (this.printerName.changed = true)
        : (this.printerName.changed = false)
    },
    computedPrinterInternalId(newValue) {
      document.querySelector('.savePrinterInternalIdButton').innerHTML = this.$t('message.receiptPrintersSettings.save')
      this.printerInternalId.startingValue !== newValue
        ? (this.printerInternalId.changed = true)
        : (this.printerInternalId.changed = false)
    },
    computedWhitelist() {
      if (this.modal.receipt_template.whitelist) {
        this.modal.receipt_template.whitelist = this.modal.receipt_template.whitelist.toLowerCase()
      }
    },
  },
  async mounted() {
    this.printerForm = { ...this.printerForm, ...this.printer }
    const { error, data } = await API.getReceiptTemplates()
    if (!error && data) {
      this.printerForm.free_receipt_templates = data
    }
    this.printerName.startingValue = this.printerForm.name
    this.printerInternalId.startingValue = this.printerForm.internal_id
  },
  methods: {
    quantityInputFormatter(value, event) {
      if (value == 0) return ''
      else if (value.length > 2) return value.substring(0, 2)
      else return value.replace(/[^\d]/g, '')
    },
    hideTypeModalShowReceiptTemplateFormModal(type) {
      this.templateTypeModalShow = false
      this.addNewTemplate(type)
    },
    updateInstructionInnerCollapsible(text) {
      if (!this.instruction.innerCollapsibleText) {
        this.instruction.innerCollapsibleText = text
        this.instruction.showInnerCollapsible = !this.instruction.showInnerCollapsible
      } else if (text === this.instruction.innerCollapsibleText) {
        this.instruction.showInnerCollapsible = !this.instruction.showInnerCollapsible
      } else if (text !== this.instruction.innerCollapsibleText) {
        this.instruction.showInnerCollapsible = true
        this.instruction.innerCollapsibleText = text
      }
    },
    resetModal() {
      let newReceiptTemplate = {
        printer: null,
        name: '',
        name_display: '',
        no_of_copies: 1,
        id: null,
        type: RECEIPT_TEMPLATE_CHOICES.WHITELISTED_MEALS,
        whitelist: '',
        chosen_template_design: DEFAULT_RECEIPT_TEMPLATE_DESIGN,
      }
      this.modal.receipt_template = { ...newReceiptTemplate }
    },
    removeTemplateFromList(id, list) {
      let removeIndex = list.map((item) => item.id).indexOf(id)
      list.splice(removeIndex, 1)
    },
    editReceiptTemplate() {
      this.modal.isEdit = !this.modal.isEdit
    },
    updateReceiptTemplate(newData) {
      let list = this.modal.receipt_template.printer
        ? this.printerForm.receipt_templates
        : this.printerForm.free_receipt_templates
      let index = list.map((item) => item.id).indexOf(newData.id)
      list.splice(index, 1, newData)
    },
    createOrSaveReceiptTemplate() {
      this.$validator.validate().then((result) => {
        if (result) {
          if (this.modal.isNewTemplate) {
            if (this.modal.receipt_template.type === this.RECEIPT_TEMPLATE_CHOICES.WHITELISTED_MEALS) {
              this.createReceiptTemplate()
            } else if (this.modal.receipt_template.type === this.RECEIPT_TEMPLATE_CHOICES.ALL_MEALS) {
              this.addNewBaseTemplate(this.RECEIPT_TEMPLATE_CHOICES.ALL_MEALS)
            } else if (this.modal.receipt_template.type === this.RECEIPT_TEMPLATE_CHOICES.REMAINING_MEALS) {
              this.addNewBaseTemplate(this.RECEIPT_TEMPLATE_CHOICES.REMAINING_MEALS)
            }
          } else {
            this.saveReceiptTemplate()
          }
        }
      })
    },
    async saveReceiptTemplate() {
      this.modal.isEdit = !this.modal.isEdit
      const { error, data } = await API.updateReceiptTemplate(this.modal.receipt_template.id, {
        ...this.modal.receipt_template,
      })
      if (!error && data) {
        this.updateReceiptTemplate(data)
      }
    },
    async createReceiptTemplate() {
      const { error, data } = await API.createReceiptTemplate(this.modal.receipt_template)
      if (!error && data) {
        this.printerForm.free_receipt_templates.push(data)
        this.modal.receipt_template = { ...data }
        this.modal.isNewTemplate = false
        this.modal.isEdit = false
      }
    },
    async deleteReceiptTemplate() {
      const { error } = await API.deleteReceiptTemplate(this.modal.receipt_template.id, {
        ...this.modal.receipt_template,
      })
      if (!error) {
        let id = this.modal.receipt_template.id
        if (this.modal.receipt_template.printer) {
          this.removeTemplateFromList(id, this.printerForm.receipt_templates)
        } else {
          this.removeTemplateFromList(id, this.printerForm.free_receipt_templates)
        }
        this.modal.show = false
        this.modal.deleteTemplateModal.show = false
      }
    },
    async saveReceiptPrinterName() {
      const { error } = await API.updateReceiptPrinterName(this.printer.id, { name: this.printerForm.name })
      if (!error) {
        this.printerName.changed = false
        this.printerName.startingValue = this.printerForm.name
        document.querySelector('.savePrinterNameButton').innerHTML = this.$t('message.receiptPrintersSettings.saved')
      }
    },
    async saveReceiptPrinterInternalId() {
      const { error } = await API.updateReceiptPrinterName(this.printer.id, {
        internal_id: this.printerForm.internal_id,
      })
      if (!error) {
        this.printerInternalId.changed = false
        this.printerInternalId.startingValue = this.printerForm.printerInternalId
        document.querySelector('.savePrinterInternalIdButton').innerHTML = this.$t(
          'message.receiptPrintersSettings.saved'
        )
      }
    },
    // #ref assignReceiptTemplate and unassignReceiptTemplate could be one method
    async assignReceiptTemplate() {
      const { error, data } = await API.assignReceiptTemplate(this.modal.receipt_template.id, {
        printer: this.printer.id,
      })
      if (!error && data) {
        this.modal.show = false
        this.modal.receipt_template = { ...data }
        this.printerForm.receipt_templates.push(this.modal.receipt_template)
        this.removeTemplateFromList(this.modal.receipt_template.id, this.printerForm.free_receipt_templates)
      }
    },
    async unassignReceiptTemplate() {
      const { error, data } = await API.assignReceiptTemplate(this.modal.receipt_template.id, {
        printer: null,
      })
      if (!error && data) {
        this.modal.show = false
        this.modal.receipt_template = { ...data }
        this.printerForm.free_receipt_templates.push(this.modal.receipt_template)
        this.removeTemplateFromList(this.modal.receipt_template.id, this.printerForm.receipt_templates)
      }
    },
    addNewTemplate(type) {
      this.resetModal()
      this.modal.receipt_template.type = type
      this.modal.show = true
      this.modal.isEdit = true
      this.modal.isNewTemplate = true
    },
    async addNewBaseTemplate(receiptTemplateChoice) {
      let noOfCopies = this.modal.receipt_template.no_of_copies
      let chosenTemplateDesign = this.modal.receipt_template.chosen_template_design
      const { error, data } = await API.createNewBaseTemplate({
        type: receiptTemplateChoice,
        no_of_copies: noOfCopies,
        chosen_template_design: chosenTemplateDesign,
      })
      if (!error && data) {
        this.printerForm.free_receipt_templates.push(data)
        this.modal.show = false
        this.resetModal()
      }
    },
    showModal(receiptTemplate) {
      this.modal.show = !this.modal.show
      this.modal.isEdit = false
      this.modal.isNewTemplate = false
      this.modal.receipt_template = { ...receiptTemplate }
    },
  },
}
</script>

<style scoped>
img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}
p {
  white-space: pre-wrap;
}
.saveButton {
  padding: 0 10% 0 10%;
  transition: background-color 0.6s;
}
</style>

import { ShiftsCurrentState } from './index'
import { Shift } from '@/types/interface'

const setCurrentShifts = (state: ShiftsCurrentState, currentShifts: Array<Shift>) => {
  state.currentShifts = currentShifts
}
const setCurrentShiftsRequest = (state: ShiftsCurrentState, status: string) => {
  state.currentShiftsRequest = status
}

export default {
  setCurrentShifts,
  setCurrentShiftsRequest,
}

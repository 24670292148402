<template>
  <div class="h-100">
    <portal to="nav-closable-left-side">
      <li class="d-flex align-items-center">
        <span v-if="isMappingModeStatic" class="mr-3">{{ $t('message.mapping.mappingMenu') }}</span>
        <span v-else
          >{{ $t('message.mapping.dynamicModeTitle') }} <span class="font-weight-bold">{{ order.number }}</span>
        </span>
        <BrandsDropdown
          v-if="isMappingModeStatic"
          :value="selectedBrandName"
          :size="isMobile ? 'sm' : 'lg'"
          @choose-brand="({ brandId }) => onChooseBrand({ brandId })"
        />
      </li>
    </portal>
    <portal to="nav-closable-right-side">
      <b-button
        v-if="isMappingModeStatic"
        id="showIgnoredElements"
        class="mr-2 px-3"
        :variant="showIgnoredOnly ? 'primary' : 'secondary'"
        :disabled="showDefaultMenuPositions"
        @click="toggleShowIgnoredOnly()"
      >
        <template v-if="showIgnoredOnly">
          <i class="fas fa-eye" aria-hidden="true" />
          <span class="d-none d-md-inline-block">{{ $t('message.mapping.showUnmaped') }}</span>
        </template>
        <template v-else>
          <i class="fas fa-eye-slash" aria-hidden="true" />
          <span class="d-none d-md-inline-block">{{ $t('message.mapping.showIgnored') }}</span>
        </template>
      </b-button>
      <b-button
        v-if="isMappingModeStatic"
        id="addNewMealToOrder"
        class="mr-2 px-3"
        :variant="showMappedOnly ? 'primary' : 'success'"
        :disabled="showIgnoredOnly"
        @click="toggleShowMappedOnly()"
      >
        <template v-if="showMappedOnly">
          <i class="fas fa-unlink" aria-hidden="true" />
          <span class="d-none d-md-inline-block">{{ $t('message.mapping.showUnmaped') }}</span>
        </template>
        <template v-else>
          <i class="fas fa-link" aria-hidden="true" />
          <span class="d-none d-md-inline-block">{{ $t('message.mapping.showMapped') }}</span>
        </template>
      </b-button>
    </portal>

    <div id="mapping-view" class="view-container">
      <b-row v-if="isMappingModeStatic">
        <b-col class="px-0">
          <b-breadcrumb class="pl-4 mb-0">
            <b-breadcrumb-item :to="{ name: 'settings' }">
              {{ $t('message.settings.settings') }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              {{ $t('message.mapping.mappingMenu') }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
      </b-row>
      <div class="card px-3 overflow-y-auto h-100">
        <b-row v-if="isMappingModeStatic" class="mt-3 mb-2">
          <b-col cols="12" sm="8" md="7" lg="5" xl="4" class="mapped-table-filters">
            <source-change
              v-model="selectedSourceId"
              :change-menu-source="onSelectSource"
              :response-errors="{}"
              :label="$t('message.common.sourceMenu')"
              :no-choosen-label="$t('message.mapping.sourceButtonAllLabel')"
              :show-only-integrations="true"
              :brand-id="selectedBrandId"
              :show-all-btn="true"
              class="d-inline-flex text-nowrap"
            />
            <b-button
              variant="light"
              class="ml-2 mr-2 default-menu-positions-btn"
              :size="isMobile ? 'sm' : 'lg'"
              :disabled="showIgnoredOnly"
              @click="toggleShowDefaultMenuPositions()"
            >
              <div>
                <div class="pl-2 mb-n2">
                  <i
                    class="fa fa-arrow-right"
                    :class="{
                      'default-menu-positions-arrow-correct': !showDefaultMenuPositions,
                      'default-menu-positions-arrow-revert': showDefaultMenuPositions,
                    }"
                  ></i>
                </div>
                <div class="pr-3">
                  <i
                    class="fa fa-arrow-left"
                    :class="{
                      'default-menu-positions-arrow-correct': showDefaultMenuPositions,
                      'default-menu-positions-arrow-revert': !showDefaultMenuPositions,
                    }"
                  ></i>
                </div>
              </div>
              <div class="ml-3">
                <span>{{ $t('message.common.menuDefault') }}</span>
              </div>
            </b-button>
          </b-col>
          <b-col>
            <b-dropdown
              :text="mappinTypeViewName"
              variant="outline-primary"
              :size="isMobile ? 'sm' : 'lg'"
              class="ml-3"
            >
              <b-dropdown-item-button
                v-for="(value, idx) in ['meal', 'single_choice_entry', 'multi_choice_entry']"
                :key="value"
                :active="value === selectedMappedTypeList"
                @click="onSelectMappingTypeView(value)"
              >
                {{ getMappinTypeViewName(value) }}
              </b-dropdown-item-button>
            </b-dropdown>
          </b-col>
        </b-row>
        <MappedTable />
      </div>
      <MappingSelectMealModal />
    </div>
    <BrandsModal @onChoose="(brandId) => onChooseBrand({ brandId })" />
  </div>
</template>

<script>
import store from './_store'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import BrandsDropdown from '@/components/BrandsDropdown'
import MappedTable from '@/components/mapped-table/MappedTable'
import MappingSelectMealModal from '@/components/mapping-select-meal-modal/MappingSelectMealModal'
import SourceChange from '@/components/source-change/SourceChange'
import BrandsModal from '@/components/BrandsModal'

const STORE = '_mapping'

export default {
  name: 'Mapping',
  components: { BrandsDropdown, MappedTable, MappingSelectMealModal, SourceChange, BrandsModal },
  data() {
    return {
      loading: false,
      dynamicMapping: false,
    }
  },
  computed: {
    ...mapFields(STORE, ['selectedSourceId', 'showMappedOnly', 'showIgnoredOnly', 'showDefaultMenuPositions', 'order']),
    ...mapGetters(['isMobile']),
    ...mapGetters(STORE, [
      'mappedItem',
      'mappingMode',
      'integrations',
      'selectedBrandId',
      'selectedBrandName',
      'selectedMappedTypeList',
    ]),
    mappinTypeViewName() {
      return this.getMappinTypeViewName(this.selectedMappedTypeList)
    },
    isMappingModeStatic() {
      return this.mappingMode === 'static'
    },
  },
  beforeMount() {
    if (!this.$store.state[STORE]) this.$store.registerModule(STORE, store)
    if (this.$route.params?.order) {
      this.initDynamicMode(this.$route.params?.order)
    } else {
      this.setMappingMode('static')
    }
  },
  mounted() {
    if (this.isMappingModeStatic) {
      this.$eventBus.$emit('onShowBrandsModal', { setBrand: true })
    } else {
      this.$eventBus.$emit('refreshMappedList')
    }
  },
  destroyed() {
    this.resetState()
    this.$store.unregisterModule(STORE)
  },
  methods: {
    ...mapMutations(STORE, [
      'setMappingMode',
      'setSelectedMappingTypeView',
      'setSelectedSourceId',
      'setSelectedBrandId',
      'resetState',
    ]),
    initDynamicMode(order) {
      this.order = order
      this.setMappingMode('dynamic')
      this.setSelectedBrandId(this.order.brand_id)
      this.setSelectedSourceId(this.order.menu_source)
    },
    onChooseBrand({ brandId }) {
      this.setSelectedBrandId(brandId)
      this.setSelectedSourceId(null)
      this.$eventBus.$emit('refreshMappedList')
    },
    onSelectSource(source) {
      this.setSelectedSourceId(source)
      this.$eventBus.$emit('refreshMappedList')
    },
    onSelectMappingTypeView(view) {
      this.setSelectedMappingTypeView(view)
      this.$eventBus.$emit('refreshMappedList')
    },
    toggleShowDefaultMenuPositions() {
      this.showDefaultMenuPositions = !this.showDefaultMenuPositions
      this.$eventBus.$emit('refreshMappedList')
    },
    getMappinTypeViewName(value) {
      switch (value) {
        case 'meal':
          return this.$t('message.mapping.meals')
        case 'single_choice_entry':
          return this.$t('message.mapping.singleChoice')
        case 'multi_choice_entry':
          return this.$t('message.mapping.multiChoice')
      }
    },
    toggleShowMappedOnly() {
      this.showMappedOnly = !this.showMappedOnly
      this.$eventBus.$emit('refreshMappedList')
    },
    toggleShowIgnoredOnly() {
      this.showIgnoredOnly = !this.showIgnoredOnly
      this.$eventBus.$emit('refreshMappedList')
    },
  },
}
</script>

<style scoped lang="scss">
.mapped-table-wrapper {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.mapped-table-filters {
  display: flex;
  height: 45px;
}

.default-menu-positions {
  &-btn {
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    display: inline-flex;
    align-items: center;
    min-width: 200px;
  }
  &-arrow-correct {
    color: $blue;
  }
  &-arrow-revert {
    opacity: 0.3;
  }
}
</style>

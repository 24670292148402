<template>
  <div>
    <b-form-input
      id="customer-phone-number"
      v-validate="validatePhone"
      name="phone"
      :value="value"
      :placeholder="$t('message.orderFormFull.phoneNumber')"
      autocomplete="off"
      :disabled="disabled"
      @input="customerPhoneInput"
      @keyup.prevent.stop.arrow-keys="selectFoundCustomer"
    />
    <input-error-message :_errors="errors" field="phone" :small="false" />
    <div class="found-customers-list">
      <b-list-group v-if="foundCustomers.length > 0" v-click-outside="closeCustomersList">
        <b-list-group-item
          v-for="(customer, index) in getCustomersAddresses()"
          :key="'customer_' + index"
          ref="foundCustomers"
          v-shortkey="focusedFoundCustomer === index ? ['enter'] : 'null'"
          class="customer-address"
          :active="focusedFoundCustomer === index"
          @mouseenter="focusedFoundCustomer = index"
          @keyup.prevent.stop.arrow-keys="selectFoundCustomer"
          @shortkey="setCustomerData(customer)"
          @click="setCustomerData(customer)"
        >
          <b-button
            id="customer-address-remove-btn"
            variant="danger"
            class="customer-address-remove-button float-right"
            @click.stop="removeAddressModal(customer)"
          >
            <i class="fas fa-times"></i>
          </b-button>
          <span>
            <strong>{{ customer.phone }}</strong> {{ customer.address || customer.full_address }}
            <b-badge v-if="customer.address && customer.city" class="ml-1 float-right customer-address-city">
              {{ customer.city }}
            </b-badge>
          </span>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import InputErrorMessage from '@/components/InputErrorMessage'

const ORDER_STORE = 'order'

export default {
  name: 'OrderCustomerPhone',
  components: {
    InputErrorMessage,
  },
  inject: ['parentValidator'],
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focusedFoundCustomer: -1,
      backFromRemoveAddressModal: false,
    }
  },
  computed: {
    ...mapGetters(ORDER_STORE, ['foundCustomers']),
    validatePhone() {
      return {
        required: true,
        regex: /^([+0-9]+)$/,
        // minimum length validation for delivery or phone which doesn't contain only prefix
        min: this.value && !this.value.match(/^\+\d\d$/) ? (this.value[0] === '+' ? 12 : 9) : '',
      }
    },
  },
  created() {
    this.$validator = this.parentValidator
  },
  methods: {
    ...mapActions(ORDER_STORE, ['findCustomers']),
    ...mapMutations(ORDER_STORE, ['setFoundCustomers']),
    selectFoundCustomer(event) {
      const { keyCode } = event
      const length = this.foundCustomers.length
      if (length <= 0) return false
      if (keyCode === 40 && this.focusedFoundCustomer < length - 1) {
        this.focusedFoundCustomer = this.focusedFoundCustomer + 1
      } else if (keyCode === 38 && this.focusedFoundCustomer > 0) {
        this.focusedFoundCustomer = this.focusedFoundCustomer - 1
      }
      // if focusedFoundCustomer is gte 0 and it's component can be found - focus on it
      if (this.focusedFoundCustomer >= 0 && this.$refs.foundCustomers[this.focusedFoundCustomer]) {
        this.$refs.foundCustomers[this.focusedFoundCustomer].focus()
      }
    },
    closeCustomersList() {
      if (this.backFromRemoveAddressModal) {
        this.backFromRemoveAddressModal = false
        return
      }
      this.setFoundCustomers([])
    },
    customerPhoneInput(value) {
      this.setPhone(value)
      this.getCustomersData(value)
    },
    getCustomersData(value) {
      // gets customers data if phone's length is 4 (not counting prefix) after seconds
      const customerPhone = value.trim()
      if (
        (customerPhone[0] === '+' && customerPhone.length >= 7) ||
        (customerPhone[0] !== '+' && customerPhone.length >= 4)
      ) {
        if (this.customerDataTimer) {
          clearTimeout(this.customerDataTimer)
        }
        this.customerDataTimer = setTimeout(() => {
          if (customerPhone) this.findCustomers(customerPhone)
        }, 1000)
      }
    },
    setCustomerData(customer) {
      // let foundCustomer = this.foundCustomers.find((foundCustomer) => {
      //   return foundCustomer.phone === customer.phone
      // })
      const { phone, address, city, full_address } = customer
      this.setPhone(phone)
      // this.customerName = foundCustomer.name
      const customerAddress = {
        address: customer.address || customer.full_address,
        city: customer.city && customer.city !== this.getLocalizationCity ? customer.city : this.getLocalizationCity,
        comment: customer.comment || '',
      }
      this.setCustomerAddress(customerAddress)
      this.setFoundCustomers([])
      // this.$refs.customerNameInput.focus()
    },
    setPhone(value) {
      this.$emit('input', value)
    },
    setCustomerAddress(value) {
      this.$emit('setCustomerAddress', value)
    },
    removeAddressModal(customer) {
      this.$bvModal
        .msgBoxConfirm(this.$t('message.orderFormFull.removeAddressConfirm'), {
          title: `${customer.name} (${customer.phone}) - ${customer.full_address}`,
          headerClass: 'w-100',
          centered: true,
          okTitle: this.$t('message.orderFormFull.yes'),
          cancelTitle: this.$t('message.orderFormFull.no'),
        })
        .then((result) => {
          if (result) {
            customer = {
              addressId: customer.address_id,
              customerPhoneNumber: this.customerPhone,
            }
            this.removeCustomerAddress(customer)
          }
          this.backFromRemoveAddressModal = true
        })
    },
    getCustomersAddresses() {
      // gets all addresses for all found customers
      let customers = this.foundCustomers
      let allAddresses = []
      customers.forEach((customer) => {
        customer.addresses.forEach((address) => {
          allAddresses.push({
            customer_id: customer.id,
            name: customer.name,
            phone: customer.phone,
            full_address: address.full_address,
            address: address.address,
            city: address.city,
            comment: address.comment,
            address_id: address.id,
          })
        })
      })
      return allAddresses
    },
  },
}
</script>

<style scoped>
.found-customers-list {
  position: absolute;
  z-index: 100;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.12);
}
.customer-address {
  padding-right: 10px;
}
.customer-address-city {
  font-size: 13px;
  line-height: normal;
}
.customer-address-remove-button {
  margin-left: 10px;
  padding: 0 5px;
  height: 25px;
  width: 25px;
}
</style>

<template>
  <b-modal
    id="mealsMenuModal"
    ref="mealsMenuModal"
    :title="$t('message.mealDetails.chooseMeal')"
    no-fade
    hide-footer
    hide-backdrop
    dialog-class="modal-fullscreen-dialog"
    content-class="modal-fullscreen-content"
    scrollable
    modal-class="mealsMenuModal"
  >
    <div>
      <MealsMenu :menu-json="getMealSetItemMenuJson" :meal-set-item="mealSetItem"></MealsMenu>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { mealSetsMixins } from '@/mixins/mealSetsMixins'
export default {
  name: 'MealsMenuModal',
  components: { MealsMenu: () => import('./MealsMenu.vue') },
  mixins: [mealSetsMixins],
  props: ['mealSetItem'],
  computed: {
    ...mapGetters(['menu']),
    getMealSetItemMenuJson() {
      // return menu json with categories-sizes-meals of item
      let menuJson = {
        mealSetItemId: this.mealSetItem.meal_set_item_id,
        categories: [],
      }
      if (!this.mealSetItem) return menuJson
      menuJson.categories = [this.generateMealSetItemMenuCategoryJson(this.mealSetItem)]
      return menuJson
    },
  },
}
</script>

<style scoped lang="scss">
.mealsMenuModal {
  z-index: 1;
}
</style>

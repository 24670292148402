import { API } from '@/services/api/api'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import { RegionsState } from './index'
import { Region } from '@/types/interface'

const getRegions = async ({ commit }: ActionContext<RegionsState, RootState>, { loading }: { loading?: boolean }) => {
  if (loading) commit('setRegionsRequest', 'loading')
  const { error, data } = await API.getDeliveryAreas()
  if (!error && data) {
    commit('setRegionsRequest', 'success')
    commit('setRegions', data)
  } else {
    commit('setRegionsRequest', 'error')
  }
}

const addRegion = async (
  { commit, dispatch, state }: ActionContext<RegionsState, RootState>,
  payload: { region: Partial<Region>; onSuccess: () => void }
) => {
  const { region, onSuccess } = payload
  commit('setRegionsRequest', 'loading')
  const { error } = await API.addDeliveryArea({
    ...region,
    points: state.editedRegionPoints,
    localization: JSON.parse(sessionStorage.getItem('localization') || '').id,
  })
  if (!error) {
    if (onSuccess) onSuccess()
    dispatch('getRegions', { loading: true })
  } else {
    // We need to make better errors for it
    commit('setRegionsRequest', 'error')
  }
}

const updateRegion = async (
  { commit, dispatch, state }: ActionContext<RegionsState, RootState>,
  { region, onSuccess }: { region: Region; onSuccess: () => void }
) => {
  commit('setRegionsRequest', 'loading')
  const { error } = await API.updateDeliveryArea(region.id, { ...region, points: state.editedRegionPoints })
  if (!error) {
    if (onSuccess) onSuccess()
    dispatch('getRegions', { loading: true })
  } else {
    // We need to make better errors for it
    commit('setRegionsRequest', 'error')
  }
}

const deleteRegion = async (
  { commit, dispatch }: ActionContext<RegionsState, RootState>,
  { regionId, onSuccess }: { regionId: number; onSuccess: () => void }
) => {
  commit('setRegionsRequest', 'loading')
  const { error } = await API.deleteDeliveryArea(regionId)
  if (!error) {
    dispatch('getRegions', { loading: true })
    if (onSuccess) onSuccess()
  } else {
    // We need to make better errors for it
    commit('setRegionsRequest', 'error')
  }
}

export default {
  getRegions,
  updateRegion,
  addRegion,
  deleteRegion,
}

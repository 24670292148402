import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { Shift } from '@/types/interface'

export interface ShiftsState {
  shifts: Array<Shift>
  shiftsRequest: string
}

const state: ShiftsState = {
  shifts: [],
  shiftsRequest: '',
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

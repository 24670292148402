<template>
  <div class="additional-fees-form">
    <b-row class="mt-4">
      <b-col cols="12" md="4">
        <DiscountsGroup
          v-model="cart.discountValue"
          :discount-type="cart.discountChoice"
          :disabled="disableDiscount"
          @discountTypeChange="cart.discountChoice = $event"
        />
      </b-col>
      <b-col cols="12" md="4">
        <b-row>
          <b-col cols="12">
            <div class="form-group">
              <label class="col-form-label pt-0" for="comment">{{ $t('message.cart.comment') }}</label>
              <textarea id="comment" v-model="cart.comment" class="form-control" rows="4" @keydown.enter.stop="" />
            </div>
          </b-col>
          <b-col cols="12">
            <DiscountCode />
          </b-col>
        </b-row>
      </b-col>
      <b-col v-if="!isOrderOnSite" cols="12" md="4">
        <price-input
          v-if="!isOrderOnSite"
          id="cartServiceChargePercent"
          v-model="cart.serviceChargePercent"
          class="mb-3"
          :label="$t('message.orderDetails.finishOrderChoices.serviceCharge')"
          append-text="%"
          @input="updateServiceChargeValue()"
        />
        <price-input
          v-if="!isOrderOnSite"
          id="cartPackagePrice"
          v-model="cart.packagePrice"
          class="mb-3"
          :label="$t('message.cart.packagePrice')"
          :append-text="currency"
        />
        <price-input
          id="cartTipInput"
          v-model="orderTip"
          :disabled="true"
          :label="$t('message.cart.tip')"
          :append-text="currency"
        >
        </price-input>
      </b-col>
    </b-row>
    <response-error-msg field="price" :response-errors="responseErrors"></response-error-msg>
  </div>
</template>

<script>
import PriceInput from '@/components/PriceInput'
import DiscountsGroup from '@/components/order-form/DiscountsGroup'
import DiscountCode from '@/components/order-form/DiscountCode'
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { responseErrorsHandler } from '@/mixins'
const STORE = '_orderEdit'

export default {
  name: 'AdditionalFeesForm',
  components: {
    PriceInput,
    DiscountsGroup,
    DiscountCode,
  },
  mixins: [responseErrorsHandler],
  props: {
    isOrderOnSite: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(['currency']),
    ...mapGetters(STORE, ['cart', 'isEdit']),
    ...mapFields(STORE, {
      orderTip: 'order.tip',
    }),
    disableDiscount() {
      return !!this.cart.discountCode
    },
    disableDiscountPercent() {
      return !!this.cart.promotionalPrice || !!this.cart.discountCode
    },
    disableDiscountValue() {
      return !!this.cart.promotionalPrice || !!this.cart.discountCode || !!this.cart.discountPercent
    },
  },
  methods: {
    ...mapActions(STORE, ['updateServiceChargeValue']),
  },
}
</script>

<style scoped lang="scss">

</style>

<template>
  <div class="view-container h-100">
    <OrderDetailsNavbar
      :order="order"
      :on-close="closeOrderDetails"
      :edit-button-active="editButtonActive"
      :edit-order="editOrder"
      :is-loading="loading"
    />
    <Loader v-if="loading" />
    <div class="order-details-container">
      <b-row>
        <b-col cols="6">
          <div class="widget">
            <div class="widget-body-wrapper">
              <order-form-papu-3 />

              <template v-if="order.status === ORDER_STATUSES.ORDER_STATUS_NEW">
                <div class="divider divider--dashed" />
                <div class="d-flex align-items-center mb-2">
                  <h4 class="d-inline mb-0">
                    {{
                      isPromisedTimePickTimeChangeAvailable
                        ? $t('message.orderDetails.changeOrAcceptPromisedTime')
                        : $t('message.orderDetails.selectPromisedTime')
                    }}
                  </h4>
                  <!--                  <span-->
                  <!--                    v-if="getPredictionsRequest && $hasModuleAccess('show-promised-time-prediction')"-->
                  <!--                    class="text-info ml-2"-->
                  <!--                  >-->
                  <!--                    <b-spinner small></b-spinner>-->
                  <!--                    {{ $t('message.promisedTime.predictingPromisedTime') }}-->
                  <!--                  </span>-->
                </div>
                <b-row class="mt-1">
                  <b-col
                    v-if="showPromisedTimeComponent"
                    cols="12"
                    :lg="isPromisedTimePickTimeChangeAvailable ? 12 : 10"
                    class="mb-3"
                  >
                    <PromisedTime
                      :order-details="true"
                      :custom-promised-time="order.promised_time"
                      :pick-time="isPromisedTimePickTimeChangeAvailable"
                      :estimated-delivery-time="estimatedDeliveryTime"
                      @input="changePromisedTime"
                    />
                  </b-col>
                  <b-col v-if="order.promised_time" cols="6" class="mb-3">
                    <b-button
                      block
                      size="lg"
                      :variant="accept ? 'secondary' : 'success'"
                      @click="
                        accept = !accept
                        decline = false
                      "
                      >
                      {{ $t('message.orderDetails.accept') }} </br> {{ order.promised_time }}
                    </b-button>
                  </b-col>
                  <b-col v-if="cancelOrderButtonVisible" cols="6" :offset="order.promised_time ? '' : 6" class="mb-3">
                    <b-button
                      block
                      size="lg"
                      class="h-100"
                      :variant="decline? 'secondary' : 'danger'"
                      @click="
                        decline = !decline
                        accept = false
                      "
                    >
                      {{ $t('message.orderDetails.decline') }}
                    </b-button>
                  </b-col>
                </b-row>
              </template>

              <b-button
                v-else-if="finishOrderButtonIsActive"
                id="show-finish-order-modal-btn"
                size="lg"
                block
                :variant="order.status !== 'finished' ? 'success' : 'primary'"
                @click="showFinishOrderModal"
              >
                <i class="fas fa-clipboard-check action__icon" />
                <span class="action__text">
                  {{
                    order.status !== 'finished'
                      ? $t('message.orderDetails.finishOrderButton')
                      : $t('message.orderDetails.changeFinishOrderButton')
                  }}
                </span>
              </b-button>

              <b-button v-else-if="editMode !== 'edit'" block size="lg" variant="secondary" class="h-100 action" disabled>
                <i class="fas fa-clipboard-check action__icon" />
                <span class="action__text">{{ $t('message.orderDetails.finishOrderButton') }}</span>
              </b-button>

              <b-row>
                <b-col cols="12">
                  <b-alert :show="responseErrors.hasOwnProperty('promised_time')" variant="danger">
                    <response-error-msg field="promised_time" :response-errors="responseErrors" />
                  </b-alert>
                  <b-alert :show="responseErrors.hasOwnProperty('non_field_errors')" variant="danger">
                    <response-error-msg field="non_field_errors" :response-errors="responseErrors" />
                  </b-alert>
                </b-col>
                <b-col cols="12">
                  <b-button
                    v-if="editMode === 'preview'"
                    id="okButton"
                    ref="okButton"
                    v-shortkey="['esc']"
                    class="action action--v-center action--ok mt-4"
                    block
                    size="lg"
                    variant="secondary"
                    :disabled="acceptOrDeclineDisabled"
                    @shortkey="onOkClick()"
                    @click.prevent="onOkClick()"
                  >
                    <kbd class="pb-0 mr-2 bg-white text-dark action__shortcut">ESC</kbd>
                    <span class="action__text action__text--lg action__text--inline">Ok</span>
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>

        <b-col cols="6">
          <div style="height: 100%; display: flex; flex-direction: column">
            <Customer :order="order" />

            <div v-if="order.meals && order.meals.length > 0" class="widget" style="margin-top: 20px">
              <div class="widget-header">
                <span class="widget-header__title">Zamówienie</span>
              </div>

              <div class="widget-body-wrapper p-0">
                <MealsList :meals="order.meals"/>
              </div>
            </div>

            <div class="widget" style="margin-top: 20px">
              <div class="widget-header">
                <span class="widget-header__title">{{ $t('message.common.courier') }}</span>
              </div>

              <div class="widget-body-wrapper">
                <OrderDetailsMap />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <finish-order-modal
      v-if="orderData"
      :payment-methods="paymentMethods"
      @close="closeFinishOrderModal"
      @changeStatus="closeOrderDetails"
    />
    <change-order-localization-modal :order="order" @transferred="$emit('closeModal')" />
  </div>
</template>

<script>
/* global $ */
import { mapGetters, mapActions, mapMutations } from 'vuex'
import {
  PICKUP_TYPE_ON_SITE,
  PICKUP_TYPE_TAKOUT,
  PICKUP_TYPE_DELIVERY,
  MENU_SOURCE_CHOICES,
  MENU_SOURCE_COLORS,
  ORDER_STATUSES,
  POS_API_DATETIME_INPUT_FORMAT,
} from '@/common/constants'
import { responseErrorsHandler, menuSourcesMixins } from '@/mixins'
import Loader from '@/components/Loader'
import OrderDetailsNavbar from './OrderDetailsNavbar'
import FinishOrderModal from './FinishOrderModal'
import OrderFormPapu3 from '@/components/papu-3/order-form/OrderFormPapu3'
import Customer from './Customer'
import OrderDetailsMap from './OrderDetailsMap'
import ChangeOrderLocalizationModal from './ChangeOrderLocalizationModal'
import PromisedTime from '@/components/PromisedTime'
import MealsList from '@/components/papu-3/order-details/MealsList'
import { API } from '@/services/api/api'

export default {
  name: 'OrderDetailsContent',
  components: {
    OrderFormPapu3,
    FinishOrderModal,
    OrderDetailsNavbar,
    Loader,
    Customer,
    OrderDetailsMap,
    ChangeOrderLocalizationModal,
    PromisedTime,
    MealsList,
  },
  mixins: [responseErrorsHandler, menuSourcesMixins],
  props: {
    uuid: {
      type: String,
      required: true,
    },
    fromModal: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      MENU_SOURCE_CHOICES,
      MENU_SOURCE_COLORS,
      ORDER_STATUSES,
      PICKUP_TYPE_ON_SITE,
      selectedFinishOrderChoice: '',
      finishOrderStatusSet: false,
      paymentMethods: [
        { text: this.$t('message.orderFormFull.paymentTypes.cash'), value: 'cash', icon: 'fa-money-bill-wave' },
        { text: this.$t('message.orderFormFull.paymentTypes.paid'), value: 'paid', icon: 'fa-clipboard-check' },
        { text: this.$t('message.orderFormFull.paymentTypes.card'), value: 'card', icon: 'fa-credit-card' },
      ],
      deliveryTypes: [
        { name: this.$t('message.orderFormFull.deliveryTypes.asap'), value: 'asap' },
        { name: this.$t('message.orderFormFull.deliveryTypes.onTime'), value: 'ontime' },
      ],
      promisedTimeValue: '',
      accept: false,
      decline: false,
    }
  },
  computed: {
    ...mapGetters('newOrder', ['editMode', 'orderData', 'loading', 'orderIsEditable']),
    ...mapGetters({
      menus: 'menus',
    }),
    order() {
      return this.orderData || {}
    },
    editButtonActive() {
      return this.editMode === 'preview'
    },
    customer() {
      return this.order?.customer
    },
    finishOrderChoices() {
      const choices = [
        {
          name: this.$t('message.orderDetails.finishOrderChoices.canceled'),
          value: 'canceled',
          pickupTypes: [PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT, PICKUP_TYPE_DELIVERY],
        },
        {
          name: this.$t('message.orderDetails.finishOrderChoices.delivered'),
          value: 'delivered',
          pickupTypes: [PICKUP_TYPE_DELIVERY],
        },
        {
          name: this.$t('message.orderDetails.finishOrderChoices.notDelivered'),
          value: 'not delivered',
          pickupTypes: [PICKUP_TYPE_DELIVERY],
        },
        {
          name: this.$t('message.orderDetails.finishOrderChoices.pickedUp'),
          value: 'finished',
          pickupTypes: [PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT],
        },
      ]
      return choices.filter((choice) => choice.pickupTypes.includes(this.order.pickup_type))
    },
    finishOrderButtonIsActive() {
      return this.order.status !== 'new' && this.editMode === 'preview'
    },
    cancelOrderButtonVisible() {
      // show button if menu_source not pyszne or order old_localization filled
      return ![parseInt(MENU_SOURCE_CHOICES.PYSZNE)].includes(this.order.source) || this.order.old_localization
    },
    isPromisedTimePickTimeChangeAvailable() {
      return (
        this.order.promised_time &&
        (this.order.source === MENU_SOURCE_CHOICES.NEW_ERESTAURANT ||
          (this.order.source === MENU_SOURCE_CHOICES.PYSZNE && this.$hasModuleAccess('takeaway-api')))
      )
    },
    showPromisedTimeComponent() {
      return !this.order.promised_time || this.isPromisedTimePickTimeChangeAvailable
    },
    acceptOrDeclineDisabled() {
      // You should not be able to accept or decline an order when:
      return (
        !this.promisedTimeValue &&
        !this.order.promised_time && // order's promised time value is not set to some value AND
        !this.decline &&
        !this.accept
      ) // both decline and accept flags are set to false (meaning no buttons to accept or decline an order were clicked)
    },
  },

  created() {
    this.getOrder(this.uuid)
    this.setEditMode('preview')
  },
  methods: {
    ...mapActions('newOrder', ['getOrder', 'updateOrder']),
    ...mapMutations('newOrder', ['setEditMode']),
    ...mapActions('ordersPapu3', ['getOrders']),
    ...mapActions({
      goToDefaultOrdersList: 'goToDefaultOrdersList',
    }),
    closeFinishOrderModal() {
      setTimeout(() => {
        if (this.$refs['okButton']?.focus) this.$refs['okButton'].focus()
      }, 500)
    },
    editOrder() {
      this.setEditMode('edit')
    },
    acceptOrder() {
      if (this.promisedTimeValue === '') {
        this.updateOrderPromisedTimeAndStatus(
          this.order.promised_time || this.$moment().format(POS_API_DATETIME_INPUT_FORMAT)
        )
      } else {
        const promisedTime = this.$moment().add(this.promisedTimeValue, 'minutes').format(POS_API_DATETIME_INPUT_FORMAT)
        this.updateOrderPromisedTimeAndStatus(promisedTime, this.promisedTimeValue)
      }
    },
    async declineOrder() {
      const { error } = await API.p3_cancelOrder(this.order.uuid)
      if (!error) {
        this.getOrders({ loading: true, filters: { archive: false } })
      } else {
        if (error.response) {
          this.setResponseErrors(error.response.data)
        }
      }
    },
    async declineTransfer() {
      const { error } = await API.p3_transferOrder(this.order.uuid, this.order.old_localization?.id)
      if (!error) {
        this.getOrders({ filters: { archive: false } })
      }
    },
    onOkClick() {
      // in case long loading, user might leave modal with pressing key "ESC"
      if (!this.loading) {
        if (this.order.status === ORDER_STATUSES.ORDER_STATUS_NEW) {
          // IF ACCEPTED
          if (!this.decline && (this.accept || this.promisedTimeValue !== '')) {
            this.acceptOrder()
          } else if (this.decline && this.order.old_localization) {
            this.declineTransfer()
            // IF CANCELED - cancel order (cancel button is not shown for pyszne)
          } else if (this.decline && !this.accept) {
            this.declineOrder()
          }
        } else {
          this.$emit('goToDefaultOrdersList')
        }
      }
      this.$emit('closeModal')
    },
    closeOrderDetails() {
      this.$emit('goToDefaultOrdersList')
      this.$emit('closeModal')
    },
    async updateOrderPromisedTimeAndStatus(promisedTime, minutes = null) {
      // For integrations, accept order and send either promised_time or minutes value
      if (
        [
          parseInt(MENU_SOURCE_CHOICES.PYSZNE),
          parseInt(MENU_SOURCE_CHOICES.NEW_ERESTAURANT),
          parseInt(MENU_SOURCE_CHOICES.RESTAUMATIC),
          parseInt(MENU_SOURCE_CHOICES.UPMENU),
          parseInt(MENU_SOURCE_CHOICES.UBEREATS),
          parseInt(MENU_SOURCE_CHOICES.GLOVO),
          parseInt(MENU_SOURCE_CHOICES.ORDER_SMART),
          parseInt(MENU_SOURCE_CHOICES.RESTABLO),
          parseInt(MENU_SOURCE_CHOICES.WOLT),
        ].includes(this.order.source)
      ) {
        const { error, data } = await API.p3_acceptOrder(this.order.uuid, {
          promised_time: promisedTime,
        })
        if (!error) {
          this.getOrders({ loading: true, filters: { archive: false } })
        }
      } else {
        this.updateOrder({
          uuid: this.order.uuid,
          order: {
            promised_time: promisedTime,
            status: ORDER_STATUSES.ORDER_STATUS_ACCEPTED,
          },
          onSuccess: () => {
            this.getOrders({ loading: true, filters: { archive: false } })
          },
        })
      }
    },
    changePromisedTime(promisedTimeValue) {
      // set promisedTime directly if received full date (in string) or promisedTimeValue (in minutes) if value
      this.promisedTimeValue = promisedTimeValue
    },
    showFinishOrderModal() {
      this.$eventBus.$emit('onShowFinishOrderModal')
    },
    getErrorValue(error) {
      const response = error?.response || null
      const data = response?.data || null
      const errorKey = data ? Object.keys(data) : null
      const errorValue = errorKey?.length ? data[errorKey[0]] : null
      return errorValue
    },
  },
}
</script>

<style scoped lang="scss">
.order-details-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: 100%;
  grid-gap: 10px;
  height: 100%;
  padding: 10px;
  background-color: #f0f4f7;
  overflow-y: auto;

  @include media-breakpoint-down(md) {
    padding: 5px;
    grid-gap: 5px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0;
    grid-gap: 0;
  }
}
.message-centred {
  margin: 0 15px;
}
.widget-details {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.widget-cart {
  overflow-y: auto;
  overflow-x: hidden;
}

.action {
  &--v-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--ok {
    min-height: 53px;
  }

  &.disabled,
  &[disabled] {
    background-color: #abb5be;
    border-color: #abb5be;
  }

  &__icon {
    font-size: 24px;
  }

  &__text {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    display: block;
    text-transform: uppercase;
    margin-left: 0;
    margin-top: 5px;

    &--inline {
      display: inline-block;
    }

    &--lg {
      font-size: 16px;
      line-height: 24px;
      margin-top: 0;
    }
  }

  &__shortcut {
    font-weight: bold;
    font-size: 10px;
    line-height: 24px;
    min-width: 32px;
    min-height: 24px;
    display: inline-block;
    padding: 0;
    font-family: 'Roboto', sans-serif;
  }
}

.order-details-footer-buttons {
  @include media-breakpoint-down(md) {
    margin-left: -2px;
    margin-right: -2px;
    .col {
      padding-left: 2px;
      padding-right: 2px;
    }
    .btn-lg {
      padding: 6px;
      i {
        font-size: 16px;
      }
    }
  }
}

.widget-info {
  overflow-y: auto;
  overflow-x: hidden;
}

.printing-error-button {
  border: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
}

.printing-error-button i {
  margin-bottom: 0;
}

.integration-error {
  &-icon {
    max-width: 50px;
    min-width: 25px;
    height: 100%;
    max-height: 30px;
    margin-left: -0.5rem;
  }
  &-info {
    font-size: 12px;
  }
}

@media (min-width: 1500px) {
  .printing-error-button {
    position: absolute;
    top: 50%;
    right: 20%;
    transform: translate(-50%, -50%);
  }
}
.center-checkbox {
  position: relative;
  transform: translate(50%, 12%);
}
.customer-details .fas {
  color: #3c4c58;
}

@media (max-width: 425px) {
  .integration-error {
    &-icon {
      margin-left: -1rem;
      margin-right: 0;
    }
    &-info {
      font-size: 10px;
    }
  }
}
</style>

<template>
  <b-modal
    id="futureDateConfirmModal"
    :title="$t('message.futureDateConfirmModal.chosenDateBigger')"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    :cancel-title="$t('message.common.cancel')"
    @ok="ok()"
    @cancel="cancel()"
    @hidden="hidden()"
  >
    <div>
      {{
        $t('message.futureDateConfirmModal.chosenDateBiggerInfo', {
          dateType: dateType,
          dateTimeDisplay: dateTimeDisplay,
          hoursToDate: hoursToDate,
        })
      }}
    </div>
  </b-modal>
</template>

<script>
import { POS_API_DATETIME_INPUT_FORMAT } from '@/common/constants'

export default {
  name: 'FutureDateConfirmModal',
  data: () => {
    return {
      dateType: '',
      value: '',
      initialValue: '',
    }
  },
  computed: {
    dateTimeDisplay() {
      return this.value
    },
    hoursToDate() {
      return this.$moment(this.value, POS_API_DATETIME_INPUT_FORMAT).diff(this.$moment(), 'hours')
    },
  },
  mounted() {
    this.$eventBus.$on('showFutureDateConfirmModal', ({ value, initialValue, dateType }) => {
      this.value = value
      this.initialValue = initialValue
      this.dateType = dateType
      this.$bvModal.show('futureDateConfirmModal')
    })
  },
  methods: {
    ok() {
      this.$emit('ok')
    },
    cancel() {
      // set old date only if different from new, otherwise set none
      this.$emit('cancel', this.initialValue && this.initialValue !== this.value ? this.initialValue : '')
    },
    hidden() {
      this.dateType = ''
      this.value = ''
      this.initialValue = ''
    },
  },
}
</script>

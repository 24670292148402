<template>
  <div>
    <b-navbar class="main-nav main-nav--order-details" type="dark">
      <div class="main-nav__left" style="margin-left: -15px">
        <b-navbar-nav>
          <li class="nav-item">
            <b-button variant="navbar" class="nav-link nav-link-button bg-transparent" @click="onClose()">
              <i class="fas fa-times-circle" :aria-label="$t('message.common.back')" style="font-size: 24px" />
            </b-button>
          </li>
        </b-navbar-nav>
        <div class="nav-item-info">
          <span class="nav-item-info__title">{{ $t('message.orderDetails.orderNumber') }}:</span>
          <b-spinner v-if="isLoading" small class="nav-item-info__spinner" />
          <strong v-else class="nav-item-info__value">{{ order.order_number }}</strong>
        </div>
        <NavItemOrderStatus
          class="d-none d-md-flex"
          :status-translated="getStatusTranslated(order.status)"
          :is-loading="isLoading"
          :finished-type-translated="getFinishTypeTranslate(order.finished_type)"
        />
        <!--        <NavItemOrderSource-->
        <!--          class="d-none d-md-flex"-->
        <!--          :is-loading="isLoading"-->
        <!--          :menu-source="order.source + ''"-->
        <!--          :menu-translated="order.source"-->
        <!--        />-->
      </div>
      <div class="main-nav__right">
        <b-button
          v-b-tooltip.hover.bottom
          class="send-order-button mr-3"
          variant="warning"
          :title="$t('message.orderDetails.changeOrderLocalizationInfo')"
          @click="showChangeOrderLocalizationModal()"
        >
          <span>
            <i class="fas fa-home" />
            <i class="fas fa-long-arrow-alt-right ml-1 mr-1" />
            <i class="fas fa-home" />
          </span>
        </b-button>
        <b-button
          v-if="$hasModuleAccess('edit-order')"
          id="editOrder"
          :class="`mr-${isMobile ? '3' : '0'}`"
          :size="isMobile ? 'sm' : 'md'"
          :variant="editButtonActive ? 'warning' : ''"
          :disabled="isLoading || !editButtonActive"
          @click="editOrder()"
        >
          <i class="fas fa-edit" />
          <span>{{ $t('message.common.edit') }}</span>
        </b-button>
      </div>
    </b-navbar>
    <div class="main-nav main-nav--order-details mobile-nav-expand d-md-none">
      <NavItemOrderStatus
        :status-translated="order.status"
        :is-loading="isLoading"
        :finished-type-translated="order.finished_type"
      />
    </div>
  </div>
</template>

<script>
import { MENU_SOURCE_COLORS } from '@/common/constants'
import { mapGetters } from 'vuex'
import NavItemOrderStatus from '@/components/order-details/NavItemOrderStatus'
import { orderMixins } from '@/mixins'

export default {
  name: 'OrderDetailsNavbar',
  components: { NavItemOrderStatus },
  mixins: [orderMixins],
  props: {
    onClose: { type: Function, required: true },
    isLoading: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      default: () => ({}),
    },
    editButtonActive: {
      type: Boolean,
      default: false,
    },
    editOrder: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      MENU_SOURCE_COLORS,
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  methods: {
    showChangeOrderLocalizationModal() {
      this.$eventBus.$emit('onShowChangeOrderLocalizationModal')
    },
  },
}
</script>

<style scoped lang="scss">
.mobile-nav-expand {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-extra-header {
  background-color: red;
}
</style>

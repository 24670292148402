export const commonMixins = {
  data() {
    return {}
  },
  methods: {
    intersectionObjectListsBy(array1, array2, compareField) {
      // return array1 filtered by array2 by same compareField
      // eg:
      // array1 = [{id: 1}, {id: 2}]
      // array2 = [{id: 1}, {id: 3}]
      // compareField = 'id'
      // returns [{id: 1}]
      var ids2 = array2.map((o) => o[compareField])
      return array1.filter((o) => ids2.includes(o[compareField]))
    },
  },
}

import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import Types from '@/types/interface'

export interface EmployeesListState {
  employees: Array<Types.User> // Array of Users
}

const getInitialState = (): EmployeesListState => {
  return {
    employees: [],
  }
}

const resetState = (state: EmployeesListState) => {
  Object.assign(state, getInitialState())
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations: {
    ...mutations,
    resetState,
  },
  actions,
}

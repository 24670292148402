<template>
  <div class="card meals-menu-container">
    <div v-if="showCategories" class="categories-container">
      <SearchMeal
        :search-keyword="searchKeyword"
        :on-input="searchMeal"
        @update:searchKeyword="
          (value) => {
            searchKeyword = value
          }
        "
      />
      <Categories
        :menu="menu"
        :meal-sets="!isMealSetItem && activeMenuNormalMealSets.length > 0 ? activeMenuNormalMealSets : null"
        :is-active="isActive"
        :is-meal-set-item="isMealSetItem"
        :active-category-name="activeCategoryName"
        :set-active-category-name-and-default-size="setActiveCategoryNameAndDefaultSize"
      />
    </div>
    <div v-show="!showOrderForm" class="meals-menu">
      <div v-if="!isMealSetItem && !activeMeal && !showOrderForm" class="widget-header">
        <span class="widget-header__title">
          <small>{{ $t('message.mealsMenu.mealsList') }}:</small> {{ getBrandName() }}
        </span>
      </div>
      <template v-if="menu">
        <div v-show="!activeMeal && !showOrderForm" class="meals-menu-wrapper">
          <div v-show="sizes.length > 1 && !searchKeyword" class="sizes-container">
            <Sizes
              :category-sizes="sizes"
              :is-active="isActive"
              :set-active-category-size-id="setActiveCategorySizeId"
            />
          </div>
          <b-alert v-if="searchKeyword.length > 0 && searchMeals.length <= 0" show variant="warning"
            >{{ $t('message.menu.notFoundMeal') }}.</b-alert
          >
          <Meals v-model="activeMeal" :meals="meals" :is-meal-sets="isMealSets" :meal-set-item="mealSetItem" />
        </div>
      </template>
      <template v-if="activeMeal">
        <meal-edit
          :meals="meals"
          :active-meal="activeMeal"
          :edit="isEditMeal"
          :meal-set-item-id="menu.mealSetItemId"
          @onSuccess="finishEdit"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Categories from './Categories'
import Sizes from './Sizes'
import Meals from './Meals'
import MealEdit from '@/components/meal-edit/MealEdit'
import SearchMeal from '@/components/meals-menu/SearchMeal'
import { mealsMixins } from '@/mixins/mealsMixins'

export default {
  name: 'MealsMenu',
  components: { Categories, Sizes, Meals, MealEdit, SearchMeal },
  mixins: [mealsMixins],
  props: ['menuJson', 'mealSetItem'],
  data: function () {
    return {
      activeCategoryName: '',
      activeCategorySizeId: '',
      activeMeal: null,
      isEditMeal: false,
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile',
      brandMenu: 'menu',
      showMealsMenu: '_orderEdit/showMealsMenu',
      showOrderForm: '_orderEdit/showOrderForm',
      activeMenuNormalMealSets: '_orderEdit/activeMenuNormalMealSets',
      brands: 'auth/brands',
      menus: 'menus',
    }),
    showCategories() {
      return this.menu && ((this.isMobile && !this.showOrderForm) || !this.isMobile)
    },
    menu() {
      // returns passed-by-prop menu or store menu (from brand)
      return this.menuJson ? this.menuJson : this.brandMenu
    },
    sizes() {
      // sizes of chosen category
      const category = this.menu.categories.filter((cat) => cat.name === this.activeCategoryName)[0]
      if (!category || !category.sizes) return []
      return category.sizes
    },
    meals() {
      // meals of chosen size or mealSets
      if (this.isMealSets) return this.activeMenuNormalMealSets
      else if (!this.sizes.length === 0) return []
      let activeCategorySize = this.sizes.filter((activeSize) => activeSize.id === this.activeCategorySizeId)[0]
      if (!activeCategorySize) return []
      let { meals } = activeCategorySize
      if (this.searchKeyword && this.searchMeals.length > 0) meals = this.searchMeals
      return meals || []
    },
    isMealSets() {
      // is category of MealSets chosen with mealSets instead of meals
      return this.activeCategoryName === 'mealSets'
    },
    isMealSetItem() {
      // is actual menu dynamically created from chosen MealSetItem's objects
      return this.menu?.mealSetItemId
    },
  },
  watch: {
    activeMeal(meal) {
      this.clearSearch()
    },
  },
  mounted() {
    this.init()
    this.$eventBus.$on('onMealEdit', (meal) => {
      this.isEditMeal = true
      this.setActiveMeal(meal)
      this.$store.commit('_orderEdit/toggleOrderForm', false)
    })
    this.$eventBus.$on('refreshNewOrder', () => {
      this.init()
      this.$eventBus.$emit('focusSearchMealInput')
      this.$eventBus.$emit('onUpdateCartActiveMeal', null)
    })
    this.$eventBus.$on('initNewOrder', () => {
      this.finishEdit()
    })
    this.$eventBus.$emit('focusSearchMealInput')
  },
  methods: {
    init() {
      if (this.mealSetItem && this.mealSetItem.size && this.mealSetItem.name) {
        // set submeal to be active meal only if mealSetItem has meal_groups - that means there's already meal assigned
        // to item and if so can be edited
        // Setting activeMeal shows MealDetails view immediately
        this.isEditMeal = true
        this.setActiveMeal(this.mealSetItem)
      } else {
        this.initNewMeal()
        this.setDefaultCategoryAndSize()
        if (!this.isMealSets && !this.isMealSetItem) this.setBrand()
      }
    },
    initNewMeal() {
      this.activeMeal = null
      this.clearSearch()
      this.isEditMeal = false
    },
    setBrand() {
      // sets brand info when more than 1 brand exists
      const menus = this.menus
      if (menus && this.menu) {
        const menu = menus.find((_menu) => _menu.json.id === this.menu.id)
        if (menu) {
          sessionStorage.setItem('currentBrandName', this.brands[menu.brand].name)
          sessionStorage.setItem('currentBrandId', menu.brand)
        }
      }
    },
    isActive(obj) {
      return (
        (obj['data'].name === this.activeCategoryName && obj.type === 'category') ||
        (obj['data'].id === this.activeCategorySizeId && obj.type === 'size')
      )
    },
    setDefaultCategoryAndSize() {
      if (!this.menu || !this.menu.categories) return false
      let activeCategory = this.menu.categories.filter((cat) => cat.is_default === true)[0] || this.menu.categories[0]
      if (!activeCategory) return false
      const { name, sizes } = activeCategory
      this.activeCategoryName = name || ''
      if (sizes) {
        let activeCategorySize =
          activeCategory.sizes.filter((size) => size.is_default === true)[0] || activeCategory.sizes[0]
        if (activeCategorySize) this.activeCategorySizeId = activeCategorySize.id
      }
    },
    setActiveCategoryNameAndDefaultSize(categoryName) {
      this.initNewMeal()
      this.activeCategoryName = categoryName
      if (this.sizes.length > 0) {
        const activeCategorySize = this.sizes.filter((size) => size.is_default === true)[0] || this.sizes[0]
        if (activeCategorySize) this.activeCategorySizeId = activeCategorySize.id
      }
    },
    setActiveCategorySizeId(categorySizeId) {
      this.activeCategorySizeId = categorySizeId
    },
    searchMeal() {
      this.searchThroughMeals()
    },
    setActiveMeal(meal) {
      this.clearSearch()
      this.activeMeal = {}
      this.activeMeal = meal
    },
    finishEdit() {
      this.initNewMeal()
      this.$eventBus.$emit('focusSearchMealInput')
    },
    clearSearch() {
      this.searchKeyword = ''
    },
    getBrandName() {
      return sessionStorage.getItem('currentBrandName') || ''
    },
  },
}
</script>

<style lang="scss" scoped>
.meals-menu {
  width: 100%;
  overflow: auto;

  @include media-breakpoint-down(xs) {
    margin-top: 50px;
    border-top: 1px solid $gray-400;
  }

  &-container {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: 0;
    height: 100%;

    @include media-breakpoint-down(xs) {
      grid-template-columns: 1fr;
      border: none;
      border-radius: 0;
    }
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
  }
}
.categories-container {
  border-right: 1px solid #dbdbdd;
  display: flex;
  flex-direction: column;
  overflow: auto;

  @include media-breakpoint-down(xs) {
    position: absolute;
    flex-direction: row;
    width: 100%;
    height: 50px;
    overflow: visible;
  }
}
.categories-search {
  position: relative;
  border-bottom: 2px solid #eaeaea;
  display: flex;
  align-items: center;
  height: 52px;
  background-color: #fff;

  &.is-searching-active {
    .fa-search {
      color: $primary;
      opacity: 1;
      transform: scale(1.15);
    }
    .categories-search__clear {
      transform: scale(1);
    }
  }
  i {
    margin-left: 10px;
    opacity: 0.5;
    transition: all 0.25s ease;
  }
  .form-control {
    border: none;
    height: 50px;
  }

  &__clear {
    position: absolute;
    right: 10px;
    z-index: 10;
    cursor: pointer;
    transform: scale(0);
    &:hover {
      opacity: 1;
    }
  }
}

.sizes-container {
  overflow-x: auto;
  padding: 0 10px;
}
</style>

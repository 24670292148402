import { SettingsState } from './index'

const setLocalizationPreferences = (state: SettingsState, localizationPreferences: any) => {
  state.localizationPreferences = { ...localizationPreferences }
}

const setErestoPreferences = (state: SettingsState, erestoPreferences: any) => {
  state.erestoPreferences = { ...erestoPreferences }
}

const setLocalizationPermissions = (state: SettingsState, localizationPermissions: any) => {
  state.localizationPermissions = { ...localizationPermissions }
}

export default {
  setLocalizationPreferences,
  setErestoPreferences,
  setLocalizationPermissions,
}

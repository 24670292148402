<template>
  <div class="">
    <b-form-radio-group
      v-model="form.discount_type"
      buttons
      :size="isMobile ? 'sm' : 'md'"
      button-variant="outline-primary"
      class="d-md-inline btn-group--center"
      :options="discountTypeOptions"
      @input="discountChange()"
    >
    </b-form-radio-group>
    <div class="d-flex mt-3">
      <price-input
        id="discountInput"
        v-model="form.discount_value"
        v-validate="validateDiscountValue"
        :append-text="getDiscountTypeOptionAppend()"
        class="d-md-inline"
        size="lg"
        name="discountValue"
        :disabled="!discountType"
        @input="discountChange()"
      >
      </price-input>
      <div class="mt-1">
        <input-error-msg class="h5" field="discountValue" :_errors="errors"></input-error-msg>
      </div>
      <span v-show="discountType" class="text-danger ml-1 mt-2">*</span>
    </div>
  </div>
</template>

<script>
import { DISCOUNT_CHOICES } from '@/common/constants'
import PriceInput from '@/components/PriceInput'
import { responseErrorsHandler } from '@/mixins'
import { isMobile, addResizeEvent, removeResizeEvent } from '@/utils/common'

export default {
  name: 'MealSetDiscount',
  components: { PriceInput },
  mixins: [responseErrorsHandler],
  props: ['discountType', 'discountValue'],
  data() {
    return {
      form: {
        discount_type: null,
        discount_value: null,
      },
      discountTypeOptions: [
        { value: null, text: this.$t('message.discounts.noDiscount'), inputAppend: '' },
        {
          value: DISCOUNT_CHOICES.DISCOUNT_CHOICE_PERCENT,
          text: this.$t('message.discounts.edit.discountValuePercent'),
          inputAppend: '%',
        },
        {
          value: DISCOUNT_CHOICES.DISCOUNT_CHOICE_VALUE,
          text: this.$t('message.discounts.edit.discountValuePrice'),
          inputAppend: 'currency',
        },
        {
          value: DISCOUNT_CHOICES.DISCOUNT_CHOICE_PROMO_PRICE,
          text: this.$t('message.discounts.edit.discountPromotionalPrice'),
          inputAppend: 'currency',
        },
      ],
      isMobile: false,
    }
  },
  computed: {
    validateDiscountValue() {
      if (!this.form.discount_type) return null
      return {
        required: true,
        min: 1,
        max: 8,
        min_value: this.form.discount_type === DISCOUNT_CHOICES.DISCOUNT_CHOICE_PERCENT ? '1' : '0',
        // For percent value - max 100 value can be set, otherwise 999999
        max_value: this.form.discount_type === DISCOUNT_CHOICES.DISCOUNT_CHOICE_PERCENT ? '100.00' : '999999.00',
      }
    },
  },
  watch: {
    form() {
      this.discountChange()
    },
  },
  mounted() {
    if (this.discountValue !== null && this.discountType !== null) {
      this.setDefaultDiscount()
    }
    this.setIsMobile()
    addResizeEvent(this.setIsMobile)
  },
  destroyed() {
    removeResizeEvent(this.setIsMobile)
  },
  methods: {
    discountChange() {
      this.discountTypeChange()
      this.$emit('discountChange', this.form)
    },
    discountTypeChange() {
      if (!this.form.discount_type) this.form.discount_value = null
    },
    setDefaultDiscount() {
      this.form.discount_type = this.discountType ? this.discountType : null
      this.form.discount_value = this.discountValue ? this.discountValue : null
    },
    getDiscountTypeOptionAppend() {
      if (this.form.discount_type) {
        let append = this.discountTypeOptions.find((option) => option.value === this.form.discount_type).inputAppend
        if (append === 'currency') return this.$store.getters['currency']
        else return append
      } else return '-'
    },
    setIsMobile() {
      this.isMobile = isMobile()
    },
  },
}
</script>

import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { Shift } from '@/types/interface'

export interface ShiftsCurrentState {
  currentShifts: Array<Shift>
  currentShiftsRequest: string
}

const state: ShiftsCurrentState = {
  currentShifts: [],
  currentShiftsRequest: '',
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { User } from '@/types/interface'

export interface EmployeeEditState {
  userId: number | null
  user: User
}

const state: EmployeeEditState = {
  userId: null,
  user: {} as User,
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

<template>
  <div class="categories">
    <b-button class="categories-toggle-btn" block variant="primary" @click="toggleCategories"
      ><small>{{ $t('message.mealSets.category') }}</small
      >{{ activeCategoryName === 'mealSets' ? $t('message.categories.mealSets') : activeCategoryName }}</b-button
    >
    <b-list-group :class="['categories-list', { 'categories-list--visible': categoriesListOpened }]">
      <b-list-group-item
        v-for="(category, index) in menu.categories"
        :key="category.id"
        :value="category.name"
        class="category"
        :class="[{ active: isActive({ type: 'category', data: category }) }]"
        @click="categoryClick(category.name)"
        >{{ category.name }}
        <span class="category__count">{{ getCategoryMealsCount(category.sizes) }}</span>
      </b-list-group-item>
      <b-list-group-item
        v-if="mealSets"
        class="category"
        :class="{ active: isActive({ type: 'category', data: { name: 'mealSets' } }) }"
        @click="categoryClick('mealSets')"
      >
        {{ $t('message.categories.mealSets') }}
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Categories',
  props: ['menu', 'isActive', 'setActiveCategoryNameAndDefaultSize', 'mealSets', 'isMealSetItem', 'activeCategoryName'],
  data() {
    return {
      categoriesListOpened: false,
    }
  },
  methods: {
    categoryClick(categoryName) {
      // If meal was changed, it pops up confirmation modal, otherwise picks category
      if (this.$store.getters['_orderEdit/mealSaved'] || this.isMealSetItem) {
        this.setActiveCategoryNameAndDefaultSize(categoryName)
        this.$store.commit('_orderEdit/toggleOrderForm', false)
        this.$eventBus.$emit('onUpdateCartActiveMeal', null)
      } else {
        this.$eventBus.$emit('onShowMealDiscardChangesModal')
      }
      this.closeCategories()
    },
    getCategoryMealsCount(sizes) {
      let count = 0
      sizes.forEach((size) => {
        count += size.meals.length
      })
      return count
    },
    toggleCategories() {
      this.categoriesListOpened = !this.categoriesListOpened
    },
    closeCategories() {
      this.categoriesListOpened = false
    },
  },
}
</script>
<style lang="scss" scoped>
.categories {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  min-width: 110px;
  background-color: #fbfbfb;

  @include media-breakpoint-up(lg) {
    min-width: 180px;
    overflow: unset;
  }

  &-list {
    padding: 0;
    margin: 0;
    list-style: none;
    overflow-y: auto;
    height: 100%;

    @include media-breakpoint-down(xs) {
      display: none;
      position: absolute;
      top: 50px;
      height: calc(100vh - 55px);
      width: 100%;
      left: 0;
      z-index: 9999;
      background: #ffffffbf;
      &--visible {
        display: inherit;
      }
    }
  }

  &-toggle-btn {
    display: none;

    @include media-breakpoint-down(xs) {
      height: 100%;
      display: inherit;
      position: relative;
      padding-top: 18px;
      border-radius: 0;
      small {
        position: absolute;
        left: 11px;
        top: 3px;
        opacity: 0.5;
        font-size: 11px;
      }
    }
  }
}
.category {
  position: relative;
  text-align: center;
  background-color: $gray-200;
  border: none;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px 10px;
  font-weight: 500;

  @include media-breakpoint-down(md) {
    font-size: 13px;
  }

  &:hover {
    background-color: #d5d5dd;
  }
  &.active {
    background-color: $primary;
    color: #fff;

    .category__count {
      color: rgba(255, 255, 255, 0.75);
    }
  }
  &:focus {
    outline: none;
  }
  &__count {
    position: absolute;
    right: 0px;
    top: 0px;
    font-size: 11px;
    line-height: normal;
    padding: 3px 4px;
    color: rgba(0, 0, 0, 0.45);
    border-radius: 0 0 0 6px;
    background: rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.02);
    border-top: none;
    border-right: none;
  }
}
</style>

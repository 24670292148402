import { API } from '@/services/api/api'
import { BILL_STATUSES } from '@/common/constants'

const getBills = async ({ commit, state }) => {
  commit('setBillsRequestStatus', 'loading')
  const { error, data } = await API.getBills({ order: state.orderId })
  if (!error && data) {
    commit('setBills', data)
    commit('setBillsRequestStatus', 'success')
  } else {
    commit('setBillsRequestStatus', 'error')
    throw { errorValue: getErrorValue(error), errorStatus: getErrorStatus(error) }
  }
}

const createBill = async ({ state, dispatch }) => {
  const { error } = await API.createBill({ order: state.orderId })
  if (!error) {
    await dispatch('getBills')
  } else {
    throw { errorValue: getErrorValue(error), errorStatus: getErrorStatus(error) }
  }
}

const deleteBill = async ({ dispatch }, id) => {
  const { error } = await API.deleteBill(id)
  if (!error) {
    await dispatch('getBills')
  } else {
    throw { errorValue: getErrorValue(error), errorStatus: getErrorStatus(error) }
  }
}

const movePosition = async ({ dispatch }, { positionId, quantity, sourceBillId, toBillId }) => {
  const { error } = await API.moveBillPosition({
    position_id: positionId,
    quantity,
    source_bill_id: sourceBillId,
    to_bill_id: toBillId,
  })
  if (!error) {
    await dispatch('getBills')
  } else {
    throw { errorValue: getErrorValue(error), errorStatus: getErrorStatus(error) }
  }
}

const closeBill = async ({ state, dispatch }, id) => {
  const bill = state.bills.find((bill) => bill.id === id)
  const payment = bill.payment
  const { error } = await API.updateBill(id, {
    status: BILL_STATUSES.CREATED,
    payment,
  })
  if (!error) {
    await dispatch('getBills')
  } else {
    throw { errorValue: getErrorValue(error), errorStatus: getErrorStatus(error) }
  }
}

const splitBillEqually = async ({ state, dispatch }, amount) => {
  const { error } = await API.splitBillEqually({
    bills_amount: amount,
    order: parseInt(state.orderId),
  })
  if (!error) {
    await dispatch('getBills')
  } else {
    throw { errorValue: getErrorValue(error), errorStatus: getErrorStatus(error) }
  }
}

const setSelectedPositionData = async ({ commit }, { position, sourceBillId, toBillId }) => {
  commit('setSelectedBillPosition', position)
  commit('setSelectedSourceBillId', sourceBillId)
  commit('setSelectedToBillId', toBillId)
}

const getErrorValue = (error) => {
  const response = error?.response || null
  const data = response?.data || null
  const errorKey = data ? Object.keys(data) : null
  const errorValue = errorKey?.length ? data[errorKey[0]] : null
  return errorValue
}

const getErrorStatus = (error) => {
  const response = error?.response || null
  const errorStatus = response?.status || null
  return errorStatus
}

export default {
  getBills,
  createBill,
  deleteBill,
  movePosition,
  setSelectedPositionData,
  closeBill,
  splitBillEqually,
}

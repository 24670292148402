<template>
  <b-navbar class="main-nav pl-0" type="dark">
    <b-navbar-nav class="w-100 justify-content-between">
      <li class="nav-item">
        <b-button variant="navbar" class="nav-link nav-link-button bg-transparent" @click="_onClick">
          <i class="fas fa-arrow-circle-left" :aria-label="$t('message.common.back')" />
        </b-button>
        <h1 class="nav-title">
          <slot name="title"> <span /> </slot>
        </h1>
      </li>
      <li class="nav-item mr-2">
        <slot name="rightSide">
          <span />
        </slot>
      </li>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
export default {
  name: 'NavBack',
  props: {
    onClick: {
      type: [Function, undefined],
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    _onClick() {
      this.onClick ? this.onClick() : this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss" scoped>
.nav-item {
  display: flex;
  align-items: center;
}
</style>

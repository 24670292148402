<template>
  <div class="view-container">
    <b-container>
      <b-row align-v="center" align-h="between">
        <b-col>
          <b-breadcrumb class="pl-4 mb-1">
            <b-breadcrumb-item :to="{ name: 'settings' }">
              {{ $t('message.settings.settings') }}
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'kitchen-settings' }" active>
              KDS - {{ $t('kitchen_settings') }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
      </b-row>

      <section class="widget">
        <div class="widget-body-wrapper p-4">
          <b-row>
            <b-col xs="12" md="8" lg="6" class="mx-auto">
              <b-form>
                <b-form-group
                  label="Ilość piecy"
                  labe-for="stoves"
                >
                  <b-form-input
                    id="stoves"
                    v-model="form.stoves_quantity"
                    v-validate="'required'"
                    type="number"
                    name="stoves"
                    :placeholder="`${$t('message.employeeForm.email')} *`"                    
                  />                  
                </b-form-group>
                <b-button class="mt-3" :disabled="isLoading" block variant="success" @click.prevent="save()">
                  {{ $t('save') }}
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </div>
      </section>
    </b-container>
  </div>
</template>

<script>
import { API } from '@/services/api/api'

export default {
  name: 'KitchenSettings',
  data() {
    return {
      form:{
        stoves_quantity: 0
      },
      isLoading: false
    }
  },
  computed:{
    localizationId(){
      return this.$store.state.auth.localization?.id
    }
  },
  methods:{
    async getLocalization(){
      this.isLoading = true
      const { error, data } = await API.getLocalization(this.localizationId)
      if (!error && data){
        this.initForm(data)
      }
      this.isLoading = false
    },
    async save(){
      this.isLoading = true
      const { error, data } = await API.updateLocalization(this.localizationId, {...this.form})
      if (!error && data){
        this.$toasted.show(this.$t('save_successfully'), {
            type: 'success',
            icon: {
              name: 'check',
            },
            duration: 3000,
          })
      }
      this.isLoading = false
    },
    initForm(data){
      this.form.stoves_quantity = data.stoves_quantity
    }
  },
  mounted(){
    this.getLocalization()
  }
}
</script>
<style lang="scss">

</style>

import { API } from '@/services/api/api'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import { ShiftState } from './index'
import Types from '@/types/interface'

const getShiftDetails = async (
  { commit }: ActionContext<ShiftState, RootState>,
  payload: { loading?: boolean; shiftId: number }
) => {
  const { loading, shiftId } = payload
  if (loading) commit('setShiftDetailsRequest', 'loading')
  const { error, data } = await API.getShift(shiftId)
  if (!error && data) {
    commit('setShift', data)
    commit('setShiftDetailsRequest', 'success')
  } else {
    commit('setShiftDetailsRequest', 'error')
  }
}

const finishShift = async (
  {}: ActionContext<ShiftState, RootState>,
  payload: {
    formData: Types.FinishShiftData
    onSuccess: ({}) => void
    onError: ({}) => void
  }
) => {
  const { formData, onSuccess, onError } = payload
  const { error, data }: { data: any; error: any } = await API.finishShift(formData)
  if (!error && data) {
    if (onSuccess) onSuccess(data)
  } else {
    if (onError && error?.response?.data) onError(error?.response?.data)
  }
}

export default {
  getShiftDetails,
  finishShift,
}

<template>
  <div class="table-responsive">
    <table class="table table-striped table-align-v--mid">
      <thead>
        <tr>
          <th scope="col">{{ $t('message.common.name') }}</th>
          <th scope="col">{{ $t('message.common.color') }}</th>
          <th scope="col">{{ $t('message.common.user') }}</th>
          <th scope="col">{{ $t('message.workstationEdit.form.mealCategories') }}</th>
          <th scope="col">{{ $t('message.workstations.activeWorkstation') }}</th>
          <th></th>
        </tr>
      </thead>
      <template v-if="isLoading">
        <TablePlaceholder :col="6" :loading="true" />
      </template>
      <template v-else-if="workstations.length === 0">
        <td colspan="100%" class="text-center">{{ $t('message.workstations.noWorkstation') }}</td>
      </template>
      <template v-else>
        <tbody>
          <tr v-for="workstation in workstations" :key="workstation.id" :class="getIsOnlineClass(workstation.user)">
            <td>{{ workstation.name }}</td>
            <td>
              <span
                class="workstation__color-label"
                :style="{ 'background-color': workstation.color, 'box-shadow': `0 0 10px ${workstation.color}64` }"
              >
                {{ workstation.name[0] }}
              </span>
            </td>

            <td class="workstation-row__user">
              {{ getUserNameById(workstation.user) || $t('message.workstations.userLogout') }}
            </td>
            <td class="text-wrap">{{ getMealCategoriesNames(workstation.meal_categories) }}</td>
            <td>
              <toggle-button
                :value="workstation.is_active"
                sync
                @change="setActiveStatus(workstation.id, !workstation.is_active, null)"
              />
            </td>
            <td class="text-right">
              <router-link
                :to="{ name: 'workstation-edit', params: { id: workstation.id } }"
                tag="button"
                class="btn btn-warning mr-3"
                :disabled="workstation.id === removingLoadingId"
                ><i class="fas fa-edit" />
              </router-link>
              <b-button
                variant="danger"
                :disabled="workstation.id === removingLoadingId"
                @click="remove(workstation.id)"
              >
                <i class="far fa-trash-alt" />
              </b-button>
            </td>
          </tr>
        </tbody>
      </template>
    </table>
  </div>
</template>

<script>
import TablePlaceholder from '@/components/TablePlaceholder'
import ToggleButton from 'vue-js-toggle-button/src/Button'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { showSuccessToast } from '@/utils/common'
const STORE = '_workstations'

export default {
  name: 'WorkstationsTable',
  components: { TablePlaceholder, ToggleButton },
  data() {
    return {
      isLoading: false,
      removingLoadingId: null,
    }
  },
  computed: {
    ...mapState(STORE, ['workstations', 'mealCategories']),
    ...mapGetters('employees', ['employees']),
  },
  async mounted() {
    this.isLoading = true
    this.setMealCategories([])
    await this.getMealCategories()
    await this.getEmployees()
    await this._getWorkstations()
  },
  methods: {
    ...mapActions(STORE, [
      'getMealCategories',
      'getWorkstations',
      'updateWorkstation',
      'removeWorkstation',
      'updateWorkstationActiveStatus',
    ]),
    ...mapActions('employees', ['getEmployees']),
    ...mapMutations(STORE, ['setMealCategories']),
    async _getWorkstations() {
      this.isLoading = true
      await this.getWorkstations()
      this.isLoading = false
    },
    getIsOnlineClass(user) {
      return user ? 'workstation-row--is-online' : 'workstation-row--is-offline'
    },
    getUserNameById(id) {
      return this.employees.find((user) => user.id === id)?.name
    },
    getMealCategoriesNames(mealCategories) {
      const found = []
      mealCategories.forEach((id) => {
        this.mealCategories.forEach((brand) =>
          brand.categories.forEach((cat) => {
            if (cat.id === id) found.push(cat.name)
          })
        )
      })
      return found.join(', ')
    },
    setActiveStatus(id, is_active) {
      this.updateWorkstationActiveStatus({ id, is_active })
    },
    remove(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('message.menu.deleteMsgBox.confirm'), {
          centered: true,
          size: 'sm',
          okTitle: this.$t('message.common.delete'),
          cancelTitle: this.$t('message.common.cancel'),
          okVariant: 'danger',
          cancelVariant: 'outline-secondary',
          footerClass: 'modal-footer--menu-management',
        })
        .then(async (confirm) => {
          if (!confirm) return false
          this.removingLoadingId = id
          const { error } = await this.removeWorkstation(id)
          if (!error) {
            showSuccessToast(this.$toasted, this.$t('message.workstationEdit.workstationDeleted'))
            await this._getWorkstations()
          }
          this.removingLoadingId = null
        })
    },
  },
}
</script>

<style lang="scss">
.workstation__color-label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.1);
}
.workstation-row {
  &--is-online {
    td:first-child {
      border-left: 5px solid $green;
    }
  }
  &--is-offline {
    td:first-child {
      border-left: 5px solid $red;
    }
    .workstation-row__user {
      color: $red;
    }
  }
}
</style>

import { API } from '@/services/api/api'

type SettingsType = 'preferences' | 'erestaurant' | 'permissions'

const getApiMethodName = (settingsType: SettingsType) => {
  switch (settingsType) {
    case 'preferences': {
      return 'savePreferences'
    }
    case 'erestaurant': {
      return 'saveErestaurantSettings'
    }
    case 'permissions': {
      return 'savePermissions'
    }
  }
}

const sendPreferences = async (
  {},
  payload: {
    settingsType: SettingsType
    formData: any
    onSuccess: (a: any) => void
    onError: (a: any) => void
  }
) => {
  const { settingsType, formData, onSuccess, onError } = payload
  const { error, data } = await API[getApiMethodName(settingsType)](formData)
  if (!error && data) {
    onSuccess?.(data)
  } else {
    onError?.(error)
  }
  return { error, data }
}

export default {
  sendPreferences,
}

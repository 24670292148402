<template>
  <div class="card">
    <div class="widget-header">
      <span class="widget-header__title">{{ $t('message.orderDetails.customerDetails') }}</span>
    </div>
    <div class="widget-details widget-info customer-details">
      <CustomerDetails v-if="customer" :customer="customer" />

      <!--      Place for Last customer's orders -->

      <!--      Place for last cutomer's reviews -->
    </div>
  </div>
</template>

<script>
import CustomerDetails from './CustomerDetails'

export default {
  name: 'Customer',
  components: { CustomerDetails },
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
    isOrderLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    customer() {
      return this.order?.order_customer
    },
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.customer-details {
  width: 300px;
  overflow-y: auto;
  overflow-x: hidden;

  @include media-breakpoint-down(md) {
    width: 230px;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.send-order-button {
  height: 50px;
  width: 100px;
  text-align: left;

  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>

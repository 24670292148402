<template>
  <div :class="['meal-status-set-item', { 'meal-status-set-item--is-active': isActive }]" :style="{ backgroundColor }">
    <i :class="`fas fa-${getStatusIconName(status)} meal-status-set-item__icon`" />
    <span class="meal-status-set-item__name">{{ getStatusName(status) }}</span>
  </div>
</template>

<script>
import { mealsStatusMixins } from '@/mixins/mealStatusMixins'
export default {
  name: 'MealStatusSetItem',
  mixins: [mealsStatusMixins],
  props: {
    status: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    backgroundColor() {
      return this.isActive ? this.getStatusColor(this.status) : '#eaeaea'
    },
  },
}
</script>
<style lang="scss" scoped>
.meal-status-set-item {
  width: 64px;
  height: 64px;
  padding: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: grayscale(1);
  opacity: 0.3;
  background-color: #eaeaea;

  &--is-active {
    filter: grayscale(0);
    color: #fff;
    opacity: 1;
  }

  &__icon {
    margin-bottom: auto;
    margin-top: auto;
    font-size: 20px;
  }

  &__name {
    white-space: nowrap;
    font-size: 12px;
  }

  & + .meal-status-set-item {
    margin-left: 10px;
  }
}
</style>

import { MENU_SOURCE_CHOICES, MENU_SOURCE_COLORS } from '@/common/constants'
import { invertColor } from '@/utils/common'

export const menuSourcesMixins = {
  methods: {
    getSourceColor(source) {
      let color = MENU_SOURCE_COLORS.DEFAULT
      if (!source) return color

      const _source = typeof source === 'string' || typeof source === 'number' ? source : source.source
      Object.keys(MENU_SOURCE_CHOICES).forEach((key) => {
        if (parseInt(MENU_SOURCE_CHOICES[key]) === parseInt(_source)) {
          color = MENU_SOURCE_COLORS[key]
        }
      })
      return color
    },
    getButtonTextColor(source) {
      return invertColor(this.getSourceColor(source), true)
    },
  },
}

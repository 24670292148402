import _ from 'lodash'
import { FISCAL_TYPES } from '@/common/constants'
import { IAuthState, ILocalizationFeatures, IMenuSource } from '@/types/interface'
import { RootGetters, RootState } from '@/store'

const getAuthToken = (state: IAuthState) => state.authToken
const getUserName = () => sessionStorage.getItem('name')
const getLoggedIn = (state: IAuthState) => state.loggedIn === 'true'
const signInRequestStatus = (state: IAuthState) => state.signInRequestStatus
const getLocalizationName = (state: IAuthState) => state.localization?.name
const getLocalizationCity = (state: IAuthState) => state.localization?.city
const getLocalizationId = (state: IAuthState) => state.localization?.id
const getLocalizationLat = (state: IAuthState) => state.localization?.lat
const getLocalizationLon = (state: IAuthState) => state.localization?.lon
const getLocalizationFeatures = (state: IAuthState): ILocalizationFeatures => state.localization?.features || {}
const brands = (state: IAuthState) => state.brands
const isMultiBrand = (state: IAuthState) => _.size(_.filter(state.brands, (brand) => !brand.only_online)) > 1
const erestoBrands = (state: IAuthState) => _.omitBy(state.brands, (value) => value.eresto === null)
const getMenuSources = (state: IAuthState) => state.menuSources
const getCompany = (state: IAuthState) => state.company
const companyLocalizations = (state: IAuthState) => state.companyLocalizations
const getPaymentBackend = (state: IAuthState) => state.paymentBackend
const isTrial = (state: IAuthState) => state.company && state.company.is_trial
const hasFiscal = (state: IAuthState) => state.localization && state.localization.fiscal_type !== FISCAL_TYPES.NO_FISCAL
const defaultOnsitePrintBill = (
  state: IAuthState,
  getters: { hasFiscal: any },
  rootState: RootState,
  rootGetters: { [x: string]: any }
) => {
  const localizationPreferences = rootGetters['settings/localizationPreferences']
  return localizationPreferences
    ? localizationPreferences.order_onsite_bill_print && getters.hasFiscal
    : getters.hasFiscal
}
const defaultDeliveryPrintBill = (
  state: IAuthState,
  getters: { hasFiscal: any },
  rootState: RootState,
  rootGetters: { [x: string]: any }
) => {
  const localizationPreferences = rootGetters['settings/localizationPreferences']
  return localizationPreferences
    ? localizationPreferences.order_delivery_auto_print_bill && getters.hasFiscal
    : getters.hasFiscal
}
const hasUserRole = (state: IAuthState) => (roles: Array<string>) => roles.includes(state.userRole)
const localizations = (state: IAuthState) => state.localizations
const getSourceById = (state: IAuthState) => (brandId: number, sourceId: string) =>
  state.menuSources?.[brandId]?.find((source) => source.source === parseInt(sourceId))
const hasSourceMenu = (state: IAuthState) => (searchedMenuSource: string) => {
  if (!state.menuSources) return false
  const menuSources = Object.values(state.menuSources)
  return menuSources.some((menu) => menu.some((source) => source.source === parseInt(searchedMenuSource)))
}
const getExistingMenuSources = (state: IAuthState) => {
  const existingIntegrations: { [key: number]: string } = {}
  if (state.menuSources)
    Object.values(state.menuSources).forEach((brand) => {
      brand.forEach((menuSource: IMenuSource) => {
        existingIntegrations[menuSource.source] = menuSource.name
      })
    })
  return existingIntegrations
}
const isErestoBrand = (
  state: IAuthState,
  getters: { erestoBrands: Array<{ [key: string]: { eresto: string; name: string } }> },
  rootState: RootState,
  rootGetters: RootGetters
) => {
  const ids = Object.keys(getters.erestoBrands)
  return rootGetters.currentBrandId ? ids.includes(rootGetters.currentBrandId.toString()) : false
}

const createdByPosAppsmart = (state: IAuthState): boolean => state.company?.created_by === 'POS_APPSMART'
const isPapu3 = (state: IAuthState): boolean => !!state.localization?.features?.papu_3

export default {
  getAuthToken,
  getUserName,
  getLoggedIn,
  getLocalizationName,
  getLocalizationId,
  getLocalizationCity,
  getLocalizationLat,
  getLocalizationLon,
  getLocalizationFeatures,
  brands,
  isMultiBrand,
  erestoBrands,
  getMenuSources,
  getSourceById,
  getCompany,
  companyLocalizations,
  getPaymentBackend,
  isTrial,
  hasFiscal,
  defaultOnsitePrintBill,
  defaultDeliveryPrintBill,
  hasUserRole,
  signInRequestStatus,
  localizations,
  hasSourceMenu,
  isErestoBrand,
  getExistingMenuSources,
  createdByPosAppsmart,
  isPapu3,
}

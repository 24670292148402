<template>
  <div class="widget">
    <div class="widget-body-wrapper">
      <div class="container-fluid mt-3">
        <div class="row">
          <div class="col-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb bg-white pl-0">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'settings' }" tag="a">{{ $t('message.settings.settings') }}</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'billing-periods' }" tag="a">{{
                    $t('message.settings.billingPeriods')
                  }}</router-link>
                </li>
                <li class="breadcrumb-item active">
                  {{ billingPeriodTitle }}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div class="row filters">
          <h4 class="employee-name">{{ emplyoeeName }}</h4>
          <div class="mr-5">
            <span>{{ $t('message.billing.beginning') }}:</span>
            <span class="font-weight-medium">{{ billingPeriod.started_at }}</span>
          </div>
          <b-form inline>
            <label class="mr-sm-2" for="inlineFormCustomSelectPref">{{ $t('message.billing.end') }}:</label>
            <b-form-select
              id="inlineFormCustomSelectPref"
              v-model="selectedFinishDate"
              class="mb-2 mr-sm-2 mb-sm-0"
              :value="null"
            >
              <option :value="null">{{ $t('message.billing.all') }}</option>
              <option v-for="(date, index) in finishedShiftsDates" :key="'date_' + index" :value="date">
                {{ date }}
              </option>
            </b-form-select>
          </b-form>
        </div>
        <div class="row mt-3">
          <div class="col-8">
            <summary-table />
          </div>
          <b-col v-if="billingPeriodRequest === 'loading'" cols="4">
            <TablePlaceholder :row="1" :col="4" :loading="true" />
          </b-col>
          <b-col v-else cols="4">
            <template v-if="!billingPeriod.finished">
              <b-button variant="primary" size="lg" block @click="closeBillingPeriod">{{
                $t('message.billing.closeAndSettle')
              }}</b-button>
              <response-error-msg field="non_field_errors" :response-errors="responseErrors" style="margin-top: 10px" />
              <response-error-msg
                field="billing_period_id"
                :response-errors="responseErrors"
                style="margin-top: 10px"
              />
              <response-error-msg field="shift_end_date" :response-errors="responseErrors" style="margin-top: 10px" />
            </template>
            <template v-else>
              <b-alert variant="success" show>
                <h4 class="alert-heading">{{ $t('message.billing.billingPeriodIsFinished') }}</h4>
                <p>
                  {{ $t('message.billing.closedDate') }}:
                  <span class="font-weight-medium">{{ billingPeriod.finished_at }}</span>
                </p>
              </b-alert>
              <div id="billing-period-edit">
                <b-button
                  variant="warning"
                  size="lg"
                  block
                  :disabled="!hasUserRole([ROLE.OWNER, ROLE.MANAGER]) || !billingPeriod.is_last"
                  @click="openBillingPeriod"
                >
                  {{ $t('message.billing.reopenBillingPeriod') }}
                </b-button>
                <b-tooltip
                  v-if="!hasUserRole([ROLE.OWNER, ROLE.MANAGER])"
                  target="billing-period-edit"
                  triggers="hover"
                  placement="bottomleft"
                >
                  {{ $t('message.billing.reopenBillingPeriodInfo') }}
                </b-tooltip>
                <b-tooltip
                  v-else-if="!billingPeriod.is_last"
                  target="billing-period-edit"
                  triggers="hover"
                  placement="bottomleft"
                >
                  {{ $t('message.billing.reopenBillingPeriodLastInfo') }}
                </b-tooltip>
              </div>
            </template>
          </b-col>
        </div>
        <hr />
        <div class="row">
          <div class="col-12">
            <entries-table />
          </div>
        </div>
        <hr />
        <h5>{{ $t('message.billing.shifts') }}</h5>
        <div class="row">
          <div class="col-12">
            <shifts-table />
          </div>
        </div>
      </div>
    </div>
    <div v-if="closeBillingPeriodRequest === 'loading'" class="simple-loading">
      <i class="fas fa-sync-alt fa-spin"></i>
    </div>
  </div>
</template>

<script>
import store from './_store'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import TablePlaceholder from '@/components/TablePlaceholder'
import SummaryTable from './_components/SummaryTable'
import EntriesTable from './_components/EntriesTable'
import ShiftsTable from './_components/ShiftsTable'
import { responseErrorsHandler } from '@/mixins'
import { ROLE } from '@/common/constants'

const STORE_NAME = '_billingPeriodDetails'

export default {
  name: 'BillingPeriodDetails',
  components: {
    EntriesTable,
    SummaryTable,
    ShiftsTable,
    TablePlaceholder,
  },
  mixins: [responseErrorsHandler],
  data() {
    return {
      selectedFinishDate: null,
      ROLE,
    }
  },
  computed: {
    ...mapGetters(STORE_NAME, {
      billingPeriod: 'billingPeriod',
      billingPeriodRequest: 'billingPeriodRequest',
      closeBillingPeriodRequest: 'closeBillingPeriodRequest',
    }),
    ...mapGetters({
      employees: 'employees/employees',
      hasUserRole: 'auth/hasUserRole',
    }),
    emplyoeeName() {
      const { employee } = this.billingPeriod
      return employee ? employee.name : ''
    },
    billingPeriodTitle() {
      const { started_at } = this.billingPeriod
      return this.emplyoeeName ? `${this.emplyoeeName} - ${started_at}` : ''
    },
    finishedShiftsDates() {
      return this.billingPeriod.shifts_dates || []
    },
  },
  watch: {
    selectedFinishDate(newVal) {
      this.getBillingPeriod({
        loading: true,
        billingPeriodId: this.$route.params.id,
        shiftEndDate: newVal,
      })
    },
  },
  methods: {
    ...mapActions(STORE_NAME, {
      getBillingPeriod: 'getBillingPeriod',
      reopenBillingPeriod: 'reopenBillingPeriod',
    }),
    closeBillingPeriod() {
      const request = {
        billingPeriodId: this.$route.params.id,
        request: {
          shift_end_date: this.selectedFinishDate ? this.selectedFinishDate : null,
        },
        onSuccess: () => {
          this.clearResponseErrors({})
        },
        onError: (errors) => {
          this.setResponseErrors(errors)
        },
      }
      this.$store.dispatch(`${STORE_NAME}/closeBillingPeriod`, request)
    },
    openBillingPeriod() {
      // opens billing period so it's last shift can be corrected and period can be finished again
      this.reopenBillingPeriod({ billingPeriodId: this.billingPeriod.id })
    },
  },
  beforeCreate() {
    if (!this.$store.state[STORE_NAME]) this.$store.registerModule(STORE_NAME, store)
  },
  created() {
    this.$store.dispatch('employees/getEmployees')
    this.selectedEmployee = this.$route.params.employee_id
    this.getBillingPeriod({ loading: true, billingPeriodId: this.$route.params.id || this.billingPeriod.id })
  },
  beforeDestroy() {
    this.$store.unregisterModule(STORE_NAME)
  },
}
</script>

<style scoped lang="scss">
.filters {
  padding: 0 15px;
  align-items: center;
}
.employee-name {
  margin-right: 20px;
}
</style>

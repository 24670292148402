<template>
  <b-modal ref="modal" :title="$t('message.orderDetails.changeOrderLocalization')" :visible="visible" @hidden="hide">
    <b-alert show variant="info">{{ $t('message.orderDetails.changeOrderLocalizationDescription') }}</b-alert>
    <b-button
      v-for="(localization, localization_id) in companyLocalizations"
      v-if="localization_id != getLocalizationId"
      :key="localization_id"
      block
      size="lg"
      :variant="changeOrderLocalizationChoice === localization_id ? 'primary' : 'outline-primary'"
      @click="changeOrderLocalizationChoice = localization_id"
    >
      {{ localization }}
    </b-button>
    <template #modal-footer="{ ok }">
      <b-button variant="success" size="lg" block @click="changeOrderLocalization()">{{
        $t('message.orderDetails.transferOrder')
      }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

const STORE = '_orderDetails'

function initData() {
  return {
    visible: false,
    changeOrderLocalizationChoice: '',
  }
}

export default {
  name: 'ChangeOrderLocalizationModal',
  data() {
    return initData()
  },
  computed: {
    ...mapGetters(STORE, ['order']),
    ...mapGetters('auth', ['getLocalizationId', 'companyLocalizations']),
  },
  methods: {
    ...mapMutations(STORE, ['updateOrder']),
    ...mapActions({
      getOrders: '_orders/getOrders',
      transferOrder: `${STORE}/transferOrder`,
    }),
    show() {
      this.visible = true
    },
    hide() {
      let initdata = initData()
      this.visible = initdata.visible
      this.changeOrderLocalizationChoice = ''
    },
    changeOrderLocalization() {
      this.transferOrder({
        orderId: this.order.id,
        localizationId: parseInt(this.changeOrderLocalizationChoice),
        onSuccess: () => {
          this.$store.commit('_orders/removeOrderFromList', this.order.id)
          this.$emit('transferred')
        },
        disableRedirect: true,
      })
    },
  },
  mounted() {
    this.$eventBus.$on('onShowChangeOrderLocalizationModal', (payload = {}) => {
      this.show()
    })
  },
  destroyed() {
    this.$eventBus.$off('onShowChangeOrderLocalizationModal')
  },
}
</script>

import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { IAuthState } from '@/types/interface'

const getDefaultState = (): IAuthState => ({
  signInRequestStatus: 'idle',
  authToken: sessionStorage.getItem('authToken'), // FIXME: replace with getter
  name: '',
  localizations: [],
  localization: null,
  loggedIn: null,
  userRole: '',
  brands: null,
  menuSources: null,
  company: null,
  companyLocalizations: [],
  paymentBackend: null,
  integrations: [],
})

const resetState = (state: IAuthState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  mutations: {
    ...mutations,
    resetState,
  },
  actions,
}

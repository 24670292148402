<template>
  <b-modal
    :id="id"
    ref="modal"
    :title="$t('message.common.discardChangesQuestion')"
    header-class="d-block text-center"
    hide-header-close
    no-close-on-backdrop
    hide-footer
  >
    <b-row class="justify-content-around">
      <b-button variant="secondary" @click="stayOnPage">
        <kbd class="pb-0 pt-1 mr-2 bg-white text-dark">ESC</kbd>{{ $t('message.common.stayOnPage') }}
      </b-button>
      <b-button v-shortkey="['enter']" variant="danger" @shortkey="discardChanges" @click="discardChanges">
        <kbd class="pb-0 pt-1 mr-2 bg-white text-dark">&crarr;</kbd>{{ $t('message.common.discardChanges') }}
      </b-button>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: 'MealDiscardChangesModal',
  props: {
    id: {
      type: String,
      default: 'discardChangesModal',
    },
  },
  methods: {
    stayOnPage() {
      this.$emit('stay-on-page')
      this.hide()
    },
    discardChanges() {
      this.$emit('discard-changes')
      this.hide()
    },
    hide() {
      this.$bvModal.hide(this.id)
    },
  },
}
</script>

<template>
  <div class="view-container">
    <b-row>
      <b-col cols="12">
        <b-breadcrumb class="pl-4 mb-0">
          <b-breadcrumb-item :to="{ name: 'settings' }">
            {{ $t('message.settings.settings') }}
          </b-breadcrumb-item>
          <b-breadcrumb-item active>
            {{ $t('message.settings.ordersSummary') }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </b-col>
    </b-row>
    <div class="card">
      <div class="widget-body-wrapper">
        <b-row>
          <b-col cols="12" md="6" class="mb-3">
            <b-row>
              <b-col>
                <vue-ctk-date-time-picker
                  id="dateFiltersStartDatePicker"
                  ref="dateFiltersStartDatePicker"
                  v-model="dateFilters.startDate"
                  format="YYYY-MM-DD HH:mm"
                  formatted="YYYY-MM-DD HH:mm"
                  time-format="HH:mm"
                  :minute-interval="15"
                  no-button-now
                  no-clear-button
                  :label="$t('message.summary.filterFromDate')"
                  @is-hidden="updateEmployeesList()"
                ></vue-ctk-date-time-picker>
              </b-col>
              <b-col>
                <vue-ctk-date-time-picker
                  id="dateFiltersStartDatePicker2"
                  ref="dateFiltersStartDatePicker2"
                  v-model="dateFilters.endDate"
                  format="YYYY-MM-DD HH:mm"
                  formatted="YYYY-MM-DD HH:mm"
                  time-format="HH:mm"
                  :minute-interval="15"
                  no-button-now
                  no-clear-button
                  :label="$t('message.summary.filterToDate')"
                  @is-hidden="updateEmployeesList()"
                ></vue-ctk-date-time-picker>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <response-error-msg field="startDate" :response-errors="responseErrors" />
                <response-error-msg field="endDate" :response-errors="responseErrors" />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" md="2" class="mb-3">
            <b-select v-model="selectedEmployee" class="form-control h-42px">
              <b-select-option value="">{{ $t('message.summary.filterByEmployee') }}</b-select-option>
              <b-select-option v-for="employee in employees" :key="employee.id" :value="employee.id">{{
                employee.name
              }}</b-select-option>
            </b-select>
          </b-col>
          <b-col v-if="multipleRestaurants()" cols="6" md="2">
            <b-select v-model="selectedBrandId" class="form-control h-42px" @change="updateEmployeesList()">
              <b-select-option value="">{{ $t('message.ordersList.restaurants') }}</b-select-option>
              <b-select-option v-for="(brand, index) in multipleRestaurants()" :key="index" :value="index">
                {{ brand.name }} {{ brand.only_online ? '(online)' : '' }}
              </b-select-option>
            </b-select>
          </b-col>
          <b-col cols="12" md="2" class="mb-3 text-right">
            <b-button variant="outline-primary" @click="resetFilters">
              <i class="fas fa-times" /><span>{{ $t('message.common.reset') }}</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row v-if="!hasUserRole([ROLE.OWNER]) && showPastDaysOrdersAlert()" class="mt-1">
          <b-col>
            <b-alert show variant="warning">
              <span
                >{{
                  $tc('message.ordersList.pastDaysOrders', pastDaysOrders, {
                    past_days_orders: pastDaysOrders,
                  })
                }}
                <TooltipButton
                  id="past_days_info_button"
                  button-class="defaultTooltipButton"
                  placement="bottom"
                  class="tooltip__button"
                >
                  ?
                  <template #tooltipText>{{ $t('message.ordersList.pastDaysOrdersInfo') }}</template>
                </TooltipButton>
              </span>
            </b-alert>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-tabs content-class="mt-3">
              <b-tab :title="$t('message.summary.bills')" active>
                <div class="row mt-3">
                  <div class="col-12">
                    <div class="table-responsive bg-white">
                      <table class="table mb-0">
                        <thead>
                          <tr>
                            <th colspan="2" class="td--bl td--br td--sticky" />
                            <th colspan="4" class="text-center td--br">
                              {{ $t('message.summary.realizedBills') }}
                            </th>
                          </tr>
                          <tr>
                            <th scope="col" class="text-left td--bl td--sticky name-cell">
                              {{ $t('message.summary.name') }}
                            </th>
                            <th scope="col" class="td--br td--sticky-second">{{ $t('message.summary.role') }}</th>
                            <th scope="col" class="text-center">{{ $t('message.summary.ordersNumber') }}</th>
                            <th scope="col" class="text-center">
                              {{ $t('message.summary.paidByCash') }}
                            </th>
                            <th scope="col" class="text-center">{{ $t('message.summary.paidByCard') }}</th>
                            <th scope="col" class="text-center td--br">{{ $t('message.summary.alreadyPaid') }}</th>
                          </tr>
                        </thead>
                        <template v-if="employeesRequest === 'loading'">
                          <TablePlaceholder :row="10" :col="6" :loading="true" />
                        </template>
                        <template v-else>
                          <tbody>
                            <tr v-for="employee in employeesList" :key="employee.id">
                              <SummaryCell
                                extra-class="td--sticky name-cell"
                                :employee="employee"
                                :redirect="false"
                                :border-left="true"
                                quantity-field-name="name"
                              />
                              <td class="td--br td--sticky-second">
                                <span v-if="employee.role === 'courier'">{{ $t('message.employeeList.courier') }}</span>
                                <span v-if="employee.role === 'waiter'">{{ $t('message.employeeList.waiter') }}</span>
                              </td>
                              <SummaryCell
                                :employee="employee"
                                quantity-field-name="total_bills"
                                value-field-name="total_bills_value"
                                :redirect="false"
                              />
                              <SummaryCell
                                :employee="employee"
                                :extra-query="{ paymentMethod: 'cash' }"
                                quantity-field-name="bills_paid_by_cash"
                                value-field-name="bills_paid_by_cash_value"
                                :redirect="false"
                              />
                              <SummaryCell
                                :employee="employee"
                                :extra-query="{ paymentMethod: 'card' }"
                                quantity-field-name="bills_paid_by_card"
                                value-field-name="bills_paid_by_card_value"
                                :redirect="false"
                              />
                              <SummaryCell
                                :employee="employee"
                                quantity-field-name="bills_already_paid"
                                value-field-name="bills_already_paid_value"
                                class="summary-cell--br"
                                :redirect="false"
                              />
                            </tr>
                            <!--            SUMMARY-->
                            <tr>
                              <td colspan="2" class="td--bl td--br text-right td--sticky">
                                <strong>{{ $t('message.summary.total') }}</strong>
                              </td>
                              <SummaryCell
                                :quantity="sumOfTotalBills"
                                :value="sumOfTotalBillsValue"
                                :redirect="false"
                              />
                              <SummaryCell
                                :redirect="false"
                                :quantity="sumOfBillsPaidByCash"
                                :value="sumOfBillsPaidByCashValue"
                              />
                              <SummaryCell
                                :redirect="false"
                                :quantity="sumOfBillsPaidByCard"
                                :value="sumOfBillsPaidByCardValue"
                              />
                              <SummaryCell
                                :redirect="false"
                                :quantity="sumOfBillsAlreadyPaid"
                                :value="sumOfBillsAlreadyPaidValue"
                                class="summary-cell--br"
                              />
                            </tr>
                          </tbody>
                        </template>
                      </table>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab :title="$t('message.summary.orders')">
                <b-row
                  v-if="
                    hasUberEatsIntegration && isStartDateBeforeUberEatsIntegrationDate && sumOfUberDiscountOrders > 0
                  "
                  class="mt-2"
                >
                  <b-col>
                    <b-alert show variant="danger">
                      {{ $t('message.summary.uberDiscountStartDateWarning') }}
                    </b-alert>
                  </b-col>
                </b-row>
                <b-row v-if="!hasUserRole([ROLE.OWNER]) && showPastDaysOrdersAlert()" class="mt-2">
                  <b-col>
                    <b-alert show variant="warning">
                      <span
                        >{{
                          $tc('message.ordersList.pastDaysOrders', pastDaysOrders, {
                            past_days_orders: pastDaysOrders,
                          })
                        }}
                        <TooltipButton
                          id="past_days_info_button"
                          button-class="defaultTooltipButton"
                          placement="bottom"
                        >
                          ?
                          <template #tooltipText>{{ $t('message.ordersList.pastDaysOrdersInfo') }}</template>
                        </TooltipButton>
                      </span>
                    </b-alert>
                  </b-col>
                </b-row>
                <div class="row mt-3">
                  <div class="col-12">
                    <div class="table-responsive bg-white">
                      <table class="table mb-0">
                        <thead>
                          <tr>
                            <th colspan="2" class="td--bl td--br td--sticky" />
                            <th :colspan="$hasModuleAccess('payments-tips') ? 8 : 7" class="text-center td--br">
                              {{ $t('message.summary.realizedOrders') }}
                            </th>
                            <th colspan="2" class="text-center td--br">
                              {{ $t('message.summary.unrealized') }}
                            </th>
                            <th
                              v-if="hasUberEatsIntegration && sumOfUberDiscountOrders > 0"
                              class="text-center td--br"
                            ></th>
                          </tr>
                          <tr>
                            <th scope="col" class="text-center td--bl td--sticky name-cell">
                              {{ $t('message.summary.name') }}
                            </th>
                            <th scope="col" class="td--br td--sticky-second">{{ $t('message.summary.role') }}</th>
                            <th scope="col" class="text-center">{{ $t('message.summary.ordersNumber') }}</th>
                            <th scope="col" class="text-center">
                              {{ $t('message.summary.paidByCash') }}
                            </th>
                            <th scope="col" class="text-center">{{ $t('message.summary.paidByCard') }}</th>
                            <th scope="col" class="text-center">{{ $t('message.summary.alreadyPaid') }}</th>
                            <th scope="col" class="text-center">{{ $t('message.summary.withSplitPayments') }}</th>
                            <th scope="col" class="text-center">{{ $t('message.summary.discounts') }}</th>

                            <th scope="col" :class="['text-center', { 'td--br': !$hasModuleAccess('payments-tips') }]">
                              {{ $t('message.summary.deliveries') }}
                            </th>
                            <th v-if="$hasModuleAccess('payments-tips')" scope="col" class="text-center td--br">
                              {{ $t('message.summary.tips') }}
                            </th>
                            <th scope="col" class="text-center">{{ $t('message.summary.inProgress') }}</th>
                            <th scope="col" class="text-center td--br">{{ $t('message.summary.canceled') }}</th>
                            <th
                              v-if="hasUberEatsIntegration && sumOfUberDiscountOrders > 0"
                              scope="col"
                              class="text-center td--br"
                              style="max-width: 140px"
                            >
                              <span>
                                {{ $t('message.summary.uberDiscount') }}
                                <TooltipButton
                                  id="uber-discount-th-text"
                                  button-class="smallTooltipButtonWithText"
                                  placement="bottom"
                                >
                                  ?
                                  <template #tooltipText>{{ $t('message.summary.uberDiscountTooltip') }}</template>
                                </TooltipButton>
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <template v-if="employeesRequest === 'loading'">
                          <TablePlaceholder
                            :row="10"
                            :col="hasUberEatsIntegration && sumOfUberDiscountOrders > 0 ? 13 : 12"
                            :loading="true"
                          />
                        </template>
                        <template v-else>
                          <tbody>
                            <tr v-for="employee in employeesList" :key="employee.id">
                              <SummaryCell
                                extra-class="td--sticky name-cell"
                                :employee="employee"
                                :default-query="summaryCellDefaultQuery"
                                :border-left="true"
                                quantity-field-name="name"
                              />
                              <td class="td--br td--sticky-second">
                                <span v-if="employee.role === 'courier'">{{ $t('message.employeeList.courier') }}</span>
                                <span v-if="employee.role === 'waiter'">{{ $t('message.employeeList.waiter') }}</span>
                              </td>
                              <SummaryCell
                                :employee="employee"
                                :default-query="summaryCellDefaultQuery"
                                quantity-field-name="total_orders"
                                value-field-name="total_orders_value"
                              />
                              <SummaryCell
                                :employee="employee"
                                :default-query="summaryCellDefaultQuery"
                                :extra-query="{ paymentMethod: 'cash' }"
                                quantity-field-name="orders_paid_by_cash"
                                value-field-name="orders_paid_by_cash_value"
                              />
                              <SummaryCell
                                :employee="employee"
                                :default-query="summaryCellDefaultQuery"
                                :extra-query="{ paymentMethod: 'card' }"
                                quantity-field-name="orders_paid_by_card"
                                value-field-name="orders_paid_by_card_value"
                              />
                              <SummaryCell
                                :employee="employee"
                                :default-query="summaryCellDefaultQuery"
                                :extra-query="{ paymentMethod: 'paid' }"
                                quantity-field-name="orders_already_paid"
                                value-field-name="orders_already_paid_value"
                              />
                              <SummaryCell
                                :employee="employee"
                                :default-query="summaryCellDefaultQuery"
                                :extra-query="{ isSplitted: true }"
                                quantity-field-name="orders_paid_splitted"
                                value-field-name="orders_paid_splitted_value"
                              />
                              <SummaryCell
                                :employee="employee"
                                :default-query="summaryCellDefaultQuery"
                                :extra-query="{ discount: 'true' }"
                                :hide-if-no-employee-id="true"
                                quantity-field-name="discounts"
                                value-field-name="discounts_value"
                              />
                              <SummaryCell
                                :employee="employee"
                                :default-query="summaryCellDefaultQuery"
                                :hide-if-no-employee-id="true"
                                :border-right="!$hasModuleAccess('payments-tips')"
                                quantity-field-name="total_orders"
                                value-field-name="orders_with_delivery_value"
                              />
                              <SummaryCell
                                v-if="$hasModuleAccess('payments-tips')"
                                :employee="employee"
                                :default-query="summaryCellDefaultQuery"
                                :extra-query="{ tips: 'true' }"
                                :hide-if-no-employee-id="true"
                                border-right
                                quantity-field-name="tips"
                                value-field-name="tips_value"
                              />
                              <td class="summary-cell">
                                {{ employee.current_orders }} ({{ priceDecimal(employee.current_orders_value) }}
                                {{ currency }})
                              </td>
                              <SummaryCell
                                :employee="employee"
                                :default-query="summaryCellDefaultQuery"
                                :extra-query="{ finishedOrderChoices: ['canceled', 'not delivered'] }"
                                :hide-if-no-employee-id="true"
                                quantity-field-name="unrealized_orders"
                                value-field-name="unrealized_orders_value"
                                :border-right="true"
                              />
                              <td
                                v-if="hasUberEatsIntegration && sumOfUberDiscountOrders > 0"
                                class="summary-cell summary-cell--br"
                              >
                                {{ employee.uber_discounts }} ({{ priceDecimal(employee.uber_discounts_value) }}
                                {{ currency }})
                              </td>
                            </tr>
                            <!--            SUMMARY-->
                            <tr>
                              <td colspan="2" class="td--bl td--br text-right td--sticky">
                                <strong>{{ $t('message.summary.total') }}</strong>
                              </td>
                              <SummaryCell
                                :default-query="summaryCellDefaultQuery"
                                :quantity="sumOfTotalOrders"
                                :value="sumOfTotalOrdersValue"
                              />
                              <SummaryCell
                                :default-query="summaryCellDefaultQuery"
                                :extra-query="{ paymentMethod: 'cash' }"
                                :quantity="sumOfOrdersPaidByCash"
                                :value="sumOfOrdersPaidByCashValue"
                              />
                              <SummaryCell
                                :default-query="summaryCellDefaultQuery"
                                :extra-query="{ paymentMethod: 'card' }"
                                :quantity="sumOfOrdersPaidByCard"
                                :value="sumOfOrdersPaidByCardValue"
                              />
                              <SummaryCell
                                :default-query="summaryCellDefaultQuery"
                                :extra-query="{ paymentMethod: 'paid' }"
                                :quantity="sumOfOrdersAlreadyPaid"
                                :value="sumOfOrdersAlreadyPaidValue"
                              />
                              <SummaryCell
                                :default-query="summaryCellDefaultQuery"
                                :extra-query="{
                                  isSplitted: true,
                                }"
                                :quantity="sumOfOrdersAlreadySplitted"
                                :value="sumOfOrdersAlreadySplittedValue"
                              />
                              <SummaryCell
                                :default-query="summaryCellDefaultQuery"
                                :extra-query="{ discount: 'true' }"
                                :quantity="sumOfDiscounts"
                                :value="sumOfDiscountsValue"
                              />
                              <SummaryCell
                                :default-query="summaryCellDefaultQuery"
                                :quantity="sumOfTotalOrders"
                                :value="sumOfDeliveryValue"
                                :border-right="!$hasModuleAccess('payments-tips')"
                              />
                              <SummaryCell
                                v-if="$hasModuleAccess('payments-tips')"
                                :default-query="summaryCellDefaultQuery"
                                :extra-query="{ tips: 'true' }"
                                :quantity="sumOfTips"
                                :value="sumOfTipsValue"
                                border-right
                              />
                              <td class="text-center">
                                {{ sumOfCurrentOrders }} ({{ priceDecimal(sumOfCurrentOrdersValue) }} {{ currency }})
                              </td>
                              <SummaryCell
                                :default-query="summaryCellDefaultQuery"
                                :extra-query="{ finishedOrderChoices: ['canceled', 'not delivered'] }"
                                :quantity="sumOfUnrealizedOrders"
                                :value="sumOfUnrealizedOrdersValue"
                                :border-right="true"
                              />

                              <td
                                v-if="hasUberEatsIntegration && sumOfUberDiscountOrders > 0"
                                class="summary-cell summary-cell--br"
                              >
                                {{ sumOfUberDiscountOrders }} ({{ priceDecimal(sumOfUberDiscountValue) }}
                                {{ currency }})
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </table>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { dateFiltersFromTimeFilters, responseErrorsHandler } from '@/mixins'
import { COMMON_DATETIME_INPUT_FORMAT, getEndDateToday, getStartDateToday, ROLE } from '@/common/constants'
import store from './_store'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import TablePlaceholder from '@/components/TablePlaceholder'
import TooltipButton from '@/components/TooltipButton'
import SummaryCell from './_components/SummaryCell'

const STORE_NAME = '_summary'

export default {
  name: 'Index',
  components: { VueCtkDateTimePicker, TablePlaceholder, TooltipButton, SummaryCell },
  mixins: [dateFiltersFromTimeFilters, responseErrorsHandler],
  beforeRouteLeave(to, from, next) {
    if (
      to.name !== 'orders-archive' &&
      to.name !== 'orders-summary' &&
      to.name !== 'settings' &&
      localStorage.getItem('dateFilters')
    )
      localStorage.removeItem('dateFilters')
    next()
  },
  data: function () {
    return {
      dateFilters: {
        startDate: this.getTodayTimeFilter('startTime'),
        endDate: this.getTodayTimeFilter('endTime'),
      },
      selectedEmployee: '',
      selectedBrandId: '',
      isMainInfoVisible: true,
      isBillsInfoVisible: true,
      isOrdersInfoVisible: true,
      ROLE,
    }
  },
  computed: {
    ...mapState({
      employees: (state) => state._summary.employees,
    }),
    ...mapGetters(STORE_NAME, {
      employeesRequest: 'employeesRequest',
    }),
    ...mapGetters({
      brands: 'auth/brands',
      hasUserRole: 'auth/hasUserRole',
      hasUberEatsIntegration: 'integrations/hasUberEatsIntegration',
      pastDaysOrders: 'settings/permissionsPastDaysOrders',
    }),
    ...mapGetters(['currency']),
    employeesList: function () {
      return this.selectedEmployee === ''
        ? this.employees
        : this.employees.filter((employee) => employee.id === this.selectedEmployee)
    },
    sumOfOrdersPaidByCash() {
      return this.employeesList.reduce((acc, employee) => acc + employee.orders_paid_by_cash, 0)
    },
    sumOfOrdersPaidByCashValue() {
      return this.employeesList
        .reduce((acc, employee) => acc + parseFloat(employee.orders_paid_by_cash_value), 0)
        .toFixed(2)
    },
    sumOfOrdersPaidByCard() {
      return this.employeesList.reduce((acc, employee) => acc + employee.orders_paid_by_card, 0)
    },
    sumOfOrdersPaidByCardValue() {
      return this.employeesList
        .reduce((acc, employee) => acc + parseFloat(employee.orders_paid_by_card_value), 0)
        .toFixed(2)
    },
    sumOfOrdersAlreadyPaid() {
      return this.employeesList.reduce((acc, employee) => acc + employee.orders_already_paid, 0)
    },
    sumOfOrdersAlreadyPaidValue() {
      return this.employeesList
        .reduce((acc, employee) => acc + parseFloat(employee.orders_already_paid_value), 0)
        .toFixed(2)
    },
    sumOfOrdersAlreadySplitted() {
      return this.employeesList.reduce((acc, employee) => acc + employee.orders_paid_splitted, 0)
    },
    sumOfOrdersAlreadySplittedValue() {
      return this.employeesList
        .reduce((acc, employee) => acc + parseFloat(employee.orders_paid_splitted_value), 0)
        .toFixed(2)
    },
    sumOfTotalOrders() {
      return this.employeesList.reduce((acc, employee) => acc + employee.total_orders, 0)
    },
    sumOfTotalOrdersValue() {
      return this.employeesList.reduce((acc, employee) => acc + parseFloat(employee.total_orders_value), 0).toFixed(2)
    },
    sumOfDiscounts() {
      return this.employeesList.reduce((acc, employee) => acc + employee.discounts, 0)
    },
    sumOfDiscountsValue() {
      return this.employeesList.reduce((acc, employee) => acc + parseFloat(employee.discounts_value), 0).toFixed(2)
    },
    sumOfTips() {
      return this.employeesList.reduce((acc, employee) => acc + employee.tips, 0)
    },
    sumOfTipsValue() {
      return this.employeesList.reduce((acc, employee) => acc + parseFloat(employee.tips_value), 0).toFixed(2)
    },
    sumOfDeliveryValue() {
      return this.employeesList
        .reduce((acc, employee) => acc + parseFloat(employee.orders_with_delivery_value), 0)
        .toFixed(2)
    },
    sumOfUnrealizedOrders() {
      return this.employeesList.reduce((acc, employee) => acc + employee.unrealized_orders, 0)
    },
    sumOfUnrealizedOrdersValue() {
      return this.employeesList
        .reduce((acc, employee) => acc + parseFloat(employee.unrealized_orders_value), 0)
        .toFixed(2)
    },
    sumOfUberDiscountOrders() {
      return this.employeesList.reduce((acc, employee) => acc + employee.uber_discounts, 0)
    },
    sumOfUberDiscountValue() {
      return this.employeesList.reduce((acc, employee) => acc + parseFloat(employee.uber_discounts_value), 0).toFixed(2)
    },
    sumOfCurrentOrders() {
      return this.employeesList.reduce((acc, employee) => acc + employee.current_orders, 0)
    },
    sumOfCurrentOrdersValue() {
      return this.employeesList.reduce((acc, employee) => acc + parseFloat(employee.current_orders_value), 0).toFixed(2)
    },
    isStartDateBeforeUberEatsIntegrationDate() {
      return this.$moment(this.dateFilters.startDate, COMMON_DATETIME_INPUT_FORMAT).isBefore(
        this.$moment('2021-05-06T00:00', COMMON_DATETIME_INPUT_FORMAT)
      )
    },
    summaryCellDefaultQuery() {
      return {
        startDate: this.dateFilters.startDate,
        endDate: this.dateFilters.endDate,
        brand: this.selectedBrandId,
        finishedOrderChoices: ['delivered', 'finished'],
      }
    },
    sumOfTotalBills() {
      return this.employeesList.reduce((acc, employee) => acc + employee.total_bills, 0)
    },
    sumOfTotalBillsValue() {
      return this.employeesList.reduce((acc, employee) => acc + parseFloat(employee.total_bills_value), 0).toFixed(2)
    },
    sumOfBillsPaidByCash() {
      return this.employeesList.reduce((acc, employee) => acc + employee.bills_paid_by_cash, 0)
    },
    sumOfBillsPaidByCashValue() {
      return this.employeesList
        .reduce((acc, employee) => acc + parseFloat(employee.bills_paid_by_cash_value), 0)
        .toFixed(2)
    },
    sumOfBillsPaidByCard() {
      return this.employeesList.reduce((acc, employee) => acc + employee.bills_paid_by_card, 0)
    },
    sumOfBillsPaidByCardValue() {
      return this.employeesList
        .reduce((acc, employee) => acc + parseFloat(employee.bills_paid_by_card_value), 0)
        .toFixed(2)
    },
    sumOfBillsAlreadyPaid() {
      return this.employeesList.reduce((acc, employee) => acc + employee.bills_already_paid, 0)
    },
    sumOfBillsAlreadyPaidValue() {
      return this.employeesList
        .reduce((acc, employee) => acc + parseFloat(employee.bills_already_paid_value), 0)
        .toFixed(2)
    },
  },
  beforeCreate() {
    if (!this.$store.state[STORE_NAME]) this.$store.registerModule(STORE_NAME, store)
  },
  created() {
    this.getFiltersFromLocalStorage()
    this._getEmployeesSummary()
  },
  beforeDestroy() {
    this.$store.unregisterModule(STORE_NAME)
  },
  methods: {
    ...mapActions({
      getEmployeesSummary: '_summary/getEmployeesSummary',
    }),
    updateEmployeesList() {
      if (this.dateFilters.startDate !== '' && this.dateFilters.endDate !== '') {
        this._getEmployeesSummary()
        this.saveDateFilters()
      }
    },
    resetFilters() {
      this.dateFilters = {
        startDate: getStartDateToday(),
        endDate: getEndDateToday(),
      }
      this.selectedEmployee = ''
      this.selectedBrandId = ''
      this._getEmployeesSummary()
    },
    multipleRestaurants() {
      return this.brands && Object.values(this.brands).length > 1 ? this.brands : false
    },
    _getEmployeesSummary() {
      this.clearResponseErrors()
      this.getEmployeesSummary({
        params: {
          ...this.dateFilters,
          brand: this.selectedBrandId,
        },
        onError: (errors) => {
          this.setResponseErrors(errors)
        },
      })
    },
    showPastDaysOrdersAlert() {
      return this.pastDaysOrders > 0 && moment().diff(moment(this.dateFilters.startDate), 'days') > this.pastDaysOrders
    },
  },
}
</script>

<style scoped lang="scss">
table {
  border-collapse: separate;
  border-spacing: 0;

  tbody {
    tr:last-child {
      td {
        border-bottom: 1px solid #dee2e6;
      }
    }
  }
}
.h-42px {
  height: 42px;
}
.td {
  &--bl {
    border-left: 1px solid #dee2e6;
  }
  &--br {
    border-right: 1px solid #dee2e6;
  }
}
.name-cell {
  text-align: left;
  min-width: 150px;
  white-space: normal;
}
@include media-breakpoint-up(md) {
  .td--sticky,
  .td--sticky-second {
    position: sticky;
    left: 0;
    z-index: 2;
    background-color: #fff;
  }
  .td--sticky-second {
    left: 150px;
    box-shadow: 5px 0 6px rgba(21, 21, 21, 0.024);
  }
}

.btn__close {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  transition: opacity 0.3s;

  &:hover,
  &:focus {
    box-shadow: none;
    opacity: 1;
  }
}
.summary__info {
  position: relative;

  .lead {
    white-space: pre-line;
    margin-bottom: 0;
    font-size: 15px;
  }

  &::before {
    content: '\f05a';
    font-family: 'Font Awesome 5 Free';
    position: absolute;
    bottom: 0;
    right: 10px;
    font-weight: 900;
    opacity: 0.1;
    font-size: 50px;
  }
}
</style>

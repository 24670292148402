<template>
  <div class="sizes">
    <div class="sizes-body">
      <button
        v-for="size in categorySizes"
        :id="'size-btn_' + size.id"
        :key="size.id"
        type="button"
        class="size py-3"
        :class="{ active: isActive({ type: 'size', data: size }) }"
        @click="setActiveCategorySizeId(size.id)"
      >
        {{ getSizeName(size.name) }}
      </button>
    </div>
  </div>
</template>

<script>
import { sizeUtilsMixins } from '@/mixins/sizeUtils.mixins'
export default {
  name: 'Sizes',
  mixins: [sizeUtilsMixins],
  props: ['categorySizes', 'isActive', 'setActiveCategorySizeId'],
}
</script>

<style lang="scss" scoped>
.sizes {
  padding: 5px 0;
  &-header {
    margin-bottom: 10px;
    &__title {
      color: #666;
      font-size: 12px;
    }
  }

  &-body {
    display: flex;
  }
}

.size {
  background-color: $yellow-light;
  border: 1px solid rgba(0, 0, 0, 0.06);
  width: 120px;
  margin: 0 0px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  font-weight: 500;
  font-size: 16px;
  transition: all $time-standard ease;
  -webkit-appearance: none !important;
  border-right: 0;
  min-width: 80px;
  flex-grow: 1;

  &:not(:last-child) {
    border-right: 0;
  }

  &:first-child {
    margin-left: 0;
    border-radius: 3px 0 0 3px;
  }
  &:last-child {
    margin-right: 0;
    border-radius: 0 3px 3px 0;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0px 4px 8px rgba(45, 45, 45, 0.05);
  }

  &.active {
    background-color: $primary;
    color: #fff;
  }
}
</style>

<template>
  <b-modal
    ref="modal"
    :title="$t('message.navMain.confirmMealChangeLoss')"
    :visible="visible"
    hide-header-close
    no-close-on-backdrop
    hide-footer
    @hidden="discardChanges"
  >
    <b-row class="justify-content-around">
      <b-button variant="danger" @click="discardChanges()">
        <kbd class="pb-0 pt-1 mr-2 bg-white text-dark">ESC</kbd>{{ $t('message.navMain.discardChanges') }}
      </b-button>
      <b-button variant="success" @click="saveChanges()">
        <kbd class="pb-0 pt-1 mr-2 bg-white text-dark">&crarr;</kbd>{{ $t('message.navMain.saveChanges') }}
      </b-button>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: 'MealDiscardChangesModal',
  data() {
    return {
      visible: false,
      discard: true,
    }
  },
  mounted() {
    // shows this modal on every try to run away from MealDetails view if meal was changed and unsaved
    this.$eventBus.$on('onShowMealDiscardChangesModal', () => {
      this.show()
    })
  },
  methods: {
    show() {
      this.visible = true
    },
    saveChanges() {
      // emits saveMeal to MealDetails
      this.discard = false
      this.visible = false
      this.$emit('saveMeal')
    },
    discardChanges() {
      // emits discardChanges to MealDetails
      this.visible = false
      this.$emit('discardChanges')
    },
  },
  // destroyed () { // TODO FIX?
  //   this.$eventBus.$off('onShowMealDiscardChangesModal')
  // }
}
</script>

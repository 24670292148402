<template>
  <div>
    <b-form-radio-group
      v-model="_value"
      :checked="_value"
      :disabled="disabled"
      buttons
      button-variant="outline-primary"
      size="lg"
    >
      <b-form-radio v-for="option in deliveryTypes" :key="option.value" :value="option.value">
        <span class="mr-2 delivery-type__icon">
          <i v-if="option.value === DELIVERY_TYPES.ASAP" class="fas fa-shipping-fast" />
          <i v-else-if="option.value === DELIVERY_TYPES.ONTIME" class="far fa-clock" />
        </span>
        <span>{{ option.name }}</span>
      </b-form-radio>
    </b-form-radio-group>
  </div>
</template>

<script>
import { DELIVERY_TYPES } from '@/common/constants'

export default {
  name: 'DeliveryType',
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      DELIVERY_TYPES,
      deliveryTypes: [
        { name: this.$t('message.orderFormFull.deliveryTypes.asap'), value: DELIVERY_TYPES.ASAP },
        { name: this.$t('message.orderFormFull.deliveryTypes.onTime'), value: DELIVERY_TYPES.ONTIME },
      ],
    }
  },
  computed: {
    _value: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.delivery-type {
  &__icon {
    display: none;
    @include media-breakpoint-up(sm) {
      display: inline;
    }
  }
}
</style>

<template>
  <div class="recommendation-messages-container">

    <transition name="slide-in">
      <div v-if="recommendation" class="recommendation-message">
        <div class="recommendation-message__icon">
          <i class="fas fa-star"/>
        </div>
        
        <div class="recommendation-message-content">
          <div class="recommendation-message__title">{{$t('newRecommendation')}}</div>
          
          <p class="recommendation-message__message">{{message}}</p>
          
          <div class="recommendation-message-action">
            <template v-if="recommendation.action === RECOMMENDATION_ACTIONS.MAKE_PHASE_3">
              <div class="recommendation-message-action__box">{{ orderNumber }}</div>
            
              <div class="recommendation-message-action__icon"><i class="fas fa-user-plus"/></div>
              
              <div class="recommendation-message-action__box">{{ courierName }}</div>
            </template>

            <template v-else-if="recommendation.action === RECOMMENDATION_ACTIONS.COURIER_SEND">
              <div class="recommendation-message-action__box">{{ courierName }}</div>
            </template>
            
          </div>
        </div>

        <div class="recommendation-message__close">
          <b-button size="sm" variant="danger" @click="removeRecommendation()">
            <i class="fas fa-times"/>          
          </b-button>
          
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { RECOMMENDATION_ACTIONS } from '@/common/constants'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'RecommendationMessages',
  data() {
    return {
      RECOMMENDATION_ACTIONS
    }
  },
  computed: {
    ...mapGetters({
      recommendation: 'recommendations/recommendation',
    }),
    message(){
      const { action } = this.recommendation || {}
      if (!action) return ''
      if (action === RECOMMENDATION_ACTIONS.MAKE_PHASE_3) return this.$t('assignCourierToOrder')
      else if (action === RECOMMENDATION_ACTIONS.COURIER_SEND) return this.$t('sendCourierWithOrders')
    },
    orderNumber(){
      const { order_id } = this.recommendation || {}
      if (!order_id) return ''
      
      const order = this.$store.getters['_orders/orders'].find(order => order.id === order_id)
      
      if (!order) return ''

      return order.number
    },
    courierName() {
      const { courier_id } = this.recommendation || {}
      if (!courier_id) return ''
      
      const courier = this.$store.getters['_orders/couriers'].find(courier => courier.user_id === courier_id)
      
      if (!courier) return ''

      return courier.name
    }
  },
  watch: {
  },
  methods: {
    ...mapMutations({
      removeRecommendation: 'recommendations/removeRecommendation',
    })
  },
}

</script>
<style lang="scss">
.recommendation-messages{
  &-container {
    z-index:1001;
    position: absolute;
    left:20px;
    bottom:20px;
  }
}

.recommendation-message{
  display:flex;
  background: #fff;
  padding:1rem;
  padding-left:0.5rem;
  border-left: 10px solid #8500EE;
  border-radius: 4px;
  box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), -10px 0 9px -6px #8500ee9c;

  &__title{
    font-size:16px;
    font-weight:600;
  }

  &__icon{
    color: #8500EE;
    margin-right:0.5rem;
  }

  &__close{
    color: #ccc;
  }

  &-action{
    display:flex;
    align-items:center;
    justify-content: space-between;
    margin-top:0.5rem;

    &__box{
      border:1px solid #ccc;
      padding:0.2rem 0.5rem;
      border-radius: 2px;
      font-size:12px;
      font-weight:600;
    }

    &__icon{
      margin: 0 10px;
      color: #989898;
    }
  }
}

.slide-in-enter-active,
.slide-in-leave-active {
  transition: transform 0.5s;
}
.slide-in-enter, .slide-in-leave-to /* .slide-in-leave-active in <2.1.8 */ {
  transform: translateX(-140%);
}
</style>
import moment from 'moment-timezone'
import { POS_API_DATETIME_INPUT_FORMAT } from '@/common/constants'
import decimal from 'decimal.js'

const setOrder = (state, order) => {
  order.order_customer = order.order_customer || {
    name: '',
    phone: '',
    address: '',
  }
  state.order = order
}
const updateOrder = (state, updatedOrderData) => {
  state.order = {
    ...state.order,
    ...updatedOrderData,
  }
}

const updateCart = (state, updatedCartData) => {
  state.cart.totalPrice = updatedCartData.price
}

const setCart = (state, data) => {
  state.cart = {
    customer: {
      id: data.customer_id,
      pickupType: data.pickup_type,
      promisedTime: data.promised_time ? data.promised_time.split(' ').pop() : 0,
      paymentType: data.payment,
      deliveryType: data.delivery_type,
    },
    meals: data.meals,
    totalPrice: data.price,
    fullPrice: data.full_price,
    comment: data.comment,
    deliveryPrice: data.delivery_price,
    discount_choice: data.discount_choice,
    discount_value: data.discount_value,
    calculated_discount_value: data.calculated_discount_value,
    serviceChargeValue: data.service_charge_value,
    serviceChargePercent: data.service_charge_percent,
    glovoServiceCharge: data.glovo_service_charge_value,
    packagePrice: data.package_price,
    edit: true,
    id: data.id,
    isInvoiced: data.invoice_id,
    menuId: data.menu_id,
  }
}

const setCustomer = (state, data) => {
  state.cart.customer.companyName = data.company_name
  state.cart.customer.streetAddress = data.street_address
  state.cart.customer.postalCode = data.postal_code
  state.cart.customer.city = data.city
  state.cart.customer.taxIDNumber = data.tax_id
}

const setOrderId = (state, orderId) => {
  state.orderId = orderId
}

const setPaymentLoading = (state, loading) => {
  state.paymentLoading = loading
}

const setTableLoading = (state, loading) => {
  state.tableLoading = loading
}

const setOrderRequestStatus = (state, status) => {
  state.orderRequestStatus = status
}

const setEstimatedDeliveryTime = (state, data) => {
  if (data?.predicted_promised_time) {
    const predictedTime = new decimal.Decimal(data.predicted_promised_time).toFixed(0)
    state.estimatedDeliveryTime = predictedTime
    state.cart.predictedPromisedTime = moment().add(predictedTime, 'minutes').format(POS_API_DATETIME_INPUT_FORMAT)
  }
}

const setInvoiceData = (
  state,
  {
    city,
    company_name,
    postal_code,
    street_address,
    tax_id,
    hide_details,
    hide_details_name,
    hide_details_vat,
    payment_type_name,
    payment_type,
  }
) => {
  const hasInvoice = state.order.invoice_id
  state.invoice.city = city || ''
  state.invoice.name = company_name || ''
  state.invoice.postalCode = postal_code || ''
  state.invoice.address = street_address || ''
  state.invoice.taxId = tax_id || ''
  state.invoice.hideNameTypeChosen = hide_details_vat && hide_details_name ? 'custom' : 'default'
  state.invoice.customPaymentType = payment_type || (hasInvoice ? null : state.order.payment[0] || null)
  if (payment_type_name) {
    state.invoice.paymentTypeName = payment_type_name
  }
  if (hide_details) {
    state.invoice.hideDetails = hide_details
  }
  if (hide_details_name) {
    state.invoice.hideNameChosen = hide_details_name
  }
  if (hide_details_vat) {
    state.invoice.hideDetailsVat = hide_details_vat
  }

  if ((payment_type && hasInvoice) || payment_type_name || !state.order.payment.length) {
    state.invoice.paymentTypeChosen = 'custom'
  }
}

const setBills = (state, items) => {
  state.allBills = items || []
  state.bills = (items && items.filter((item) => !item.main_bill)) || []
  state.baseBill = (items && items.find((item) => item.main_bill)) || null
}

export default {
  setOrder,
  setCustomer,
  updateOrder,
  setCart,
  setOrderId,
  setPaymentLoading,
  setTableLoading,
  setOrderRequestStatus,
  updateCart,
  setEstimatedDeliveryTime,
  setInvoiceData,
  setBills,
}

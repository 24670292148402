<template>
  <b-modal
    id="removeBillModal"
    ref="removeBillModal"
    class="modal-bills"
    :title="$t('message.splitBill.deleteBillModal.title')"
    centered
    @ok="handleOk"
  >
    <p class="modal-bills__text">
      {{ $t('message.splitBill.deleteBillModal.description') }}
    </p>
    <template #modal-footer="{ ok, cancel }">
      <b-button size="lg" variant="outline-dark" class="mr-3" @click="cancel()">
        {{ $t('message.common.cancel') }}
      </b-button>
      <b-overlay
        :show="deleting"
        rounded
        variant="danger"
        opacity="1"
        spinner-small
        spinner-variant="light"
        class="d-inline-block"
      >
        <b-button size="lg" variant="danger" :disabled="deleting" @click="ok()">
          {{ $t('message.common.delete') }}
        </b-button>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
const STORE = '_orderSplitBill'

export default {
  name: 'RemoveBillModal',
  data() {
    return {
      deleting: false,
    }
  },
  computed: {
    billId() {
      return parseInt(this.$route.params.billId)
    },
    id() {
      return parseInt(this.$route.params.id)
    },
    prevBillId() {
      return this.$store.getters[`${STORE}/prevBillId`](this.billId)
    },
    nextBillId() {
      return this.$store.getters[`${STORE}/nextBillId`](this.billId)
    },
  },
  methods: {
    async handleOk(bvModalEvt) {
      const prevBillId = this.prevBillId
      const nextBillId = this.nextBillId

      bvModalEvt.preventDefault()
      this.deleting = true
      try {
        await this.$store.dispatch(`${STORE}/deleteBill`, this.billId)
        this.$router.replace({
          name: 'orders-split-bill-edit',
          params: {
            id: this.id,
            billId: prevBillId || nextBillId,
          },
        })
      } catch ({ errorStatus, errorValue }) {
        const msg = errorStatus !== 500 && errorValue ? errorValue : this.$t('message.common.anErrorOccured')
        this.$toasted.error(msg, {
          duration: 5000,
        })
      }
      this.deleting = false
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/scss/components/split-bill';
</style>

<template>
  <tr :class="['cart-summary-row', borderTopClass]">
    <td :colspan="colspanLeft" :class="boldClass">{{ name }}</td>

    <td :class="boldClass">{{ getValue }}</td>
    <td colspan="100%" />
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CartSummaryRow',
  props: {
    name: {
      required: true,
      type: String,
    },
    value: {
      required: true,
    },
    colspanLeft: {
      default: 4,
      type: Number,
    },
    bold: {
      default: false,
      type: Boolean,
    },
    borderTop: {
      default: false,
      type: Boolean,
    },
    sufix: {
      default: false,
    },
  },
  computed: {
    ...mapGetters(['currency']),
    boldClass() {
      if (this.bold) return 'font-weight-semi-bold'
    },
    borderTopClass() {
      if (this.borderTop) return 'cart-summary-row--border-top'
    },
    getValue() {
      if (!this.value) return '-'
      else return `${this.value} ${this.sufix || this.currency}`
    },
  },
}
</script>

<style lang="scss" scoped>
.cart-summary-row {
  td {
    white-space: nowrap;
  }
  &--border-top {
    border-top: 2px solid #d7d9da;
    td {
      border-top: 2px solid #d7d9da;
    }
  }
}
</style>

<template>
  <div class="widget">
    <div class="widget-body-wrapper pl-0">
      <b-row>
        <b-col>
          <b-breadcrumb class="bg-white">
            <b-breadcrumb-item :to="{ name: 'settings' }">
              {{ $t('message.settings.settings') }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active :to="{ name: 'receipt-printers-list' }">
              {{ $t('message.receiptPrintersSettings.name') }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button variant="success" class="ml-2" @click="newPrinterModal.show = true"
            ><i class="fas fa-plus mr-2"></i>
            {{ $t('message.receiptPrintersSettings.newPrinter') }}
          </b-button>
        </b-col>
      </b-row>
      <b-modal
        v-model="newPrinterModal.show"
        :title="$t('message.receiptPrintersSettings.newPrinterDetails')"
        hide-footer
      >
        <b-form-group>
          <template #label
            >{{ $t('message.receiptPrintersSettings.printerName') }}
            <span class="text-danger mx-1">*</span>
          </template>
          <b-input-group>
            <b-form-input v-model="newPrinterModal.name"> </b-form-input>
            <template #append>
              <b-input-group-text id="nameTooltip">
                <i class="fas fa-question text-info"></i>
                <b-tooltip target="nameTooltip" triggers="hover">
                  {{ $t('message.receiptPrintersSettings.newPrinterNameTooltipText') }}
                </b-tooltip>
              </b-input-group-text>
            </template>
          </b-input-group>
        </b-form-group>
        <b-form-group>
          <template #label
            >{{ $t('message.receiptPrintersSettings.printerInternalId') }}
            <span class="text-danger mx-1">*</span>
          </template>
          <b-input-group>
            <b-form-input v-model="newPrinterModal.internalId"> </b-form-input>
            <template #append>
              <b-input-group-text id="internalIdTooltip">
                <i class="fas fa-question text-info"></i>
                <b-tooltip target="internalIdTooltip" triggers="hover">
                  {{ $t('message.receiptPrintersSettings.newPrinterInternalIdTooltipText') }}
                </b-tooltip>
              </b-input-group-text>
            </template>
          </b-input-group>
        </b-form-group>
        <div class="mt-3">
          <b-button variant="success" @click="createNewPrinter()">
            {{ $t('message.receiptPrintersSettings.create') }}
          </b-button>
          <b-button @click="newPrinterModal.show = false">
            {{ $t('message.receiptPrintersSettings.cancel') }}
          </b-button>
        </div>
      </b-modal>
      <div>
        <b-table striped class="text-left mt-3" :items="receiptPrinters" :fields="fields">
          <template #head(name)>{{ $t('message.receiptPrintersSettings.printerName') }}</template>
          <template #head(whitelist)>{{ $t('message.receiptPrintersSettings.templates') }}</template>
          <template #head(edit)>{{ $t('message.receiptPrintersSettings.edit') }}</template>
          <template #head(delete)>{{ $t('message.receiptPrintersSettings.delete') }}</template>
          <template #cell(name)="data">
            {{ data.item.name }}
          </template>
          <template #cell(whitelist)="data">
            {{ getReceiptTemplatesNames(data.item.receipt_templates) }}
          </template>
          <template #cell(edit)="data">
            <router-link
              :to="{ name: 'receipt-printer-edit', params: { printer: data.item } }"
              tag="button"
              class="btn btn-block btn-warning"
              ><i class="fas fa-edit"></i>
            </router-link>
          </template>
          <template #cell(delete)="data">
            <b-button variant="danger" class="btn-block" @click="showDeleteModal(data.item.id)">
              <i class="fas fa-trash"></i>
            </b-button>
          </template>
        </b-table>
        <b-modal
          v-model="deleteModal.show"
          hide-footer
          centered
          :title="$t('message.receiptPrintersSettings.deletePrinterModalTitle')"
        >
          <b-button class="mt-3" variant="danger" @click="deletePrinter()">{{
            $t('message.receiptPrintersSettings.delete')
          }}</b-button>
          <b-button class="mt-3" @click="deleteModal.show = false">{{
            $t('message.receiptPrintersSettings.cancel')
          }}</b-button>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from '@/services/api/api'

export default {
  name: 'ReceiptPrintersList',
  data() {
    return {
      fields: ['name', 'whitelist', 'edit', 'delete'],
      receiptPrinters: [],
      deleteModal: {
        show: false,
        id: null,
      },
      newPrinterModal: {
        show: false,
        name: '',
        internalId: '',
      },
    }
  },
  mounted() {
    this.getReceiptPrinters()
  },
  methods: {
    getReceiptTemplatesNames(receiptTemplates) {
      return receiptTemplates.map(({ name_display }) => name_display).join(', ')
    },
    showDeleteModal(id) {
      this.deleteModal.show = true
      this.deleteModal.id = id
    },
    async createNewPrinter() {
      const { error, data } = await API.createReceiptPrinter({
        name: this.newPrinterModal.name,
        internal_id: this.newPrinterModal.internalId,
      })
      if (!error && data) {
        this.$router.push({ name: 'receipt-printer-edit', params: { printer: data } })
      }
    },
    async deletePrinter() {
      let id = this.deleteModal.id
      this.deleteModal.show = false
      let removeIndex = this.receiptPrinters.map((item) => item.id).indexOf(id)
      const { error } = await API.deleteReceiptPrinter(id)
      if (!error) {
        this.receiptPrinters.splice(removeIndex, 1)
        this.getReceiptPrinters()
      }
    },
    async getReceiptPrinters() {
      const { error, data } = await API.getReceiptPrinters()
      if (!error && data) {
        this.receiptPrinters = data
      }
    },
  },
}
</script>

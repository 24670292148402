export const integrationsMixins = {
  methods: {
    showConfirmation(message) {
      this.$toasted.show(message, {
        type: 'success',
        icon: {
          name: 'check',
        },
      })
    },
    showMenuConfirmation() {
      this.$toasted.show(this.$t('message.integrations.updateMenuConfirmation'), {
        type: 'success',
        icon: {
          name: 'check',
        },
        action: [
          {
            text: this.$t('message.navMain.logout'),
            onClick: () => {
              this.logout()
            },
          },
          {
            text: this.$t('message.common.close'),
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            },
          },
        ],
      })
    },
    showFailure(message) {
      this.$toasted.error(message, {
        duration: 3000,
      })
    },
  },
}

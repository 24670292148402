import { DISCOUNT_CHOICES, MEALSET_TYPES } from '@/common/constants'
import { commonMixins } from './commonMixins'
import { posErestoMealSetMixins } from './posErestoMealSetMixins'

export const mealSetsMixins = {
  mixins: [commonMixins, posErestoMealSetMixins],
  data() {
    return {}
  },
  methods: {
    getParsedDiscount(item) {
      // returns parsed discount value based on discount_value and discount_type of MealSetItem
      if (!item.discount_type || !item.discount_value) return '-'
      if (item.discount_type === DISCOUNT_CHOICES.DISCOUNT_CHOICE_PERCENT) return `- ${item.discount_value} %`
      else if (item.discount_type === DISCOUNT_CHOICES.DISCOUNT_CHOICE_VALUE) {
        return `- ${item.discount_value} ${this.$store.getters['currency']}`
      } else return `Cena promocyjna ${item.discount_value} ${this.currency}`
    },
    getMealSetItemSizeNames(item) {
      let sizeNames = item.sizes.map((size) => {
        let name = size.name
        if (name === 'default') name = ''
        return name
      })
      return sizeNames.join(', ')
    },
    getMealSetItemMealsNames(item) {
      let mealNames = item.meals.map((meal) => meal.name)
      mealNames = _.uniq(mealNames)
      return mealNames.join(', ')
    },
    assignDiscount(mealSet, discountObject = null) {
      // for MealSet or MealSetItem
      // discountObject - an object with discount_type and discount_value
      if (!discountObject) {
        discountObject = mealSet
      }
      const discountType = discountObject.discount_type
      const discountValue = discountObject.discount_value
      mealSet.discount = ''
      mealSet.promotionalPrice = ''
      mealSet.discount_value = ''
      if (discountType === DISCOUNT_CHOICES.DISCOUNT_CHOICE_PERCENT) mealSet.discount = discountValue
      else if (discountType === DISCOUNT_CHOICES.DISCOUNT_CHOICE_PROMO_PRICE) mealSet.promotionalPrice = discountValue
      else if (discountType === DISCOUNT_CHOICES.DISCOUNT_CHOICE_VALUE) mealSet.discount_value = discountValue
      delete mealSet.discount_type
    },
    getMealSetTypeName(mealSet) {
      switch (mealSet.type) {
        case MEALSET_TYPES.MEAL_SET:
          return this.$t('message.mealSets.normal')
        case MEALSET_TYPES.HALF_PIZZA:
          return this.$t('message.mealSets.halfByHalf')
        default:
          return null
      }
    },
  },
}

<template>
  <b-card class="h-100" no-body>
    <b-card-header header-class="header">
      <div class="w-100 d-flex justify-content-between">
        <div class="box placeholder-anim" style="width: 25%" />
        <div class="d-flex" style="width: 30%">
          <div class="box mr-2 placeholder-anim" />
          <div class="box placeholder-anim" />
        </div>
      </div>
    </b-card-header>
    <b-card-body body-class="p-3 overflow-y-auto">
      <b-overlay :show="true" spinner-variant="primary" spinner-small style="min-height: 100%" />
    </b-card-body>
  </b-card>
</template>

<script>
const STORE = '_menuEdit'

export default {
  name: 'Edit',
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
.box {
  width: 100%;
  background-color: $gray-200;
  border-radius: 8px;
  height: 30px;
}
</style>

<template>
  <l-marker
    :lat-lng="coords"
    :icon="getOrderIcon()"
    @mouseenter="setIsHover(true)"
    @mouseleave="setIsHover(false)"
    @click="selectOrder(id)"
  >
    <l-tooltip
      :options="{
        permanent: true,
        interactive: true,
        direction: 'top',
        offset: [0, -8],
        className: 'order-marker',
        maxWidth: 200,
      }"
      :class="[
        'order-marker-content',
        {
          'order-marker--selected': isSelected,
          'order-marker--hover': isHover,
        },
      ]"
    >
      <div class="d-flex order-marker-content__wrapper" @mouseenter="setIsHover(true)" @mouseleave="setIsHover(false)">
        <Countdown
          :date="promisedTime"
          :is-map="true"
          :is-map-tooltip="true"
          :show-left-time="false"
          :show-promised-time="false"
          :order-number="number"
          :show-order-number="true"
          :class="{
            'font-weight-bold': isSelected || isHover,
          }"
        ></Countdown>
        <div class="order-marker-content__label d-flex align-items-center flex-fill text-truncate pl-1">
          <span v-show="isSelected || isHover" class="order-marker__label">{{ label }}</span>
          <i v-if="!courier" class="fas fa-user-times text-danger order-marker__courier-icon pr-1"></i>
          <i v-else class="fas fa-user-check text-success order-marker__courier-icon pr-1"></i>
        </div>
      </div>
    </l-tooltip>
  </l-marker>
</template>

<script>
import { LMarker, LTooltip } from 'vue2-leaflet'
import L from 'leaflet'
import Countdown from '@/components/Countdown'
import pointImage from '@/../public/static/images/order-map-marker-point.svg'

export default {
  name: 'OrderMapMarker',
  components: { LMarker, Countdown, LTooltip },
  props: {
    id: { type: [Number, String], required: true },
    coords: { type: Object, required: true },
    label: { type: String, required: true },
    promisedTime: { type: String, required: true },
    courier: null,
    number: { type: String, required: true },
    isSelected: { type: Boolean, default: false },
    selectOrder: { type: Function, default: () => false },
  },
  data() {
    return {
      isHover: false,
    }
  },
  methods: {
    getOrderIcon() {
      return L.icon({
        iconUrl: pointImage,
        iconSize: [18, 18],
      })
    },
    setIsHover(value) {
      this.isHover = value
    },
  },
}
</script>

<style lang="scss">
div.leaflet-tooltip.order-marker {
  padding: 0;
  border: none;
  opacity: 1 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.order-marker {
  &--selected,
  &--hover {
    z-index: 2 !important;
    overflow: visible !important;
  }

  &--selected {
    box-shadow: 0 0 0 2px rgba(31, 118, 255, 0.5), 0 0 9px 0 rgba(0, 123, 255, 0.85);
  }

  &-content {
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: 4px;
    &:hover {
      z-index: 3 !important;
      overflow: visible;
    }

    &__wrapper {
      border-radius: 4px;
      overflow: hidden;
    }

    &__label {
      max-width: 200px;
      padding: 3px;
    }
  }

  &__courier-icon {
    margin-left: 5px;
    font-size: 16px;
    align-items: center;
    display: flex;
  }
}
</style>

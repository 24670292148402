import { API } from '@/services/api/api'
import Types from '@/types/interface'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import { EmployeesListState } from './index'

const getEmployees = async ({ commit }: ActionContext<EmployeesListState, RootState>) => {
  const { error, data } = await API.getUsers({ visible: true })
  if (!error && data) {
    commit('setEmployees', data)
  }
}

const setUserIsActive = async (
  { dispatch }: ActionContext<EmployeesListState, RootState>,
  formData: Types.setUserActiveData
) => {
  const { error } = await API.setUserActive(formData)
  if (!error) {
    dispatch('getEmployees')
  } else {
    // TODO: Handle error
  }
}

const removeUser = async ({ dispatch }: ActionContext<EmployeesListState, RootState>, userId: number) => {
  const { error } = await API.deleteUser(userId)
  if (!error) {
    dispatch('getEmployees')
  }
}

export default {
  getEmployees,
  setUserIsActive,
  removeUser,
}

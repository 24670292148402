<template>
  <td
    class="summary-cell"
    :class="[extraClass, { 'summary-cell--bl': borderLeft, 'summary-cell--br': borderRight }]"
    :style="styles"
  >
    <template v-if="isLink">
      <component :is="redirect ? 'router-link' : 'span'" :to="redirect ? { name: 'orders-archive', query } : null">
        <span>{{ getQuantity }} </span
        ><span v-if="getValue !== undefined">({{ priceDecimal(getValue) }} {{ currency }}) </span>
      </component>
    </template>
    <template v-else-if="!hideIfNoEmployeeId">
      <span>{{ getQuantity }} </span
      ><span v-if="getValue !== undefined">({{ priceDecimal(getValue) }} {{ currency }}) </span>
    </template>
  </td>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SummaryCell',
  props: {
    employee: Object,
    defaultQuery: Object,
    extraQuery: Object,
    quantity: Number,
    quantityFieldName: String,
    value: [String, Number],
    valueFieldName: String,
    borderLeft: {
      type: Boolean,
      default: false,
    },
    borderRight: {
      type: Boolean,
      default: false,
    },
    hideIfNoEmployeeId: {
      type: Boolean,
      default: false,
    },
    styles: String,
    extraClass: String,
    redirect: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['currency']),
    employeeId() {
      return this.employee && this.employee.id
    },
    isLink() {
      return this.employeeId || this.quantity !== undefined
    },
    query() {
      let query = {
        ...this.defaultQuery,
      }

      if (this.employee && this.employee.id) {
        query.employeeID = this.employee.id
      }

      if (this.extraQuery) {
        query = { ...query, ...this.extraQuery }
      }
      return query
    },
    getQuantity() {
      if (this.quantity !== undefined) {
        return this.quantity
      } else if (this.employee && this.quantityFieldName) {
        return this.employee[this.quantityFieldName]
      }
    },
    getValue() {
      if (this.value !== undefined) {
        return this.value
      } else if (this.employee && this.valueFieldName) {
        return this.employee[this.valueFieldName]
      }
    },
  },
}
</script>

<style lang="scss">
.summary-cell {
  text-align: center;
  white-space: nowrap;
  &--bl {
    border-left: 1px solid #dee2e6;
  }
  &--br {
    border-right: 1px solid #dee2e6;
  }
}
</style>

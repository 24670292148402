import { IRecommendationsState } from './index'
import { IRecommendation } from '@/types/interface'

const setRecommendation = (state: IRecommendationsState, recommendation: IRecommendation) => {
  state.recommendation = recommendation
}

const removeRecommendation = (state: IRecommendationsState) => {
  state.recommendation = null
}

export default {
  setRecommendation,
  removeRecommendation
}
import { API } from '@/services/api/api'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import { IOrderState } from '@/store/modules/order/index'
import { ICustomerAddressRemove } from '@/types/interface/customer'

const findCustomers = async ({ commit, state }: ActionContext<IOrderState, RootState>, phone: string) => {
  const { error, data } = await API.findCustomer(phone)
  if (!error && data) {
    commit('setFoundCustomers', data)
  } else {
    // TODO: Handle error
  }
}

const removeCustomerAddress = async (
  { commit, state }: ActionContext<IOrderState, RootState>,
  customer: ICustomerAddressRemove
) => {
  const { addressId, customerPhoneNumber } = customer
  const { error, data } = await API.removeCustomerAddress(addressId, customerPhoneNumber)
  if (!error && data) {
    commit('setFoundCustomers', data)
  } else {
    // TODO: Handle error
  }
}

export default {
  findCustomers,
  removeCustomerAddress,
}

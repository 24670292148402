import Rollbar from 'rollbar'
import Vue from 'vue'

export const rollbar = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR_API_KEY ? process.env.VUE_APP_ROLLBAR_API_KEY : '',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: !!process.env.VUE_APP_ROLLBAR_API_KEY,
  environment: process.env.VUE_APP_ROLLBAR_ENVIRONMENT,
  verbose: true,
  autoInstrument: {
    network: true,
    log: true,
    dom: true,
    navigation: true,
    connectivity: true,
    networkRequestBody: true,
    networkResponseHeaders: true,
    networkResponseBody: true,
  },
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
}).global({
  itemsPerMinute: 5,
  maxItems: 10,
})
// array of errors messages and fragment of stacks
const ignoredErrors = [
  { msg: '_leaflet_pos', stackFragment: '' },
  { msg: 'is undefined', stackFragment: 'getPosition' },
  { msg: 'is undefined', stackFragment: '_setOpacityIE' },
  { msg: '$ is not defined', stackFragment: 'script' },
  { msg: 'Unable to decode audio data', stackFragment: '' },
  { msg: '', stackFragment: 'scrubJson ' },
  { msg: '', stackFragment: 'chunk-vendors' },
  { msg: '', stackFragment: 'twk-chunk-common' },
  { msg: 'Decoding failed', stackFragment: '' },
]

const checkIsIgnored = function (item: any): boolean {
  return ignoredErrors.some((error) => {
    return item.message.includes(error.msg) && item.stack.includes(error.stackFragment)
  })
}

// @ts-ignore
rollbar.handleUncaughtException = (msg: string, url: string, lineNo: number, colNo: number, event: any) => {
  // ignored errors will not be logged to rollbar
  if (!checkIsIgnored(event)) {
    try {
      Vue.prototype.$rollbar.error(event)
    } catch (error) {}
  }
}

// @ts-ignore
rollbar.handleUnhandledRejection = (rejection: any) => {
  const { message, stack, request } = rejection
  if (!checkIsIgnored({ message, stack })) {
    try {
      const error = new Error(message)
      if (stack) error.stack = stack
      // @ts-expect-error: Property 'request' does not exist on type 'Error'.
      if (request) error.request = request
      Vue.prototype.$rollbar.error(error)
    } catch (error) {}
  }
}

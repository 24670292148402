export const responseErrorsHandler = {
  data() {
    return {
      responseErrors: {},
    }
  },
  methods: {
    clearResponseErrors() {
      this.responseErrors = {}
    },
    setResponseErrors(errors = {}) {
      console.log('errors', errors)
      // String error could come if it's stacktrace from backend
      if (typeof errors === 'string') this.responseErrors = { non_field_errors: [errors] }
      else if (Array.isArray(errors)) this.responseErrors = { non_field_errors: errors.join('.') }
      else this.responseErrors = { ...errors }
    },
  },
}

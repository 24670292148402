import { EmployeesListState } from './index'

const employees = (state: EmployeesListState) => state.employees
const filteredByActive = (state: EmployeesListState) =>
  state.employees.filter((employee) => {
    return employee.is_active === true
  })

export default {
  employees,
  filteredByActive,
}

import { IOrderState } from '@/store/modules/order/index'
import { ICustomer } from '@/types/interface/customer'

const setFoundCustomers = (state: IOrderState, value: Array<ICustomer>) => {
  state.foundCustomers = value
}

export default {
  setFoundCustomers,
}

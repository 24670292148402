<template>
  <div class="toggle-filters">
    <b-button
      class="btn-toggle-filters"
      :variant="
        extraFilters
          ? primaryFiltersCount
            ? 'outline-primary'
            : 'primary'
          : primaryFiltersCount
          ? 'outline-primary'
          : 'light'
      "
      @click="$emit('toggleExtraFilters')"
    >
      <i class="fa fa-filter" />
    </b-button>
    <b-badge v-if="primaryFiltersCount" class="btn-toggle-filters-badge" variant="primary" pill>
      {{ primaryFiltersCount }}
    </b-badge>
  </div>
</template>

<script>
export default {
  name: 'OrderListFiltersToggleButton',
  props: {
    primaryFiltersCount: {
      type: Number,
      default: 0,
    },
    extraFilters: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
.toggle-filters {
  position: relative;
}

.btn-toggle-filters {
  margin-left: 10px;
  width: 50px;
  height: 100%;
  font-size: 18px;

  &-badge {
    position: absolute;
    right: -5px;
    top: -5px;
  }
}

@media (max-width: 576px) {
  .btn-toggle-filters {
    font-size: 15px;
  }
}
</style>

<template>
  <div class="view-container">
    <b-container>
      <b-row align-v="center" align-h="between">
        <b-col>
          <b-breadcrumb class="pl-4 mb-1">
            <b-breadcrumb-item :to="{ name: 'settings' }">
              {{ $t('message.settings.settings') }}
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'integrations' }" active>
              {{ $t('message.settings.integrations') }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
      </b-row>
      <section class="widget">
        <div class="widget-body-wrapper p-4">
          
          <!--          <IntegrationWolt />-->
        </div>
      </section>
    </b-container>
  </div>
</template>

<script>
import IntegrationWolt from './_components/integrationWolt'

export default {
  name: 'Integrations',
  components: {
    IntegrationWolt,
  },
  data() {
    return {}
  },
}
</script>
<style lang="scss">
.integration {
  &-section {
    margin: 0rem -1.5rem 2rem -1.5rem;
    padding: 0 1.5rem;
    border-bottom: 1px solid $gray-300;
  }
  &-option-row {
    margin-bottom: 32px;
    &__title {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
    &__description {
      font-size: 12px;
      line-height: 16px;
      color: $gray-600;
    }
  }
  &-badge {
    &--active {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;

      &.icon {
        &--power-off {
          right: 31px;
        }
      }

      &.badge--green {
        .icon,
        .text {
          color: #24d17e;
        }
      }
    }
  }
  &-info {
    padding-bottom: 1.5rem;
    a {
      margin-left: 1rem;
    }
  }
}

.update-menu {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  &__loader {
    width: auto;
    top: 0;
    right: 0;
    position: relative;
    margin-left: 20px;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
}
</style>

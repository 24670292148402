import { ROLE } from '@/common/constants'

export const UsersMixins = {
  methods: {
    getUserRoleName(role) {
      switch (role) {
        case ROLE.COURIER:
          return this.$t('message.employeeList.courier')
        case ROLE.WAITER:
          return this.$t('message.employeeList.waiter')
        case ROLE.MANAGER:
          return this.$t('message.employeeList.manager')
        case ROLE.OWNER:
          return this.$t('message.employeeForm.employeeTypes.owner')
        case ROLE.COOK:
          return this.$t('message.employeeForm.employeeTypes.cook')
      }
    },
  },
}

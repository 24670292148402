const setBillingPeriods = (state, billingPeriods) => {
  state.billingPeriods = [...billingPeriods]
}

const setBillingPeriodsRequest = (state, billingPeriodsRequest) => {
  state.billingPeriodsRequest = billingPeriodsRequest
}

export default {
  setBillingPeriods,
  setBillingPeriodsRequest,
}

<template>
  <section class="page">
    <header class="page__header">
      <h1 class="sr-only">{{ $t('message.mealDetails.splitBill') }}</h1>
      <nav>
        <ul class="list">
          <li>
            <b-button v-shortkey="['esc']" class="btn btn--close" @shortkey="back" @click="back">
              <kbd class="pb-0 mr-2 bg-white text-dark btn__shortcut">ESC</kbd>
              {{ $t('message.orderDetails.close') }}
            </b-button>
          </li>
        </ul>
      </nav>
    </header>
    <main class="page__main">
      <div class="pr-1 bill">
        <b-card class="card--bill" no-body>
          <Loader v-if="gettingBills || creating" :text="`${$t('message.splitBill.gettingBills')}...`" />
          <template v-else>
            <b-card-header class="card__header">
              <h2 class="card__title mt-0">{{ $t('message.common.order') }}</h2>
              <p class="card__total">{{ priceDecimal(total) }} {{ currency }}</p>
            </b-card-header>
            <b-card-body class="card__body card__body--bill">
              <ul v-if="billPositions.length" class="list">
                <li
                  v-for="(billPosition, billPositionIndex) in billPositions"
                  :key="`position_${billPositionIndex}`"
                  class="list__item"
                >
                  <BillPosition move-action="onRight" :item="billPosition" />
                </li>
              </ul>
              <div v-else class="list__placeholder">
                <p class="list__placeholder-text">
                  {{ $t('message.splitBill.emptyBaseBillDescription') }}
                </p>
              </div>
              <MoveItemModal />
            </b-card-body>
          </template>
        </b-card>
      </div>
      <div class="pl-1 px-md-1 bill">
        <router-view v-if="billsLength" />
        <div v-else class="list__placeholder list__placeholder--rounded">
          <p v-if="!gettingBills" class="list__placeholder-text">
            <i class="fas fa-plus d-block display-4 font-weight-bold mb-2" aria-hidden="true" />
            {{ $t('message.splitBill.addFirstBill') }}
          </p>
        </div>
      </div>
      <div class="bill-shortcut">
        <b-card class="card--bill" body-class="card__body--shortcuts">
          <ul class="list p-0">
            <template v-if="bills.length">
              <li v-for="(bill, billIndex) in bills" :key="`bill_${billIndex}`">
                <BillShortcut
                  :id="bill.id"
                  :shortcut-status="bill.status"
                  :number="billIndex + 1"
                  :total="bill.total_price"
                  :active="isClickedBillActive(bill.id)"
                />
              </li>
            </template>
            <li v-if="canAddedNewBill && !hasBillsLimitExceeded">
              <b-overlay
                :show="creating"
                rounded
                opacity="0.6"
                spinner-small
                variant="secondary"
                spinner-variant="light"
                class="overlay--add mb-2 mt-2"
              >
                <BillShortcut is-button @clicked="createBill" />
              </b-overlay>
            </li>
          </ul>
        </b-card>
      </div>
    </main>
    <footer class="page__footer">
      <b-row>
        <b-col cols="8">
          <b-button
            v-b-modal.splitOrderModal
            variant="info"
            class="mr-4"
            :disabled="billPositions.length === 0 || hasBillsLimitExceeded || billsLength === billsLimit - 1"
          >
            <i class="fas fa-slash mr-2" aria-hidden="true" />
            {{ $t('message.splitBill.splitIntoEqualParts') }}
          </b-button>
          <!-- hide, feature for future version -->
          <!-- <b-button variant="info" v-b-modal.splitOrderByPriceModal>
            <i class="fas fa-sign-out-alt mr-2" aria-hidden="true" />
            {{ $t('message.splitBill.extractTheAmount') }}
          </b-button> -->
        </b-col>
        <b-col cols="4" class="text-right">
          <b-overlay
            :show="creating"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
          >
            <b-button variant="primary" :disabled="!canAddedNewBill || hasBillsLimitExceeded" @click="createBill">
              <i class="fas fa-plus mr-2" aria-hidden="true" />
              {{ $t('message.splitBill.addBill') }}
            </b-button>
          </b-overlay>
        </b-col>
      </b-row>
    </footer>
    <SplitOrderModal />
    <!-- hide, feature for future version -->
    <!-- <SplitOrderByPriceModal /> -->
  </section>
</template>

<script>
import BillShortcut from './_components/BillShortcut'
import BillPosition from './_components/BillPosition'
import MoveItemModal from './_components/modals/MoveItemModal'
import SplitOrderModal from './_components/modals/SplitOrderModal'
// import SplitOrderByPriceModal from './_components/modals/SplitOrderByPriceModal'
import Loader from '@/components/Loader'
import store from './_store'
import { billStatusMixins } from '@/mixins'
import { mapGetters } from 'vuex'

const STORE = '_orderSplitBill'

export default {
  name: 'OrdersSplitBill',
  components: {
    BillShortcut,
    BillPosition,
    MoveItemModal,
    SplitOrderModal,
    Loader,
    // SplitOrderByPriceModal
  },
  mixins: [billStatusMixins],
  data() {
    return {
      creating: false,
    }
  },
  computed: {
    id() {
      return parseInt(this.$route.params.id)
    },
    ...mapGetters({
      currency: 'currency',
      baseBill: `${STORE}/baseBill`,
      bills: `${STORE}/bills` || [],
      billsLimit: `${STORE}/billsLimit`,
      billsLength: `${STORE}/billsLength`,
      lastBillId: `${STORE}/lastBillId`,
      billsRequestStatus: `${STORE}/billsRequestStatus`,
      hasSplitBills: `${STORE}/hasSplitBills`,
    }),
    billPositions() {
      return this.baseBill?.positions || []
    },
    total() {
      return this.baseBill?.total_price || 0
    },
    currentRouteName() {
      return this.$route.name
    },
    billId() {
      return this.$route.params.billId || null
    },
    gettingBills() {
      return this.billsRequestStatus === 'loading'
    },
    canAddedNewBill() {
      const filledBaseBill = !!this.baseBill?.positions.length || false
      const existNewBills = this.bills?.some((bill) => this.checkIsNew(bill.status)) || false
      return filledBaseBill || (filledBaseBill && !existNewBills)
    },
    hasBillsLimitExceeded() {
      return this.billsLength >= this.billsLimit
    },
  },
  async created() {
    this.registerStore()
    await this.getBills()
    this.checkAccessToModule()
    if (this.currentRouteName === 'orders-split-bill' && this.billsLength > 0) {
      this.redirectToFirstBill()
    }
  },
  mounted() {
    this.initModalListener()
  },
  destroyed() {
    this.destroyModalListener()
    this.unregisterStore()
  },
  methods: {
    back() {
      this.$eventBus.$emit('onShowDetailsModal', { id: this.id })
    },
    async createBill() {
      if (!this.hasBillsLimitExceeded) {
        this.creating = true
        try {
          await this.$store.dispatch(`${STORE}/createBill`)
          this.redirectToLastBill()
        } catch ({ errorStatus, errorValue }) {
          this.showErrorToast(errorStatus, errorValue)
        }
        this.creating = false
      }
    },
    async getBills() {
      this.$store.commit(`${STORE}/setOrderId`, this.id)
      try {
        await this.$store.dispatch(`${STORE}/getBills`)
      } catch ({ errorStatus, errorValue }) {
        this.showErrorToast(errorStatus, errorValue)
      }
    },
    registerStore() {
      if (!this.$store.state[STORE]) {
        this.$store.registerModule(STORE, store)
      } else {
        this.$store.commit(`${STORE}/resetState`)
      }
    },
    unregisterStore() {
      this.$store.commit(`${STORE}/resetState`)
      this.$store.unregisterModule(STORE)
    },
    initModalListener() {
      this.$root.$on('bv::modal::shown', (e, modalId) => {
        if (modalId === 'detailsModal') {
          this.$router.push({ name: 'orders-list', params: { orderStatus: this.queryOrderStatus } })
        }
      })
    },
    destroyModalListener() {
      this.$root.$off('bv::modal::shown')
    },
    redirectToLastBill() {
      this.$router.replace({
        name: 'orders-split-bill-edit',
        params: { id: this.id, billId: this.lastBillId, orderStatus: this.queryOrderStatus },
      })
    },
    isClickedBillActive(id) {
      return id == this.billId
    },
    redirectToFirstBill() {
      this.$router.replace({
        name: 'orders-split-bill-edit',
        params: { id: this.id, billId: this.bills[0].id, orderStatus: this.queryOrderStatus },
      })
    },
    showErrorToast(errorStatus, errorValue) {
      const msg = errorStatus !== 500 && errorValue ? errorValue : this.$t('message.common.anErrorOccured')
      this.$toasted.error(msg, {
        duration: 5000,
      })
    },
    checkAccessToModule() {
      if (
        this.currentRouteName === 'orders-split-bill' &&
        (this.isEmptyQueryOrderStatus || (!this.hasSplitBills && this.isOrderFinished))
      ) {
        this.$router.go(-1)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/scss/components/split-bill';

.page {
  height: 100vh;
  display: flex;
  flex-direction: column;

  &__header {
    background-color: #121f3e;
  }

  &__main {
    flex-grow: 1;
    padding: 12px;
    display: flex;
    flex-wrap: wrap;

    @media (min-width: 768px) {
      flex-wrap: nowrap;
    }
  }

  &__footer {
    background: #ffffff;
    border: 1px solid #e4e7e8;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.03);
    padding: 21px 16px;
  }
}

.btn {
  &--close {
    background-color: #1f2e54;
    border-color: #1f2e54;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }

  &__shortcut {
    font-weight: bold;
    font-size: 10px;
    line-height: 24px;
    min-width: 32px;
    min-height: 24px;
    display: inline-block;
    padding: 0;
    font-family: 'Roboto', sans-serif;
  }
}

$headerHeight: 62px;
$mainPaddingTopBottom: 24px;
$footerHeight: 82px;
$shortcutWidth: 83px;

.card__body--shortcuts {
  padding: 0;
  overflow-y: auto;
  max-height: calc(100vh - #{$headerHeight} - #{$mainPaddingTopBottom} - #{$footerHeight});

  .list {
    @media (max-width: 767px) {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.card__body--bill {
  $cardHeaderHeight: 51px;
  max-height: calc(100vh - #{$headerHeight} - #{$mainPaddingTopBottom} - #{$footerHeight} - #{$cardHeaderHeight});
}

.bill {
  flex-grow: 1;
  max-width: calc((100% - #{$shortcutWidth}) / 2);
}

.overlay--add {
  margin-left: 8px;
  margin-right: 8px;
  display: inline-block;
}
</style>

<template>
  <div class="view-elements p-2 p-md-4 overflow-y-auto">
    <template v-if="getActiveMenuId">
      <categories-list v-if="menuElement === 'categories'" />
      <sizes-list v-if="menuElement === 'sizes'" />
      <choice-groups-list v-if="menuElement === 'multi_choice_groups'" :group-type="'multi_choice_group'" />
      <choice-groups-list v-if="menuElement === 'single_choice_groups'" :group-type="'single_choice_group'" />
      <meals-list v-if="menuElement === 'meals'" />
    </template>
  </div>
</template>

<script>
import CategoriesList from './list/categories/CategoriesList'
import SizesList from './list/SizesList'
import ChoiceGroupsList from './list/choice-groups/ChoiceGroupsList'
import MealsList from './list/MealsList'
import { mapGetters } from 'vuex'

const STORE = '_menuEdit'

export default {
  name: 'List',
  components: {
    CategoriesList,
    SizesList,
    ChoiceGroupsList,
    MealsList,
  },
  computed: {
    ...mapGetters(STORE, ['getActiveMenuId', 'menuElement']),
  },
}
</script>

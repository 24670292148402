import { IntegrationsState } from './index'
import { IIntegration } from '@/types/interface'

const setIntegrations = (state: IntegrationsState, integrations: Array<IIntegration>) => {
  state.integrations = integrations
}

export default {
  setIntegrations
}

import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import { MODULES, ROLE } from '@/common/constants'
import { isMobile } from '@/utils/common'
import { getDefaultModulesAccess, IModulesState } from '@/store/modules/modules/index'
import _ from 'lodash'

export const assignModuleAccess = ({ rootGetters, commit }: ActionContext<IModulesState, RootState>) => {

  const createdByPosAppsmart = rootGetters['auth/createdByPosAppsmart']
  const permissions = rootGetters['settings/localizationPermissions']
  const hasUserRole = rootGetters['auth/hasUserRole']
  const isPapu3 = rootGetters['auth/isPapu3']
  const features = rootGetters['auth/getLocalizationFeatures']
  const enableShiftsAndBillingPeriods = features?.enable_shifts_and_billing_periods
  
  // KEEP THIS LIST IN ALPHABETIC ORDER PLEASE
  const modulesAccess = {
    [MODULES.administration]: [!isPapu3],
    [MODULES.billingPeriods]: [enableShiftsAndBillingPeriods, !isPapu3],
    [MODULES.billingPeriodsCurrent]: [enableShiftsAndBillingPeriods, !isPapu3],
    [MODULES.billingPeriodDetails]: [enableShiftsAndBillingPeriods, !isPapu3],
    [MODULES.billPrint]: [!createdByPosAppsmart, !isPapu3],
    [MODULES.billSplit]: [!createdByPosAppsmart, !isMobile(), !isPapu3],
    [MODULES.discountList]: [!isPapu3],
    [MODULES.editOrder]: [!createdByPosAppsmart],
    [MODULES.employeeAdd]: [!isPapu3],
    [MODULES.employeesList]: [
      !hasUserRole(ROLE.WAITER) || (hasUserRole(ROLE.WAITER) && permissions.manage_employees_waiter),
    ],
    [MODULES.erestaurant]: [!isPapu3],
    [MODULES.getMenus]: [!createdByPosAppsmart],
    [MODULES.helpButton]: [!createdByPosAppsmart],
    [MODULES.helpdeskChat]: [!createdByPosAppsmart],
    [MODULES.invoicePrint]: [!createdByPosAppsmart, !isPapu3],
    [MODULES.integrations]: [!isPapu3],
    [MODULES.kds]: [features?.enable_kds],
    [MODULES.logout]: [!createdByPosAppsmart],
    [MODULES.mapping]: [features?.mapping, !isPapu3],
    [MODULES.menuEdit]: [!createdByPosAppsmart, !isPapu3],
    [MODULES.meals]: [!isPapu3],
    [MODULES.mealSets]: [!isPapu3],
    [MODULES.newOrder]: [!createdByPosAppsmart],
    [MODULES.newOrderDelivery]: [!createdByPosAppsmart, !isPapu3],
    [MODULES.newOrderOnSite]: [!createdByPosAppsmart, !isPapu3],
    [MODULES.newOrderPapu3]: [isPapu3],
    [MODULES.ordersList]: [!isPapu3],
    [MODULES.ordersArchive]: [!isPapu3],
    [MODULES.ordersSummary]: [true],
    [MODULES.papu_3]: [isPapu3],
    [MODULES.paymentMethodEdit]: [!createdByPosAppsmart],
    [MODULES.paymentsPackagePrice]: [true],
    [MODULES.paymentsServiceCharge]: [true],
    [MODULES.paymentsTips]: [true],
    [MODULES.permissions]: [!isPapu3],
    [MODULES.promisedTimePredictionShow]: [features?.show_promised_time_prediction],
    [MODULES.receiptPrint]: [!isPapu3],
    [MODULES.receiptPrintersEdit]: [!isMobile(), !isPapu3],
    [MODULES.receiptPrintersList]: [ !isMobile(), !isPapu3],
    [MODULES.receiptPrintersSettings]: [!isMobile(), !isPapu3],
    [MODULES.restoreOrder]: [!createdByPosAppsmart],
    [MODULES.settings]: [!createdByPosAppsmart],
    [MODULES.shiftDetails]: [enableShiftsAndBillingPeriods, !isPapu3],
    [MODULES.shifts]: [enableShiftsAndBillingPeriods, !isPapu3],
    [MODULES.shiftsCurrent]: [enableShiftsAndBillingPeriods, !isPapu3],
    [MODULES.subscriptions]: [!features?.use_new_payments_system],
    [MODULES.tableNumberEdit]: [!createdByPosAppsmart],
    [MODULES.takeawayApi]: [features?.takeaway_api],
    [MODULES.recommendations]: [features?.scheduler]
  }

  const defaultModuleAccess = getDefaultModulesAccess()
  _.forEach(modulesAccess, (guards, key) => {
    defaultModuleAccess[key] = guards.every((value) => !!value)
  })
  commit('setModulesAccess', defaultModuleAccess)
}

export default {
  assignModuleAccess,
}

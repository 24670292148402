import { API } from '@/services/api/api'

// API REFACTORING - NEED TESTING
const getBillingPeriods = async ({ commit }, payload = {}) => {
  const { loading, filters = {} } = payload
  if (loading) commit('setBillingPeriodsRequest', 'loading')
  const { error, data } = await API.getBillingPeriodsCurrent(filters)
  if (!error && data) {
    commit('setBillingPeriods', data)
    commit('setBillingPeriodsRequest', 'success')
  } else {
    commit('setBillingPeriodsRequest', 'error')
  }
}

// API REFACTORING - NEED TESTING
const closeBillingPeriod = async ({ dispatch }, billingPeriodID) => {
  const { error } = await API.closeBillingPeriod(billingPeriodID)
  if (!error) {
    dispatch('getBillingPeriods')
  }
}
// API REFACTORING - NEED TESTING
const createBillingPeriodEntry = async ({ dispatch, commit, state }, payload) => {
  const { form, onSuccess, onError } = payload
  const { error } = await API.createBillingPeriodEntry(form)
  if (!error) {
    if (onSuccess) onSuccess()
    dispatch('getBillingPeriods', { loading: true })
  } else {
    if (onError && error?.response?.data) onError(error.response.data)
  }
}

export default {
  getBillingPeriods,
  closeBillingPeriod,
  createBillingPeriodEntry,
}

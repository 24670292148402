import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { IDeliveryType, IOrderFormEditMode, IOrderPapu3 } from '@/types/interface'
import { DELIVERY_TYPES, MENU_SOURCE_CHOICES, PICKUP_TYPE_DELIVERY } from '@/common/constants'
import { getField, updateField } from 'vuex-map-fields'

export interface INewOrderState {
  orderData: any
  order: Partial<IOrderPapu3>
  menu_source: number | string
  loading: boolean
  editMode: IOrderFormEditMode
}

const getDefaultState = (): INewOrderState => {
  return {
    orderData: {},
    order: {
      customer: {
        phone: '',
        address: {
          address: '',
          city: '',
        },
        paymentType: '',
      },
      delivery_type: DELIVERY_TYPES.ASAP as IDeliveryType,
      pickup_type: PICKUP_TYPE_DELIVERY,
      menu: null,
      promised_time: '',
      comment: '',
      price: 0,
      payment: ['cash'],
    },
    menu_source: MENU_SOURCE_CHOICES.DEFAULT,
    loading: false,
    editMode: 'new',
  }
}

const resetState = (state: INewOrderState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getField,
    ...getters,
  },
  mutations: {
    updateField,
    resetState,
    ...mutations,
  },
  actions,
}

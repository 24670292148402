import { BILL_STATUSES } from '@/common/constants'

const STORE = '_orderSplitBill'

export const billStatusMixins = {
  data() {
    return {
      BILL_STATUSES,
    }
  },
  computed: {
    queryBillId() {
      return parseInt(this.$route.params.billId)
    },
    queryOrderStatus() {
      return this.$route.params.orderStatus
    },
    isOrderFinished() {
      return this.queryOrderStatus === 'finished'
    },
    isEmptyQueryOrderStatus() {
      return !this.queryOrderStatus
    },
    billStatus() {
      const bill = this.$store.getters[`${STORE}/bill`](this.queryBillId)
      return bill ? bill.status : null
    },
    isNew() {
      return this.checkIsNew(this.billStatus)
    },
    isDone() {
      return this.checkIsDone(this.billStatus)
    },
    isDoneAndPrinted() {
      return this.checkIsDoneAndPrinted(this.billStatus)
    },
    isPrintError() {
      return this.checkIsPrintError(this.billStatus)
    },
    isInQueue() {
      return this.checkIsInQueue(this.billStatus)
    },
    isSkipped() {
      return this.checkIsSkipped(this.billStatus)
    },
    hasDoneBadgeShown() {
      return this.isDone || this.isPrintError || this.isInQueue || this.isDoneAndPrinted
    },
    canPrintedBill() {
      return this.isDone || this.isPrintError || this.isOrderFinished
    },
    editableBill() {
      return this.checkIsEditable(this.billStatus)
    },
  },
  methods: {
    checkIsNew(status) {
      return status === BILL_STATUSES.CREATING
    },
    checkIsDone(status) {
      return status === BILL_STATUSES.CREATED
    },
    checkIsDoneAndPrinted(status) {
      return status === BILL_STATUSES.CREATED_AND_PRINTED
    },
    checkIsPrintError(status) {
      return status < 0
    },
    checkIsInQueue(status) {
      return status === BILL_STATUSES.IN_QUEUE
    },
    checkIsSkipped(status) {
      return status === BILL_STATUSES.SKIPPED
    },
    checkHasDoneBadgeShown(status) {
      return (
        this.checkIsDone(status) ||
        this.checkIsPrintError(status) ||
        this.checkIsInQueue(status) ||
        this.checkIsDoneAndPrinted(status)
      )
    },
    checkIsEditable(status) {
      return (this.checkIsNew(status) || this.checkIsSkipped(status)) && !this.isOrderFinished
    },
  },
}

import { API } from '@/services/api/api'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import { IRecommendationsState } from './index'
import { RECOMMENDATION_ACTIONS } from '@/common/constants'
import { getSoundFile } from '@/utils/common'
import _ from 'lodash'

const getRecommendation = async ({state, commit, rootState }: ActionContext<IRecommendationsState, RootState>) => {
  const localizationId = rootState.auth?.localization?.id
  if (!localizationId) return

  const lastRecommendation = state.recommendation
  
  const { error, data } = await API.getRecommendation(localizationId)

  if (!error && data) {
    if ([RECOMMENDATION_ACTIONS.MAKE_PHASE_3, RECOMMENDATION_ACTIONS.COURIER_SEND].includes(data.action)) commit('setRecommendation', data)
    else commit('removeRecommendation')
  }

  if (state.recommendation && (!lastRecommendation || !_.isEqual(lastRecommendation, state.recommendation)) ){
    const notification = new Audio(getSoundFile(rootState.settings.localizationPreferences.new_recommendation_sound))
    if (notification) notification.play()
  }
}

export default {
  getRecommendation
}

<template>
  <div class="edit-section">
    <h4 id="mealsWithSelectedChoiceGroups" class="label mb-3">
      {{ $t('message.menu.choiceGroups.mealsWithSelectedChoiceGroups') }}:
    </h4>
    <b-overlay :show="loaders.mealsTree">
      <template v-for="(category, categoryIndex) in tree">
        <b-list-group
          v-if="categoryHasAnyMeal(category)"
          :key="categoryIndex"
          flush
          aria-labelledby="mealsWithSelectedChoiceGroups"
        >
          <b-list-group-item class="px-0">
            <div class="d-flex align-items-center">
              <b-button v-b-toggle="'collapse_category_' + categoryIndex" class="collapseButton" size="sm">
                <span
                  class="when-opened fas fa-minus mr-2"
                  :aria-label="$t('message.receiptPrintersSettings.shrink')"
                />
                <span class="when-closed fas fa-plus mr-2" :aria-label="$t('message.receiptPrintersSettings.expand')" />
              </b-button>
              <b-form-checkbox
                v-model="category.allCategoryMeals"
                class="groupCheckbox"
                size="lg"
                :disabled="saving"
                :indeterminate="category.indeterminate"
                @change="toggleCategoryAll(categoryIndex)"
              />
              <span>{{ category.name }}</span>
            </div>
            <b-collapse :id="'collapse_category_' + categoryIndex" :visible="anyCategorySizeSelected(categoryIndex)">
              <template v-for="(size, sizeIndex) in category.sizes">
                <b-list-group v-if="size.meals.length > 0" :key="sizeIndex">
                  <b-list-group-item class="list-group__item">
                    <div class="d-flex align-items-center">
                      <b-button
                        v-b-toggle="'collapse_category_' + categoryIndex + '_size_' + sizeIndex"
                        size="sm"
                        class="collapseButton"
                      >
                        <span
                          class="when-opened fas fa-minus mr-2"
                          :aria-label="$t('message.receiptPrintersSettings.shrink')"
                        />
                        <span
                          class="when-closed fas fa-plus mr-2"
                          :aria-label="$t('message.receiptPrintersSettings.expand')"
                        />
                      </b-button>
                      <b-form-checkbox
                        v-model="size.allSizeMeals"
                        class="groupCheckbox"
                        size="lg"
                        :disabled="saving"
                        :indeterminate="size.indeterminate"
                        @change="toggleCategoryIndeterminateAllSizeAll(sizeIndex, categoryIndex)"
                      />
                      <span>{{ size.name === 'default' ? $t('message.menu.defaultSizeName') : size.name }}</span>
                    </div>
                    <b-collapse
                      :id="'collapse_category_' + categoryIndex + '_size_' + sizeIndex"
                      :visible="anySizeMealSelected(sizeIndex, categoryIndex)"
                      class="list-group__item"
                    >
                      <div class="sizeGroupMeals">
                        <b-form-checkbox
                          v-for="(meal, mealIndex) in size.meals"
                          :key="meal.id"
                          v-model="meal.checked"
                          :disabled="saving"
                          size="lg"
                          button-variant="outline-primary"
                          @change="toggleSizeIndeterminate(categoryIndex, sizeIndex, mealIndex)"
                          >{{ meal.name }}</b-form-checkbox
                        >
                      </div>
                    </b-collapse>
                  </b-list-group-item>
                </b-list-group>
              </template>
            </b-collapse>
          </b-list-group-item>
        </b-list-group>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'

const STORE = '_menuEdit'

export default {
  name: 'MealsTreeChoiceGroup',
  data() {
    return {
      tree: [],
    }
  },
  computed: {
    ...mapGetters(STORE, ['menuElement', 'saving', 'form', 'loaders', 'getActiveMenuId']),
    ...mapGetters('_menu', ['mealsTree']),
    ...mapFields(STORE, {
      id: 'form.id',
      mealsSelected: 'form.meals',
    }),
  },
  beforeMount() {
    this.initSelectedMeals()
  },
  methods: {
    ...mapMutations(STORE, ['setForm']),
    initSelectedMeals() {
      this.mealsTree(this.getActiveMenuId)?.forEach((cat) => {
        let category = {
          ...cat,
          allCategoryMeals: false,
          indeterminate: false,
          sizes: [],
        }
        cat.sizes.forEach((catSize) => {
          let size = {
            ...catSize,
            allSizeMeals: false,
            indeterminate: false,
            meals: [],
          }
          catSize.meals.forEach((meal) => {
            let _meal = {
              ...meal,
              checked: this.mealsSelected.includes(meal.id),
            }
            size.meals.push(_meal)
          })

          if (size.meals.length > 0 && this.getCheckedMealsLength(size.meals) === size.meals.length) {
            size.allSizeMeals = true
          } else if (this.getCheckedMealsLength(size.meals) > 0) {
            size.indeterminate = true
          }
          category.sizes.push(size)
          let allCategoryMeals = category.sizes.every((s) => {
            return s.allSizeMeals
          })
          let categoryIndeterminate = category.sizes.some((s) => {
            return s.allSizeMeals
          })
          if (allCategoryMeals) category.allCategoryMeals = true
          else if (categoryIndeterminate) category.indeterminate = true
        })
        this.tree.push(category)
      })
    },
    updateMeals() {
      const mealsIds = this.tree.flatMap((cat) =>
        cat.sizes.flatMap((size) => size.meals.filter((meal) => meal.checked).map((m) => m.id))
      )
      this.setForm({ ...this.form, meals: mealsIds })
      this.$emit('change', mealsIds)
    },
    getCheckedMealsLength(meals) {
      return meals.filter((meal) => meal.checked)?.length
    },
    sortedMeals(meals) {
      return _.orderBy(meals, 'name')
    },
    toggleSizeIndeterminate(categoryIndex, sizeIndex, mealIndex) {
      // When meal is checked or unchecked,
      // toggle size to be indeterminate, checked or unchecked
      this.$nextTick(() => {
        const category = this.tree[categoryIndex]
        const size = category.sizes[sizeIndex]
        const meals = size.meals
        const checkedMealsLength = this.getCheckedMealsLength(meals)
        // toggling size checked, uncheck, ed or indeterminate
        if (meals[mealIndex].checked) {
          if (meals.length === checkedMealsLength) {
            size.indeterminate = false
            size.allSizeMeals = true
          } else size.indeterminate = true
        } else if (checkedMealsLength > 0) {
          size.allSizeMeals = false
          size.indeterminate = true
        } else size.indeterminate = false
        // and change the state of category if needed
        this.checkAndToggleCategory(category)
        this.updateMeals()
      })
    },
    toggleCategoryIndeterminateAllSizeAll(sizeIndex, categoryIndex) {
      // When size is toggled checked or unchecked,
      // change state of subject meals and parent category
      const category = this.tree[categoryIndex]
      const size = category.sizes[sizeIndex]
      // toggling size and all size meals checked or unchecked
      if (size.meals.length !== this.getCheckedMealsLength(size.meals)) {
        this.toggleSizeAllMeals(sizeIndex, categoryIndex, true)
      } else {
        this.toggleSizeAllMeals(sizeIndex, categoryIndex, false)
      }
      this.checkAndToggleCategory(category)
      this.updateMeals()
    },
    toggleCategoryAll(categoryIndex) {
      // Toggle checked or unchecked category and subject sizes and meals
      const category = this.tree[categoryIndex]
      if (category.allCategoryMeals) {
        category.sizes.forEach((size, sizeIndex) => {
          this.toggleSizeAllMeals(sizeIndex, categoryIndex, true)
        })
      } else {
        category.sizes.forEach((size, sizeIndex) => {
          this.toggleSizeAllMeals(sizeIndex, categoryIndex, false)
        })
      }
      this.updateMeals()
    },
    toggleSizeAllMeals(sizeIndex, categoryIndex, toggle) {
      // Toggle checked or unchecked size and subject meals depending on given 'toggle'
      let size = this.tree[categoryIndex].sizes[sizeIndex]
      if (toggle) {
        size.indeterminate = false
        size.allSizeMeals = true
      } else {
        size.indeterminate = false
        size.allSizeMeals = false
      }
      size.meals.forEach((meal) => {
        meal.checked = toggle
      })
    },
    checkAndToggleCategory(category) {
      // toggling category checked, indeterminate or unchecked
      let categoryAllMeals = category.sizes.every((s) => {
        return s.allSizeMeals
      })
      let categoryAnyMeals = category.sizes.some((s) => {
        return s.indeterminate || s.allSizeMeals
      })
      if (categoryAllMeals) {
        category.indeterminate = false
        category.allCategoryMeals = true
      } else if (categoryAnyMeals) {
        category.indeterminate = true
        category.allCategoryMeals = false
      } else {
        category.indeterminate = false
        category.allCategoryMeals = false
      }
    },
    anySizeMealSelected(sizeIndex, categoryIndex) {
      return this.tree[categoryIndex].sizes[sizeIndex].meals.length > 0
    },
    anyCategorySizeSelected(categoryIndex) {
      return this.tree[categoryIndex].sizes.some((size) => {
        return size.allSizeMeals || size.indeterminate
      })
    },
    categoryHasAnyMeal(category) {
      return category.sizes.some((size) => size.meals.length > 0)
    },
  },
}
</script>
<style scoped lang="scss">
.sizeGroupMeals {
  margin-top: 0;
  padding-left: 14px;
}

.groupCheckbox {
  display: inline-flex;
  top: -2px;
}

.collapseButton {
  width: 2.5em;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.list-group__item {
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
  border-left: 1px solid $gray-400;
  margin-left: 15px;
  margin-top: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>

<template>
  <div class="countdown" :class="[getClass, { 'countdown--is-table': isTable }]">
    <span v-show="showLeftTime" class="countdown__left-minutes">{{ leftTime }}</span>
    <span v-show="date && showPromisedTime" class="promise-time">{{ getPromisedTime }}</span>
    <span v-show="showOrderNumber">#{{ orderNumber }}</span>
    <div
      v-if="onTime && !isMapTooltip"
      v-b-tooltip.hover
      class="d-flex justify-content-center align-items-center countdown-ontime"
      :title="`${$t('message.ordersList.onTime')} ${getPromisedTime}`"
    >
      <i class="fas fa-clock" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Countdown',
  props: {
    date: {
      default: 0,
    },
    isMap: {
      type: Boolean,
      default: false,
    },
    isTable: {
      type: Boolean,
      default: false,
    },
    isMapTooltip: {
      type: Boolean,
      default: false,
    },
    showLeftTime: {
      type: Boolean,
      default: true,
    },
    showPromisedTime: {
      type: Boolean,
      default: true,
    },
    showOrderNumber: {
      type: Boolean,
      default: false,
    },
    orderNumber: {
      type: String,
      default: '',
    },
    setBorderRadius: {
      type: Boolean,
      default: false,
    },
    onTime: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      interval: null,
      intervalTime: 1000,
      leftTime: 0,
    }
  },
  computed: {
    getPromisedTime() {
      return this.isMap && this.date ? this.date.split(' ')[1] : this.date
    },
    getClass() {
      if (this.isMap || this.isTable) {
        let countdownClass = ''
        if (this.isMapTooltip === true) {
          countdownClass += 'countdown--is-map-tooltip p-1'
        }
        if (this.leftTime <= 0) {
          countdownClass += ' below-0'
        } else if (this.leftTime <= 30) {
          countdownClass += ' below-30'
        } else if (this.leftTime <= 60) {
          countdownClass += ' below-60'
        } else if (this.leftTime === 'Brak' || this.leftTime > 60) {
          countdownClass += ' none-or-over-60'
        }
        return countdownClass
      } else {
        if (this.leftTime <= 0) {
          return 'overtime'
        }
      }
    },
  },
  mounted() {
    if (this.date) {
      this.calcTimeLeft()
      this.interval = setInterval(() => {
        this.calcTimeLeft()
      }, this.intervalTime)
    } else {
      this.leftTime = 'Brak'
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
    this.interval = null
  },
  methods: {
    calcTimeLeft() {
      const now = this.$moment()
      const promised = this.$moment(this.date, 'DD-MM-YYYY HH:mm:ss')
      this.leftTime = promised.diff(now, 'minutes')
    },
  },
}
</script>

<style lang="scss" scoped>
.countdown {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 2px;
  height: 36px;
  padding: 4px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &--is-table {
    height: 24px;
    padding: 4px 6px;
    min-width: 32px;
    width: max-content;
    .countdown__left-minutes {
      font-size: 14px;
    }
  }
  &--is-map-tooltip {
    border-radius: 2px 0 0 2px;
  }
  &__left-minutes {
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    white-space: nowrap;
  }
  .promise-time {
    font-size: 12px;
    line-height: 13px;
  }

  &.overtime {
    color: red;
  }

  &.below-0 {
    color: #fff;
    background-color: $red;
    text-align: center;
    .countdown-ontime {
      color: $red;
    }
  }
  &.below-30 {
    color: #31373d;
    background-color: $yellow;
    text-align: center;
    .countdown-ontime {
      color: $yellow;
    }
  }
  &.below-60 {
    color: #fff;
    background-color: $green-dark;
    text-align: center;
    .countdown-ontime {
      color: $green-dark;
    }
  }
  &.none-or-over-60 {
    color: #fff;
    background-color: $gray-600;
    text-align: center;
    .countdown-ontime {
      color: $gray-600;
    }
  }
}
.border-radius-5 {
  border-radius: 5px;
}
.countdown-ontime {
  position: absolute;
  width: 17px;
  height: 17px;
  bottom: -7px;
  left: -7px;
  background-color: #fff;
  border-radius: 50%;
}
</style>

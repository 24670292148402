<template>
  <OrdersMapPapu3 v-if="$hasModuleAccess(MODULES.papu_3)" />
  <OrdersMap v-else />
</template>

<script>
import OrdersMap from './_components/OrdersMap'
import OrdersMapPapu3 from '@/views/papu-3/orders-map/components/OrdersMap'

export default {
  name: 'CouriersMap',
  components: {
    OrdersMap,
    OrdersMapPapu3,
  },
}
</script>


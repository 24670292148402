import { EmployeeEditState } from './index'
import { User } from '@/types/interface'

const setUser = (state: EmployeeEditState, user: User) => {
  state.user = user
}
const setUserId = (state: EmployeeEditState, id: number) => {
  state.userId = id
}

export default {
  setUser,
  setUserId,
}

import { API } from '@/services/api/api'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import { ShiftsCurrentState } from './index'
import Types from '@/types/interface'

const getCurrentShifts = async (
  { commit }: ActionContext<ShiftsCurrentState, RootState>,
  payload: { loading?: boolean; filters: Types.GetShiftsCurrentFilters } = { filters: {} }
) => {
  const { loading, filters } = payload
  if (loading) commit('setCurrentShiftsRequest', 'loading')
  const { error, data } = await API.getShiftsCurrent(filters)
  if (!error) {
    commit('setCurrentShifts', data)
    commit('setCurrentShiftsRequest', 'success')
  } else {
    commit('setCurrentShiftsRequest', 'error')
  }
}

const startShift = async (
  { dispatch }: ActionContext<ShiftsCurrentState, RootState>,
  payload: { formData: Types.StartShiftData; onSuccess: () => void; onError: ({}) => void }
) => {
  const { formData, onSuccess, onError } = payload
  const { error }: { error: any } = await API.startShift(formData)
  if (!error) {
    if (onSuccess) onSuccess()
    dispatch('getCurrentShifts')
  } else {
    if (onError && error?.response?.data) onError(error?.response?.data)
  }
}

const finishShift = async (
  { dispatch }: ActionContext<ShiftsCurrentState, RootState>,
  payload: {
    formData: Types.FinishShiftData
    getCurrentShifts: boolean
    onSuccess: ({}) => void
    onError: ({}) => void
  }
) => {
  const { formData, onSuccess, onError, getCurrentShifts = true } = payload
  const { error, data }: { data: any; error: any } = await API.finishShift(formData)
  if (!error && data) {
    if (getCurrentShifts) dispatch('getCurrentShifts')
    if (onSuccess) onSuccess(data)
  } else {
    if (onError && error?.response?.data) onError(error?.response?.data)
  }
}

const createShiftEntry = async (
  { dispatch }: ActionContext<ShiftsCurrentState, RootState>,
  payload: { formData: Types.CreateShiftEntryData; onSuccess: () => void; onError: ({}) => void }
) => {
  const { formData, onSuccess, onError } = payload
  const { error }: { error: any } = await API.createShiftEntry(formData)
  if (!error) {
    if (onSuccess) onSuccess()
    dispatch('getCurrentShifts')
  } else {
    if (onError && error?.response.data) onError(error?.response.data)
  }
}

export default {
  getCurrentShifts,
  startShift,
  finishShift,
  createShiftEntry,
}

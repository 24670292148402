<template>
  <tbody>
    <template v-if="noData">
      <div class="table-no-data-info">
        <span>{{ text }}</span>
      </div>
    </template>
    <template v-else>
      <div v-if="loading" class="simple-loading">
        <i class="fas fa-sync-alt fa-spin"></i>
      </div>
      <tr v-for="r in row" :key="r" class="table-row-placeholder">
        <td v-for="c in col" :key="c">
          <div class="placeholder-anim"></div>
        </td>
      </tr>
    </template>
  </tbody>
</template>

<script>
export default {
  name: 'TablePlaceholder',
  props: {
    noData: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: 'Brak danych.',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Number,
      default: 10,
    },
    col: {
      type: Number,
      default: 11,
    },
  },
}
</script>

<style lang="scss" scoped>
.table-row-placeholder {
  td {
    padding: 14px;
    div {
      height: 24px;
      border-radius: 3px;
    }
  }
}
.table-no-data-info {
  min-height: 200px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
}
</style>

<template>
  <div class="widget">
    <div class="widget-body-wrapper d-flex flex-column">
      <b-row>
        <b-col>
          <b-breadcrumb class="bg-white">
            <b-breadcrumb-item :to="{ name: 'settings' }">
              {{ $t('message.settings.settings') }}
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'settings' }" active>
              {{ $t('message.settings.billingPeriods') }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <vue-ctk-date-time-picker
            v-model="startDate"
            format="YYYY-MM-DD HH:mm"
            formatted="YYYY-MM-DD HH:mm"
            time-format="HH:mm"
            :minute-interval="15"
            no-button-now
            no-button
            :label="$t('message.summary.filterFromDate')"
            @is-hidden="_getBillingPeriods(true)"
            @input="$event === null ? _getBillingPeriods(true) : ''"
          />
        </b-col>
        <b-col cols="3">
          <vue-ctk-date-time-picker
            v-model="finishDate"
            format="YYYY-MM-DD HH:mm"
            formatted="YYYY-MM-DD HH:mm"
            time-format="HH:mm"
            :minute-interval="15"
            no-button-now
            no-button
            :label="$t('message.summary.filterToDate')"
            @is-hidden="_getBillingPeriods(true)"
            @input="$event === null ? _getBillingPeriods(true) : ''"
          />
        </b-col>
        <b-col cols="3">
          <select v-model="selectedEmployeeId" class="form-control" @change="_getBillingPeriods(true)">
            <option value="">{{ $t('message.billing.filterByEmployee') }}</option>
            <option v-for="(employee, index) in employees" :key="'employee_' + index" :value="employee.id">
              {{ employee.name }}
            </option>
          </select>
        </b-col>
        <b-col cols="1">
          <b-button variant="primary" @click="resetFilters">{{ $t('message.common.reset') }}</b-button>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-pagination-nav
            v-model="page"
            :number-of-pages="numberOfPages"
            :link-gen="billingPeriodsLinkGen"
            limit="5"
            use-router
            align="right"
            @input="pageChanged"
          >
          </b-pagination-nav>
        </b-col>
      </b-row>
      <div class="table-container">
        <b-table-simple responsive>
          <thead>
            <tr>
              <th scope="col">{{ $t('message.summary.name') }}</th>
              <th scope="col">{{ $t('message.billing.startedAt') }}</th>
              <th scope="col">{{ $t('message.billing.finishedAt') }}</th>
              <th scope="col">{{ $t('message.billing.manHours') }}</th>
              <th scope="col">{{ $t('message.billing.numberOfOrders') }}</th>
              <th scope="col">{{ $t('message.billing.kilometrage') }}</th>
              <th scope="col">{{ $t('message.billing.operationalBalance') }}</th>
              <th scope="col">{{ $t('message.billing.cashFromOrders') }}</th>
              <th scope="col">{{ $t('message.billing.bonuses') }}</th>
              <th scope="col">{{ $t('message.billing.penalties') }}</th>
              <th scope="col">{{ $t('message.billing.advances') }}</th>
              <th scope="col">{{ $t('message.billing.toBePaidOut') }}</th>
              <th scope="col">{{ $t('message.billing.details') }}</th>
            </tr>
          </thead>
          <template v-if="billingPeriodsRequest === 'loading'">
            <TablePlaceholder :row="10" :col="7" :loading="true" />
          </template>
          <tbody>
            <tr v-for="(billingPeriod, index) in billingPeriods" :key="'billing_period_' + index">
              <td>{{ billingPeriod.employee.name }}</td>
              <td>{{ billingPeriod.started_at }}</td>
              <td>{{ billingPeriod.finished_at }}</td>
              <td>
                {{ billingPeriod.man_hours }}
                <span v-if="billingPeriod.man_hours_salary !== null"
                  >({{ priceDecimal(billingPeriod.man_hours_salary) }} {{ currency }})</span
                >
              </td>
              <td>
                {{ billingPeriod.orders }}
                <span v-if="billingPeriod.orders_salary !== null"
                  >({{
                    priceDecimal(
                      priceDecimal(billingPeriod.orders_salary, false).add(priceDecimal(billingPeriod.zones_salary))
                    )
                  }}
                  {{ currency }})</span
                >
              </td>
              <td>
                <span v-if="billingPeriod.mileage !== null">{{ billingPeriod.mileage }}</span>
                <span v-if="billingPeriod.mileage_salary !== null"
                  >({{ priceDecimal(billingPeriod.mileage_salary) }} {{ currency }})</span
                >
              </td>
              <td>{{ billingPeriod.operational_balance }}</td>
              <td>{{ billingPeriod.orders_cash_balance }}</td>
              <td>{{ billingPeriod.bonuses }}</td>
              <td>{{ billingPeriod.penalties }}</td>
              <td>{{ billingPeriod.advances }}</td>
              <td>{{ billingPeriod.total_salary }} {{ currency }}</td>
              <td>
                <router-link
                  :to="{ name: 'billing-period-details', params: { id: billingPeriod.id } }"
                  tag="button"
                  class="btn btn-primary btn-block"
                  >{{ $t('message.billing.details') }}</router-link
                >
              </td>
            </tr>
          </tbody>
        </b-table-simple>
      </div>
      <!--<div class="modal fade" id="close-billing-period-modal" tabindex="-1" role="dialog">-->
      <!--<div class="modal-dialog" role="document">-->
      <!--<div class="modal-content">-->
      <!--<div class="modal-body">-->
      <!--<p>{{ $t('message.billing.confirmation') }} {{selectedBillingPeriodID}}</p>-->
      <!--</div>-->
      <!--<div class="modal-footer">-->
      <!--<button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('message.billing.cancel') }}</button>-->
      <!--<button type="submit" class="btn btn-success" @click="closeBillingPeriod(selectedBillingPeriodID)" data-dismiss="modal">{{ $t('message.billing.confirm') }}</button>-->
      <!--<button type="submit" class="btn btn-success" @click="closeBillingPeriod(selectedBillingPeriodID)" data-dismiss="modal">{{ $t('message.billing.confirmAndPrint') }}</button>-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->
    </div>
  </div>
</template>

<script>
import store from './_store'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import TablePlaceholder from '@/components/TablePlaceholder'
import { Decimal } from 'decimal.js'

const STORE_NAME = '_billingPeriods'

export default {
  name: 'BillingPeriods',
  components: {
    VueCtkDateTimePicker,
    TablePlaceholder,
  },
  data: function () {
    return {
      selectedBillingPeriodID: '',
      startDate: '',
      finishDate: '',
      selectedEmployeeId: '',
      page: 1,
      numberOfPages: 1,
    }
  },
  computed: {
    ...mapGetters(STORE_NAME, {
      billingPeriods: 'billingPeriods',
      billingPeriodsRequest: 'billingPeriodsRequest',
    }),
    ...mapGetters({
      employees: 'employees/employees',
      currency: 'currency',
    }),
  },
  // watch: {
  //   selectedEmployeeId (newVal) {
  //     console.log(newVal)
  //     this.$store.dispatch('_billingPeriods/getBillingPeriods', {
  //       filters: {
  //         employeeId: newVal
  //       }
  //     })
  //   }
  // },
  methods: {
    ...mapActions(STORE_NAME, ['getBillingPeriods']),
    resetFilters() {
      this.startDate = ''
      this.finishDate = ''
      this.selectedEmployeeId = ''
      this._getBillingPeriods(true)
    },
    pageChanged(pageNum) {
      this._getBillingPeriods(false, false)
    },
    billingPeriodsLinkGen(pageNum) {
      // generating link for every page-button of pagination
      return this.$route.fullPath.replace(/page=\d/, `page=${pageNum}`)
    },
    _getBillingPeriods(filterRequest = false, countNumberOfPages = true) {
      // fetches billing periods
      // if this method fires from filter, then filterRequest should be true
      let filters = { page: this.page }
      if (filterRequest) {
        this.page = 1 // resets the page of paginator
        filters = {
          employee_id: this.selectedEmployeeId,
          start_date: this.startDate,
          finish_date: this.finishDate,
          page: this.page,
        }
      }
      let onSuccess = null
      if (countNumberOfPages) {
        onSuccess = (periodsCount) => {
          // calculating number of pages of billingPeriods
          let numberOfPages = new Decimal(periodsCount).dividedBy(this.billingPeriods.length).ceil().toNumber()
          if (!numberOfPages) numberOfPages = 1
          this.numberOfPages = numberOfPages
        }
      }
      this.getBillingPeriods({ loading: true, filters: filters, onSuccess: onSuccess })
    },
  },
  beforeCreate() {
    if (!this.$store.state[STORE_NAME]) this.$store.registerModule(STORE_NAME, store)
  },
  created() {
    this.$store.dispatch('employees/getEmployees')
    this.selectedEmployeeId = this.$route.query.employee_id || ''
    this._getBillingPeriods()
  },
  beforeDestroy() {
    this.$store.unregisterModule(STORE_NAME)
  },
}
</script>

<template>
  <div id="search-orders" :class="['d-flex align-items-center search-orders', getClass]">
    <i v-if="!searchKeyword" class="fas fa-search search-orders__icon" />

    <span v-if="searchKeyword" class="search-orders__remove" @click="clear()">
      <i class="far fa-times-circle" />
    </span>

    <b-form-input
      id="search-order-input"
      v-model="searchKeyword"
      class="search-orders__input"
      :class="{ 'search-orders__input--filled': searchKeyword }"
      :disabled="disabled"
      @input="!isArchive ? search() : ''"
      @keydown.enter="search()"
      @keydown.esc="clear()"
      @focusin="focused = true"
      @focusout="focused = false"
    />

    <template v-if="isArchive">
      <b-form-select
        v-model="searchBy"
        :options="searchByOptions"
        class="mx-2 search-orders__select"
        :placeholder="$t('search_by') + '...'"
      >
        <template #first>
          <b-form-select-option :value="null" disabled>{{ $t('search_by') }}...</b-form-select-option>
        </template>
      </b-form-select>

      <b-button class="search-orders__button ml-2" variant="primary" :disabled="disabledSearchBtn" @click="search()">
        <span class="d-none d-md-inline">{{ $t('message.ordersList.search') }}</span>
        <i class="fas fa-search d-inline d-md-none" />
      </b-button>
    </template>
  </div>
</template>

<script>
import { orderMixins } from '@/mixins'

export default {
  name: 'SearchOrders',
  mixins: [orderMixins],
  props: {
    isArchive: {
      default: false,
      type: Boolean,
    },
    orders: {
      default: () => {
        return []
      },
      type: Array,
      required: true,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      searchKeyword: this.$route.query?.searchKeyword || '',
      searchBy: this.$route.query?.searchBy || null,
      focused: false,
      searchByOptions: [
        { text: this.$t('order_number'), value: 'number' },
        { text: this.$t('address'), value: 'customer_address' },
        { text: this.$t('city'), value: 'customer_city' },
        { text: this.$t('message.common.price'), value: 'price' },
        { text: this.$t('customer_name'), value: 'customer_name' },
        { text: this.$t('phone_number'), value: 'customer_phone' },
        { text: this.$t('message.menu.meal.name'), value: 'meal_name' },
      ],
    }
  },
  computed: {
    getClass() {
      return {
        '--is-archive': this.isArchive,
        'search-orders--extended': this.searchKeyword.length > 0,
        'search-orders--focused': this.focused,
      }
    },
    disabledSearchBtn() {
      return !this.searchKeyword || this.disabled || !this.searchBy
    },
  },
  mounted() {
    this.$eventBus.$once('clearSearch', () => {
      this.clear()
    })
  },
  destroyed() {
    this.$eventBus.$off('clearSearch')
  },
  methods: {
    search() {
      let searchResults = []
      if (this.searchKeyword) {
        let searchKeyword = this.searchKeyword.toLowerCase()
        // If Archive, emit searchKeyword for backend filtering
        // OrdersList/Map - search through already fetched actual orders (front)
        if (!this.isArchive) {
          this.orders.forEach((order) => {
            let addressSearch = this.getOrderFullAddress(order).toLowerCase().includes(searchKeyword)
            let phoneSearch = order.order_customer?.phone && order.order_customer.phone.includes(searchKeyword)
            let customerNameSearch =
              order.order_customer?.name && order.order_customer.name.toLowerCase().includes(searchKeyword)
            let mealsSearch = JSON.stringify(order.meals).toLowerCase().includes(searchKeyword)
            let priceSearch = order.price.includes(searchKeyword)
            let orderNumberSearch = false
            if (order.number) {
              let orderNumber = order.number.toString().toLowerCase()
              orderNumberSearch =
                order.number && (orderNumber.includes(searchKeyword) || `#${orderNumber}`.includes(searchKeyword))
            }
            if (
              addressSearch ||
              phoneSearch ||
              customerNameSearch ||
              mealsSearch ||
              priceSearch ||
              orderNumberSearch ||
              orderNumberSearch
            ) {
              searchResults.push(order.id)
            }
          })
        }
        this.$emit(
          'searching',
          this.isArchive ? { searchKeyword: this.searchKeyword, searchBy: this.searchBy } : searchResults
        )
      } else this.$emit('searching', this.isArchive ? { searchKeyword: null, searchBy: null } : null)
    },
    clear() {
      this.searchKeyword = ''
      this.searchBy = null
      this.search()
    },
  },
}
</script>

<style scoped lang="scss">
.search-orders {
  border-right: 1px solid $gray-400;
  width: 50px;
  grid-area: SearchOrders;
  transition: width 0.2s ease;

  &__icon,
  &__remove {
    position: absolute;
    text-align: center;
    z-index: 4;
  }

  &__icon {
    left: 18px;
    pointer-events: none;
    transition: all 0.2s ease;
  }

  &__remove {
    height: 100%;
    width: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;

    > i {
      font-size: 20px;
    }
  }

  &__input {
    border: none;
    height: 100%;
    z-index: 2;

    &:valid {
      padding-left: 40px;
    }

    &:active {
      box-shadow: inset 0 0 0px 3px rgba(59, 133, 255, 0.3);
    }
    &:focus {
      box-shadow: inset 0 0 4px 2px rgba(59, 133, 255, 0.3);
    }
  }

  &__select {
    height: 46px;
    width: fit-content;
  }

  &__button {
    width: 120px;
  }

  &--focused {
    .search-orders {
      &__input {
        padding-left: 40px;
      }
      &__icon {
        transform: translateX(-5px);
      }
    }
  }
}

.search-orders.--is-archive {
  width: 100%;

  .search-orders {
    &__icon {
      left: 30px;
    }
    &__input {
      border: 1px solid $input-border-color;
      border-radius: 4px;
      height: 46px;
    }
  }
}

@media (max-width: 576px) {
  .search-orders:not(.--is-archive) {
    .search-orders__input {
      &:focus {
        position: absolute;
        width: 100%;
        height: 50px;
        box-shadow: inset 0 0 4px 2px rgba(59, 133, 255, 0.3), 0 4px 16px rgba(0, 0, 0, 0.3);
      }
    }
  }
  .search-orders {
    &.--is-archive {
      width: 100%;
    }
  }
}
@media (min-width: 576px) {
  .search-orders {
    height: 100%;

    &--focused {
      width: 100px;
    }

    &--extended {
      width: 200px;
    }
  }
}
</style>

<template>
  <div>
    <b-button
      v-b-modal="'sourceChangeModal'"
      size="lg"
      block
      class="source-change-btn"
      :style="{
        'background-color': getSourceColor(value),
        color: getButtonTextColor(value),
      }"
      :disabled="disabled"
    >
      <span v-if="!noLabel">{{ label || $t('message.orderFormFull.orderSource') }}:</span>
      {{ value ? getSourceNameByBrandId : noChoosenLabel || $t('message.orderFormFull.choose') }}
    </b-button>
    <response-error-msg field="menu" :response-errors="responseErrors" />
    <source-change-modal
      :sources="getMenuSourcesByBrandId"
      :chosen-source-id="value"
      :show-all-btn="showAllBtn"
      @changeSource="changeMenuSource"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { menuSourcesMixins } from '@/mixins'
import { MENU_SOURCE_COLORS } from '@/common/constants'
import SourceChangeModal from './SourceChangeModal'

export default {
  name: 'SourceChange',
  components: { SourceChangeModal },
  mixins: [menuSourcesMixins],
  props: [
    'value',
    'changeMenuSource',
    'responseErrors',
    'noLabel',
    'showOnlyIntegrations',
    'noChoosenLabel',
    'label',
    'brandId',
    'showAllBtn',
    'disabled',
  ],
  data() {
    return {
      MENU_SOURCE_COLORS,
    }
  },
  computed: {
    ...mapGetters({
      menuSources: 'auth/getMenuSources',
      localizationPreferences: 'settings/localizationPreferences',
      currentBrandId: 'currentBrandId',
    }),
    getSourceNameByBrandId() {
      return (
        this.menuSources[this.brandId || this.currentBrandId]?.find((source) => source.source === parseInt(this.value))
          ?.name || ''
      )
    },
    getMenuSourcesByBrandId() {
      let sources = this.menuSources[this.brandId || this.currentBrandId]
      if (this.showOnlyIntegrations) {
        return sources.filter((source) => ![1, 3, 8].includes(source.source))
      }
      if (this.localizationPreferences && this.localizationPreferences.menu_source_manual_choice_required) {
        sources = sources.filter((src) => src.source !== 1)
      }
      return sources
    },
  },
}
</script>

<style  lang="scss">
.source-change-btn {
  border: 1px solid rgba(0, 0, 0, 0.15) !important;

  &--selected {
    border: 2px solid $primary !important;
  }
}
</style>

const setBillingPeriods = (state, billingPeriods) => {
  state.billingPeriods = billingPeriods
}
const setBillingPeriodsRequest = (state, status) => {
  state.billingPeriodsRequest = status
}
export default {
  setBillingPeriods,
  setBillingPeriodsRequest,
}

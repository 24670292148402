<template>
  <div class="edit-section">
    <h4 id="vatRate" :class="['label', { 'sr-only': labelHide }]">{{ $t('message.menu.vatRate') }}</h4>
    <b-dropdown
      class="vat-dropdown"
      :text="getVatRateText(value)"
      aria-describedby="vatRate"
      variant="primary"
      size="sm"
      :disabled="saving"
    >
      <b-dropdown-item v-for="(vat_rate, index) in getVatRates()" :key="index" @click="onClick(vat_rate.value)">
        {{ vat_rate.text }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { vatMixins } from '@/mixins/vatMixins'
import { mapGetters } from 'vuex'

const STORE = '_menuEdit'

export default {
  name: 'VatDropdown',
  mixins: [vatMixins],
  props: {
    value: {
      type: String,
      default: '',
    },
    labelHide: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(STORE, ['saving']),
  },
  methods: {
    onClick(vat) {
      this.$emit('input', vat)
      this.$emit('click')
    },
  },
}
</script>

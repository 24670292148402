const setOrderId = (state, orderId) => {
  state.orderId = orderId
}

const setSelectedBillPosition = (state, value) => {
  state.selectedBillPosition = value
}

const setSelectedSourceBillId = (state, value) => {
  state.selectedSourceBillId = value
}

const setSelectedToBillId = (state, value) => {
  state.selectedToBillId = value
}

const setBills = (state, items) => {
  const baseBill = (items && items.find((item) => item.main_bill)) || null

  state.allBills = items || []
  state.bills = (items && items.filter((item) => !item.main_bill)) || []
  state.baseBill = baseBill
  state.selectedSourceBillId = baseBill?.id
}

const setBillsRequestStatus = (state, status) => {
  state.billsRequestStatus = status
}

const setBillPayment = (state, { value, bill }) => {
  const billIndex = state.bills.indexOf(bill)
  state.bills[billIndex].payment = value
}

export default {
  setOrderId,
  setSelectedBillPosition,
  setSelectedSourceBillId,
  setSelectedToBillId,
  setBills,
  setBillsRequestStatus,
  setBillPayment,
}

<template>
  <b-modal
    ref="modal"
    :title="title"
    :visible="visible"
    no-close-on-backdrop
    no-close-on-esc
    @close="$emit('close')"
    @hidden="hide"
    @keyup.enter.native="accept"
  >
    <b-container class="bv-example-row">
      <b-row v-for="(choice, index) in finishOrderChoices" :key="choice.value" :value="choice.value" class="mb-3">
        <b-col>
          <b-form-checkbox
            v-model="form.finishOrderChoice"
            button
            name="printReceipt"
            :button-variant="getFinishOrderChoiceBtnVariant(choice.value)"
            :value="choice.value"
            unchecked-value=""
            size="lg"
          >
            <i v-if="form.finishOrderChoice === choice.value" class="fas fa-check-square"></i>
            <i v-else class="far fa-square"></i>
            <span>{{ choice.name }}</span>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <response-error-msg field="finished_type" :response-errors="responseErrors" />
      <template v-if="form.finishOrderChoice === 'finished'">
        <!--         <b-row v-if="order.pickup_type === PICKUP_TYPE_ON_SITE && !order.is_printed">-->
        <!--          <b-col>-->
        <!--            <b-form-group>-->
        <!--              <price-input v-model="form.serviceChargePercent"-->
        <!--                       :label="$t('message.orderDetails.finishOrderChoices.serviceCharge')"-->
        <!--                       appendText="%">-->
        <!--              </price-input>-->
        <!--            </b-form-group>-->
        <!--          </b-col>-->
        <!--        </b-row>-->
        <b-row v-if="!hasSplitBills" class="mb-3">
          <b-col>
            <b-form-group :label="$t('message.cart.paymentMethod')">
              <b-form-radio-group
                id="btnradios1"
                v-model="form.payment"
                buttons
                button-variant="outline-primary"
                size="lg"
                :options="paymentMethods"
                name="radiosBtnDefault"
              ></b-form-radio-group>
            </b-form-group>
            <response-error-msg field="payment" :response-errors="responseErrors" />
          </b-col>
        </b-row>
        <CashCalculator v-if="form.payment === 'cash'" v-model="form.cash" :total-price="calcPrice" class="mb-2" />
      </template>
      <response-error-msg field="non_field_errors" :response-errors="responseErrors" />
    </b-container>
    <b-button
      slot="modal-footer"
      ref="finishOkButton"
      :disabled="disabled"
      variant="success"
      size="lg"
      block
      @click="accept"
      ><kbd class="pb-0 pt-1 mr-3 bg-white text-dark">&crarr;</kbd>OK</b-button
    >
  </b-modal>
</template>

<script>
import { PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT, PICKUP_TYPE_DELIVERY, BILL_STATUSES } from '@/common/constants'
import { mapActions, mapGetters } from 'vuex'
import { responseErrorsHandler } from '@/mixins'
import CashCalculator from '@/components/CashCalculator'

const STORE = '_orderDetails'

function initData() {
  return {
    visible: false,
    disabled: false,
    form: {
      cash: 0,
      finishOrderChoice: '',
      payment: '',
      // serviceChargePercent: 0
    },
    PICKUP_TYPE_ON_SITE: PICKUP_TYPE_ON_SITE,
  }
}

export default {
  name: 'FinishOrderModal',
  components: {
    CashCalculator,
  },
  mixins: [responseErrorsHandler],
  props: ['paymentMethods'],
  data() {
    return initData()
  },
  computed: {
    ...mapGetters('_orderDetails', ['order', 'cart', 'hasSplitBills']),
    title() {
      return `${this.$t('message.orderDetails.finishOrder')} # ${this.order.number}`
    },
    finishOrderChoices() {
      const choices = [
        {
          name: this.$t('message.orderDetails.finishOrderChoices.canceled'),
          value: 'canceled',
          pickupTypes: [PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT, PICKUP_TYPE_DELIVERY],
        },
        {
          name: this.$t('message.orderDetails.finishOrderChoices.delivered'),
          value: 'delivered',
          pickupTypes: [PICKUP_TYPE_DELIVERY],
        },
        {
          name: this.$t('message.orderDetails.finishOrderChoices.notDelivered'),
          value: 'not delivered',
          pickupTypes: [PICKUP_TYPE_DELIVERY],
        },
        {
          name: this.$t('message.orderDetails.finishOrderChoices.pickedUp'),
          value: 'finished',
          pickupTypes: [PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT],
        },
      ]

      return choices.filter((choice) => choice.pickupTypes.includes(this.order.pickup_type))
    },
    calcRest() {
      return this.priceDecimal(this.form.cash - this.calcPrice)
    },
    calcPrice() {
      // return (parseFloat(this.cart.totalPrice) + parseFloat(this.cart.totalPrice) * (this.form.serviceCharge) / 100).toFixed(2)
      return this.priceDecimal(this.cart.totalPrice)
    },
  },
  methods: {
    ...mapActions({
      closeOrder: `${STORE}/closeOrder`,
    }),
    show() {
      this.visible = true
      this.form.finishOrderChoice = this.order.finished_type || ''
      this.form.payment = this.order.payment
    },
    hide() {
      let initdata = initData()
      this.visible = initdata.visible
      this.form.payment = initdata.form.payment
      this.form.finishOrderChoice = initdata.form.finishOrderChoice
      this.form.cash = initdata.form.cash
    },
    accept() {
      this.disabled = true
      let orderData = {
        finished_type: this.form.finishOrderChoice,
      }
      if (!this.hasSplitBills) {
        orderData.payment = this.form.payment
      }
      this.closeOrder({
        orderData: orderData,
        onSuccess: () => {
          this.clearResponseErrors({})
          this.disabled = false
          this.hide()
        },
        onError: (data) => {
          this.disabled = false
          if (data) this.setResponseErrors(data)
        },
        orderId: this.order.id,
      })
    },
    getFinishOrderChoiceBtnVariant(choice) {
      if (choice === 'canceled') return 'outline-danger'
      else return 'outline-primary'
    },
    keyListener(e) {
      if (e.key === 'Enter' && this.disabled === false) {
        this.accept()
      }
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler(val) {
        if (val) {
          setTimeout(() => {
            if (this.$refs['finishOkButton']) this.$refs['finishOkButton'].focus()
          }, 100)
          window.addEventListener('keydown', this.keyListener)
        } else {
          window.removeEventListener('keydown', this.keyListener)
        }
      },
    },
  },
  mounted() {
    this.$eventBus.$on('onShowFinishOrderModal', (payload = {}) => {
      this.show()
    })
    this.form.payment = this.order.payment
  },
  destroyed() {
    this.$eventBus.$off('onShowFinishOrderModal')
  },
}
</script>

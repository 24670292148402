import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { IPermissions } from '@/types/interface'

export interface SettingsState {
  localizationPreferences: any
  erestoPreferences: {
    logo: any
    favicon: any
    background: any
  }
  localizationPermissions: IPermissions
}

const getInitialState = (): SettingsState => {
  return {
    localizationPreferences: {},
    erestoPreferences: {
      logo: null,
      favicon: null,
      background: null,
    },
    localizationPermissions: {},
  }
}

const resetState = (state: SettingsState) => {
  Object.assign(state, getInitialState())
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations: {
    ...mutations,
    resetState,
  },
  actions,
}

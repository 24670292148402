<template>
  <div class="table-responsive">
    <table class="table table-bordered table-sm">
      <thead class="thead-dark">
        <tr>
          <th scope="col">{{ $t('message.shifts.no') }}</th>
          <th scope="col">{{ $t('message.shifts.date') }}</th>
          <th scope="col">{{ $t('message.shifts.address') }}</th>
          <th scope="col">{{ $t('message.shifts.deliveryArea') }}</th>
          <th scope="col">{{ $t('message.shifts.price') }}</th>
          <th scope="col">{{ $t('message.shifts.payment') }}</th>
          <th scope="col">{{ $t('message.shifts.status') }}</th>
          <th scope="col">{{ $t('message.shifts.finishedType') }}</th>
        </tr>
      </thead>
      <template v-if="shiftDetailsRequest === 'loading'">
        <TablePlaceholder :row="4" :col="4" :loading="true" />
      </template>
      <tbody v-else>
        <tr v-for="(order, index) in orders" :key="'balance_table_row_' + index">
          <td class="table-cell">{{ order.number }}</td>
          <td class="table-cell">{{ order.created }}</td>
          <td class="table-cell">{{ getOrderFullAddress(order) }}</td>
          <td class="table-cell">
            <span v-if="order.courier_delivery_area">{{ order.courier_delivery_area.name }}</span>
            <TooltipButton
              v-else
              :id="'noAreaAssignedTooltipButton_' + index"
              placement="right"
              button-class="smallTooltipButton-exclamation"
              class="no-zone-alert"
              variant="light"
            >
              <i class="fas fa-exclamation-circle"></i>
              <template #tooltipText>{{ $t('message.shifts.orderNotAssignedDA') }}</template>
            </TooltipButton>
          </td>
          <td class="table-cell">{{ priceDecimal(order.price) }}</td>
          <td class="table-cell">{{ order.payment_translated }}</td>
          <td class="table-cell">{{ order.status_translated }}</td>
          <td class="table-cell">{{ order.finished_type_translated }}</td>
        </tr>
        <tr v-if="orders.length === 0" class="table-empty-row">
          <td colspan="8">{{ $t('message.shifts.none') }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TablePlaceholder from '@/components/TablePlaceholder'
import { orderMixins } from '@/mixins'
import TooltipButton from '@/components/TooltipButton'

export default {
  name: 'ShiftOrdersTable',
  components: {
    TooltipButton,
    TablePlaceholder,
  },
  mixins: [orderMixins],
  data() {
    return {
      entriesTypes: {
        1: 'advance',
        2: 'penalty',
        3: 'bonus',
      },
    }
  },
  computed: {
    ...mapGetters('_shiftDetails', {
      shift: 'shift',
      shiftDetailsRequest: 'shiftDetailsRequest',
    }),
    employeeName() {
      return _.get(this.shift, 'employee.name')
    },
    orders() {
      return this.shift.orders || []
    },
  },
  methods: {
    // formatDate (date) {
    //   return this.$moment(date).format('DD-MM-YYYY HH:mm')
    // },
    getEntryValue(entry, cellType) {
      const { type } = entry
      if (cellType === type) return entry.amount
      else return ''
    },
  },
}
</script>

<style scoped lang="scss">
.summary-row {
  background-color: #f1f1f1;
  font-weight: bold;
  td {
    text-align: right;
  }
}
</style>

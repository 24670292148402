import store from '@/store'
import UserAuth from '@/views/auth/login/index.vue'
import Demo from '@/views/demo/index.vue'
import OrdersList from '@/views/orders-list/index.vue'
import OrdersSummary from '@/views/orders-summary/index.vue'
import OrderEdit from '@/views/order-edit/index.vue'
import Settings from '@/views/settings/index.vue'
import Integrations from '@/views/integrations/index.vue'
import EmployeesList from '@/views/employees/list/index.vue'
import EmployeeEdit from '@/views/employees/edit/index.vue'
import OrderDetails from '@/views/order-details/index.vue'
import OrdersMap from '@/views/orders-map/index.vue'
import OrderSplitBill from '@/views/orders/split-bill/index.vue'
import OrderSplitBillEdit from '@/views/orders/split-bill/edit.vue'
import Regions from '@/views/regions/index.vue'
import CurrentBillingPeriods from '@/views/billing-periods/current/index.vue'
import BillingPeriods from '@/views/billing-periods/list/index.vue'
import BillingPeriodDetails from '@/views/billing-periods/details/index.vue'
import CurrentShifts from '@/views/shifts/current/index.vue'
import Shifts from '@/views/shifts/list/index.vue'
import ShiftDetails from '@/views/shifts/details/index.vue'
import NotFound404 from '@/views/404.vue'
import Administration from '@/views/administration/index.vue'
import Erestaurant from '@/views/erestaurant/index.vue'
import MenuEdit from '@/views/menu/index.vue'
import Permissions from '@/views/permissions/index.vue'
import MealSetsList from '@/views/mealsets/list/index.vue'
import MealSetEdit from '@/views/mealsets/edit/index.vue'
import PasswordReset from '@/views/auth/password-reset/index.vue'
import ReceiptPrintersList from '@/views/receipt-printers/list/index.vue'
import ReceiptPrinterEdit from '@/views/receipt-printers/edit/index.vue'
import Help from '@/views/help.vue'
import Mapping from '@/views/mapping/index.vue'
import WorkstationsList from '@/views/workstations/list/index.vue'
import WorkstationEdit from '@/views/workstations/edit/index.vue'
import MobileSubpage from '@/layouts/MobileSubpage.vue'
import DefaultLayout from '@/layouts/Default.vue'
import ClosableLayout from '@/layouts/ClosableLayout.vue'
import KitchenSettings from '@/views/kitchen-settings/index.vue'

import { DEFAULT_ORDERS_VIEW } from '@/common/constants'
import { isMobile } from '@/utils/common'

function loggedInRedirect(to: any, from: any, next: any) {
  if (sessionStorage.getItem('loggedIn')) {
    if (parseInt(store.getters['settings/defaultOrdersListView']) === DEFAULT_ORDERS_VIEW.ORDERS_LIST) {
      next('/orders')
    } else {
      next('/map')
    }
  } else {
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'auth',
    component: UserAuth,
    props: true,
    beforeEnter: (to: any, from: any, next: any) => {
      loggedInRedirect(to, from, next)
    },
  },
  {
    path: '/demo',
    name: 'demo',
    component: Demo,
    props: true,
    beforeEnter: (to: any, from: any, next: any) => {
      loggedInRedirect(to, from, next)
    },
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: PasswordReset,
    beforeEnter: (to: any, from: any, next: any) => {
      loggedInRedirect(to, from, next)
    },
  },
  {
    path: '/password-reset/confirm/:uid/:token/',
    name: 'password-reset-confirm',
    component: PasswordReset,
    props: true,
    beforeEnter: (to: any, from: any, next: any) => {
      loggedInRedirect(to, from, next)
    },
  },
  {
    path: '/logout',
    name: 'logout',
    redirect: '/rest-auth/logout',
  },
  {
    path: '/orders',
    name: 'orders-list',
    component: OrdersList,
  },
  {
    path: '/orders/:id/split-bill',
    name: 'orders-split-bill',
    component: OrderSplitBill,
    meta: {
      withMainMenu: false,
      cache: true,
    },
    children: [
      {
        path: ':billId/edit',
        name: 'orders-split-bill-edit',
        component: OrderSplitBillEdit,
        meta: {
          withMainMenu: false,
          cache: true,
        },
      },
    ],
  },
  {
    path: '/orders/archive/:filters?',
    name: 'orders-archive',
    component: OrdersList,
  },
  {
    path: '/orders/summary',
    name: 'orders-summary',
    component: OrdersSummary,
  },
  {
    path: '/new-order',
    name: 'new-order',
    component: OrderEdit,
  },
  {
    path: '/order/:id/edit',
    name: 'edit-order',
    component: OrderEdit,
    props: true,
  },
  {
    path: '/order/:id',
    name: 'order',
    component: OrderDetails,
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
  },
  {
    path: '/settings/employees-list',
    name: 'employees-list',
    component: EmployeesList,
  },
  {
    path: '/settings/employee/:id?',
    name: 'employee',
    component: EmployeeEdit,
  },
  {
    path: '/settings/current-billing-periods',
    name: 'current-billing-periods',
    component: CurrentBillingPeriods,
  },
  {
    path: '/settings/billing-periods',
    name: 'billing-periods',
    component: BillingPeriods,
  },
  {
    path: '/settings/billing-period-details/:id',
    name: 'billing-period-details',
    component: BillingPeriodDetails,
  },
  {
    path: '/settings/current-shifts',
    name: 'current-shifts',
    component: CurrentShifts,
  },
  {
    path: '/settings/shifts',
    name: 'shifts',
    component: Shifts,
  },
  {
    path: '/settings/shift-details/:id',
    name: 'shift-details',
    component: ShiftDetails,
  },
  {
    path: '/settings/administration',
    name: 'administration',
    component: Administration,
  },
  {
    path: '/settings/erestaurant',
    name: 'erestaurant',
    component: Erestaurant,
  },
  {
    path: '/settings/menu-edit',
    name: 'menu-edit',
    component: MenuEdit,
    meta: {
      layout: ClosableLayout,
    },
  },
  {
    path: '/settings/permissions',
    name: 'permissions',
    component: Permissions,
  },
  {
    path: '/settings/mealsets-list',
    name: 'mealsets-list',
    component: MealSetsList,
  },
  {
    path: '/settings/mealset/:id?',
    name: 'mealset-edit',
    component: MealSetEdit,
    props: true,
    meta: {
      layout: isMobile() ? MobileSubpage : DefaultLayout,
    },
  },
  {
    path: '/settings/receipt-printers-list',
    name: 'receipt-printers-list',
    component: ReceiptPrintersList,
  },
  {
    path: '/settings/receipt-printer/:id?',
    name: 'receipt-printer-edit',
    component: ReceiptPrinterEdit,
    props: true,
  },
  {
    path: '/settings/integrations',
    name: 'integrations',
    component: Integrations,
    props: true,
  },
  {
    path: '/settings/mapping',
    name: 'mapping',
    component: Mapping,
    props: true,
    meta: {
      layout: ClosableLayout,
    },
  },
  {
    path: '/settings/workstations',
    name: 'workstations-list',
    component: WorkstationsList,
  },
  {
    path: '/settings/workstation/:id?',
    name: 'workstation-edit',
    component: WorkstationEdit,
  },
  {
    path: '/settings/kitchen',
    name: 'kitchen-settings',
    component: KitchenSettings,
  },
  {
    path: '/map',
    name: 'couriers-map',
    component: OrdersMap,
  },
  {
    path: '/regions',
    name: 'regions',
    component: Regions,
    meta: {
      layout: isMobile() ? MobileSubpage : DefaultLayout,
    },
  },
  {
    path: '/help',
    name: 'help',
    component: Help,
  },
  {
    path: '/not-found',
    name: 'notFound404',
    component: NotFound404,
  },
  
  {
    path: '*',
    redirect: '/not-found',
  },
]

export default routes

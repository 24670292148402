import { WorkstationsState } from './index'
import { ICategory, IWorkStation } from '@/types/interface'

const setWorkstations = (state: WorkstationsState, workstations: IWorkStation[]) => {
  state.workstations = workstations
}
const setWorkstation = (state: WorkstationsState, workstation: IWorkStation) => {
  state.workstation = workstation
}
const setWorkstationActiveStatus = (
  state: WorkstationsState,
  { id, is_active }: { id: number; is_active: boolean }
) => {
  state.workstations = state.workstations.map((ws) => {
    if (ws.id === id) ws.is_active = is_active
    return ws
  })
}
const setMealCategories = (state: WorkstationsState, mealCategories: ICategory[]) => {
  state.mealCategories = mealCategories
}
export default {
  setWorkstations,
  setWorkstation,
  setWorkstationActiveStatus,
  setMealCategories,
}

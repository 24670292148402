<template>
  <vue-ctk-date-time-picker
    :id="id + '_DateTimePicker'"
    v-model="time_picked"
    time-format="HH:mm"
    format="HH:mm"
    formatted="HH:mm"
    :disable-date="true"
    :minute-interval="15"
    :label="$t('message.common.pickTime')"
    only-time
    @formatted-value="changed()"
    @validate="changed"
    @is-shown="onShow"
  />
</template>

<script>
import { responseErrorsHandler } from '@/mixins'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import { isMobile } from '@/utils/common'
export default {
  name: 'DateTimePicker',
  components: {
    VueCtkDateTimePicker,
  },
  mixins: [responseErrorsHandler],
  props: ['time', 'id'],
  data: function () {
    return {
      time_picked: this.time || '',
      isSubmitted: false,
      isMobile,
    }
  },
  methods: {
    changed() {
      this.$emit('input', this.time_picked)
      this.isSubmitted = true
    },
    onShow() {
      this.isSubmitted = false
    },
  },
}
</script>

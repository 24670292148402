<template>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <a :href="externalUrl" tag="button" target="_blank" :class="['btn btn-block btn-lg btn-settings', { disabled }]">
      <slot name="icon">
        <i :class="`fas fa-${icon} fa-fw`" />
      </slot>
      <span>{{ text }}</span>
      <div class="btn-settings__external-icon">
        <span class="mr-2">{{ description }}</span>
        <i class="fas fa-external-link-alt" />
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'SettingsButton',
  props: {
    externalUrl: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
.btn-settings {
  &__external-icon {
    margin-left: auto;
    font-size: 12px;
    opacity: 0.5;
    i.fa-external-link-alt {
      font-size: 15px;
    }
  }
}
</style>

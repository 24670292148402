const setOrders = (state, orders) => {
  state.orders = orders
}

const setOrdersRequest = (state, type) => {
  state.ordersRequest = type
}

const setCouriers = (state, couriers) => {
  state.couriers = couriers
}

const setEmployees = (state, employees) => {
  state.employees = employees
}

const updateOrderList = (state, order) => {
  let found = state.orders.find((item) => item.id === order.id)
  if (found !== undefined) {
    Object.assign(found, order)
  }
}

const removeOrderFromList = (state, orderId) => {
  let index = state.orders.findIndex((item) => item.id === orderId)
  if (index !== -1) {
    state.orders.splice(index, 1)
  }
}

const setOrdersInRegeocode = (state, orderIds) => {
  state.ordersInRegeocode = orderIds
}

const removeOrdersInRegeocodeByIds = (state, ordersIds) => {
  state.ordersInRegeocode = state.ordersInRegeocode.filter((orderId) => !ordersIds.includes(orderId))
}

const setPrimaryFilter = (state, payload) => {
  const { filter, value } = payload
  state.primaryFilters[filter] = value
}

export default {
  setOrders,
  setCouriers,
  setEmployees,
  setOrdersRequest,
  updateOrderList,
  removeOrderFromList,
  setOrdersInRegeocode,
  removeOrdersInRegeocodeByIds,
  setPrimaryFilter,
}

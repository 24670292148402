<template>
  <div class="menu-elements-container">
    <b-button-group style="flex: 1">
      <FilterButton
        v-for="(menuButton, index) in menuButtons"
        :key="index"
        :is-active="menuElement === menuButton.element"
        :text="menuButton.text"
        :on-click="() => viewElements(menuButton.element, index)"
        :no-count="true"
        class="filter__btn"
      />
    </b-button-group>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import FilterButton from '@/components/orders/FilterButton'

const STORE = '_menuEdit'

export default {
  name: 'MenuList',
  components: {
    FilterButton,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      menuSources: 'auth/getMenuSources',
      currentBrandId: 'currentBrandId',
      categories: 'categories',
      multiChoiceGroups: 'multiChoiceGroups',
      singleChoiceGroups: 'singleChoiceGroups',
      menuElement: '_menuEdit/menuElement',
    }),
    menuButtons() {
      return [
        { text: this.$t('message.menu.categories.title'), element: 'categories', count: this.categoriesCount },
        { text: this.$t('message.menu.sizes.title'), element: 'sizes', count: this.sizesCount },
        {
          text: this.$t('message.menu.choiceGroups.multiChoiceGroupTitle'),
          element: 'multi_choice_groups',
          count: this.multiChoiceGroupsCount,
        },
        {
          text: this.$t('message.menu.choiceGroups.singleChoiceGroupTitle'),
          element: 'single_choice_groups',
          count: this.singleChoiceGroupsCount,
        },
        { text: this.$t('message.menu.meal.title'), element: 'meals', count: this.mealsCount },
      ]
    },
    categoriesCount() {
      return this.categories?.length || 0
    },
    multiChoiceGroupsCount() {
      return this.multiChoiceGroups?.length || 0
    },
    singleChoiceGroupsCount() {
      return this.singleChoiceGroups?.length || 0
    },
    sizesCount() {
      return this.categories?.reduce((a, b) => a + (b?.sizes.length || 0), 0)
    },
    mealsCount() {
      return this.categories?.reduce((a, b) => a + (b?.sizes[0]?.meals?.length || 0), 0)
    },
  },
  methods: {
    ...mapMutations(STORE, ['setMenuElement', 'clearForm']),
    viewElements(element) {
      this.setMenuElement(element)
      this.clearForm()
    },
  },
}
</script>

<style scoped lang="scss">
.menu-elements-container {
  display: flex;
  overflow-x: auto;
  width: 100%;
  min-height: 57px;

  > .btn-group {
    min-height: 42px;
  }
}
.filter {
  &__btn {
    background-color: #fff;

    @include media-breakpoint-down(sm) {
      min-width: auto;
    }
  }
}
</style>

<template>
  <div class="view-container h-100">
    <b-container class="d-flex flex-column h-100">
      <b-row align-v="center" align-h="between">
        <b-col>
          <b-breadcrumb class="pl-4 mb-1">
            <b-breadcrumb-item :to="{ name: 'settings' }">{{ $t('message.settings.settings') }} </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'workstations-list' }"
              >KDS - {{ $t('message.workstations.title') }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{ $t('message.settings.editWorkstation') }} </b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
      </b-row>
      <div class="widget">
        <b-overlay :show="workstationLoading" spinner-variant="primary" spinner-small class="h-100">
          <div class="widget-body-wrapper p-4">
            <b-row>
              <b-col xs="12" md="8" lg="6" class="mx-auto">
                <b-form>
                  <b-row>
                    <b-col cols="8">
                      <b-form-group>
                        <label>{{ $t('message.workstationEdit.form.name') }}</label>
                        <b-form-input
                          id="workstation-name-input"
                          v-model="form.name"
                          v-validate="'required'"
                          type="text"
                          class="form-control"
                          name="name"
                          :placeholder="`${$t('message.workstationEdit.form.name')} *`"
                        />
                        <input-error-msg field="name" :_errors="errors" :response-errors="responseErrors" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="4"
                      ><b-form-group>
                        <label>{{ $t('message.workstationEdit.form.color') }}</label>
                        <b-form-input
                          id="workstation-color-input"
                          v-model="form.color"
                          v-validate="'required'"
                          type="color"
                          class="form-control"
                          name="color"
                          :placeholder="`${$t('message.workstationEdit.form.color')} *`"
                        />
                        <input-error-msg
                          field="color"
                          :_errors="errors"
                          :response-errors="responseErrors"
                        /> </b-form-group
                    ></b-col>
                  </b-row>

                  <b-form-group>
                    <label>{{ $t('message.workstationEdit.form.mealCategories') }}</label>
                    <multiselect
                      v-model="selectedMealCategories"
                      :options="mealCategoriesOptions"
                      :multiple="true"
                      :close-on-select="false"
                      :searchable="true"
                      :loading="mealCategoriesLoading"
                      track-by="id"
                      label="name"
                      selected-label="✓"
                      select-label=""
                      deselect-label="×"
                      group-values="categories"
                      group-label="brandName"
                      :placeholder="$t('message.workstationEdit.form.selectMealCategories')"
                      @input="setFormMealCategories"
                    />
                    <input-error-msg field="meal_categories" :_errors="errors" :response-errors="responseErrors" />
                  </b-form-group>
                  <b-button
                    v-if="!$route.params.id"
                    class="mt-5"
                    block
                    variant="success"
                    :disabled="saveLoading"
                    @click.prevent="_createWorkstation()"
                    ><b-spinner v-if="saveLoading" small></b-spinner
                    ><span>{{ $t('message.workstationEdit.addButton') }}</span>
                  </b-button>
                  <b-button
                    v-else
                    class="mt-5"
                    block
                    variant="success"
                    :disabled="saveLoading"
                    @click.prevent="_updateWorkstation()"
                    ><b-spinner v-if="saveLoading" small></b-spinner
                    ><span>{{ $t('message.workstationEdit.saveButton') }}</span>
                  </b-button>
                  <b-alert v-if="errorMessage" show variant="danger" class="mt3">
                    {{ errorMessage }}
                  </b-alert>
                </b-form>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import store from '@/views/workstations/_store'
import { responseErrorsHandler } from '@/mixins'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: 'WorkstationEdit',
  components: { Multiselect },
  mixins: [responseErrorsHandler],
  props: {
    id: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      selectedMealCategories: [],
      form: {
        name: '',
        color: '',
        meal_categories: [],
      },
      mealCategoriesLoading: false,
      workstationLoading: false,
      saveLoading: false,
      errorMessage: null,
    }
  },
  computed: {
    ...mapState(store.name, ['workstation', 'mealCategories']),
    mealCategoriesOptions() {
      return this.mealCategories
    },
  },
  watch: {
    workstation(workstationData) {
      if (this.$route.params.id) {
        this.fillForm(workstationData)
      }
    },
    mealCategories() {
      if (this.$route.params.id) {
        this.fillSelectedMealCategories(this.form.meal_categories)
      }
    },
  },
  beforeCreate() {
    if (!this.$store.state[store.name]) this.$store.registerModule(store.name, store)
  },
  created() {
    this.workstationLoading = !!this.$route.params.id
    this.setMealCategories([])
  },
  async mounted() {
    this.mealCategoriesLoading = true
    await this.getMealCategories()
    this.mealCategoriesLoading = false
    if (this.$route.params.id) {
      await this.getWorkstation(this.$route.params.id)
    }
    this.workstationLoading = false
  },
  beforeDestroy() {
    this.resetState()
    //this.$store.unregisterModule(store.name)
  },
  methods: {
    ...mapActions(store.name, ['createWorkstation', 'getWorkstation', 'updateWorkstation', 'getMealCategories']),
    ...mapMutations(store.name, ['setWorkstation', 'resetState', 'setMealCategories']),
    async _createWorkstation() {
      this.saveLoading = true
      const result = await this.$validator.validate()
      if (result) {
        const { error } = await this.createWorkstation(this.form)
        if (!error) {
          this.clearResponseErrors({})
          this.$router.push({ name: 'workstations-list' })
        } else {
          this.setResponseErrors(error?.response?.data)
        }
      }
      this.saveLoading = false
    },
    fillForm(data) {
      if (!data) return false
      this.form.name = data.name
      this.form.color = data.color
      this.form.meal_categories = data.meal_categories
      this.fillSelectedMealCategories(data.meal_categories)
    },
    fillSelectedMealCategories(meal_categories) {
      let selected = []
      meal_categories.forEach((id) => {
        this.mealCategories.forEach((brand) =>
          brand.categories.forEach((cat) => {
            if (cat.id === id) selected.push(cat)
          })
        )
      })
      this.selectedMealCategories = selected
    },
    setFormMealCategories(value) {
      this.form.meal_categories = value.map((v) => v.id)
    },
    async _updateWorkstation() {
      this.saveLoading = true
      const result = await this.$validator.validate()
      if (result) {
        const { error } = await this.updateWorkstation({ id: this.workstation.id, data: this.form })
        if (!error) {
          this.clearResponseErrors()
          this.$router.push({ name: 'workstations-list' })
        } else if (error?.response?.data) {
          this.setResponseErrors(error?.response?.data)
        }
      }
      this.saveLoading = false
    },
  },
}
</script>

<style lang="scss">

</style>

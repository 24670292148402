export const taxIdMixins = {
  methods: {
    customerTaxIDformatter(taxId) {
      // taxId - string name of taxId property
      // Removes non digit and non-dash characters @input
      let value = _.get(this, taxId).replace(/[^\d\-]/g, '')
      // Needed for model to be updated
      this.$nextTick(() => {
        _.set(this, taxId, value)
      })
    },
  },
}

<template>
  <b-modal
    ref="modal"
    :title="title"
    :visible="visible"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    @keyup.enter.native="save"
  >
    <b-container>
      <!-- Start shift form-->
      <b-alert v-if="pendingOrdersWarning" variant="warning" show>{{
        $t('message.billing.pendingOrdersWarning')
      }}</b-alert>
      <template v-if="actionType === 'start'">
        <div class="form-group">
          <label for="started_at">
            {{ $t('message.billing.shiftStart') }}<span class="text-danger ml-1">*</span>
          </label>
          <vue-ctk-date-time-picker
            id="started_at"
            key="started_at"
            v-model="form.started_at"
            v-validate="'required'"
            name="started_at"
            format="YYYY-MM-DD HH:mm"
            formatted="YYYY-MM-DD HH:mm"
            time-format="HH:mm"
            :minute-interval="5"
            no-button-now
            no-button
            no-clear-button
            :label="$t('message.billing.shiftStart')"
            @keyup.enter.stop
          />
          <input-error-msg field="started_at" :_errors="errors" :response-errors="responseErrors" />
        </div>
        <div v-if="user.role === 'courier' && isBillingPerKmActive('salary_config')" class="form-group">
          <price-input
            id="initialMileage"
            v-model="form.initial_mileage"
            append-text="km"
            :label="$t('message.billing.initialMileage')"
            :required-at-label="true"
            @keyup.enter.stop
          >
          </price-input>
          <input-error-msg field="initial_mileage" :_errors="errors" :response-errors="responseErrors" />
        </div>
        <div class="form-group">
          <price-input
            id="initialMileage"
            v-model="form.operational_cash"
            :append-text="currency"
            :label="$t('message.billing.operationalCash')"
            :required-at-label="true"
            @keyup.enter.stop
          >
          </price-input>
          <response-error-msg field="operational_cash" :response-errors="responseErrors" />
        </div>
      </template>
      <!-- end of from -->

      <!-- Finish shift form-->
      <template v-if="actionType === 'finish'">
        <div class="form-group">
          <label for="started_at"
            >{{ $t('message.billing.shiftStart') }}
            <span class="text-danger ml-1">*</span>
          </label>
          <vue-ctk-date-time-picker
            id="started_at"
            key="started_at"
            v-model="form.started_at"
            name="started_at"
            format="YYYY-MM-DD HH:mm"
            formatted="YYYY-MM-DD HH:mm"
            time-format="HH:mm"
            :minute-interval="5"
            no-button-now
            no-button
            no-clear-button
            :label="$t('message.billing.shiftStart')"
            @keyup.enter.stop
          />
        </div>
        <div class="form-group">
          <label for="finished_at">
            {{ $t('message.billing.shiftFinish') }}<span class="text-danger ml-1">*</span>
          </label>
          <vue-ctk-date-time-picker
            id="finished_at"
            key="finished_at"
            v-model="form.finished_at"
            v-validate="'required'"
            name="finished_at"
            format="YYYY-MM-DD HH:mm"
            formatted="YYYY-MM-DD HH:mm"
            time-format="HH:mm"
            :minute-interval="5"
            no-button-now
            no-button
            no-clear-button
            :label="$t('message.billing.shiftFinish')"
            @is-hidden="changeFinishedAt"
            @keyup.enter.stop
          />
          <input-error-msg field="finished_at" :_errors="errors" :response-errors="responseErrors" />
        </div>
        <div v-if="user.role === 'courier' && isBillingPerKmActive('salary_config')" class="form-group">
          <price-input
            id="initialMileage"
            v-model="form.initial_mileage"
            append-text="km"
            :label="$t('message.billing.initialMileage')"
            :required-at-label="true"
            @keyup.enter.stop
          >
          </price-input>
          <input-error-msg field="initial_mileage" :_errors="errors" :response-errors="responseErrors" />
        </div>
        <div v-if="user.role === 'courier' && isBillingPerKmActive('last_shift')" class="form-group">
          <price-input
            id="finalMileage"
            v-model="form.final_mileage"
            append-text="km"
            :label="$t('message.billing.finalMileage')"
            :required-at-label="true"
            @keyup.enter.stop
          >
          </price-input>
          <input-error-msg field="final_mileage" :_errors="errors" :response-errors="responseErrors" />
        </div>
        <div v-if="!form.shiftId" class="form-group">
          <price-input
            id="returnedOperationalCash"
            v-model="form.returned_operational_cash"
            :append-text="currency"
            :label="$t('message.billing.returnedOperationalCash')"
            :required-at-label="true"
            @keyup.enter.stop
          >
          </price-input>
          <response-error-msg field="returned_operational_cash" :response-errors="responseErrors" />
        </div>
        <div v-if="!form.shiftId" class="form-group">
          <price-input
            id="returnedCashForOrders"
            v-model="form.returned_cash_for_orders"
            :append-text="currency"
            :label="$t('message.billing.returnedCashForOrders')"
            :required-at-label="true"
            @keyup.enter.stop
          >
          </price-input>
          <response-error-msg field="returned_cash_for_orders" :response-errors="responseErrors" />
        </div>
        <div class="form-group">
          <input id="close-period" v-model="form.close_billing_period" type="checkbox" />
          <label for="close-period">{{ $t('message.billing.closeBillingPeriod') }}</label>
        </div>
      </template>
      <response-error-msg field="non_field_errors" :response-errors="responseErrors" />
      <response-error-msg field="employee_id" :response-errors="responseErrors" />
    </b-container>
    <div slot="modal-footer">
      <button type="button" class="btn btn-secondary" @click="hide">{{ $t('message.billing.cancel') }}</button>
      <button type="button" class="btn btn-success ml-3" @click="save">
        <kbd class="pb-0 pt-1 mr-2 bg-white text-dark">&crarr;</kbd> {{ $t('message.billing.save') }}
      </button>
    </div>
    <future-date-confirm-modal @cancel="changeFinishedAt"> </future-date-confirm-modal>
  </b-modal>
</template>

<script>
/* eslint-disable camelcase */
import store from './../_store'
import { mapActions, mapGetters } from 'vuex'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import { responseErrorsHandler } from '@/mixins'
import PriceInput from '@/components/PriceInput'
import FutureDateConfirmModal from '@/components/FutureDateConfirmModal'
import { COMMON_DATETIME_INPUT_FORMAT, POS_API_DATETIME_INPUT_FORMAT } from '@/common/constants'

export default {
  name: 'ShiftControlModal',
  components: {
    VueCtkDateTimePicker,
    PriceInput,
    FutureDateConfirmModal,
  },
  mixins: [responseErrorsHandler],
  data() {
    return {
      visible: false,
      user: {},
      actionType: '',
      form: {
        shiftId: false,
      },
      old_finished_at: null,
      responseErrors: {},
      onSuccess: null,
    }
  },
  computed: {
    ...mapGetters(['currency']),
    title() {
      if (this.actionType === 'start') {
        return `${this.$t('message.billing.startDay')}: ${this.user.name}`
      } else {
        return `${this.$t('message.billing.endDay')}: ${this.user.name}`
      }
    },
    isBillingPerKmActive() {
      return (from) => {
        return _.get(this.user, `${from}.per_km_is_active`)
      }
    },
    hasTerminalNumber() {
      return _.get(this.user, 'last_shift.terminal_number')
    },
    pendingOrdersWarning() {
      return this.user && this.user.last_shift && this.user.last_shift.show_pending_orders_warning
    },
  },
  methods: {
    ...mapActions({
      startShift: '_shiftsCurrent/startShift',
      // finishShift: '_shiftsCurrent/finishShift'
    }),
    show() {
      this.visible = true
    },
    hide() {
      this.resetForm()
      this.visible = false
    },
    finishShift(request) {
      let storeName = ''
      if (this.$route.name === 'current-shifts' || this.$route.name === 'billing-period-details')
        storeName = '_shiftsCurrent'
      else if (this.$route.name === 'shift-details') storeName = '_shiftDetails'
      else storeName = '_shifts'
      this.$store.dispatch(`${storeName}/finishShift`, request)
    },
    save() {
      this.$validator.validate().then((result) => {
        const _valid = this.validateForm()
        if (!_valid) result = _valid
        if (result) {
          const request = {
            formData: this.form,
            onSuccess: (response) => {
              this.hide()
              this.clearResponseErrors({})
              if (this.onSuccess) this.onSuccess()
              if (this.actionType === 'finish' && response.period_id && response.period_id !== null) {
                this.$router.push({ name: 'billing-period-details', params: { id: response.period_id } })
              }
              if (this.form.shiftId && this.$route.name === 'billing-period-details') {
                this.$emit('shiftEdited')
              }
            },
            onError: (errors) => {
              this.setResponseErrors(errors)
            },
            getCurrentShifts: !(this.$route.name === 'billing-period-details'),
          }
          this.actionType === 'start' ? this.startShift(request) : this.finishShift(request)
        }
      })
    },
    resetForm() {
      this.form = {
        ...this.form,
        employee_id: '',
      }
      if (this.actionType === 'start') {
        this.initFormForStart()
      }
      if (this.actionType === 'finish') {
        this.initFormForFinish()
      }
    },
    initFormForStart() {
      let form = {
        started_at: '',
        operational_cash: 0,
        initial_mileage: 0,
      }
      // if (this.isBillingPerKmActive) {
      //   form.initial_mileage = 0
      // }
      this.form = {
        ...form,
      }
    },
    initFormForFinish() {
      let locPrefs = JSON.parse(sessionStorage.getItem('localizationPreferences'))
      let form = {
        finished_at: '',
        returned_operational_cash: 0,
        returned_cash_for_orders: 0,
        close_billing_period: locPrefs ? locPrefs.shift_close_billing_period : false,
      }
      if (this.isBillingPerKmActive) {
        form.initial_mileage = _.get(this.user, 'last_shift.initial_mileage')
        form.final_mileage = _.get(this.user, 'last_shift.final_mileage')
          ? _.get(this.user, 'last_shift.final_mileage')
          : 0
      }
      if (_.get(this.user, 'last_shift.terminal_number')) {
        form.terminal_returned = 0
      }
      if (_.get(this.user, 'last_shift.started_at')) {
        form.started_at = this.$moment(_.get(this.user, 'last_shift.started_at'), POS_API_DATETIME_INPUT_FORMAT).format(
          COMMON_DATETIME_INPUT_FORMAT
        )
      }
      if (_.get(this.user, 'last_shift.finished_at')) {
        form.finished_at = this.$moment(
          _.get(this.user, 'last_shift.finished_at'),
          POS_API_DATETIME_INPUT_FORMAT
        ).format(COMMON_DATETIME_INPUT_FORMAT)
      }
      this.form = {
        ...this.form,
        ...form,
      }
    },
    assignFormData() {
      this.resetForm()
      this.form = {
        ...this.form,
        employee_id: this.user.id,
      }
    },
    validateForm() {
      let valid = true
      return valid
    },
    changeFinishedAt(finishedAt = null) {
      if (finishedAt !== null) {
        this.form.finished_at = finishedAt
        this.old_finished_at = null
      } else {
        let finished_at = this.$moment(this.form.finished_at, COMMON_DATETIME_INPUT_FORMAT)
        if (finished_at.isAfter(this.$moment().add(12, 'hours')) && this.old_finished_at !== this.form.finished_at) {
          this.$eventBus.$emit('showFutureDateConfirmModal', {
            value: finished_at.format(POS_API_DATETIME_INPUT_FORMAT),
            initialValue: this.old_finished_at,
            dateType: this.$t('message.futureDateConfirmModal.shiftFinishedAt'),
          })
        }
      }
      this.old_finished_at = this.form.finished_at
    },
  },
  mounted() {
    if (!this.$store.state['_shiftsCurrent']) this.$store.registerModule('_shiftsCurrent', store)
    this.resetForm()
    this.$eventBus.$on('onShowShiftControlModal', (payload) => {
      const { user, actionType, onSuccess } = payload
      if (!user) return false // Tu dodać validacje
      this.user = user
      if (payload.hasOwnProperty('shiftId')) this.form.shiftId = payload.shiftId
      this.actionType = actionType
      this.onSuccess = onSuccess
      this.assignFormData()
      this.show()
      this.old_finished_at = this.form.finished_at || null
    })
  },
  beforeDestroy() {
    if (this.$store.state['_shiftsCurrent']) this.$store.unregisterModule('_shiftsCurrent')
  },
  destroyed() {
    this.$eventBus.$off('onShowShiftControlModal')
  },
}
</script>

<template>
  <b-modal ref="modal" :title="$t('message.orderDetails.changeOrderLocalization')" :visible="visible" @hidden="hide">
    <b-alert show variant="info">{{ $t('message.orderDetails.changeOrderLocalizationDescription') }}</b-alert>
    <b-button
      v-for="(localization, localization_id) in companyLocalizations"
      v-if="localization_id != getLocalizationId"
      :key="localization_id"
      block
      size="lg"
      :variant="changeOrderLocalizationChoice === localization_id ? 'primary' : 'outline-primary'"
      @click="changeOrderLocalizationChoice = localization_id"
    >
      {{ localization }}
    </b-button>
    <template #modal-footer="{ ok }">
      <b-button
        variant="success"
        size="lg"
        block
        :disabled="!changeOrderLocalizationChoice"
        @click="changeOrderLocalization()"
        >{{ $t('message.orderDetails.transferOrder') }}</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { API } from '@/services/api/api'

const STORE = 'newOrder'

function initData() {
  return {
    visible: false,
    changeOrderLocalizationChoice: '',
  }
}

export default {
  name: 'ChangeOrderLocalizationModal',
  data() {
    return initData()
  },
  computed: {
    ...mapGetters(STORE, ['orderData']),
    ...mapGetters('auth', ['getLocalizationId', 'companyLocalizations']),
  },
  mounted() {
    this.$eventBus.$on('onShowChangeOrderLocalizationModal', (payload = {}) => {
      this.show()
    })
  },
  destroyed() {
    this.$eventBus.$off('onShowChangeOrderLocalizationModal')
  },
  methods: {
    ...mapActions({
      transferOrder: `${STORE}/transferOrder`,
    }),
    show() {
      this.visible = true
    },
    hide() {
      let initdata = initData()
      this.visible = initdata.visible
      this.changeOrderLocalizationChoice = ''
    },
    async changeOrderLocalization() {
      const { error, data } = await API.p3_transferOrder(
        this.orderData.uuid,
        parseInt(this.changeOrderLocalizationChoice)
      )
      if (!error) {
        this.$emit('transferred')
      }
    },
  },
}
</script>

<template>
  <div v-if="_errors">
    <div v-if="typeof _errors === 'string'">
      <span class="text-danger">{{ _errors }}</span>
    </div>
    <div v-else-if="Object.values(_errors).length > 1">
      <p v-for="(error, name) in _errors" :key="name" class="small text-danger">
        {{ typeof error === 'object' ? error[0] : error }}
      </p>
    </div>
    <div v-else-if="_errors.length === 1">
      <span class="text-danger">{{ _errors[0] }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResponseErrorsMessages',
  props: {
    field: String,
    responseErrors: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  computed: {
    _errors() {
      return this.field ? this.responseErrors[this.field] : this.responseErrors
    },
  },
}
</script>

<template>
  <b-navbar class="main-nav justify-content-between pl-0" type="dark">
    <div class="main-nav__left">
      <b-navbar-nav class="align-items-center">
        <li class="nav-item">
          <b-button variant="navbar" class="nav-link nav-link-button bg-transparent" @click="$router.go(-1)">
            <i class="fas fa-times-circle icon--close" :aria-label="$t('message.common.back')" />
          </b-button>
        </li>
        <slot name="leftSide">
          <li />
        </slot>
      </b-navbar-nav>
    </div>

    <div class="main-nav__right">
      <slot name="rightSide">
        <span />
      </slot>
    </div>
  </b-navbar>
</template>

<script>
export default {
  name: 'NavClosable',
}
</script>
<style scoped lang="scss">
.icon--close {
  @include media-breakpoint-up(md) {
    font-size: 20px;
  }
}
</style>

<template>
  <div id="orders-filters" class="card orders-filters-card">
    <div :class="['orders-filters', { 'orders-filters-papu3': isPapu3 }]">
      <SearchOrders :orders="orders" @searching="searchOrder" />

      <div class="d-flex filters-buttons filters-by-state">
        <b-button-group>
          <FilterButton
            v-for="button in filterByStatusButtons"
            :key="button.value"
            :is-active="ordersListFilters[button.filterType] === button.value"
            :is-unaccepted="anyUnacceptedOrders(button.value)"
            :text="button.text"
            :count="countOrdersByStatus(button.value)"
            :on-click="
              () => {
                $emit('setFilter', { filter: button.filterType, value: button.value })
              }
            "
          />
        </b-button-group>
        <order-list-filters-toggle-button
          :primary-filters-count="primaryFiltersCount"
          :extra-filters="extraFilters"
          class="toggle-filters-button"
          @toggleExtraFilters="extraFilters = !extraFilters"
        />
      </div>
    </div>
    <div v-if="extraFilters" id="orders-extra-filters">
      <div class="d-flex extra-filters">
        <b-dropdown :text="`${$t('message.ordersList.restaurant')}: ${getFilterBrandName}`" variant="outline-primary">
          <b-dropdown-item-btn
            :active="!primaryFilters.brand"
            @click="$emit('setPrimaryFilter', { filter: 'brand', value: null })"
          >
            {{ $t('message.ordersList.restaurant') }}: {{ $t('message.ordersList.all') }}
          </b-dropdown-item-btn>
          <b-dropdown-item-btn
            v-for="(brand, brandId, index) in brands"
            :key="brandId"
            :value="brand.uuid"
            :active="primaryFilters.brand === brand.uuid"
            @click="$emit('setPrimaryFilter', { filter: 'brand', value: brand.uuid })"
          >
            {{ $t('message.ordersList.restaurant') }}: {{ brand.name }} {{ brand.only_online ? '(online)' : '' }}
          </b-dropdown-item-btn>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { ORDER_STATUSES, PICKUP_TYPE_DELIVERY, PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT } from '@/common/constants'
import { orderListFiltersMixinsPapu3, orderMixins } from '@/mixins'
import SearchOrders from '@/components/orders/SearchOrders'
import FilterButton from '@/components/orders/FilterButton'
import { mapGetters } from 'vuex'
import OrderListFiltersToggleButton from '@/components/orders/OrderListFiltersToggleButton'

export default {
  name: 'OrdersListFilters',
  components: { SearchOrders, FilterButton, OrderListFiltersToggleButton },
  mixins: [orderMixins, orderListFiltersMixinsPapu3],
  props: {
    isArchive: {
      default: false,
      type: Boolean,
    },
    isMap: {
      default: false,
      type: Boolean,
    },
    orders: {
      default: () => {
        return []
      },
      type: Array,
    },
    ordersListFilters: {
      default: () => {
        return {}
      },
      type: Object,
    },
    primaryFilters: {
      default: () => ({}),
      type: Object,
    },
  },
  data: function () {
    return {
      PICKUP_TYPE_ON_SITE,
      PICKUP_TYPE_TAKOUT,
      PICKUP_TYPE_DELIVERY,
      ORDER_STATUSES,
      filterTypes: ['pickupType', 'status'],
      filterByTypeButtons: [
        {
          filterType: 'pickupType',
          value: PICKUP_TYPE_ON_SITE,
          icon: 'fa-concierge-bell',
          text: this.$t('message.ordersList.onSite'),
        },
        {
          filterType: 'pickupType',
          value: PICKUP_TYPE_TAKOUT,
          icon: 'fa-shopping-bag',
          text: this.$t('message.ordersList.takeaway'),
        },
        {
          filterType: 'pickupType',
          value: PICKUP_TYPE_DELIVERY,
          icon: 'fa-truck',
          text: this.$t('message.ordersList.delivery'),
        },
      ],
      filterByStatusButtons: [
        {
          filterType: 'status',
          value: ORDER_STATUSES.ORDER_STATUS_NEW,
          text: this.$t('message.ordersList.unaccepted'),
        },
        { filterType: 'status', value: ORDER_STATUSES.ORDER_STATUS_ACCEPTED, text: this.$t('message.ordersList.todo') },
        {
          filterType: 'status',
          value: ORDER_STATUSES.ORDER_STATUS_DELIVERING_IN,
          text: this.$t('message.ordersList.deliveringIn'),
        },
      ],
      extraFilters: false,
    }
  },
  computed: {
    ...mapGetters({
      brands: 'auth/brands',
    }),
    ...mapGetters('auth', ['createdByPosAppsmart', 'isPapu3']),
    isFilterFuture() {
      return this.ordersListFilters.future
    },
    noFilters() {
      return (
        !this.ordersListFilters.status &&
        !this.ordersListFilters.pickupType &&
        !this.ordersListFilters.courier &&
        !this.ordersListFilters.future &&
        this.ordersListFilters.orders.length === 0
      )
    },
    getFilterBrandName() {
      return this.primaryFilters.brand
        ? Object.values(this.brands).find((brand) => brand.uuid === this.primaryFilters.brand)?.name
        : 'Wszystkie'
    },
    primaryFiltersCount() {
      return Object.values(this.primaryFilters).filter((filter) => !!filter).length
    },
  },
  methods: {
    searchOrder(searchResults) {
      this.$emit('searchKeyword', searchResults)
    },
    _clearFilters() {
      this.$emit('clearFilters')
      this.$eventBus.$emit('clearSearch')
    },
    countFutureOrders() {
      return this.getFutureOrders().length
    },
    anyUnacceptedOrders(buttonValue) {
      return (
        buttonValue === ORDER_STATUSES.ORDER_STATUS_NEW && this.countOrdersByStatus(ORDER_STATUSES.ORDER_STATUS_NEW) > 0
      )
    },
  },
}
</script>

<style scoped lang="scss">
.orders-filters {
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-rows: 50px 50px;
  gap: 0 0;
  grid-template-areas:
    'SearchOrders FiltersByType'
    'FiltersByState FiltersByState';
  border-bottom: 1px solid $gray-400;

  &-card {
    margin-bottom: 10px;
  }

  &-whitelabel {
    grid-template-rows: 50px;
    grid-template-areas: 'SearchOrders FiltersByState';
  }

  &-papu3 {
    grid-template-rows: 50px;
    grid-template-areas: 'SearchOrders FiltersByState';
  }
}
.filters-by-type {
  grid-area: FiltersByType;
}
.filters-by-state {
  grid-area: FiltersByState;
  border-top: 1px solid $gray-400;
}

.filters-buttons {
  padding: 10px;
  width: 100%;
  > .btn-group {
    flex: 1;
  }
}

.extra-filters {
  padding: 10px;
  width: 100%;
}

@media (max-width: 576px) {
  .filters-buttons {
    overflow-x: auto;
    overflow-y: visible;
    padding: 5px;

    &-extra-filters {
      overflow: unset;
    }
  }
  .filters-buttons::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 576px) {
  .orders-filters {
    grid-template-columns: min-content 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'SearchOrders FiltersByType'
      'FiltersByState FiltersByState';

    &-whitelabel {
      grid-template-rows: 1fr;
    }
    &-papu3 {
      grid-template-rows: 1fr;
      grid-template-areas: 'SearchOrders FiltersByState';
    }
  }
  .filters-by-state {
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .toggle-filters-button {
    display: none;

    &-mobile {
      display: block;
    }
  }
}

@media (min-width: 768px) {
  .toggle-filters-button {
    display: block;

    &-mobile {
      display: none;
    }
  }
}

@media (min-width: 1024px) {
  .orders-filters {
    grid-template-columns: min-content 1fr;
    grid-template-rows: 70px;
    grid-template-areas: 'SearchOrders FiltersByType FiltersByState';

    &-whitelabel {
      grid-template-columns: min-content 1fr;
    }
    &-papu3 {
      grid-template-rows: 1fr;
      grid-template-areas: 'SearchOrders FiltersByState';
    }
  }
  .filters-by-state {
    border-top: none;
    overflow-x: visible;
  }
}
</style>

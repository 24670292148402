var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.validateCoords(_vm.coords))?_c('l-marker',{attrs:{"lat-lng":_vm.coords,"icon":_vm.getIcon()}},[_c('l-tooltip',{attrs:{"options":{
      permanent: true,
      interactive: true,
      direction: 'top',
      offset: [0, -15],
      className: 'localization-marker',
    }}},[_c('div',[_vm._v(" "+_vm._s(_vm.label)+" ")])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="table-responsive">
    <table class="table table-bordered table-sm">
      <thead class="thead-dark">
        <tr>
          <th scope="col">{{ $t('message.billing.nameLastName') }}</th>
          <th scope="col">{{ $t('message.billing.date') }}</th>
          <th scope="col">{{ $t('message.billing.type') }}</th>
          <th scope="col">{{ $t('message.billing.amount') }}</th>
          <th scope="col">{{ $t('message.billing.comment') }}</th>
        </tr>
      </thead>
      <template v-if="billingPeriodRequest === 'loading'">
        <TablePlaceholder :row="4" :col="4" :loading="true" />
      </template>
      <tbody v-else>
        <tr v-for="(entry, index) in entriesTableData" :key="'balance_table_row_' + index">
          <td class="table-cell">{{ employeeName }}</td>
          <td class="table-cell">{{ formatDate(entry.created_at) }}</td>
          <td class="table-cell">{{ $t(`message.billing.${entriesTypes[entry.type]}`) }}</td>
          <td class="table-cell">{{ entry.amount }}</td>
          <td class="table-cell">{{ entry.comment }}</td>
        </tr>
        <tr v-if="entriesTableData.length === 0" class="table-empty-row">
          <td colspan="6">{{ $t('message.billing.none') }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TablePlaceholder from '@/components/TablePlaceholder'
import { COMMON_DATETIME_INPUT_FORMAT } from '@/common/constants'

export default {
  name: 'EntriesTable',
  components: {
    TablePlaceholder,
  },
  data() {
    return {
      entriesTypes: {
        1: 'advance',
        2: 'penalty',
        3: 'bonus',
      },
    }
  },
  computed: {
    ...mapGetters('_billingPeriodDetails', {
      billingPeriod: 'billingPeriod',
      billingPeriodRequest: 'billingPeriodRequest',
    }),
    employeeName() {
      return _.get(this.billingPeriod, 'employee.name')
    },
    entriesTableData() {
      return this.billingPeriod.entries || []
    },
  },
  methods: {
    formatDate(date) {
      return this.$moment(date).format(COMMON_DATETIME_INPUT_FORMAT)
    },
    getEntryValue(entry, cellType) {
      const { type } = entry
      if (cellType === type) return entry.amount
      else return ''
    },
  },
}
</script>

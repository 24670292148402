import { ShiftsState } from './index'
import { Shift } from '@/types/interface'

const setShifts = (state: ShiftsState, shifts: Array<Shift>) => {
  state.shifts = shifts
}
const setShiftsRequest = (state: ShiftsState, status: string) => {
  state.shiftsRequest = status
}

export default {
  setShifts,
  setShiftsRequest,
}

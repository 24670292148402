import { ORDER_STATUSES } from '@/common/constants'

const order = (state) => state.order
const orderIsEditable = (state, getters) =>
  ![ORDER_STATUSES.ORDER_STATUS_NEW, ORDER_STATUSES.ORDER_STATUS_FINISHED].includes(state.order?.status) &&
  getters.allBillsLength > 0
const cart = (state) => state.cart
const paymentLoading = (state) => state.paymentLoading
const tableLoading = (state) => state.tableLoading
const orderRequestStatus = (state) => state.orderRequestStatus
const estimatedDeliveryTime = (state) => state.estimatedDeliveryTime
const hasBaseBill = (state) => !!state.baseBill

const getInvoiceDataPrepareToApi = (state) => ({
  order: state.orderId,
  hide_details: state.invoice.hideDetails,
  hide_details_name: state.invoice.hideNameTypeChosen === 'custom' ? state.invoice.hideNameChosen : '',
  hide_details_vat: state.invoice.hideNameTypeChosen === 'custom' ? state.invoice.hideDetailsVat : '',
  payment_type: Array.isArray(state.invoice.customPaymentType)
    ? state.invoice.customPaymentType[0]
    : state.invoice.customPaymentType,
  payment_type_name: state.invoice.paymentTypeChosen === 'custom' ? state.invoice.paymentTypeName : '',
  company_name: state.invoice.name,
  tax_id: state.invoice.taxId,
  city: state.invoice.city,
  postal_code: state.invoice.postalCode,
  street_address: state.invoice.address,
})

const hasSplitBills = (state) => !!state.bills?.length || false
const allBills = (state) => state.allBills
const allBillsLength = (state) => state.allBills.length

export default {
  order,
  orderIsEditable,
  cart,
  paymentLoading,
  tableLoading,
  orderRequestStatus,
  estimatedDeliveryTime,
  getInvoiceDataPrepareToApi,
  hasBaseBill,
  hasSplitBills,
  allBills,
  allBillsLength,
}

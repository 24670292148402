<template>
  <div class="regions">
    <regions-list-item
      v-if="isAddingNewRegion || editedRegionId"
      :is-new="!editedRegionId"
      :editable="!!editedRegionId"
      class="mx-1 my-3"
      :data="editingRegion"
      @onCancel="cancelNewRegion"
    />
    <div v-else class="regions__actions">
      <b-button v-if="isListVisible" variant="outline-primary" block @click="toggleView">
        <i class="fas fa-map mr-1" aria-hidden="true" />
        {{ $t('message.regions.map') }}
      </b-button>
      <b-button v-else variant="outline-primary" block @click="toggleView">
        <i class="fas fa-list-ul mr-1" aria-hidden="true" />
        {{ $t('message.regions.list') }}
      </b-button>
      <b-button variant="primary" class="ml-4 mt-0" block @click="addNewRegion">
        <i class="fas fa-plus-square mr-1" aria-hidden="true" />
        {{ $t('message.regions.addNewBtnText') }}
      </b-button>
    </div>
    <Map v-if="!isListVisible" @map-loaded="onMapLoaded" />
    <regions-list v-else class="regions__list" />
  </div>
</template>

<script>
import Map from './Map'
import RegionsList from './RegionsList'
import RegionsListItem from './RegionsListItem'
import { mapGetters } from 'vuex'

export default {
  name: 'RegionsMobile',
  components: {
    Map,
    RegionsList,
    RegionsListItem,
  },
  data() {
    return {
      isListVisible: false,
    }
  },
  computed: {
    ...mapGetters('_regions', ['isAddingNewRegion', 'editedRegionId', 'regions']),
    editingRegion() {
      return this.regions.find((region) => region.id === this.editedRegionId)
    },
  },
  watch: {
    editedRegionId(value) {
      if (!!value) {
        this.toggleView()
      }
    },
  },
  methods: {
    toggleView() {
      this.isListVisible = !this.isListVisible
    },
    addNewRegion() {
      if (this.isListVisible) {
        this.toggleView()
      }
      this.$store.commit('_regions/setAddingNewRegion', true)
      this.$eventBus.$emit('onAddNewRegion')
    },
    cancelNewRegion() {
      this.$store.commit('_regions/setAddingNewRegion', false)
    },
    onMapLoaded() {
      if (this.editedRegionId) {
        this.$eventBus.$emit('onStartEditRegion', this.editedRegionId)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.regions {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__actions {
    display: flex;
    justify-content: space-between;
    padding: 15px;
  }

  &__list {
    margin-left: 0;
  }
}
</style>

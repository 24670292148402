<template>
  <div :class="['cart-meals', { 'cart-meals--order-details': orderDetails }]">
    <div v-if="cartActiveMeal !== null" class="card active-meal">
      <div class="card-header pt-0 pb-0 pl-2 d-flex">
        <span class="">{{ $t('message.cart.actualMeal') }}</span>
        <div class="ml-auto">{{ $t('message.cart.quantity') }}: {{ cartActiveMeal.quantity }}</div>
      </div>
      <div class="card-body p-2">
        <div class="card-text">
          <small v-for="(textItem, index) in parseMeal(cartActiveMeal)" :key="index" v-html="textItem"> </small>
        </div>
      </div>
    </div>
    <div ref="_cartMealsRef" class="table-container">
      <table class="table table-sm cart-table table-striped">
        <thead>
          <tr>
            <th scope="col">{{ $t('message.cart.name') }}</th>
            <th scope="col">{{ $t('message.cart.quantity') }}</th>
            <th v-if="isOrderFormFull" scope="col">{{ $t('message.cart.unitPrice') }}</th>
            <th v-if="orderDetails" scope="col">{{ $t('message.cart.discount') }}</th>
            <th scope="col">{{ $t('message.cart.sum') }}</th>
            <th v-if="isMappingColumn" scope="col">
              {{ $t('message.mapping.mapping') }}
            </th>
            <th scope="col">{{ $t('message.cart.edit') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="orderDetails" class="cart-table-subheader">
            <th :colspan="columnsLength">{{ $t('message.cart.orderContent') }}</th>
          </tr>
          <tr v-for="(meal, index) in cart.meals" :key="'meal' + index">
            <td>
              <div class="d-flex">
                <MealStatusIcon v-if="$hasModuleAccess('kds') && orderDetails" :status="meal.status" class="mr-3" />
                <div class="meal-cell">
                  <span v-for="(textItem, index) in parseMeal(meal)" :key="index" v-html="textItem"></span>
                </div>
              </div>
            </td>
            <td>
              <span>{{ meal.quantity }}</span>
            </td>
            <td v-if="isOrderFormFull">
              <span v-if="meal.size && meal.size.price">{{ priceDecimal(meal.size.price) }} {{ currency }}</span>
              <span v-else>-</span>
            </td>
            <td v-if="orderDetails">
              <span v-if="meal.discount">{{ priceDecimal(meal.discount) }} %</span>
              <span v-else-if="meal.promotionalPrice || meal.discount_value"
                >{{ priceDecimal(meal.calculated_discount_value) }} {{ currency }}</span
              >
            </td>
            <td>
              <span>{{ priceDecimal(meal.price) }} {{ currency }}</span>
            </td>
            <td v-if="isMappingColumn" style="vertical-align: middle">
              <b-btn
                v-if="meal.external_id"
                id="open-mapping-btn"
                v-b-tooltip.hover.left
                :disabled="disabledMapping"
                size="sm"
                :variant="meal.is_mapped ? 'primary' : 'danger'"
                :title="
                  meal.is_mapped
                    ? $t('message.mapping.mappingBtnTooltipMapped')
                    : $t('message.mapping.mappingBtnTooltip')
                "
                block
                @click="openMapping"
              >
                <i class="fas fa-link" />
                <span v-if="meal.is_mapped">{{ $t('message.mapping.checkMapping') }}</span>
                <span v-else>{{ $t('message.mapping.mapIt') }}</span>
              </b-btn>
            </td>
            <td style="vertical-align: middle">
              <button
                v-if="editMealButtonIsActive"
                id="edit-meal-btn"
                class="btn btn-sm btn-block btn-warning"
                @click="editMeal(meal, index)"
              >
                <i class="fas fa-edit m-0" />
              </button>
            </td>
          </tr>
          <template v-if="orderDetails">
            <tr v-if="showAdditionalFeesHeader" class="cart-table-subheader">
              <th :colspan="columnsLength">{{ $t('message.cart.additionalFees') }}</th>
            </tr>
            <CartSummaryRow
              v-if="cart.serviceChargeValue && cart.serviceChargeValue !== '0.00'"
              :name="$t('message.orderDetails.finishOrderChoices.serviceCharge')"
              :value="cart.serviceChargeValue !== '0.00' && cart.serviceChargeValue"
            />
            <CartSummaryRow
              v-if="order.tip && order.tip !== '0.00'"
              :name="$t('message.orderDetails.finishOrderChoices.tip')"
              :value="order.tip"
            />
            <CartSummaryRow
              v-if="cart.glovoServiceCharge && cart.glovoServiceCharge !== '0.00'"
              :name="$t('message.orderDetails.finishOrderChoices.gloVoServiceCharge')"
              :value="cart.glovoServiceCharge"
            />
            <CartSummaryRow
              v-if="cart.deliveryPrice"
              :name="$t('message.cart.deliveryPriceIncluded')"
              :value="cart.deliveryPrice && priceDecimal(cart.deliveryPrice)"
            />
            <CartSummaryRow
              v-if="cart.packagePrice && cart.packagePrice !== '0.00'"
              :name="$t('message.cart.packagePriceIncluded')"
              :value="cart.packagePrice && priceDecimal(cart.packagePrice)"
            />
            <CartSummaryRow
              v-if="cart.discount_choice === DISCOUNT_CHOICES.DISCOUNT_CHOICE_PERCENT"
              :name="$t('message.cart.cart_percent_discount')"
              :value="cart.discount_value"
              sufix="%"
            />
            <CartSummaryRow
              v-if="cart.calculated_discount_value && cart.calculated_discount_value !== '0.00'"
              :name="$t('message.cart.discount_all')"
              :value="priceDecimal(cart.calculated_discount_value)"
            />
            <CartSummaryRow
              :bold="true"
              :border-top="true"
              :name="$t('message.cart.totalPrice')"
              :value="priceDecimal(totalPrice)"
            />
            <template v-if="createdAndPrintedBills && createdAndPrintedBills.length > 0">
              <template v-for="(bill, billIndex) in createdAndPrintedBills">
                <CartSummaryRow
                  :key="`bill_${billIndex}`"
                  :name="`${$t('message.splitBill.bill')} ${billIndex + 1}`"
                  :value="priceDecimal(bill.total_price)"
                />
              </template>
            </template>
            <CartSummaryRow
              v-else-if="order.bill_price"
              :name="$t('message.cart.totalBillPrice')"
              :value="priceDecimal(order.bill_price)"
            />
          </template>
        </tbody>
      </table>
      <div v-if="!cart.meals || (cart.meals && cart.meals.length <= 0)" class="empty-cart">
        <span>
          {{ $t('message.cart.noMeals') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import CartSummaryRow from './CartSummaryRow'
import MealStatusIcon from '@/components/meal/MealStatusIcon'
import { BILL_STATUSES, COMPLEX_MEAL_TYPES, DISCOUNT_CHOICES } from '@/common/constants'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'CartMeals',
  components: {
    CartSummaryRow,
    MealStatusIcon,
  },
  props: {
    orderDetails: { type: Boolean, default: false },
    isOrderFormFull: { type: Boolean, default: false },
    showAdditionalFeesHeader: { type: [Boolean, String], default: false },
    editMealButtonIsActive: { type: Boolean },
    disabledMapping: { type: Boolean, default: false },
    cart: {
      type: Object,
      default: () => {
        return {}
      },
    },
    order: {
      type: Object,
      default: () => {
        return {}
      },
    },
    totalPrice: { type: [String, Number], default: '0.00' },
    orderBills: {
      type: Array,
      default: () => {
        return []
      },
    },
    fromOrderDetailsModal: {
      type: Boolean,
      default: false,
    },
    orderBrandId: {
      type: [Number, String, undefined],
    },
  },
  data() {
    return {
      cartActiveMeal: null,
      DISCOUNT_CHOICES,
    }
  },
  computed: {
    ...mapGetters(['currency', 'menus']),
    createdAndPrintedBills() {
      return this.orderBills.filter((bill) => bill.status === BILL_STATUSES.CREATED_AND_PRINTED)
    },
    isMappingColumn() {
      return this.$hasModuleAccess('mapping') && this.orderDetails
    },
    columnsLength() {
      let cols = 6
      if (this.isMappingColumn) cols++
      return cols
    },
  },
  mounted() {
    this.$eventBus.$on('onUpdateCartActiveMeal', (cartActiveMeal) => {
      if (cartActiveMeal) this.cartActiveMeal = { ...cartActiveMeal }
      else this.cartActiveMeal = null
    })
    this.$eventBus.$on('onAddMealToCart', () => {
      setTimeout(() => {
        if (this.$refs._cartMealsRef) {
          this.$refs._cartMealsRef.scrollTop = this.$refs._cartMealsRef.scrollHeight
        }
      }, 50)
    })
  },
  methods: {
    ...mapMutations(['setBrand']),
    parseMeal(meal) {
      // returns list of texts to be shown
      if (!meal) return []
      if (meal.complexMeal && meal.complexMealType === COMPLEX_MEAL_TYPES.MEAL_SET) {
        // returns list of mealSet name + names of every submeal
        return [`${meal.name}: `, ...meal.submeals.map((submeal) => this.getParsedMeal(submeal, true, true))]
      } else return [this.getParsedMeal(meal, true)]
    },
    getParsedMeal(meal, size = true, isMealSetItem = false) {
      if (!meal.name && !isMealSetItem) return `<span class="meal-empty">BRAK</span>` // TODO: translate
      const { singleChoiceGroupEntries, multiChoiceGroupEntries, ingredients } = meal
      let mealName = meal.name
      if (meal.complexMeal && meal.complexMealType !== COMPLEX_MEAL_TYPES.MEAL_SET) {
        // HALF_PIZZA - 1/2 submeal1 / 1/2 submeal2
        mealName = `[${this.getParsedMeal(meal.submeals[0], false)} / ${this.getParsedMeal(meal.submeals[1], false)}]`
      }
      let mealSizeName = null
      if (meal.size && size) {
        mealSizeName = meal.size.name
        if (meal.size.name === 'default') {
          mealSizeName = ''
        }
      }
      let singleChoiceGroupEntriesText = this.getSingleChoiceGroupEntriesText(singleChoiceGroupEntries)

      let multiChoiceGroupEntriesText = this.getMultiChoiceGroupEntriesText(multiChoiceGroupEntries)

      let ingredientsText = this.getIngredientText(ingredients)

      let parsedMealText = ''

      if (isMealSetItem && !meal?.name) return `<strong>[${this.$t('message.common.choose')}]</strong>, `

      if (isMealSetItem && meal.quantity) parsedMealText += ` • ${parseInt(meal.quantity)}x `

      parsedMealText += `${mealName}`

      if (mealSizeName) parsedMealText = parsedMealText + ` ${mealSizeName}`

      if (singleChoiceGroupEntriesText) {
        parsedMealText += ` (${singleChoiceGroupEntriesText})`
      }

      if (multiChoiceGroupEntriesText) {
        parsedMealText += ` ${multiChoiceGroupEntriesText}`
      }

      if (ingredientsText) {
        parsedMealText += ` ${ingredientsText}`
      }

      if (isMealSetItem) {
        parsedMealText += ', '
      }

      return parsedMealText
    },
    getSingleChoiceGroupEntriesText(singleChoiceGroupEntries) {
      if (!singleChoiceGroupEntries) return false
      return singleChoiceGroupEntries
        .map(({ name }) => `<span class="meal-ingredient--single-choice">${name}</span>`)
        .join(', ')
    },
    getMultiChoiceGroupEntriesText(multiChoiceGroupEntries) {
      if (!multiChoiceGroupEntries) return false
      return multiChoiceGroupEntries.map(({ name }) => `<span class="meal-ingredient--add">+ ${name}</span>`).join(' ')
    },
    getIngredientText(ingredients) {
      if (!ingredients) return false
      return ingredients.map(({ name }) => `<span class="meal-ingredient--remove">- ${name}</span>`).join(' ')
    },
    editMeal(mealFromCart, index) {
      // If meal was changed, it pops up confirmation modal, otherwise changes meal
      if (this.$store.getters['_orderEdit/mealSaved']) {
        this.cartActiveMeal = mealFromCart
        if (this.$route.name === 'order' || this.fromOrderDetailsModal) {
          this.setBrand({ brandId: this.order.brand_id, brandName: this.order.brand_name })
        }
        let mealData = mealFromCart
        if (this.$route.name === 'order' || this.fromOrderDetailsModal) {
          this.$emit('closeModal')
          this.$router.push({
            name: 'edit-order',
            params: { id: this.order.id, editMeal: mealData, order: this.order },
          })
        }
        // Prevent double onMealEdit - timeout needed for view change from OrderDetails to OrderEdit
        if (this.$route.params.editMeal) {
          setTimeout(() => {
            this.$eventBus.$emit('onMealEdit', { mealData: mealData, mealCartPosition: index })
          }, 200)
        } else {
          this.$eventBus.$emit('onMealEdit', { mealData: mealData, mealCartPosition: index })
        }
        setTimeout(() => {
          this.$eventBus.$emit('onUpdateCartActiveMeal', mealFromCart)
        }, 200)
      } else {
        this.$eventBus.$emit('onShowMealDiscardChangesModal')
      }
    },
    openMapping() {
      const { brand_id, id, menu_source, number } = this.order
      this.$router.push({ name: 'mapping', params: { order: { id, brand_id, menu_source, number } } })
    },
  },
}
</script>
<style lang="scss">
.meal-ingredient--single-choice {
  font-weight: bold;
  white-space: nowrap;
}
.meal-ingredient--add {
  color: green;
  white-space: nowrap;
}
.meal-ingredient--remove {
  color: red;
  white-space: nowrap;
}
.meal-empty {
  font-weight: bold;
  color: red;
}
</style>
<style lang="scss" scoped>
.cart-meals {
  height: 100%;
  border-bottom: 1px solid #ebecef;
  overflow: auto;
  display: flex;
  flex-direction: column;

  &--order-details {
    .cart-table {
      td,
      .meal-cell {
        font-size: 13px;
      }
    }
  }
}
.meal-cell {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  white-space: normal;
}

.empty-cart {
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: center;
  height: calc(100% - 35px);
  align-items: center;
  background-color: $gray-200;
  color: $gray-500;
  min-height: 60px;
}
.table-container {
  overflow: auto;
}
.cart-table {
  margin-bottom: 0;
  border-collapse: separate;
  border-spacing: 0;
  overflow: auto;
  td {
    white-space: nowrap;
    font-size: 12px;
  }
  thead > tr > th {
    background-color: white;
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    z-index: 2;
    box-shadow: 5px 0 6px rgba(21, 21, 21, 0.2);
    font-size: 12px;
  }
  th {
    @include media-breakpoint-down(md) {
      vertical-align: middle;
      font-size: 10px;
      text-align: center;
      border-right: 1px dashed $gray-500;

      &:last-of-type {
        border-right: 0;
      }
    }
  }
  &-subheader {
    th {
      background-color: #e6e6e6;
      font-size: 12px;
      border-top: 1px solid #d9dee2;
    }
  }
}
.active-meal {
  border: 2px solid $blue;
  border-radius: 0;
}
</style>

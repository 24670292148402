<template>
  <b-overlay :show="loaders.mealGroupIngredients">
    <div v-if="isEditMode" class="edit-section">
      <h4 id="ingredients" class="label">
        {{ $t('message.menu.meal.ingredients') }} <span class="text-danger ml-1">*</span>
      </h4>
      <draggable
        v-model="ingredients"
        aria-labelledby="ingredients"
        group="ingredients"
        :disabled="disabledDraggable"
        @change="updateIngredientsPositions"
      >
        <ingredient-meal
          v-for="(ingredient, index) in ingredients"
          :id="ingredient.id"
          :key="index"
          v-model="ingredient.name"
          :index="index"
          :meal-group-id="mealGroupId"
          :disabled-draggable="disabledDraggable"
          :validator="validateName"
          @blur="updateIngredient(ingredient)"
          @create-ingredient="createIngredient(ingredient)"
          @remove-ingredient="removeIngredient(ingredient)"
          @cancel-add-ingredient="cancelAddingredient"
        />
      </draggable>
      <input-error-msg field="ingredientName" :_errors="errors"></input-error-msg>
      <b-button
        variant="primary"
        size="sm"
        class="addSizeButton"
        :disabled="addNewIngredientButtonDisabled"
        @click="addIngredient"
      >
        <i class="fas fa-plus-square mr-2" aria-hidden="true" /> {{ $t('message.menu.meal.addIngredient') }}
      </b-button>
      <hr />
    </div>
  </b-overlay>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import draggable from 'vuedraggable'
import IngredientMeal from '@/views/menu/_components/edit/meal/IngredientMeal'

const STORE = '_menuEdit'

export default {
  name: 'IngredientListMeal',
  components: {
    IngredientMeal,
    draggable,
  },
  props: {
    mealGroupId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      addingNewIngredient: false,
    }
  },
  computed: {
    ...mapFields(STORE, ['form.ingredients']),
    ...mapGetters(STORE, ['form', 'saving', 'loaders', 'isEditMode', 'getActiveMenuId']),
    disabledDraggable() {
      return this.ingredients?.some((item) => !item.id)
    },
    addNewIngredientButtonDisabled() {
      return this.saving || this.loaders.mealGroupIngredients
    },
    validateName() {
      return {
        required: true,
        min: 1,
      }
    },
  },
  async mounted() {
    await this.getMealGroupIngredients(this.mealGroupId)
  },
  methods: {
    ...mapActions(STORE, [
      'updateElement',
      'editElement',
      'deleteElement',
      'createElement',
      'getMealGroupIngredients',
      'updateElementsPositions',
    ]),
    ...mapMutations(STORE, ['setForm']),
    addIngredient() {
      this.ingredients.push({ id: 0, meal_group: this.mealGroupId })
      this.addingNewIngredient = true
    },
    async updateIngredient(ingredient) {
      if (this.isEditMode && ingredient.id) {
        const { error, data } = await this.updateElement({
          id: ingredient.id,
          requestData: ingredient,
          apiCall: 'updateIngredient',
        })
        if (!error) {
          const index = this.ingredients.findIndex((ing) => ing.id == ingredient.id)
          this.ingredients.splice(index, 1, data)
        }
      }
    },
    async createIngredient(ingredient) {
      if (this.isEditMode) {
        const { error, data } = await this.createElement({
          requestData: ingredient,
          apiCall: 'createIngredient',
        })
        if (!error) {
          const index = this.ingredients.findIndex((ing) => ing.id == 0)
          this.ingredients.splice(index, 1, data)
          this.addingNewIngredient = false
        }
      }
    },
    async removeIngredient(ingredient) {
      if (this.isEditMode) {
        const { error } = await this.deleteElement({ id: ingredient.id, apiCall: 'deleteIngredient' })
        if (!error) {
          const index = this.ingredients.findIndex((ing) => ing.id == ingredient.id)
          this.ingredients.splice(index, 1)
        }
      }
    },
    cancelAddingredient() {
      this.ingredients.splice(this.ingredients.length - 1, 1)
      this.addingNewIngredient = false
    },
    async updateIngredientsPositions() {
      let requestData = this.ingredients.map((ingredient, index) => ({ id: ingredient.id, position: index + 1 }))
      requestData = { menu_id: this.getActiveMenuId, positions: requestData }
      const { error, data } = await this.updateElementsPositions({ requestData, apiCall: 'updateIngredientsPositions' })
      if (error) {
        await this.getMealGroupIngredients()
      }
    },
  },
}
</script>

<style scoped>

</style>

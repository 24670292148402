<template>
  <pp-widget>
    <div class="container-fluid mt-3">
      <div class="row">
        <div class="col-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-white pl-0">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'settings' }" tag="a">{{ $t('message.settings.settings') }}</router-link>
              </li>
              <li class="breadcrumb-item active">
                {{ $t('message.settings.currentShifts') }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <button
            id="get-current-shifts-list-btn"
            class="btn btn-primary btn-block"
            @click="
              showHidden = !showHidden
              getCurrentShiftsList({ loading: true })
            "
          >
            {{ showHidden ? $t('message.billing.hideRemovedEmployees') : $t('message.billing.showRemovedEmployees') }}
          </button>
        </div>
        <div class="col-3">
          <select v-model="selectedEmployeeID" class="form-control" @change="getCurrentShiftsList({ loading: true })">
            <option value="">{{ $t('message.billing.filterByEmployee') }}</option>
            <option v-for="(employee, index) in employees" :key="'employee_' + index" :value="employee.id">
              {{ employee.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">{{ $t('message.summary.name') }}</th>
                  <th scope="col">{{ $t('message.billing.role') }}</th>
                  <th scope="col">{{ $t('message.billing.start') }}</th>
                  <th scope="col">{{ $t('message.billing.end') }}</th>
                  <th scope="col">{{ $t('message.billing.operationalCash') }}</th>
                  <th scope="col">{{ $t('message.billing.paymentsFromCustomer') }}</th>
                  <th scope="col">{{ $t('message.billing.cashFromOrders') }}</th>
                  <th scope="col">{{ $t('message.billing.balance') }}</th>
                </tr>
              </thead>
              <template v-if="currentShiftsRequest === 'loading'">
                <TablePlaceholder :row="10" :col="7" :loading="true" />
              </template>
              <tbody v-else>
                <CurrentShiftRow v-for="(user, index) in currentShifts" :key="'shift_' + index" :user="user" />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <shift-control-modal />
    <shift-entry-modal />
  </pp-widget>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import store from './_store'
import Widget from '@/components/Widget.vue'
import CurrentShiftRow from './_components/CurrentShiftRow'
import ShiftControlModal from './_components/ShiftControlModal'
import ShiftEntryModal from './_components/ShiftEntryModal'
import TablePlaceholder from '@/components/TablePlaceholder'

const STORE_NAME = '_shiftsCurrent'

export default {
  name: 'Index',
  components: {
    'pp-widget': Widget,
    CurrentShiftRow,
    ShiftControlModal,
    ShiftEntryModal,
    TablePlaceholder,
  },
  data: function () {
    return {
      selectedEmployeeID: '',
      selectedEmployee: '',
      showHidden: false,
    }
  },
  computed: {
    ...mapGetters(STORE_NAME, {
      currentShifts: 'currentShifts',
      currentShiftsRequest: 'currentShiftsRequest',
    }),
    ...mapGetters('employees', ['employees']),
  },
  methods: {
    ...mapActions({
      getCurrentShifts: '_shiftsCurrent/getCurrentShifts',
      createShiftEntry: '_shiftsCurrent/createShiftEntry',
    }),
    ...mapMutations(STORE_NAME, {
      setCurrentShifts: '_shiftsCurrent/setCurrentShifts',
    }),
    getCurrentShiftsList(payload = {}) {
      this.getCurrentShifts({
        ...payload,
        filters: {
          employee_id: this.selectedEmployeeID,
          show_hidden: this.showHidden,
        },
      })
    },
  },
  beforeCreate() {
    if (!this.$store.state[STORE_NAME]) this.$store.registerModule(STORE_NAME, store)
  },
  created() {
    this.getCurrentShifts({ loading: true })
    this.$store.dispatch('employees/getEmployees')
  },
  beforeDestroy() {
    this.$store.unregisterModule(STORE_NAME)
  },
}
</script>

<template>
  <div>
    <b-form-radio-group
      v-model="_value"
      :checked="_value"
      :disabled="disabled"
      buttons
      button-variant="outline-primary"
      size="lg"
    >
      <b-form-radio v-for="option in pickupTypes" :key="option.value" :value="option.value">
        {{ option.text }}
      </b-form-radio>
    </b-form-radio-group>
  </div>
</template>

<script>
import { PICKUP_TYPE_DELIVERY, PICKUP_TYPE_TAKOUT } from '@/common/constants'

export default {
  name: 'PickupType',
  props: {
    value: {
      type: Number,
      default: NaN,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pickupTypes: [
        { text: this.$t('message.orderFormFull.pickupTypes.takeaway'), value: PICKUP_TYPE_TAKOUT },
        { text: this.$t('message.orderFormFull.pickupTypes.delivery'), value: PICKUP_TYPE_DELIVERY },
      ],
    }
  },
  computed: {
    _value: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style scoped>

</style>

import { API } from '@/services/api/api'
import { ActionContext } from 'vuex'
import { GlobalEmployeeState } from './index'
import { RootState } from '@/store'

const getEmployees = async ({ commit }: ActionContext<RootState, GlobalEmployeeState>) => {
  const { error, data } = await API.getUsers({ active: true })
  if (!error && data) {
    commit('setEmployees', data)
  }
}

export default {
  getEmployees,
}

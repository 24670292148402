<template>
  <b-modal id="menuSourceModal" ref="menuSourceModal" title="Wybierz menu źródła zewnętrznego" hide-footer>
    <b-button
      v-for="(source, index) in externalSources"
      :key="index"
      class="source-change-btn"
      block
      size="lg"
      :style="{
        'background-color': getSourceColor(source),
        color: getButtonTextColor(source),
      }"
      @click="changeExternalSource(source)"
    >
      {{ source.name }}
    </b-button>
  </b-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { MENU_SOURCE_CHOICES } from '@/common/constants'
import _ from 'lodash'
import { menuSourcesMixins } from '@/mixins/menuSourcesMixins'
const STORE = '_menuEdit'

export default {
  name: 'MenuSourceModal',
  mixins: [menuSourcesMixins],
  data: () => {
    return {}
  },
  computed: {
    ...mapGetters({
      menuSources: 'auth/getMenuSources',
      currentBrandId: 'currentBrandId',
    }),
    externalSources() {
      let sources = this.menuSources[this.currentBrandId]
      return _.omitBy(sources, (source) =>
        [MENU_SOURCE_CHOICES.DEFAULT, MENU_SOURCE_CHOICES.NEW_ERESTAURANT].includes(source.source.toString())
      )
    },
  },
  methods: {
    ...mapMutations(STORE, ['setActiveMenuId']),
    changeExternalSource(source) {
      this.$emit('changeExternalSource', source)
    },
  },
}
</script>

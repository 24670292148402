<template>
  <div class="edit-section">
    <b-overlay :show="loaders.entries" spinner-variant="primary" spinner-small>
      <default-entry-choice-group
        v-if="isSingleChoiceGroup && form.entries"
        v-model="defaultEntry"
        :options="creatingNewEntry ? [] : form.entries"
        @click="updateEntry(defaultEntry, { is_default: true })"
      />
      <table id="__BVID__553" role="table" aria-busy="false" aria-colcount="3" class="table b-table">
        <thead role="rowgroup" class="">
          <tr role="row" class="">
            <th role="columnheader" scope="col" aria-colindex="1" class="">
              <div>{{ $t('message.menu.choiceGroups.name') }}<span class="text-danger ml-1">*</span></div>
            </th>
            <th role="columnheader" scope="col" aria-colindex="2" class="">
              <div>{{ $t('message.menu.choiceGroups.price') }}<span class="text-danger ml-1">*</span></div>
            </th>
            <th role="columnheader" scope="col" aria-colindex="3" class=""><div /></th>
          </tr>
        </thead>

        <draggable
          v-model="entries"
          v-bind="dragOptions"
          tag="tbody"
          group="entities"
          :disabled="disabledDraggable"
          @change="updateEntriesPositions"
        >
          <tr v-for="(item, index) of entries" :key="index" role="row" class="drag-row">
            <td aria-colindex="1" role="cell" class="align-top">
              <div class="d-flex align-items-center">
                <i :class="['fas fa-bars fa-fw text-secondary mr-2 movable-meal', { invisible: disabledDraggable }]" />
                <b-form-input
                  v-model.trim="item.name"
                  v-validate="validateName"
                  name="entryName"
                  :disabled="saving"
                  @blur="updateEntry(item.id, { name: item.name })"
                />
                <input-error-msg v-if="!item.name" field="entryName" :_errors="errors" />
              </div>
            </td>
            <td aria-colindex="2" role="cell" class="">
              <price-input
                v-model="item.price"
                v-validate="validateName"
                class="price-input"
                :append-text="currency"
                name="entryPrice"
                :disabled="saving"
                @blur="updateEntry(item.id, { price: item.price })"
              >
              </price-input>
              <input-error-msg v-if="!item.price" field="entryPrice" :_errors="errors" />
            </td>
            <td aria-colindex="3" role="cell" class="align-top">
              <b-button
                v-if="item.id"
                variant="danger"
                :disabled="saving"
                class="btn-input"
                @click="deleteEntry(item.id, index)"
              >
                <i class="fas fa-trash-alt" :aria-label="$t('message.common.delete')" />
              </b-button>
              <template v-else>
                <b-button
                  :disabled="saving"
                  class="btn-input mr-2"
                  variant="outline-secondary"
                  @click="cancelEntry(index)"
                >
                  <i class="fas fa-times" :aria-label="$t('message.common.cancel')" />
                </b-button>
                <b-button
                  :disabled="saving"
                  class="btn-input"
                  variant="success"
                  @click="createEntry({ name: item.name, price: item.price, group: id })"
                >
                  <i class="fas fa-save" :aria-label="$t('message.common.save')" />
                </b-button>
              </template>
            </td>
          </tr>
        </draggable>

        <tfoot role="rowgroup">
          <tr role="row">
            <td class="px-0">
              <b-button variant="primary" size="sm" class="px-4 mr-3" :disabled="creatingNewEntry" @click="addEntry">
                <i class="fas fa-plus-square mr-2" aria-hidden="true" />
                {{ $t('message.menu.choiceGroups.addNext') }}
              </b-button>
            </td>
            <td colspan="2">
              <div class="text-wrap d-flex align-items-center">
                <span class="mr-2">{{ $t('message.menu.choiceGroups.changeAllEntriesPrice') }}</span>
                <span style="white-space: nowrap">
                  <b-button
                    :disabled="saving"
                    variant="success"
                    size="sm"
                    class="mr-1"
                    @click="increaseAllEntriesPrice(id)"
                    >{{ '+ 1 ' + currency }}
                  </b-button>
                  <b-button variant="danger" size="sm" :disabled="saving" @click="decreaseAllEntriesPrice(id)">
                    {{ '- 1 ' + currency }}
                  </b-button>
                </span>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { API } from '@/services/api/api'
import { showErrorToast } from '@/utils/common'
import { Decimal } from 'decimal.js'
import DefaultEntryChoiceGroup from './DefaultEntryChoiceGroup'
import draggable from 'vuedraggable'
import { mapFields } from 'vuex-map-fields'
import PriceInput from '@/components/PriceInput'

const STORE = '_menuEdit'

export default {
  name: 'ChoiceGroupEntries',
  components: {
    DefaultEntryChoiceGroup,
    draggable,
    PriceInput,
  },
  data() {
    return {
      creatingNewEntry: false,
    }
  },
  computed: {
    ...mapGetters(['currency']),
    ...mapFields(STORE, ['form.id', 'form.entries', 'form.defaultEntry']),
    ...mapGetters(STORE, ['form', 'isEditMode', 'saving', 'loaders', 'isSingleChoiceGroup', 'getActiveMenuId']),
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
        handle: '.movable-meal',
      }
    },
    disabledDraggable() {
      return this.entries?.some((item) => !item.id)
    },
    validateName() {
      return {
        required: true,
        min: 1,
      }
    },
  },
  mounted() {
    this.getEntries()
  },
  methods: {
    ...mapActions(STORE, [
      'getChoiceGroupEntries',
      'createElement',
      'updateElement',
      'deleteElement',
      'updateElementsPositions',
    ]),
    ...mapMutations(STORE, ['setForm', 'setSavingStatus', 'setIsEdited']),

    async getEntries() {
      if (this.id) {
        await this.getChoiceGroupEntries({
          id: this.id,
          apiCall: this.isSingleChoiceGroup ? 'getSingleChoiceEntries' : 'getMultiChoiceEntries',
        })
      }
    },
    addEntry() {
      this.creatingNewEntry = true
      this.entries.push({ price: 0 })
    },
    async createEntry(requestData) {
      const apiCall = this.isSingleChoiceGroup ? 'createSingleChoiceEntry' : 'createMultiChoiceEntry'
      if (this.isEditMode) {
        const { data, error } = await this.createElement({ requestData, apiCall })
        if (!error) {
          this.entries[this.entries.findIndex((entry) => !entry.id)] = data
          this.setForm({ ...this.form })
          this.creatingNewEntry = false
        }
      }
    },
    async updateEntry(id, requestData) {
      const apiCall = this.isSingleChoiceGroup ? 'updateSingleChoiceEntry' : 'updateMultiChoiceEntry'
      if (this.isEditMode && id) {
        const { data, error } = await this.updateElement({ id, requestData, apiCall })
        if (!error) {
        }
      }
    },
    async deleteEntry(id) {
      const apiCall = this.isSingleChoiceGroup ? 'deleteSingleChoiceEntry' : 'deleteMultiChoiceEntry'
      if (this.isEditMode) {
        const { data, error } = await this.deleteElement({ id, apiCall })
        if (!error) {
          await this.getEntries()
        }
      }
    },
    async increaseAllEntriesPrice(id) {
      // the step of increase should be set in settings
      this.entries.forEach((entry) => {
        if (!entry.price) entry.price = new Decimal(1).toFixed(2)
        else entry.price = new Decimal(entry.price).plus(1).toFixed(2)
      })
      const apiIncreasePriceMethod = this.isSingleChoiceGroup
        ? 'increaseSingleChoiceEntriesPrice'
        : 'increaseMultiChoiceEntriesPrice'
      this.setSavingStatus(true)
      const { error } = await API[apiIncreasePriceMethod](id)
      this.$toasted.clear()
      if (!error) {
        this.setIsEdited(true)
      } else {
        await this.getEntries()
        showErrorToast(this.$toasted, error)
      }
      this.setSavingStatus(false)
    },
    async decreaseAllEntriesPrice(id) {
      // the step of increase should be set in settings
      this.entries.forEach((entry) => {
        if (entry.price == null || entry.price === 0) entry.price = 0
        else {
          let price = new Decimal(entry.price).minus(1)
          if (price < 0) price = new Decimal(0)
          entry.price = price.toFixed(2)
        }
      })
      const apiDecreasePriceMethod = this.isSingleChoiceGroup
        ? 'decreaseSingleChoiceEntriesPrice'
        : 'decreaseMultiChoiceEntriesPrice'
      this.setSavingStatus(true)
      const { error } = await API[apiDecreasePriceMethod](id)
      this.$toasted.clear()
      if (!error) {
        this.setIsEdited(true)
      } else {
        await this.getEntries()
        showErrorToast(this.$toasted, error)
      }
      this.setSavingStatus(false)
    },
    async updateEntriesPositions() {
      const apiCall = this.isSingleChoiceGroup ? 'updateSingleChoiceEntryPositions' : 'updateMultiChoiceEntryPositions'
      let requestData = this.entries.map((entry, index) => ({ id: entry.id, position: index + 1 }))
      requestData = { menu_id: this.getActiveMenuId, positions: requestData }
      const { error, data } = await this.updateElementsPositions({ requestData, apiCall })
      if (error) {
        this.getEntries()
      }
    },
    cancelEntry(index) {
      this.entries.splice(index, 1)
      this.creatingNewEntry = false
    },
  },
}
</script>

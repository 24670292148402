<template>
  <div>
    <name-meal v-model="name" @blur="updateMealGroup({ name })" />
    <size-list-meal :meal-group-id="id" />
    <hr />
    <ingredient-list-meal v-if="isEditMode" :meal-group-id="id" />

    <div v-if="isErestoBrand" class="edit-section">
      <div class="d-flex align-items-center mb-3">
        <label class="switch mb-0 mr-2" aria-labelledby="availableInEresto">
          <input
            v-model="in_erestaurant"
            type="checkbox"
            :disabled="saving"
            @change="updateMealGroup({ in_erestaurant })"
          />
          <span class="slider round"></span>
        </label>
        <h4 id="availableInEresto" class="label mb-0">{{ $t('message.menu.meal.availableInEresto') }}</h4>
      </div>
      <template v-if="in_erestaurant">
        <eresto-description-meal v-model="description" @blur="updateMealGroup({ description })" />
        <eresto-image-upload v-model="image" @upload="saveImage" @remove="removeImage">
          <template #label> {{ $t('message.menu.meal.visibleInEresto') }}: </template>
        </eresto-image-upload>
      </template>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import NameMeal from './meal/NameMeal'
import ErestoDescriptionMeal from './meal/ErestoDescriptionMeal'
import ErestoImageUpload from './generic/ErestoImageUpload'
import IngredientListMeal from '@/views/menu/_components/edit/meal/IngredientListMeal'
import SizeListMeal from '@/views/menu/_components/edit/meal/SizeListMeal'

const STORE = '_menuEdit'

export default {
  name: 'MealEdit',
  components: {
    SizeListMeal,
    IngredientListMeal,
    NameMeal,
    ErestoDescriptionMeal,
    ErestoImageUpload,
  },
  data() {
    return {
      vat: null,
      price: '',
    }
  },
  computed: {
    ...mapFields(STORE, [
      'form.id',
      'form.name',
      'form.description',
      'form.image',
      'form.imageBase64',
      'form.in_erestaurant',
      'form.category',
    ]),
    ...mapGetters(STORE, ['differentPrices', 'isEditMode', 'saving', 'menuElement', 'loaders']),
    ...mapGetters({
      isErestoBrand: 'auth/isErestoBrand',
    }),
  },

  methods: {
    ...mapMutations(STORE, ['setSavingStatus', 'setIsEdited']),
    ...mapActions(STORE, ['updateElement', 'getMealGroups']),
    saveImage(image) {
      this.imageBase64 = image
      this.updateMealGroup({ image })
    },
    async removeImage() {
      this.imageBase64 = ''
      this.image = ''
      await this.updateMealGroup({ image: '' })
    },
    async updateMealGroup(requestData) {
      this.$emit('update', requestData)
    },
  },
}
</script>

<style scoped lang="scss">
.mealSizes {
  margin-bottom: 0.5em;
}
</style>

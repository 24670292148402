<template>
  <tr
    :class="[
      'mapping-row',
      {
        'mapping-row--loading': isLoading,
        'mapping-row--mapped': mappingItem,
        'mapping-row--archived': isTargetArchived,
        'mapping-row--ignored': mappedItem.ignore_mapping,
        'mapping-row--nthMappingSourceMeal': index,
      },
    ]"
  >
    <td style="position: relative">
      <span v-if="mappedItem.external_id" class="mapping-row__external-id"> ID: {{ mappedItem.external_id }} </span>
      <span
        v-show="showLeftSide"
        class="mapping-row__source"
        :style="{ 'background-color': sourceColor, color: invertColor(sourceColor, true) }"
      >
        {{ sourceName }}
      </span>
    </td>
    <td class="font-weight-bold">
      <span v-if="showEntryCorner" class="corner" />
      <span v-show="showLeftSide">{{ mappedItem.name || '' }}</span>
    </td>
    <td v-if="!isEntry || isDynamicMode">
      <span v-show="showLeftSide">{{ !isEntry ? mappedItem.category_name : '' }}</span>
    </td>
    <td v-if="!isEntry || isDynamicMode">
      <span v-show="showLeftSide">
        {{ mappedItem.size_name === 'default' ? $t('message.menu.defaultSizeName') : mappedItem.size_name || '' }}
      </span>
    </td>
    <td v-if="isEntry || isDynamicMode">
      <span v-show="showLeftSide">
        <span :id="mappedItemGroupNameId">{{ isEntry ? mappedItem.group_name : '' }}</span>
        <MappedTableGroupNameTooltip
          v-if="isEntry && !isDynamicMode"
          :target="mappedItemGroupNameId"
          :entry-group-meals="getEntryGroupMeals(mappedItem, mappedItemMenuId)"
        />
      </span>
    </td>
    <td class="mapping-row__integration-price" style="border-right: 2px solid #ebecef">
      <span v-show="showLeftSide" class="mapping-row__price"
        >{{ priceDecimal(mappedItem.price) || '' }} {{ currency }}</span
      >
    </td>
    <template v-if="isLoading">
      <td :colspan="getLoadingColspan">
        <div class="d-flex justify-content-center align-items-center">
          <b-spinner small variant="primary" />
        </div>
      </td>
    </template>
    <template v-else-if="mappingItem">
      <td>
        <div style="position: relative">
          <span v-if="mappingItem.external_id" class="mapping-row__external-id">
            ID: {{ mappingItem.external_id }}
          </span>
        </div>
        <span
          class="mapping-row__source"
          :style="{
            'background-color': getSourceColor(mappingItem.source),
            color: invertColor(getSourceColor(mappingItem.source), true),
          }"
        >
          {{ getSourceName(mappingItem.source) }}
        </span>
      </td>
      <td class="font-weight-bold"><span v-if="showEntryCorner" class="corner" />{{ mappingItem.name }}</td>
      <td v-if="!isEntry || isDynamicMode">{{ !isEntry ? mappingItem.category_name : '' }}</td>
      <td v-if="!isEntry || isDynamicMode">
        {{ mappingItem.size_name === 'default' ? $t('message.menu.defaultSizeName') : mappingItem.size_name || '' }}
      </td>
      <td v-if="isEntry || isDynamicMode">
        <span :id="mappingItemGroupNameId">{{ isEntry ? mappingItem.group_name : '' }}</span>
        <MappedTableGroupNameTooltip
          v-if="isEntry"
          :target="mappingItemGroupNameId"
          :entry-group-meals="getEntryGroupMeals(mappingItem, mappingItemMenuId)"
        />
      </td>
      <td>
        <span class="mapping-row__price">{{ priceDecimal(mappingItem.price) }} {{ currency }}</span>
      </td>
      <td v-if="!isEntry || isDynamicMode">{{ getVatText || '' }}</td>
      <td class="text-right">
        <i
          v-if="isTargetArchived"
          v-b-tooltip.hover
          class="fas fa-exclamation-triangle text-danger mr-2"
          :title="$t('message.mapping.isArchivedWarningTooltip')"
        />
        <b-button
          v-if="!showDefaultMenuPositions"
          v-b-tooltip.hover
          variant="warning"
          size="sm"
          :title="$t('message.mapping.editMapping')"
          @click="openMappingSelectModal"
        >
          <i class="fas fa-edit" />
        </b-button>
        <span v-b-tooltip.hover title="Usuń mapowanie">
          <b-button
            v-if="!showDefaultMenuPositions"
            class="ml-2"
            variant="danger"
            size="sm"
            :disabled="removeMappingDisabled"
            @click="removeMapping"
          >
            <i class="fas fa-unlink" />
          </b-button>
        </span>
      </td>
    </template>
    <template v-else>
      <td :colspan="getEmptyRowColspan">
        <span class="no-mapping-cell-text">{{ $t('message.mapping.table.noMapping') }}</span>
      </td>
      <td class="text-right">
        <b-btn
          v-if="!mappedItem.ignore_mapping && !showDefaultMenuPositions"
          variant="primary"
          size="sm"
          :disabled="isLoading || mappingDisabled"
          @click="openMappingSelectModal"
        >
          <i class="fas fa-link" /><span>{{ $t('message.mapping.bind') }}</span>
        </b-btn>
      </td>
    </template>
    <td v-if="isIgnoreMappingButton" class="text-right">
      <b-btn
        :variant="mappedItem.ignore_mapping ? 'primary' : 'secondary'"
        size="sm"
        class="w-100"
        :disabled="isLoading || ignoreMappingDisabled"
        @click="ignoreMenuElement({ mappedItem: mappedItem, ignoreMapping: !mappedItem.ignore_mapping })"
      >
        <template v-if="mappedItem.ignore_mapping">
          <i class="fas fa-eye" /><span>{{ $t('message.mapping.unblock') }}</span>
        </template>
        <template v-else>
          <i class="fas fa-eye-slash" /><span>{{ $t('message.mapping.ignore') }}</span>
        </template>
      </b-btn>
    </td>
  </tr>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { menuSourcesMixins, vatMixins } from '@/mixins'
import MappedTableGroupNameTooltip from './MappedTableGroupNameTooltip'
import { invertColor } from '@/utils/common'
import { ENTRY_TYPES, MENU_POS_LIST } from '@/common/constants'
const STORE = '_mapping'

export default {
  name: 'MappingTableRow',
  components: { MappedTableGroupNameTooltip },
  mixins: [menuSourcesMixins, vatMixins],
  props: {
    mappedItem: { type: Object, required: true },
    fixedMappingItem: { type: Object, required: false },
    index: { type: Number, required: false },
    mappingDisabled: { type: Boolean, default: false },
    ignoreMappingButton: { type: Boolean, default: false },
    ignoreMappingDisabled: { type: Boolean, default: false },
    removeMappingDisabled: { type: Boolean, default: false },
  },
  data() {
    return {
      sourceColor: '',
      sourceName: '',
      invertColor,
    }
  },
  computed: {
    ...mapGetters(['currency']),
    ...mapGetters('auth', ['getSourceById']),
    ...mapGetters(STORE, [
      'selectedBrandId',
      'isDynamicMode',
      'showDefaultMenuPositions',
      'selectedSourceId',
      'singleChoiceGroups',
      'multiChoiceGroups',
      'getSelectedSourceMenuID',
      'getDefaultMenuId',
      'getOrderSourceMenuID',
    ]),
    ...mapGetters('integrations', ['getActiveIntegrations']),
    ...mapGetters('_menu', ['mealsTree']),
    mappingItem() {
      if (this.fixedMappingItem) return this.fixedMappingItem
      else {
        return this.isEntry ? this.mappedItem.mapped_entry : this.mappedItem.mapped_meal
      }
    },
    mappedItemGroupNameId() {
      let id = `${this.mappedItem.entry_type}_${this.mappedItem.id}`
      return this.isDynamicMode ? `order_meal_${id}` : id
    },
    mappingItemGroupNameId() {
      return `${this.mappingItem.entry_type}_${this.mappingItem.id}`
    },
    isLoading() {
      return this.mappedItem.isLoading
    },
    isEntry() {
      return this.mappedItem.entry_type
    },
    showEntryCorner() {
      return this.isEntry && this.isDynamicMode
    },
    getEmptyRowColspan() {
      return this.isDynamicMode ? 7 : this.isEntry ? 4 : 6
    },
    getLoadingColspan() {
      let colsSpan = this.isDynamicMode ? 7 : this.isEntry ? 5 : 7
      if (this.getActiveIntegrations.length > 0) colsSpan++
      return colsSpan
    },
    getVatText() {
      return this.getVatRateText(this.mappingItem.vat)
    },
    isTargetArchived() {
      return this.mappedItem?.mapped_meal?.is_archived || this.mappedItem?.mapped_entry?.is_archived
    },
    showLeftSide() {
      return !this.index
    },
    mappedItemMenuId() {
      const source = this.isDynamicMode ? this.getOrderSourceMenuID : this.mappedItem.source
      return this.getSourceById(this.selectedBrandId, source)?.menu_id
    },
    mappingItemMenuId() {
      return this.getSourceById(this.selectedBrandId, this.mappingItem.source)?.menu_id
    },
    isMenuPOS() {
      return MENU_POS_LIST.includes(parseInt(this.mappedItem.source))
    },
    isIgnoreMappingButton() {
      return this.ignoreMappingButton && this.isEntry && !this.isMenuPOS
    },
  },
  mounted() {
    const mappedItemSource = this.mappedItem.source || parseInt(this.selectedSourceId)
    this.sourceName = this.getSourceName(mappedItemSource)
    this.sourceColor = this.getSourceColor(mappedItemSource)
  },
  methods: {
    ...mapActions(STORE, ['removeMappingFromItem', 'ignoreMenuElement']),
    ...mapMutations(STORE, ['setMappedItem']),
    getSourceName(source) {
      return this.getSourceById(this.selectedBrandId, source)?.name || source
    },
    openMappingSelectModal() {
      this.setMappedItem({ ...this.mappedItem })
      this.$eventBus.$emit('onShowMappingSelectMealModal', {
        mappedItemType: this.isEntry || 'meal',
      })
    },
    removeMapping() {
      this.$bvModal
        .msgBoxConfirm(this.$t('message.mapping.remove.promptQuestion'), {
          title: this.$t('message.mapping.remove.removeMapping'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('message.common.delete'),
          cancelTitle: this.$t('message.common.cancel'),
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) this.removeMappingFromItem(this.mappedItem)
        })
    },
    getEntryGroupMeals(entry, menuId) {
      const groups =
        entry.entry_type === ENTRY_TYPES.SINGLE_CHOICE_ENTRY ? this.singleChoiceGroups : this.multiChoiceGroups
      const menuGroups = groups[menuId]
      if (!menuGroups || menuGroups.length === 0) return []
      const group = menuGroups.find((menuGroup) => menuGroup.id === entry.group)
      if (!group) return []

      const mealsId = group.meals
      const mealsTree = this.mealsTree(menuId)

      const groupMealsList = []
      mealsTree?.forEach((category) => {
        category.sizes.forEach((size) => {
          size.meals.forEach((meal) => {
            if (mealsId.includes(meal.id)) {
              const existingGroupMeal = groupMealsList.find((groupMeal) => groupMeal.meal_group === meal.meal_group)
              if (existingGroupMeal) {
                existingGroupMeal.size_names.push(size.name)
              } else {
                groupMealsList.push({
                  name: meal.name,
                  meal_group: meal.meal_group,
                  size_names: [size.name],
                })
              }
            }
          })
        })
      })

      return groupMealsList
    },
  },
}
</script>
<style lang="scss">
$arrow-width: 24px;

.mapping-row {
  td {
    max-width: 200px;
    height: 60px;
    white-space: normal !important;
    padding-bottom: 15px !important;
  }
  &__source {
    padding: 4px 6px;
    border-radius: 2px;
    font-size: 10px;
  }
  &__external-id {
    position: absolute;
    bottom: 2px;
    font-size: 10px;
    color: $gray-600;
    white-space: nowrap;
  }
  &__price {
    white-space: nowrap;
  }
  &__integration-price {
    position: relative;
    padding-right: 30px !important;
    &:before,
    &:after {
      content: '';
      position: absolute;
    }
    &:before {
      width: 6px;
      height: 6px;
      background-color: $red;
      right: $arrow-width/2;
      top: calc(50% - 2px);
      border-radius: 50%;
    }
    &:after {
      width: $arrow-width + 4;
      height: 0px;
      border-top: 2px dashed $red;
      right: $arrow-width/2 * -1;
      top: calc(50%);
    }
    & + td {
      position: relative;
      padding-left: 30px !important;
      &:before {
        content: '';
        position: absolute;
        left: $arrow-width/2-2;
        top: calc(50% - 4px);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid $red;
      }
    }
  }
  &--loading {
    background-color: $gray-200;
  }
  &--mapped {
    .mapping-row__integration-price {
      &:before {
        background-color: $green;
      }
      &:after {
        border-top: 2px solid $green;
      }
      & + td {
        &:before {
          border-left-color: $green;
        }
      }
    }
  }
  &--archived {
    td {
      background-color: #fff0f0;
      border-top: 1px solid #efebeb;
      &:first-child {
        border-left: 2px solid $red;
      }
    }
  }
  &--ignored {
    td {
      background-color: $gray-500;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  &--nthMappingSourceMeal {
    .mapping-row__integration-price {
      &:before {
        width: 2px;
        height: 40px;
        border-top: 2px solid $green;
        right: $arrow-width/2 + 2;
        top: -20px;
        border-radius: 0;
      }
    }
  }
}
.corner {
  display: inline-block;
  width: 18px;
  height: 14px;
  margin-right: 10px;
  margin-left: 15px;
  border-left: 1px solid #afafaf;
  border-bottom: 1px solid #afafaf;
  vertical-align: top;
}
.no-mapping-cell-text {
  opacity: 0.2;
  font-size: 13px;
}
</style>

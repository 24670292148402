<template>
  <OrderDetailsContent
    :id="$route.params.id"
    @editOrder="$router.push({ name: 'edit-order', params: $event })"
    @goToDefaultOrdersList="goToDefaultOrdersList()"
  />
</template>

<script>
import OrderDetailsContent from './_components/OrderDetailsContent.vue'
import { mapActions } from 'vuex'

export default {
  name: 'OrderDetails',
  components: {
    OrderDetailsContent,
  },
  methods: {
    ...mapActions({
      goToDefaultOrdersList: 'goToDefaultOrdersList',
    }),
  },
}
</script>

import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { getField, updateField } from 'vuex-map-fields'

export interface IOrderState {
  foundCustomers: Array<any>
}

const getDefaultState = (): IOrderState => {
  return {
    foundCustomers: [],
  }
}

const resetState = (state: IOrderState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getField,
    ...getters,
  },
  mutations: {
    updateField,
    resetState,
    ...mutations,
  },
  actions,
}

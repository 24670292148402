import { API } from '@/services/api/api'
import { ActionContext } from 'vuex'
import { OrdersSummaryState } from './index'
import { RootState } from '@/store'

const getEmployeesSummary = async (
  { commit }: ActionContext<RootState, OrdersSummaryState>,
  { params, onError }: { params: any; onError: any }
) => {
  commit('setEmployeesRequest', 'loading')
  const { error, data }: { error: any; data: any } = await API.getUsersSummary(params)
  if (!error && data) {
    commit('setEmployeesList', data)
    commit('setEmployeesRequest', 'success')
  } else {
    commit('setEmployeesRequest', 'error')
    if (onError && error?.response?.data) onError(error.response.data)
  }
}

export default {
  getEmployeesSummary,
}

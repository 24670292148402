<template>
  <li class="nav-item">
    <b-button
      v-shortkey="['f1']"
      class="nav-link nav-link-button"
      variant="success"
      :class="{ 'router-link-exact-active': isNewOrderActive }"
      @shortkey="isMultiBrand ? showBrandsModal() : goToNewOrder()"
      @click="onClickTrigger((onsite = false))"
    >
      <slot name="content">
        <kbd class="nav-link__shortcut">F1</kbd>
        <i class="nav-link__icon fas fa-truck" aria-hidden="true" />
        <span class="nav-link__text">{{ $t('message.navMain.newDelivery') }}</span>
      </slot>
    </b-button>
  </li>
</template>

<script>
import { newOrderMixins } from '@/mixins/newOrderMixins'

export default {
  name: 'ItemNewDelivery',
  mixins: [newOrderMixins],
  computed: {
    isNewOrderActive() {
      return this.$route.name === 'new-order' && !this.$route.query.orderOnSite
    },
  },
}
</script>

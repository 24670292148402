import { MODULES } from '@/common/constants'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export interface IModulesState {
  modulesAccess: { [key: string]: boolean }
}

export const getDefaultModulesAccess = () => {
  const defaultModuleAccess: { [key: string]: boolean } = {}
  Object.values(MODULES).forEach((moduleName) => {
    defaultModuleAccess[moduleName] = true
  })
  return defaultModuleAccess
}

export default {
  namespaced: true,
  state: {
    modulesAccess: { ...getDefaultModulesAccess() },
  },
  actions,
  mutations,
  getters,
}

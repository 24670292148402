import Vue from 'vue'
import { MappingState } from './index'
import {
  IMappedEntry,
  IMappedItem,
  IMappedOrderEntry,
  IMappingEntry,
  IMappingMeal,
  IPagination,
  MappingMode,
  MappingType,
} from '@/types/interface'

const setSelectedSourceId = (state: MappingState, source: number) => {
  state.selectedSourceId = source
}
const setSelectedBrandId = (state: MappingState, id: number) => {
  state.selectedBrandId = id
}
const setMappingMode = (state: MappingState, value: MappingMode) => {
  state.mappingMode = value
}
const setSelectedMappingTypeView = (state: MappingState, value: MappingType) => {
  state.selectedMappedTypeList = value
}
const setMappedList = (state: MappingState, list: Array<IMappedItem>) => {
  state.mappedList = list
}
const setMappedListPagination = (state: MappingState, pagination: Partial<IPagination>) => {
  state.mappedListPagination = { ...state.mappedListPagination, ...pagination }
}
const setMappedItem = (state: MappingState, item: IMappedItem) => {
  state.mappedItem = item
}
const resetMappedItem = (state: MappingState) => {
  state.mappedItem = null
}
const setMappingMealsList = (state: MappingState, list: Array<IMappingMeal>) => {
  state.mappingMealsList = list
}
const setMappingSingleChoiceEntriesList = (state: MappingState, list: Array<IMappingEntry>) => {
  state.mappingSingleChoiceEntriesList = list
}
const setMappingMultiChoiceEntriesList = (state: MappingState, list: Array<IMappingEntry>) => {
  state.mappingMultiChoiceEntriesList = list
}
const setMappedItemLoading = (
  state: MappingState,
  { mappedItem, value }: { mappedItem: IMappedItem; value: boolean }
) => {
  if (mappedItem.order_meal && mappedItem.entry_type) {
    const entriesListName = mappedItem.entry_type.replace('_entry', '_entries')
    state.mappedList = state.mappedList.map((item) => {
      if (mappedItem.order_meal === item.id) {
        // @ts-ignore
        const entries = item[entriesListName]
        if (entries) {
          entries.map((entry: IMappedItem) => {
            if (entry.id === mappedItem.id) {
              setItemLoadingValue(entry, value)
            }
            return entry
          })
        }
      }
      return item
    })
  } else {
    state.mappedList = state.mappedList.map((item) => {
      if (item.id === mappedItem.id) {
        setItemLoadingValue(item, value)
      }
      return item
    })
  }
}
const setItemLoadingValue = (item: IMappedItem, value: boolean) => {
  if (item.isLoading === undefined) {
    Vue.set(item, 'isLoading', value)
  } else item.isLoading = value
}

const mapSelectedItem = (state: MappingState, { mappingItem }: { mappingItem: IMappedItem }) => {
  if (state.mappedItem) {
    if (mappingItem.entry_type) {
      state.mappedItem.mapped_entry = mappingItem
    } else {
      state.mappedItem.mapped_meal = mappingItem
    }
  }
}

const updateMappedList = (state: MappingState, { mappedItem }: { mappedItem: IMappedItem }) => {
  // Dynamic - Update all meals with the same external_id
  if (state.mappingMode === 'dynamic') {
    // Update mapped entry
    if (mappedItem.order_meal && mappedItem.entry_type) {
      const entriesName = mappedItem.entry_type.replace('_entry', '_entries')
      state.mappedList = state.mappedList.map((item: IMappedItem) => {
        // @ts-ignore
        const entries = item[entriesName]
        if (entries) {
          // @ts-ignore
          item[entriesName] = entries.map((entry: IMappedOrderEntry) => {
            if (entry.external_id === mappedItem.external_id) {
              entry.mapped_entry = mappedItem.mapped_entry
              entry.multi_choice_entry = mappedItem.multi_choice_entry
              entry.single_choice_entry = mappedItem.single_choice_entry
            }
            return entry
          })
        }
        return item
      })
    } else {
      // Update mapped meals
      state.mappedList = state.mappedList.map((item) => {
        if (item.external_id === mappedItem.external_id) {
          item.mapped_meal = mappedItem.mapped_meal
          item.meal = mappedItem.meal
        }
        return item
      })
    }
  } else {
    // Static - Update list items (meal/entry)
    state.mappedList = state.mappedList.map((item) => {
      if (item.id === mappedItem?.id) {
        item = { ...mappedItem }
      }
      return item
    })
  }
}

const setLoaders = (state: MappingState, loader: { [k: string]: boolean }) => {
  state.loaders = { ...state.loaders, ...loader }
}

const setShowMappedOnly = (state: MappingState, value: boolean) => {
  state.showMappedOnly = value
}

const setSingleChoiceGroups = (
  state: MappingState,
  { menuId, singleChoiceGroups }: { menuId: number; singleChoiceGroups: Array<any> }
) => {
  state.singleChoiceGroups[menuId] = singleChoiceGroups
}

const setMultiChoiceGroups = (
  state: MappingState,
  { menuId, multiChoiceGroups }: { menuId: number; multiChoiceGroups: Array<any> }
) => {
  state.multiChoiceGroups[menuId] = multiChoiceGroups
}

export default {
  setSelectedSourceId,
  setSelectedBrandId,
  setSelectedMappingTypeView,
  setMappedList,
  setMappedListPagination,
  setMappedItem,
  setMappingMealsList,
  setMappingSingleChoiceEntriesList,
  setMappingMultiChoiceEntriesList,
  resetMappedItem,
  setMappedItemLoading,
  mapSelectedItem,
  updateMappedList,
  setMappingMode,
  setLoaders,
  setShowMappedOnly,
  setSingleChoiceGroups,
  setMultiChoiceGroups,
}

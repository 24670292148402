<template>
  <div class="section section-border pt-0">
    <div class="section-header">
      <label class="section-header__title">{{ $t('message.menu.sizes.title') }}</label>
    </div>
    <div class="sizes-list">
      <b-button
        v-for="size in filteredSizes"
        :key="'size_' + size.id"
        variant="light"
        class="size my-1"
        :class="{ active: formInputs.size.name === size.name }"
        @click="onSizeSelect(size)"
      >
        <div class="size-top">
          <span class="size__title">{{ getSizeName(size.name) }}</span>
        </div>
        <div v-if="!formInputs.complexMeal" class="size-bottom">
          <span class="size__price">{{ priceDecimal(size.price) }} {{ currency }}</span>
        </div>
      </b-button>
    </div>
  </div>
</template>

<script>
import { sizeUtilsMixins } from '@/mixins/sizeUtils.mixins'
import { mapGetters } from 'vuex'
export default {
  name: 'Sizes',
  mixins: [sizeUtilsMixins],
  props: {
    formInputs: {
      required: true,
      type: Object,
    },
    sizes: {
      required: true,
      type: Array,
    },
    onSizeSelect: {
      required: true,
      type: Function,
    },
  },
  computed: {
    ...mapGetters(['currency']),
    filteredSizes() {
      return this.sizes
    },
  },
}
</script>

<style scoped lang="scss">
.sizes-list {
  flex-wrap: nowrap;
  display: flex;
  overflow-x: auto;
}
.size {
  background-color: $gray-200;
  border: 1px solid rgba(0, 0, 0, 0.06);
  margin: 0 10px;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  font-weight: 500;
  transition: all $time-standard ease;
  -webkit-appearance: none !important;
  padding: 5px;
  cursor: pointer;
  white-space: nowrap;
  max-width: 50%;
  width: 100%;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  &-top {
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
    span + span {
      margin-left: 10px;
    }
    margin-bottom: 10px;
  }
  &-bottom {
    text-align: center;
  }

  &__size {
    opacity: 0.5;
    font-weight: 300;
    font-size: 14px;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0px 4px 8px rgba(45, 45, 45, 0.05);
  }

  &.active {
    background-color: $primary;
    color: #fff;
  }
}

@media screen and (max-width: $break-medium) {
  .size {
    font-size: 14px;
    &-top {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span + span {
        margin-left: 0;
      }
    }
  }
}
</style>

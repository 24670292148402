<template>
  <div class="widget">
    <div class="widget-body-wrapper">
      <div class="container-fluid mt-3">
        <div class="row">
          <div class="col-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb bg-white pl-0">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'settings' }" tag="a">{{ $t('message.settings.settings') }}</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'shifts' }" tag="a">{{ $t('message.settings.shifts') }}</router-link>
                </li>
                <li class="breadcrumb-item active">
                  {{ shiftDetailsTitle }}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <b-row class="filters">
          <h4 class="employee-name">{{ emplyoeeName }}</h4>
          <b-col lg="10">
            <div class="mr-5">
              <div class="font-weight-medium text-uppercase">{{ $t('message.shifts.shift') }}</div>
              <div>
                <span class="mr-5"
                  >{{ $t('message.shifts.beginning') }}:<span class="font-weight-medium">{{
                    shift.started_at
                  }}</span></span
                >
                <span class="mr-5"
                  >{{ $t('message.shifts.end') }}:
                  <span class="font-weight-medium">{{ shift.finished_at || $t('message.shifts.lasts') }}</span></span
                >
                <span id="shift_details_edit_button">
                  <b-button
                    v-if="shift.finished"
                    :disabled="
                      !shift.last_shift || !hasUserRole([ROLE.OWNER, ROLE.MANAGER]) || shift.billing_period_finished
                    "
                    variant="warning"
                    @click="showShiftControlModal"
                  >
                    {{ $t('message.billing.shiftEdit') }}
                  </b-button>
                  <b-tooltip
                    v-if="!hasUserRole([ROLE.OWNER, ROLE.MANAGER])"
                    target="shift_details_edit_button"
                    triggers="hover"
                    placement="bottomleft"
                  >
                    {{ $t('message.billing.shiftEditInfo') }}
                  </b-tooltip>
                  <b-tooltip
                    v-else-if="!shift.last_shift || shift.billing_period_finished"
                    target="shift_details_edit_button"
                    triggers="hover"
                    placement="bottomleft"
                  >
                    {{ $t('message.billing.shiftEditFinishedBillingInfo') }}
                  </b-tooltip>
                </span>
              </div>
            </div>
            <div class="mr-5">
              <span class="font-weight-medium text-uppercase">{{ $t('message.shifts.billingPeriod') }}</span>
              <div>
                <span class="mr-5"
                  >{{ $t('message.shifts.beginning') }}:<span class="font-weight-medium">{{
                    shift.billing_period_started_at
                  }}</span></span
                >
                <span class="mr-5"
                  >{{ $t('message.shifts.end') }}:
                  <span class="font-weight-medium">{{
                    shift.billing_period_finished_at || $t('message.shifts.lasts')
                  }}</span></span
                >
                <router-link :to="{ name: 'billing-period-details', params: { id: shift.billing_period } }" tag="a">
                  {{ $t('message.shifts.billingPeriodLink') }}
                </router-link>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="row mt-3">
          <div class="col-6">
            <div class="row">
              <shift-info-table />
            </div>
            <div class="row">
              <cash-table />
            </div>
          </div>
          <div class="col-6">
            <salary-table />
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12">
            <shift-entries-table />
          </div>
        </div>
        <hr />
        <h5>{{ $t('message.shifts.orders') }}</h5>
        <div class="row">
          <div class="col-12">
            <shift-orders-table />
          </div>
        </div>
      </div>
    </div>
    <!--<div class="simple-loading" v-if="closeBillingPeriodRequest === 'loading'">-->
    <!--<i class="fas fa-sync-alt fa-spin"></i>-->
    <!--</div>-->
    <shift-control-modal></shift-control-modal>
  </div>
</template>

<script>
import store from './_store'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import ShiftInfoTable from './_components/ShiftInfoTable'
import CashTable from './_components/CashTable'
import SalaryTable from './_components/SalaryTable'
import ShiftEntriesTable from './_components/ShiftEntriesTable'
import ShiftOrdersTable from './_components/ShiftOrdersTable'
import { responseErrorsHandler } from '@/mixins'
import { ROLE } from '@/common/constants'

const STORE_NAME = '_shiftDetails'
import ShiftControlModal from './../current/_components/ShiftControlModal'

export default {
  name: 'ShiftDetails',
  components: {
    ShiftInfoTable,
    CashTable,
    SalaryTable,
    ShiftEntriesTable,
    ShiftOrdersTable,
    ShiftControlModal,
  },
  mixins: [responseErrorsHandler],
  data() {
    return {
      selectedFinishDate: null,
      ROLE,
    }
  },
  computed: {
    ...mapGetters(STORE_NAME, {
      shift: 'shift',
      shiftDetailsRequest: 'shiftDetailsRequest',
    }),
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
    }),
    emplyoeeName() {
      const { employee } = this.shift
      return employee ? employee.name : ''
    },
    shiftDetailsTitle() {
      const { started_at } = this.shift
      return this.emplyoeeName ? `${this.emplyoeeName} - ${started_at}` : ''
    },
    finishedShiftsDates() {
      return this.billingPeriod.shifts_dates || []
    },
  },
  methods: {
    ...mapActions(STORE_NAME, {
      getShiftDetails: 'getShiftDetails',
    }),
    ...mapActions('_billingPeriodDetails', ['getBillingPeriod']),
    showShiftControlModal() {
      let employee = this.shift.employee
      const user = {
        ...employee,
        last_shift: { ...this.shift },
      }
      this.$eventBus.$emit('onShowShiftControlModal', {
        actionType: 'finish',
        user,
        shiftId: this.shift.id,
        onSuccess: () => {
          this.getShiftDetails({ loading: true, shiftId: this.$route.params.id })
        },
      })
    },
  },
  beforeCreate() {
    if (!this.$store.state[STORE_NAME]) this.$store.registerModule(STORE_NAME, store)
  },
  created() {
    this.getShiftDetails({ loading: true, shiftId: this.$route.params.id })
  },
  beforeDestroy() {
    this.$store.unregisterModule(STORE_NAME)
  },
}
</script>

<style scoped lang="scss">
.filters {
  padding: 0 15px;
  align-items: center;
}
.employee-name {
  margin-right: 20px;
}
</style>

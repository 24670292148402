<template>
  <div>
    <b-form-radio-group
      v-model="_value"
      buttons
      button-variant="outline-primary"
      :checked="_value"
      size="lg"
      :disabled="disabled"
    >
      <template v-for="option in paymentTypes">
        <b-form-radio :key="option.text" :value="option.value">
          <span class="mr-2">
            <i v-if="option.value === 'cash'" class="fas fa-money-bill-wave"></i>
            <i v-else-if="option.value === 'card'" class="fas fa-credit-card"></i>
            <i v-else-if="option.value === 'paid'" class="fas fa-clipboard-check"></i>
          </span>
          {{ option.text }}
        </b-form-radio>
      </template>
    </b-form-radio-group>
  </div>
</template>

<script>
import { PAYMENT_TYPES } from '@/common/constants'

export default {
  name: 'PaymentType',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paymentTypes: [
        { text: this.$t('message.orderFormFull.paymentTypes.cash'), value: PAYMENT_TYPES.CASH },
        { text: this.$t('message.orderFormFull.paymentTypes.card'), value: PAYMENT_TYPES.CARD },
        { text: this.$t('message.orderFormFull.paymentTypes.paid'), value: PAYMENT_TYPES.PAID },
      ],
    }
  },
  computed: {
    _value: {
      get() {
        return this.value[0]
      },
      set(value) {
        this.$emit('input', [value])
      },
    },
  },
}
</script>

<style scoped>

</style>

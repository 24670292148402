import { ORDER_STATUSES, SOUNDS } from '@/common/constants'
import { orderListFiltersMixins } from './orderListFiltersMixins'
import { mapGetters, mapMutations } from 'vuex'
import { getSoundFile } from '@/utils/common'

export const ordersListMixins = {
  mixins: [orderListFiltersMixins],
  data: () => {
    return {
      assignCourierIsOpen: false,
      assignCourierData: null,
      searchResults: null,
      searchKeyword: null,
      ordersListFilters: {
        status: ORDER_STATUSES.ORDER_STATUS_ACCEPTED,
        pickupType: false,
        future: false,
        courier: false,
        orders: [],
      },
      is_toast_about_sound_notification_shown: false,
    }
  },
  computed: {
    ...mapGetters({
      primaryFilters: '_orders/primaryFilters',
      localizationPreferences: 'settings/localizationPreferences',
    }),
    isArchive() {
      return this.$route.name === 'orders-archive'
    },
    allOrders() {
      // Filtering orders list by searched keyword, selected brand (list, ordersBase),
      // clicked status or pickupType button (list, map) or selected courier (map)
      let orders = [...this.orders]
      if (this.searchResults !== null) {
        orders = orders.filter((order) => {
          return this.searchResults.includes(order.id)
        })
      }
      // FOR ARCHIVE - NO NEED FOR FURTHER FILTERING
      if (this.isArchive) {
        return orders
      }
      if (this.ordersListFilters.future) {
        orders = this.getFutureOrders(orders)
      } else {
        if (this.ordersListFilters.status || this.ordersListFilters.pickupType || this.ordersListFilters.courier) {
          orders = this.getPresentOrders(orders)
        }
      }
      if (this.ordersListFilters.status) {
        if (this.ordersListFilters.status === ORDER_STATUSES.ORDER_STATUS_NEW) {
          // if NEW filter chosen return all NEW orders (present and future)
          orders = [...this.orders].filter((order) => {
            return order.status === this.ordersListFilters.status
          })
        } else if (this.ordersListFilters.status === ORDER_STATUSES.ORDER_STATUS_DELIVERING_IN) {
          // return PICKED_UP with DELIVERING_IN orders
          orders = orders.filter((order) => {
            return [ORDER_STATUSES.ORDER_STATUS_DELIVERING_IN, ORDER_STATUSES.ORDER_STATUS_PICKED_UP].includes(
              order.status
            )
          })
        } else {
          orders = orders.filter((order) => {
            return order.status === this.ordersListFilters.status
          })
        }
      }
      if (this.ordersListFilters.pickupType) {
        orders = orders.filter((order) => {
          return order.pickup_type === this.ordersListFilters.pickupType
        })
      }
      if (this.ordersListFilters.courier) {
        orders = orders.filter((order) => {
          return order.user_id && order.user_id === this.ordersListFilters.courier
        })
      }
      if (this.primaryFilters.brand) {
        orders = orders.filter((order) => {
          return order.brand_id == this.primaryFilters.brand
        })
      }
      // return all orders if no filters were chosen
      if (
        !Object.values(this.ordersListFilters).some((filter) => {
          return Array.isArray(filter) ? filter.length > 0 : filter
        })
      ) {
        // returns orders filtered by searchResults or all orders
        orders = [...orders]
      }
      return orders
    },
  },
  methods: {
    ...mapMutations({
      setPrimaryFilter: '_orders/setPrimaryFilter',
    }),
    setFilter(name, value) {
      this.clearFilters(false)
      this.ordersListFilters = { ...this.ordersListFilters, [name]: value }
    },
    clearFilters(resetFilters = true) {
      _.forEach(this.ordersListFilters, (value, name) => {
        if (name === 'orders') this.ordersListFilters.orders = []
        else this.ordersListFilters[name] = false
      })
      if (resetFilters) {
        _.forEach(this.primaryFilters, (value, name) => {
          this.primaryFilters[name] = null
        })
      }
    },
    playNotification() {
      if (this.countOrdersByStatus('new') > 0 && this.localizationPreferences.new_order_notification_sound !== 'none') {
        const notification = new Audio(getSoundFile(this.localizationPreferences.new_order_notification_sound))
        const promise = notification.play()

        if (promise !== undefined) {
          promise.catch((e) => {
            if (!this.is_toast_about_sound_notification_shown) {
              this.is_toast_about_sound_notification_shown = true
              this.$toasted.show(this.$t('message.ordersList.browserPreventsSoundNotifications'), {
                type: 'error',
                action: {
                  text: this.$t('message.ordersList.browserPreventsSoundNotificationsActionText'),
                  onClick: (e, toastObject) => {
                    this.is_toast_about_sound_notification_shown = false
                    toastObject.goAway(0)
                  },
                },
              })
            }
          })
        }
      }
    },
  },
}

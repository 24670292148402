<template>
  <div class="edit-section px-0">
    <label for="isDefaultEntry">{{ $t('message.menu.choiceGroups.defaultScge') }}</label>
    <div class="d-flex align-items-center justify-content-between">
      <b-select
        id="isDefaultEntry"
        :value="value"
        :disabled="saving || options.length === 0"
        :options="options"
        text-field="name"
        value-field="id"
        style="width: 80%; max-width: 400px"
        @change="change"
      >
        <template #first>
          <b-select-option :value="null">-</b-select-option>
        </template>
      </b-select>
      <TooltipButton
        id="choiceGroupDefaultEntry"
        size="md"
        button-class="defaultTooltipButton"
        class="ml-1"
        placement="topleft"
      >
        ?
        <template #tooltipText>{{ $t('message.menu.choiceGroups.defaultScgeInfo') }}</template>
      </TooltipButton>
    </div>
  </div>
</template>

<script>
import TooltipButton from '@/components/TooltipButton'
import { mapGetters } from 'vuex'

const STORE = '_menuEdit'

export default {
  name: 'DefaultEntryChoiceGroup',
  components: {
    TooltipButton,
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(STORE, ['saving']),
  },
  methods: {
    change($event) {
      this.$emit('input', $event)
      this.$emit('click')
    },
  },
}
</script>

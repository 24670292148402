<template>
  <div class="edit-section">
    <div class="d-flex justify-content-between">
      <label>{{ $t('message.menu.categories.kdsStatuses') }} </label>
      <TooltipButton id="mealStatusesTooltipBtn" size="md" placement="topleft" button-class="defaultTooltipButton">
        ?
        <template #tooltipText>
          {{ $t('message.menu.categories.kdsStatusesTooltip') }}
        </template>
      </TooltipButton>
    </div>

    <div class="meal-status-set mb-3">
      <MealStatusSetItem
        v-for="status in statusesPreview"
        :key="status.id"
        :status="status.id"
        :is-active="status.isActive"
      />
    </div>
    <b-button variant="primary" :disabled="saving" @click="showModal">
      {{ $t('message.common.choose') }}
    </b-button>
    <MealStatusesModal @onSetClick="choseStatusSet" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { MEAL_STATUSES } from '@/common/constants'
import { mealsStatusMixins } from '@/mixins/mealStatusMixins'
import MealStatusSetItem from './MealStatusSetItem'
import MealStatusesModal from './MealStatusesModal'
import TooltipButton from '@/components/TooltipButton'
const STORE = '_menuEdit'

export default {
  name: 'MealStatuses',
  components: {
    MealStatusSetItem,
    MealStatusesModal,
    TooltipButton,
  },
  mixins: [mealsStatusMixins],
  props: {
    value: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(STORE, ['saving']),
    statusesPreview() {
      return [
        { id: MEAL_STATUSES.TODO, isActive: this.value?.includes(MEAL_STATUSES.TODO) },
        { id: MEAL_STATUSES.DOING, isActive: this.value?.includes(MEAL_STATUSES.DOING) },
        { id: MEAL_STATUSES.IN_OVEN, isActive: this.value?.includes(MEAL_STATUSES.IN_OVEN) },
        { id: MEAL_STATUSES.DONE, isActive: this.value?.includes(MEAL_STATUSES.DONE) },
      ]
    },
  },
  methods: {
    showModal() {
      this.$bvModal.show('mealStatusesModal')
    },
    hideModal() {
      this.$bvModal.hide('mealStatusesModal')
    },
    choseStatusSet(statusSet) {
      this.hideModal()
      this.$emit('input', statusSet)
      this.$emit('onChange')
    },
  },
}
</script>
<style lang="scss">
.meal-status-set {
  display: flex;
}
</style>

<template>
  <b-form>
    <b-row>
      <b-col cols="12" md="6">
        <b-form-group :label="$t('message.mealSets.mealSetName')">
          <b-form-input
            id="mealset-name"
            v-model="mealSetForm.name"
            name="mealset-name"
            :placeholder="$t('message.mealSets.mealSetNameInput')"
            @input="formUpdated"
          />

          <response-errors-messages field="name" :response-errors="responseErrors" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="6">
        <b-form-group :label="$t('description')">
          <b-form-input
            id="mealset-description"
            v-model="mealSetForm.description"
            name="mealset-description"
            :placeholder="$t('description')"
            @input="formUpdated"
          />

          <response-errors-messages field="name" :response-errors="responseErrors" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="6">
        <b-form-group :label="$t('message.mealSets.mealSetDiscount')">
          <meal-set-discount
            id="mealSetDiscount"
            ref="mealSetDiscount"
            :discount-type="mealSetForm.discount_type"
            :discount-value="mealSetForm.discount_value"
            @discountChange="setDiscount"
          />
          <response-errors-messages field="discount" :response-errors="responseErrors" />
        </b-form-group>
      </b-col>
    </b-row>

    <div class="divider" />

    <div>
      <b-checkbox
        id="mealset-in-erestaurant"
        v-model="mealSetForm.in_erestaurant"
        size="lg"
        switch
        @change="formUpdated"
      >
        {{ $t('message.mealSets.mealSetInEresto') }}
      </b-checkbox>
    </div>

    <b-row v-if="mealSetForm.in_erestaurant" class="mt-4">
      <b-col cols="12" md="6">
        <eresto-image-upload v-model="mealSetForm.image" @upload="saveImage" @remove="removeImage">
          <template #label> {{ $t('message.menu.meal.visibleInEresto') }}: </template>
        </eresto-image-upload>
      </b-col>
    </b-row>

    <div class="divider" />

    <b-form-group class="mt-3" :label="$t('message.mealSets.mealSetItems')">
      <b-button
        v-b-modal.mealSetItemModal
        variant="primary"
        class="my-3"
        :disabled="addMealSetItemButtonDisabled"
        @click="addNewItem()"
      >
        <i class="fas fa-plus mr-2"></i>
        {{ $t('message.mealSets.addMealSetItem') }}
      </b-button>
      <template v-if="mealSetForm.items.length > 0">
        <b-table-simple v-if="mealSetForm.items.length > 0" responsive>
          <b-thead>
            <b-tr>
              <b-th scope="col"></b-th>
              <b-th scope="col">{{ $t('message.mealSets.discount') }}</b-th>
              <b-th scope="col">{{ $t('message.mealSets.count') }}</b-th>
              <b-th scope="col">{{ $t('message.mealSets.category') }}</b-th>
              <b-th scope="col">{{ $t('message.mealSets.sizes') }}</b-th>
              <b-th scope="col">{{ $t('message.mealSets.meals') }}</b-th>
              <b-th scope="col"></b-th>
            </b-tr>
          </b-thead>

          <draggable v-model="mealSetForm.items" v-bind="dragOptions" tag="tbody" @change="updateMealSetItemPositions">
            <MealSetItemRow
              v-for="(mealSetItem, index) in mealSetForm.items"
              :key="mealSetItem.id"
              :meal-set-item="mealSetItem"
              :is-half-pizza="isHalfPizza"
              :disabled="isHalfPizza && index === 1"
              @editMealSetItem="editMealSetItem({ item: mealSetItem, index: index })"
              @removeMealSetItem="removeItem(index)"
            />
          </draggable>
        </b-table-simple>

        <b-alert :show="isHalfPizza" variant="info">
          {{ $t('message.mealSets.halfPizzaMealSetItemInfo') }}
        </b-alert>
      </template>

      <template v-else>
        <p class="mealset__placeholder">{{ $t('message.mealSets.noMealSetItems') }}</p>
      </template>

      <input-error-msg class="h5 mt-3" field="noItems" :_errors="errors" />
    </b-form-group>

    <MealSetItemModal
      v-if="menu"
      :menu="menu"
      :type="type"
      :in-erestaurant="mealSetForm.in_erestaurant"
      @saveMealSetItem="saveMealSetItem"
    />
  </b-form>
</template>

<script>
import { mapGetters } from 'vuex'
import ResponseErrorsMessages from '@/components/ResponseErrorsMessages'
import MealSetItemModal from './MealSetItemModal'
import { responseErrorsHandler } from '@/mixins'
import { mealSetsMixins } from '@/mixins/mealSetsMixins'
import MealSetDiscount from './MealSetDiscount'
import MealSetItemRow from './MealSetItemRow'
import { API } from '@/services/api/api'
import draggable from 'vuedraggable'
import { MEALSET_TYPES } from '@/common/constants'
import ErestoImageUpload from '@/views/menu/_components/edit/generic/ErestoImageUpload.vue'

export default {
  name: 'MealSet',
  components: {
    MealSetDiscount,
    MealSetItemModal,
    ResponseErrorsMessages,
    MealSetItemRow,
    draggable,
    ErestoImageUpload,
  },
  mixins: [responseErrorsHandler, mealSetsMixins],
  props: ['mealSet', 'type'],
  data() {
    return {
      mealSetForm: {
        name: null,
        description: '',
        image: '',
        discount_type: null,
        discount_value: null,
        menu: null,
        items: [],
        in_erestaurant: false,
        type: MEALSET_TYPES.MEAL_SET,
      },
    }
  },
  computed: {
    ...mapGetters(['currency']),
    menu() {
      // return menu based on mealSet menu if edit or store menu
      const menus = this.$store.getters['menus']
      if (this.mealSet && this.mealSet.menu && menus) {
        const menu = menus.find((menu) => menu.json.id === this.mealSet.menu)
        if (menu) return menu.json
      }
      return this.$store.getters['menu']
    },
    edit() {
      return !!this.mealSet
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'mealSets',
        disabled: this.isHalfPizza,
        ghostClass: 'ghost',
      }
    },
    isHalfPizza() {
      return this.type === MEALSET_TYPES.HALF_PIZZA
    },
    isNormalMealSet() {
      return this.type === MEALSET_TYPES.MEAL_SET
    },
    addMealSetItemButtonDisabled() {
      return this.isHalfPizza && this.mealSetForm.items.length === 2
    },
  },
  watch: {
    mealSetItems: function (newValue) {
      this.errors.items = this.errors.items.filter((e) => e.field !== 'noItems')
    },
  },
  created() {
    if (this.mealSet) {
      this.mealSetForm = {
        ...this.mealSetForm,
        ...this.mealSet,
        items: _.sortBy(this.mealSet.items, 'position', 'id'),
      }
    }
    if (this.isHalfPizza) this.mealSetForm.type = MEALSET_TYPES.HALF_PIZZA
  },
  mounted() {
    this.$eventBus.$on('onSaveMealSet', () => {
      this.saveMealSet()
    })
  },
  destroyed() {
    this.$eventBus.$off('onSaveMealSet')
  },
  methods: {
    validateItems() {
      return this.mealSetForm.items && this.mealSetForm.items.length > 0
    },
    addNewItem() {
      this.$eventBus.$emit('onMealSetItemModal', null)
    },
    editMealSetItem(item) {
      this.$eventBus.$emit('onMealSetItemModal', {
        ...item.item,
        itemIndex: item.index,
      })
    },
    removeItem(item) {
      if (this.isHalfPizza) {
        this.mealSetForm.items.splice(0, 2)
      } else if (this.isNormalMealSet) {
        this.mealSetForm.items.splice(item.index, 1)
      }
      this.formUpdated()
    },
    saveMealSetItem(mealSetItem) {
      this.removeRowHighlight()

      if (this.isHalfPizza) {
        const { category, context, count, meals, menu, sizes } = mealSetItem
        if (this.mealSetForm.items.length > 0) {
          this.mealSetForm.items.forEach((item) => {
            item.category = category
            item.context = context
            item.count = count
            item.meals = meals
            item.menu = menu
            item.sizes = sizes
          })
        } else {
          this.mealSetForm.items.push(mealSetItem)
          this.mealSetForm.items.push(mealSetItem)
        }
      } else if (this.isNormalMealSet) {
        const itemIndex = mealSetItem?.itemIndex
        const mealSetForm = this.mealSetForm

        if (itemIndex !== null) {
          mealSetForm.items.splice(mealSetItem.itemIndex, 1, mealSetItem)
        } else {
          mealSetForm.items.push(mealSetItem)
        }
      }
    },
    setDiscount(discountForm) {
      this.mealSetForm.discount_value = discountForm.discount_value
      this.mealSetForm.discount_type = discountForm.discount_type
      this.formUpdated()
    },
    async createMealSet(mealSetData) {
      const { error } = await API.createMealset(mealSetData)
      if (!error) {
        this.removeChangedStatus()
        this.$emit('success')
        this.goToMealSetList()
      } else {
        if (error.response && error.response.data) this.setResponseErrors(error.response.data)
        this.$emit('loading', false)
      }
    },
    async updateMealSet(mealSetData) {
      const { error } = await API.updateMealset(mealSetData.id, mealSetData)
      if (!error) {
        this.removeChangedStatus()
        this.$emit('success')
        this.goToMealSetList()
      } else {
        if (error?.response?.data) this.setResponseErrors(error.response.data)
        this.$emit('loading', false)
      }
    },
    goToMealSetList() {
      this.$router.push({ name: 'mealsets-list', params: { menuId: this.menu.id } })
    },
    saveMealSet() {
      this.$refs.mealSetDiscount.$validator.validateAll().then((mealSetDiscountValidated) => {
        this.$validator.validateAll().then((result) => {
          if (!this.validateItems()) {
            this.errors.add({
              field: 'noItems',
              msg: this.$t('message.mealSets.noMealSetItems'),
            })
            result = false
          } else this.errors.items = this.errors.items.filter((e) => e.field !== 'noItems')
          if (result && mealSetDiscountValidated) {
            this.$emit('loading', true)
            let mealSetData = {
              ...this.mealSetForm,
              menu: this.menu.id,
            }

            // Remove image if it is not changed
            if (mealSetData.image?.startsWith('http')) delete mealSetData.image

            if (!this.mealSetForm.id) this.createMealSet(mealSetData)
            else this.updateMealSet(mealSetData)
          }
        })
      })
    },
    removeRowHighlight() {
      this.mealSetForm.items.forEach((item) => delete item._rowVariant)
    },
    removeChangedStatus() {
      this.mealSetForm.items.forEach((item) => delete item.isChanged)
    },
    formUpdated() {
      this.$emit('form-changed', true)
    },
    updateMealSetItemPositions() {
      this.mealSetForm.items = this.mealSetForm.items.map((mealSetItem, index) => {
        return {
          ...mealSetItem,
          position: index + 1,
        }
      })
    },
    saveImage(image) {
      this.mealSetForm.image = image
    },
    async removeImage() {
      this.mealSetForm.image = ''
    },
  },
}
</script>

<style scoped lang="scss">
.mealset {
  &__placeholder {
    text-align: center;
    background-color: #fafafa;
    color: #909294;
    border: 1px solid #eeeeee;
    font-weight: 500;
    padding: 60px 10px;
  }
}
</style>

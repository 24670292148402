const couriers = (state) => state.couriers
const orders = (state) => state.orders
const ordersRequest = (state) => state.ordersRequest
const ordersInRegeocode = (state) => state.ordersInRegeocode
const primaryFilters = (state) => state.primaryFilters

export default {
  couriers,
  orders,
  ordersRequest,
  ordersInRegeocode,
  primaryFilters,
}

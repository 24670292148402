import { ICategory, IMeal, IMenuState, ISize } from '@/types/interface'
import { API } from '@/services/api/api'
// @ts-ignore
import async from 'async'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'

const getMealsTree = async ({ commit, state }: ActionContext<IMenuState, RootState>, menuId: number) => {
  async.parallel(
    {
      categories: async function (callback: any) {
        const { error, data } = await API.getCategories(menuId, 'id,name')
        callback(null, { error, data })
      },
      sizes: async function (callback: any) {
        const { error, data } = await API.getSizes(menuId, 'id,name,category')
        callback(null, { error, data })
      },
      meals: async function (callback: any) {
        const { error, data } = await API.getMeals(menuId, 'id,name,size,meal_group')
        callback(null, { error, data })
      },
    },
    function (err: any, results: any) {
      if (err) return false
      const { categories, sizes, meals } = results
      if (!categories.error && !sizes.error && !meals.error) {
        categories.data.forEach((cat: ICategory) => (cat.sizes = []))
        sizes.data.forEach((size: ISize) => (size.meals = []))

        meals.data.forEach((meal: IMeal) => {
          sizes.data.forEach((size: ISize) => {
            if (size.id === meal.size) {
              if (!size.meals) size.meals = []
              size.meals.push(meal)
              return true
            }
          })
        })
        sizes.data.forEach((size: ISize) => {
          categories.data.forEach((category: ICategory) => {
            if (category.id === size.category) {
              category.sizes.push(size)
              return true
            }
          })
        })
        commit('setMealsTree', {
          menuId: menuId,
          mealsTree: categories.data,
        })
      }
    }
  )
}

const getMealSets = async ({ commit }: ActionContext<IMenuState, RootState>) => {
  const { error, data } = await API.getMealSets({})
  if (!error && data) {
    commit('setMealSets', data)
  }
}

export default {
  getMealsTree,
  getMealSets,
}

<template>
  <div class="d-flex w-100">
    <div class="flex-grow-1">
      <label for="choiceGroupName">
        {{ $t('message.menu.choiceGroups.groupName') }} <span class="text-danger ml-1">*</span>
      </label>
      <b-form-input
        id="choiceGroupName"
        v-validate="validateName"
        :value="value"
        name="choiceGroupName"
        :disabled="saving"
        @input="$emit('input', $event)"
        @blur="veeFields.choiceGroupName.changed ? $emit('blur') : null"
      />
      <input-error-msg field="choiceGroupName" :_errors="errors" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const STORE = '_menuEdit'

export default {
  name: 'NameChoiceGroup',
  inject: ['$validator'],
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(STORE, ['saving']),
    validateName() {
      return {
        required: true,
        min: 1,
      }
    },
  },
}
</script>

<template>
  <tr :class="getShiftRowClass(isShiftFinished)">
    <td>
      <router-link :to="{ name: 'shifts', query: { employeeID: user.id } }" tag="a">
        {{ user.name }}
      </router-link>
    </td>
    <td>
      {{ user.role ? $t(`message.employeeList.${user.role}`) : '' }}
    </td>
    <td>
      <template v-if="!user.salary_config">
        <span class="alert-text alert-text--small" style="width: 150px; white-space: normal">{{
          $t('message.billing.noSalaryConfigAlert')
        }}</span>
        <router-link :to="{ name: 'employee', params: { id: user.id } }">{{
          $t('message.employeeList.edit')
        }}</router-link>
      </template>
      <button
        v-else-if="!isShiftExist || isShiftFinished"
        id="show-shift-control-modal-btn"
        class="btn btn-primary btn-block"
        type="button"
        data-toggle="modal"
        data-target="#user-modal"
        @click="showShiftControlModal({ actionType: 'start', user: user })"
      >
        <i class="fas fa-play"></i>
        <span>{{ $t('message.billing.newShift') }}</span>
      </button>
      <div v-else-if="isShiftExist" class="table-cell--date">
        <div class="table-cell--date__row">
          <i class="far fa-calendar fa-fw"></i
          >{{ user.last_shift.started_at && user.last_shift.started_at.split(' ')[0] }}
        </div>
        <div class="table-cell--date__row">
          <i class="far fa-clock fa-fw"></i>{{ user.last_shift.started_at && user.last_shift.started_at.split(' ')[1] }}
        </div>
      </div>
    </td>
    <td>
      <div v-if="isShiftFinished" class="table-cell--date">
        <div class="table-cell--date__row">
          <i class="far fa-calendar fa-fw"></i
          >{{ user.last_shift.finished_at && user.last_shift.finished_at.split(' ')[0] }}
        </div>
        <div class="table-cell--date__row">
          <i class="far fa-clock fa-fw"></i
          >{{ user.last_shift.finished_at && user.last_shift.finished_at.split(' ')[1] }}
        </div>
      </div>
      <button
        v-else-if="isShiftExist"
        class="btn btn-danger btn-block"
        type="button"
        data-toggle="modal"
        data-target="#shift-modal"
        @click="showShiftControlModal({ actionType: 'finish', user: user })"
      >
        <i class="fas fa-stop"></i>
        <span>{{ $t('message.billing.end') }}</span>
      </button>
    </td>
    <td>
      <b-input-group v-if="isShiftContinues">
        <b-input-group-prepend>
          <b-button
            id="operational-cash-button"
            variant="danger"
            size="md"
            @click="showShiftEntryModal({ user: user, entryType: shiftEntryTypes['operationalCashIn'] })"
            ><i class="fas fa-minus"
          /></b-button>
        </b-input-group-prepend>
        <b-form-input
          v-if="isShiftExist"
          id="operational-cash-input"
          class="font-weight-semi-bold"
          disabled
          :value="priceDecimal(user.last_shift.operational_cash) + ' ' + currency"
        >
        </b-form-input>
        <b-input-group-append>
          <b-button
            variant="success"
            size="md"
            @click="showShiftEntryModal({ user: user, entryType: shiftEntryTypes['operationalCashOut'] })"
            ><i class="fas fa-plus"
          /></b-button>
        </b-input-group-append>
      </b-input-group>
      <span v-else-if="isShiftExist" class="font-weight-semi-bold"
        >{{ priceDecimal(user.last_shift.operational_cash) }} {{ currency }}</span
      >
      <span v-else class="no-data-cell">---</span>
    </td>
    <td>
      <template v-if="isShiftContinues && user.role !== 'waiter'">
        <b-input-group>
          <b-input-group-prepend>
            <b-button
              variant="danger"
              size="md"
              @click="showShiftEntryModal({ user: user, entryType: shiftEntryTypes['paymentFromCustomer'] })"
              ><i class="fas fa-minus"
            /></b-button>
          </b-input-group-prepend>
          <b-form-input
            id="payments-from-customers-input"
            class="font-weight-semi-bold"
            disabled
            :value="priceDecimal(user.last_shift.payments_from_customers) + ' ' + currency"
          >
          </b-form-input>
        </b-input-group>
      </template>
      <span v-else-if="isShiftFinished" class="font-weight-semi-bold">{{
        getValueInCurrency(user.last_shift.payments_from_customers)
      }}</span>
      <span v-else class="no-data-cell">---</span>
    </td>
    <td>
      <span v-if="isShiftExist" class="font-weight-semi-bold">{{
        getValueInCurrency(user.last_shift.cash_for_orders)
      }}</span>
      <span v-else class="no-data-cell">---</span>
    </td>
    <td>
      <span v-if="isShiftExist" class="font-weight-semi-bold"
        >{{ priceDecimal(user.last_shift.balance) }} {{ currency }}</span
      >
      <span v-else class="no-data-cell">---</span>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'
import { rowColor } from '@/mixins'
export default {
  name: 'CurrentShiftRow',
  mixins: [rowColor],
  props: {
    user: Object,
  },
  data() {
    return {
      shiftEntryTypes: {
        paymentFromCustomer: 1,
        operationalCashIn: 2,
        operationalCashOut: 3,
      },
    }
  },
  computed: {
    ...mapGetters(['currency']),
    isShiftExist() {
      return this.user && this.user.last_shift
    },
    isShiftFinished() {
      return this.isShiftExist && this.user.last_shift.finished
    },
    isShiftContinues() {
      return this.isShiftExist && !this.isShiftFinished
    },
  },
  methods: {
    showShiftControlModal(payload) {
      this.$eventBus.$emit('onShowShiftControlModal', payload)
    },
    showShiftEntryModal(payload) {
      this.$eventBus.$emit('onShowShiftEntryModal', payload)
    },

    getValueInCurrency(value) {
      return value ? `${this.priceDecimal(value)} ${this.currency}` : `--`
    },
  },
}
</script>

<style scoped lang="scss">
td {
  font-size: 14px;
  vertical-align: middle;
}
</style>

<template>
  <div v-if="visible" class="address-hint">
    <b-list-group v-click-outside="close" class="relative">
      <div v-if="loading" class="address-hint__loader">
        <b-spinner small />
      </div>

      <div v-else-if="error" class="p-1">
        <b-alert show variant="danger" class="mb-0 p-2"
          ><i class="fas fa-exclamation-triangle mr-2" />{{ $t('address_hint_general_error') }}</b-alert
        >
      </div>

      <b-list-group-item v-else-if="results.length === 0" class="d-flex justify-content-center">
        <span>{{ $t('no_results') }}</span>
      </b-list-group-item>

      <b-list-group-item
        v-for="(result, index) in results"
        :key="'result' + index"
        ref="results"
        v-shortkey="focusedResultIdx === index ? ['enter'] : 'null'"
        class="address-hint__result"
        :active="focusedResultIdx === index"
        @mouseenter="focusedResultIdx = index"
        @shortkey="selectResult(result)"
        @click="selectResult(result)"
        @keyup.prevent.stop.arrow-keys="focusHint"
      >
        <span class="text-capitalize">{{ result.street }}, {{ result.city }}</span>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { API } from '@/services/api/api'
import { debounce } from 'lodash'

export default {
  name: 'StreetAddressHint',
  props: {
    prompt: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      focusedResultIdx: -1,
      results: [],
      loading: false,
      visible: false,
      error: false,
    }
  },
  watch: {
    prompt() {
      if (
        this.prompt.length >= 2 &&
        (this.focusedResultIdx === -1 ||
          (this.results.length > 0 &&
            this.focusedResultIdx >= 0 &&
            !this.prompt.includes(this.results[this.focusedResultIdx].street)))
      ) {
        this.debounceGetResults()
        this.visible = true
      }
    },
    visible() {
      this.$emit('toggle', this.visible)
    },
  },
  mounted() {
    this.$eventBus.$on('closeAddressHint', () => {
      this.close()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('closeAddressHint')
  },
  methods: {
    debounceGetResults: debounce(function () {
      this.getResults()
    }, 300),
    async getResults() {
      this.focusedResultIdx = -1
      if (this.prompt.length < 2) {
        this.results = []
        return
      }

      this.loading = true
      this.error = false

      const { error, data } = await API.getAddressHints(this.prompt)
      if (!error && data) {
        if (data.length > 12) {
          this.results = data.slice(0, 12)
          this.$store.commit('_orderEdit/setAddressHintRequests', this.$store.state._orderEdit.addressHintRequests + 1)
        } else this.results = data
      } else {
        this.error = true
      }

      this.loading = false
    },
    selectResult(result) {
      this.visible = false
      this.$store.commit('_orderEdit/setAddressHintClicks', this.$store.state._orderEdit.addressHintClicks + 1)
      this.$emit('select', result)
    },
    close() {
      this.visible = false
    },
  },
}
</script>

<style lang="scss">
.address-hint {
  position: absolute;
  z-index: 300;
  margin-top: 2px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.12);
  width: 100%;
  min-height: 46px;
  background-color: white;

  &__result {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  &__loader {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 101;
  }
}
</style>

<template>
  <b-modal id="mealStatusesModal" ref="mealStatusesModal" title="Wybierz zestaw statusów" hide-footer>
    <div
      v-for="statusSet in statusSets"
      class="meal-status-set meal-status-set--selectable mb-3"
      @click="onSetClick(statusSet)"
    >
      <MealStatusSetItem
        v-for="status in statuses"
        :key="status"
        :status="status"
        :is-active="statusSet.includes(status)"
      />
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { MEAL_STATUSES } from '@/common/constants'
import { mealsStatusMixins } from '@/mixins/mealStatusMixins'
import MealStatusSetItem from './MealStatusSetItem'
const STORE = '_menuEdit'

export default {
  name: 'MealStatusesModal',

  components: { MealStatusSetItem },
  mixins: [mealsStatusMixins],
  data: () => {
    return {
      statuses: [MEAL_STATUSES.TODO, MEAL_STATUSES.DOING, MEAL_STATUSES.IN_OVEN, MEAL_STATUSES.DONE],
      statusSets: [
        [],
        [MEAL_STATUSES.TODO, MEAL_STATUSES.DONE],
        [MEAL_STATUSES.TODO, MEAL_STATUSES.DOING, MEAL_STATUSES.DONE],
        [MEAL_STATUSES.TODO, MEAL_STATUSES.DOING, MEAL_STATUSES.IN_OVEN, MEAL_STATUSES.DONE],
      ],
    }
  },
  computed: {
    ...mapGetters({
      menuSources: 'auth/getMenuSources',
      currentBrandId: 'currentBrandId',
    }),
  },
  methods: {
    onSetClick(statusSet) {
      this.$emit('onSetClick', statusSet)
    },
  },
}
</script>
<style lang="scss">
.meal-status-set--selectable {
  padding: 10px;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #e9e9e9;
  cursor: pointer;
  &:hover {
    border: 1px solid $primary;
    background-color: #edf4ff;
  }
}
</style>

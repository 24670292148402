<template>
  <tr class="order-row" @click="openDetails()">
    <th scope="row" class="text-left">
      <div>
        <span>{{ order.number }}</span>
        <div class="order-row__status-icons">
          <TooltipButton
            v-if="order.is_printed"
            :id="'orderPrintedTooltipButton_' + order.id"
            class="tooltip__button"
            size="md"
            variant="light"
            button-class="smallTooltipButton"
          >
            <i class="fas fa-receipt"></i>
            <template #tooltipText>{{ $t('message.ordersList.printed') }}</template>
          </TooltipButton>
          <TooltipButton
            v-if="order.comment"
            :id="'orderCommentTooltipButton_' + order.id"
            class="tooltip__button"
            size="md"
            variant="light"
            button-class="smallTooltipButton"
          >
            <i class="far fa-comment"></i>
            <template #tooltipText>{{ order.comment }}</template>
          </TooltipButton>
        </div>
      </div>
    </th>
    <td v-if="multipleRestaurants()">{{ order.brand_name }}</td>
    <td v-if="!isArchive">
      <countdown
        :date="order.promised_time"
        :show-promised-time="false"
        :is-table="true"
        :on-time="order.delivery_type === DELIVERY_TYPES.ONTIME"
      />
      <span class="order-row__promise-time">{{ order.promised_time }}</span>
    </td>
    <td class="order-row__created-date">{{ order.created }}</td>
    <td v-if="isArchive">{{ order.finished_at }}</td>
    <td v-if="isArchive">{{ order.promised_time }}</td>
    <td>
      {{ order.order_customer ? order.order_customer.phone : '' }}
    </td>
    <td class="order-row__address">
      <template v-if="!isArchive">
        <div v-if="isInRegeocode" class="regeocode">
          <b-spinner type="grow" small />
          <span class="regeocode-text">{{ $t('message.regeocodeOrders.relocating') }}</span>
        </div>
        <TooltipButton
          v-if="order.relocated_twice"
          :id="'orderBillValuesMismatchTooltipButton_' + order.id"
          class="tooltip__button"
          size="md"
          variant="light"
          button-class="smallTooltipButton"
        >
          <i class="fas fa-exclamation-circle regeocode-failed-icon" />
          <template #tooltipText>
            {{ $t('message.regeocodeOrders.relocatingError') }}
          </template>
        </TooltipButton>
      </template>
      <span v-if="order.pickup_type === PICKUP_TYPE_DELIVERY && !order.lat && !order.lon">
        <i
          v-b-tooltip.hover
          class="fas fa-exclamation-circle text-danger"
          :title="$t('message.ordersList.noAddress')"
        />
      </span>
      {{ getOrderFullAddress(order) }}
    </td>
    <td
      v-if="order.pickup_type === PICKUP_TYPE_DELIVERY"
      v-b-tooltip.hover.bottom
      :title="$t('message.ordersList.priceTooltip')"
    >
      <div>
        <div class="order-row__price">{{ priceDecimal(order.price) }} {{ currency }}</div>
        <div class="order-row__partial-prices">{{ getOrderPrice(order) }} {{ currency }}</div>
        <div class="order-row__partial-prices">+ {{ priceDecimal(order.delivery_price) }} {{ currency }}</div>
      </div>
    </td>
    <td v-else class="order-row__price">{{ priceDecimal(order.price) }} {{ currency }}</td>
    <td v-if="isArchive">
      <span v-if="order.calculated_discount_value"
        >{{ priceDecimal(order.calculated_discount_value) }} {{ currency }}</span
      >
    </td>
    <td v-if="isArchive && $hasModuleAccess('payments-tips')">
      <span v-if="order.tip">{{ priceDecimal(order.tip) }} {{ currency }}</span>
    </td>
    <td class="order-row__payment">{{ order.payment_translated }}</td>
    <td class="text-truncate" style="max-width: 150px">
      <b-badge
        v-if="$hasModuleAccess('mapping') && isMappingRequired"
        v-b-tooltip.hover.bottom
        class="mr-2"
        variant="danger"
        :title="$t('message.mapping.mappingBtnTooltip')"
        ><i class="fas fa-unlink"
      /></b-badge>
      <MealStatusIcon v-if="$hasModuleAccess('kds')" :status="mealsStatus" class="mb-1" />
      <MealsTextTruncate :meals="order.meals" />
    </td>
    <td>
      <div
        v-if="
          ![PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT].includes(order.pickup_type) && order.status !== 'new' && !isArchive
        "
        :id="`open-assign-courier-modal-btn_${order.id}`"
      >
        <button
          type="button"
          :class="[
            'btn btn-block btn-md assign-courier-button',
            { 'btn-primary': order.courier, 'btn-warning': !order.courier },
          ]"
          data-toggle="modal"
          data-target="#assign-courier-modal"
          :disabled="openAssignCourierButtonDisabled"
          @click.stop="openAssignCourier(order)"
        >
          <span v-if="order.courier">{{ order.courier.name }}</span>
          <i v-else class="fas fa-user-plus" />
        </button>
        <b-tooltip
          v-if="openAssignCourierButtonDisabled"
          :target="`open-assign-courier-modal-btn_${order.id}`"
          triggers="hover"
          placement="left"
        >
          {{ $t('message.ordersList.cantAssignCourierNotAllMealsReady') }}
        </b-tooltip>
      </div>
      <button
        v-else-if="order.pickup_type === PICKUP_TYPE_DELIVERY && order.status !== 'new'"
        type="button"
        :disabled="!order.courier"
        :class="['btn btn-md btn-block', { 'btn-primary': order.courier, 'btn-outline-secondary': !order.courier }]"
        @click.stop="() => {}"
      >
        <span v-if="order.courier">{{ order.courier.name }}</span>
        <span v-else>{{ $t('message.ordersList.none') }}</span>
      </button>
      <button v-else class="btn btn-light btn-block" disabled>
        <i v-if="order.pickup_type === PICKUP_TYPE_TAKOUT" class="fas fa-shopping-bag" />
        <i v-else-if="order.pickup_type === PICKUP_TYPE_ON_SITE" class="fas fa-concierge-bell" />
        <span>{{ order.pickup_type_translated }}</span>
      </button>
    </td>
    <td>{{ order.status_translated }}</td>
    <td v-if="isArchive">{{ order.finished_type_translated }}</td>
    <td>{{ order.menu_translated }}</td>
  </tr>
</template>

<script>
import {
  PICKUP_TYPE_ON_SITE,
  PICKUP_TYPE_TAKOUT,
  PICKUP_TYPE_DELIVERY,
  DELIVERY_TYPES,
  MEAL_STATUSES,
} from '@/common/constants'
import { mapActions, mapGetters } from 'vuex'
import { orderMixins } from '@/mixins'
import TooltipButton from '@/components/TooltipButton'
import Countdown from '@/components/Countdown'
import MealsTextTruncate from '@/components/MealsTextTruncate'
import MealStatusIcon from '@/components/meal/MealStatusIcon'

export default {
  name: 'OrderTableRow',
  components: { TooltipButton, Countdown, MealsTextTruncate, MealStatusIcon },
  mixins: [orderMixins],
  props: {
    order: {
      type: Object,
      required: true,
    },
    isArchive: {
      type: Boolean,
      default: false,
    },
    openAssignCourier: {
      type: [Function, null],
      default: null,
    },
  },
  data() {
    return {
      PICKUP_TYPE_ON_SITE: PICKUP_TYPE_ON_SITE,
      PICKUP_TYPE_TAKOUT: PICKUP_TYPE_TAKOUT,
      PICKUP_TYPE_DELIVERY: PICKUP_TYPE_DELIVERY,
      DELIVERY_TYPES: DELIVERY_TYPES,
    }
  },
  computed: {
    ...mapGetters('auth', ['brands']),
    ...mapGetters(['currency']),
    ...mapGetters({
      ordersInRegeocode: '_orders/ordersInRegeocode',
    }),
    ...mapGetters('settings', ['localizationPreferences']),
    isInRegeocode() {
      return this.ordersInRegeocode.includes(this.order.id)
    },
    isMappingRequired() {
      return !this.order.is_mapped
    },
    openAssignCourierButtonDisabled() {
      return (
        this.$hasModuleAccess('kds') &&
        this.localizationPreferences.kds_block_assign_courier_to_order_with_meals_not_ready &&
        this.mealsStatus !== MEAL_STATUSES.DONE
      )
    },
  },
  methods: {
    getOrderPrice(order) {
      let deliveryPrice = order.delivery_price
      if (deliveryPrice === null) {
        deliveryPrice = '0'
      }
      return this.priceDecimal(this.priceDecimal(order.price, false).minus(deliveryPrice))
    },
    multipleRestaurants() {
      return Object.values(this.brands).length > 1 ? this.brands : false
    },
    openDetails() {
      this.$eventBus.$emit('onShowDetailsModal', { id: this.order.id })
    },
  },
}
</script>

<style scoped lang="scss">
.order-row {
  cursor: pointer;
  &:hover {
    background: #f1f1f1;
  }
  td {
    white-space: nowrap;
  }
  &__created-date {
    white-space: normal !important;
  }
  &__payment {
    white-space: normal !important;
  }
  &__price {
    font-size: 14px;
    font-weight: 500;
  }
  &__partial-prices {
    color: $gray-600;
    font-size: 11px;
    line-height: 14px;
  }
  &__promise-time {
    font-size: 12px;
  }
  &__status-icons {
    display: flex;
    margin-left: -10px;
  }
}
</style>

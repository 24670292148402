<template>
  <auth-layout>
    <transition name="fade">
      <b-container v-if="appLoaded" class="auth-view">
        <b-alert v-if="tokenInvalid" show variant="warning" class="auth-success auth-element">
          <div class="text-center">
            {{ $t('message.auth.resetLinkExpired') }}
            <div>
              <b-link :to="{ name: 'password-reset' }">{{ $t('message.auth.passwordReset') }}</b-link>
            </div>
          </div>
        </b-alert>
        <b-alert v-else-if="formSent" show variant="success" class="auth-success auth-element">
          {{ successResponse }}
          <div v-if="passwordResetSuccess">
            {{ $t('message.auth.canSignIn') }}
            <div>
              <b-link :to="{ name: 'auth' }">{{ $t('message.auth.signIn') }}</b-link>
            </div>
          </div>
        </b-alert>
        <template v-else-if="uid && token">
          <b-card class="auth-form-container">
            <b-card-title class="text-center">
              {{ $t('message.auth.setNewPassword') }}
            </b-card-title>
            <b-form-group :label="$t('message.auth.password')">
              <b-form-input
                id="new_password1"
                v-model="resetConfirmForm.new_password1"
                type="password"
                required
                class="form-control"
              >
              </b-form-input>
            </b-form-group>
            <response-errors-messages
              field="new_password1"
              :response-errors="responseErrors"
            ></response-errors-messages>
            <b-form-group :label="$t('message.auth.confirmPassword')">
              <b-form-input
                id="new_password2"
                v-model="resetConfirmForm.new_password2"
                type="password"
                required
                class="form-control"
              >
              </b-form-input>
            </b-form-group>
            <response-errors-messages
              field="new_password2"
              :response-errors="responseErrors"
            ></response-errors-messages>
            <b-button
              v-shortkey="[resetConfirmFormIsEmpty || sending ? null : 'enter']"
              variant="success"
              block
              size="lg"
              :disabled="resetConfirmFormIsEmpty || sending"
              class="auth-btn text-uppercase"
              @click="resetConfirm()"
              @shortkey="resetConfirm()"
            >
              {{ $t('message.auth.send') }}
            </b-button>
          </b-card>
        </template>
        <template v-else>
          <b-card class="auth-element auth-card">
            <b-card-title class="text-center">
              {{ $t('message.auth.passwordReset') }}
            </b-card-title>
            <b-form-group label="E-mail">
              <b-form-input id="email" v-model="resetForm.email" type="email" required class="form-control">
              </b-form-input>
            </b-form-group>
            <response-errors-messages field="email" :response-errors="responseErrors"></response-errors-messages>
            <b-button
              v-shortkey="[resetFormIsEmpty || sending ? null : 'enter']"
              variant="success"
              block
              size="lg"
              :disabled="resetFormIsEmpty || sending"
              class="auth-btn text-uppercase"
              @click="reset()"
              @shortkey="reset()"
            >
              {{ $t('message.auth.send') }}
            </b-button>
          </b-card>
        </template>
      </b-container>
    </transition>
  </auth-layout>
</template>

<script>
import AuthLayout from '@/layouts/AuthLayout'
import { mapActions } from 'vuex'
import { responseErrorsHandler } from '../../../mixins'
import ResponseErrorsMessages from '../../../components/ResponseErrorsMessages'

export default {
  name: 'PasswordReset',
  components: { ResponseErrorsMessages, AuthLayout },
  mixins: [responseErrorsHandler],
  props: {
    uid: {
      type: String,
      default: '',
    },
    token: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      appLoaded: false,
      sending: false,
      formSent: false,
      tokenInvalid: false,
      passwordResetSuccess: false,
      successResponse: '',
      resetForm: {
        email: '',
      },
      resetConfirmForm: {
        new_password1: '',
        new_password2: '',
      },
    }
  },
  computed: {
    resetFormIsEmpty() {
      return this.resetForm.email === ''
    },
    resetConfirmFormIsEmpty() {
      return this.resetConfirmForm.new_password1 === '' || this.resetConfirmForm.new_password2 === ''
    },
  },
  methods: {
    ...mapActions('auth', ['resetPassword', 'resetPasswordConfirm']),
    reset() {
      this.sending = true
      this.clearResponseErrors()
      this.resetPassword({
        resetData: this.resetForm,
        onSuccess: (data) => {
          this.successResponse = data.detail
          this.formSent = true
        },
        onError: (errorResponse) => {
          this.sending = false
          if (errorResponse?.status === 400) this.setResponseErrors(errorResponse?.data)
        },
      })
    },
    resetConfirm() {
      this.sending = true
      this.clearResponseErrors()
      this.resetPasswordConfirm({
        resetConfirmData: {
          ...this.resetConfirmForm,
          uid: this.uid,
          token: this.token,
        },
        onSuccess: (data) => {
          this.successResponse = data.detail
          this.formSent = true
          this.passwordResetSuccess = true
        },
        onError: (errorResponse) => {
          this.sending = false
          if (errorResponse?.status === 400) {
            if (errorResponse?.data?.hasOwnProperty('token')) {
              this.tokenInvalid = true
            } else {
              this.setResponseErrors(errorResponse?.data)
            }
          }
        },
      })
    },
  },
  mounted() {
    setTimeout(() => {
      this.appLoaded = true
    }, 300)
  },
}
</script>

<style scoped lang="scss">
.auth-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 360px;
  padding: 0 20px;
}
.auth-form {
  width: 100%;
}
.auth-card {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  margin: 0 auto;
}

.auth-success {
  width: 100%;
  max-width: 400px;
  text-align: center;
  margin: 0 auto;
}

@media (max-width: 500px) {
  .auth-card-container {
    width: 100%;
    padding: 0;
  }
  .auth-logo {
    img {
      width: 220px;
    }
  }
}
</style>

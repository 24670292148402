<template>
  <b-container class="overflow-y-auto settings-view">
    <div class="widget">
      <b-overlay :show="requestLoading" class="h-100" no-wrap spinner-variant="primary" />
      <div class="widget-body-wrapper pl-0">
        <b-row>
          <b-col cols="12">
            <b-breadcrumb class="bg-white px-3 pt-3">
              <b-breadcrumb-item :to="{ name: 'settings' }">
                {{ $t('message.settings.settings') }}
              </b-breadcrumb-item>
              <b-breadcrumb-item :to="{ name: 'permissions' }" active>
                {{ $t('message.permissions.permissionsSettings') }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </b-col>
        </b-row>
        <b-row class="justify-content-center">
          <b-col cols="12">
            <div class="administration-section">
              <i class="fa fa-clock pr-3 pt-1"></i>{{ $t('message.permissions.ordersPermissions') }}
            </div>

            <div class="administration-section-content mt-4">
              <b-table-simple bordered class="text-center">
                <b-tbody>
                  <b-tr>
                    <b-td>
                      <h6>{{ $t('message.permissions.pastHistoryOrdersView') }}</h6>
                    </b-td>

                    <b-td class="text-left">
                      <price-input
                        v-model="form.past_days_orders_waiter"
                        :label="$t('message.employeeForm.employeeTypes.waiter')"
                        :append-text="$tc('message.permissions.days', form.past_days_orders_waiter)"
                        class="mb-3"
                      />
                      <price-input
                        v-model="form.past_days_orders_manager"
                        :label="$t('message.employeeForm.employeeTypes.manager')"
                        :append-text="$tc('message.permissions.days', form.past_days_orders_manager)"
                      />
                    </b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>
                      <h6>{{ $t('message.permissions.updateFinishedOrders') }}</h6>
                    </b-td>

                    <b-td class="text-left">
                      <b-form-checkbox v-model="form.finished_orders_update_waiter" size="lg" switch class="mb-3">
                        {{ $t('message.employeeForm.employeeTypes.waiter') }}
                      </b-form-checkbox>
                      <b-form-checkbox v-model="form.finished_orders_update_manager" size="lg" switch>
                        {{ $t('message.employeeForm.employeeTypes.manager') }}
                      </b-form-checkbox>
                    </b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>
                      <h6>{{ $t('message.permissions.orderPaymentPaidChange') }}</h6>
                    </b-td>

                    <b-td class="text-left">
                      <b-form-checkbox
                        v-model="form.order_payment_type_paid_change_waiter"
                        size="lg"
                        switch
                        class="mb-3"
                      >
                        {{ $t('message.employeeForm.employeeTypes.waiter') }}
                      </b-form-checkbox>
                      <b-form-checkbox v-model="form.order_payment_type_paid_change_manager" size="lg" switch>
                        {{ $t('message.employeeForm.employeeTypes.manager') }}
                      </b-form-checkbox>
                    </b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>
                      <h6>{{ $t('message.permissions.createPaidOrders') }}</h6>
                    </b-td>

                    <b-td class="text-left">
                      <b-form-checkbox v-model="form.create_paid_orders_waiter" size="lg" switch class="mb-3">
                        {{ $t('message.employeeForm.employeeTypes.waiter') }}
                      </b-form-checkbox>
                      <b-form-checkbox v-model="form.create_paid_orders_manager" size="lg" switch>
                        {{ $t('message.employeeForm.employeeTypes.manager') }}
                      </b-form-checkbox>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-center">
          <b-col cols="12">
            <div class="administration-section">
              <i class="fa fa-user pr-3 pt-1"></i>{{ $t('message.permissions.employeesPermissions') }}
            </div>
            <div class="administration-section-content mt-4">
              <b-table-simple bordered class="text-center">
                <b-tbody>
                  <b-tr>
                    <b-td>
                      <h6>{{ $t('message.permissions.manageEmployees') }}</h6>
                    </b-td>
                    <b-td class="text-left">
                      <b-form-checkbox v-model="form.manage_employees_waiter" size="lg" switch>
                        {{ $t('message.employeeForm.employeeTypes.waiter') }}
                      </b-form-checkbox>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="widget-footer-container">
        <b-col cols="9" class="widget-footer widget-footer--without-shadow border-0">
          <b-button block variant="success" @click="validateAndSave()">
            {{ $t('message.administration.saveChanges') }}
          </b-button>
        </b-col>
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { settingsMixins } from '@/mixins'
import PriceInput from '@/components/PriceInput'

const STORE = 'settings'
export default {
  name: 'Permissions',
  components: {
    PriceInput,
  },
  mixins: [settingsMixins],
  data() {
    return {
      requestLoading: false,
      form: {
        past_days_orders_waiter: 0,
        past_days_orders_manager: 0,
        finished_orders_update_waiter: false,
        finished_orders_update_manager: false,
        order_payment_type_paid_change_waiter: false,
        order_payment_type_paid_change_manager: false,
        create_paid_orders_manager: false,
        create_paid_orders_waiter: false,
        manage_employees_waiter: false,
      },
    }
  },
  computed: {
    ...mapGetters(STORE, ['localizationPermissions']),
  },
  methods: {
    ...mapMutations(STORE, ['setLocalizationPermissions']),
    save() {
      this.requestLoading = true
      this.setLocalizationPermissions(this.form)
      this.savePreferences('permissions', {
        formData: this.form,
        onSuccess: () => {
          this.requestLoading = false
        },
        onError: () => {
          this.requestLoading = false
        },
      })
    },
  },
  created() {
    if (Object.values(this.localizationPermissions).length > 0) {
      this.form = { ...this.form, ...this.localizationPermissions }
    }
  },
}
</script>

<template>
  <b-list-group-item
    :active="isActive"
    class="custom-list-group-item custom-list-group-item-slim list-group-item--draggable"
    @click="onClick"
  >
    <div class="d-flex align-items-center">
      <i class="fas fa-align-justify fa-fw mr-2 drag-icon" />
      <span>{{ choiceGroup.name }}</span>
      <div class="choice-title" v-html="getCategorySizeLabel"></div>
    </div>

    <div class="d-flex align-items-center">
      <TooltipButton :id="'group_' + choiceGroup.id" variant="link">
        <b-badge :variant="isActive || !choiceGroup.meals_count ? 'light' : 'secondary'" pill>{{
          choiceGroup.meals_count
        }}</b-badge>
        <template #tooltipText>{{ $t('message.menu.choiceGroupMealsCount') }}</template>
      </TooltipButton>
    </div>
  </b-list-group-item>
</template>

<script>
import { mapGetters } from 'vuex'
import TooltipButton from '@/components/TooltipButton'

const STORE = '_menuEdit'

export default {
  name: 'ChoiceGroupsListElement',
  components: { TooltipButton },
  props: {
    choiceGroup: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      mealsTree: '_menu/mealsTree',
      activeMenuId: `${STORE}/getActiveMenuId`,
    }),
    getCategorySizeLabel() {
      let s = ''
      const meals = this.choiceGroup.meals
      let categories = {}
      this.mealsTree(this.activeMenuId)?.forEach((cat) => {
        cat.sizes.forEach((size) => {
          if (size.meals.some((m) => meals.includes(m.id))) {
            if (!categories[size.category]) categories[size.category] = { sizes: [] }
            categories[size.category].sizes.push(
              size.name === 'default' ? this.$t('message.menu.defaultSizeName') : size.name
            )
          }
          if (categories[cat.id]) categories[cat.id].name = cat.name
        })
      })
      Object.values(categories).forEach((cat) => {
        s += cat.name
        s +=
          cat.sizes?.length > 0 ? '<span class="choice-title__size"> [ ' + cat.sizes.join(', ') + ' ]</span>, ' : ', '
      })
      return s.slice(0, -2)
    },
  },
  methods: {
    onClick() {
      this.$emit('onClick', this.choiceGroup.id)
    },
  },
}
</script>
<style lang="scss">
.choice-title {
  margin-left: 20px;
  opacity: 0.9;
  font-size: 12px;
  &__size {
    font-size: 11px;
  }
}
</style>

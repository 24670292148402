import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { getField, updateField } from 'vuex-map-fields'
import { IEditMenuState } from '@/types/interface'

const getDefaultState = (): IEditMenuState => ({
  activeMenuId: 0,
  categories: [],
  sizes: [],
  multiChoiceGroups: [],
  singleChoiceGroups: [],
  mealGroups: [],
  selectedCategoryId: null,
  mealsTree: [],
  form: null,
  menuElement: 'categories',
  loaders: {
    form: false,
    categorySizes: false,
    entries: false,
    mealsTree: false,
  },
  differentPrices: false,
  externalMenuSource: null,
  externalMenuSourceName: '',
  externalMenuBrandId: '',
  saving: false,
  isEdited: false,
  editedMenusIds: [],
})

const state: IEditMenuState = getDefaultState()

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
    resetState: (state: IEditMenuState) => {
      Object.assign(state, getDefaultState())
    },
  },
  getters: {
    ...getters,
    getField,
  },
}

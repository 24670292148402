import { API } from '@/services/api/api'

const getBillingPeriods = async ({ commit }, payload) => {
  const { loading, filters = {}, onSuccess } = payload
  if (loading) commit('setBillingPeriodsRequest', 'loading')
  const { error, data } = await API.getBillingPeriods(filters)
  if (!error && data) {
    commit('setBillingPeriods', data.results)
    commit('setBillingPeriodsRequest', 'success')
    if (onSuccess) onSuccess(data.count)
  } else {
    commit('setBillingPeriodsRequest', 'error')
  }
}

const closeBillingPeriod = async ({}, billingPeriodID) => {
  const { error } = await API.closeBillingPeriod(billingPeriodID)
  if (!error) {
    window.location.reload()
  }
}

export default {
  getBillingPeriods,
  closeBillingPeriod,
}

import { Decimal } from 'decimal.js'
import { DISCOUNT_CHOICES, HALF_PIZZA_PRICE } from '../common/constants'
import { join } from 'lodash'

export const posErestoMixins = {
  methods: {
    getSingleMealFullPrice(meal, onlyPrice = false) {
      // Meal single full price - without discount and quantity
      const { singleChoiceGroupEntries, multiChoiceGroupEntries, size, complexMeal, submeals } = meal

      let price = new Decimal(0)
      // SCGS, MCGS
      let sumOfSingleChoiceEntries = new Decimal(0)
      let sumOfMultiChoiceEntries = new Decimal(0)
      if (singleChoiceGroupEntries) {
        singleChoiceGroupEntries.forEach((entry) => {
          sumOfSingleChoiceEntries = sumOfSingleChoiceEntries.plus(entry.price)
        })
      }
      if (multiChoiceGroupEntries) {
        multiChoiceGroupEntries.forEach((entry) => {
          sumOfMultiChoiceEntries = sumOfMultiChoiceEntries.plus(entry.price)
        })
      }
      if (size.price) price = price.add(size.price)
      price = price.add(sumOfSingleChoiceEntries).add(sumOfMultiChoiceEntries)
      // ComplexMeal
      let sumOfSubmealsChoiceEntries = new Decimal(0)
      if (complexMeal && submeals) {
        // Always add Single- and MultiChoiceEntries price
        // Add mealSetItem price to price
        // Add sumb od choiceEntries price to price
        submeals.forEach((submeal) => {
          if (this.isNormalMealSet && submeal.price) price = price.plus(submeal.price)
          if (submeal.multiChoiceGroupEntries) {
            submeal.multiChoiceGroupEntries.forEach((entry) => {
              sumOfSubmealsChoiceEntries = sumOfSubmealsChoiceEntries.plus(entry.price)
            })
          }
          if (submeal.singleChoiceGroupEntries) {
            submeal.singleChoiceGroupEntries.forEach((entry) => {
              sumOfSubmealsChoiceEntries = sumOfSubmealsChoiceEntries.plus(entry.price)
            })
          }
        })
        // add submeals choice price only for half pizza complex meal
        if (!this.isNormalMealSet) {
          price = price.plus(sumOfSubmealsChoiceEntries)
        }
      }
      if (!onlyPrice)
        return {
          price,
          sumOfSingleChoiceEntries,
          sumOfMultiChoiceEntries,
          sumOfSubmealsChoiceEntries,
        }
      else return price
    },
    getMealFullPrice(meal) {
      // Meal full price - without discount
      const singleMealFullPrice = this.getSingleMealFullPrice(meal, true)
      if (!singleMealFullPrice) return 0
      return this.multiplyMealPriceByQuantity(singleMealFullPrice, meal)
    },
    getSingleMealTotalPrice(meal) {
      // Meal single total price - with discount, but without quantity
      const singleMealFullPrice = this.getSingleMealFullPrice(meal)
      if (!singleMealFullPrice) return 0
      const { sumOfSingleChoiceEntries, sumOfMultiChoiceEntries, sumOfSubmealsChoiceEntries } = singleMealFullPrice
      let { price } = singleMealFullPrice

      // Discount
      if (meal.discount) {
        price = price.minus(price.times(meal.discount).dividedBy(100))
      } else if (meal.promotionalPrice) {
        price = new Decimal(meal.promotionalPrice)
          .plus(sumOfMultiChoiceEntries)
          .plus(sumOfSingleChoiceEntries)
          .plus(sumOfSubmealsChoiceEntries)
      } else if (meal.discount_value) {
        price = price.minus(meal.discount_value)
      }
      return price
    },
    getMealTotalPrice(meal) {
      // Meal total price - with discount
      const price = this.getSingleMealTotalPrice(meal)
      return this.multiplyMealPriceByQuantity(price, meal)
    },
    multiplyMealPriceByQuantity(price, meal) {
      // Quantity
      const quantity = this.checkPriceInput(meal.quantity.toString())
      if (quantity) {
        price = this.priceDecimal(price.times(quantity))
      }
      return price
    },
    checkPriceInput(value) {
      const inputValue = value.replace(',', '.') // input can be with comma or dot
      const patt = /^\d*(\.\d+)?$/
      if (patt.test(inputValue)) return inputValue
      else return null
    },
    getHalfPizzaSubmealName(submeal) {
      return submeal?.name ? `${submeal.name}` : ''
    },
    setHalfPizzaMealNameAndPrice(form, halfPizzaPriceType, halfPizzaAdditionalPrice) {
      // If halfPizzaPrice is set and it's variant with more expensive's half price
      if (halfPizzaPriceType && halfPizzaPriceType === HALF_PIZZA_PRICE.MORE_EXPENSIVE_HALF) {
        form.size.price = new Decimal(
          Math.max(
            ...form.submeals.map((submeal) => {
              return submeal.price
            })
          )
        ).toFixed(2)
      } else {
        // otherwise standard price is the average of 2 halves
        form.size.price = form.submeals
          .map((submeal) => new Decimal(submeal?.price || 0))
          .reduce((s1, s2) => s1.plus(s2))
          .dividedBy(2)
          .toFixed(2)
      }
      if (halfPizzaAdditionalPrice) {
        form.size.price = new Decimal(form.size.price).plus(new Decimal(halfPizzaAdditionalPrice)).toFixed(2)
      }
      form.name = `${this.getHalfPizzaSubmealName(form.submeals[0])} / ${this.getHalfPizzaSubmealName(
        form.submeals[1]
      )}`
    },
    getHalfPizzaMultiChoiceGroups(mcgs, halfPizzaMcgHalfPrice) {
      // returns multi_choice_groups with half price for entries
      if (!halfPizzaMcgHalfPrice) return [...mcgs]
      const halfMcgs = []
      mcgs = JSON.parse(JSON.stringify(mcgs))
      mcgs.forEach((mcg) => {
        const halfMcgEntries = []
        mcg.entries.forEach((entry) => {
          entry.price = new Decimal(entry.price).dividedBy(2).toFixed(2)
          halfMcgEntries.push(entry)
        })
        mcg.entries = halfMcgEntries
        halfMcgs.push(mcg)
      })
      return halfMcgs
    },
    cleanSubmeal(submeal) {
      //  pops out unimportant data from submeal
      delete submeal.ingredientsList
      delete submeal.meal_group
      delete submeal.single_choice_groups
      delete submeal.singleChoiceGroups
      delete submeal.multi_choice_groups
      delete submeal.multiChoiceGroups
      delete submeal.sizes
      delete submeal.meals
      delete submeal.submeals
      delete submeal.discount_sorted
      delete submeal.count
      delete submeal.single_price
      if (this.isHalfPizza) {
        // Price of HalfPizza complexMeal does nto depend directly on size.price of submeals, but on price of whole meal
        // (adjusted by additional half_pizza price settings)
        delete submeal.price
      }
    },
    getSubmealParseClean(mealName) {
      // returns clean meal_name instead of 1/2 meal_name
      return this.isHalfPizza ? mealName.substring(4) : mealName
    },
    getCategoryMealsCount(category) {
      return category.sizes.map((size) => size.meals.length).reduce((a, b) => a + b)
    },
    sortSubmealsDiscounts(form, mealSetItems) {
      // Group submeals by cat and sizes
      // Sort discounts per discount type
      // Assign discounts to submeals, so the meal with highest meal's price will get lowest discount
      // Only percent discount and discount value
      let groupedByCatAndSizes = []
      form.submeals.forEach((submeal, index) => {
        const originalMealSetItem = mealSetItems[index]
        const itemCategoryId = originalMealSetItem.category.id
        const itemSizes = originalMealSetItem.sizes
        const itemDiscount = {
          discount_type: originalMealSetItem.discount_type || null,
          discount_value: originalMealSetItem.discount_value || 0,
        }
        const submealData = { index, price: this.getMealFullPrice(submeal) }

        // find group of same cat and sizes in groupedByCatAndSizes
        let item = groupedByCatAndSizes.find((item) => {
          const sameCategory = item.category_id === itemCategoryId
          const sameSizes = item.sizes.every((s) => itemSizes.map((s) => s.id).includes(s.id))
          return sameCategory && sameSizes
        })

        // create new group of cat & sizes if necessary
        if (!item) {
          groupedByCatAndSizes.push({
            category_id: itemCategoryId,
            sizes: itemSizes,
            submeals: [],
            discounts: [],
          })
          item = groupedByCatAndSizes[groupedByCatAndSizes.length - 1]
        }
        // add submeal's index and fullPrice to list of submeals in group
        item.submeals.push(submealData)
        // add submeal's discount to list of discounts in group
        item.discounts.push(itemDiscount)
      })
      // Don't sort discounts for single grouped items or when no discount_values
      groupedByCatAndSizes = groupedByCatAndSizes.filter((g) => g.submeals.length >= 2)
      groupedByCatAndSizes = groupedByCatAndSizes.filter((g) => g.discounts.some((d) => d.discount_value))
      // sort every submeal and discount array by submeal price and assign lowest discount to highest price
      groupedByCatAndSizes.forEach((group) => {
        // sort by price asc
        // eslint-disable-next-line no-undef
        group.submeals = _.orderBy(group.submeals, ['price', (o, index) => index], ['desc', 'asc'])
        // find first not-null discount to apply to meals with null discount_type
        const groupDiscountType = group.discounts.find((discount) => discount.discount_type !== null).discount_type
        group.discounts.forEach((discount) => {
          if (!discount.discount_type) discount.discount_type = groupDiscountType
        })
        // SORT DISCOUNTS
        if (group.discounts[0].discount_type === DISCOUNT_CHOICES.DISCOUNT_CHOICE_PROMO_PRICE) {
          // PROMO_PRICE
          // sort by discount type and value asc
          // eslint-disable-next-line no-undef
          group.discounts = _.sortBy(group.discounts, ['discount_type', 'discount_value'])
          // sort promoPrice discounts by their impact on submeals prices
          // 1 - for every discount, calculate impact of discount value for every submeal's price
          // impact = 100 * (price - promoPrice)/price
          group.discounts.forEach((discount) => {
            discount.submeals_impact = group.submeals.map((submeal) => {
              if (!submeal.price || submeal.price == 0) return 0
              else return new Decimal(submeal.price).minus(discount.discount_value).dividedBy(submeal.price).toFixed(2)
            })
          })
          // 2 - sort discounts by the impact of discount on submeal's price, desc
          // eslint-disable-next-line no-undef
          group.discounts = _.orderBy(group.discounts, (discount, index) => discount.submeals_impact[index], 'desc')
        } else {
          // DISCOUNT, DISCOUNT_VALUE
          // sort by discount type and value asc
          // eslint-disable-next-line no-undef
          group.discounts = _.sortBy(group.discounts, ['discount_type', 'discount_value'])
        }
        // assign discounts to form's submeals based on index in group's submeals array
        group.submeals.forEach((submeal, index) => {
          // assign discount value to correct submeal field (discount/discount_value) based on type of discount
          // or clear (set null) discount if no type of discount should be assigned
          const submealDiscountValue = group.discounts[index].discount_value
            ? group.discounts[index].discount_value
            : null
          if (group.discounts[index].discount_type === DISCOUNT_CHOICES.DISCOUNT_CHOICE_PERCENT) {
            form.submeals[submeal.index].discount = submealDiscountValue
          } else if (group.discounts[index].discount_type === DISCOUNT_CHOICES.DISCOUNT_CHOICE_VALUE) {
            form.submeals[submeal.index].discount_value = submealDiscountValue
          } else if (group.discounts[index].discount_type === DISCOUNT_CHOICES.DISCOUNT_CHOICE_PROMO_PRICE) {
            form.submeals[submeal.index].promotionalPrice = submealDiscountValue
          } else {
            form.submeals[submeal.index].discount = null
            form.submeals[submeal.index].discount_value = null
          }
          if (!submealDiscountValue) form.submeals[submeal.index].discount_type = null
          // flag to show indicator/tooltipButton next to submeal price to explain price sorting
          form.submeals[submeal.index].discount_sorted = true
          // recalculate price of submeal
          form.submeals[submeal.index].price = this.getMealTotalPrice(form.submeals[submeal.index])
        })
      })
    },
  },
}

<template>
  <div class="compare-meals">
    <div v-if="mappedItem" class="compare-meals__left-side">
      <div class="compare-meals__meal-title text-right">
        <div class="item-name font-weight-bold">{{ mappedItem.name }}</div>
        <span
          class="mapping-row__source mr-2"
          :style="{ 'background-color': sourceColor, color: invertColor(sourceColor, true) }"
        >
          {{ sourceName }}
        </span>
        <template v-if="!isEntry">
          <span class="mr-2">{{ mappedItem.category_name }} <i class="ml-2">•</i> </span>
          <span class="mr-2"
            >{{ mappedItem.size_name === 'default' ? $t('message.menu.defaultSizeName') : mappedItem.size_name || '' }}
            <i class="ml-2">•</i>
          </span>
        </template>
        <template v-else>
          <span class="mr-2">{{ mappedItem.group_name }} <i class="ml-2">•</i> </span>
        </template>
        <span>{{ priceDecimal(mappedItem.price) }} {{ currency }}</span>
      </div>
    </div>
    <div class="line-arrow line-arrow--dashed" :class="[getRightSide ? 'line-arrow--green' : 'line-arrow--red']" />
    <div class="compare-meals__right-side">
      <div v-if="getRightSide" class="compare-meals__meal-title">
        <div class="item-name font-weight-bold">{{ getRightSide.name }}</div>
        <template v-if="!isEntry">
          <span class="mr-2">{{ getRightSide.category_name }} <i class="ml-2">•</i></span>
          <span class="mr-2"
            >{{
              getRightSide.size_name === 'default' ? $t('message.menu.defaultSizeName') : getRightSide.size_name || ''
            }}
            <i class="ml-2">•</i></span
          >
        </template>
        <template v-else>
          <span class="mr-2">{{ getRightSide.group_name }} <i class="ml-2">•</i></span>
        </template>
        <span>{{ priceDecimal(getRightSide.price) }} {{ currency }}</span>
      </div>
      <span v-else class="compare-meals__empty-text">{{ $t('message.mapping.selectMealToMap') }}</span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { menuSourcesMixins } from '@/mixins'
import { invertColor } from '@/utils/common'

export default {
  name: 'MappingCompareMeals',
  mixins: [menuSourcesMixins],
  data() {
    return {
      invertColor,
    }
  },
  computed: {
    ...mapGetters(['currency']),
    ...mapGetters('auth', ['getSourceById']),
    ...mapGetters('_mapping', ['mappedItem', 'selectedBrandId']),
    isEntry() {
      return this.mappedItem?.entry_type
    },
    sourceColor() {
      return this.getSourceColor(this.mappedItem.source)
    },
    sourceName() {
      return this.getSourceById(this.selectedBrandId, this.mappedItem.source)?.name
    },
    getRightSide() {
      return this.mappedItem?.[`mapped_${this.isEntry ? 'entry' : 'meal'}`]
    },
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
.compare-meals {
  position: relative;
  display: flex;
  border-bottom: 1px solid $gray-300;
  border-top: 1px solid $gray-300;
  width: 100%;

  &__left-side,
  &__right-side {
    width: 50%;
    padding: 1rem;
  }
  &__left-side {
    border-right: 2px solid $gray-300;
    padding-right: 2rem;
  }
  &__right-side {
    padding-left: 2rem;
  }

  &__meal-title {
    font-size: 14px;
    white-space: nowrap;
  }
  &__empty-text {
    color: $gray-500;
    display: flex;
    height: 100%;
    align-items: center;
  }
}
.item-name {
  font-size: 16px;
}
</style>

<template>
  <div class="widget">
    <div class="widget-body-wrapper">
      <b-row>
        <b-col>
          <b-breadcrumb class="bg-white">
            <b-breadcrumb-item :to="{ name: 'settings' }">{{ $t('message.settings.settings') }} </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'employees-list' }"
              >{{ $t('message.settings.employeesList') }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{ $t('message.settings.addEditEmployee') }} </b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
      </b-row>
      <b-overlay :show="isLoading" spinner-variant="primary" spinner-small>
        <b-row>
          <b-col xs="12" md="8" lg="6" class="mx-auto">
            <b-form>
              <b-form-group>
                <b-form-select
                  v-model="formInputs.role"
                  v-validate="'required'"
                  name="role"
                  class="form-control"
                  :disabled="!!$route.params.id"
                  :options="employeeTypesFiltered"
                  text-field="name"
                >
                  <template #first>
                    <b-form-select-option value="" disabled
                      >{{ $t('message.employeeForm.employeeType') }} *</b-form-select-option
                    >
                  </template>
                </b-form-select>
                <input-error-msg field="role" :_errors="errors" :response-errors="responseErrors" />
              </b-form-group>
              <b-form-group>
                <b-form-input
                  id="employee-name"
                  v-model="formInputs.name"
                  v-validate="'required'"
                  type="text"
                  class="form-control"
                  name="name"
                  :placeholder="`${$t('message.employeeForm.employeeName')} *`"
                >
                </b-form-input>
                <input-error-msg field="name" :_errors="errors" :response-errors="responseErrors" />
              </b-form-group>
              <b-form-group>
                <b-form-input
                  id="employee-phone"
                  v-model="formInputs.phone"
                  v-validate="formInputs.role !== ROLE.MANAGER ? 'required|numeric|min:9' : ''"
                  type="text"
                  name="phone"
                  :placeholder="`${$t('message.employeeForm.phoneNumber')} *`"
                >
                </b-form-input>
                <input-error-msg field="phone" :_errors="errors" :response-errors="responseErrors" />
              </b-form-group>
              <b-form-group v-if="false">
                <b-form-input
                  v-if="formInputs.role === 'waiter'"
                  id="employee-role"
                  v-model="formInputs.pin"
                  type="text"
                  name="pin"
                  placeholder="PIN"
                  data-vv-as="PIN"
                >
                </b-form-input>
                <input-error-msg field="pin" :_errors="errors" :response-errors="responseErrors" />
              </b-form-group>
              <b-form-group>
                <b-form-input
                  id="employee-email"
                  v-model="formInputs.email"
                  v-validate="'required|email'"
                  type="email"
                  name="email"
                  :placeholder="`${$t('message.employeeForm.email')} *`"
                >
                </b-form-input>
                <input-error-msg field="email" :_errors="errors" :response-errors="responseErrors" />
              </b-form-group>
              <div class="form-group">
                <input
                  ref="password"
                  v-model="formInputs.password"
                  v-validate="{ rules: { required: !$route.params.id } }"
                  type="password"
                  class="form-control"
                  :class="{ password: $route.params.id }"
                  name="password"
                  :placeholder="getPasswordPlaceholder()"
                />
                <input-error-msg field="password" :_errors="errors" :response-errors="responseErrors" />
                <input
                  v-validate="passwordConfirmRules"
                  type="password"
                  class="form-control mt-3"
                  :class="{ password: $route.params.id }"
                  name="confirmPassword"
                  :placeholder="getConfirmPasswordPlaceholder()"
                />
                <input-error-msg field="confirmPassword" :_errors="errors" :response-errors="responseErrors" />
              </div>
              <template v-if="formInputs.role === 'courier'">
                <b-form-group>
                  <b-form-select
                    v-model="formInputs.courier_type"
                    v-validate="'required'"
                    class="form-control"
                    name="courier_type"
                    :options="courierTypes"
                    text-field="name"
                  >
                    <template #first>
                      <b-form-select-option value="" disabled
                        >{{ $t('message.employeeForm.courierType') }} *</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                  <input-error-msg field="courier_type" :_errors="errors" :response-errors="responseErrors" />
                </b-form-group>
                <b-form-group>
                  <b-form-select
                    v-model="formInputs.mean_of_transport"
                    v-validate="'required'"
                    class="form-control"
                    name="mean_of_transport"
                    :options="courierTransportMethods"
                    text-field="name"
                  >
                    <template #first>
                      <b-form-select-option value="" disabled
                        >{{ $t('message.employeeForm.courierTransportMethod') }} *</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                  <input-error-msg field="mean_of_transport" :_errors="errors" :response-errors="responseErrors" />
                </b-form-group>
              </template>
              <template v-if="$hasModuleAccess('billing-periods')">
                <b-row v-if="formInputs.role !== ROLE.MANAGER" class="mt-3">
                  <b-col sm="8">
                    <input
                      id="salary-per-hour-is-active"
                      v-model="formInputs.salary_config.per_hour_is_active"
                      type="checkbox"
                    />
                    <label for="salary-per-hour-is-active">{{ $t('message.employeeForm.salaryPerHour') }}</label>
                  </b-col>
                  <b-col sm="4">
                    <price-input
                      v-model="formInputs.salary_config.per_hour"
                      :append-text="currency"
                      :disabled="!formInputs.salary_config.per_hour_is_active"
                    ></price-input>
                  </b-col>
                </b-row>
                <input-error-msg field="salary_config.per_hour" :_errors="errors" :response-errors="responseErrors" />

                <template v-if="formInputs.role === 'courier'">
                  <b-row class="mt-3">
                    <b-col sm="8">
                      <input
                        id="salary-per-km-is-active"
                        v-model="formInputs.salary_config.per_km_is_active"
                        type="checkbox"
                      />
                      <label for="salary-per-km-is-active">{{ $t('message.employeeForm.salaryPerKm') }}</label>
                    </b-col>
                    <b-col sm="4">
                      <price-input
                        v-model="formInputs.salary_config.per_km"
                        :append-text="currency"
                        :disabled="!formInputs.salary_config.per_km_is_active"
                      ></price-input>
                    </b-col>
                  </b-row>
                  <input-error-msg field="salary_config.per_km" :_errors="errors" :response-errors="responseErrors" />
                  <b-row class="mt-3">
                    <b-col sm="8">
                      <input
                        id="salary-per-delivery-is-active"
                        v-model="formInputs.salary_config.per_delivery_is_active"
                        type="checkbox"
                      />
                      <label for="salary-per-delivery-is-active">{{
                        $t('message.employeeForm.salaryPerDelivery')
                      }}</label>
                    </b-col>
                    <b-col sm="4">
                      <price-input
                        v-model="formInputs.salary_config.per_delivery"
                        :append-text="currency"
                        :disabled="!formInputs.salary_config.per_delivery_is_active"
                      ></price-input>
                    </b-col>
                  </b-row>
                  <input-error-msg
                    field="salary_config.per_delivery"
                    :_errors="errors"
                    :response-errors="responseErrors"
                  />
                  <b-row class="my-3">
                    <b-col sm="8">
                      <input
                        id="salary-per-zone-delivery-is-active"
                        v-model="formInputs.salary_config.per_zone_delivery_is_active"
                        type="checkbox"
                      />
                      <label for="salary-per-zone-delivery-is-active">{{
                        $t('message.employeeForm.salaryPerZoneDelivery')
                      }}</label>
                    </b-col>
                  </b-row>
                </template>
              </template>
              <b-alert v-if="formInputs.role === 'courier'" show variant="info">
                {{ $t('message.employeeForm.appInfo') }}
              </b-alert>
              <b-button
                v-if="!$route.params.id"
                :disabled="isLoading"
                class="mt-3"
                block
                variant="success"
                @click.prevent="_createUser()"
                >{{ $t('message.employeeForm.addUserButton') }}
              </b-button>
              <b-button v-else class="mt-3" :disabled="isLoading" block variant="success" @click.prevent="_updateUser()"
                >{{ $t('message.employeeForm.saveChangesButton') }}
              </b-button>
              <b-alert v-if="errorMessage" show variant="danger" class="mt3">
                {{ errorMessage }}
              </b-alert>
              <b-alert v-if="showVerifyEmailMessage" show variant="success" class="mt-3">
                {{ $t('message.employeeForm.verifyEmailMessage') }}
              </b-alert>
            </b-form>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import store from './_store'
import { responseErrorsHandler } from '@/mixins'
const STORE_NAME = '_employeeEdit'
import PriceInput from '@/components/PriceInput'
import { ROLE } from '@/common/constants'
import { showSuccessToast } from '@/utils/common'

export default {
  name: 'EmployeeEdit',
  components: { PriceInput },
  mixins: [responseErrorsHandler],
  props: ['id'],
  data: function () {
    return {
      ROLE,
      isLoading: false,
      employeeTypes: [
        { name: this.$t('message.employeeForm.employeeTypes.waiter'), value: 'waiter' },
        { name: this.$t('message.employeeForm.employeeTypes.courier'), value: 'courier' },
        { name: this.$t('message.employeeForm.employeeTypes.manager'), value: 'manager' },
        { name: this.$t('message.employeeForm.employeeTypes.cook'), value: 'cook' },
      ],
      courierTypes: [
        { name: this.$t('message.employeeForm.courierTypes.core'), value: 1 },
        { name: this.$t('message.employeeForm.courierTypes.uber'), value: 2 },
      ],
      courierTransportMethods: [
        { name: this.$t('message.employeeForm.courierTransportMethods.car'), value: 'A' },
        { name: this.$t('message.employeeForm.courierTransportMethods.motorbike'), value: 'M' },
        { name: this.$t('message.employeeForm.courierTransportMethods.bike'), value: 'R' },
      ],
      formInputs: {
        role: '',
        name: '',
        phone: '',
        pin: null,
        email: '',
        password: '',
        courier_type: '',
        mean_of_transport: '',
        isActive: true,
        salary_config: {
          per_km: 0,
          per_km_is_active: false,
          per_hour: 0,
          per_hour_is_active: false,
          per_delivery: 0,
          per_delivery_is_active: false,
          per_zone_delivery_is_active: false,
        },
      },
      appSent: false,
      errorMessage: null,
      cachedOwnerEmail: false,
      showVerifyEmailMessage: false,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state._employeeEdit.user,
    }),
    ...mapGetters({
      currency: 'currency',
      hasUserRole: 'auth/hasUserRole',
    }),
    passwordConfirmRules() {
      return this.$route.params.id && !this.formInputs.password ? 'confirmed:password' : 'required|confirmed:password'
    },
    isEdit() {
      return !!this.$route.params.id
    },
    employeeTypesFiltered() {
      let employeeTypes = this.employeeTypes

      if (!this.hasUserRole([ROLE.OWNER, ROLE.MANAGER])) {
        // filters employeeTypes list to not include 'manager' or 'owner' if user !== manager
        employeeTypes = this.employeeTypes.filter((employeeType) => {
          return employeeType.value !== ROLE.MANAGER
        })
      }

      if (!this.isEdit) {
        if (!this.$hasModuleAccess(this.MODULES.kds)) {
          employeeTypes = this.employeeTypes.filter((type) => {
            return type.value !== ROLE.COOK
          })
        }
        if (this.$hasModuleAccess(this.MODULES.papu_3)) {
          employeeTypes = this.employeeTypes.filter((type) => {
            return type.value === ROLE.COURIER
          })
        }
      }

      return employeeTypes
    },
  },
  watch: {
    user(newVal) {
      if (this.$route.params.id) {
        this.fillForm(newVal)
        if (newVal.role === ROLE.OWNER) this.cachedOwnerEmail = newVal.email
      }
    },
  },
  beforeCreate() {
    if (!this.$store.state[STORE_NAME]) this.$store.registerModule(STORE_NAME, store)
  },
  async mounted() {
    if (this.$route.params.id) {
      this.$store.commit('_employeeEdit/setUserId', this.$route.params.id)
      this.isLoading = true
      await this.getUser()

      this.isLoading = false
    }
  },
  beforeDestroy() {
    this.setUser({})
    this.$store.unregisterModule(STORE_NAME)
  },
  methods: {
    ...mapActions(STORE_NAME, ['createUser', 'getUser', 'updateUser', 'sendCourierApp']),
    ...mapMutations(STORE_NAME, ['setUser']),
    async _createUser() {
      this.isLoading = true
      const result = await this.$validator.validate()
      if (result) {
        const { error } = await this.createUser(this.formInputs)
        if (!error) {
          this.clearResponseErrors({})
        } else {
          this.setResponseErrors(error?.response?.data)
        }
      }
      this.isLoading = false
    },
    fillForm(data) {
      if (!data) return false
      this.formInputs.role = data.role
      this.formInputs.name = data.name
      this.formInputs.phone = data.phone
      this.formInputs.email = data.email
      this.formInputs.pin = data.waiter ? data.waiter.pin : ''
      this.formInputs.courier_type = data.courier ? data.courier.courier_type : ''
      this.formInputs.mean_of_transport = data.courier ? data.courier.mean_of_transport : ''
      this.formInputs.isActive = data.is_active
      this.formInputs.salary_config = data.salary_config
        ? {
            per_hour: data.salary_config.per_hour,
            per_hour_is_active: data.salary_config.per_hour_is_active,
            per_km: data.salary_config.per_km,
            per_km_is_active: data.salary_config.per_km_is_active,
            per_delivery: data.salary_config.per_delivery,
            per_delivery_is_active: data.salary_config.per_delivery_is_active,
            per_zone_delivery_is_active: data.salary_config.per_zone_delivery_is_active,
          }
        : this.formInputs.salary_config
    },
    async _updateUser() {
      this.isLoading = true
      const result = await this.$validator.validate()
      if (result) {
        const { error } = await this.updateUser(this.formInputs)
        if (!error) {
          this.clearResponseErrors()
          this.checkIfShowVerifyEmailMessage()
          showSuccessToast(this.$toasted, this.$t('message.employeeForm.updateEmployeeSuccess'))
        } else if (error?.response?.data) {
          this.setResponseErrors(error?.response?.data)
        }
      }
      this.isLoading = false
    },
    getPasswordPlaceholder() {
      if (this.$route.params.id) {
        return '********'
      } else {
        return this.$t('message.employeeForm.password') + ' *'
      }
    },
    getConfirmPasswordPlaceholder() {
      if (this.$route.params.id) {
        return '********'
      } else {
        return this.$t('message.employeeForm.confirmPassword') + ' *'
      }
    },
    checkIfShowVerifyEmailMessage() {
      if (this.cachedOwnerEmail && this.cachedOwnerEmail !== this.formInputs.email) this.showVerifyEmailMessage = true
    },
  },
}
</script>

<style scoped>
.password::-webkit-input-placeholder {
  font-weight: bold;
}
.password::-moz-placeholder {
  font-weight: bold;
}
.password:-ms-input-placeholder {
  font-weight: bold;
}
.password:-o-input-placeholder {
  font-weight: bold;
}
</style>

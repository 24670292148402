import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { ICategory, IWorkStation } from '@/types/interface'

export interface WorkstationsState {
  workstations: IWorkStation[]
  workstation: IWorkStation | null
  mealCategories: Array<ICategory>
}

const getDefaultState = (): WorkstationsState => {
  return {
    workstations: [],
    workstation: null,
    mealCategories: [],
  }
}

const state: WorkstationsState = getDefaultState()

export default {
  name: '_workstations',
  namespaced: true,
  state,
  getters,
  mutations: {
    ...mutations,
    resetState: (state: WorkstationsState) => {
      Object.assign(state, getDefaultState())
    },
  },
  actions,
}

<template>
  <b-list-group flush>
    <b-list-group-item v-if="customer.name">
      <i class="fas fa-user-circle fa-fw mr-3" />{{ customer.name || '–' }}
    </b-list-group-item>
    <b-list-group-item v-if="customer.phone">
      <i class="fas fa-phone fa-fw mr-3" /> {{ customer.phone }}
    </b-list-group-item>
    <b-list-group-item v-if="customer.email">
      <i class="fas fa-envelope fa-fw mr-3" />{{ customer.email || '–' }}
    </b-list-group-item>
  </b-list-group>
</template>

<script>
export default {
  name: 'Customer',
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>

<style scoped lang="scss">

</style>

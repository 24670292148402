<template>
  <b-container class="overflow-y-auto settings-view">
    <div class="widget">
      <div class="widget-body-wrapper p-0">
        <b-row>
          <b-col cols="12">
            <b-breadcrumb class="bg-white px-3 pt-3">
              <b-breadcrumb-item :to="{ name: 'settings' }">
                {{ $t('message.settings.settings') }}
              </b-breadcrumb-item>
              <b-breadcrumb-item :to="{ name: 'administration' }" active>
                {{ $t('message.administration.settings') }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </b-col>
        </b-row>
        <div>
          <SettingsSection
            id="localization-config"
            icon="home"
            :title="$t('message.administration.localizationConfig.message')"
          >
            <table class="table table-striped table-bordered text-center">
              <tr>
                <td scope="col">{{ $t('message.administration.localizationConfig.localizationName') }}</td>
                <td>
                  <input v-model="form.localization_name" type="text" class="form-control" />
                </td>
              </tr>
            </table>
          </SettingsSection>
          <SettingsSection
            id="integrations-print-config"
            icon="print"
            :title="$t('message.administration.integrationsConfig.message')"
          >
            <b-table
              class="text-center"
              outlined
              :items="integrationsTableItems(print_integrations)"
              :fields="integrations_table_fields"
            >
              <template #head(text)="data">
                <span>
                  {{ $t('message.administration.integrationsConfig.receiptAuto') }}
                </span>
              </template>
              <template #head(switch)="data"><span></span></template>
              <template #cell(switch)="data">
                <label class="switch">
                  <input
                    type="checkbox"
                    :checked="getIntegrationModel(data.item.model)"
                    @change="changeIntegrationModel(data.item.model)"
                  />
                  <span class="slider round"></span>
                </label>
              </template>
            </b-table>
          </SettingsSection>
          <SettingsSection
            v-if="integrationsTableItems(new_order_status_integrations).length > 0"
            id="integrations-new-order-status-config"
            icon="folder-plus"
            :title="$t('message.administration.integrationsNewOrderStatus.message')"
          >
            <b-table
              class="text-center"
              thead-class="d-none"
              outlined
              :items="integrationsTableItems(new_order_status_integrations)"
              :fields="integrations_table_fields"
            >
              <template #head(text)="data"> </template>
              <template #head(switch)="data"> </template>
              <template #cell(switch)="data">
                <b-form-radio-group
                  class="flex-wrap"
                  :checked="getIntegrationModel(data.item.model)"
                  buttons
                  button-variant="outline-primary"
                  :options="new_order_status_options"
                  @change="changeIntegrationStatusModel(data.item.model, $event)"
                >
                </b-form-radio-group>
              </template>
            </b-table>
          </SettingsSection>
          <SettingsSection
            v-if="integrationsTableItems(new_order_status_integrations).length > 0"
            id="default-pos-view"
            icon="home"
            :title="$t('message.administration.defaultPosView.message')"
          >
            <table class="table table-striped table-bordered text-center">
              <thead>
                <tr>
                  <th scope="col" colspan="2">{{ $t('message.administration.defaultPosView.defaultView') }}</th>
                </tr>
              </thead>
              <tr class="row__images">
                <td scope="col">
                  <b-form-radio v-model="form.default_orders_view" name="defaultViewMapRadio" value="1">
                    <h6>{{ $t('message.administration.defaultPosView.map') }}</h6>
                    <img class="img-thumbnail" :src="defaultViewMap" />
                  </b-form-radio>
                </td>
                <td scope="col">
                  <b-form-radio v-model="form.default_orders_view" name="defaultViewListRadio" value="2">
                    <h6>{{ $t('message.administration.defaultPosView.list') }}</h6>
                    <img class="img-thumbnail" :src="defaultViewOrdersList" />
                  </b-form-radio>
                </td>
              </tr>
            </table>
          </SettingsSection>
          <SettingsSection
            id="orders-summary-time-filters"
            icon="clock"
            :title="$t('message.administration.ordersSummaryTimeFilters.message')"
          >
            <week-time-table
              ref="time_filters_week_time_table"
              v-model="form.time_filters"
              table-name="time_filters"
              @validationErrors="setValidationErrors"
            />
          </SettingsSection>
          <SettingsSection
            id="order-on-site-config"
            icon="clipboard-list"
            :title="$t('message.administration.orders.message')"
          >
            <table class="table table-striped table-bordered text-center">
              <thead>
                <tr>
                  <th scope="col">{{ $t('message.administration.orders.ordersCommonConfig') }}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tr>
                <td scope="col">{{ $t('message.administration.orders.manualSourceChoiceRequired') }}</td>
                <td scope="col">
                  <label class="switch">
                    <input v-model="form.menu_source_manual_choice_required" type="checkbox" />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <thead>
                <tr>
                  <th scope="col">{{ $t('message.administration.orders.orderOnSite') }}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tr v-if="hasFiscal">
                <td scope="col">{{ $t('message.administration.orders.printBillCheck') }}</td>
                <td scope="col">
                  <label class="switch">
                    <input v-model="form.order_onsite_bill_print" type="checkbox" />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr>
                <td scope="col">{{ $t('message.administration.orders.finishOrderCheck') }}</td>
                <td>
                  <label class="switch">
                    <input v-model="form.order_onsite_finish_order" type="checkbox" />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <template v-if="hasFiscal">
                <thead>
                  <tr>
                    <th scope="col">{{ $t('message.administration.orders.orderDelivery') }}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tr>
                  <td scope="col">{{ $t('message.administration.orders.printBillDeliveryCheck') }}</td>
                  <td>
                    <label class="switch">
                      <input v-model="form.order_delivery_auto_print_bill" type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                  </td>
                </tr>
              </template>
              <template v-if="hasFiscal">
                <thead>
                  <tr>
                    <th scope="col">{{ $t('message.administration.orders.orderDetails') }}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tr>
                  <td scope="col">{{ $t('message.administration.orders.closeOrderOnPrintBill') }}</td>
                  <td>
                    <label class="switch">
                      <input v-model="form.close_order_on_print_bill_button" type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                  </td>
                </tr>
              </template>
            </table>
          </SettingsSection>
          <SettingsSection
            v-if="hasFiscal"
            id="bill-config"
            icon="truck"
            :title="$t('message.administration.deliveryOnBill.message')"
          >
            <table class="table table-striped table-bordered text-center">
              <thead>
                <tr>
                  <th scope="col">{{ $t('message.administration.deliveryOnBill.delivery') }}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tr>
                <td scope="col">{{ $t('message.administration.deliveryOnBill.skipDeliveryOnBillAndInvoice') }}</td>
                <td>
                  <label class="switch">
                    <input v-model="form.skip_delivery_on_bill_and_invoice" type="checkbox" />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr>
                <td scope="col">{{ $t('message.administration.deliveryOnBill.vatRate') }}</td>
                <td scope="col">
                  <b-dropdown
                    id="dropdownMenuButton"
                    :disabled="form.skip_delivery_on_bill_and_invoice"
                    block
                    variant="primary"
                    menu-class="w-100"
                    :text="form.delivery_vat_symbol"
                  >
                    <b-dropdown-item
                      v-for="(vat, index) in getVatRates()"
                      :key="index"
                      type="button"
                      :value="vat.value"
                      :class="{ active: form.delivery_vat_symbol === vat.value }"
                      @click="form.delivery_vat_symbol = vat.value"
                    >
                      {{ vat.text }}
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
              <tr>
                <td scope="col">{{ $t('message.administration.deliveryOnBill.positionNameOnBill') }}</td>
                <td>
                  <input
                    v-model="form.delivery_string"
                    type="text"
                    :disabled="form.skip_delivery_on_bill_and_invoice"
                    class="form-control"
                  />
                </td>
              </tr>
            </table>
          </SettingsSection>
          <SettingsSection
            v-if="hasFiscal"
            id="tip-config"
            icon="comment-dollar"
            :title="$t('message.administration.tip.message')"
          >
            <table class="table table-striped table-bordered text-center">
              <thead>
                <tr>
                  <th scope="col">{{ $t('message.administration.tip.tips') }}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tr>
                <td scope="col">{{ $t('message.administration.tip.skipTipOnBillAndInvoice') }}</td>
                <td>
                  <label class="switch">
                    <input v-model="form.skip_tip_on_bill_and_invoice" type="checkbox" />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr>
                <td scope="col">{{ $t('message.administration.tip.vatRate') }}</td>
                <td scope="col">
                  <b-dropdown
                    id="dropdownMenuButton"
                    :disabled="form.skip_tip_on_bill_and_invoice"
                    block
                    variant="primary"
                    menu-class="w-100"
                    :text="form.tip_vat"
                  >
                    <b-dropdown-item
                      v-for="(vat, index) in getVatRates()"
                      :key="index"
                      type="button"
                      :value="vat.value"
                      :class="{ active: form.tip_vat === vat.value }"
                      @click="form.tip_vat = vat.value"
                    >
                      {{ vat.text }}
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
              <tr>
                <td scope="col">{{ $t('message.administration.tip.positionNameOnBill') }}</td>
                <td>
                  <input
                    v-model="form.tip_string"
                    type="text"
                    :disabled="form.skip_tip_on_bill_and_invoice"
                    class="form-control"
                  />
                </td>
              </tr>
            </table>
          </SettingsSection>

          <SettingsSection id="package-config" icon="box" :title="$t('package_settings')">
            <table class="table table-striped table-bordered text-center">
              <thead>
                <tr>
                  <th scope="col">{{ $t('packages') }}</th>
                  <th scope="col"></th>
                </tr>
              </thead>

              <tr>
                <td scope="col">{{ $t('package_vat_rate') }}</td>
                <td scope="col">
                  <b-dropdown
                    id="dropdownMenuButton"
                    block
                    variant="primary"
                    menu-class="w-100"
                    :text="form.package_price_vat"
                  >
                    <b-dropdown-item
                      v-for="(vat, index) in getVatRates()"
                      :key="index"
                      type="button"
                      :value="vat.value"
                      :class="{ active: form.package_price_vat === vat.value }"
                      @click="form.package_price_vat = vat.value"
                    >
                      {{ vat.text }}
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </table>
          </SettingsSection>

          <SettingsSection
            v-if="half_pizza_settings"
            id="half-pizza-config"
            icon="pizza-slice"
            :title="$t('message.administration.halfPizza.message')"
          >
            <b-table-simple bordered class="text-center">
              <b-tbody>
                <b-tr>
                  <b-td>
                    <h6>{{ $t('message.administration.halfPizza.halfPizzaPrice') }}</h6>
                  </b-td>
                  <b-td>
                    <b-form-group>
                      <b-form-radio-group
                        v-model="form.half_pizza_price"
                        buttons
                        stacked
                        button-variant="outline-primary"
                        class="btn-block"
                        :options="half_pizza_options"
                        name="half-pizza-radios"
                      >
                      </b-form-radio-group>
                    </b-form-group>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    <h6>{{ $t('message.administration.halfPizza.halfPizzaMcgPrice') }}</h6>
                  </b-td>
                  <b-td>
                    <b-form-group>
                      <b-form-radio-group
                        v-model="form.half_pizza_mcg_half_price"
                        buttons
                        stacked
                        button-variant="outline-primary"
                        class="btn-block"
                        :options="half_pizza_mcg_price_options"
                        name="half-pizza-mcg-radios"
                      >
                      </b-form-radio-group>
                    </b-form-group>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    <b-form-checkbox
                      v-model="form.half_pizza_additional_price_active"
                      button
                      :button-variant="form.half_pizza_additional_price_active ? 'primary' : 'secondary'"
                      @change="form.half_pizza_additional_price = ''"
                    >
                      {{ $t('message.administration.halfPizza.additionalPrice') }}
                    </b-form-checkbox>
                  </b-td>
                  <b-td>
                    <price-input
                      v-model="form.half_pizza_additional_price"
                      :append-text="currency"
                      :disabled="!form.half_pizza_additional_price_active"
                    >
                    </price-input>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </SettingsSection>
          <SettingsSection
            v-if="$hasModuleAccess('shifts')"
            id="shifts-config"
            icon="hourglass-half"
            :title="$t('message.administration.shifts.message')"
          >
            <b-table-simple bordered class="text-center">
              <b-thead>
                <tr>
                  <th scope="col">{{ $t('message.administration.shifts.oneDayBillingPeriods') }}</th>
                  <th scope="col"></th>
                </tr>
              </b-thead>
              <tr>
                <td scope="col">{{ $t('message.administration.shifts.closeBillingPeriod') }}</td>
                <td scope="col">
                  <label class="switch">
                    <input v-model="form.shift_close_billing_period" type="checkbox" />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
            </b-table-simple>
          </SettingsSection>
          <SettingsSection
            id="summary-config"
            icon="envelope-open-text"
            :title="$t('message.administration.summary.message')"
          >
            <b-table-simple bordered class="text-center">
              <b-thead>
                <b-tr>
                  <b-th>
                    {{ $t('message.administration.summary.summaries') }}
                  </b-th>
                  <b-th> </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>
                    {{ $t('message.administration.summary.summary_description') }}
                  </b-td>
                  <b-td>
                    <label class="switch">
                      <input v-model="form.send_summary" type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                  </b-td>
                </b-tr>
                <b-tr v-if="hasFiscal">
                  <b-td>
                    {{ $t('message.administration.summary.summary_fiscal_description') }}
                  </b-td>
                  <b-td>
                    <label class="switch">
                      <input v-model="form.send_fiscal_summary" type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </SettingsSection>
          <SettingsSection
            id="integrations-settings"
            icon="utensils"
            :title="$t('message.administration.integrationsSettings.message')"
          >
            <b-table-simple bordered class="text-center">
              <b-thead>
                <b-tr>
                  <b-th colspan="2"> UberEats </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>
                    {{ $t('message.administration.integrationsSettings.includeUberPromotionDescription') }}
                  </b-td>
                  <b-td>
                    <label class="switch">
                      <input v-model="form.include_uber_promotions" type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-table-simple bordered class="text-center">
              <b-thead>
                <b-tr>
                  <b-th colspan="2"> {{ $t('message.administration.integrationsSettings.takeawayLabel') }} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>
                    {{ $t('message.administration.integrationsSettings.subtractStampcardFromPrice') }}
                  </b-td>
                  <b-td>
                    <label class="switch">
                      <input v-model="form.takeaway_subtract_stampcard_discount_from_price" type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-table-simple
              v-if="getExistingIntegrationsList().includes(MENU_SOURCE_CHOICES.GLOVO.toString()) && hasFiscal"
              bordered
              class="text-center"
            >
              <b-thead>
                <b-tr>
                  <b-th colspan="2"> Glovo </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <tr>
                  <td>{{ $t('message.administration.integrationsSettings.glovoServiceChargeVat') }}</td>
                  <td>
                    <b-dropdown variant="primary" block :text="form.glovo_service_charge_vat">
                      <b-dropdown-item-button
                        v-for="(vat, index) in getVatRates()"
                        :key="index"
                        :active="form.glovo_service_charge_vat === vat.value"
                        @click="form.glovo_service_charge_vat = vat.value"
                      >
                        {{ vat.text }}
                      </b-dropdown-item-button>
                    </b-dropdown>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('message.administration.integrationsSettings.gloVoServiceChargeNameOnBill') }}</td>
                  <td>
                    <input v-model="form.glovo_service_charge_string" type="text" class="form-control" />
                  </td>
                </tr>
              </b-tbody>
            </b-table-simple>
          </SettingsSection>
          <SettingsSection
            id="integrations-settings"
            icon="bell"
            :title="$t('message.administration.notifications.message')"
          >
            <b-table-simple bordered class="text-center">
              <b-thead>
                <tr>
                  <th scope="col" colspan="2">{{ $t('message.administration.notifications.newOrder') }}</th>
                </tr>
              </b-thead>
              <tr>
                <td scope="col">{{ $t('message.administration.notifications.soundNotification') }}</td>
                <td scope="col">
                  <b-dropdown
                    id="newOrderNotificationSoundDropdown"
                    block
                    variant="outline-primary"
                    menu-class="w-100"
                    :text="getNotificationSoundText"
                  >
                    <b-dropdown-item
                      v-for="(sound, index) in notification_sounds"
                      :key="index"
                      type="button"
                      :value="sound.value"
                      :class="{ active: form.new_order_notification_sound === sound.value }"
                      @click="onNewOrderNotificationSoundChange(sound.value)"
                    >
                      {{ sound.text }}
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </b-table-simple>

            <b-table-simple v-if="$hasModuleAccess(MODULES.recommendations)" bordered class="text-center">
              <b-thead>
                <tr>
                  <th scope="col" colspan="2">{{ $t('newRecommendation') }}</th>
                </tr>
              </b-thead>
              <tr>
                <td scope="col">{{ $t('message.administration.notifications.soundNotification') }}</td>
                <td scope="col">
                  <b-dropdown
                    id="newOrderNotificationSoundDropdown"
                    block
                    variant="outline-primary"
                    menu-class="w-100"
                    :text="getRecommendationSoundText"
                  >
                    <b-dropdown-item
                      v-for="(sound, index) in notification_sounds"
                      :key="index"
                      type="button"
                      :value="sound.value"
                      :class="{ active: form.new_recommendation_sound === sound.value }"
                      @click="onNewRecommendationSoundChange(sound.value)"
                    >
                      {{ sound.text }}
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </b-table-simple>
          </SettingsSection>
        </div>
      </div>
      <div class="widget-footer-container">
        <b-col cols="9" class="widget-footer widget-footer--without-shadow border-0">
          <b-button block variant="success" :disabled="saving" @click="validateAndSave()">
            <b-spinner v-if="saving" small></b-spinner>
            <span>{{ $t('message.administration.saveChanges') }}</span>
          </b-button>
        </b-col>
      </div>
    </div>
  </b-container>
</template>

<script>
import { responseErrorsHandler, settingsMixins, vatMixins } from '@/mixins'
import WeekTimeTable from '@/components/WeekTimeTable'
import default_view_map from '../../../public/static/images/default_view_map.png'
import default_view_orders_list from '../../../public/static/images/default_view_orders_list.png'
import {
  MENU_SOURCE_CHOICES,
  DEFAULT_ORDERS_VIEW,
  HALF_PIZZA_PRICE,
  VAT_CHOICES_DE,
  VAT_CHOICES_PL,
  SOUNDS,
  MODULES
} from '@/common/constants'
import { Decimal } from 'decimal.js'
import { mapGetters, mapMutations } from 'vuex'
import PriceInput from '@/components/PriceInput'
import SettingsSection from '@/components/settings/SettingsSection'
import { getSoundFile } from '@/utils/common'

export default {
  name: 'Administration',
  components: {
    WeekTimeTable,
    PriceInput,
    SettingsSection,
  },
  mixins: [responseErrorsHandler, settingsMixins, vatMixins],
  data() {
    return {
      MENU_SOURCE_CHOICES: MENU_SOURCE_CHOICES,
      HALF_PIZZA_PRICE: HALF_PIZZA_PRICE,
      defaultViewMap: default_view_map,
      defaultViewOrdersList: default_view_orders_list,
      saving: false,
      form: {
        time_filters: this.setTimeFilters(),
        order_onsite_finish_order: false,
        order_onsite_bill_print: false,
        order_delivery_auto_print_bill: false,
        delivery_vat_symbol: null, // set @ created, where this.getDefaultVatSymbol() is available
        delivery_string: 'Dostawa',
        print_default_receipt: true,
        print_pyszne_receipt: true,
        print_restaumatic_receipt: true,
        print_upmenu_receipt: true,
        print_erestaurant_receipt: true,
        print_ubereats_receipt: true,
        print_new_erestaurant_receipt: true,
        print_groupon_receipt: true,
        print_ordersmart_receipt: true,
        new_order_status_pyszne: 'accepted',
        half_pizza_price: HALF_PIZZA_PRICE.AVERAGE,
        half_pizza_additional_price: '',
        half_pizza_additional_price_active: false,
        default_orders_view: DEFAULT_ORDERS_VIEW.COURIERS_MAP,
        shift_close_billing_period: false,
        half_pizza_mcg_half_price: true,
        send_summary: true,
        send_fiscal_summary: false,
        skip_delivery_on_bill_and_invoice: false,
        close_order_on_print_bill_button: false,
        menu_source_manual_choice_required: false,
        include_uber_promotions: false,
        takeaway_subtract_stampcard_discount_from_price: false,
        glovo_service_charge_string: '',
        glovo_service_charge_vat: null,
        skip_tip_on_bill_and_invoice: false,
        tip_string: 'Napiwek',
        tip_vat: null,
        localization_name: this.$store.getters['auth/getLocalizationName'],
        new_order_notification_sound: SOUNDS.CRICKETS,
        new_recommendation_sound: SOUNDS.REQUEST
      },
      //existingIntegrations: this.getExistingIntegrations(),
      half_pizza_settings: this.$store.getters['halfPizzaSettings'] || false,
      half_pizza_options: [
        { text: this.$t('message.administration.halfPizza.halfPizzaAvgPrice'), value: HALF_PIZZA_PRICE.AVERAGE },
        {
          text: this.$t('message.administration.halfPizza.halfPizzaMoreExpensive'),
          value: HALF_PIZZA_PRICE.MORE_EXPENSIVE_HALF,
        },
      ],
      half_pizza_mcg_price_options: [
        { text: this.$t('message.administration.halfPizza.halfPizzaMcgHalfPrice'), value: true },
        { text: this.$t('message.administration.halfPizza.halfPizzaMcgFullPrice'), value: false },
      ],
      integrations_table_fields: ['text', 'switch'],
      print_integrations: [
        {
          value: MENU_SOURCE_CHOICES.DEFAULT,
          text: this.$t('message.administration.integrationsConfig.phone'),
          model: 'print_default_receipt',
        },
        {
          value: MENU_SOURCE_CHOICES.PYSZNE,
          text: this.$t('message.administration.integrationsConfig.pyszne'),
          model: 'print_pyszne_receipt',
        },
        {
          value: MENU_SOURCE_CHOICES.ERESTAURANT,
          text: this.$t('message.administration.integrationsConfig.erestaurant'),
          model: 'print_erestaurant_receipt',
        },
        {
          value: MENU_SOURCE_CHOICES.RESTAUMATIC,
          text: this.$t('message.administration.integrationsConfig.restaumatic'),
          model: 'print_restaumatic_receipt',
        },
        {
          value: MENU_SOURCE_CHOICES.UPMENU,
          text: this.$t('message.administration.integrationsConfig.upmenu'),
          model: 'print_upmenu_receipt',
        },
        {
          value: MENU_SOURCE_CHOICES.UBEREATS,
          text: this.$t('message.administration.integrationsConfig.ubereats'),
          model: 'print_ubereats_receipt',
        },
        {
          value: MENU_SOURCE_CHOICES.NEW_ERESTAURANT,
          text: this.$t('message.administration.integrationsConfig.new_erestaurant'),
          model: 'print_new_erestaurant_receipt',
        },
        {
          value: MENU_SOURCE_CHOICES.GROUPON,
          text: this.$t('message.administration.integrationsConfig.groupon'),
          model: 'print_groupon_receipt',
        },
        {
          value: MENU_SOURCE_CHOICES.ORDER_SMART,
          text: this.$t('message.administration.integrationsConfig.ordersmart'),
          model: 'print_ordersmart_receipt',
        },
      ],
      new_order_status_integrations: [
        {
          value: MENU_SOURCE_CHOICES.PYSZNE,
          text: this.$t('message.administration.integrationsConfig.pyszne'),
          model: 'new_order_status_pyszne',
        },
      ],
      new_order_status_options: [
        { value: 'new', text: this.$t('message.ordersList.unaccepted') },
        { value: 'accepted', text: this.$t('message.ordersList.todo') },
      ],
      notification_sounds: [
        { value: 'none', text: this.$t('message.common.none') },
        ..._.map(SOUNDS, (sound) => {
          return { value: sound, text: sound.toLowerCase() }
        })
      ],
      validationErrors: {},
      MODULES
    }
  },
  computed: {
    ...mapGetters(['currency']),
    ...mapGetters({
      hasFiscal: 'auth/hasFiscal',
      menuSources: 'auth/getMenuSources',
      getExistingMenuSources: 'auth/getExistingMenuSources',
    }),
    getNotificationSoundText() {
      return this.notification_sounds.find((sound) => this.form.new_order_notification_sound === sound.value)?.text
    },
    getRecommendationSoundText() {
      return this.notification_sounds.find((sound) => this.form.new_recommendation_sound === sound.value)?.text
    },
  },
  created() {
    this.setInitialForm()
  },
  methods: {
    ...mapMutations('settings', ['setLocalizationPreferences']),
    setInitialForm() {
      this.localizationPreferences = this.$store.getters['settings/localizationPreferences']
      if (Object.values(this.localizationPreferences).length > 0) {
        this.form = {
          ...this.form,
          ...this.localizationPreferences,
          time_filters: this.localizationPreferences.time_filters || this.setTimeFilters(),
          delivery_vat_symbol: this.localizationPreferences.delivery_vat_symbol || this.getDefaultVatSymbol(),
          tip_vat: this.localizationPreferences.tip_vat || null,
        }
        this.form.half_pizza_additional_price_active = this.form.half_pizza_additional_price !== ''
      }
    },
    getDefaultVatSymbol() {
      const countryCode = this.$store.getters['auth/getCompany'].country
      if (countryCode === 'DE') {
        return VAT_CHOICES_DE['19']
      } else {
        return VAT_CHOICES_PL.A
      }
    },
    changeIntegrationModel(integrationModel) {
      this.form[integrationModel] = !this.form[integrationModel]
    },
    changeIntegrationStatusModel(integrationModel, status) {
      this.form[integrationModel] = status
    },
    getIntegrationModel(integrationModel) {
      return this.form[integrationModel]
    },
    setValidationErrors(value) {
      this.validationErrors[value.tableName] = value.errors
    },
    getExistingIntegrationsList() {
      return Object.keys(this.getExistingMenuSources)
    },
    integrationsTableItems(integrations) {
      return integrations.filter((integration) => {
        return integration.value in this.getExistingMenuSources
      })
    },
    onNewOrderNotificationSoundChange(value) {
      this.form.new_order_notification_sound = value
      this.playSound(value)      
    },
    onNewRecommendationSoundChange(value){
      this.form.new_recommendation_sound = value
      this.playSound(value)
    },    
    playSound(value){
      if (value !== 'none') {
        const sound = new Audio(getSoundFile(value))
        sound.play()
      }
    },
    validate() {
      this.$refs.time_filters_week_time_table.validateInputs()
    },
    async save() {
      this.saving = true
      const toSave = {
        ...this.form,
        half_pizza_additional_price: this.form.half_pizza_additional_price
          ? new Decimal(this.form.half_pizza_additional_price).toFixed(2)
          : '',
      }
      this.setLocalizationPreferences(toSave)
      sessionStorage.setItem('localizationPreferences', JSON.stringify(toSave))
      // reset of dateFilters defaulty used as date filters in order summary
      localStorage.removeItem('dateFilters')
      await this.savePreferences('preferences', { formData: toSave })
      // Dynamically set localization name
      this.$store.commit('auth/setLocalizationName', this.form.localization_name)
      this.saving = false
    },
  },
}
</script>

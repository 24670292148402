import { IOrderPapu3 } from '@/types/interface'
import { IOrdersPapu3State } from '.'

const setOrders = (state: IOrdersPapu3State, orders: IOrderPapu3[]) => {
  state.orders = orders
}

const setOrdersRequest = (state: IOrdersPapu3State, type: string) => {
  state.ordersRequest = type
}

const updateOrderList = (state: IOrdersPapu3State, order: IOrderPapu3) => {
  const found = state.orders.find((item: IOrderPapu3) => item.id === order.id)
  if (found !== undefined) {
    Object.assign(found, order)
  }
}

const removeOrderFromList = (state: IOrdersPapu3State, orderId: number) => {
  const index = state.orders.findIndex((item) => item.id === orderId)
  if (index !== -1) {
    state.orders.splice(index, 1)
  }
}

const setPrimaryFilter = (state: IOrdersPapu3State, payload: any) => {
  const { filter, value } = payload
  state.primaryFilters[filter] = value
}

export default {
  setOrders,
  setOrdersRequest,
  updateOrderList,
  removeOrderFromList,
  setPrimaryFilter,
}

<template>
  <div class="meal-edit-header">
    <span class="meal-edit-header__title">
      {{ title }}
    </span>
    <div class="meal-edit-header__right-side d-flex align-items-center">
      <b-button
        v-if="showHalfPizzaButton"
        class="half-switch-button"
        :variant="isHalfPizzaModeOn ? 'primary' : 'secondary'"
        @click="toggleHalfPizzaMode()"
      >
        <i class="fas fa-adjust" />
        <span>1/2</span>
      </b-button>
      <div class="meal-edit-header__price-container">
        <small>{{ $t('message.mealDetails.price') }}:</small>
        <span class="meal-edit-header__price ml-1">{{ totalPrice }} {{ currency }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MealEditHeader',
  props: {
    title: { type: String, required: true },
    showHalfPizzaButton: { type: Boolean, default: false },
    isHalfPizzaModeOn: { type: Boolean, default: false },
    toggleHalfPizzaMode: { type: Function, required: true },
    totalPrice: { type: String, default: '0.00' },
  },
  computed: {
    ...mapGetters(['currency']),
  },
}
</script>

<style lang="scss" scoped>
$header-height: 40px;

.meal-edit-header {
  display: flex;
  align-items: center;
  height: 40px;
  min-height: 40px;
  background-color: $gray-200;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 15px;
  box-shadow: 0 5px 14px rgba(39, 49, 58, 0.07);
  z-index: 100;
  &__title {
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &__right-side {
    margin-left: auto;
  }
  &__price {
    &-container {
      display: flex;
      align-items: center;
      height: $header-height;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      padding-left: 15px;
    }
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
  }
}
.half-switch-button {
  margin-right: 20px;
  height: $header-height;
  white-space: nowrap;
}
</style>

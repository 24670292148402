<template>
  <div>
    <b-row>
      <b-col v-if="responseErrors && responseErrors.non_field_errors" cols="12">
        <b-alert variant="danger" show>
          <response-error-msg field="non_field_errors" :response-errors="responseErrors" />
        </b-alert>
      </b-col>
      <b-col cols="12">
        <div class="meal-edit-footer">
          <div class="full-price">
            <span class="full-price__title">{{ $t('message.mealDetails.totalPrice') }}:</span>
            <span class="full-price__value">{{ totalPrice }} {{ currency }}</span>
          </div>
          <b-button
            v-shortkey="!submittedButtonDisabled ? ['enter'] : ['null']"
            block
            variant="success"
            size="lg"
            class="text-center"
            :disabled="submittedButtonDisabled"
            @click="onConfirm"
            @shortkey="onConfirm"
          >
            <kbd class="pb-0 pt-1 mr-3 bg-white text-dark">&crarr;</kbd>
            <span>{{ isEdit ? $t('message.orderFormFull.updateOrder') : $t('message.orderFormFull.placeOrder') }}</span>
            <b-spinner v-if="isLoading" class="ml-4" small />
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'OrderFormFooter',
  props: {
    responseErrors: {
      type: Object,
      required: true,
    },
    submittedButtonDisabled: {
      type: Boolean,
      default: false,
    },
    totalPrice: {
      type: String,
      default: '0.00',
    },
    onConfirm: {
      type: Function,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapGetters(['currency']),
  },
}
</script>

<style scoped>

</style>

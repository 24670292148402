import decimal from 'decimal.js'
import { MODULES } from '@/common/constants'

export const UtilsMixins = {
  data() {
    return {
      MODULES,
    }
  },
  methods: {
    isLoggedIn: function () {
      return sessionStorage.getItem('loggedIn')
    },
    priceDecimal: function (price, string = true) {
      if (price === null) price = '0'
      // returns fixed string or decimal
      // @ts-expect-error: Object is possibly 'undefined'.
      price = decimal.isDecimal(price) ? price : new decimal.Decimal(price)
      return string ? (!price ? '0' : price.toFixed(2)) : price
    },
    $hasModuleAccess(moduleName) {
      return this.$store.getters['modules/hasModuleAccess'](moduleName)
    },
  },
}

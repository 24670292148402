import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { __DEV__ } from '@/utils/global'

export class ApiAuthService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
    })
  }

  async axiosCall<T>(config: AxiosRequestConfig) {
    if (__DEV__)
      console.log(`%cREQUEST|${config.method}: ${config.url}`, 'background: #00F; color: #fff', 'Request: ', config)
    try {
      const response = await this.axiosInstance.request<T>(config)
      if (__DEV__) console.log(`%cSUCCESS|${config.method}: ${config.url}`, 'background: #0F0; color: #000', response)
      const { data }: { data: any } = response
      return { error: null, data }
    } catch (error) {
      if (__DEV__) console.log(`%cERROR|${config.method}: ${config.url}`, 'background: #F00; color: #BADA55', error)
      const data: any = null
      return { error, data }
    }
  }

  async register(data: any) {
    return this.axiosCall({ method: 'post', url: `auth/registration/`, data })
  }
  async login(data: any) {
    return this.axiosCall({ method: 'post', url: `auth/login/`, data })
  }
  async logout(headers: any) {
    return this.axiosCall({ method: 'post', url: `auth/logout/`, headers })
  }
  async resetPassword(data: any) {
    return this.axiosCall({ method: 'post', url: `/auth/password/reset/`, data })
  }
  async resetPasswordConfirm(data: any) {
    return this.axiosCall({ method: 'post', url: `/auth/password/reset/confirm/`, data })
  }
  async createDemo(data: any) {
    return this.axiosCall({ method: 'post', url: `api/integral/public-demo/`, data })
  }
}

export const APIAuth = new ApiAuthService()

<template>
  <b-modal
    id="mealsModal"
    ref="mealsModal"
    :title="$t('message.mealsModal.title')"
    :visible="visible"
    hide-header-close
    hide-footer
    @hidden="hide"
    @shown="focusOnSearch()"
  >
    <Meals :meals="meals" :chosen-meal="chosenMeal" @input="mealChosen" />
  </b-modal>
</template>

<script>
import Meals from './Meals'

export default {
  name: 'MealsModal',
  components: { Meals },
  props: ['meals', 'chosenMeal'],
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    },
    mealChosen(meal) {
      this.$emit('onMealChosen', meal)
      this.hide()
      this.$refs.mealsModal.hide()
    },
    focusOnSearch() {
      // Focus on input for searching meals
      if (this.$refs.mealsModal.$children.length > 0 && this.$refs.mealsModal.$children[0].$refs.mealsSearchInput) {
        this.$refs.mealsModal.$children[0].$refs.mealsSearchInput.focus()
      }
    },
  },
}
</script>

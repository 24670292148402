<template>
  <li class="nav-item">
    <b-button
      v-shortkey="['f2']"
      variant="success"
      class="nav-link nav-link-button"
      :class="{ 'router-link-exact-active': isNewOrderOnSiteActive }"
      @shortkey="isMultiBrand ? showBrandsModal({ orderOnSite: true }) : goToNewOrder((onsite = true))"
      @click="onClickTrigger((onsite = true))"
    >
      <slot name="content">
        <kbd class="nav-link__shortcut">F2</kbd>
        <i class="nav-link__icon fas fa-concierge-bell" aria-hidden="true" />
        <span class="nav-link__text">{{ $t('message.navMain.onSite') }}</span>
      </slot>
    </b-button>
  </li>
</template>

<script>
import { newOrderMixins } from '@/mixins/newOrderMixins'

export default {
  name: 'ItemNewOnsite',
  mixins: [newOrderMixins],
  computed: {
    isNewOrderOnSiteActive() {
      return this.$route.name === 'new-order' && this.$route.query.orderOnSite
    },
  },
}
</script>

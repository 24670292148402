import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { getField, updateField } from 'vuex-map-fields'
import {
  IMappedItem,
  IMappingMeal,
  MappingMode,
  IMappingEntry,
  MappingType,
  IMenuSource,
  IEditMenuState,
  IPagination,
} from '@/types/interface'

export interface MappingState {
  selectedBrandId: number | null
  selectedSourceId: number | null
  mappedList: Array<IMappedItem>
  mappedListPagination: IPagination
  mappedItem: IMappedItem | null
  mappingMealsList: Array<IMappingMeal>
  mappingSingleChoiceEntriesList: Array<IMappingEntry>
  mappingMultiChoiceEntriesList: Array<IMappingEntry>
  mappingMode: MappingMode
  selectedMappedTypeList: MappingType
  loaders: {
    mappedList: boolean
    mappingList: boolean
  }
  showMappedOnly: boolean
  showIgnoredOnly: boolean
  showDefaultMenuPositions: boolean
  order: { id: number; brand_id: number; menu_source: string; number: string } | null
  singleChoiceGroups: any
  multiChoiceGroups: any
}

const getDefaultState = (): MappingState => {
  return {
    selectedBrandId: null,
    selectedSourceId: null,
    selectedMappedTypeList: 'meal',
    mappedList: [],
    mappedListPagination: {
      pages: 0,
      page: 1,
      count: 0,
      next: null,
      previous: null,
    },
    mappedItem: null,
    mappingMealsList: [],
    mappingSingleChoiceEntriesList: [],
    mappingMultiChoiceEntriesList: [],
    mappingMode: 'static',
    loaders: {
      mappedList: false,
      mappingList: false,
    },
    showMappedOnly: false,
    showIgnoredOnly: false,
    showDefaultMenuPositions: false,
    order: null,
    singleChoiceGroups: {},
    multiChoiceGroups: {},
  }
}

const state: MappingState = getDefaultState()

export default {
  namespaced: true,
  state,
  getters: {
    ...getters,
    getField,
  },
  mutations: {
    ...mutations,
    updateField,
    resetState: (state: IEditMenuState) => {
      Object.assign(state, getDefaultState())
    },
  },
  actions,
}

import { OrdersSummaryState } from './index'
const setEmployeesList = (state: OrdersSummaryState, employeesList: OrdersSummaryState['employees']) => {
  state.employees = employeesList
}

const setEmployeesRequest = (state: OrdersSummaryState, value: string) => {
  state.employeesRequest = value
}

export default {
  setEmployeesList,
  setEmployeesRequest,
}

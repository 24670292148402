import { ORDER_STATUSES, POS_API_DATETIME_INPUT_FORMAT } from '../common/constants'

export const orderListFiltersMixinsPapu3 = {
  methods: {
    getPresentOrders(ordersList) {
      const future = this.$moment().add(24, 'hours')
      let orders = ordersList || this.orders
      return orders.filter((order) => {
        return this.$moment(order.promised_time, POS_API_DATETIME_INPUT_FORMAT).isSameOrBefore(future)
      })
    },
    getFutureOrders(ordersList) {
      const future = this.$moment().add(24, 'hours')
      let orders = ordersList || this.orders
      return orders.filter((order) => {
        return this.$moment(order.promised_time, POS_API_DATETIME_INPUT_FORMAT).isAfter(future)
      })
    },
    countOrdersByStatus(status) {
      let orders = []
      if (status === ORDER_STATUSES.ORDER_STATUS_NEW) {
        orders = this.orders
      } else {
        orders = this.getPresentOrders()
      }
      if (this.primaryFilters.brand) {
        orders = orders.filter((order) => {
          return order.restaurant.uuid === this.primaryFilters.brand.split('-').join('')
        })
      }
      if (status === ORDER_STATUSES.ORDER_STATUS_DELIVERING_IN) {
        orders = orders.filter((order) => {
          return [ORDER_STATUSES.ORDER_STATUS_DELIVERING_IN, ORDER_STATUSES.ORDER_STATUS_PICKED_UP].includes(
            order.status
          )
        })
      } else {
        orders = orders.filter((order) => {
          return order.status === status
        })
      }

      return orders.length
    },
    countOrdersByPickupType(pickupType) {
      let orders = this.getPresentOrders()
      if (this.primaryFilters.brand) {
        orders = orders.filter((order) => {
          return order.restaurant.uuid === this.primaryFilters.brand.split('-').join('')
        })
      }
      return orders.filter((order) => {
        return order.pickup_type === pickupType
      }).length
    },
  },
}

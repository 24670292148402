<template>
  <li class="nav-item">
    <TooltipButton
      v-if="tooltip"
      :id="id"
      variant="navbar"
      :pill="false"
      class="nav-item__tooltip"
      button-class="nav-link-button"
      :on-button-click="goToHelpPage"
    >
      <i class="nav-link__icon fas fa-question-circle" :aria-label="$t('message.navMain.canWeHelp')" />
      <template #tooltipText>
        <HelpData class="text-left p-2" />
        <b-button variant="primary" block class="mb-2" @click="reportBug()">
          <i class="fas fa-bug mr-2" />
          {{ $t('report_sugestion') }}
        </b-button>
      </template>
    </TooltipButton>
    <router-link v-else :to="{ name: helpPageName }" class="nav-link btn-navbar">
      <i class="nav-link__icon fas fa-question-circle" :aria-label="$t('message.navMain.canWeHelp')" />
      <span class="nav-link__text">{{ $t('message.navMain.help') }}</span>
    </router-link>
  </li>
</template>

<script>
import TooltipButton from '@/components/TooltipButton'
import HelpData from '@/components/navigation/HelpData'

export default {
  name: 'ItemHelp',
  components: { TooltipButton, HelpData },
  props: {
    tooltip: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: 'contactButton',
    },
  },
  data() {
    return {
      helpPageName: 'help',
    }
  },
  methods: {
    goToHelpPage() {
      this.$router.push({ name: this.helpPageName })
    },
    reportBug() {
      if (window.Ybug) {
        window.Ybug.open()
      }
    },
  },
}
</script>

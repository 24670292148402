import { SettingsState } from './index'
import { RootState } from '@/store'
import { GetterTree } from 'vuex'
import { ROLE } from '@/common/constants'

const defaultOrdersListView = (state: SettingsState) => state.localizationPreferences.default_orders_view
const defaultTimeFilters = (state: SettingsState) => state.localizationPreferences.time_filters
const localizationPreferences = (state: SettingsState) => state.localizationPreferences
const orderDeliveryAutoPrintBill = (state: SettingsState) =>
  state.localizationPreferences.order_delivery_auto_print_bill
const halfPizzaPrice = (state: SettingsState) => state.localizationPreferences.half_pizza_price
const halfPizzaAdditionalPrice = (state: SettingsState) => state.localizationPreferences.half_pizza_additional_price
const halfPizzaMcgHalfPrice = (state: SettingsState) => state.localizationPreferences.half_pizza_mcg_half_price
const erestoPreferences = (state: SettingsState) => state.erestoPreferences
const localizationPermissions = (state: SettingsState) => state.localizationPermissions
const permissionsPastDaysOrders = (
  state: SettingsState,
  getters: GetterTree<SettingsState, RootState>,
  rootState: RootState,
  rootGetters: any
) => {
  const isWaiter = rootGetters['auth/hasUserRole']([ROLE.WAITER])
  const isManager = rootGetters['auth/hasUserRole']([ROLE.MANAGER])

  if (isWaiter) return state.localizationPermissions.past_days_orders_waiter
  else if (isManager) return state.localizationPermissions.past_days_orders_manager
  else return 0
}

export default {
  defaultOrdersListView,
  defaultTimeFilters,
  localizationPreferences,
  orderDeliveryAutoPrintBill,
  halfPizzaPrice,
  halfPizzaAdditionalPrice,
  halfPizzaMcgHalfPrice,
  erestoPreferences,
  localizationPermissions,
  permissionsPastDaysOrders,
}

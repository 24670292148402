var render = function render(){var _vm=this,_c=_vm._self._c;return _c('l-marker',{attrs:{"lat-lng":_vm.coords,"icon":_vm.getOrderIcon()},on:{"mouseenter":function($event){return _vm.setIsHover(true)},"mouseleave":function($event){return _vm.setIsHover(false)},"click":function($event){return _vm.selectOrder(_vm.id)}}},[_c('l-tooltip',{class:[
      'order-marker-content',
      {
        'order-marker--selected': _vm.isSelected,
        'order-marker--hover': _vm.isHover,
      },
    ],attrs:{"options":{
      permanent: true,
      interactive: true,
      direction: 'top',
      offset: [0, -8],
      className: 'order-marker',
      maxWidth: 200,
    }}},[_c('div',{staticClass:"d-flex order-marker-content__wrapper",on:{"mouseenter":function($event){return _vm.setIsHover(true)},"mouseleave":function($event){return _vm.setIsHover(false)}}},[_c('Countdown',{class:{
          'font-weight-bold': _vm.isSelected || _vm.isHover,
        },attrs:{"date":_vm.promisedTime,"is-map":true,"is-map-tooltip":true,"show-left-time":false,"show-promised-time":false,"order-number":_vm.number,"show-order-number":true}}),_c('div',{staticClass:"order-marker-content__label d-flex align-items-center flex-fill text-truncate pl-1"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSelected || _vm.isHover),expression:"isSelected || isHover"}],staticClass:"order-marker__label"},[_vm._v(_vm._s(_vm.label))]),(!_vm.courier)?_c('i',{staticClass:"fas fa-user-times text-danger order-marker__courier-icon pr-1"}):_c('i',{staticClass:"fas fa-user-check text-success order-marker__courier-icon pr-1"})])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <b-table :items="Object.values(value)" :fields="fields" bordered table-class="weekTable">
      <template #head(weekday)="data">
        {{ fieldsNamed[0] }}
      </template>
      <template #head(startTime)="data">
        {{ fieldsNamed[1] }}
      </template>
      <template #head(endTime)="data">
        {{ fieldsNamed[2] }}
      </template>
      <template #cell(weekday)="data">
        {{ weekdays[data.index] }}
      </template>
      <template #cell(startTime)="data">
        <DateTimePicker
          :id="tableName + '_startTime_' + data.index"
          v-model="data.item.startTime"
          :time="data.item.startTime"
          @input="clearedValue($event, data.index, 'startTime')"
        />
        <input-error-msg :field="tableName + '_startTime_' + data.index" :_errors="errors"></input-error-msg>
      </template>
      <template #cell(endTime)="data">
        <DateTimePicker
          :id="tableName + '_endTime_' + data.index"
          v-model="data.item.endTime"
          :time="data.item.endTime"
          @input="clearedValue($event, data.index, 'endTime')"
        />
        <input-error-msg :field="tableName + '_endTime_' + data.index" :_errors="errors"></input-error-msg>
      </template>
    </b-table>
  </div>
</template>
<script>
import DateTimePicker from './DateTimePicker'

export default {
  name: 'WeekTimeTable',
  components: {
    DateTimePicker,
  },
  props: ['value', 'tableName'],
  data() {
    return {
      fieldsNamed: [
        this.$t('message.administration.ordersSummaryTimeFilters.weekday'),
        this.$t('message.administration.ordersSummaryTimeFilters.start'),
        this.$t('message.administration.ordersSummaryTimeFilters.end'),
      ],
      fields: ['weekday', 'startTime', 'endTime'],
      weekdays: [
        this.$t('message.administration.ordersSummaryTimeFilters.monday'),
        this.$t('message.administration.ordersSummaryTimeFilters.tuesday'),
        this.$t('message.administration.ordersSummaryTimeFilters.wednesday'),
        this.$t('message.administration.ordersSummaryTimeFilters.thursday'),
        this.$t('message.administration.ordersSummaryTimeFilters.friday'),
        this.$t('message.administration.ordersSummaryTimeFilters.saturday'),
        this.$t('message.administration.ordersSummaryTimeFilters.sunday'),
      ],
    }
  },
  mounted() {
    this.$on('validateWeekTimeTable', () => {
      this.validateInputs()
    })
  },
  methods: {
    clearedValue(value, index, time) {
      // settings emptied value to empty string, not null
      if (value === null) {
        this.value[index + 1][time] = ''
      }
    },
    validateInput(index, time) {
      let tableIndex = index + 1
      if (!this.value[tableIndex][time]) {
        if (time === 'startTime') {
          if (this.value[tableIndex].endTime) {
            this.errors.add({
              field: this.tableName + '_startTime_' + index,
              tableName: this.tableName,
              msg: this.$t('message.common.weekTimeTable.bothTimeFieldsMustBeFilled'),
            })
          } else {
            this.errors.items = this.errors.items.filter((error) => {
              return error.field !== this.tableName + '_startTime_' + index
            })
          }
        } else if (this.value[tableIndex].startTime) {
          this.errors.add({
            field: this.tableName + '_endTime_' + index,
            tableName: this.tableName,
            msg: this.$t('message.common.weekTimeTable.bothTimeFieldsMustBeFilled'),
          })
        } else {
          this.errors.items = this.errors.items.filter((error) => {
            return error.field !== this.tableName + '_endTime_' + index
          })
        }
      } else {
        if (time === 'startTime') {
          this.errors.items = this.errors.items.filter((error) => {
            return error.field !== this.tableName + '_startTime_' + index
          })
        } else {
          this.errors.items = this.errors.items.filter((error) => {
            return error.field !== this.tableName + '_endTime_' + index
          })
        }
      }
    },
    validateInputs() {
      Object.values(this.value).forEach((weekday, index) => {
        this.validateInput(index, 'startTime')
        this.validateInput(index, 'endTime')
      })
      let anyTableErrors = this.errors.items.filter((error) => {
        return error.tableName === this.tableName
      }).length
      this.$emit('validationErrors', { errors: anyTableErrors > 0, tableName: this.tableName })
    },
  },
}
</script>

<style scoped>
.weekTable {
  text-align: center;
}
</style>

import { mapGetters } from 'vuex'

export const mealsMixins = {
  data() {
    return {
      searchKeyword: '',
      searchMeals: [],
    }
  },
  computed: {
    ...mapGetters({
      menu: 'menu',
    }),
  },
  methods: {
    searchThroughMeals(meals = null) {
      // searches through meals or all meals and saves to searchMeals
      let mealsForSearching = meals || this.getAllMeals()
      this.searchMeals = []
      const words = this.searchKeyword.split(' ')
      if (words.length > 0 && words[0].length > 0) {
        mealsForSearching.forEach((meal) => {
          let include = true
          words.forEach((word) => {
            if (
              !meal.name.toLowerCase().includes(word.toLowerCase()) &&
              !meal.size.name.toLowerCase().includes(word.toLowerCase())
            ) {
              include = false
            }
          })
          if (include) {
            this.searchMeals.push(meal)
          }
        })
      }
    },
    getAllMeals() {
      // gets all meals for all categories and sizes
      let allMeals = []
      this.menu.categories.forEach((category) => {
        category.sizes.forEach((size) => {
          size.meals.forEach((meal) => {
            allMeals.push(meal)
          })
        })
      })
      return allMeals
    },
    findMealInMenu(meal) {
      if (!meal) return null
      let uuid = meal?.uuid || meal?.submeals?.[0]?.uuid
      if (!uuid) return null
      let menuMeal = null
      this.menu.categories.forEach((category) => {
        const size = category.sizes.find((size) => size.id === meal.size.id)
        if (size) menuMeal = size.meals.find((meal) => meal.uuid === uuid)
      })
      return menuMeal
    },
    getMealSizeName(meal) {
      if (!meal.size) return null
      let sizeName = meal.size.name
      if (sizeName === 'default') sizeName = ''
      return sizeName
    },
    getParsedMealDiscount(meal) {
      // returns parsed discount value based on discount, promotionalPrice, discount_value of meal
      // TODO: Translate
      if (meal.discount) return `- ${meal.discount} %`
      else if (meal.promotionalPrice)
        return `Cena promocyjna ${meal.promotionalPrice} ${this.$store.getters['currency']}`
      else if (meal.discount_value) return `- ${meal.discount_value} ${this.$store.getters['currency']}`
    },
  },
}

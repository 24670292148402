<template>
  <b-modal
    ref="modal"
    :title="title"
    :visible="visible"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    @keyup.enter.native="save"
  >
    <b-container>
      <price-input
        v-model.number="form.amount"
        :label="$t('message.billing.amount')"
        :append-text="currency"
        @keyup.enter.native="save"
      >
      </price-input>
      <input-error-msg field="amount" :_errors="errors" :response-errors="responseErrors" />
      <div class="form-group">
        <label for="comment">{{ $t('message.billing.comment') }}<span class="text-danger ml-1">*</span></label>
        <textarea
          id="comment"
          v-model="form.comment"
          v-validate="'required'"
          class="form-control"
          name="comment"
          rows="3"
          @keyup.enter.stop
        ></textarea>
        <input-error-msg field="comment" :_errors="errors" :response-errors="responseErrors" />
      </div>
      <response-error-msg field="non_field_errors" :response-errors="responseErrors" />
      <response-error-msg field="shift_id" :response-errors="responseErrors" />
    </b-container>
    <div slot="modal-footer">
      <button type="button" class="btn btn-secondary" @click="hide">{{ $t('message.billing.cancel') }}</button>
      <button type="button" class="btn btn-success" @click="save">
        <kbd class="pb-0 pt-1 mr-2 bg-white text-dark">&crarr;</kbd> {{ $t('message.billing.save') }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { responseErrorsHandler } from '@/mixins'
import PriceInput from '@/components/PriceInput'

export default {
  name: 'ShiftEntryModal',
  components: { PriceInput },
  mixins: [responseErrorsHandler],
  data() {
    return {
      visible: false,
      user: {},
      entryType: '',
      form: {},
    }
  },
  computed: {
    ...mapGetters(['currency']),
    title() {
      return this.form.type === 1
        ? this.$t('message.billing.paymentsFromCustomer')
        : this.$t('message.billing.operationalCash')
    },
  },
  methods: {
    ...mapActions({
      createShiftEntry: '_shiftsCurrent/createShiftEntry',
    }),
    resetForm() {
      this.form = {
        shift_id: '',
        amount: '',
        comment: '',
        type: '',
      }
    },
    show() {
      this.visible = true
    },
    hide() {
      this.resetForm()
      this.visible = false
    },
    save() {
      this.$validator.validate().then((result) => {
        if (result) {
          const request = {
            formData: this.form,
            onSuccess: () => {
              this.hide()
              this.clearResponseErrors({})
            },
            onError: (errors) => {
              this.setResponseErrors(errors)
            },
          }
          this.createShiftEntry(request)
        }
      })
    },
    assignFormData() {
      this.resetForm()
      this.form = {
        ...this.form,
        shift_id: this.user.last_shift.id,
        type: this.entryType,
      }
    },
  },
  mounted() {
    this.$eventBus.$on('onShowShiftEntryModal', (payload) => {
      const { user, entryType } = payload
      this.user = user
      this.entryType = entryType
      this.assignFormData()
      this.show()
    })
  },
  destroyed() {
    this.$eventBus.$off('onShowShiftEntryModal')
  },
}
</script>

<template>
  <div class="not-found">
    <div class="not-found__info">
      <p class="not-found__oops">Oops!</p>
      <div class="not-found__404-container">
        <p class="not-found__404">404</p>
      </div>
      <div>
        <p>Nic tutaj nie ma</p>
        <router-link :to="{ name: 'orders-list' }" tag="a" class="not-found__link"
          >Wróc do swoich zamówień<i class="fas fa-arrow-right"></i
        ></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound404',
}
</script>

<style scoped lang="scss">
.not-found {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;

  &__info {
    text-align: center;
  }
  &__oops {
    font-weight: 600;
    font-size: 48px;
    color: #616161;
  }
  &__404-container {
    position: relative;
  }
  &__404 {
    font-size: 448px;
    font-weight: bold;
    line-height: 500px;
    color: #1f2e54;
  }
  &__link {
    font-size: 18px;
    font-weight: 700;
    i {
      margin-left: 10px;
    }
  }
}
</style>

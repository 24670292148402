<template>
  <b-nav-item :to="{ name: 'orders-list' }" link-classes="btn-navbar">
    <kbd class="nav-link__shortcut">F3</kbd>
    <i v-shortkey="['f3']" class="nav-link__icon fas fa-clipboard-list" @shortkey="navigate()"></i>
    <span class="nav-link__text">{{ $t('message.navMain.orders') }}</span>
    <div v-if="newOrdersCount" class="nav-link__badge">
      <span>{{ newOrdersCount }}</span>
    </div>
  </b-nav-item>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ItemOrders',
  computed: {
    ...mapGetters(['newOrdersCount']),
  },
  methods: {
    navigate() {
      this.$router.push({ name: 'orders-list' })
    },
  },
}
</script>

<template>
  <div>
    <b-form-group class="mb-0">
      <template v-if="label" #label
        >{{ label }}
        <span v-if="requiredAtLabel" class="text-danger">*</span>
      </template>
      <b-input-group>
        <template #append>
          <b-input-group-text v-if="appendText" :class="!size ? 'input-group-text' : ''">{{
            appendText
          }}</b-input-group-text>
          <b-input-group-text v-else-if="info" :class="!size ? 'input-group-text' : ''">
            <TooltipButton :id="id" placement="top" button-class="defaultTooltipButton">
              ?
              <template #tooltipText>{{ info }}</template>
            </TooltipButton>
          </b-input-group-text>
        </template>
        <b-form-input
          :id="id"
          ref="input"
          v-validate="validatePrice"
          type="text"
          name="price"
          :disabled="disabled"
          :formatter="inputFormatter"
          :value="value"
          :placeholder="placeholder"
          :class="!size ? 'form-control-md' : ''"
          :size="size"
          @blur="updateValue"
          @input="updateValue"
        />
      </b-input-group>
    </b-form-group>
    <input-error-message v-if="validate && name" :_errors="errors" field="price" :small="false" />
  </div>
</template>

<script>
import TooltipButton from './TooltipButton'
import InputErrorMessage from '@/components/InputErrorMessage'

export default {
  name: 'PriceInput',
  components: { TooltipButton, InputErrorMessage },
  inject: ['parentValidator'],
  props: [
    'id',
    'validate',
    'value',
    'label',
    'requiredAtLabel',
    'appendText',
    'size',
    'placeholder',
    'info',
    'disabled',
    'name',
  ],
  computed: {
    validatePrice() {
      return this.validate ? 'required' : ''
    },
  },
  created() {
    this.$validator = this.parentValidator
  },
  methods: {
    inputFormatter(value) {
      // Replace comma with dot
      let v = value.replace(',', '.').replace(/[^\d.,]/, '')

      // Replace multiple dots with one
      v = v.replace(/(\.\d*)\./g, '$1')

      // Replace '.' to '0.'
      if (v === '.') v = v.replace(/\.$/g, '0.')

      return v
    },
    updateValue(value) {
      let type = 'input'
      if (typeof value === 'object' && value.type === 'blur') {
        type = 'blur'
        value = value.target.value
      }
      this.$emit(type, value)
    },
  },
}
</script>

<style scoped>
.form-control-md {
  height: auto;
}
.input-group-text {
  padding-top: 0.275rem;
  padding-bottom: 0.275rem;
}
</style>

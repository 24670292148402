import { IMealsTree, IMenuState } from '@/types/interface'

const setMealsTree = (state: IMenuState, { menuId, mealsTree }: { menuId: number; mealsTree: IMealsTree }) => {
  state.mealsTrees[menuId] = mealsTree
}

const setMealSets = (state: { mealSets: any }, payload: any) => {
  sessionStorage.setItem('mealSets', JSON.stringify(payload))
  state.mealSets = payload
}

export default {
  setMealsTree,
  setMealSets,
}

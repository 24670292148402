<template>
  <div class="orders-list-item">
    <b-row class="mb-2">
      <b-col cols="2" class="pr-0" @click="openOrderDetails()">
        <countdown
          :date="order.promised_time"
          :is-map="true"
          :set-border-radius="true"
          :on-time="order.delivery_type === DELIVERY_TYPES.ONTIME"
        />
      </b-col>
      <b-col cols="6" @click="openOrderDetails()">
        <div class="d-flex">
          <h6 class="number">#{{ order.order_number }}</h6>
          <div v-show="showCheckbox" class="ml-auto" @click.stop>
            <b-form-checkbox size="lg" :checked="chosen" @change="addOrderToChosenOrders()" />
          </div>
        </div>
        <div class="orders-list-item__status-icons">
          <TooltipButton
            v-if="order.comment"
            :id="'orderCommentTooltipButton_' + order.uuid"
            class="tooltip__button"
            size="md"
            variant="light"
            button-class="smallTooltipButton"
          >
            <i class="far fa-comment" />
            <template #tooltipText>{{ order.comment }}</template>
          </TooltipButton>
        </div>
      </b-col>
      <b-col cols="4" class="d-flex pl-0">
        <div
          v-if="
            ![PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT].includes(order.pickup_type) &&
            order.status !== ORDER_STATUSES.ORDER_STATUS_NEW
          "
          :id="`open-assign-courier-modal-btn_${order.uuid}`"
          class="btn-block assign-courier-button"
        >
          <b-button
            v-b-tooltip.hover.left
            block
            :variant="!!order.courier ? 'primary' : 'warning'"
            :class="['h-100', { 'p-1': !!order.courier }]"
            data-toggle="modal"
            data-target="#assign-courier-modal"
            :title="$t('message.ordersList.assignCourier')"
            @click="openAssignCourier()"
          >
            <small v-if="order.courier">{{ order.courier.name }}</small>
            <small v-else><i class="fas fa-user-plus" style="margin-right: 3px" /></small>
          </b-button>
        </div>
        <b-button v-else block size="sm" variant="light" class="assign-courier-button">
          <small>{{ getPickupTypeTranslate(order.pickup_type) }}</small></b-button
        >
      </b-col>
    </b-row>
    <b-row @click="openOrderDetails()">
      <b-col cols="8" class="d-flex flex-column justify-content-between">
        <div class="orders-list-item__address-container mb-1">
          <span v-if="order.pickup_type === PICKUP_TYPE_DELIVERY && !order.lat && !order.lon">
            <i
              v-b-tooltip.hover
              class="fas fa-exclamation-circle text-danger"
              :title="$t('message.ordersList.noAddress')"
            />
          </span>
          <i
            v-else-if="order.order_customer && order.order_customer.address"
            class="fas fa-map-marker-alt orders-list-item__address-icon fa-fw mr-1"
          />
          <span v-if="order.order_customer && order.order_customer.address" class="text-truncate">
            {{ getOrderFullAddress(order) }}
          </span>
        </div>
      </b-col>
      <b-col cols="4" class="d-flex text-right justify-content-end mt-1">
        <div class="payment">
          <span class="payment__price">{{ priceDecimal(order.price) }} {{ currency }}</span>
          <small class="payment__type">{{ order.payment_translated }} </small>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  DELIVERY_TYPES,
  ORDER_STATUSES,
  PICKUP_TYPE_DELIVERY,
  PICKUP_TYPE_ON_SITE,
  PICKUP_TYPE_TAKOUT,
} from '@/common/constants'
import { orderMixins } from '@/mixins'
import Countdown from '@/components/Countdown'
import TooltipButton from '@/components/TooltipButton'
import { mapGetters } from 'vuex'

export default {
  name: 'OrderListItemPapu3',
  components: {
    TooltipButton,
    Countdown,
  },
  mixins: [orderMixins],
  props: {
    order: {
      default: () => {
        return {}
      },
      type: Object,
    },
    chosen: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      PICKUP_TYPE_ON_SITE: PICKUP_TYPE_ON_SITE,
      PICKUP_TYPE_TAKOUT: PICKUP_TYPE_TAKOUT,
      PICKUP_TYPE_DELIVERY: PICKUP_TYPE_DELIVERY,
      DELIVERY_TYPES: DELIVERY_TYPES,
      ORDER_STATUSES: ORDER_STATUSES,
    }
  },
  computed: {
    ...mapGetters(['currency']),
    ...mapGetters('settings', ['localizationPreferences']),
    showCheckbox() {
      return this.order.status !== ORDER_STATUSES.ORDER_STATUS_NEW && this.order.pickup_type === PICKUP_TYPE_DELIVERY
    },
    isInRegeocode() {
      return this.ordersInRegeocode.includes(this.order.id)
    },
  },
  methods: {
    openOrderDetails() {
      this.$eventBus.$emit('onShowDetailsModal', { uuid: this.order.uuid })
    },
    openAssignCourier() {
      this.$emit('onOpenAssignCourier', this.order)
    },
    addOrderToChosenOrders() {
      this.$emit('onAddOrderToChosenOrders', this.order.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.orders-list-item {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #dde0e1;
  font-size: 13px;
  &:hover {
    background-color: #f3f5f7;
  }
  &__address-container {
    font-size: 14px;
    &:empty {
      display: none;
    }
  }
  &__meals {
    display: flex;
    flex-direction: row;
    color: $gray-600;
    font-size: 12px;
    align-items: center;
  }

  &__address-icon {
    color: #bdc2cd;
  }

  &__status-icons {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 120px;
  }
}
.number {
  margin-bottom: 4px;
  font-size: 16px;
}
.regeocode {
  display: flex;
  align-items: center;
  &-text {
    font-size: 12px;
    margin-left: 6px;
  }
  > .spinner-grow-sm {
    width: 12px;
    height: 12px;
  }
  &-failed-icon {
    font-size: 12px;
  }
}
.payment {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &__price {
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
  }
  &__type {
    font-size: 12px;
    color: $gray-700;
  }
}
</style>

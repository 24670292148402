import { API } from '@/services/api/api'
import { ActionContext } from 'vuex'
import { INewOrderState } from '@/store/modules/new-order'
import { RootState } from '@/store'
import { IOrderPapu3 } from '@/types/interface'

// API REFACTORING - NEED TESTING
const getOrders = async (
  { commit, rootState }: ActionContext<INewOrderState, RootState>,
  { filters = {}, loading = false, onSuccess }: any
) => {
  if (loading) commit('setOrdersRequest', 'loading')
  rootState.delayOrdersListRefresh = null

  const params = {
    archive: filters.archive,
    finished_order_choices: filters.finishedOrderChoices,
    menu_source: filters.menuSource,
    page: filters.page,
    finished: filters.finished,
    brand: filters.brand,
    search_keyword: filters.searchKeyword,
  }

  const { error, data } = await API.p3_getOrders(params)
  if (!error && data) {
    // eslint-disable-next-line camelcase
    const { results, count, price_sum, discount_sum, tip_sum } = data
    // If it's archive then response contains pagination,
    // if current list, then response is flat orders list
    const orders = results || data
    commit('setOrdersRequest', 'success')
    commit('setOrders', orders)
    // eslint-disable-next-line camelcase
    if (onSuccess) {
      onSuccess({
        count: count,
        price_sum: price_sum,
        discount_sum: discount_sum,
        tip_sum: tip_sum,
      })
    }
    commit(
      'setNewOrdersCount',
      orders.filter((order: IOrderPapu3) => {
        return order.status === 'new'
      }).length,
      { root: true }
    )
  } else {
    commit('setOrdersRequest', 'error')
  }
}

export default {
  getOrders,
}

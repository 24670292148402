<template>
  <b-button
    class="btn-filter"
    variant="light"
    :class="{
      active: isActive,
      'btn-filter--unaccepted': isUnaccepted,
    }"
    @click="onClick"
  >
    <i v-if="icon" class="fas" :class="icon" />
    <span>{{ text }}</span>
    <b-badge v-if="!noCount" variant="primary" pill class="ml-2">
      {{ count }}
    </b-badge>
  </b-button>
</template>

<script>
export default {
  name: 'FilterButton',
  props: {
    isUnaccepted: { type: Boolean, default: false },
    isActive: { type: Boolean, default: false },
    onClick: { type: Function, required: true },
    text: { type: String, default: '' },
    icon: { type: String, default: '' },
    count: { type: Number, default: 0 },
    noCount: { type: Boolean, default: false },
  },
}
</script>

<style scoped lang="scss">
.btn-filter {
  min-width: 80px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  margin-right: 1px;
  font-size: 13px;
  > i {
    font-size: 15px;
  }
  i + span {
    margin-left: 10px;
  }
  &--unaccepted {
    animation-name: blink;
    animation-duration: 1.7s;
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    animation-iteration-count: infinite;
    border-color: transparent;
    z-index: 100 !important;
    border-width: 2px;
  }
}

@include media-breakpoint-down(xs) {
  .btn-filter {
    i + span {
      display: none;
    }
  }
}

@media (max-width: 1200px) and (min-width: 1024px) {
  .btn-filter {
    padding: 10px 5px;
    i {
      display: none;
    }
  }
}

@media (max-width: 576px) {
  .btn-filter {
    height: 40px;
  }
}
@media (max-width: 1200px) {
  .btn-filter {
    font-size: 12px;
  }
}
</style>

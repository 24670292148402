<template>
  <b-nav-item :to="{ name: 'couriers-map' }" link-classes="btn-navbar">
    <kbd class="nav-link__shortcut">F4</kbd>
    <i v-shortkey="['f4']" class="nav-link__icon fas fa-map-marked-alt" @shortkey="navigate()"></i>
    <span class="nav-link__text">{{ $t('message.navMain.couriersMap') }}</span>
  </b-nav-item>
</template>

<script>
export default {
  name: 'ItemMap',
  methods: {
    navigate() {
      this.$router.push({ name: 'couriers-map' })
    },
  },
}
</script>

import { MEAL_STATUSES } from '@/common/constants'

export const mealsStatusMixins = {
  methods: {
    getStatusIconName(status) {
      switch (status) {
        case MEAL_STATUSES.TODO:
          return 'hourglass-start'
        case MEAL_STATUSES.DOING:
          return 'fire-alt'
        case MEAL_STATUSES.IN_OVEN:
          return 'dumpster-fire'
        case MEAL_STATUSES.DONE:
          return 'check'
      }
    },
    getStatusName(status) {
      switch (status) {
        case MEAL_STATUSES.TODO:
          return this.$t('message.kds.mealStatus.todo')
        case MEAL_STATUSES.DOING:
          return this.$t('message.kds.mealStatus.doing')
        case MEAL_STATUSES.IN_OVEN:
          return this.$t('message.kds.mealStatus.inOven')
        case MEAL_STATUSES.DONE:
          return this.$t('message.kds.mealStatus.done')
      }
    },
    getStatusColor(status) {
      switch (status) {
        case MEAL_STATUSES.TODO:
          return '#808080'
        case MEAL_STATUSES.DOING:
          return '#EA7015'
        case MEAL_STATUSES.IN_OVEN:
          return '#EA7015'
        case MEAL_STATUSES.DONE:
          return '#109E4D'
      }
    },
  },
}

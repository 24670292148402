<template>
  <component :is="navbar ? 'li' : 'div'" :class="navbar ? 'nav-item' : ''">
    <b-button
      :class="navbar ? 'nav-link nav-link-button' : ''"
      :variant="navbar ? 'navbar' : 'primary'"
      @click.prevent="openTawk"
    >
      <i class="nav-link__icon fas fa-comment mr-0" />
      <span v-if="withLabel">{{ $t('message.navMain.chat') }}</span>
    </b-button>
  </component>
</template>

<script>
export default {
  name: 'ItemChat',
  props: {
    navbar: {
      type: Boolean,
      default: false,
    },
    withLabel: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openTawk() {
      window.Tawk_API && window.Tawk_API.popup()
    },
  },
}
</script>

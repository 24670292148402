<template>
  <div class="mapped-table-wrapper mt-3 mb-3">
    <div v-if="!isDynamicMode" class="search-meal mb-3">
      <span v-if="searchKeyword" class="search-meal__remove" @click="clearSearch()">
        <i class="far fa-times-circle" />
      </span>
      <span v-else class="search-meal__remove">
        <i class="fas fa-search" />
      </span>
      <b-form-input
        id="search-mapped-meal-input"
        v-model="searchKeyword"
        class="search-meal__input"
        :class="{ 'search-meal__input--filled': searchKeyword }"
        :placeholder="$t('message.ordersList.search') + '...'"
      />
    </div>
    <div ref="mappingTable" class="table-container">
      <b-overlay :show="loadingList" spinner-variant="primary" spinner-small class="h-100">
        <b-table-simple class="table mapped-table" outlined>
          <b-thead head-variant="light">
            <tr>
              <th
                variant="secondary"
                scope="col"
                :colspan="getLeftColspan"
                class="text-center"
                style="border-right: 2px solid #ebecef"
              >
                {{
                  showDefaultMenuPositions
                    ? $t('message.mapping.table.positionFromMenu')
                    : $t('message.mapping.table.positionFromIntegration')
                }}
              </th>
              <th scope="col" :colspan="getRightColspan" class="text-center">
                {{
                  showDefaultMenuPositions
                    ? $t('message.mapping.table.positionFromIntegration')
                    : $t('message.mapping.table.positionFromMenu')
                }}
              </th>
            </tr>
            <tr>
              <th scope="col">{{ $t('message.orderDetails.orderSource') }}</th>
              <th scope="col">{{ $t('message.common.name') }}</th>
              <th scope="col">{{ getCategoryOrGroupLabel }}</th>
              <th v-if="isDynamicMode || !isEntryView" scope="col">{{ $t('message.common.size') }}</th>
              <th v-if="isDynamicMode" scope="col">{{ $t('message.common.group') }}</th>
              <th scope="col" style="border-right: 2px solid #ebecef">{{ $t('message.common.price') }}</th>
              <th scope="col" style="padding-left: 30px">{{ $t('message.orderDetails.orderSource') }}</th>
              <th scope="col">{{ $t('message.common.name') }}</th>
              <th scope="col">{{ getCategoryOrGroupLabel }}</th>
              <th v-if="isDynamicMode || !isEntryView" scope="col">{{ $t('message.common.size') }}</th>
              <th v-if="isDynamicMode" scope="col">{{ $t('message.common.group') }}</th>
              <th scope="col">{{ $t('message.common.price') }}</th>
              <th v-if="isDynamicMode || !isEntryView" scope="col">VAT</th>
              <th scope="col"></th>
              <th v-if="getActiveIntegrations.length > 0" scope="col"></th>
            </tr>
          </b-thead>
          <b-tbody>
            <template v-for="(item, idx) in mappedList">
              <template v-if="item.mapping_source_meals && item.mapping_source_meals.length > 0">
                <MappedTableRow
                  v-for="(mapping_source_item, index) in item.mapping_source_meals"
                  :key="'mapping_source_meal_' + mapping_source_item.id"
                  :mapped-item="item"
                  :fixed-mapping-item="mapping_source_item"
                  :index="index"
                />
              </template>
              <template v-else-if="showMappingSourceEntries(item)">
                <template v-if="item.mapping_source_single_choice_entries.length > 0">
                  <MappedTableRow
                    v-for="(mapping_source_item, index) in item.mapping_source_single_choice_entries"
                    :key="'mapping_source_single_choice_entry_' + mapping_source_item.id"
                    :mapped-item="item"
                    :fixed-mapping-item="mapping_source_item"
                    :index="index"
                  />
                </template>
                <template v-if="item.mapping_source_multi_choice_entries.length > 0">
                  <MappedTableRow
                    v-for="(mapping_source_item, index) in item.mapping_source_multi_choice_entries"
                    :key="'mapping_source_multi_choice_entry_' + mapping_source_item.id"
                    :mapped-item="item"
                    :fixed-mapping-item="mapping_source_item"
                    :index="index"
                  />
                </template>
              </template>
              <template v-else>
                <MappedTableRow
                  :key="item.id"
                  :mapped-item="item"
                  :remove-mapping-disabled="isItemRemoveMappingDisabled(item)"
                  :ignore-mapping-button="isEntryIgnoreMappingButton"
                />
                <MappedTableRow
                  v-for="singleChoiceEntry in item.single_choice_entries"
                  :key="'singleChoice' + singleChoiceEntry.id"
                  :mapped-item="singleChoiceEntry"
                  :mapping-disabled="isEntryMappingDisabled(item)"
                  :ignore-mapping-button="isEntryIgnoreMappingButton"
                  :ignore-mapping-disabled="isEntryIgnoreMappingDisabled(item)"
                />
                <MappedTableRow
                  v-for="multiChoiceEntry in item.multi_choice_entries"
                  :key="'multiChoice' + multiChoiceEntry.id"
                  :mapped-item="multiChoiceEntry"
                  :mapping-disabled="isEntryMappingDisabled(item)"
                  :ignore-mapping-button="isEntryIgnoreMappingButton"
                  :ignore-mapping-disabled="isEntryIgnoreMappingDisabled(item)"
                />
              </template>
            </template>
            <tr v-if="!loadingList && mappedList.length === 0">
              <th colspan="100%" class="text-center">
                {{
                  showMappedOnly
                    ? $t('message.mapping.table.noMappedPositions')
                    : $t('message.mapping.table.noPositionsToBind')
                }}
              </th>
            </tr>
          </b-tbody>
        </b-table-simple>
      </b-overlay>
    </div>
    <div class="mt-3">
      <b-pagination
        v-model="mappedListPagination.page"
        :total-rows="mappedListPagination.count"
        :per-page="DEFAULT_PAGE_SIZE"
        aria-controls="my-table"
        align="right"
        @change="
          () => {
            getList()
          }
        "
      />
    </div>
  </div>
</template>
<script>
import MappedTableRow from '@/components/mapped-table/MappedTableRow'
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { DEFAULT_PAGE_SIZE, MENU_SOURCE_CHOICES } from '@/common/constants'
import _ from 'lodash'

const STORE = '_mapping'

export default {
  name: 'MappedTable',
  components: { MappedTableRow },
  props: {},
  data() {
    return {
      searchKeyword: '',
      DEFAULT_PAGE_SIZE,
    }
  },
  computed: {
    ...mapFields(STORE, ['mappedListPagination']),
    ...mapGetters(STORE, [
      'mappedList',
      'loaders',
      'showMappedOnly',
      'selectedMappedTypeList',
      'isDynamicMode',
      'mappedListPagination',
      'showDefaultMenuPositions',
      'selectedBrandId',
      'selectedSourceId',
    ]),
    ...mapGetters('integrations', ['getActiveIntegrations']),
    loadingList() {
      return this.loaders.mappedList
    },
    getCategoryOrGroupLabel() {
      return this.selectedMappedTypeList === 'meal' ? 'Kategoria' : 'Grupa'
    },
    isEntryView() {
      return this.selectedMappedTypeList !== 'meal'
    },
    getLeftColspan() {
      return this.isDynamicMode ? 6 : this.isEntryView ? 4 : 5
    },
    getRightColspan() {
      let colsSpan = this.isDynamicMode ? 8 : this.isEntryView ? 5 : 7
      if (this.getActiveIntegrations.length > 0) colsSpan++
      return colsSpan
    },
    isIntegration() {
      return this.$store.getters['integrations/getActiveIntegrations'].length > 0
    },
    isEntryIgnoreMappingButton() {
      return this.isIntegration && !this.showDefaultMenuPositions
    },
  },
  watch: {
    searchKeyword() {
      this.debounceGetList()
    },
  },
  mounted() {
    this.$eventBus.$on('refreshMappedList', () => {
      this.getList({ page: 1 })
    })
  },
  destroyed() {
    this.$eventBus.$off('refreshMappedList')
  },
  methods: {
    ...mapActions(STORE, ['getMappedList', 'getMappedListForOrder', 'getBrandMenuElements']),
    getList({ page } = {}) {
      this.$nextTick(() => {
        if (this.isDynamicMode) {
          this.getMappedListForOrder({ page, name: this.searchKeyword })
          this.getBrandMenuElements({
            brandId: this.selectedBrandId,
            limitedMenuSources: [parseInt(MENU_SOURCE_CHOICES.DEFAULT)],
          })
        } else {
          this.getMappedList({ page, name: this.searchKeyword })
          this.getBrandMenuElements({
            brandId: this.selectedBrandId,
            limitedMenuSources: [],
          })
        }
      })
    },
    debounceGetList: _.debounce(function () {
      this.getList({ page: 1 })
    }, 500),
    clearSearch() {
      this.searchKeyword = ''
    },
    showMappingSourceEntries(item) {
      return (
        (item.mapping_source_single_choice_entries && item.mapping_source_single_choice_entries.length > 0) ||
        (item.mapping_source_multi_choice_entries && item.mapping_source_multi_choice_entries.length > 0)
      )
    },
    isEntryMappingDisabled(mappedItem) {
      return this.isDynamicMode && !mappedItem.mapped_meal
    },
    isEntryIgnoreMappingDisabled(mappedItem) {
      return this.isDynamicMode && !mappedItem.mapped_meal
    },
    isItemRemoveMappingDisabled(mappedItem) {
      if (!this.isDynamicMode || mappedItem.entry_type) return false

      const anySingleChoiceEntryMapped = mappedItem.single_choice_entries.some((entry) => entry.mapped_entry)
      const anyMultiChoiceEntryMapped = mappedItem.multi_choice_entries.some((entry) => entry.mapped_entry)

      return anySingleChoiceEntryMapped || anyMultiChoiceEntryMapped
    },
  },
}
</script>
<style lang="scss">
.mapped-table {
  border-collapse: separate;
  border-spacing: 0;
  margin: 0;
  border-bottom: 1px solid $gray-300;
  thead {
    > tr {
      > th {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 2;
      }
      &:nth-child(2) {
        > th {
          top: 40px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <router-link
      :to="{ name: link }"
      tag="button"
      :class="['btn btn-block btn-lg btn-settings', { disabled }]"
      @click.native="$emit('click')"
    >
      <slot name="icon">
        <i :class="`fas fa-${icon} fa-fw`"></i>
      </slot>
      <span>{{ text }}</span>
      <Loader v-if="processing" class="loader--integration" />
    </router-link>
  </div>
</template>

<script>
import Loader from '@/components/Loader'

export default {
  name: 'SettingsButton',
  components: {
    Loader,
  },
  props: {
    link: {
      type: String,
      default: '',
    },
    externalUrl: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    processing: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

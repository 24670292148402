import { GlobalEmployeeState } from './index'
import { User } from '@/types/interface'

const setEmployees = (state: GlobalEmployeeState, employees: Array<User>) => {
  state.employees = employees
}

export default {
  setEmployees,
}

<template>
  <div class="table-responsive">
    <table class="table table-bordered table-sm">
      <thead class="thead-dark">
        <tr>
          <th scope="col">{{ $t('message.shifts.salary') }}</th>
          <th scope="col">{{ $t('message.shifts.quantity') }}</th>
          <th scope="col">{{ $t('message.shifts.employeePrice') }}</th>
          <th scope="col">{{ $t('message.shifts.value') }}</th>
        </tr>
      </thead>
      <template v-if="shiftDetailsRequest === 'loading'">
        <TablePlaceholder :row="4" :col="4" :loading="true" />
      </template>
      <tbody v-else>
        <tr v-for="(row, index) in tableData" :key="'balance_table_row_' + index">
          <td class="table-cell">
            {{ row.title }}
            <TooltipButton
              v-if="row.title === $t('message.shifts.orders') && shift.not_all_orders_in_zones"
              :id="'noZoneTooltipButton_' + index"
              placement="bottom"
              button-class="smallTooltipButton-exclamation"
              class="no-zone-alert"
              variant="light"
            >
              <i class="fas fa-exclamation-circle"></i>
              <template #tooltipText>{{ $t('message.shifts.notAllOrdersWithDA') }}</template>
            </TooltipButton>
          </td>
          <td class="table-cell">{{ row.amount }}</td>
          <td class="table-cell">{{ row.rate }}</td>
          <td class="table-cell">{{ row.val === null ? row.val : priceDecimal(row.val) }}</td>
        </tr>
        <tr>
          <td style="border: none"></td>
          <td style="border: none"></td>
          <td class="text-center font-weight-medium">{{ $t('message.shifts.sum') }}</td>
          <td>{{ priceDecimal(shift.total_salary) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TablePlaceholder from '@/components/TablePlaceholder'
import TooltipButton from '@/components/TooltipButton'

export default {
  name: 'SalaryTable',
  components: {
    TooltipButton,
    TablePlaceholder,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('_shiftDetails', {
      shift: 'shift',
      shiftDetailsRequest: 'shiftDetailsRequest',
    }),
    tableData() {
      const {
        total_hours,
        total_hr_salary,
        total_mileage,
        total_mileage_salary,
        total_orders,
        total_deliveries_salary,
        total_zones_salary,
        zones_orders_summary,
        per_hour,
        per_km,
        per_delivery,
      } = this.shift
      const rows = [
        { title: this.$t('message.shifts.manHours'), amount: total_hours, rate: per_hour, val: total_hr_salary },
        { title: this.$t('message.shifts.kilometres'), amount: total_mileage, rate: per_km, val: total_mileage_salary },
        {
          title: this.$t('message.shifts.orders'),
          amount: total_orders,
          rate: per_delivery,
          val: total_deliveries_salary,
        },
      ]
      if (zones_orders_summary) {
        zones_orders_summary.forEach((zone) => {
          const { zone_name, orders_no, orders_salary, courier_price } = zone
          rows.push({
            title: zone_name,
            amount: orders_no,
            rate: courier_price,
            val: orders_salary,
          })
        })
      }
      return rows
    },
  },
}
</script>

<style scoped lang="scss">
.summary-row {
  background-color: #f1f1f1;
  font-weight: bold;
  td {
    text-align: right;
  }
}
.no-zone-alert {
  margin-left: 15px;
  text-align: center;
  i {
    color: $red;
    font-size: 20px;
  }
}
</style>

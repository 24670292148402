<template>
  <div class="text-truncate">
    <span
      v-for="(meal, index) in meals"
      :key="meal.id + '_' + index"
      data-toggle="tooltip"
      data-placement="top"
      :title="tooltipText"
      >{{ meal.name }},
    </span>
  </div>
</template>

<script>
export default {
  name: 'MealsTextTruncate',
  props: {
    meals: {
      type: Array,
      required: true,
    },
  },
  data() {
    const tooltipText = this.meals
      .map(function (meal) {
        return meal.name
      })
      .join(', ')
    return {
      tooltipText,
    }
  },
}
</script>

<style scoped>

</style>

import { INewOrderState } from '@/store/modules/new-order/index'
import { ORDER_STATUSES } from '@/common/constants'

const order = (state: INewOrderState) => state.order //TODO: Rename to orderForm
const orderData = (state: INewOrderState) => state.orderData ///TODO: Rename to order
const loading = (state: INewOrderState) => state.loading
const editMode = (state: INewOrderState) => state.editMode
const orderIsEditable = (state: INewOrderState) =>
  ![ORDER_STATUSES.ORDER_STATUS_NEW, ORDER_STATUSES.ORDER_STATUS_FINISHED].includes(state.orderData?.status)
export default {
  orderData,
  order,
  loading,
  editMode,
  orderIsEditable,
}

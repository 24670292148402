<template>
  <div class="regions-list-container" :class="{ 'regions-list-container--empty': noRegions }">
    <b-overlay :show="regionsRequest === 'loading'" class="regions-list-container-overlay">
      <div v-if="!isMobile()" class="regions-list-header">
        <span class="regions-list-header__title">{{ $t('message.regions.title') }}</span>
        <button
          id="add-new-region-btn"
          class="btn btn-sm btn-primary"
          :disabled="isAddingNewRegion"
          @click="addNewRegion"
        >
          <i class="fas fa-plus-square"></i><span>{{ $t('message.regions.addNewBtnText') }}</span>
        </button>
      </div>
      <regions-list-item v-if="isAddingNewRegion && !isMobile()" is-new></regions-list-item>
      <div class="regions-list">
        <regions-list-item v-for="region in regions" :key="region.id" :data="region" />
        <template v-if="regions.length === 0">
          <div class="regions-list-empty">
            <p>
              <i class="fas fa-exclamation-circle" style="margin-right: 10px"></i>{{ $t('message.regions.noRegions') }}
            </p>
            <button
              id="add-new-region_2"
              class="btn btn-sm btn-primary"
              :disabled="isAddingNewRegion"
              @click="addNewRegion"
            >
              <i class="fas fa-plus-square"></i><span>{{ $t('message.regions.addNewBtnText') }}</span>
            </button>
          </div>
        </template>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RegionsListItem from './RegionsListItem'
import { isMobile } from '@/utils/common'

export default {
  name: 'RegionsList',
  components: {
    RegionsListItem,
  },
  data() {
    return {
      isMobile,
    }
  },
  computed: {
    ...mapGetters('_regions', ['regions', 'isAddingNewRegion', 'regionsRequest']),
    noRegions() {
      return this.regions.length === 0
    },
  },
  methods: {
    addNewRegion() {
      this.$store.commit('_regions/setAddingNewRegion', true)
      this.$eventBus.$emit('onAddNewRegion')
    },
  },
}
</script>

<style scoped lang="scss">
.regions-list-container {
  flex-shrink: 0;
  margin-left: 20px;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    width: 400px;
  }

  &--empty {
    text-align: center;
    p {
      font-weight: 500;
    }
    .regions-list {
      background-color: #f7f9fb;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-overlay {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
.regions-list {
  border: 1px solid #cecece;
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;

  &-header {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &__title {
      font-size: 16px;
      font-weight: bold;
      margin-right: auto;
    }
  }

  &-empty {
    padding-top: 15px;
    padding-bottom: 15px;
    @include media-breakpoint-up(xl) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
</style>

<template>
  <div>
    <template v-if="isButton">
      <b-button class="bill-shortcut__btn bill-shortcut__btn--transparent m-0" @click="$emit('clicked')">
        <i class="fas fa-plus-circle bill-shortcut__btn-icon" :aria-label="$t('message.splitBill.addBill')" />
      </b-button>
    </template>
    <template v-else>
      <router-link
        :id="`billShortcut${id}`"
        :to="{ name: 'orders-split-bill-edit', params: { billId: id, orderStatus: queryOrderStatus } }"
        :class="[
          'bill-shortcut__link',
          {
            'bill-shortcut__link--red-full': checkIsPrintError(shortcutStatus) && !active,
            'bill-shortcut__link--red': checkIsPrintError(shortcutStatus),
            'bill-shortcut__link--green-full': checkHasDoneBadgeShown(shortcutStatus) && !active,
            'bill-shortcut__link--green': checkHasDoneBadgeShown(shortcutStatus) && active,
            'bill-shortcut__link--blue': checkIsEditable(shortcutStatus) && active,
          },
        ]"
      >
        <span class="bill-shortcut__mark"> {{ $t('message.splitBill.mark') }}{{ number }} </span>
        <span class="bill-shortcut__price"> {{ priceDecimal(total) }} {{ currency }} </span>
        <span
          v-if="checkIsPrintError(shortcutStatus) || checkIsDoneAndPrinted(shortcutStatus)"
          :class="[
            'bill-flag',
            {
              'bill-flag--red': checkIsPrintError(shortcutStatus),
              'bill-flag--green': checkIsDoneAndPrinted(shortcutStatus),
            },
          ]"
        >
          <i
            v-if="checkIsPrintError(shortcutStatus)"
            class="fas fa-print"
            :aria-label="$t('message.splitBill.status.printError')"
          />
          <i
            v-else-if="checkIsDoneAndPrinted(shortcutStatus)"
            class="fas fa-print"
            :aria-label="$t('message.splitBill.status.printed')"
          />
        </span>
      </router-link>
      <b-tooltip v-if="checkIsPrintError(shortcutStatus)" :target="`billShortcut${id}`" variant="danger">
        {{ $t('message.splitBill.status.printError') }}
      </b-tooltip>
    </template>
  </div>
</template>

<script>
import { billStatusMixins } from '@/mixins'

export default {
  name: 'BillShortcut',
  mixins: [billStatusMixins],
  props: {
    isButton: {
      type: Boolean,
      default: false,
    },
    number: {
      type: [String, Number],
      default: '',
    },
    id: {
      type: Number,
    },
    shortcutStatus: {
      type: Number,
      default: null,
    },
    total: {
      type: Number,
      default: 0,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currency() {
      return this.$store.getters.currency
    },
  },
}
</script>
<style lang="scss" scoped>
.bill-shortcut {
  margin-top: 15px;
  width: 100%;
  min-width: 82px;

  @media (min-width: 768px) {
    margin-top: 0;
    padding-left: 5px;
    width: auto;
  }

  &__link,
  &__btn {
    box-sizing: border-box;
    border-radius: 6px;
    width: 67px;
    height: 67px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin: 11px 8px;
    color: #343a40;
    background: #fafafa;
    border: 1px solid #bbbbbb;
    position: relative;

    &--blue {
      background: #f2f8ff;
      border: 1px dashed #007bff;
    }

    &--transparent {
      background: #ffffff;
      border: 1px dashed #d7d7d7;
      color: #e2e2e2;
    }

    &--green {
      background: linear-gradient(0deg, rgba(100, 205, 133, 0.33), rgba(100, 205, 133, 0.33)), #ffffff;
      border: 1px dashed #24d17e;
    }

    &--green-full {
      background: #64cd85;
      border: 1px solid #64cd85;
      color: #fff;
    }

    &--red {
      background: linear-gradient(0deg, rgba(205, 100, 100, 0.33), rgba(205, 100, 100, 0.33)), #ffffff;
      border: 1px dashed #dc3545;
    }

    &--red-full {
      background: #dc3545;
      border: 1px solid #dc3545;
      color: #fff;
    }

    &-icon {
      font-size: 24px;
    }
  }

  &__mark {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }

  &__price {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    margin-top: 9px;
  }
}

.bill-flag {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  position: absolute;
  top: -7px;
  right: -7px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  color: #fff;
  border: 1px solid #ffffff;

  &--green {
    background: #64cd85;
  }

  &--red {
    background: #dc3545;
  }
}
</style>

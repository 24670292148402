<template>
  <div class="nav-item-info">
    <span class="nav-item-info__title">{{ $t('message.orderDetails.orderStatus') }}:</span>
    <b-spinner v-if="isLoading" small class="nav-item-info__spinner" />
    <span v-else class="nav-item-info__value"
      >{{ statusTranslated }}
      <strong v-if="finishedTypeTranslated">({{ finishedTypeTranslated }})</strong>
    </span>
  </div>
</template>

<script>
export default {
  name: 'NavItemOrderStatus',
  props: {
    statusTranslated: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    finishedTypeTranslated: {
      type: [String, null],
      default: null,
    },
  },
}
</script>

<style scoped>

</style>

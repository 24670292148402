import { Validator } from 'vee-validate'

export const dictionary = {
  en: {
    attributes: {
      phone: 'phone number',
      phoneNumber: 'phone number',
      customerAddress: 'address',
      employeeType: 'employee type',
      name: 'name',
      email: 'email',
      password: 'password',
      confirmPassword: 'confirm password',
      courier_type: 'courier type',
      mean_of_transport: 'courier transport method',
      pickupType: 'pickup type',
      paymentType: 'payment type',
      customerPromisedTime: 'promised time',
      customerPromisedTimeValue: 'promised time',
      regionName: 'region name',
      startDateTime: 'start date',
      finishDateTime: 'finish date',
      started_at: 'date and time of shift start',
      finished_at: 'date and time of shift finish',
      comment: 'comment',
      role: 'employee role',
      categoryName: 'category name',
      categorySizeName: 'category size',
      mealName: 'meal name',
      sizePrice: 'meal price in size',
      ingredientName: 'ingredient name',
      choiceGroupName: 'group name',
      entryName: 'name',
      entryPrice: 'price',
      discountValue: 'value',
      receiptPrinterName: 'receipt printer name',
      receiptPrinterInternalId: 'receipt printer internal windows name',
      receiptTemplateName: 'receipt template name',
      receiptTemplateNoOfCopies: 'number of copies',
      mealSetItemSizes: 'size',
      mealSetItemMeals: 'meal',
    },
    custom: {
      mealSetItemCategory: {
        required: 'Choose meal set item category',
      },
    },
  },
  pl: {
    attributes: {
      phone: 'numer telefonu',
      phoneNumber: 'numer telefonu',
      customerAddress: 'adres',
      employeeType: 'typ pracownika',
      name: 'imię i nazwisko',
      email: 'adres email',
      password: 'hasło',
      confirmPassword: 'potwierdź hasło',
      courier_type: 'typ kuriera',
      mean_of_transport: 'środek transportu',
      pickupType: 'typ odbioru',
      paymentType: 'metoda płatności',
      customerPromisedTime: 'obietnica czasu',
      customerPromisedTimeValue: 'obietnica czasu',
      regionName: 'nazwa regionu',
      startDateTime: 'data startu',
      finishDateTime: 'data końca',
      started_at: 'data i godzina rozpoczęcia zmiany',
      finished_at: 'data i godzina zakończenia zmiany',
      comment: 'komentarz',
      role: 'typ pracownika',
      categoryName: 'nazwy kategorii',
      categorySizeName: 'nazwy rozmiaru',
      mealName: 'nazwy dania',
      sizePrice: 'ceny dania w rozmiarze',
      ingredientName: 'nazwy składnika',
      choiceGroupName: 'nazwy grupy',
      entryName: 'nazwy',
      entryPrice: 'ceny',
      discountValue: 'wartości',
      receiptPrinterName: 'nazwa boniarki',
      receiptPrinterInternalId: 'nazwa boniarki w systemie Windows',
      receiptTemplateName: 'nazwa szablonu',
      receiptTemplateNoOfCopies: 'ilość kopii',
      mealSetItemSizes: 'rozmiar',
      mealSetItemMeals: 'danie',
    },
    custom: {
      mealSetItemCategory: {
        required: 'Wybierz kategorię elementu',
      },
    },
  },
}

export const customRules = {
  atLeastOne: {
    getMessage: (field: string): string => {
      const locale: string = Validator.locale
      const dictionary: any = {
        en: `Choose at least 1 ${field}`,
        pl: `Wybierz przynajmniej 1 ${field}`,
      }
      return dictionary[locale]
    },
    validate: (value: any) => !!value && value.length > 0,
  },
}

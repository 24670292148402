<template>
  <div class="layout">
    <NavBack>
      <template #title>
        <portal-target name="nav-title" tag="span"> {{ $t(`message.${$route.name}.title`) }} </portal-target>
      </template>
      <template #rightSide>
        <portal-target name="nav-right-side" />
      </template>
    </NavBack>
    <div class="layout-content">
      <router-view />
    </div>
  </div>
</template>

<script>
import NavBack from '@/components/navigation/NavBack'

export default {
  name: 'MobileSubpageLayout',
  components: {
    NavBack,
  },
}
</script>

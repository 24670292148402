const setBillingPeriod = (state, billingPeriod) => {
  state.billingPeriod = billingPeriod
}
const setBillingPeriodRequest = (state, status) => {
  state.billingPeriodRequest = status
}
const setCloseBillingPeriodRequest = (state, status) => {
  state.closeBillingPeriodRequest = status
}
export default {
  setBillingPeriod,
  setBillingPeriodRequest,
  setCloseBillingPeriodRequest,
}

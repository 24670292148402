import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { Shift } from '@/types/interface'

export interface ShiftState {
  shift: Shift
  shiftDetailsRequest: string
}

const state = {
  shift: {} as Shift,
  shiftDetailsRequest: '',
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

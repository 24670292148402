<template>
  <div ref="choiceGroup">
    <b-tabs content-class="mt-3">
      <b-tab title="Informacje" active>
        <div class="d-flex align-items-end edit-section">
          <name-choice-group v-model="name" @blur="update({ name })" />
          <b-button v-if="id" variant="primary" class="btn-input flex-shrink-0 ml-2" @click="clone">
            <i class="fas fa-copy mr-2" aria-hidden="true" />
            {{ $t('message.menu.choiceGroups.cloneGroup') }}
          </b-button>
        </div>

        <is-default-switch v-if="isErestoBrand" v-model="in_erestaurant" @click="update({ in_erestaurant })">
          <template #label>
            {{ $t('message.menu.choiceGroups.availableInEresto') }}
          </template>
        </is-default-switch>

        <template v-if="isSingleChoiceGroup">
          <is-default-switch
            v-model="is_required"
            :tooltip-text="$t('message.menu.choiceGroups.requiredInfo')"
            @click="update({ is_required })"
          >
            <template #label>
              {{ $t('message.menu.choiceGroups.required') }}
            </template>
          </is-default-switch>
          <hr />
        </template>
        <meals-tree-choice-group
          @change="
            (mealIds) => {
              update({ meals: mealIds })
            }
          "
        />
      </b-tab>
      <b-tab
        v-if="id"
        :title="
          $t(`message.menu.choiceGroups.${isSingleChoiceGroup ? 'singleChoiceGroupTitle' : 'multiChoiceGroupTitle'}`)
        "
      >
        <choice-group-entries />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import NameChoiceGroup from './choice-group/NameChoiceGroup'
import IsDefaultSwitch from './generic/IsDefaultSwitch'
import MealsTreeChoiceGroup from './choice-group/MealsTreeChoiceGroup'
import ChoiceGroupEntries from './choice-group/ChoiceGroupEntries'

const STORE = '_menuEdit'

export default {
  name: 'ChoiceGroupEdit',
  components: {
    NameChoiceGroup,
    IsDefaultSwitch,
    MealsTreeChoiceGroup,
    ChoiceGroupEntries,
  },
  data() {
    return {
      choiceGroupsErrors: false,
    }
  },
  computed: {
    ...mapGetters(STORE, ['isEditMode', 'menuElement', 'isSingleChoiceGroup']),
    ...mapGetters({ isErestoBrand: 'auth/isErestoBrand' }),
    ...mapFields(STORE, [
      'form.category',
      'form.size',
      'form.in_erestaurant',
      'form.id',
      'form.entries',
      'form.clone',
      'form.name',
      'form.is_required',
      'form.defaultEntry',
      'form.meals',
    ]),
  },
  methods: {
    ...mapMutations(STORE, ['setSavingStatus', 'setIsEdited', 'setForm']),
    ...mapActions(STORE, ['updateElement', 'getSingleChoiceGroups', 'getMultiChoiceGroups', 'cloneChoiceGroup']),
    async update(requestData) {
      this.$emit('update', requestData)
    },
    clone() {
      this.cloneChoiceGroup(this.id)
    },
  },
}
</script>

<template>
  <b-navbar class="main-nav main-nav--new-order" type="dark">
    <div class="main-nav__left" style="margin-left: -15px">
      <b-navbar-nav>
        <li class="nav-item">
          <b-button variant="navbar" class="nav-link nav-link-button bg-transparent" @click="onCloseOrderEdit()">
            <i class="fas fa-times-circle" :aria-label="$t('message.common.back')" />
          </b-button>
        </li>
      </b-navbar-nav>
      <div>
        <small>{{ $t('message.orderFormShort.order') }}:</small>
        <span class="ml-2">{{ cartNumber ? cartNumber : $t('message.orderFormShort.new') }}</span>
      </div>
    </div>

    <div class="main-nav__right">
      <b-button id="addNewMealToOrder" class="mr-2" variant="success" size="sm" @click="addNewMealToOrder">
        <template v-if="showMealsMenu">
          <i class="fas fa-shopping-cart" />
          <span>{{ priceDecimal(orderTotalPrice) }} {{ currency }}</span>
        </template>
        <template v-else>
          <i class="fas fa-cart-plus" />
          <span>{{ $t('message.cart.addMeal') }}</span>
        </template>
      </b-button>
    </div>
  </b-navbar>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'NavNewOrder',
  components: {},
  props: {
    onCloseOrderEdit: { type: Function, required: true },
  },
  computed: {
    ...mapGetters(['currency']),
    ...mapGetters('_orderEdit', ['cartNumber', 'orderTotalPrice', 'showMealsMenu', 'showOrderForm', 'mealSaved']),
  },
  methods: {
    ...mapMutations('_orderEdit', ['toggleOrderForm']),
    addNewMealToOrder() {
      this.showMealsMenu ? this.openOrderForm() : this.openMealsMenu()
    },
    openOrderForm() {
      // If meal was changed, it pops up confirmation modal, otherwise goes to OrderFormLong
      if (this.mealSaved) this.toggleOrderForm(true)
      else this.$eventBus.$emit('onShowMealDiscardChangesModal')
    },
    openMealsMenu() {
      this.toggleOrderForm(false)
    },
  },
}
</script>

<style scoped lang="scss">
.main-nav--new-order {
  @include media-breakpoint-up(md) {
    display: none;
  }
}
</style>

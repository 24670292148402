import { render, staticRenderFns } from "./WorkstationsTable.vue?vue&type=template&id=b6aee24e&"
import script from "./WorkstationsTable.vue?vue&type=script&lang=js&"
export * from "./WorkstationsTable.vue?vue&type=script&lang=js&"
import style0 from "./WorkstationsTable.vue?vue&type=style&index=0&id=b6aee24e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
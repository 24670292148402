<template>
  <b-form-group :label="$t('message.cart.discountCode')">
    <b-input-group>
      <b-input-group-prepend v-if="showDiscountInfo" class="discountCodePrepend">
        <b-button variant="success" disabled style="padding: 0 0.75rem">
          <i class="fas fa-check pr-3"></i>
          <span v-if="cart.discountChoice === DISCOUNT_CHOICES.DISCOUNT_CHOICE_FREE_DELIVERY">
            {{ $t('message.cart.freeDelivery') }}
          </span>
          <span v-else-if="cart.discountChoice === DISCOUNT_CHOICES.DISCOUNT_CHOICE_PERCENT">
            {{ '-' + cart.discountValue }} %
          </span>
          <span v-else-if="cart.discountChoice === DISCOUNT_CHOICES.DISCOUNT_CHOICE_PROMO_PRICE">
            {{ priceDecimal(cart.promotionalPrice) }} {{ currency }}
          </span>
          <span v-else-if="cart.discountChoice === DISCOUNT_CHOICES.DISCOUNT_CHOICE_VALUE">
            {{ '-' + priceDecimal(cart.discountValue) }} {{ currency }}
          </span>
        </b-button>
      </b-input-group-prepend>
      <b-form-input
        id="discount-code-input"
        v-model="cart.discountCode"
        :disabled="disableDiscountCode"
        class="text-uppercase"
      >
      </b-form-input>
      <b-input-group-append>
        <b-button
          v-if="!cart.discountCodeApplied"
          variant="primary"
          :disabled="disableDiscountCode"
          style="padding: 0 0.75rem"
          @click="_applyDiscountCode()"
        >
          {{ $t('message.cart.applyCode') }}
        </b-button>
        <b-button v-else-if="!isEdit" variant="danger" style="padding: 0 0.75rem" @click="clearDiscount()">
          <span class="fas fa-times"></span>
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <response-error-msg field="discount_code" :response-errors="responseErrors"/>
    <response-error-msg field="non_field_errors" :response-errors="responseErrors"/>
  </b-form-group>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { responseErrorsHandler } from '@/mixins'
import { DISCOUNT_CHOICES } from '@/common/constants'

const STORE = '_orderEdit'

export default {
  name: 'DiscountCode',
  mixins: [responseErrorsHandler],
  data() {
    return {
      DISCOUNT_CHOICES,
    }
  },
  computed: {
    ...mapGetters(['currency']),
    ...mapGetters(STORE, ['cart', 'isEdit']),
    showDiscountInfo() {
      return this.cart.discountCodeApplied
    },
    disableDiscountCode() {
      return (
        this.cart.discountCodeApplied ||
        !!this.cart.discountPercent ||
        !!this.cart.promotionalPrice ||
        this.isAnyMealDiscountOrPromo
      )
    },
  },
  methods: {
    ...mapMutations(STORE, {
      clearDiscount: 'clearDiscount',
    }),
    ...mapActions(STORE, ['applyDiscountCode']),
    _applyDiscountCode() {
      this.applyDiscountCode({
        onSuccess: () => {
          this.clearResponseErrors()
        },
        onError: (error) => {
          this.setResponseErrors(error)
        },
      })
    },
  },
}
</script>

<style scoped>

</style>

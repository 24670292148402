<template>
  <div class="categorySizes">
    <b-form-input
      id="categorySizeName"
      v-validate="validator"
      :value="getValue"
      class="categorySize categorySize-input"
      name="categorySizeName"
      :disabled="saving"
      @input="$emit('input', $event)"
      @blur="veeFields.categorySizeName.changed ? $emit('blur') : null"
    />
    <b-button
      :variant="isDefault ? 'primary' : 'outline-primary'"
      class="categorySize btn-input"
      :disabled="saving || isDefault"
      @click="$emit('change-default-size')"
    >
      {{ $t('message.menu.categories.default') }}
    </b-button>
    <b-button
      v-if="id"
      variant="danger"
      class="px-3 btn-input"
      :disabled="saving || isDefault"
      @click="$emit('remove-category-size')"
    >
      <i class="fas fa-trash-alt" :aria-label="$t('message.common.delete')" />
    </b-button>
    <template v-else>
      <b-button
        :disabled="saving"
        class="btn-input mr-2"
        variant="outline-secondary"
        @click="$emit('cancel-add-category-size')"
      >
        <i class="fas fa-times" :aria-label="$t('message.common.cancel')" />
      </b-button>
      <b-button :disabled="saving" variant="success" class="px-3 btn-input" @click="$emit('create-category-size')">
        <i class="fas fa-save" :aria-label="$t('message.common.save')" />
      </b-button>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

const STORE = '_menuEdit'

export default {
  name: 'SizeCategory',
  props: {
    value: {
      type: String,
      default: '',
    },
    isDefault: {
      type: Boolean,
      default: false,
    },
    validator: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapFields(STORE, ['form.sizes']),
    ...mapGetters(STORE, ['saving']),
    getValue() {
      return this.value === 'default' ? this.$t('message.menu.defaultSizeName') : this.value
    },
  },
}
</script>

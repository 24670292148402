export const rowColor = {
  methods: {
    getShiftRowClass(finished) {
      return {
        'shift-row--finished': finished,
        'shift-row--continues': !finished,
      }
    },
  },
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('l-marker',{attrs:{"lat-lng":_vm.coords,"icon":_vm.getIcon},on:{"click":_vm.onClick}},[_c('l-tooltip',{attrs:{"options":{
      permanent: true,
      interactive: true,
      direction: 'top',
      offset: [0, -14],
      className: 'courier-marker',
    }}},[_c('div',{on:{"click":_vm.onClick}},[_vm._v(" "+_vm._s(_vm.label)+" ("),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.ordersCount))]),_vm._v(") ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { API } from '@/services/api/api'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import { IntegrationsState } from './index'
import { IIntegration } from '@/types/interface'

const saveIntegrations = ({ commit }: any, integrations: IIntegration) => {
  sessionStorage.setItem('integrations', JSON.stringify(integrations))
  commit('setIntegrations', integrations)
}

export default {
  saveIntegrations
}

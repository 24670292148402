<template>
  <div class="menuImage">
    <h5 class="label">
      <slot name="label"> {{ $t('message.menu.categories.visibleInEresto') }}: </slot>
    </h5>
    <div class="d-flex align-center">
      <b-form-file
        :value="value"
        :placeholder="$t('message.erestaurant.loadFile')"
        :browse-text="$t('message.erestaurant.choose')"
        class="menuImage-file mr-2 cursor-pointer"
        :disabled="!!value || saving"
        accept="image/jpeg, image/png, image/gif"
        @input="emitValue"
      />
      <b-button variant="danger" :disabled="!value || saving" class="btn-input" @click="$emit('remove')">
        <i class="fas fa-trash-alt" :aria-label="$t('message.common.delete')" />
      </b-button>
    </div>
    <p class="menuImage-resolution">{{ $t('message.erestaurant.imageResolution') }}</p>
    <div class="menuImage-image">
      <h5 v-if="value" class="erestaurant-text mt-3 title-gray">
        {{ $t('message.erestaurant.preview') }}
      </h5>
      <b-img v-if="srcImage" class="menuImage-icon" width="100" height="100" thumbnail :src="srcImage" />
    </div>
  </div>
</template>

<script>
const STORE = '_menuEdit'

export default {
  name: 'ErestoImageUpload',
  props: {
    value: {
      type: [File, String],
      default: null,
    },
    saving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valueAsString: '',
    }
  },
  computed: {
    loading() {
      if (this.$store[STORE]) return this.$store.getters[`${STORE}/saving`]
      else return this.saving
    },

    srcImage() {
      return typeof this.value === 'string' ? this.value : this.valueAsString
    },
  },
  watch: {
    value(value) {
      if (!value) {
        this.valueAsString = ''
      }
    },
  },
  methods: {
    emitValue(value) {
      this.$emit('input', value)
      if (value && typeof value !== 'string') this.readFileAsString(value)
    },
    async readFileAsString(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async () => {
        this.valueAsString = reader.result
        this.$emit('upload', this.valueAsString)
      }
    },
  },
}
</script>

const selectedBillPosition = (state) => state.selectedBillPosition
const selectedSourceBillId = (state) => state.selectedSourceBillId
const selectedToBillId = (state) => state.selectedToBillId

const billsRequestStatus = (state) => state.billsRequestStatus

const allBills = (state) => state.allBills
const baseBill = (state) => state.baseBill
const bills = (state) => state.bills
const billsLimit = (state) => state.billsLimit
const billsLength = (state) => state.bills?.length || 0

const bill = (getters) => (id) => (getters.bills && getters.bills.find((bill) => bill.id == id)) || null
const billPosition = (getters) => (bill) => getters.bills.indexOf(bill) + 1

const prevBillId = (getters) => (currentBillId) => {
  const currentBillIndex = getters.bills.map((bill) => bill.id).indexOf(currentBillId)
  return currentBillIndex > 0 ? getters.bills[currentBillIndex - 1].id : null
}

const nextBillId = (getters) => (currentBillId) => {
  const currentBillIndex = getters.bills.map((bill) => bill.id).indexOf(currentBillId)
  return getters.bills[currentBillIndex + 1]?.id || null
}

const lastBillId = (state) => {
  const bills = state.bills
  return bills?.length ? bills[bills.length - 1].id : null
}

const hasSplitBills = (state) => !!state.bills?.length || false

const orderId = (state) => state.orderId

export default {
  selectedBillPosition,
  allBills,
  baseBill,
  bills,
  billsRequestStatus,
  bill,
  billPosition,
  selectedSourceBillId,
  selectedToBillId,
  prevBillId,
  nextBillId,
  billsLimit,
  billsLength,
  lastBillId,
  hasSplitBills,
  orderId,
}

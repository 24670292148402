<template>
  <b-overlay :show="loaders.categorySizes">
    <div class="edit-section">
      <h5 id="addSizes" class="label w-100">
        {{ $t('message.menu.categories.addSize') }}
      </h5>
      <size-category
        v-for="(size, index) in sizes"
        :id="size.id"
        :key="index"
        v-model="size.name"
        :index="index"
        :is-default="size.is_default"
        :validator="validateName"
        aria-labelledby="addSizes"
        @blur="updateSize(size)"
        @change-default-size="changeDefaultSize(index)"
        @create-category-size="createSize(size)"
        @remove-category-size="deleteSize(size)"
        @cancel-add-category-size="cancelAddCategorySize"
      />
      <input-error-msg field="categorySizeName" :_errors="errors" />
      <b-button
        variant="primary"
        class="addSizeButton"
        :disabled="saving || addingNewCategory"
        size="sm"
        @click="addCategorySize"
      >
        <i class="fas fa-plus-square mr-2" aria-hidden="true" /> {{ $t('message.menu.categories.addNextSize') }}
      </b-button>
    </div>
  </b-overlay>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import SizeCategory from './SizeCategory'

const STORE = '_menuEdit'

export default {
  name: 'SizeListCategory',
  components: {
    SizeCategory,
  },
  props: {
    categoryId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      hasSizes: true,
      addingNewCategory: false,
    }
  },
  computed: {
    ...mapFields(STORE, ['form.sizes', 'categories']),
    ...mapGetters(STORE, ['form', 'saving', 'loaders', 'isEditMode']),
    validateName() {
      return {
        required: true,
        min: 1,
      }
    },
  },
  async mounted() {
    await this.getCategorySizes(this.categoryId)
  },
  methods: {
    ...mapActions(STORE, ['updateElement', 'editElement', 'deleteElement', 'createElement', 'getCategorySizes']),
    ...mapMutations(STORE, ['setLoaders', 'setForm']),
    changeDefaultSize(sizeIndex) {
      // Change actual is_default of sizes
      const categorySizes = this.sizes
      const defaultCategorySize = categorySizes.find((size) => {
        return size.is_default
      })
      if (defaultCategorySize) defaultCategorySize.is_default = false
      this.sizes[sizeIndex].is_default = true
      this.updateSize(this.sizes[sizeIndex])
    },
    addCategorySize(value = true) {
      if (value) {
        this.sizes.push({
          id: 0,
          name: '',
          is_default: !this.sizes.some((size) => size.is_default),
          in_erestaurant: true,
          meals: [],
          category: this.categoryId,
        })
        if (this.sizes.length === 1) this.sizes[0].is_default = true
      } else {
        this.sizes = []
      }
      this.addingNewCategory = true
    },
    async deleteSize(size) {
      this.$bvModal
        .msgBoxConfirm(this.$t('message.menu.deleteMsgBox.confirm'), {
          centered: true,
          size: 'sm',
          okTitle: this.$t('message.common.delete'),
          cancelTitle: this.$t('message.common.cancel'),
          okVariant: 'danger',
          cancelVariant: 'outline-secondary',
          footerClass: 'modal-footer--menu-management',
        })
        .then(async (confirm) => {
          if (confirm) {
            const { error } = await this.deleteElement({ id: size.id, apiCall: 'deleteSize' })
            if (!error) {
              this.categories.find((category) => category.id === this.categoryId).sizes_count -= 1
              this.getCategorySizes(this.categoryId)
            }
          }
        })
    },
    async updateSize(size) {
      if (this.isEditMode && size.id) {
        await this.updateElement({ id: size.id, requestData: size, apiCall: 'updateSize' })
      }
    },
    async createSize(requestData) {
      if (this.isEditMode) {
        const { error, data } = await this.createElement({ requestData, apiCall: 'createSize' })
        this.sizes[this.sizes.findIndex((size) => size.id === 0)] = data
        this.setForm({ ...this.form, sizes: this.sizes })
        this.addingNewCategory = false
        this.categories.find((category) => category.id === this.categoryId).sizes_count += 1
      }
    },
    cancelAddCategorySize() {
      this.sizes.splice(this.sizes.length - 1, 1)
      this.addingNewCategory = false
    },
  },
}
</script>

<template>
  <div class="meal-edit-footer">
    <div class="full-price">
      <span class="full-price__title">{{ $t('message.mealDetails.totalPrice') }}:</span>
      <span class="full-price__value">{{ totalPrice }} {{ currency }}</span>
    </div>

    <b-button
      v-shortkey="activeMeal ? ['enter'] : ['null']"
      size="lg"
      block
      variant="success"
      :class="!isEdit ? 'finish-button-add' : ''"
      @click="addOrUpdateMealInCart"
      @shortkey="addOrUpdateMealInCart"
    >
      <kbd class="pb-0 pt-1 mr-3 bg-white text-dark">&crarr;</kbd>
      <template v-if="!isEdit">
        <i class="fas fa-cart-plus" />
        <span>{{ $t('message.mealDetails.add') }}</span>
      </template>
      <template v-else>
        <i class="fas fa-save" />
        <span>{{ $t('message.mealDetails.save') }}</span>
      </template>
    </b-button>
    <b-button
      v-if="showDeleteBtn"
      class="mt-0 ml-4 meal-edit-footer__delete-btn"
      size="lg"
      block
      variant="danger"
      @click="deleteMealFromCart"
    >
      <i class="fas fa-trash" />
      <span>{{ $t('message.mealDetails.delete') }}</span>
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MealEditFooter',
  props: {
    totalPrice: { type: String, default: '0.00' },
    showDeleteBtn: { type: Boolean, default: false },
    isEdit: { type: Boolean, default: false },
    deleteMealFromCart: { type: Function, required: true },
    addOrUpdateMealInCart: { type: Function, required: true },
    activeMeal: { type: [Object, null], default: null },
  },
  computed: {
    ...mapGetters(['currency']),
  },
}
</script>

<style lang="scss">
.meal-edit-footer {
  display: flex;
  flex-direction: row;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid $gray-400;
  padding: 10px 15px;
  height: 100px;
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
  &__delete-btn {
    width: 25%;
  }
  @include media-breakpoint-down(xs) {
    .btn {
      padding: 5px;
    }
  }
}
.full-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  position: relative;
  > span {
    white-space: nowrap;
  }
  &__title {
    font-size: 12px;
    margin-right: 10px;
  }
  &__value {
    font-size: 28px;
    font-weight: 700;
  }
  @include media-breakpoint-down(xs) {
    flex-direction: column;
  }
}
</style>

<template>
  <div class="table-responsive">
    <table class="table table-bordered table-sm">
      <thead class="thead-dark">
        <tr>
          <th scope="col">{{ $t('message.shifts.date') }}</th>
          <th scope="col">{{ $t('message.shifts.type') }}</th>
          <th scope="col">{{ $t('message.shifts.amount') }}</th>
          <th scope="col">{{ $t('message.shifts.comment') }}</th>
        </tr>
      </thead>
      <template v-if="shiftDetailsRequest === 'loading'">
        <TablePlaceholder :row="4" :col="4" :loading="true" />
      </template>
      <tbody v-else>
        <tr v-for="(entry, index) in entriesTableData" :key="'balance_table_row_' + index">
          <td class="table-cell">{{ entry.created_at }}</td>
          <td class="table-cell">{{ $t(`message.billing.${entriesTypes[entry.type]}`) }}</td>
          <td class="table-cell">{{ entry.type <= 2 ? '-' : '+' }}{{ priceDecimal(entry.amount) }}</td>
          <td class="table-cell">{{ entry.comment }}</td>
        </tr>
        <tr v-if="entriesTableData.length === 0" class="table-empty-row">
          <td colspan="6">{{ $t('message.shifts.none') }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TablePlaceholder from '@/components/TablePlaceholder'

export default {
  name: 'ShiftEntriesTable',
  components: {
    TablePlaceholder,
  },
  data() {
    return {
      entriesTypes: {
        1: 'returnedCashForOrders',
        2: 'returnedOperationalCash',
        3: 'operationalCashOut',
      },
    }
  },
  computed: {
    ...mapGetters('_shiftDetails', {
      shift: 'shift',
      shiftDetailsRequest: 'shiftDetailsRequest',
    }),
    employeeName() {
      return _.get(this.shift, 'employee.name')
    },
    entriesTableData() {
      return this.shift.entries || []
    },
  },
  methods: {
    // formatDate (date) {
    //   console.log("format date")
    //   console.log(date)
    //   return this.$moment(date, DD-MM).format('YYYY-MM-DD HH:mm')
    // },
    getEntryValue(entry, cellType) {
      const { type } = entry
      if (cellType === type) return entry.amount
      else return ''
    },
  },
}
</script>

<style scoped lang="scss">
.summary-row {
  background-color: #f1f1f1;
  font-weight: bold;
  td {
    text-align: right;
  }
}
</style>

import { IEditMenuState, IMenuGetters } from '@/types/interface'

const getActiveMenuId = (state: IEditMenuState) => state.activeMenuId
const categories = (state: IEditMenuState) => state.categories
const sizes = (state: IEditMenuState) => state.sizes
const multiChoiceGroups = (state: IEditMenuState) => state.multiChoiceGroups
const singleChoiceGroups = (state: IEditMenuState) => state.singleChoiceGroups
const mealGroups = (state: IEditMenuState) => state.mealGroups
const selectedCategoryId = (state: IEditMenuState) => state.selectedCategoryId
const form = (state: IEditMenuState) => state.form
const isEditMode = (state: IEditMenuState) => state.form?.id && state.form.id !== 0
const isNewItemMode = (state: IEditMenuState, getters: IMenuGetters) => !getters.isEditMode
const menuElement = (state: IEditMenuState) => state.menuElement
const isSingleChoiceGroup = (state: IEditMenuState) => state.menuElement === 'single_choice_groups'
const isMultiChoiceGroup = (state: IEditMenuState) => state.menuElement === 'multi_choice_groups'
const loaders = (state: IEditMenuState) => state.loaders
const differentPrices = (state: IEditMenuState) => state.differentPrices
const externalMenuSource = (state: IEditMenuState) => state.externalMenuSource
const externalMenuSourceName = (state: IEditMenuState) => state.externalMenuSource?.name
const saving = (state: IEditMenuState) => state.saving
const isEdited = (state: IEditMenuState) => state.isEdited
const editedMenusIds = (state: IEditMenuState) => state.editedMenusIds

export default {
  categories,
  sizes,
  multiChoiceGroups,
  singleChoiceGroups,
  mealGroups,
  selectedCategoryId,
  form,
  loaders,
  differentPrices,
  externalMenuSource,
  externalMenuSourceName,
  menuElement,
  isSingleChoiceGroup,
  isMultiChoiceGroup,
  isEditMode,
  getActiveMenuId,
  isNewItemMode,
  saving,
  isEdited,
  editedMenusIds,
}

<template>
  <div class="edit-section">
    <label for="mealName"> {{ $t('message.menu.meal.name') }} <span class="text-danger ml-1">*</span> </label>
    <b-form-input
      id="meal-name-input"
      v-validate="validateName"
      :value="value"
      class="editName"
      name="mealName"
      :disabled="saving"
      @input="$emit('input', $event)"
      @blur="veeFields.mealName.changed ? $emit('blur') : null"
    />
    <input-error-msg field="mealName" :_errors="errors" />
    <input-error-msg field="mealNameExists" :_errors="errors" />
    <input-error-msg field="ingredientsDuplicated" :_errors="errors" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const STORE = '_menuEdit'

export default {
  name: 'NameMeal',
  inject: ['$validator'],
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(STORE, ['saving']),
    validateName() {
      return {
        required: true,
        min: 1,
      }
    },
  },
}
</script>

<template>
  <div>
    <p v-if="_errors.first(field)" class="text-danger" :class="getClass">{{ errorMessage }}</p>
    <p v-if="responseErrorMessage" class="text-danger" :class="getClass">{{ responseErrorMessage }}</p>
  </div>
</template>

<script>
export default {
  name: 'InputErrorMessage',
  props: {
    field: String,
    _errors: {
      type: Object,
      default: () => {
        return {}
      },
    },
    responseErrors: {
      type: Object,
      default: () => {
        return {}
      },
    },
    small: {
      type: Boolean,
      default: true,
    },
    noMargin: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getClass() {
      return { small: this.small, 'm-0': this.noMargin }
    },
    errorMessage() {
      return this._errors ? this._errors.first(this.field) : ''
    },
    responseErrorMessage() {
      //return this.responseErrors[this.field][0]
      const error = _.get(this.responseErrors, this.field)
      if (error && _.isArray(error) && error.length > 0) return error[0]
      else return false
    },
  },
}
</script>

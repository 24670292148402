<template>
  <tr>
    <td>
      <router-link :to="{ name: 'billing-periods', query: { employee_id: user.id } }" tag="a">
        {{ user.name }}
      </router-link>
    </td>
    <template v-if="billingPeriod">
      <td>{{ billingPeriod.started_at }}</td>
      <td>
        {{ billingPeriod.man_hours }}
        <span v-if="billingPeriod.man_hours_salary !== null"
          >({{ priceDecimal(billingPeriod.man_hours_salary) }} {{ currency }})</span
        >
      </td>
      <td>
        <b-input-group v-if="billingPeriod.finished_at == null">
          <b-input-group-prepend>
            <b-button variant="danger" size="md" @click="showBillingPeriodEntryModal({ user, entryType: 1 })"
              ><i class="fas fa-minus"
            /></b-button>
          </b-input-group-prepend>
          <b-form-input class="font-weight-semi-bold" disabled :value="billingPeriod.advances"></b-form-input>
        </b-input-group>
        <!--<span class="cell-operation-btn">-->
        <!--<i class="fas fa-minus-square text-danger"-->
        <!--@click="showBillingPeriodEntryModal({user, entryType: 1})"></i>-->
        <!--</span>-->
        <span v-else>{{ billingPeriod.advances }}</span>
      </td>
      <td>
        <b-input-group v-if="billingPeriod.finished_at == null">
          <b-input-group-prepend>
            <b-button variant="danger" size="md" @click="showBillingPeriodEntryModal({ user, entryType: 2 })"
              ><i class="fas fa-minus"
            /></b-button>
          </b-input-group-prepend>
          <b-form-input class="font-weight-semi-bold" disabled :value="billingPeriod.penalties"></b-form-input>
        </b-input-group>

        <!--<span class="cell-operation-btn">-->
        <!--<i class="fas fa-minus-square text-danger"-->
        <!--@click="showBillingPeriodEntryModal({user, entryType: 2})"></i>-->
        <!--</span>-->
        <span v-else>{{ billingPeriod.penalties }}</span>
      </td>
      <td>
        <b-input-group v-if="billingPeriod.finished_at == null">
          <b-form-input class="font-weight-semi-bold" disabled :value="billingPeriod.bonuses"></b-form-input>
          <b-input-group-append>
            <b-button variant="success" size="md" @click="showBillingPeriodEntryModal({ user, entryType: 3 })"
              ><i class="fas fa-plus"
            /></b-button>
          </b-input-group-append>
        </b-input-group>
        <!--<span>{{ billingPeriod.bonuses }}</span>-->
        <!--<span class="cell-operation-btn">-->
        <!--<i class="fas fa-plus-square text-success"-->
        <!--@click="showBillingPeriodEntryModal({user, entryType: 3})"></i>-->
        <!--</span>-->
        <span v-else>{{ billingPeriod.bonuses }}</span>
      </td>
      <td>
        <span v-if="billingPeriod.mileage !== null">{{ billingPeriod.mileage }}</span>
        <span v-if="billingPeriod.mileage_salary !== null"
          >({{ priceDecimal(billingPeriod.mileage_salary) }} {{ currency }})</span
        >
      </td>
      <td>
        {{ billingPeriod.orders }}
        <span v-if="billingPeriod.orders_salary !== null"
          >({{
            priceDecimal(priceDecimal(billingPeriod.orders_salary, false).add(priceDecimal(billingPeriod.zones_salary)))
          }}
          {{ currency }})</span
        >
      </td>
      <td>{{ priceDecimal(billingPeriod.total_salary) }} {{ currency }}</td>
      <td>
        <router-link
          :to="{ name: 'billing-period-details', params: { id: billingPeriod.id } }"
          tag="button"
          class="btn btn-primary btn-block"
          >{{ $t('message.billing.details') }}</router-link
        >
      </td>
    </template>
    <template v-else>
      <td v-for="n in 9" class="no-data-cell">---</td>
    </template>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'BillingPeriodRow',
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      billingPeriod: null,
    }
  },
  mounted() {
    this.billingPeriod = this.user.last_billing_period
  },
  computed: {
    ...mapGetters(['currency']),
  },
  methods: {
    showBillingPeriodEntryModal(payload) {
      this.$eventBus.$emit('onShowBillingPeriodEntryModal', payload)
    },
  },
}
</script>

<template>
  <div v-if="Object.values(erestoPreferences).length > 0" class="edit-section">
    <div class="d-flex align-items-center">
      <label class="switch mb-0 mr-2" aria-labelledby="availableInEresto">
        <input :checked="value" type="checkbox" :disabled="saving" @input="onClick" />
        <span class="slider round"></span>
      </label>
      <h4 id="availableInEresto" class="label mb-0">{{ $t('message.menu.sizes.availableInEresto') }}</h4>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const STORE = '_menuEdit'

export default {
  name: 'ErestoSize',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('settings', ['erestoPreferences']),
    ...mapGetters(STORE, ['saving']),
  },
  methods: {
    onClick($event) {
      this.$emit('input', $event.target.checked)
      this.$emit('click')
    },
  },
}
</script>

<template>
  <b-tr class="row--size">
    <b-td>
      <div class="d-flex align-items-center">
        <label class="switch mb-0 mr-2" aria-labelledby="sizeInMealGroup">
          <input :checked="mealExist" type="checkbox" :disabled="saving || shouldDisable" @change="switchToggled" />
          <span class="slider round"/>
        </label>

        <h4 id="sizeInMealGroup" class="label mb-0 text-wrap">
          {{ size.name === 'default' ? $t('message.menu.defaultSizeName') : size.name }}
        </h4>
      </div>
    </b-td>

    <b-td>
      <vat-dropdown v-if="mealExist" v-model="meal.vat" label-hide class="vat" @click="$emit('blur')" />
    </b-td>
    
    <b-td class="text-right">
      <price-input
        v-if="mealExist"
        v-model="meal.price"
        v-validate="validator"
        class="categorySize-price categorySize-price-meal"
        :append-text="currency"
        name="sizePrice"
        :disabled="saving"
        @blur="onPriceChange"
      />
      
      <price-input v-else class="categorySize-price categorySize-price-meal" :append-text="currency" disabled />
    </b-td>
    
    <b-td v-if="isErestoBrand && differentPrices" class="text-right">
      <price-input
        v-if="mealExist"
        v-model="meal.erestaurant_price"
        v-validate="validator"
        :class="['categorySize-price categorySize-price-meal', !erestoPriceTouched ? 'categorySize-price--border-dashed' : '']"
        :append-text="currency"
        name="sizePrice"
        :disabled="saving"
        @blur="onErestoPriceChange"
      />
      
      <price-input v-else class="categorySize-price categorySize-price-meal" :append-text="currency" disabled />
    </b-td>
  </b-tr>
</template>

<script>
import PriceInput from '@/components/PriceInput'
import VatDropdown from '../generic/VatDropdown'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

const STORE = '_menuEdit'

export default {
  name: 'SizeMeal',
  components: {
    PriceInput,
    VatDropdown,
  },
  props: {
    size: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    validator: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      erestoPriceTouched: false,
    }
  },
  computed: {
    ...mapFields(STORE, ['form.meals']),
    ...mapGetters({
      differentPrices: `${STORE}/differentPrices`,
      isEditMode: `${STORE}/isEditMode`,
      isErestoBrand: 'auth/isErestoBrand',
      saving: `${STORE}/saving`,
      currency: 'currency',
    }),
    meal() {
      return this.meals?.find((meal) => meal.size === this.size.id)
    },
    mealExist() {
      return !!this.meal
    },
    shouldDisable() {
      return this.mealExist && this.meals.length <= 1
    },
  },
  methods: {
    ...mapMutations(STORE, ['setSavingStatus', 'setIsEdited']),
    ...mapActions(STORE, ['refreshMenu']),
    switchToggled() {
      if (this.mealExist) {
        this.$emit('removeMeal', this.size)
      } else {
        this.$emit('createMeal', this.size)
      }
    },
    onPriceChange() {
      if (!this.isEditMode && !this.erestoPriceTouched) this.meal.erestaurant_price = this.meal.price
      this.$emit('blur')
    },
    onErestoPriceChange(){
      this.erestoPriceTouched = true
      this.$emit('blur')
    }
  },
}
</script>
<style lang="scss">
.row--size {
  td {
    height: 75px;
  }

  .categorySize-price-meal {
    display: flex;
    margin: 0 auto;

    legend {
      padding-bottom: 0;
    }
  }

  .vat {
    text-align: center;
    margin-bottom: 0;

    .vat-dropdown {
      .btn {
        min-height: 38px;
      }
    }
  }
}

.categorySize-price--border-dashed{
  input{
    border:1px dashed #c2c4ca !important;
  }
}
</style>

<template>
  <div class="auth-page">
    <div v-if="appLoaded" class="auth-logo">
      <img :src="logoSrc" />
    </div>
    <slot />
    <footer class="auth-footer d-flex justify-content-between p-3">
      <img class="eu-flag" src="/static/images/EU.png" />
      <div v-if="!isOrdersmart">
        <div class="download-courier-app-text text-right">{{ $t('message.auth.appDownload') }}</div>
        <div class="stores-container d-flex justify-content-end align-items-center">
          <a
            class="store-link store-link--google"
            href="https://play.google.com/store/apps/details?id=io.papu.papuio&hl=pl&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          />
          <a class="store-link store-link--apple" href="https://apps.apple.com/pl/app/papu-io/id1468463068?mt=8" />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'AuthLayout',
  data() {
    return {
      appLoaded: false,
    }
  },
  computed: {
    isOrdersmart() {
      return this.$route.query?.ref === 'ordersmart'
    },
    logoSrc() {
      return this.isOrdersmart ? '/static/images/order-smart-logo.svg' : '/static/images/papuio_logo_white_tagline.png'
    },
  },
  mounted() {
    setTimeout(() => {
      this.appLoaded = true
    }, 300)
  },
}
</script>

<style scoped lang="scss">
.auth-page {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-image: url('/static/images/auth-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.auth-logo {
  margin-top: auto;
  margin-bottom: 50px;
  img {
    width: 320px;
  }
}
.auth-footer {
  width: 100%;
  margin-top: auto;
}
.eu-flag {
  width: 60px;
  height: 45px;
  @include media-breakpoint-up(md) {
    width: 80px;
    height: 65px;
  }
}
.store-link {
  display: inline-block;
  overflow: hidden;
  width: 100px;
  height: 30px;
  background-position: center;
  background-size: cover;

  @include media-breakpoint-up(md) {
    width: 135px;
    height: 40px;
  }

  &--google {
    background-image: url('/static/images/google-play-badge.png');
  }
  &--apple {
    background-image: url('/static/images/apple-appstore-badge.svg');
  }
}
.download-courier-app-text {
  font-size: 12px;
  color: #fff !important;
  margin-bottom: 5px;
  @include media-breakpoint-up(md) {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
@media (max-width: 600px) {
  .auth-logo {
    img {
      width: 220px;
    }
  }
}
</style>

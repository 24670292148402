<template>
  <div>
    <Loader v-if="loading" />
    <b-container fluid>
      <b-row class="mb-2">
        <b-col>
          <b-form-group class="mb-1">
            <order-customer-phone v-model="phone" :disabled="isPreview" @setCustomerAddress="setCustomerAddress" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group class="mb-0">
            <order-customer-address
              v-model="address"
              :address="address"
              :city="city"
              :disabled="isPreview"
              :pickup-type="pickup_type"
              @setAddress="setAddress"
              @setCity="setCity"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-0 mb-2" />

      <b-row>
        <b-col class="mb-3">
          <b-form-group :label="$t('message.orderFormFull.pickupType')" class="mb-0">
            <pickup-type v-model="pickup_type" :disabled="isPreview" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="mb-3">
          <b-form-group :label="$t('message.orderFormFull.orderSource')" class="mb-0">
            <source-change
              v-model="menu_source"
              :change-menu-source="changeMenuSource"
              :response-errors="responseErrors"
              :no-label="true"
              :disabled="isPreview"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="row mb-2">
        <b-col>
          <b-form-group :label="$t('message.orderFormFull.deliveryType')" class="mb-0">
            <delivery-type v-model="delivery_type" :disabled="isPreview" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group :label="$t('message.orderFormFull.promisedTime')" class="mb-1">
            <PromisedTime
              v-if="!isPreview"
              :pick-time="isPromisedTimePickTime"
              default-time="15"
              :custom-promised-time="promised_time"
              :modal="true"
              :is-edit="editMode === 'edit'"
              @input="changePromisedTime"
            />
            <div v-else class="promised-time-value">{{ promised_time }}</div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group :label="$t('message.invoiceModal.paymentType')" class="mb-2">
            <payment-type v-model="payment" :disabled="isPreview" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group :label="$t('message.cart.totalPrice')" class="mb-2">
            <price-input
              id="total-price-input"
              v-model="price"
              name="price"
              :append-text="currency"
              :disabled="isPreview"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-0 mb-2" />

      <b-row>
        <b-col>
          <b-form-group :label="$t('message.cart.comment')">
            <b-form-textarea v-model="comment" rows="1" size="sm" :disabled="isPreview" />
          </b-form-group>
        </b-col>
      </b-row>

      <response-error-msg field="non_field_errors" :response-errors="responseErrors" />

      <response-error-msg field="promised_time" :response-errors="responseErrors" />
    </b-container>
    <div class="d-flex w-100 modal-footer">
      <b-button
        v-if="editMode === 'new'"
        v-shortkey="!loading ? ['enter'] : ['null']"
        variant="success"
        size="lg"
        block
        :disabled="loading"
        @click="_placeOrder"
        @shortkey="_placeOrder"
      >
        <kbd class="pb-0 pt-1 mr-2 bg-white text-dark">&crarr;</kbd>
        {{ $t('message.navMain.addOrder') }}
      </b-button>

      <template v-else-if="editMode === 'edit'">
        <b-button
          v-shortkey="!loading ? ['esc'] : ['null']"
          variant="secondary"
          size="lg"
          :disabled="loading"
          @click="cancelEdit"
          @shortkey="cancelEdit"
        >
          <kbd class="pb-0 pt-1 mr-2 bg-white text-dark">&crarr;</kbd>
          {{ $t('message.common.cancel') }}
        </b-button>

        <b-button
          v-shortkey="!loading ? ['enter'] : ['null']"
          variant="success"
          size="lg"
          :disabled="loading"
          @click="_updateOrder"
          @shortkey="_updateOrder"
        >
          <kbd class="pb-0 pt-1 mr-2 bg-white text-dark">&crarr;</kbd>
          {{ $t('message.navMain.saveChanges') }}
        </b-button>
      </template>
    </div>
  </div>
</template>

<script>
import {
  DATETIME_TIMEZONE,
  DELIVERY_TYPES,
  MENU_SOURCE_CHOICES,
  POS_API_DATETIME_INPUT_FORMAT,
} from '@/common/constants'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import moment from 'moment-timezone'
import { responseErrorsHandler } from '@/mixins'
import PromisedTime from '@/components/PromisedTime'
import SourceChange from '@/components/source-change/SourceChange'
import DeliveryType from '@/components/order-form/DeliveryType'
import PickupType from '@/components/order-form/PickupType'
import PaymentType from '@/components/order-form/PaymentType'
import OrderCustomerPhone from '@/components/order-form/OrderCustomerPhone'
import OrderCustomerAddress from '@/components/order-form/OrderCustomerAddress'
import Loader from '@/components/Loader'
import PriceInput from '@/components/PriceInput'

const STORE = 'newOrder'

export default {
  name: 'OrderPapu3Form',
  components: {
    SourceChange,
    PromisedTime,
    DeliveryType,
    PickupType,
    OrderCustomerPhone,
    OrderCustomerAddress,
    Loader,
    PriceInput,
    PaymentType,
  },
  mixins: [responseErrorsHandler],
  provide() {
    return {
      parentValidator: this.$validator,
    }
  },
  data() {
    return {
      visible: false,
      promisedTimeValue: 15,
      menuSourceChanged: false,
      paymentTypes: [
        { name: this.$t('message.orderFormFull.paymentTypes.cash'), value: 'cash' },
        { name: this.$t('message.orderFormFull.paymentTypes.paid'), value: 'paid' },
        { name: this.$t('message.orderFormFull.paymentTypes.card'), value: 'card' },
      ],
    }
  },
  computed: {
    ...mapGetters(['currency', 'menu', 'currentBrandId']),
    ...mapGetters('auth', ['getSourceById', 'getLocalizationCity']),
    ...mapGetters(STORE, ['order', 'loading', 'editMode', 'orderData']),
    ...mapFields(STORE, {
      phone: 'order.customer.phone',
      address: 'order.customer.address.address',
      city: 'order.customer.address.city',
      pickup_type: 'order.pickup_type',
      payment: 'order.payment',
      delivery_type: 'order.delivery_type',
      promised_time: 'order.promised_time',
      price: 'order.price',
      comment: 'order.comment',
      menu_source: 'menu_source',
    }),
    isPromisedTimePickTime() {
      return this.delivery_type === DELIVERY_TYPES.ONTIME || this.editMode === 'edit'
    },
    isPreview() {
      return this.editMode === 'preview'
    },
    getPaymentTypes() {
      // Filters paymentTypes list dependent on chosen menuSource
      if (this.menuSource === MENU_SOURCE_CHOICES.UBEREATS) {
        return this.paymentTypes.filter(function (paymentType) {
          if (['paid'].includes(paymentType['value'])) {
            return paymentType
          }
        })
      } else {
        return this.paymentTypes
      }
    },
  },
  watch: {
    promisedTimeValue: function () {
      this.changePromisedTime(this.promisedTimeValue)
    },
  },
  mounted() {
    this.setDefaultSourceMenu()
    this.changePromisedTime(15)
  },
  beforeDestroy() {
    this.resetState()
  },
  methods: {
    ...mapMutations({
      setDelayOrdersListRefresh: 'setDelayOrdersListRefresh',
    }),
    ...mapMutations(STORE, ['setMenu', 'resetState', 'fillOrderForm', 'setEditMode']),
    ...mapActions(STORE, ['placeOrder', 'updateOrder', 'restoreForm']),
    setDefaultSourceMenu() {
      const defaultMenuSource = this.getSourceById(this.currentBrandId, MENU_SOURCE_CHOICES.DEFAULT)
      this.setMenu({ menu_source: MENU_SOURCE_CHOICES.DEFAULT, menu_id: defaultMenuSource.menu_id })
    },
    changeMenuSource(menu_source, menu_id) {
      this.setMenu({ menu_source, menu_id })
      this.menuSourceChanged = true
    },
    changePromisedTime(promisedTimeValue) {
      if (!promisedTimeValue) promisedTimeValue = 0
      if (this.delivery_type === DELIVERY_TYPES.ASAP && this.editMode !== 'edit') {
        this.promised_time = moment()
          .tz(DATETIME_TIMEZONE)
          .add(promisedTimeValue, 'minutes')
          .format(POS_API_DATETIME_INPUT_FORMAT)
      } else if (typeof promisedTimeValue === 'string') {
        this.promised_time = promisedTimeValue
      }
    },
    setAddress(value) {
      this.address = value
    },
    setCity(value) {
      this.city = value
    },
    setCustomerAddress(value) {
      const { address, city, comment } = value
      this.address = address
      this.city = city
      this.comment = comment
    },
    getOrderData() {
      const orderData = {
        ...this.order,
      }

      if (!orderData.comment) delete orderData.comment

      if (!orderData.customer.address.city) orderData.customer.address.city = this.getLocalizationCity

      if (!this.menuSourceChanged && this.editMode === 'edit') delete orderData.menu

      return orderData
    },
    _placeOrder() {
      this.$validator.validateAll().then((result) => {
        if (!result) return

        this.placeOrder({
          order: this.getOrderData(),
          onSuccess: (responseData) => {
            this.clearResponseErrors({})
            this.resetState()
            this.$eventBus.$emit('hideOrderPapu3Modal')
          },
          onError: (error) => {
            // this.validationErrors = error.response.data
            this.setResponseErrors(error.response.data)
          },
        })
      })
    },
    _updateOrder() {
      this.$validator.validateAll().then((result) => {
        if (!result) return

        this.updateOrder({
          uuid: this.orderData.uuid,
          order: this.getOrderData(),
          onSuccess: (responseData) => {
            this.clearResponseErrors({})
            this.setEditMode('preview')
          },
          onError: (error) => {
            // this.validationErrors = error.response.data
            this.setResponseErrors(error.response.data)
          },
        })
      })
    },
    cancelEdit() {
      this.restoreForm()
      this.setEditMode('preview')
    },
  },
}
</script>

<style lang="scss">
.order-modal {
  &-body {
    padding-bottom: 0 !important;
  }
  &-dialog {
    @include media-breakpoint-down(sm) {
      margin: 0 !important;
      display: inline !important;
      height: 100% !important;
      max-height: 100% !important;
    }
  }
  &-content {
    @include media-breakpoint-down(sm) {
      border-radius: 0 !important;
    }
  }
}
</style>

<style scoped lang="scss">
.order-modal {
  &__price {
    margin-top: 0.5rem;
    max-width: 100px;
  }
}
.promised-time-value {
  text-align: center;
  height: 100%;
  border: 1px dashed #e0e0e0;
  border-radius: 3px;
  background: #fdfdfd;
  padding: 12px;
  font-size: 18px;
  font-weight: bold;
}
</style>

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { __DEV__ } from '@/utils/global'

export class ApiService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: 'https://localhost',
    })

    this.axiosInstance.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8'
  }

  private static encodeData(data: any) {
    let query = ''
    for (const key in data) {
      query += encodeURIComponent(key) + '=' + encodeURIComponent(data[key]) + '&'
    }
    return query
  }

  async axiosCall<T>(config: AxiosRequestConfig) {
    if (__DEV__)
      console.log(`%cREQUEST|${config.method}: ${config.url}`, 'background: #00F; color: #fff', 'Request: ', config)
    try {
      const response = await this.axiosInstance.request<T>(config)
      if (__DEV__) console.log(`%cSUCCESS|${config.method}: ${config.url}`, 'background: #0F0; color: #000', response)
      const { data } = response
      return { error: null, data }
    } catch (error) {
      if (__DEV__) console.log(`%cERROR|${config.method}: ${config.url}`, 'background: #F00; color: #BADA55', error)
      return { error, data: null }
    }
  }

  async printBill(data: any) {
    return this.axiosCall({ method: 'post', data: ApiService.encodeData(data) })
  }
  async getBillError(data: any) {
    return this.axiosCall({ method: 'post', data: ApiService.encodeData(data) })
  }
  async openDrawer(data: any) {
    return this.axiosCall({ method: 'post', data: ApiService.encodeData(data) })
  }
}

export const APILocalPrinter = new ApiService()

<template>
  <div>
    <b-navbar class="main-nav main-nav--order-details" type="dark">
      <div class="main-nav__left" style="margin-left: -15px">
        <b-navbar-nav>
          <li class="nav-item">
            <b-button variant="navbar" class="nav-link nav-link-button bg-transparent" @click="onClose()">
              <i class="fas fa-times-circle" :aria-label="$t('message.common.back')" style="font-size: 24px" />
            </b-button>
          </li>
        </b-navbar-nav>
        <div class="nav-item-info">
          <span class="nav-item-info__title">{{ $t('message.orderDetails.orderNumber') }}:</span>
          <b-spinner v-if="isLoading" small class="nav-item-info__spinner" />
          <strong v-else class="nav-item-info__value">{{ order.number }}</strong>
        </div>
        <NavItemOrderStatus
          class="d-none d-md-flex"
          :status-translated="order.status_translated"
          :is-loading="isLoading"
          :finished-type-translated="order.finished_type_translated"
        />
        <NavItemOrderSource
          class="d-none d-md-flex"
          :is-loading="isLoading"
          :menu-source="order.menu_source"
          :menu-translated="order.menu_translated"
          :external-display-id="order.external_display_id"
        />
      </div>
      <div class="main-nav__right">
        <b-button
          v-if="order.logs && order.logs.length > 1"
          id="editHistoryButton"
          v-b-tooltip.hover
          :size="isMobile ? 'sm' : 'md'"
          class="mr-2"
          variant="warning"
          :title="$t('order_changes_history')"
          @click="showOrderEditHistoryModal()"
        >
          <i class="fas fa-history" />
        </b-button>
        <b-button
          v-if="$hasModuleAccess('edit-order')"
          id="editOrder"
          :class="`mr-${isMobile ? '3' : '0'}`"
          :size="isMobile ? 'sm' : 'md'"
          :variant="editButtonActive ? 'warning' : ''"
          :disabled="isLoading || !editButtonActive"
          @click="editOrder()"
        >
          <i class="fas fa-edit" />
          <span>{{ $t('message.common.edit') }}</span>
        </b-button>
      </div>
    </b-navbar>
    <div class="main-nav main-nav--order-details mobile-nav-expand d-md-none">
      <NavItemOrderStatus
        :status-translated="order.status_translated"
        :is-loading="isLoading"
        :finished-type-translated="order.finished_type_translated"
      />
      <NavItemOrderSource
        :is-loading="isLoading"
        :menu-source="order.menu_source"
        :menu-translated="order.menu_translated"
        :external-display-id="order.external_display_id"
      />
    </div>
  </div>
</template>

<script>
import { MENU_SOURCE_COLORS } from '@/common/constants'
import { mapGetters } from 'vuex'
import NavItemOrderStatus from '@/components/order-details/NavItemOrderStatus'
import NavItemOrderSource from '@/components/order-details/NavItemOrderSource'

export default {
  name: 'NavOrderDetails',
  components: { NavItemOrderStatus, NavItemOrderSource },
  props: {
    onClose: { type: Function, required: true },
    isLoading: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      required: true,
    },
    editButtonActive: {
      type: Boolean,
      default: false,
    },
    editOrder: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      MENU_SOURCE_COLORS,
    }
  },
  computed: {
    ...mapGetters(['currency', 'isMobile']),
    ...mapGetters('_orderEdit', ['cartNumber', 'orderTotalPrice', 'showMealsMenu', 'showOrderForm']),
  },
  methods: {
    showOrderEditHistoryModal() {
      this.$eventBus.$emit('showOrderEditHistoryModal')
    },
  },
}
</script>

<style scoped lang="scss">
.mobile-nav-expand {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-extra-header {
  background-color: red;
}
</style>

<template>
  <div class="order-edit-container" :class="getClass">
    <NavNewOrder :on-close-order-edit="closeOrderEdit" />
    <MealsMenu />
    <OrderPreview v-if="showMealsMenu || (showOrderForm && orderOnSite)" />
    <OrderForm v-if="!orderOnSite && showOrderForm" />
    <order-on-site-modal />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import store from './_store'
import { printBillMixins } from '@/mixins'
import MealsMenu from '@/components/meals-menu/MealsMenu'
import OrderForm from '@/components/order-form/OrderForm'
import OrderPreview from '@/components/order-form/OrderPreview'
import OrderOnSiteModal from '@/components/order-form/OrderOnSiteModal'
import NavNewOrder from '@/components/navigation/NavNewOrder'

const STORE = '_orderEdit'

export default {
  name: 'EditOrder',
  components: {
    NavNewOrder,
    OrderOnSiteModal,
    MealsMenu,
    OrderForm,
    OrderPreview,
  },
  mixins: [printBillMixins],
  beforeRouteLeave(to, from, next) {
    // If meal was changed, it pops up confirmation modal, otherwise changes view
    if (this.$store.getters['_orderEdit/mealSaved']) {
      if (from.name === 'edit-order' && to.name === 'new-order') {
        // Hack na zresetowanie store
        this.$store.unregisterModule(STORE)
      }
      next()
    } else this.$eventBus.$emit('onShowMealDiscardChangesModal')
  },
  props: ['order'],
  data: function () {
    return {}
  },
  computed: {
    ...mapGetters(STORE, ['showMealsMenu', 'showOrderForm', 'orderOnSite']),
    formType() {
      return this.orderOnSite ? 'onsite' : 'delivery'
    },
    getClass() {
      if (this.showMealsMenu) {
        return 'order-edit-container--meals-menu-visible'
      } else if (!this.orderOnSite && this.showOrderForm) {
        return 'order-edit-container--order-form-visible'
      } else if (this.orderOnSite && this.showOrderForm) {
        return 'order-edit-container--order-preview-only-visible'
      }
    },
  },
  watch: {
    $route() {
      this.checkIfOrderOnSite()
      this.$store.commit('_orderEdit/setEmptyCart')
      this.$store.commit('_orderEdit/toggleOrderForm', false)
      this.setPrintBill(this.formType)
    },
  },
  beforeCreate() {
    if (!this.$store.state[STORE]) this.$store.registerModule(STORE, store)
  },
  created() {
    this.checkIfOrderOnSite()
    this.setInitialBills()
  },
  beforeMount() {
    // Set orderFormLong to not be shown on start
    this.$store.commit('_orderEdit/toggleOrderForm', false)
  },
  mounted() {
    if (this.$route.params.id) {
      // Jeśli edycja zamówienia
      this.setEditMode()
    } else {
      // Jeśli nowe zamówienie
      this.$store.commit('_orderEdit/setEmptyCart')
    }
    this.setPrintBill(this.formType)
  },
  beforeDestroy() {
    this.$eventBus.$off('onMealEdit')
  },
  destroyed() {
    this.$store.commit('_orderEdit/resetState')
  },
  methods: {
    ...mapMutations(STORE, ['setCart', 'setOrder']),
    setEditMode() {
      this.$store.commit('_orderEdit/setOrderId', this.$route.params.id)
      this.$store.commit('_orderEdit/setIsEdit', true)
      // if came from by url, then fetch order (order prop will be null)
      if (!this.order) this.$store.dispatch('_orderEdit/getOrder')
      else {
        // if came from orderDetails order prop is filled - use it
        this.setCart(this.order)
        this.setOrder(this.order)
      }
    },
    checkIfOrderOnSite() {
      const {
        query: { orderOnSite },
      } = this.$route
      const orderOnSiteParsed = typeof orderOnSite === 'string' ? orderOnSite === 'true' : orderOnSite
      this.$store.commit('_orderEdit/setOrderOnSite', orderOnSiteParsed)
    },
    setInitialBills() {
      this.$store.commit(`${STORE}/setBillsInCart`, [
        {
          value: 0,
          text: this.$t('message.mealDetails.main'),
          type: 'bill',
        },
      ])
    },
    closeOrderEdit() {
      this.$router.push({ name: 'couriers-map' })
    },
  },
}
</script>

<style lang="scss" scoped>
.orderEdit {
  display: flex;
  height: 100%;
  overflow: auto;
}
.order-edit-container {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  @media (min-width: 1280px) {
    grid-gap: 10px;
  }

  &--meals-menu-visible {
    grid-template-columns: 1fr max-content;
  }
  &--order-form-visible {
    grid-template-columns: max-content 1fr;
  }
  &--order-preview-only-visible {
    grid-template-columns: 0 1fr;
  }
}
</style>

import { RegionsState } from './index'
import _ from 'lodash'

const regions = (state: RegionsState) => _.sortBy(state.regions, 'id')
const regionsRequest = (state: RegionsState) => state.regionsRequest
const isAddingNewRegion = (state: RegionsState) => state.addingNewRegion
const isEditing = (state: RegionsState) => state.isEditing
const editedRegionPoints = (state: RegionsState) => state.editedRegionPoints
const validEditedRegionPoints = (state: RegionsState) => {
  let valid = true
  const pointsCount = state.editedRegionPoints.length
  if (pointsCount >= 3) {
    state.editedRegionPoints.forEach((point) => {
      if (!_.isNumber(point.lat) || !_.isNumber(point.lon)) {
        valid = false
        return false
      }
    })
  } else valid = false
  return {
    pointsCount,
    valid,
  }
}
const editedRegionId = (state: RegionsState) => state.editedRegionId

export default {
  regions,
  regionsRequest,
  isAddingNewRegion,
  isEditing,
  editedRegionPoints,
  validEditedRegionPoints,
  editedRegionId,
}

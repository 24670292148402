import { API } from '@/services/api/api'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import { INewOrderState } from '@/store/modules/new-order/index'

const getOrder = async ({ commit, state, dispatch }: ActionContext<INewOrderState, RootState>, uuid: string) => {
  commit('setLoading', true)
  const { error, data } = await API.p3_getOrder(uuid)
  if (!error && data) {
    commit('setOrderData', data)
    commit('fillOrderForm', data)
  }
  commit('setLoading', false)
}

const placeOrder = async ({ dispatch, commit }: ActionContext<INewOrderState, RootState>, payload: any) => {
  const { order, onError, onSuccess } = payload
  commit('setLoading', true)
  const { error, data } = await API.p3_createOrder(order)
  if (!error && data) {
    if (onSuccess) onSuccess(data)
    dispatch('ordersPapu3/getOrders', { filters: { archive: false } }, { root: true })
    await dispatch('goToDefaultOrdersList', {}, { root: true })
  } else {
    if (onError) onError(error)
  }
  commit('setLoading', false)
}

const updateOrder = async ({ dispatch, commit }: ActionContext<INewOrderState, RootState>, payload: any) => {
  const { uuid, order, onError, onSuccess } = payload
  commit('setLoading', true)

  const { error, data } = await API.p3_updateOrder(uuid, order)
  if (!error && data) {
    if (onSuccess) onSuccess(data)
    commit('setOrderData', data)
    commit('fillOrderForm', data)
    dispatch('ordersPapu3/getOrders', { filters: { archive: false } }, { root: true })
  } else {
    if (onError) onError(error)
  }
  commit('setLoading', false)
}

const restoreForm = ({ state, commit }: ActionContext<INewOrderState, RootState>) => {
  commit('fillOrderForm', state.orderData)
}

export default {
  getOrder,
  placeOrder,
  updateOrder,
  restoreForm,
}

<template>
  <b-row align-v="center">
    <b-col cols="6" md="4" class="d-flex align-items-center">
      <b-form-group :label="$t('message.cart.insertCash')">
        <b-form-input
          id="cash-value"
          type="number"
          size="lg"
          :value="value"
          @keyup.enter.stop
          @input="$emit('input', $event)"
        />
      </b-form-group>
      <i class="fas fa-minus ml-3" />
    </b-col>
    <b-col cols="6" md="4">
      <b-form-group :label="$t('message.cart.price')">
        <div class="form-control form-control-lg form-control-block-info">
          <span>{{ totalPrice }}</span>
        </div>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="4" class="d-flex align-items-center">
      <i class="fas fa-equals mr-3" />
      <b-form-group :label="$t('message.cart.change')">
        <div class="form-control form-control-lg form-control-block-info">
          <span>{{ calcRest }}</span>
        </div>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'CashCalculator',
  props: {
    totalPrice: {
      type: [Number, String],
      required: true,
    },
    value: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    calcRest() {
      return (this.value - parseFloat(this.totalPrice)).toFixed(2)
    },
  },
}
</script>
<style scoped lang="scss">
.form-control-block-info {
  background: #ebeff3;
  font-size: 18px;
  text-align: center;
}
</style>

import { DATETIME_TIMEZONE, PICKUP_TYPE_DELIVERY, POS_API_DATETIME_INPUT_FORMAT } from '@/common/constants'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { getField, updateField } from 'vuex-map-fields'
import moment from 'moment-timezone'

const initialState = {
  orderId: null,
  order: {},
  cart: {
    customer: {
      address: '',
      addressComment: '',
      city: '',
      pickupType: PICKUP_TYPE_DELIVERY,
      name: '',
      paymentType: '',
      phone: sessionStorage.getItem('callerID'),
      promisedTime: moment().tz(DATETIME_TIMEZONE).format(POS_API_DATETIME_INPUT_FORMAT),
      promisedTimeValue: '',
      deliveryType: 'asap',
      company_tax_id: '',
      company_name: '',
      email: '',
    },
    deliveryPrice: null,
    manualDeliveryPrice: null,
    id: null,
    meals: [],
    totalPrice: 0,
    fullPrice: 0,
    serviceChargeValue: 0,
    serviceChargePercent: 0,
    lat: null,
    lon: null,
    discountChoice: null,
    discountValue: '',
    discountCode: null,
    discountCodeApplied: false,
    packagePrice: null,
    bills: [],
    predictedPromisedTime: '',
  },
  test: {
    phone: '00000',
  },
  isEdit: false,
  show: {
    mealsMenu: true,
    orderForm: false,
    menu: {
      full: true,
      categories: true,
      sizes: true,
      meals: true,
      mealSearchResults: false,
      mealDetails: false,
    },
    mealSetItemMenu: false,
  },
  foundCustomers: [],
  orderOnSite: false,
  externalOrder: false,
  mealSaved: true,
  deliveryArea: null,
  areaWarning: null,
  cartTotalPriceChanged: false,
  estimatedDeliveryTime: NaN,
  initialAreaDeliveryPrice: null,
  oldGeocodeAddress: '',
  deliveryAreaAndPriceChangedPopup: false, // used to show deliveryAreaAndPriceChangedPopup modal in orderFormFull view
  printBill: false,
  allBills: [],
  baseBill: null,
  bills: [],
  mobileViewActive: 'meals-menu',
  editOrderRequestStatus: '',
  addressHintRequests: 0,
  addressHintClicks: 0,
}

const state = { ...initialState }

export default {
  namespaced: true,
  state() {
    return { ...state }
  },
  getters: {
    getField,
    ...getters,
  },
  mutations: {
    updateField,
    resetState: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    ...mutations,
  },
  actions,
}

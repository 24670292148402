import { getStartDateToday, getEndDateToday, DATETIME_TIMEZONE, COMMON_DATETIME_INPUT_FORMAT } from '@/common/constants'
import moment from 'moment-timezone'
import store from '@/store'

export const dateFiltersFromTimeFilters = {
  data() {
    return {}
  },
  methods: {
    saveDateFilters() {
      localStorage.setItem(
        'dateFilters',
        JSON.stringify({
          startDate: this.dateFilters.startDate,
          endDate: this.dateFilters.endDate,
        })
      )
    },
    getTodayTimeFilter(time) {
      let timeFilters = store.getters['settings/defaultTimeFilters']
      if (timeFilters) {
        let weekday = new Date().getDay()
        if (weekday === 0) weekday = 7 // fix for sunday which is 0 in JS, but time_filters' sunday is 7
        let filter = timeFilters[weekday]
        const startTime = moment()
          .tz(DATETIME_TIMEZONE)
          .set({
            hour: parseInt(filter.startTime.slice(0, 2)),
            minute: parseInt(filter.startTime.slice(3, 5)),
          })
        if (time === 'startTime') {
          if (filter.startTime !== '') {
            return startTime.format(COMMON_DATETIME_INPUT_FORMAT)
          } else {
            return getStartDateToday()
          }
        }
        if (time === 'endTime') {
          if (filter.endTime !== '') {
            let endTime = moment()
              .tz(DATETIME_TIMEZONE)
              .set({
                hour: parseInt(filter.endTime.slice(0, 2)),
                minute: parseInt(filter.endTime.slice(3, 5)),
              })
            if (endTime.isBefore(startTime)) {
              endTime = moment(endTime).tz(DATETIME_TIMEZONE).add(1, 'days')
            }
            return endTime.format(COMMON_DATETIME_INPUT_FORMAT)
          } else {
            return getEndDateToday()
          }
        }
      } else {
        if (time === 'startTime') return getStartDateToday()
        else return getEndDateToday()
      }
    },
    getFiltersFromLocalStorage() {
      let filters = localStorage.getItem('dateFilters')
      if (!filters) return false
      filters = JSON.parse(filters)
      const { startDate, endDate } = filters
      this.dateFilters.startDate = startDate
      this.dateFilters.endDate = endDate
    },
  },
}

<template>
  <div class="mb-3">
    <label for="description"> {{ $t('message.menu.meal.description') }}: </label>
    <b-form-textarea
      id="description"
      v-validate="{}"
      name="mealDescription"
      :value="value"
      :disabled="saving"
      @input="$emit('input', $event)"
      @blur="veeFields.mealDescription.changed ? $emit('blur') : null"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const STORE = '_menuEdit'

export default {
  name: 'ErestoDescriptionMeal',
  inject: ['$validator'],
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(STORE, ['saving']),
  },
}
</script>

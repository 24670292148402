import {
  IEditMenuState,
  ICategory,
  ISize,
  IMultiChoiceGroup,
  ISingleChoiceGroup,
  IMealGroup,
  IEditMenuForm,
  IEditMenuLoaders,
  IMenuSource,
  IEntry,
} from '@/types/interface'

const setActiveMenuId = (state: IEditMenuState, menuId: number) => {
  state.activeMenuId = menuId
}

const setCategories = (state: IEditMenuState, categories: Array<ICategory>) => {
  state.categories = categories
}

const setSizes = (state: IEditMenuState, sizes: Array<ISize>) => {
  state.sizes = sizes
}

const setMultiChoiceGroups = (state: IEditMenuState, multiChoiceGroups: Array<IMultiChoiceGroup>) => {
  state.multiChoiceGroups = multiChoiceGroups
}

const setSingleChoiceGroups = (state: IEditMenuState, singleChoiceGroups: Array<ISingleChoiceGroup>) => {
  state.singleChoiceGroups = singleChoiceGroups
}

const setMealGroups = (state: IEditMenuState, mealGroups: Array<IMealGroup>) => {
  state.mealGroups = mealGroups
}

const setForm = (state: IEditMenuState, data: IEditMenuForm) => {
  state.form = data
}

const setDefaultEntry = (state: IEditMenuState, entryId: number) => {
  // @ts-ignore
  state.form?.entries?.forEach((entry: IEntry) => {
    entry.is_default = entry.id === entryId
  })
  // @ts-ignore
  state.form = { ...state.form }
}

const clearForm = (state: IEditMenuState) => {
  state.form = null
}

const setLoaders = (state: IEditMenuState, loader: Partial<IEditMenuLoaders>) => {
  state.loaders = { ...state.loaders, ...loader }
}

const setSelectedCategoryId = (state: IEditMenuState, categoryId: number) => {
  state.selectedCategoryId = categoryId
}

const setMenuElement = (state: IEditMenuState, name: string) => {
  state.menuElement = name
}

const setSavingStatus = (state: IEditMenuState, value: boolean) => {
  state.saving = value
}

const setIsEdited = (state: IEditMenuState, value: boolean) => {
  state.isEdited = value
  if (!state.editedMenusIds.find((id) => id === state.activeMenuId)) {
    state.editedMenusIds.push(state.activeMenuId)
  }
}

const setExternalMenuSource = (state: IEditMenuState, source: IMenuSource) => {
  state.externalMenuSource = source
}

export default {
  setActiveMenuId,
  setCategories,
  setSizes,
  setMultiChoiceGroups,
  setSingleChoiceGroups,
  setMealGroups,
  setForm,
  clearForm,
  setLoaders,
  setSelectedCategoryId,
  setDefaultEntry,
  setMenuElement,
  setSavingStatus,
  setIsEdited,
  setExternalMenuSource,
}

<template>
  <div v-b-tooltip.hover.bottom :class="['meal-status-icon', getClass]" :title="statusName" :style="[getSizeStyle()]">
    <i :class="`fas fa-${iconName}`" />
  </div>
</template>
<script>
import { mealsStatusMixins } from '@/mixins/mealStatusMixins'
import { MEAL_STATUSES } from '@/common/constants'

export default {
  name: 'MealStatusIcon',
  mixins: [mealsStatusMixins],
  props: {
    status: {
      type: Number,
      required: true,
    },
    size: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {}
  },
  computed: {
    iconName() {
      return this.getStatusIconName(this.status)
    },
    statusName() {
      return this.getStatusName(this.status)
    },
    getClass() {
      switch (this.status) {
        case MEAL_STATUSES.TODO:
          return 'meal-status-icon--todo'
        case MEAL_STATUSES.DOING:
          return 'meal-status-icon--doing'
        case MEAL_STATUSES.IN_OVEN:
          return 'meal-status-icon--in-oven'
        case MEAL_STATUSES.DONE:
          return 'meal-status-icon--done'
      }
    },
  },
  methods: {
    getSizeStyle() {
      return {
        'min-width': `${this.size}px`,
        'min-height': `${this.size}px`,
        width: `${this.size}px`,
        height: `${this.size}px`,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.meal-status-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  i {
    font-size: 12px;
    color: #fff;
  }
  &--todo {
    background-color: gray;
  }
  &--doing {
    background-color: $orange;
  }
  &--in-oven {
    background-color: $orange;
  }
  &--done {
    background-color: $green-dark;
  }
}
</style>

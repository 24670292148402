import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const getDefaultState = () => {
  return {
    orders: [],
    ordersRequest: '',
    couriers: [],
    employees: [],
    ordersInRegeocode: [],
    primaryFilters: {
      // Filters which are constant filters, not cleared when changing status/pickup type
      brand: null,
    },
  }
}

const resetState = (state) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  mutations: {
    ...mutations,
    resetState,
  },
  actions,
}

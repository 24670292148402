<template>
  <tr :class="getShiftRowClass(shift.billing_period_finished)">
    <td v-if="shift.employee">{{ shift.employee.name }}</td>
    <td v-if="shift.employee">{{ userRole }}</td>
    <td class="table-cell--date">
      <template v-if="shift.started_at">
        <div class="table-cell--date__row">
          <i class="far fa-calendar fa-fw"></i>{{ shift.started_at.split(' ')[0] }}
        </div>
        <div class="table-cell--date__row"><i class="far fa-clock fa-fw"></i>{{ shift.started_at.split(' ')[1] }}</div>
      </template>
      <span v-else>---</span>
    </td>
    <td class="table-cell--date">
      <template v-if="shift.finished_at">
        <div class="table-cell--date__row">
          <i class="far fa-calendar fa-fw"></i>{{ shift.finished_at.split(' ')[0] }}
        </div>
        <div class="table-cell--date__row"><i class="far fa-clock fa-fw"></i>{{ shift.finished_at.split(' ')[1] }}</div>
      </template>
      <template v-else-if="shift.started_at">
        <b-button v-if="closingShiftBtn" variant="danger" block @click="showShiftControlModal()">
          <i class="fas fa-stop"></i>
          <span>{{ $t('message.billing.end') }}</span>
        </b-button>
        <span v-else>{{ $t('message.shifts.lasts') }}</span>
      </template>
      <span v-else>---</span>
    </td>
    <td>{{ getValue(shift.operational_cash) }}</td>
    <td>
      {{ shift.total_orders }}
      <span v-if="shift.total_deliveries_salary !== null"
        >({{
          priceDecimal(priceDecimal(shift.total_deliveries_salary, false).add(priceDecimal(shift.total_zones_salary)))
        }}
        {{ currency }})</span
      >
      <TooltipButton
        v-if="shift.not_all_orders_in_zones"
        :id="'noZoneTooltipButton_' + shift.id"
        placement="bottom"
        button-class="smallTooltipButton-exclamation"
        class="no-zone-alert"
        variant="light"
      >
        <i class="fas fa-exclamation-circle"></i>
        <template #tooltipText>{{ $t('message.shifts.notAllOrdersWithDA') }}</template>
      </TooltipButton>
    </td>
    <td class="border-left">{{ getValue(shift.cash_for_orders) }}</td>
    <td>{{ getValue(shift.payments_from_customers) }}</td>
    <td class="border-right">{{ getValue(shift.orders_cash_balance) }}</td>
    <td>{{ getValue(shift.balance) }}</td>
    <td>
      <router-link
        :to="{ name: 'shift-details', params: { id: shift.id } }"
        tag="button"
        class="btn btn-primary btn-block btn-sm"
        >{{ $t('message.billing.details') }}</router-link
      >
    </td>
    <td>
      <div :id="'shift_edit_button_' + shift.id" class="shift_edit_button">
        <b-button
          v-if="isShiftFinished && !isBillingPeriodFinished && shift.last_shift"
          :disabled="!hasUserRole([ROLE.OWNER, ROLE.MANAGER])"
          size="sm"
          variant="warning"
          @click="showShiftControlModal(shift.id)"
        >
          {{ $t('message.billing.shiftEdit') }}
        </b-button>
        <b-tooltip
          v-if="!hasUserRole([ROLE.OWNER, ROLE.MANAGER])"
          :target="'shift_edit_button_' + shift.id"
          triggers="hover"
          placement="topleft"
        >
          {{ $t('message.billing.shiftEditInfo') }}
        </b-tooltip>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'
import { rowColor } from '@/mixins'
import TooltipButton from '@/components/TooltipButton'
import { ROLE } from '@/common/constants'

export default {
  name: 'CurrentShiftRow',
  components: { TooltipButton },
  mixins: [rowColor],
  props: {
    shift: {
      type: Object,
      default: () => {
        return {}
      },
    },
    closingShiftBtn: {
      default: true,
      type: Boolean,
    },
    employee: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      ROLE,
    }
  },
  computed: {
    ...mapGetters({
      currency: 'currency',
      hasUserRole: 'auth/hasUserRole',
    }),
    userRole() {
      const { employee } = this.shift
      return employee && employee.role ? this.$t(`message.employeeList.${employee.role}`) : ''
    },
    isShiftFinished() {
      return this.shift && this.shift.finished
    },
    isBillingPeriodFinished() {
      return this.shift.hasOwnProperty('billing_period_finished')
        ? this.shift.billing_period_finished
        : this.closingShiftBtn
    },
  },
  methods: {
    showShiftControlModal(shiftId = null) {
      let employee = this.shift.employee || this.employee
      const user = {
        ...employee,
        last_shift: { ...this.shift },
      }
      let sendData = {
        actionType: 'finish',
        user,
        onSuccess: () => {
          if (this.$route.name === '_shifts') this.$store.dispatch('_shifts/getShifts', { loading: true })
        },
      }
      if (shiftId) sendData.shiftId = shiftId
      this.$eventBus.$emit('onShowShiftControlModal', sendData)
    },
    getValue(value, currency) {
      if (value !== undefined) {
        value = this.priceDecimal(value)
        if (currency) return value + ' ' + currency
        else return value
      } else return '---'
    },
  },
}
</script>

<style scoped lang="scss"></style>

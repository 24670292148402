<template>
  <b-modal
    ref="modal"
    :title="$t('message.orderFormFull.newOrder')"
    header-class="py-2"
    body-class="order-modal-body"
    dialog-class="order-modal-dialog"
    content-class="order-modal-content"
    :visible="visible"
    no-close-on-backdrop
    scrollable
    :hide-footer="true"
    @hidden="hide"
  >
    <order-form-papu-3 />
  </b-modal>
</template>

<script>
import { responseErrorsHandler } from '@/mixins'
import OrderFormPapu3 from '@/components/papu-3/order-form/OrderFormPapu3'

export default {
  name: 'OrderPapu3Modal',
  components: {
    OrderFormPapu3,
  },
  mixins: [responseErrorsHandler],
  provide() {
    return {
      parentValidator: this.$validator,
    }
  },
  data() {
    return {
      visible: false,
      promisedTimeValue: 15,
    }
  },
  mounted() {
    this.$eventBus.$on('showOrderPapu3Modal', () => {
      this.show()
    })
    this.$eventBus.$on('hideOrderPapu3Modal', () => {
      this.hide()
    })
  },
  destroyed() {
    this.$eventBus.$off('showOrderPapu3Modal')
    this.$eventBus.$off('hideOrderPapu3Modal')
  },
  methods: {
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    },
  },
}
</script>

<style lang="scss">
.order-modal {
  &-body {
    padding-bottom: 0 !important;
  }
  &-dialog {
    @include media-breakpoint-down(sm) {
      margin: 0 !important;
      display: inline !important;
      height: 100% !important;
      max-height: 100% !important;
    }
  }
  &-content {
    @include media-breakpoint-down(sm) {
      border-radius: 0 !important;
    }
  }
}
</style>

<style scoped lang="scss">
.order-modal {
  &__price {
    margin-top: 0.5rem;
    max-width: 100px;
  }
}
</style>

<template>
  <b-modal
    id="splitOrderModal"
    ref="splitOrderModal"
    class="modal-bills"
    :title="$t('message.splitBill.splitBillHeading')"
    centered
    @hidden="resetModal"
    @ok="handleOk"
  >
    <div class="my-3 d-flex justify-content-around">
      <template v-for="(numberOfBills, numberOfBillsIndex) in numberOfBillsOptions">
        <b-button
          v-if="numberOfBills <= maxBillsAmountToSplit"
          :key="`numberOfBills_${numberOfBillsIndex}`"
          variant="outline-primary"
          size="lg"
          class="mx-1"
          @click="selectNumberOfBills(numberOfBills)"
        >
          {{ numberOfBills }}
        </b-button>
      </template>
    </div>
    <p class="modal-bills__separating-text">
      {{ $t('message.common.or') }}
    </p>
    <ValidationObserver ref="observer">
      <b-form ref="form" slot-scope="{ validate }" @submit.prevent="validate().then(handleSubmit)">
        <ValidationProvider :rules="`required|min_value:2|max_value:${maxBillsAmountToSplit}`" name=" " tag="div">
          <b-form-group
            slot-scope="{ valid, errors }"
            :label="$t('message.splitBill.numberOfBills')"
            label-for="numberOfBills"
          >
            <b-form-input
              id="numberOfBills"
              v-model="numberOfBills"
              :state="errors[0] ? false : valid ? true : null"
              :placeholder="`${$t('message.splitBill.enterQuantity')}...`"
              type="number"
              class="modal-bills__input"
            />
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-form>
    </ValidationObserver>

    <template #modal-footer="{ ok, cancel }">
      <b-button size="lg" variant="outline-dark" class="mr-3" @click="cancel()">
        {{ $t('message.common.cancel') }}
      </b-button>
      <b-overlay :show="processing" rounded="sm" variant="success" spinner-variant="light" opacity="1">
        <b-button size="lg" variant="success" @click="ok()">
          <i class="fas fa-check mr-2" aria-hidden="true" />
          {{ $t('message.common.confirm') }}
        </b-button>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'

const STORE = '_orderSplitBill'

export default {
  name: 'SplitOrderModal',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      numberOfBills: '',
      numberOfBillsOptions: [2, 3, 4, 5],
      processing: false,
    }
  },
  computed: {
    id() {
      return parseInt(this.$route.params.id)
    },
    billsLimit() {
      return this.$store.getters[`${STORE}/billsLimit`]
    },
    billsLength() {
      return this.$store.getters[`${STORE}/billsLength`]
    },
    lastBillId() {
      return this.$store.getters[`${STORE}/lastBillId`]
    },
    maxBillsAmountToSplit() {
      return this.billsLength < this.billsLimit ? this.billsLimit - this.billsLength : 0
    },
  },
  methods: {
    resetModal() {
      this.numberOfBills = ''
    },
    redirectToLastBill() {
      this.$router.replace({ name: 'orders-split-bill-edit', params: { id: this.id, billId: this.lastBillId } })
    },
    async handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.handleSubmit()
      }
    },
    handleSubmit() {
      this.selectNumberOfBills(this.numberOfBills)
    },
    async selectNumberOfBills(value) {
      const amount = typeof value === 'string' ? parseFloat(value) : value
      this.processing = true

      try {
        await this.$store.dispatch(`${STORE}/splitBillEqually`, amount)
        this.$bvModal.hide('splitOrderModal')
        this.redirectToLastBill()
      } catch ({ errorStatus, errorValue }) {
        const msg = errorStatus !== 500 && errorValue ? errorValue : this.$t('message.common.anErrorOccured')
        this.$toasted.error(msg, {
          duration: 5000,
        })
      }

      this.processing = false
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/scss/components/split-bill';
</style>

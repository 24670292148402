<template>
  <div class="widget">
    <div class="widget-body-wrapper d-flex flex-column pb-0">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PpWidget',
}
</script>

<template>
  <div class="layout">
    <NavClosable>
      <template #leftSide>
        <portal-target name="nav-closable-left-side" />
      </template>
      <template #rightSide>
        <portal-target name="nav-closable-right-side" />
      </template>
    </NavClosable>
    <div class="layout-content">
      <router-view />
    </div>
  </div>
</template>

<script>
import NavClosable from '@/components/navigation/NavClosable'

export default {
  name: 'ClosableLayout',
  components: {
    NavClosable,
  },
}
</script>

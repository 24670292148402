<template>
  <b-nav-item :to="{ name: 'settings' }" link-classes="btn-navbar">
    <i class="nav-link__icon fas fa-cogs mr-0" />
    <span v-if="showLabel" class="nav-link__text">{{ $t('message.navMain.settings') }}</span>
  </b-nav-item>
</template>

<script>
export default {
  name: 'ItemSettings',
  props: {
    showLabel: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <div v-if="notifications.length > 0 && isLoggedIn()" class="container-fluid">
    <div v-for="(notification, index) in notifications" :key="'notification_' + index" class="row">
      <div class="col-12">
        <div
          :id="shoudGlow(notification) ? 'glowing' : ''"
          class="alert alert-dismissible fade show"
          role="alert"
          :class="`alert-${notification.color}`"
        >
          <strong>
            <pre class="text-wrap"><span v-html="notification.content"></span></pre>
            <router-link
              v-if="
                hasUserRole([ROLE.OWNER, ROLE.MANAGER]) && notification.type === NOTIFICATION_TYPE.SUBSCRIPTION_ENDS
              "
              :to="{ name: 'subscriptions' }"
              tag="a"
            >
              {{ $t('message.navMain.addFunds') }}
            </router-link>
          </strong>
          <small v-if="notification.deletable" class="font-italic">
            {{ $t('message.notifications.deletable') }}
          </small>
          <button
            v-if="notification.closable"
            id="close-notification-btn"
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            @click="closeDeleteNotification(notification)"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { NOTIFICATION_TYPE, ROLE } from '@/common/constants'
import { API } from '@/services/api/api'

export default {
  name: 'Notifications',
  data: function () {
    return { NOTIFICATION_TYPE, ROLE }
  },
  computed: {
    ...mapGetters({
      notifications: 'notifications',
      hasUserRole: 'auth/hasUserRole',
    }),
  },
  methods: {
    ...mapActions({
      getNotifications: 'getNotifications',
    }),
    shoudGlow(notification) {
      const glowingTypes = [NOTIFICATION_TYPE.NEW_FEATURE]
      return glowingTypes.includes(notification.type)
    },
    async closeDeleteNotification(notification) {
      if (notification.deletable) {
        await API.deleteNotification(notification.pk)
      }
      if (notification.closable) {
        // updated sessionStorage.notificationsClosed (where closed notifications are stored) with notification.pk
        const notificationsClosed = sessionStorage.getItem('notificationsClosed')
        const notificationsPrevClosed = notificationsClosed ? JSON.parse(notificationsClosed) : []
        sessionStorage.setItem('notificationsClosed', JSON.stringify([notification.pk].concat(notificationsPrevClosed)))
      }
      // update notifications (and than if notifications has changed, Vue would render view)
      this.getNotifications()
    },
  },
}
</script>

<style scoped>
@keyframes glowing {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(161, 185, 209, 1);
  }
  20% {
    box-shadow: 0px 0px 7px 7px rgba(161, 185, 209, 1);
  }
  70% {
    box-shadow: 0px 0px 7px 7px rgba(161, 185, 209, 1);
  }
  90% {
    box-shadow: 0px 0px 0px 0px rgba(161, 185, 209, 1);
  }
}
#glowing {
  animation: glowing 4s infinite;
}
</style>

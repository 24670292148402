import { VAT_CHOICES_DE, VAT_CHOICES_PL, VAT_RATES_DE, VAT_RATES_PL } from '../common/constants'

export const vatMixins = {
  methods: {
    getVatRates(noNullVat = false) {
      // get vat_rate for given company country or PL
      let vatRates = []
      const countryCode = this.$store.getters['auth/getCompany'].country
      if (countryCode === 'DE') {
        vatRates = [...VAT_RATES_DE]
      } else {
        vatRates = [...VAT_RATES_PL]
      }
      // remove null vat rate option
      if (noNullVat) {
        vatRates.pop()
      }
      return vatRates
    },
    getDefaultFoodServiceVatSymbol() {
      const countryCode = this.$store.getters['auth/getCompany'].country
      if (countryCode === 'DE') {
        return VAT_CHOICES_DE['7']
      } else {
        return VAT_CHOICES_PL.B
      }
    },
    getDefaultFoodServiceVatRateName() {
      const countryCode = this.$store.getters['auth/getCompany'].country
      if (countryCode === 'DE') {
        return VAT_CHOICES_DE['7']
      } else {
        return VAT_RATES_PL[VAT_RATES_PL.findIndex((rate) => rate.value === VAT_CHOICES_PL.B)].text
      }
    },
    getVatRateText(vatChoice) {
      // get vat_rate representational text
      // vat_rate may be not found if has different vat_rates e.g. has vat B (PL), but country == DE (no vat B)
      const foundVatRate = this.getVatRates().find((vat) => {
        return vat.value === vatChoice
      })
      if (foundVatRate) {
        return foundVatRate.text
      } else {
        return this.getVatRates().find((vat) => {
          return vat.value === null
        }).text
      }
    },
  },
}
